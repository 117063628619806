import { actionActionType } from "./ActionActions";
const initAction = {
  open: false,
  message: "",
  onClick: null,
};
export default function action(state = initAction, action) {
  switch (action.type) {
    case actionActionType.close:
      return {
        ...initAction,
      };
    case actionActionType.open:
      return {
        ...state,
        ...action,
        open: true,
      };
    default:
      return state;
  }
}
