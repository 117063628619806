import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { objToQueryStr, formatTime } from "services/utils";
import { actionError } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Flex from "components/flex/Flex";
import Button from "components/button/Button";
import DateRange from "components/date-range/DateRange";
import PageLabel from "components/page-label/PageLabel";
import NotSignedStatus from "../detail/NotSignedStatus";
import fonts from "libs/fonts";
import routes from "libs/routes";
import * as APIS from "libs/apis";
import { saveAs } from "file-saver";
import { getDefaultPageSize, STORAGE_KEY } from "libs/consts";

export default function MemberSNS({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state || {};
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const classes = useStyle();

  const [shops, setShops] = useState([]);
  const [users, setUsers] = useState([]);
  const [summary, setSummary] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [startDate, setstartDate] = useState(
    formatTime(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));

  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      state: {
        fromDate: startDate,
        toDate: endDate,
        ...getDefaultPageSize(),
      },
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isNaN(state.page)) {
      console.log("/member/sns", state);
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    const param = {
      ...state,
    };

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }

    dispatch(loadingStart);
    APIS.getNotSignedSocialUser(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setUsers(
            data.content.map((item) => {
              // const { status, userType } = item;
              return {
                ...item,
              };
            })
          );
          setTotalCount(data.totalElements);
        } else {
          alert(message);
        }
      })
      .then(() => {
        APIS.getNotSignedSocialUserSummary(objToQueryStr(param)).then(
          ({ data: { success, data, message } }) => {
            if (success) {
              setSummary(data);
            }
          }
        );
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const onClickExcelDownload = () => {
    const param = { ...state };
    if (param.fromDate) {
      param.fromDate = `${param.fromDate}T00:00:00`;
    }
    if (param.toDate) {
      param.toDate = `${param.toDate}T23:59:59`;
    }

    // 이상, 이하
    const { amountType, more, less } = param;
    param["amountAssetType"] = amountType;
    if (more) {
      param[`startAssetAmount`] = more;
    }

    if (less) {
      param[`endAssetAmount`] = less;
    }

    delete param.more;
    delete param.less;
    delete param.amountType;
    delete param.page;
    delete param.size;

    if (!param.startAssetAmount && !param.endAssetAmount) {
      delete param["amountAssetType"];
    }
    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.searchMemberExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `일반회원_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
    // axios({
    //   method: "GET",
    //   url: `/api/v1/manager/search/excel${objToQueryStr(param)}`,
    //   responseType: "blob",
    // }).then((response) => {
    //   const blob = new Blob([response.data]);
    //   saveAs(blob, `일반회원_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`);
    // });
  };

  const memoizedUsers = useMemo(() => {
    return users.map((user) => {
      return {
        ...user,
      };
    });
  }, [users]);

  const handleClickRow = useCallback((item) => {
    history.push(`${routes.memberDetail}/${item.id}`);
  }, []);

  return (
    <Flex>
      <PageLabel>SNS회원</PageLabel>
      <Flex row className={classes.toolbar}>
        <DateRange
          startDate={setstartDate}
          endDate={setEndDate}
          period={["week", "month", "month3"]}
        />
        <Button
          label="검색"
          className={classes.searchButton}
          onClick={fetchList}
        />
      </Flex>
      <NotSignedStatus summaryData={summary} />
      <MemoizedDataTable
        data={memoizedUsers}
        totalCount={totalCount}
        onClick={handleClickRow}
      />
    </Flex>
  );
}

const MemoizedDataTable = React.memo(({ data, totalCount, onClick }) => {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const headerRows = [
    [
      { label: "NO", rowSpan: 2 },
      { label: "가입일", rowSpan: 2 },
      { label: "SNS방식", rowSpan: 2 },
      { label: "social_key", rowSpan: 2 },
      { label: "ad_id", rowSpan: 2 },
      { label: "본인 인증", colSpan: 2 },
      { label: "약관", colSpan: 2 },
      { label: "추천매장", colSpan: 2 },
      { label: "이메일", colSpan: 2 },
      { label: "결제비번", colSpan: 2 },
    ],
    [
      { label: "일시" },
      { label: "횟수" },
      { label: "일시" },
      { label: "횟수" },
      { label: "일시" },
      { label: "횟수" },
      { label: "일시" },
      { label: "횟수" },
      { label: "일시" },
      { label: "횟수" },
    ],
  ];
  return (
    <Flex style={{ margin: "0 50px 0 50px" }}>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: 600 }}
      >
        <Table>
          <TableHead>
            {headerRows?.map((row, index) => {
              return (
                <TableRow key={index}>
                  {row.map(
                    ({ label, rowSpan, colSpan, width, render }, index) => {
                      return (
                        <TableCell
                          key={index}
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                          width={width}
                        >
                          {render || label}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody style={{ background: "white" }}>
            {data?.map((row, index) => {
              return (
                <TableRow>
                  <TableCell className={classes.cell} align={"center"}>
                    {totalCount -
                      parseInt(state.page) * parseInt(state.size) -
                      index}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {formatTime(row.createdAt, "YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {row.type}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    align={"center"}
                    style={{
                      maxWidth: 240,
                      wordWrap: "break-word",
                    }}
                  >
                    {row.socialKey}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    align={"center"}
                    style={{
                      maxWidth: 240,
                      wordWrap: "break-word",
                    }}
                  >
                    {row.adId}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {formatTime(row.selfVerificationAt, "YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {row.selfVerificationCnt}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {formatTime(row.termsAt, "YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {row.termsCnt}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {formatTime(row.recommendShopAt, "YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {row.recommendShopCnt}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {formatTime(row.emailAt, "YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {row.emailCnt}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {formatTime(row.finNumberAt, "YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell className={classes.cell} align={"center"}>
                    {row.finNumberCnt}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        onChangePage={(e, p) => {
          history.replace(location.pathname, {
            ...state,
            page: p,
          });
        }}
        className={classes.pagination}
        count={totalCount || 0}
        onChangeRowsPerPage={(e) => {
          history.replace(location.pathname, {
            ...state,
            page: 0,
            size: e.target.value,
          });
          localStorage.setItem(STORAGE_KEY.tableSize, e.target.value);
        }}
        // page={parseInt(state.page)}
        page={state.page > 0 && totalCount < state.page ? 0 : state.page}
        rowsPerPage={parseInt(state.size)}
      />
    </Flex>
  );
});

const useStyle = makeStyles({
  toolbar: {
    margin: "50px 50px 10px 50px",
    justifyContent: "space-between",
  },
  searchButton: {
    height: 35,
  },
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
  cell: {
    borderRight: "1px solid #e0e0e0",
    height: "46px",
  },
});
