const useFocusAndBlur = () => {
  const handleFocus = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.style.transition = "all .4s ease-in-out";
      elem.style.color = "#4583ee";
      elem.style.background = "#c9e2ff";
    }
  };

  const handleBlur = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.style.transition = "all .4s ease-in-out";
      elem.style.background = "#f2f4f6";
      elem.style.color = "#636a75";
    }
  };

  return { handleFocus, handleBlur };
};

export default useFocusAndBlur;
