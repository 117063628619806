import SearchLayoutV2 from "layouts/search/SearchLayoutV2";
import { formatTime } from "libs/utils";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import {
  getFromHistoryState,
  INVENTORY_APPRISAL_FEE,
} from "./appraisalFeeConst";
import ApprisalDisposeButtonSection from "./components/ApprisalDisposeButtonSection";
import ApprisalStatus from "./components/ApprisalStatus";
import InventoryApprisalForm from "./components/InventoryApprisalForm";
import InventoryApprisalGrid from "./components/InventoryApprisalGrid";
import { useAppraisalInventoryStatus, useApprisalFee } from "./appraisalHooks";
import { useHistory, useLocation } from "react-router";

const InventoryApprisalFee = () => {
  const history = useHistory();
  const location = useLocation();

  const [search, setSearch] = useState(undefined);
  const [initalValues, setInitalValues] = useState(undefined);

  const [datas] = useApprisalFee(search);
  const {
    appraisalStatus,
    refetch: statusRefetch,
    isLoading,
  } = useAppraisalInventoryStatus();

  const refetch = () => {
    setSearch({ ...search, page: 0, refetchFlag: search.refetchFlag + 1 });
  };
  const hanldePageChange = (e) => {
    const { skip, take } = e.page;
    const page = skip / take;
    history.replace({
      pathname: location.pathname,
      state: { ...search, page, size: take },
    });
    setSearch({ ...search, page, size: take });
  };

  const handleSubmit = (e) => {
    const { values } = e;

    const payload = {
      page: 0,
      size: search.size,
      dateType: values.dateType.value,
      startDate: formatTime(values.dateRange.start, "YYYY-MM-DD"),
      endDate: formatTime(values.dateRange.end, "YYYY-MM-DD"),
      refetchFlag: search.refetchFlag + 1,
    };

    if ("assetType" in values) {
      payload.assetType = values.assetType.map((value) => value.value);
    }
    if ("disposeType" in values) {
      payload.disposeType = values.disposeType.map((value) => value.value);
    }

    history.replace({
      pathname: location.pathname,
      state: payload,
    });
    setSearch({ ...payload });
  };

  useEffect(() => {
    const isHistoryEmpty = !location.state;
    if (isHistoryEmpty) {
      const INIT = {
        dateType: "CREATED",
        page: 0,
        size: 100,
        startDate: moment().subtract(21, "d").format("YYYY-MM-DD"),
        endDate: formatTime(new Date(), "YYYY-MM-DD"),
        refetchFlag: 0,
      };
      const initalValues = {
        dateType: INVENTORY_APPRISAL_FEE.DROP_DOWN.DATE_TYPE[0],
        dateRange: {
          start: new Date(INIT.startDate),
          end: new Date(),
        },
      };
      setSearch(INIT);
      setInitalValues(initalValues);
    } else {
      const { historyInitial, historySearch } = getFromHistoryState(
        location.state
      );
      setSearch(historySearch);
      setInitalValues(historyInitial);
    }
  }, [setSearch, setInitalValues]);

  return (
    <div style={{ margin: "15px" }}>
      <SearchLayoutV2 pageTitle="감정평가 수수료">
        <InventoryApprisalForm
          initalValues={initalValues}
          handleSubmit={handleSubmit}
        />
      </SearchLayoutV2>
      <div
        style={{
          marginTop: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ApprisalStatus
          appraisalStatus={appraisalStatus}
          isLoading={isLoading}
        />
        <ApprisalDisposeButtonSection refetch={refetch} />
      </div>
      <InventoryApprisalGrid
        datas={datas}
        refetch={refetch}
        statusRefetch={statusRefetch}
        hanldePageChange={hanldePageChange}
      />
    </div>
  );
};

export default InventoryApprisalFee;
