import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import action from "./action/ActionReducer";
import loading from "./loading/LoadingReducer";
import user from "./user/UserReducer";
import modal from "./modal/ModalReducer";
import quickMenu from "./quickMenu/QuickMenuReducer";
import auth from "./auth/AuthReducer";
import imageModal from "./image-modal/ImageModalReducer";
import imageViewer from "./image-viewer/ImageViewerReducer";
import dialogAlert from "./dialog-alert/DialogAlertReducer";
import shop from "./shop/AssociateShopReducer";

export const store = createStore(
  combineReducers({
    user,
    action,
    loading,
    modal,
    imageModal,
    imageViewer,
    quickMenu,
    auth,
    dialogAlert,
    shop,
  }),
  {},
  compose(applyMiddleware(thunk))
);
