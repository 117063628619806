import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import GridTable from "../../components/gird-table/GridTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import Flex from "../../components/flex/Flex";
import { decode } from "js-base64";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import cloneDeep from "lodash.clonedeep";
import * as APIS from "../../libs/apis";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { numberWithCommas, unComma } from "../../libs/utils";
import AdminCofirmBtn from "../../components/admin-confirm-btn/AdminConfirmBtn";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

const assetList = [
  { key: "GOLD", text: "금" },
  { key: "SILVER", text: "은" },
  // { key: "KRW", text: "KRW" },
  { key: "POINT", text: "POINT" },
];

const ServiceAssetDrop = (props) => {
  const [list, setList] = useState([]);
  const [amount, setAmount] = useState("");
  const [progress, setProgress] = useState(false);
  const [selected, setSelected] = useState(assetList[0]);
  const [result, setResult] = useState(false);
  const [selectedEl, setSelectedEl] = useState("");
  const [event, setEvent] = useState("");
  const [eventList, setEventList] = useState([]);
  const [payments, setPayments] = useState(false);
  //const [result, setResult] = useState(false);
  const listLenRef = useRef(0);
  const listDataRef = useRef([]);
  const modeRef = useRef(false);
  const countRef = useRef(null);
  const paymentsRef = useRef(false);
  const resultRef = useRef(result);
  const currentPosition = useRef("");
  const { searchFn } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    APIS.getEventList()
      .then((res) => {
        try {
          const { data } = res;
          const { success, data: eventData } = data;

          const parseEvent = eventData.map((item) => {
            return {
              ...item,
              eventName: `[${item.eventId}] ${item.eventName}`,
            };
          });

          if (success) {
            setEventList(parseEvent || []);
            setEvent(parseEvent?.[0] || "");
          }
        } catch (err) {
          dispatch(actionOpen(err, null, "", "", true));
        }
      })
      .catch((err) => dispatch(actionError(err)));
  };

  useEffect(() => {
    function setCaretPosition(elemId) {
      let elem = document.getElementById(elemId);
      const position = currentPosition?.current || 0;

      if (elem != null) {
        if (elem.createTextRange) {
          var range = elem?.createTextRange();
          range?.move("character", position);
          range?.select();
        } else {
          if (elem.selectionStart) {
            elem?.focus();
            elem?.setSelectionRange(position, position);
          } else elem?.focus();
        }
      }
    }
    setCaretPosition(selectedEl);
  }, [selectedEl, list]);

  useEffect(() => {
    const dropArea = document.getElementById("drop-area");
    [("dragenter", "dragover")].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });
    dropArea.addEventListener("drop", handleDrop, false);
    modeRef.current = true;
    fetchAssetAmount();
    return () => {
      const dropArea = document.getElementById("drop-area");
      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea?.removeEventListener(eventName, highlight, false);
      });
      ["dragleave", "drop"].forEach((eventName) => {
        dropArea?.removeEventListener(eventName, unhighlight, false);
      });
      dropArea?.removeEventListener("drop", handleDrop, false);
      clearInterval(countRef.current);
    };
  }, []);

  const fetchAssetAmount = () => {
    APIS.getAssetAmount()
      .then((res) => {
        try {
          const { data } = res;
          const { success, data: assetData } = data;
          if (success) {
            setAmount(assetData);
          }
        } catch (err) {
          dispatch(actionOpen("처리할 수 없습니다.", null, "", "", true));
        }
      })
      .catch((err) => dispatch(actionError(err)));
  };

  const highlight = (e) => {
    e.preventDefault();
    const dropArea = document.getElementById("drop-area");
    dropArea.style.borderColor = "purple";
    dropArea.style.background = "#7d7d7d";
    dropArea.style.opacity = "0.3";
  };

  const unhighlight = (e) => {
    e.preventDefault();
    const dropArea = document.getElementById("drop-area");
    dropArea.style.borderColor = "black";
    dropArea.style.background = "#fff";
    dropArea.style.opacity = "1";
  };

  const handleDrop = (e) => {
    if (paymentsRef.current) {
      dispatch(
        actionOpen("초기화 버튼을 먼저 눌러주세요.", null, "", "", true)
      );
      return;
    }
    e.preventDefault();
    let dt = e.dataTransfer;
    let files = dt.files;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    resultRef.current = result;
    reader.onload = function (event) {
      const text = event.target.result;
      const parseStr = text.split(",");
      if (parseStr[0].includes("csv")) {
        const value = decode(parseStr[1]);
        const data = csvToArray(value);
        setProgress(true);
        if (resultRef.current) {
          setResult(false);
        }
        move(data);
      } else {
        dispatch(
          actionOpen("해당파일은 업로드가 불가합니다.", null, "", "", true)
        );
        return;
      }
    };
  };

  const csvToArray = (str, delimiter = ",") => {
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    headers = headers.map((item) => {
      return item.replace("\r", "");
    });

    let rows = str.slice(str.indexOf("\n") + 1).split("\n");
    rows = rows.map((item) => {
      return item.replace("\r", "");
    });
    const parseArr = rows.filter((item) => item !== "");
    const arr = parseArr.map((row) => {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    return arr;
  };

  const move = (data) => {
    let elem = document.getElementById("greenBar");
    elem.classList.add("active");
    let count = 0;
    countRef.current = setInterval(function () {
      if (count >= 100) {
        clearInterval(countRef.current);
        countRef.current = null;
        return false;
      }
      count += 1;
      document.getElementById("barPercent").innerHTML = `${count}%`;
    }, 27);
    setTimeout(() => {
      listLenRef.current = data.length;
      let parseData = cloneDeep(listDataRef.current);
      parseData = parseData.concat(data);
      parseData = parseData.map((item) => {
        return {
          ...item,
          etc: "new",
          isValid: item?.isValid ? item.isValid : "",
        };
      });
      listDataRef.current = [...parseData];
      setList(parseData);
      setProgress(false);
      elem.classList.remove("active");
    }, 3000);
  };

  const onChangeValue = (e, dataIndex) => {
    const { name, value } = e.target;

    let el = document?.getElementById?.(selectedEl);
    currentPosition.current = el?.selectionStart;

    let copyData = cloneDeep(list);

    const reg = /[^0-9\s,\s.]/g;
    if (reg.test(value)) {
      return;
    }

    copyData[dataIndex] = {
      ...copyData[dataIndex],
      [name]: value,
    };

    setList(copyData);
  };

  const onClickSave = (props) => {
    const copyData = cloneDeep(list);
    let check = true;

    const currentValue = amount?.[`${selected.key?.toLowerCase()}`];
    const totalValue = calcAmount();

    if (currentValue < totalValue) {
      dispatch(
        actionOpen(
          `이벤트 지급량이 현재 보유량 보다 많습니다.`,
          null,
          "",
          "",
          true
        )
      );
      return;
    }

    for (let i = 0; i < copyData.length; i++) {
      if (!copyData[i].userId || !copyData[i].amount) {
        dispatch(
          actionOpen(
            `이벤트 지급 명단에 필수값이 \n입력되지 않았습니다.`,
            null,
            "",
            "",
            true
          )
        );
        check = false;
        break;
      }
    }

    if (check) {
      dispatch(loadingStart);
      const parseList = list
        .filter((el) => el.isValid === true)
        .map((item) => {
          return {
            userId:
              typeof item.userId === "string"
                ? +unComma(item.userId)
                : +item.userId,
            amount:
              typeof item.amount === "string"
                ? +unComma(item.amount)
                : +item.amount,
          };
        });
      const payload = {
        managerPassword: props,
        eventId: event.eventId,
        assetType: selected.key,
        userList: [...parseList],
      };

      APIS.postDropEventAsset(payload)
        .then((res) => {
          try {
            const { data } = res;
            const { success, data: resultData, message } = data;
            if (success) {
              const successLen = resultData.filter(
                (item) => item.isSuccess === true
              );
              const failLen = resultData.filter(
                (item) => item.isSuccess === false
              );
              dispatch(
                actionOpen(
                  `총 인원 : ${successLen.length + failLen.length}\n
                성공 : ${successLen.length} / 실패 : ${failLen.length}`,
                  null,
                  "",
                  "",
                  true
                )
              );
              fetchAssetAmount();
              setPayments(true);
              paymentsRef.current = true;
              setList(resultData);
              setResult(false);
              searchFn(event, { page: 0, size: 30 });
            } else {
              dispatch(actionOpen(message, null, "", "", true));
            }
          } catch (err) {
            dispatch(actionOpen(err));
          } finally {
            dispatch(loadingEnd);
          }
        })
        .catch((err) => dispatch(actionError(err)));
    } else {
      return;
    }
  };

  const calcAmount = () => {
    let total = 0;
    list.forEach((item) => {
      const value =
        typeof item.amount === "string" ? unComma(item.amount) : item.amount;
      if (item?.isValid) {
        total += +value;
      }
    });
    return total;
  };

  const onClickCheckUser = () => {
    let check = true;

    let copyList = cloneDeep(list);
    copyList = copyList.filter((item) => item.isValid === "");
    for (let i = 0; i < copyList.length; i++) {
      if (!copyList[i].userId || !copyList[i].amount) {
        check = false;
        dispatch(
          actionOpen(
            "필수값을 모두 입력해주세요.(회원번호, 수량)",
            null,
            "",
            "",
            true
          )
        );
        break;
      }
    }

    copyList = copyList.map((item) => {
      return +item.userId;
    });

    if (check) {
      APIS.getUserList(`?userIdList=${[...copyList]}`)
        .then((res) => {
          dispatch(loadingStart);
          try {
            const { data } = res;
            const { success, data: resultData } = data;
            const { userList } = resultData;
            if (success) {
              //여기서 기존 리스트와 확인된 유저 맵핑
              if (userList.length > 0) {
                // resultData / isVaild 값이랑 번호 이름이 있다. / userId
                let parseList = cloneDeep(list);
                for (let i = 0; i < userList.length; i++) {
                  const { userId, isValid, userName, phoneNumber } =
                    userList[i];
                  const findIndex = parseList.findIndex(
                    (item) => +item.userId === +userId
                  );
                  parseList[findIndex] = {
                    ...parseList[findIndex],
                    isValid: isValid,
                    userName: userName,
                    phoneNumber: phoneNumber,
                  };
                }
                setResult(true);
                listDataRef.current = parseList;
                setList(parseList);
              }
            }
          } catch (err) {
          } finally {
            setTimeout(() => {
              dispatch(loadingEnd);
            }, 1000);
          }
        })
        .catch((err) => dispatch(actionError(err)));
    } else {
      return;
    }
  };

  return (
    <Flex
      id="dragDrop"
      column
      style={{
        marginTop: "10px",
        position: "relative",
        height: "590px",
        width: "1200px",
      }}
    >
      <div
        id="dim"
        style={{
          display: progress ? "block" : "none",
          width: "100%",
          height: "610px",
          background: "black",
          opacity: "0.3",
          position: "absolute",
          zIndex: "100",
        }}
      />
      <div
        className="progress-bar"
        style={{ display: progress ? "block" : "none" }}
      >
        <div id="greenBar" className="progress-bar__bar"></div>
        <span
          id="barPercent"
          style={{
            display: "flex",
            justifyContent: "center",
            lineHeight: "35px",
            position: "relative",
          }}
        >
          10%
        </span>
      </div>
      <Flex row style={{ margiBottom: "10px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: "20px",
          }}
        >
          <span style={{ fontSize: "15px" }}>
            <h4>이벤트 지급</h4>
          </span>
          &nbsp;&nbsp;
          <div style={{ display: "flex", justifyContent: "center" }}>
            &nbsp;&nbsp;
            <DropDownList
              style={{
                width: "350px",
                height: "35px",
                background: "#fff",
              }}
              data={eventList}
              defaultValue={event || "이벤트를 선택해주세요."}
              dataItemKey="eventId"
              textField="eventName"
              onChange={(e) => {
                setEvent(e.value);
              }}
            />
            &nbsp; &nbsp;
            <DropDownList
              style={{
                width: "100px",
                height: "35px",
                background: "#fff",
              }}
              defaultValue={selected || "자산을 선택해주세요."}
              data={assetList}
              dataItemKey="key"
              textField="text"
              onChange={(e) => {
                setSelected(e.value);
              }}
            />
            &nbsp; &nbsp;
            <div
              style={{
                width: "250px",
                height: "35px",
                boxShadow: "1px 1px 1px 1px #7d7d7d",
                borderRadius: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ marginLeft: "10px" }}>보유량 : </span>
              <div>
                {/* <span>10,000,000</span>&nbsp; */}
                <span>
                  {numberWithCommas(
                    amount?.[`${selected.key?.toLowerCase()}`] || "0"
                  )}
                </span>
                &nbsp;
                <span style={{ lineHeight: "35px", marginRight: "10px" }}>
                  g/₩
                </span>
              </div>
            </div>
            &nbsp;&nbsp;
            <Button
              fillMode="outline"
              style={{
                width: "70px",
                height: "37px",
                background: "#fe6e0e",
                border: "none",
                color: "#fff",
                fontWeight: "bold",
                marginRight: "5px",
              }}
              onClick={() => {
                if (payments) {
                  dispatch(
                    actionOpen(
                      "초기화 버튼을 먼저 눌러주세요.",
                      null,
                      "",
                      "",
                      true
                    )
                  );
                  return;
                }
                let copyData = cloneDeep(list);
                copyData = copyData.concat({
                  userId: "",
                  amount: "",
                  etc: "new",
                  isValid: "",
                });
                listDataRef.current = copyData;
                setList(copyData);
                setResult(false);
              }}
            >
              추가
            </Button>
            <Button
              fillMode="outline"
              style={{
                width: "70px",
                height: "37px",
                background: "#fe6e0e",
                border: "none",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={list.length > 0 || !payments ? false : true}
              onClick={() => {
                setList([]);
                listDataRef.current = [];
                if (payments) {
                  setPayments(false);
                  paymentsRef.current = false;
                }
              }}
            >
              초기화
            </Button>
            &nbsp;
            <Button
              fillMode="outline"
              style={{
                width: "70px",
                height: "37px",
                background: "#fe6e0e",
                border: "none",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={list.length > 0 ? false : true}
              onClick={onClickCheckUser}
            >
              회원확인
            </Button>
            &nbsp;
            <AdminCofirmBtn
              label="지급"
              style={{
                width: "70px",
                height: "37px",
                background: "#fe6e0e",
                border: "none",
                color: "#fff",
                fontWeight: "bold",
              }}
              title={"이벤트 자산 지급"}
              disabled={result && list.length > 0 ? false : true}
              callback={(password) => onClickSave(password)}
            />
          </div>
        </div>
      </Flex>
      <Flex row>
        <Flex
          style={{
            width: "100%",
            transition: "0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
            //transition: "all 0.3s",
          }}
        >
          <GridTableWrap>
            <div>
              <span style={{ color: "#c85b53", fontWeight: "500" }}>
                # 이벤트 지급 목록을 업로드 한 후 <span>&quot;</span>회원확인
                <span>&quot;</span>을 누르셔야 &quot;지급&quot;버튼이 활성화
                됩니다.
              </span>
              <br />
              <span>
                합계&nbsp;:&nbsp;{numberWithCommas(calcAmount())}&nbsp;g
              </span>{" "}
              /{" "}
              <span>
                {numberWithCommas(
                  list.filter((item) => item.isValid === true).length || "0"
                )}
                &nbsp;명
              </span>
            </div>
            <GridTable
              data={list}
              style={{
                height: "520px",
              }}
              scrollable
            >
              <Column
                title="No"
                width={50}
                cell={(props) => {
                  const { dataIndex, dataItem } = props;
                  return (
                    <td style={{ textAlign: "center" }}>
                      {dataItem["etc"] === "new" ? (
                        <Button
                          onClick={() => {
                            let copyData = cloneDeep(list);
                            copyData = copyData.filter(
                              (_, index) => index !== dataIndex
                            );
                            listDataRef.current = copyData;
                            if (!result) {
                              setResult(true);
                            }
                            setList(copyData);
                          }}
                          style={{
                            background: "#fe6e0e",
                            color: "#fff",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  );
                }}
              />
              <Column
                title="회원번호"
                field="userId"
                cell={(props) => {
                  const { dataItem, dataIndex } = props;
                  const check =
                    dataItem["etc"] === "new" && dataItem["isValid"] === ""
                      ? true
                      : false;
                  return (
                    <td style={{ textAlign: "right" }}>
                      {check ? (
                        <>
                          <input
                            style={{ display: "none" }}
                            aria-hidden={true}
                          />
                          <input
                            id={`userId-${dataIndex}`}
                            name={"userId"}
                            type="text"
                            key={`userId-${dataIndex}`}
                            autoComplete="new-password"
                            value={list?.[dataIndex]?.["userId"] || ""}
                            onFocus={() => setSelectedEl(`userId-${dataIndex}`)}
                            onChange={(e) => onChangeValue(e, dataIndex)}
                            style={{
                              width: "200px",
                              border: "1px solid #ededed",
                              borderRadius: "0.2rem",
                              height: "30px",
                              textAlign: "right",
                            }}
                          />
                        </>
                      ) : (
                        dataItem["userId"]
                      )}
                    </td>
                  );
                }}
              />
              <Column
                title="이름"
                field="userName"
                width={120}
                cell={(props) => {
                  const { dataItem } = props;
                  return (
                    <td style={{ textAlign: "center" }}>
                      {dataItem?.["userName"] || "-"}
                    </td>
                  );
                }}
              />
              <Column
                title="휴대폰"
                field="phoneNumber"
                width={150}
                cell={(props) => {
                  const { dataItem } = props;
                  return (
                    <td style={{ textAlign: "right" }}>
                      {dataItem?.["phoneNumber"] || "-"}
                    </td>
                  );
                }}
              />
              <Column
                title="중량"
                field="amount"
                cell={(props) => {
                  const { dataItem, dataIndex } = props;
                  const check =
                    dataItem["etc"] === "new" && dataItem["isValid"] === ""
                      ? true
                      : false;
                  return (
                    <td style={{ textAlign: "right" }}>
                      {check ? (
                        <>
                          <form autoComplete="new-password">
                            <input
                              type="text"
                              autoComplete="new-password"
                              style={{ display: "none" }}
                            />
                          </form>
                          <input
                            id={`amount-${dataIndex}`}
                            name={"amount"}
                            type="text"
                            key={`amount-${dataIndex}`}
                            autoComplete="new-password"
                            value={numberWithCommas(
                              list?.[dataIndex]?.["amount"] || ""
                            )}
                            onFocus={() => setSelectedEl(`amount-${dataIndex}`)}
                            onChange={(e) => onChangeValue(e, dataIndex)}
                            style={{
                              width: "200px",
                              border: "1px solid #ededed",
                              borderRadius: "0.2rem",
                              height: "30px",
                              textAlign: "right",
                            }}
                          />
                        </>
                      ) : (
                        dataItem["amount"]
                      )}
                    </td>
                  );
                }}
              />
              {/* <Column
                width="70"
                title="조회"
                cell={(props) => {
                  const { dataItem } = props;
                  return (
                    <td style={{ textAlign: "center" }}>
                      {dataItem["isValid"] === true ? (
                        <span style={{ color: "blue" }}>성공</span>
                      ) : dataItem["isValid"] === false ? (
                        <span style={{ color: "red" }}>실패</span>
                      ) : (
                        "-"
                      )}
                    </td>
                  );
                }}
              /> */}
              <Column
                width="70"
                title="지급"
                cell={(props) => {
                  const { dataItem } = props;
                  return (
                    <td style={{ textAlign: "center" }}>
                      {dataItem["isSuccess"] === true ? (
                        <span style={{ color: "blue" }}>성공</span>
                      ) : dataItem["isSuccess"] === false ? (
                        <span style={{ color: "red" }}>실패</span>
                      ) : (
                        "-"
                      )}
                    </td>
                  );
                }}
              />
            </GridTable>
          </GridTableWrap>
        </Flex>
        &nbsp;&nbsp;&nbsp;
        <Flex
          style={{
            width: "500px",
            height: "580px",
            transition: "all 0.3s",
          }}
        >
          <DropEnter id="drop-area">
            <span style={{ fontWeight: "bold" }}>명단을 업로드 해주세요.</span>
            <span style={{ color: "red", fontWeight: "bold" }}>
              # csv 파일만 가능
            </span>
            <br />
            <span>파일 업로드 양식</span>
            <table
              border="1px solid #ededed"
              style={{
                borderCollapse: "collapse",
              }}
            >
              <tr>
                <th style={{ fontWeight: 400, width: "100px" }}>userId</th>
                <th style={{ fontWeight: 400, width: "100px" }}>amount</th>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>135</td>
                <td style={{ textAlign: "right" }}>13.5</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>137</td>
                <td style={{ textAlign: "right" }}>0.74</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>139</td>
                <td style={{ textAlign: "right" }}>14.2</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>120</td>
                <td style={{ textAlign: "right" }}>3.75</td>
              </tr>
            </table>
          </DropEnter>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ServiceAssetDrop;

const DropEnter = styled.div`
  height: 550px;
  border: 5px dashed black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .highlight {
    color: yellow;
    background: yellow;
    border-color: purple;
  }
`;

const GridTableWrap = styled.div`
  .k-grid-norecords {
    height: 481px;
  }

  td {
    border-bottom: 1px solid #ebebeb;
  }

  input {
    width: 250px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`;
