import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import Flex from "../../components/flex/Flex";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import SubCategoryRows from "./SubCategoryRows";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function SubcategoryList({ subcategoryid }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [categorydata, setcategorydata] = useState([]);
  const [categoryreser, setcategoryreser] = useState("");
  const [categoryid, setcategoryid] = useState([]);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getGoodsTypeList(subcategoryid)
      .then((res) => {
        setcategorydata([]);
        setcategorydata(res.data.data);
        setcategoryreser(0);
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, [categoryreser]);

  const categoryAdd = () => {
    setcategorydata((categorydata) => [...categorydata, {}]);
  };
  const categoryDelete = () => {
    if (categoryid.length < 1) {
      dispatch(
        actionOpen("삭제할 카테고리를 선택해주세요", null, null, null, true)
      );
      return;
    }
    var cateTxt = [];
    for (var key in categoryid) {
      cateTxt.push(categoryid[key].id);
    }

    dispatch(loadingStart);
    APIS.postGoodsTypeDelete(cateTxt)
      .then(() => {
        setcategoryreser(1);
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  const categoryselectfunc = (id, e) => {
    if (e.target.checked === true) {
      setcategoryid((categoryid) => [...categoryid, { id }]);
    } else {
      setcategoryid(categoryid.filter((categoryid) => categoryid.id !== id));
    }
  };

  return (
    <>
      <Flex style={{ flex: 1 }}>
        <Flex row className={classes.header}>
          <span className={classes.label}>상품분류</span>
          <Grid item xs={6} className={classes.gridHeaderRow}>
            <Button
              onClick={() => categoryAdd()}
              label="추가"
              className={classes.bt1}
              style={{ width: "80px", "margin-right": "10px" }}
            />
            <Button label="삭제" onClick={() => categoryDelete()} />
          </Grid>
        </Flex>
        <Flex className={classes.list}>
          <table className={classes.table} cellPadding="0" cellSpacing="0">
            <tr>
              <th>순서</th>
              <th>No</th>

              <th>세분류</th>
              <th>수정</th>
              <th>
                <Checkbox />
              </th>
            </tr>
            {categorydata.map((x, i) => {
              return (
                <>
                  <SubCategoryRows
                    subcategoryid={subcategoryid}
                    rows={x}
                    i={i}
                    setcategoryreser={setcategoryreser}
                    categoryselectfunc={categoryselectfunc}
                    before={categorydata[i - 1]}
                    after={categorydata[i + 1]}
                  />
                </>
              );
            })}
          </table>
        </Flex>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
