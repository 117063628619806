import React from "react";
import { useHistory } from "react-router";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";

export default function ServiceUseGuide({}) {
  const history = useHistory();
  return (
    <>
      <PageLabel
        add={{
          title: "등록하기",
          onClick: () => {
            history.push(routes.serviceUseGuideDetail);
          },
        }}
      >
        이용가이드 관리
      </PageLabel>
      <DataTable
        onClick={() => {
          history.push(routes.serviceUseGuideDetail);
        }}
        data={[{}, {}, {}, {}, {}]}
        columns={[{ label: "제목" }, { label: "조회수" }]}
      />
    </>
  );
}
