import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React from "react";
import styled from "styled-components";
import KendoButton from "../../components/button/KendoButton";
import Flex from "../../components/flex/Flex";
import StyledTable from "../../components/table/StyledTable";

const SearchLayout = ({
  title = "",
  onSearch = () => {},
  onReset = () => {},
  children,
}) => {
  const handleKeyPress = (e) => {
    if (e.nativeEvent.key === "Enter") {
      onSearch();
    }
  };

  return (
    <SearchContainer>
      <PanelBar>
        <PanelBarItem expanded={true} title={title}>
          <StyledTable onKeyPress={handleKeyPress}>
            <Flex>{children}</Flex>
            <Flex className="button-section" row>
              <KendoButton themeColor="primary" onClick={onReset}>
                초기화
              </KendoButton>
              <KendoButton onClick={onSearch}>검색</KendoButton>
            </Flex>
          </StyledTable>
        </PanelBarItem>
      </PanelBar>
    </SearchContainer>
  );
};

export const SearchItem = ({ header, children }) => {
  return (
    <>
      <Flex className="header">{header}</Flex>
      <Flex className="data">{children}</Flex>
    </>
  );
};

const SearchContainer = styled(Flex)`
  * {
    z-index: auto;
  }
  .header {
    min-width: 100px;
  }
  .button-section {
    justify-content: center;
    padding: 5px 0;

    button:not(:first-child) {
      margin-left: 5px;
    }
  }
`;

export default SearchLayout;
