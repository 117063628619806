import { saveAs } from "file-saver";
import styled from "styled-components";
import { useEffect, useState, cloneElement } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { GridColumn } from "@progress/kendo-react-grid";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import fonts from "libs/fonts";
import * as APIS from "libs/apis";
import {
  numberWithCommas,
  formatTime,
  formatNumber,
  addMonth,
  subMonth,
} from "libs/utils";

import GridTable, {
  NumberCell,
  CenterCell,
} from "components/gird-table/GridTable";
import Flex from "components/flex/Flex";
import SmallTitle from "components/title/SmallTitle";
import StyledTable from "components/table/StyledTable";
import KendoButton from "components/button/KendoButton";
import StockStatus from "../components/StockStatus";
import withProgressBar from "hoc/withProgressBar";
import detailConfigs from "./detailConfigs";
import { openModal } from "redux/modal/ModalReducer";
import { useDispatch } from "react-redux";
import { actionError } from "redux/action/ActionActions";
import DateInput from "../../../components/input/DateInput";
import moment from "moment-timezone";

const InventoryStatus = () => {
  return (
    <InventoryStatusContainer>
      <SmallTitle>재고 현황</SmallTitle>
      <StockStatus />
      <InOutList />
    </InventoryStatusContainer>
  );
};

// 입출고 변수 목록
export const InoutVariables = {
  // 자산
  goldOrSilver: {
    GOLD: "금",
    SILVER: "은",
  },
  // 처리유형
  bizTypes: {
    MATERIALS: "재질분류",
    RAWS_MODIFY: "재질분류수정",
    REFINE: "정련입고",
    REFINE_MODIFY: "정련입고수정",
    GOODS_ORDER: "상품제작주문",
    GOODS_DISCARD: "상품회수",
    EVENT: "이벤트",
  },
  // 입/출고
  rawHistoryType: {
    STOCKED: "입고",
    DELIVERY: "출고",
  },
  // 재고 유형
  inventoryType: {
    INCOME: "손익",
    FIDUCIARY: "수탁자산",
  },
  // 재질분류유형
  itemType: {
    RAW_MATERIALS: "원재료",
    INCOME: "손익",
  },
  // 순도
  purityType: {
    TWENTY_FOUR_KARATS: "24K",
    ETC: "기타",
  },
  // 입고상태
  stockStatus: {
    STOCKED_WAIT: "입고대기",
    STOCKED_PART: "부분입고",
    STOCKED_COMPLETE: "입고완료",
  },
  // 회수구분(원재료/정련)
  discardGoodsType: {
    RAW_MATERIALS: "원재료",
    REFINE: "정련",
  },
  // 정련출고 상태
  refineDeliveryStatus: {
    TAKE_WAIT: "출고대기",
    TAKE_COMPLETE: "출고완료",
    STOCKED_WAIT: "입고대기",
    STOCKED_PART: "부분입고",
    STOCKED_COMPLETE: "입고완료",
    STOCKED_MODIFY: "입고수정",
  },
};

// 주어진 객체에서 값으로 키를 찾는 함수
const findKeyByValue = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

const transformList = (data) => {
  return data?.map((item) => ({
    ...item,
    goldOrSilver:
      InoutVariables.goldOrSilver[item.goldOrSilver] || item.goldOrSilver,
    itemType: InoutVariables.itemType[item.itemType] || item.itemType,
    inventoryType:
      InoutVariables.inventoryType[item.inventoryType] || item.inventoryType,
    bizType: InoutVariables.bizTypes[item.bizType] || item.bizType,
    rawHistoryType: InoutVariables.rawHistoryType[item.rawHistoryType] || null,
  }));
};

const transformDetail = (data) => {
  return data?.map((item) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
      if (key === "userName" || key === "userId") {
        acc["user"] = `${item.userName}(${item.userId})`;
      } else if (key === "id" && value === null) {
        acc[key] = null; // bugfix 0417: id가 null일 때, '-'로 치환하면 setter 함수 동작할 때 data가 제대로 변경되지 않음.
      } else if (value === null || value === undefined) {
        acc[key] = "-";
      } else if (InoutVariables[key]) {
        acc[key] = InoutVariables[key][value] || value;
      } else if (
        typeof value === "string" &&
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)
      ) {
        acc[key] = value.replace("T", " ");
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  });
};

export const AllToNull = (filter) => {
  const params = { ...filter };

  for (const key of Object.keys(params)) {
    if (Object.prototype.hasOwnProperty.call(InoutVariables, key)) {
      const allSelected = Object.keys(InoutVariables[key]).every((innerKey) => {
        return params[key].includes(innerKey);
      });

      if (allSelected) {
        delete params[key];
      }
    }
  }

  return params;
};

// 입출고 내역 목록 조회
const fetchInventoryPresentDaily = async (param) => {
  for (const key of Object.keys(param)) {
    if (Object.prototype.hasOwnProperty.call(InoutVariables, key)) {
      const allSelected = Object.keys(InoutVariables[key]).every((innerKey) => {
        return param[key].includes(innerKey);
      });

      if (allSelected) {
        delete param[key];
      }
    }
  }

  const res = await APIS.getInventoryPresentDaily(param);
  return transformList(res?.data?.data);
};

// 입출고 내역 상세 조회
const fetchInventoryPresentDailyDetail = async (type, param) => {
  const paramCopy = { ...param };
  const data = AllToNull(paramCopy);
  delete data.bizTypes;

  const res = await APIS.getInventoryPresentDailyDetail(type, data);
  return transformDetail(res?.data?.data);
};

const fetchData = async (progressBar, setInoutList, param = {}) => {
  progressBar.on();
  try {
    const data = AllToNull(param);
    const inoutData = await fetchInventoryPresentDaily(data);
    setInoutList(inoutData);
  } finally {
    progressBar.off();
  }
};

// 처리일자 선택 필터
const DateFilter = ({ handleDateChange, startDate, endDate }) => {
  return (
    <FilterItemWrap>
      <FilterTitle>처리일자</FilterTitle>
      <Flex row>
        <DateInput
          value={startDate}
          onChange={(e) => handleDateChange("startDate", e)}
          max={formatTime()}
        />
        ~
        <DateInput
          value={endDate}
          onChange={(e) => handleDateChange("endDate", e)}
          min={startDate}
        />
      </Flex>
    </FilterItemWrap>
  );
};

// 드롭다운 필터
const DropdownFilter = ({ title, data, onChange, currentFilter }) => {
  const bizTypeLabels =
    currentFilter?.bizTypes?.map((key) => InoutVariables["bizTypes"][key]) ||
    [];
  const [value, setValue] = useState(bizTypeLabels || []);

  useEffect(() => {
    const newBizTypeLabels =
      currentFilter?.bizTypes?.map((key) => InoutVariables["bizTypes"][key]) ||
      [];
    setValue(newBizTypeLabels);
  }, [currentFilter]);

  const handleChange = (event) => {
    const selectedValues = event.value;
    const selectedKeys = selectedValues
      ?.map((selectedValue) => {
        const selectedKey = findKeyByValue(
          InoutVariables["bizTypes"],
          selectedValue
        );

        if (!selectedKey) {
          console.error(`value에 대한 Key 없음: ${selectedValue}`);
          return null;
        }

        return selectedKey;
      })
      .filter(Boolean);

    setValue(selectedValues);
    onChange(selectedKeys);
  };

  const itemRender = (li, itemProps) => {
    const isSelected = itemProps.selected;
    const itemChildren = (
      <span style={{ color: isSelected ? "inherit" : "#000" }}>
        {li.props.children}
      </span>
    );
    return cloneElement(
      li,
      {
        ...li.props,
        style: {
          backgroundColor: isSelected ? "#424242" : "inherit",
          ...li.props.style,
        },
      },
      itemChildren
    );
  };

  return (
    <FilterItemWrap>
      <FilterTitle>{title}</FilterTitle>
      <MultiSelectDropdown
        data={data}
        value={value}
        onChange={handleChange}
        placeholder="전체 (목록만 적용)"
        itemRender={itemRender}
        style={{ minWidth: "150px" }}
      />
    </FilterItemWrap>
  );
};

// 버튼 필터
const ButtonFilter = ({ title, selectedKey, onChange, currentFilter }) => {
  const buttons = Object.values(InoutVariables[selectedKey]);

  const isSelected = (btn) => {
    const keyForBtn = Object.keys(InoutVariables[selectedKey]).find(
      (key) => InoutVariables[selectedKey][key] === btn
    );

    return currentFilter?.[selectedKey]?.includes(keyForBtn);
  };

  return (
    <FilterItemWrap>
      <FilterTitle>{title}</FilterTitle>
      <ButtonWrap row>
        {buttons?.map((btn) => (
          <ToggleBtn
            key={btn}
            className={isSelected(btn) ? "k-selected" : ""}
            onClick={() => onChange(btn)}
          >
            {btn}
          </ToggleBtn>
        ))}
      </ButtonWrap>
    </FilterItemWrap>
  );
};

const InOutList = withProgressBar(({ progressBar }) => {
  const ONE_MONTH = 31;
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setDate(today.getDate() - ONE_MONTH);
  const initialFilter = {
    startDate: formatTime(oneMonthAgo),
    endDate: formatTime(today),
  };
  const [inoutList, setInoutList] = useState([]);
  const [filter, setFilter] = useState(initialFilter); // 실제 검색 조건

  const totalInventoryWeight = inoutList?.reduce((sum, item) => {
    const weight = item.inventoryWeight ?? 0;
    return sum + weight;
  }, 0);

  useEffect(() => {
    fetchData(progressBar, setInoutList, filter);
  }, []);

  useEffect(() => {
    fetchData(progressBar, setInoutList, filter);
  }, [filter]);

  // 초기화
  const handleClickReset = () => {
    setFilter(initialFilter); // 필터 초기화
    fetchData(progressBar, setInoutList, initialFilter);
  };

  // 필터 Change 함수
  const ChangeHandler = (filterName) => {
    return (selectedValue) => {
      const selectedKey = findKeyByValue(
        InoutVariables[filterName],
        selectedValue
      );

      if (!selectedKey) {
        console.error(`value에 대한 Key 없음: ${selectedValue}`);
        return;
      }

      setFilter((prev) => {
        const existingValues = prev[filterName] || [];
        const updatedValues = existingValues.includes(selectedKey)
          ? existingValues.filter((value) => value !== selectedKey) // 선택된 값 제거
          : [...existingValues, selectedKey]; // 선택된 값 추가

        return {
          ...prev,
          [filterName]: updatedValues,
        };
      });
    };
  };

  const handleDateChange = (key, dateString) => {
    setFilter((prev) => {
      const newFilter = { ...prev, [key]: dateString };
      if (key === "startDate") {
        const adjustedEndDate = addMonth(dateString);
        if (
          moment(prev.endDate).isBefore(moment(dateString)) ||
          moment(prev.endDate).diff(moment(dateString), "months") >= 1
        ) {
          newFilter.endDate = adjustedEndDate;
        }
      } else if (key === "endDate") {
        const adjustedStartDate = subMonth(dateString);
        if (
          moment(prev.startDate).isAfter(moment(dateString)) ||
          moment(dateString).diff(moment(prev.startDate), "months") >= 1
        ) {
          newFilter.startDate = adjustedStartDate;
        }
      }
      return newFilter;
    });
  };

  const handleBizTypeChange = (bizTypeKey) => {
    setFilter((prev) => ({
      ...prev,
      bizTypes: bizTypeKey,
    }));
  };

  return (
    <InoutListContainer>
      <SmallTitle>입출고 내역 목록</SmallTitle>
      <FilterLine>
        <FilterWrap row>
          <DateFilter
            startDate={filter.startDate}
            endDate={filter.endDate}
            handleDateChange={handleDateChange}
          />
          <ButtonFilter
            currentFilter={filter}
            title="자산"
            selectedKey="goldOrSilver"
            onChange={ChangeHandler("goldOrSilver")}
          />
          <ButtonFilter
            currentFilter={filter}
            title="입/출고"
            selectedKey="rawHistoryType"
            onChange={ChangeHandler("rawHistoryType")}
          />
          <ButtonFilter
            currentFilter={filter}
            title="재고유형"
            selectedKey="inventoryType"
            onChange={ChangeHandler("inventoryType")}
          />
          <DropdownFilter
            title="처리유형"
            data={Object.values(InoutVariables["bizTypes"])}
            onChange={handleBizTypeChange}
            currentFilter={filter}
          />
          <KendoButton
            className="reset"
            themeColor="primary"
            fillMode="flat"
            onClick={handleClickReset}
            label="초기화"
          />
        </FilterWrap>
        <Flex row>{/* <KendoButton icon="excel" label="Excel" /> */}</Flex>
      </FilterLine>
      <SummaryLine>
        <SummaryTable>
          <Flex row>
            <div className="header">총 주문</div>
            <div className="data">{numberWithCommas(inoutList.length)} 건</div>
            <div className="header">총 중량</div>
            <div className="data">{formatNumber(totalInventoryWeight)} g</div>
          </Flex>
        </SummaryTable>
      </SummaryLine>
      <GridTable
        id="inout-grid"
        className="inout-grid"
        name="inout-grid"
        data={inoutList}
        scrollable={true}
        resizable={false}
        style={{ width: "100%", height: "300px" }}
      >
        <GridColumn title="처리일자" field="completeDate" cell={CenterCell} />
        <GridColumn title="자산" field="goldOrSilver" cell={CenterCell} />
        <GridColumn title="입/출고" field="rawHistoryType" cell={CenterCell} />
        <GridColumn
          title="재고유형(수탁/손익)"
          field="inventoryType"
          cell={CenterCell}
        />
        <GridColumn title="처리유형" field="bizType" cell={CenterCell} />
        <GridColumn
          title="중량"
          field="inventoryWeight"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="건수"
          field="count"
          cell={(props) => {
            return <NumberCell {...props} />;
          }}
        />
      </GridTable>
      <DetailedList filter={filter} />
    </InoutListContainer>
  );
});

export default InventoryStatus;

const DetailedList = withProgressBar(({ progressBar, filter }) => {
  const bizTypeKeys = Object.keys(detailConfigs);
  const [selected, setSelected] = useState(0);
  const [selectedBizType, setSelectedBizType] = useState(bizTypeKeys[0]); // 파라미터로 보내는 처리유형
  const [detailList, setDetailList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDetail = async () => {
      progressBar.on();
      try {
        const detailData = await fetchInventoryPresentDailyDetail(
          selectedBizType,
          filter
        );
        setDetailList(detailData);
      } finally {
        progressBar.off();
      }
    };
    fetchDetail();
  }, [selectedBizType, filter]);

  const handleSelect = (e) => {
    setSelected(e.selected);
    const selectedBizTypeKey = bizTypeKeys[e.selected];
    if (selectedBizTypeKey) {
      setSelectedBizType(selectedBizTypeKey);
    }
  };

  const aggregateWeights = (detailList, assetType) => {
    const list = detailList.filter((item) => item.assetType === assetType);
    console.log({ list });
    const weights = calculateWeights(list, (item) => item.purityType);
    console.log(weights);
    calculateAverages(weights);
    console.log(weights);
    return weights;
  };

  const calculateWeights = (list, keySelector) => {
    return list.reduce((acc, item) => {
      const key = keySelector(item);
      if (!acc[key]) {
        acc[key] = { stocked: 0, balance: 0, totalRate: 0, count: 0 };
      }
      acc[key].stocked += parseFloat(item.stockedWeightGram) || 0;
      acc[key].balance += parseFloat(item.balanceWeightGram) || 0;
      acc[key].totalRate += parseFloat(item.returnRate) || 0;
      acc[key].count += 1;
      return acc;
    }, {});
  };

  const calculateAverages = (weights) => {
    Object.values(weights).forEach((weight) => {
      if (weight.count > 0) {
        weight.averageRate = weight.totalRate / weight.count;
      }
    });
  };

  const WeightsTable = ({ data, title }) => (
    <>
      <SummaryModalTitle>{title}</SummaryModalTitle>
      <WeightStyledTable>
        <thead>
          <tr>
            <th className="td-c">{title === "금" ? "순도" : "품목"}</th>
            <th className="td-c">입고중량</th>
            <th className="td-c">최종잉여잔량</th>
            <th className="td-c">최종회수율</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(
            ([key, { stocked, balance, averageRate }], index) => (
              <tr key={index}>
                <td>{key}</td>
                <td className="td-r">{formatNumber(stocked)}</td>
                <td className="td-r">{formatNumber(balance)}</td>
                <td className="td-r">{formatNumber(averageRate)}</td>
              </tr>
            )
          )}
        </tbody>
      </WeightStyledTable>
    </>
  );

  const handleAggregate = () => {
    const goldWeights = aggregateWeights(detailList, "금");
    const silverWeights = aggregateWeights(detailList, "은");
    dispatch(
      openModal({
        visible: true,
        children: (
          <SummaryModal>
            <WeightsTable data={goldWeights} title="금" />
            <WeightsTable data={silverWeights} title="은" />
          </SummaryModal>
        ),
      })
    );
  };

  const handleClickExcel = async () => {
    const paramCopy = { ...filter };
    const data = AllToNull(paramCopy);
    delete data.bizTypes;

    await APIS.getInventoryPresentDailyDetailExcel(selectedBizType, data).then(
      (response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `처리유형별 세부목록_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      }
    );
  };

  return (
    <DetailedContainer>
      <SmallTitle>처리유형별 세부 목록</SmallTitle>
      <TabContainer selected={selected} onSelect={handleSelect}>
        {Object.entries(detailConfigs).map(([key, value], i) => {
          const title = value.title || "";
          return (
            <TabStripTab key={`tabStrip-${i}`} title={title}>
              <div>
                {value?.innerTabs ? (
                  value?.innerComponents({ detailList, filter })
                ) : (
                  <>
                    <SummaryLine>
                      <DetailSummaryTable>
                        <Flex row>
                          <div className="header">총 주문</div>
                          <div className="data">
                            {numberWithCommas(detailList?.length)} 건
                          </div>
                          {value.summaries?.map((summary, index) => (
                            <Flex key={index} row>
                              <div className="header">{summary.header}</div>
                              <div className="data">
                                {numberWithCommas(
                                  summary.calculate(detailList)
                                )}
                              </div>
                            </Flex>
                          ))}
                        </Flex>
                      </DetailSummaryTable>
                      {/* <KendoButton
                          icon="excel"
                          label="Excel"
                          onClick={() => handleClickExcel()}
                        /> */}
                      {value?.showSumBtn ? (
                        <SumBtn
                          label="순도, 품목별 중량합"
                          onClick={() => handleAggregate()}
                        />
                      ) : null}
                    </SummaryLine>
                    <DetailGridTable
                      className="detail-grid"
                      data={detailList}
                      scrollable={true}
                      sortable={true}
                      style={{ width: "100%", height: "500px" }}
                    >
                      {value?.details?.map((col, idx) => {
                        return (
                          <GridColumn
                            key={`selectedKey-${idx}`}
                            title={col?.title}
                            field={col?.field}
                            cell={col?.cell}
                            width={col?.width}
                          />
                        );
                      })}
                    </DetailGridTable>
                  </>
                )}
              </div>
            </TabStripTab>
          );
        })}
      </TabContainer>
    </DetailedContainer>
  );
});

const InventoryStatusContainer = styled(Flex)`
  flex: 1;
  padding: 20px 20px 40px;
`;

const InoutListContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  .inout-grid {
    margin-top: 10px;
  }
`;

const FilterItemWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  margin-right: 8px;
  background-color: #fafafa;
  border-radius: 8px;
  min-width: "fit-content";
`;

const FilterTitle = styled.div`
  font-size: 14px;
  font-weight: ${fonts.notoSansKRMedium};
  color: #222222;
  margin-bottom: 4px;
`;

const FilterLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const FilterWrap = styled.div`
  width: 100%;
  display: flex;
`;

const SummaryLine = styled(FilterLine)``;

const SummaryTable = styled(StyledTable)`
  width: 35%;
  height: 32px;
  margin-right: 10px;
`;

const ButtonWrap = styled.div`
  display: flex;
  > button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ToggleBtn = styled(Button)`
  width: 56px;
  height: 24px;
  &.k-button-solid-base.k-selected {
    border-color: #424242;
    background-color: #424242;
  }
`;

const DetailedContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const MultiSelectDropdown = styled(MultiSelect)`
  width: 100%;
`;

const DetailSummaryTable = styled(StyledTable)`
  margin-right: 10px;
`;

const TabContainer = styled(TabStrip)`
  margin-top: 20px;
  width: 100%;

  .k-animation-container.k-animation-container-relative {
    width: 100%;
  }
`;

const DetailGridTable = styled(GridTable)`
  margin-top: 10px;
`;

const SumBtn = styled(KendoButton)`
  min-width: fit-content;
`;

const SummaryModal = styled.div`
  width: 100%;
`;

const SummaryModalTitle = styled.div`
  font-size: 15px;
`;

const WeightStyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 5px 0 20px;
  font-size: 15px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
  }
  th {
    background-color: #f2f2f2;
    font-weight: 400;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  tr:hover {
    background-color: #f1f1f1;
  }
`;
