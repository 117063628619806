import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { closeDialog } from "../../redux/dialog-alert/DialogAlertReducer";

const DialogAlert = () => {
  const state = useSelector((state) => state.dialogAlert);
  const {
    //type = "confirm",
    title = "알림",
    message = "",
    action = "",
    show = false,
  } = state;
  const dispatch = useDispatch();

  const toggleDialog = () => {
    dispatch(closeDialog());
  };

  return (
    show && (
      <Dialog title={title} onClose={toggleDialog}>
        <div
          style={{
            width: "400px",
            height: "150px",
            userSelect: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: "500" }}>{message}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {action && (
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={action}
              style={{ width: "100px", marginRight: "5px" }}
            >
              확인
            </button>
          )}
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            style={{ width: "100px" }}
            onClick={toggleDialog}
          >
            닫기
          </button>
        </div>
        {/* DialogActionsBar 버전 업데이트로 인한 css 오류 */}
        {/* <DialogActionsBar>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {action && (
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={action}
                  style={{ width: "100px", marginRight: "5px" }}
                >
                  확인
                </button>
              )}
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                style={{ width: "100px" }}
                onClick={toggleDialog}
              >
                닫기
              </button>
            </div>
          </DialogActionsBar> */}
      </Dialog>
    )
  );
};

export default DialogAlert;
