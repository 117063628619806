import { ButtonBase, InputBase, makeStyles, Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import PageLabel from "../../components/page-label/PageLabel";
import { actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";

export default function ServiceFaqCategory({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const [faqCategoryList, setFaqCategoryList] = useState([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getFaqCategoryList()
      .then(({ data: { success, data } }) => {
        if (success) {
          setFaqCategoryList(data);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const validationData = (name) => {
    if (!name) {
      return "제목을 입력하여 주세요";
    }
    return "";
  };

  const registerCategory = (e) => {
    const { name } = e.target;
    const categoryName = faqCategoryList[name]?.name || "";
    const errorMessage = validationData(categoryName);
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    dispatch(loadingStart);
    APIS.postRegisterFaqCategory({ name: categoryName })
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "FAQ 카테고리 등록에 실패하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "FAQ 카테고리 등록이 완료되었습니다.",
              () => {
                fetchList();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const editCategory = (e) => {
    const { name, value } = e.target;

    const categoryName = faqCategoryList[name]?.name || "";
    const errorMessage = validationData(categoryName);
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    dispatch(loadingStart);
    APIS.postRegisterFaqCategory({ id: value, name: categoryName })
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "FAQ 카테고리 수정에 실패하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "FAQ 카테고리 수정이 완료되었습니다.",
              () => {
                fetchList();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const putChageStatusFaqCategory = (id, status) => {
    dispatch(loadingStart);

    const changeStatus = status === "BLIND" ? "ACTIVE" : "BLIND";
    const param = {
      id: id,
      faqStatus: changeStatus,
    };
    const paramQuery = objToQueryStr(param);
    APIS.putChageStatusFaqCategory(paramQuery)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "해당 카테고리의 상태를 변경하지 못하였습니다.",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "FAQ 카테고리가 상태가 변경되었습니다.",
              () => {
                setFaqCategoryList(data);
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const addItem = () => {
    const arr = faqCategoryList.concat({ name: "" });
    setFaqCategoryList(arr);
  };

  const dropData = (i, faqCategoryList) => {
    let selectData = {};
    let replacementData = {};
    faqCategoryList.map((item, index) => {
      index === i ? (selectData = item) : "";
      index === i + 1 ? (replacementData = item) : "";
    });
    const data = [];
    data.push(selectData);
    data.push(replacementData);

    dispatch(loadingStart);
    APIS.postChangePositionFaqCategory(data)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "FAQ 카테고리 순서를 변경하지 못하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          fetchList();
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const raiseData = (i, faqCategoryList) => {
    let selectData = {};
    let replacementData = {};
    faqCategoryList.map((item, index) => {
      index === i ? (selectData = item) : "";
      index === i - 1 ? (replacementData = item) : "";
    });
    const data = [];
    data.push(selectData);
    data.push(replacementData);

    dispatch(loadingStart);
    APIS.postChangePositionFaqCategory(data)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "FAQ 카테고리 순서를 변경하지 못하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          fetchList();
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  return (
    <>
      <PageLabel>FAQ 분류 관리</PageLabel>

      <Button
        variant="outlined"
        style={{ alignSelf: "start", margin: 50, marginBottom: 0 }}
        onClick={addItem}
      >
        항목 추가
      </Button>

      <table cellPadding={10} className={classes.table}>
        <tbody>
          {faqCategoryList.map((item, i) => {
            return (
              <tr key={i.toString()}>
                <td style={{width: "10%"}}>
                  <Button
                    style={{
                      border: "1px solid rgba(0,0,0,0.23)",
                      backgroundColor: "#f2f2f2",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      raiseData(i, faqCategoryList);
                    }}
                    disabled={i === 0}
                  >
                    <ArrowDropUp />
                  </Button>
                </td>
                <td style={{width: "10%"}}>
                  <Button
                    style={{
                      border: "1px solid rgba(0,0,0,0.23)",
                      backgroundColor: "#f2f2f2",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      dropData(i, faqCategoryList);
                    }}
                    disabled={i === faqCategoryList.length - 1}
                  >
                    <ArrowDropDown />
                  </Button>
                </td>
                <td colSpan={5} style={{ padding: "0px 20px" }}>
                  <input
                    className={classes.input}
                    placeholder="대분류 제목"
                    value={item?.name}
                    disabled={item.status === "BLIND" ? true : false}
                    onChange={(e) => {
                      const { value } = e.target;
                      const copyList = [...faqCategoryList];
                      copyList[i] = {
                        ...copyList[i],
                        name: value,
                      };
                      setFaqCategoryList(copyList);
                    }}
                  />
                </td>
                {item.id ? (
                  <>
                    <td>
                      <ButtonBase
                        className={classes.button1}
                        value={item.id}
                        name={i}
                        onClick={editCategory}
                      >
                        수정
                      </ButtonBase>
                    </td>
                    <td className={classes.buttonTd}>
                      <Button
                        className={classes.button2}
                        variant="outlined"
                        disabled={item.status === "BLIND" ? false : true}
                        onClick={() => {
                          putChageStatusFaqCategory(item.id, item.status);
                        }}
                      >
                        활성화
                      </Button>
                    </td>
                    <td className={classes.buttonTd}>
                      <Button
                        className={classes.button2}
                        variant="outlined"
                        disabled={item.status === "BLIND" ? true : false}
                        onClick={() => {
                          putChageStatusFaqCategory(item.id, item.status);
                        }}
                      >
                        블라인드
                      </Button>
                    </td>
                  </>
                ) : (
                  <td>
                    <ButtonBase
                      className={classes.button1}
                      name={i}
                      onClick={registerCategory}
                    >
                      저장
                    </ButtonBase>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const useStyle = makeStyles({
  table: {
    marginRight: "50%",
    margin: "20px 50px",
  },
  input: {
    padding: " 0px 10px",
    border: "1px solid #ddd",
    alignSelf: "stretch",
    flex: 1,
    height: 40,
    width: "100%",
  },
  button1: {
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    width: "100%",
    height: 35,
    borderRadius: 5,
    padding: "7px",
  },
  button2: {
    textAlign: "center",
    width: "100%",
    padding: "5px",
  },
  buttonTd: {
    width: 105,
  },
});
