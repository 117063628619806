import axios from "axios";
import consts, { STORAGE_KEY } from "../../libs/consts";
import { actionOpen, actionError } from "../action/ActionActions";
import * as APIS from "../../libs/apis";

export const userActionType = {
  update: "user/update",
  init: "user/init",
  logout: "user/logout",
};
export const userSignOut = (dispatch) => {
  localStorage.clear(STORAGE_KEY.accessToken);
  dispatch({ type: userActionType.logout });
};

export const userSignIn = (account, password) => (dispatch) => {
  if (!account || !password) {
    dispatch(actionError("아이디와 비밀번호를 입력해 주세요."));
  } else {
    APIS.postSignIn({ account, password })
      .then(({ data: { authToken } }) => {
        localStorage.setItem(STORAGE_KEY.accessToken, authToken);
        dispatch(userInit);
      })
      .catch(() => {
        dispatch(actionError("로그인에 실패하였습니다."));
      });
  }
};

export const userInit = (dispatch) => {
  const token = localStorage.getItem(STORAGE_KEY.accessToken);

  if (token) {
    APIS.setAuthorization(token, dispatch);
    dispatch({
      type: userActionType.update,
      user: { signed: true, init: true },
    });
  }
};

export const userUpdate = (user) => (dispatch) => {
  dispatch({ type: userActionType.update, user });
};
