import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen } from "../../redux/action/ActionActions";
import Filters from "../../components/filters/Filters";
import { getDefaultPageSize } from "../../libs/consts";

export default function ServiceFaq({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [totalCount, setTotalCount] = useState(0);
  const [faqList, setFaqList] = useState([]);
  const [faqCategoryList, setFaqCategoryList] = useState([]);
  const [faqFixedList, setFaqFixedList] = useState([]);
  const { state = {} } = location;
  const {} = state;

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        ...getDefaultPageSize(),
      });
    }
  }, [state.tab]);

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    const paramQuery = objToQueryStr({
      page: state.page,
      size: state.size,
      faqCategoryId: state.categoryStatus,
    });
    dispatch(loadingStart);
    APIS.getFaqList(paramQuery)
      .then(({ data: { success, data, totalElements } }) => {
        if (success) {
          const faqContent = [...data.faqResponseDtoPage.content] || [];
          const changeFaqContent = faqContent.map((item) => {
            return { ...item, name: item?.["faqCategory"]?.name || "" };
          });
          const faqFixedContent = [...data.faqFixedDtoList] || [];
          const changeFaqFixedContent = faqFixedContent.map((item, index) => {
            return {
              ...item,
              name: item?.["faqCategory"]?.name || "",
              index: index + 1,
            };
          });
          setFaqList(changeFaqContent);
          setFaqCategoryList(data.faqCategoryList);
          setFaqFixedList(changeFaqFixedContent);
          setTotalCount(data.faqResponseDtoPage.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const validationData = () => {
    if (state.selected.length === 0) {
      return "목록을 선택해주세요.";
    }
    return "";
  };

  const handleChangeStatus = (value) => {
    if (state.selected.length) {
      dispatch(loadingStart);
      const copySelected = [...state.selected];
      let data = [];
      copySelected.map((item) => {
        let temp = { id: item, status: value };
        data.push(temp);
      });

      APIS.postFaqStatus(data)
        .then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(
              actionOpen("상태를 바꾸지 못하였습니다", null, null, null, true)
            );
          } else {
            dispatch(
              actionOpen(
                "해당 아이템의 상태를 바꾸었습니다",
                () => {
                  history.go(0);
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    } else {
      const errorMessage = validationData();
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }
  };

  const newList = faqCategoryList.map((item) => {
    return { label: item.name, value: item.id };
  });
  return (
    <>
      <PageLabel
        fixed={{
          title: "자주하는 질문",
          value: "FIXED",
          onClick: () => {
            handleChangeStatus("FIXED");
          },
        }}
        active={{
          title: "활성화",
          value: "ACTIVE",
          onClick: () => {
            handleChangeStatus("ACTIVE");
          },
        }}
        blind={{
          title: "블라인드",
          value: "BLIND",
          onClick: () => {
            handleChangeStatus("BLIND");
          },
        }}
        add={{
          title: "등록하기",
          onClick: () => {
            history.push(routes.serviceFaqDetail, {
              faqCategoryList: faqCategoryList,
            });
          },
        }}
      >
        FAQ
      </PageLabel>
      <Filters
        defaultState={{ ...getDefaultPageSize() }}
        onSearch={fetchList}
        type="categorySearch"
        data={[
          {
            data: newList,
            type: "menu",
            label: "카테고리 분류",
            // wrap: true,
            key: "categoryStatus",
          },
        ]}
      />
      <DataTable
        onClick={(item) => {
          history.push(routes.serviceFaqDetail, {
            id: item.id,
            faqCategoryList: faqCategoryList,
          });
        }}
        onChangeCheckbox={(e) => {
          e.stopPropagation();
        }}
        pageable={false}
        data={faqFixedList}
        title="자주하는 질문"
        useCheckbox
        isFixed
        columns={[
          { label: "NO", key: "index" },
          { label: "상태", key: "status" },
          { label: "분류", key: "name" },
          { label: "자주하는 질문", key: "title" },
          { label: "작성일", key: "createdAt", type: "date" },
          { label: "작성자", key: "createdByName" },
        ]}
        rowClassName="pointer"
      />
      <DataTable
        onClick={(item) => {
          history.push(routes.serviceFaqDetail, {
            id: item.id,
            faqCategoryList: faqCategoryList,
          });
        }}
        onChangeCheckbox={(e) => {
          e.stopPropagation();
        }}
        data={faqList}
        totalCount={totalCount}
        useCheckbox
        columns={[
          { label: "상태", key: "status" },
          { label: "분류", key: "name" },
          { label: "자주하는 질문", key: "title" },
          { label: "작성일", key: "createdAt", type: "date" },
          { label: "작성자", key: "createdByName" },
        ]}
        rowClassName="pointer"
      />
    </>
  );
}
