import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Flex from "components/flex/Flex";
import Text from "components/text/Text";
import Button from "components/button/Button";
import KendoButton from "components/button/KendoButton";
import RenderInput from "components/render-input/RenderInput";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import fonts from "libs/fonts";
import consts from "libs/consts";
import * as APIS from "libs/apis";
import { objToQueryStr } from "services/utils";
import { assets, filterKey } from "constants/index";
import { Switch } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function AppraisalProduct({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [selectData, setSelectData] = useState({});
  const [tab, setTab] = useState(0);
  const [type, setType] = useState(assets[filterKey(assets)[tab]].type);
  const [name, setName] = useState("");
  const [purity, setPurity] = useState("");
  const [qualityMaterial, setQualityMaterial] = useState("");
  const [weightConversionRate, setWeightConversionRate] = useState("");
  const [appraisalFeeRate, setAppraisalFeeRate] = useState("");
  const [isRateModifyAllowed, setIsRateModifyAllowed] = useState(false);
  const [isUserProductAllowed, setIsUserProductAllowed] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [guideFile, setGuideFile] = useState();
  const imageRef = useRef();
  const guideRef = useRef();
  const appraisalFeeRateInputRef = useRef();
  const [product, setProduct] = useState([]);
  const [purityTypeGoldList, setPurityTypeGoldList] = useState([]);
  const [purityTypeSilverList, setPurityTypeSilverList] = useState([]);
  const qualityMaterialList = [
    { key: "GOODS", text: "상품" },
    { key: "RAW_MATERIALS", text: "원재료" },
    { key: "REFINE", text: "정련" },
  ];

  useEffect(() => {
    APIS.getPurityType().then(({ data: { success, data, message } }) => {
      if (success) {
        setPurityTypeGoldList(
          data
            .find((item) => item.assetType === "GOLD")
            .purityTypeList.map(({ purityType, purityTypeName }) => {
              return { key: purityType, text: purityTypeName };
            })
        );
        setPurityTypeSilverList(
          data
            .find((item) => item.assetType === "SILVER")
            .purityTypeList.map(({ purityType, purityTypeName }) => {
              return { key: purityType, text: purityTypeName };
            })
        );
      } else {
        dispatch(actionError(message));
      }
    });
  }, []);

  useEffect(() => {
    const filterAssetKey = filterKey(assets);
    const type = assets[filterAssetKey[tab]].type;
    fetchProduct(type);
  }, [tab]);

  // useEffect(() => {
  //   const type = filterKey(assets);
  //   const parseType = assets[type[tab]].type;
  //   fetchProduct(parseType);
  // }, [tab]);

  useEffect(() => {
    if (selectData?.id) {
      setType(selectData.assetType);
      setName(selectData.name);
      setPurity(
        (type === "GOLD"
          ? purityTypeGoldList
          : type === "SILVER"
          ? purityTypeSilverList
          : purityTypeGoldList
        ).find((item) => item.key === selectData.purityType) || ""
      );
      setQualityMaterial(
        qualityMaterialList.find((item) => item.key === selectData.itemType) ||
          ""
      );
      setWeightConversionRate(selectData.weightRatePct);
      setAppraisalFeeRate(selectData.appraisalFeeRate);
      setIsRateModifyAllowed(
        selectData.isWeightRateChange === "true" ? true : false
      );
      setIsUserProductAllowed(
        selectData.isUserProduct === "true" ? true : false
      );
      setImageFile({
        path: selectData.imageUrl,
      });
      setGuideFile({
        path: selectData.guideUrl,
      });
    }
  }, [selectData?.id]);

  const handleDelete = (type) => {
    const ids = product.filter((x) => x.checked).map((x) => x.id);
    if (ids.length) {
      dispatch(loadingStart);

      APIS.deleteAppraisalProducts({ ids: ids.join(",") })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(
            actionOpen(
              "선택하신 제품이 삭제 되었습니다.",
              null,
              null,
              null,
              true
            )
          );
          dispatch(loadingEnd);
          fetchProduct(type);
        });
    } else {
      dispatch(
        actionOpen("삭제할 제품을 선택해 주세요.", null, null, null, true)
      );
    }
  };

  const fetchProduct = (type) => {
    APIS.getAppraisalProducts(objToQueryStr({ assetType: type }))
      .then(({ data: { data } }) => {
        setProduct(data);
      })
      .catch((err) => {
        dispatch(actionError(err));
      });
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleAdd = () => {
    setSelectData({ open: true });
    initData(true);
  };

  const initData = (a) => {
    if (!a) {
      setSelectData({});
    }
    setType(assets[filterKey(assets)[tab]].type);
    setName("");
    setPurity("");
    setQualityMaterial("");
    setWeightConversionRate("");
    setAppraisalFeeRate("");
    setIsRateModifyAllowed(false);
    setIsUserProductAllowed(false);
    setImageFile("");
    setGuideFile("");
  };

  const handleSave = () => {
    let data;
    const filterAssetKey = filterKey(assets);
    const type = assets[filterAssetKey[tab]].type;
    let feeRate = parseFloat(appraisalFeeRate);
    const saved = () => {
      if (selectData?.id) {
        dispatch(
          actionOpen("선택하신 제품이 수정 되었습니다.", null, null, null, true)
        );
        fetchProduct(type);
        setSelectData({ isOpen: false });
      } else {
        dispatch(actionOpen("제품이 추가 되었습니다.", null, null, null, true));
        fetchProduct(type);
        setSelectData({ isOpen: false });
        initData();
      }
    };

    try {
      if (isUserProductAllowed === true) {
        if (!imageFile) {
          throw "제품 이미지를 등록해 주세요.";
        } else if (!guideFile) {
          throw "제품 순도 확인 가이드를 등록해 주세요.";
        }
      } else {
        if (purity === "") {
          throw "순도를 선택해 주세요.";
        } else if (qualityMaterial === "") {
          throw "재질을 선택해 주세요.";
        }
      }
      if (!name) {
        throw "품목명을 입력해 주세요.";
      }
      if (isNaN(weightConversionRate) || weightConversionRate === "") {
        throw "올바른 중량 환산율을 입력해 주세요.";
      }
      console.log(
        typeof feeRate,
        feeRate,
        isNaN(feeRate),
        appraisalFeeRateInputRef.current
      );

      if (feeRate < 0 || feeRate > 100) {
        appraisalFeeRateInputRef.current.focus();
        setAppraisalFeeRate("0");
        throw "올바른 감정평가 수수료율을 입력해 주세요.";
      }
    } catch (me) {
      dispatch(actionError(me));
      return;
    }

    const params = {
      name,
      assetType: type,
      weightRatePct: weightConversionRate,
      appraisalFeeRate: feeRate,
      isWeightRateChange: isRateModifyAllowed,
      isUserProduct: isUserProductAllowed,
      purityType: purity.key,
      itemType: qualityMaterial.key,
    };

    if (!selectData?.id) {
      params.sortOrder = product.length + 11;
    }
    const json = JSON.stringify(params);

    const blob = new Blob([json], {
      type: "application/json",
    });

    let formData = new FormData();
    formData.append("product", blob);
    imageFile && formData.append("image", imageFile);
    guideFile && formData.append("guide", guideFile);

    dispatch(loadingStart);

    const successCallback = () => {
      dispatch(loadingEnd);
      saved();
    };

    const errorCallback = (err) => {
      dispatch(loadingEnd);
      dispatch(actionError(err));
    };

    selectData?.id
      ? APIS.putAppraisalProducts(selectData.id, formData)
          .then(({ data: { data, success, message } }) => {
            if (success) {
              dispatch(loadingEnd);
              saved();
            } else {
              dispatch(loadingEnd);
              dispatch(actionError(message));
            }
          })
          .catch(errorCallback)
      : APIS.postAppraisalProducts(formData)
          .then(({ data: { data, success, message } }) => {
            if (success) {
              dispatch(loadingEnd);
              saved();
            } else {
              dispatch(loadingEnd);
              dispatch(actionError(message));
            }
          })
          .catch(errorCallback);
  };

  const updateSort = (index, action, type) => {
    const products = product;

    if (
      (action === "up" && index === 0) ||
      (action === "down" && index === products.length - 1)
    ) {
      return false;
    }

    const targetIndex = action === "up" ? index - 1 : index + 1;
    const currentItem = products[index];
    const targetItem = products[targetIndex];

    const targetData = {
      sortOrder: targetIndex,
    };
    const currentData = {
      sortOrder: index,
    };

    const targetJson = JSON.stringify(targetData);
    const cuurentJson = JSON.stringify(currentData);
    const targetBlob = new Blob([targetJson], {
      type: "application/json",
    });
    const currentBlob = new Blob([cuurentJson], {
      type: "application/json",
    });
    let formData = new FormData();
    formData.append("product", targetBlob);
    let currentFormData = new FormData();
    currentFormData.append("product", currentBlob);

    dispatch(loadingStart);
    const callApi1 = APIS.putAppraisalProducts(currentItem.id, formData);
    const callApi2 = APIS.putAppraisalProducts(targetItem.id, currentFormData);

    Promise.all([callApi1, callApi2])
      .then((e) => {
        console.log("allThen", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
        fetchProduct(type);
      });
  };

  const assetKeys = filterKey(assets);

  const onChangeTab = (_, value) => {
    setTab(value);
  };

  return (
    <Flex
      row
      style={{
        margin: "30px 0px",
      }}
    >
      <Flex style={{ flex: 1, margin: "0px 50px" }}>
        <Tabs
          value={tab}
          onChange={onChangeTab}
          aria-label="basic tabs example"
        >
          {assetKeys.map((item, index) => {
            const title = `${assets[item].title} 제품`;
            return <Tab key={index} label={title} />;
          })}
        </Tabs>
        <TabPanel
          tableData={product}
          productObj={assets[assetKeys[tab]]}
          updateSort={updateSort}
          handleDelete={handleDelete}
          setSelectData={setSelectData}
          setProduct={setProduct}
          handleAdd={handleAdd}
        />
      </Flex>
      {selectData.open && (
        <Flex style={{ flex: 1, margin: "0px 50px" }}>
          {selectData.open && (
            <Grid container spacing={2} className={classes.mainC2ContentGrid}>
              <Grid item xs={6}>
                <Text font={fonts.notoSansKRBold}>제품 상세</Text>
              </Grid>
              <Grid item xs={6} className={classes.gridHeaderRow}>
                <KendoButton
                  label="저장"
                  onClick={handleSave}
                  themeColor="primary"
                />
                <KendoButton
                  label="취소"
                  onClick={() => initData()}
                  style={{ marginLeft: 5 }}
                />
              </Grid>
              <Grid item xs={3}>
                자산
              </Grid>
              <Grid item xs={9}>
                <FormControl>
                  <RadioGroup
                    onChange={handleTypeChange}
                    row
                    value={type}
                    className={classes.radiogroup}
                  >
                    <FormControlLabel
                      style={{ marginTop: "0px !important" }}
                      label="금"
                      control={
                        <Radio
                          value="GOLD"
                          color="default"
                          disabled
                          style={{ color: "black" }}
                        />
                      }
                    />
                    <FormControlLabel
                      style={{ marginTop: "0px !important" }}
                      label="은"
                      control={
                        <Radio
                          value="SILVER"
                          color="default"
                          disabled
                          style={{ color: "black" }}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                품목
              </Grid>
              <Grid item xs={9}>
                <InputBase
                  value={name}
                  autoFocus={true}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={3}>
                순도
              </Grid>
              <Grid item xs={9}>
                <DropDownList
                  value={purity}
                  data={
                    type === "GOLD"
                      ? purityTypeGoldList
                      : type === "SILVER"
                      ? purityTypeSilverList
                      : purityTypeGoldList
                  }
                  dataItemKey="key"
                  textField="text"
                  style={{
                    width: "120px",
                    height: "35px",
                    background: "#fff",
                  }}
                  onChange={(e) => {
                    setPurity(e.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                재질
              </Grid>
              <Grid item xs={9}>
                <DropDownList
                  value={qualityMaterial}
                  data={qualityMaterialList}
                  dataItemKey="key"
                  textField="text"
                  style={{
                    width: "120px",
                    height: "35px",
                    background: "#fff",
                  }}
                  onChange={(e) => {
                    setQualityMaterial(e.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                감정평가 수수료율
              </Grid>
              <Grid item xs={9}>
                <span
                  style={{
                    border: "1px solid rgba(0,0,0,0.23)",
                    minWidth: "50%",
                    padding: "6px 10px",
                  }}
                >
                  <InputBase
                    inputRef={appraisalFeeRateInputRef}
                    type="number"
                    value={appraisalFeeRate}
                    onChange={(e) => {
                      let value = e.target.value;
                      setAppraisalFeeRate(value);
                    }}
                    onBlur={(e) => {
                      let value = parseFloat(e.target.value);

                      // 유효한 숫자인지 확인
                      if (!isNaN(value)) {
                        // 0 이상 100 이하인지 확인
                        if (value < 0 || value > 100) {
                          setAppraisalFeeRate(0);
                          dispatch(
                            actionError(
                              "감정평가 수수료율은 0 이상 100 이하이어야 합니다."
                            )
                          );
                        } else {
                          setAppraisalFeeRate(value.toFixed(4)); // 소수점 넷째 자리까지 포맷팅
                        }
                      } else {
                        setAppraisalFeeRate(0);
                        dispatch(
                          actionError(
                            "올바른 감정평가 수수료율을 입력해 주세요."
                          )
                        );
                      }
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                      step: 0.0001,
                    }}
                  />
                  %
                </span>
              </Grid>
              <Grid item xs={3}>
                중량 환산율
              </Grid>
              <Grid item xs={9}>
                <span
                  style={{
                    border: "1px solid rgba(0,0,0,0.23)",
                    minWidth: "50%",
                    padding: "6px 10px",
                  }}
                >
                  <InputBase
                    type="number"
                    value={weightConversionRate}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        value &&
                        (isNaN(value) ||
                          parseInt(value) < 1 ||
                          parseInt(value) > 100)
                      ) {
                        return;
                      }
                      setWeightConversionRate(value);
                    }}
                  ></InputBase>
                  %
                </span>
              </Grid>
              <Grid item xs={3}>
                중량 환산율 수정
                <br />
                허용 여부
              </Grid>
              <Grid item xs={9}>
                <Switch
                  defaultChecked={false}
                  checked={isRateModifyAllowed}
                  onChange={(e) => {
                    setIsRateModifyAllowed(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                사용자 제품
              </Grid>
              <Grid item xs={9}>
                <Switch
                  defaultChecked={false}
                  checked={isUserProductAllowed}
                  onChange={(e) => {
                    setIsUserProductAllowed(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                이미지
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                <div
                  onClick={() => {
                    imageRef.current?.click();
                  }}
                  className={classes.img}
                >
                  {imageFile ? (
                    <img
                      src={
                        imageFile.path
                          ? consts.s3BaseUrl + "/" + imageFile.path
                          : imageFile.uri
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Add />
                  )}
                </div>
                <RenderInput
                  onChange={setImageFile}
                  inputRef={imageRef}
                  onlyImage
                />
              </Grid>
              <Grid item xs={3}>
                제품순도 확인
                <br />
                방법 가이드
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                <div
                  onClick={() => {
                    guideRef.current?.click();
                  }}
                  className={classes.img2}
                >
                  {guideFile ? (
                    <img
                      src={
                        guideFile.path
                          ? consts.s3BaseUrl + "/" + guideFile.path
                          : guideFile.uri
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Add />
                  )}
                </div>
                <RenderInput
                  onChange={setGuideFile}
                  inputRef={guideRef}
                  onlyImage
                />
              </Grid>
            </Grid>
          )}
        </Flex>
      )}
    </Flex>
  );
}

const TabPanel = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    productObj,
    tableData,
    updateSort,
    handleDelete,
    setSelectData,
    setProduct,
    handleAdd,
  } = props;
  const title = productObj.title || "-";
  const type = productObj.type || "-";
  const data = tableData.map((item) => {
    return {
      ...item,
      isWeightRateChange: String(item.isWeightRateChange),
      isUserProduct: String(item.isUserProduct),
    };
  });
  return (
    <Flex style={{ flex: 1 }}>
      <Flex row className={classes.header}>
        <span className={classes.label}>{title}제품</span>
        <Flex row>
          <KendoButton label="제품 추가" onClick={handleAdd} />
          <KendoButton
            label="삭제"
            onClick={() => {
              dispatch(
                actionOpen(
                  "선택하신 제품을 삭제하시겠습니까?",
                  () => {
                    handleDelete(type);
                  },
                  "취소",
                  "삭제"
                )
              );
            }}
            themeColor="primary"
            style={{ marginLeft: 5 }}
          />
        </Flex>
      </Flex>
      <Flex className={classes.list}>
        <table className={classes.table} cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>순서</th>
              <th>No</th>
              <th>품목</th>
              <th>순도</th>
              <th>재질</th>
              <th>중량 환산율</th>
              <th>감정평가 수수료율</th>
              <th>중량 환산율 수정 허용 여부</th>
              <th>사용자 제품 여부</th>
              <th>수정</th>
              <th>
                <Checkbox />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((x, i) => {
              return (
                <tr key={i.toString() + x.checked}>
                  <td>
                    <ArrowDropUpOutlined
                      onClick={() => {
                        updateSort(i, "up", type);
                      }}
                    />
                    <ArrowDropDownOutlined
                      onClick={() => {
                        updateSort(i, "down", type);
                      }}
                    />
                  </td>
                  <td>{i + 1}</td>
                  <td>{x.name}</td>
                  <td>{x.purityTypeName || "-"}</td>
                  <td>
                    {x.itemType
                      ?.replace("GOODS", "상품")
                      ?.replace("RAW_MATERIALS", "원재료")
                      ?.replace("REFINE", "정련")
                      ?.replace("ETC", "기타") || "-"}
                  </td>
                  <td>{x.weightRatePct}%</td>
                  <td>{x.appraisalFeeRate}%</td>
                  <td>
                    {x.isWeightRateChange
                      ?.replace("false", "NO")
                      ?.replace("true", "YES") || "-"}
                  </td>
                  <td>
                    {x.isUserProduct
                      ?.replace("false", "NO")
                      ?.replace("true", "YES") || "-"}
                  </td>
                  <td
                    onClick={() => {
                      setSelectData({
                        ...x,
                        type: x.type,
                        open: true,
                      });
                    }}
                    style={{ cursor: "pointer", color: "red" }}
                  >
                    수정
                  </td>
                  <td>
                    <Checkbox
                      checked={x.checked}
                      onChange={(e) => {
                        const c = e.target.checked;
                        data[i].checked = c;
                        setProduct([...data]);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flex>
    </Flex>
  );
};

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  buttonWrap: {
    flexDirection: "row",
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "0px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
