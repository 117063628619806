import { GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import KendoButton from "components/button/KendoButton";
import Flex from "components/flex/Flex";
import GridTable, {
  CenterCell,
  KendoCell,
  NumberCell,
  numberWithCommasCell,
} from "components/gird-table/GridTable";
import TextInput from "components/input/TextInput";
import DropDownList from "components/select/DropDownList";
import StyledTable from "components/table/StyledTable";
import SmallTitle from "components/title/SmallTitle";
import withProgressBar from "hoc/withProgressBar";
import * as APIS from "libs/apis";
import { formatTime, numberWithCommas } from "libs/utils";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { Popup, PopupPropsContext } from "@progress/kendo-react-popup";
import fonts from "libs/fonts";
import { RadioButton } from "@progress/kendo-react-inputs";
import { saveAs } from "file-saver";

const POPUP_WIDTH = 300;

const InventoryOrder = () => {
  const [categories, setCategories] = useState([]);

  const history = useHistory();

  useEffect(() => {
    APIS.getGoodsCategoryList().then(({ data: { success, data } }) => {
      success && setCategories(data.filter(({ deleted }) => !deleted));
    });
  }, []);

  return (
    <InventoryOrderContainer>
      <SmallTitle style={{ marginBottom: 10 }}>상품 재고 현황</SmallTitle>
      <StatusSection categories={categories} />
      <SmallTitle style={{ margin: "10px 0" }}>상품 재고 및 주문</SmallTitle>
      <OrderSection categories={categories} />
    </InventoryOrderContainer>
  );
};

const StatusSection = withProgressBar(({ progressBar, categories }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    progressBar.on();
    APIS.getInventoryOrderPresent()
      .then(({ data: { success, data } }) => {
        success && setItems(data);
      })
      .finally(progressBar.off);
  }, []);

  const parsedItems = useMemo(() => {
    return categories.map((category) => {
      const item = items.find(({ id }) => id === category.id);
      return item || { ...category, name: category.kind };
    });
  }, [categories, items]);

  return (
    <StyledTable row className="status-section">
      <Flex style={{ width: 200 }}>
        <Flex className="header">카테고리</Flex>
        <Flex className="data">보유 수량</Flex>
        <Flex className="data">보유 총 중량</Flex>
      </Flex>
      {parsedItems.map((item, i) => (
        <Flex className="item" key={i.toString()}>
          <Flex className="header">{item.name}</Flex>
          <Flex className="data">{numberWithCommas(item.quantity)}</Flex>
          <Flex className="data">{numberWithCommas(item.weight)}</Flex>
        </Flex>
      ))}
    </StyledTable>
  );
});

const OrderSection = withProgressBar(({ progressBar, categories = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const { categoryId = "", goodsName = "", outsourcing = "" } = locationState;
  const [goods, setGoods] = useState([]);

  const [popupState, setPopupState] = useState({
    show: false,
    offset: { top: 0, left: 0 },
  });

  useEffect(() => {
    if (goodsName || outsourcing) {
      getGoods();
    }
  }, []);

  const categoryOptions = useMemo(() => {
    return categories.map(({ id, kind }) => ({ value: id, label: kind }));
  }, [categories]);

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const getGoods = () => {
    progressBar.on();
    APIS.getInventoryOrderGoods({ categoryId, goodsName, outsourcing })
      .then(({ data: { success, data = [], message } }) => {
        if (success) {
          setGoods(
            data.map((item, i) => {
              const totalCount = item.stockedWait + item.packing + item.stocked;
              return {
                ...item,
                id: `${item.id}_${item.optionId}`,
                goodsId: item.id,
                no: data.length - i,
                totalCount,
                quantity: 0,
              };
            })
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  };

  const handlePopupShow = ({ target, item, field }) => {
    const rect = target.getBoundingClientRect();
    const top = window.pageYOffset + rect.bottom + 5;

    const left =
      window.pageXOffset + (rect.left + rect.right) / 2 - POPUP_WIDTH / 2;

    setPopupState({
      show: true,
      offset: { left, top },
      item,
      field,
    });
  };
  const handlePopupClose = () => {
    setPopupState({ ...popupState, show: false });
  };

  const handleClickOrder = (dataIndex) => {
    const item = goods[dataIndex];
    const { goodsId, quantity, optionId } = item;

    if (quantity <= 0) {
      dispatch(actionError("주문 수량을 입력해주세요."));
    } else {
      progressBar.on();
      APIS.postInventoryOrderGoodsRequest({
        goodsId: goodsId,
        orderType: "GOODS",
        quantity,
        optionId,
      })
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              actionOpen("주문이 완료되었습니다.", getGoods, null, null, true)
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(progressBar.off);
    }
  };

  const onUpdate = (param) => {
    progressBar.on();
    APIS.postInventoryOrderGoodsMove(param)
      .then(({ data: { data, success, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "재고상태가 변경되었습니다.",
              () => {
                handlePopupClose();
                getGoods();
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  };

  const handleClickReset = () => {
    handleChangeLocationState({
      categoryId: "",
      goodsName: "",
      outsourcing: "",
    });
  };

  const handleClickExcel = () => {
    getGoodsExcel({ ...locationState });
  };

  const getGoodsExcel = (param) => {
    progressBar.on();
    APIS.getInventoryOrderGoodsExcel(param)
      .then(({ data }) => {
        const blob = new Blob([data]);
        saveAs(
          blob,
          `상품재고주문_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .finally(progressBar.off);
  };

  const grid = useMemo(() => {
    return (
      <GridTable
        className="order-grid"
        data={goods}
        scrollable
        onScroll={handlePopupClose}
      >
        <GridColumn title="NO" field="no" cell={CenterCell} width={50} />
        <GridColumn title="제품정보">
          <GridColumn title="카테고리" field="categoryName" width={100} />
          <GridColumn title="상품명" field="name" />
          <GridColumn
            title="옵션명"
            field="optionName"
            width={100}
            cell={(props) => {
              const { optionName = "" } = props.dataItem;
              return (
                <KendoCell {...props}>
                  {optionName ? optionName : "-"}
                </KendoCell>
              );
            }}
          />
          <GridColumn
            title="단위중량"
            field="gram"
            cell={NumberCell}
            width={100}
          />
          <GridColumn
            title="제작/포장"
            cell={(props) => {
              const { outsourcing = "", packingOutsourcing = "" } =
                props.dataItem;
              return (
                <KendoCell {...props}>
                  {outsourcing}
                  <br />
                  {packingOutsourcing}
                </KendoCell>
              );
            }}
          />
          <GridColumn
            title="결제완료"
            field="approval"
            cell={NumberCell}
            width={100}
          />
        </GridColumn>
        <GridColumn title="재고상태">
          <GridColumn
            title="제작중"
            field="making"
            cell={NumberCell}
            width={100}
          />
          <GridColumn title="보유">
            <GridColumn
              title="입고대기"
              field="stockedWait"
              cell={(props) => {
                const { dataItem, field } = props;
                if (!dataItem[field]) {
                  return <NumberCell {...props} />;
                }
                return (
                  <NumberCell
                    {...props}
                    className="click-cell"
                    onClick={({ target }) => {
                      handlePopupShow({
                        target,
                        item: props.dataItem,
                        field: props.field,
                      });
                    }}
                  />
                );
              }}
              width={100}
            />
            <GridColumn
              title="포장중"
              field="packing"
              cell={NumberCell}
              width={100}
            />
            <GridColumn
              title="입고완료"
              field="stocked"
              cell={(props) => {
                const { dataItem, field } = props;
                if (!dataItem[field]) {
                  return <NumberCell {...props} />;
                }
                return (
                  <NumberCell
                    {...props}
                    className="click-cell"
                    onClick={({ target }) => {
                      handlePopupShow({
                        target,
                        item: props.dataItem,
                        field: props.field,
                      });
                    }}
                  />
                );
              }}
              width={100}
            />
            <GridColumn
              title="합계"
              field="totalCount"
              cell={NumberCell}
              width={100}
            />
            <GridColumn
              title="총중량"
              field="totalWeight"
              cell={NumberCell}
              width={100}
            />
          </GridColumn>
          <GridColumn
            title="예상재고"
            field="expect"
            cell={NumberCell}
            width={100}
          />
        </GridColumn>
        <GridColumn title="주문">
          <GridColumn
            title="수량"
            field="quantity"
            width={80}
            cell={(props) => {
              const quantity = props.dataItem["quantity"];

              const [value, setValue] = useState(quantity || 0);

              useEffect(() => {
                setValue(quantity);
              }, [quantity]);

              return (
                <CenterCell {...props}>
                  <TextInput
                    type="number"
                    value={numberWithCommas(value)}
                    style={{ textAlign: "right", width: 70 }}
                    onChange={(value) => {
                      const replaceValue = value.replace(/,/gi, "") || "0";
                      goods[props.dataIndex]["quantity"] = +replaceValue;
                      setValue(+replaceValue);
                    }}
                  />
                </CenterCell>
              );
            }}
          />
          <GridColumn
            title=""
            width={80}
            cell={(props) => {
              return (
                <CenterCell {...props}>
                  <KendoButton
                    label="주문"
                    style={{ width: 50 }}
                    onClick={() => {
                      handleClickOrder(props.dataIndex);
                    }}
                  />
                </CenterCell>
              );
            }}
          />
        </GridColumn>
      </GridTable>
    );
  }, [goods]);

  return (
    <Flex className="order-section">
      <Flex
        row
        style={{ justifyContent: "space-between", marginBottom: 10 }}
        onKeyDown={(e) => {
          e.key === "Enter" && getGoods();
        }}
      >
        <Flex row alignCenter>
          <span>카테고리</span>
          <DropDownList
            style={{ margin: "0 10px" }}
            useAll
            options={categoryOptions}
            selected={categoryId}
            onChange={(categoryId) => handleChangeLocationState({ categoryId })}
          />
          <span>상품명</span>
          <TextInput
            style={{ width: 200, margin: "0 10px" }}
            value={goodsName}
            onChange={(goodsName) => {
              handleChangeLocationState({ goodsName });
            }}
          />
          <span>제조사</span>
          <TextInput
            style={{ width: 200, marginLeft: 10 }}
            value={outsourcing}
            onChange={(outsourcing) => {
              handleChangeLocationState({ outsourcing });
            }}
          />
          <KendoButton
            label="검색"
            style={{ marginLeft: 10 }}
            onClick={getGoods}
          />
          <KendoButton
            label="초기화"
            style={{ marginLeft: 5 }}
            themeColor="primary"
            onClick={handleClickReset}
          />
        </Flex>
        <Flex>
          <KendoButton icon="excel" label="Excel" onClick={handleClickExcel} />
        </Flex>
      </Flex>
      {grid}
      <OrderPopup
        {...popupState}
        onClose={handlePopupClose}
        onUpdate={onUpdate}
      />
    </Flex>
  );
});

const OrderPopup = ({ show, offset, onClose, item = {}, field, onUpdate }) => {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const { goodsId, categoryName, name, gram, optionId, goldOrSilver } = item;
  const [status, setStatus] = useState("PACKING"); // 입고대기시 보유재고변경 status
  const [stockedStatus, setStockedStatus] = useState(""); // 입고완료시 보유재고변경 status
  const [quantity, setQuantity] = useState(0);
  const [memo, setMemo] = useState("");
  const [silverProductNameList, setSilverProductNameList] = useState([]); // 품목 리스트
  const [selectedProductName, setSelectedProductName] = useState(null);

  useEffect(() => {
    APIS.getInventoryItemCategory().then(({ data: { success, data } }) => {
      success && setSilverProductNameList(data);
    });
  }, []);

  useEffect(() => {
    if (field === "stockedWait") {
      setStatus("PACKING");
    } else {
      setStockedStatus("");
    }
    setQuantity(0);
    setMemo("");
    setSelectedProductName(null);
  }, [offset]);

  // 드롭다운 상태 변경
  const handleChangeStatusDrop = (value) => {
    setStockedStatus(value);
  };

  // 품목 상태 변경
  const handleChangeProductName = (vlaue) => {
    setSelectedProductName(vlaue);
  };

  //입고대기쪽 status
  const handleChangeStatus = (e) => {
    setStatus(e.value);
  };

  const productNameOptions = useMemo(() => {
    return silverProductNameList
      .filter(
        (productName) =>
          productName.assetType === "SILVER" &&
          productName.purityTypeEnum !== "SILVER"
      )
      .map((product) => ({
        label: product.purityType,
        value: product.purityTypeEnum,
      }));
  }, [silverProductNameList]);

  const targetStatus = useMemo(() => {
    if (field === "stockedWait") {
      return [
        { label: "포장 주문", value: "PACKING" },
        { label: "입고 완료", value: "STOCKED" },
      ];
    } else {
      return [
        { label: "재포장", value: "PACKING" },
        { label: "입고 대기", value: "STOCKED_WAIT" },
        { label: "정련 대기", value: "REFINE_DISCARD" },
        { label: "원재료 입고", value: "RAWS_DISCARD" },
      ];
    }
  }, [field, goldOrSilver]);

  const handleClickUpdateOrder = () => {
    if (field !== "stockedWait" && stockedStatus === "") {
      dispatch(actionError("상태를 선택해주세요."));
      return false;
    }

    // 은, 정련대기일 때만 품목 체크
    if (
      item["goldOrSilver"] === "SILVER" &&
      stockedStatus === "REFINE_DISCARD" &&
      selectedProductName === ""
    ) {
      dispatch(actionError("품목을 선택해주세요."));
      return false;
    }

    if (quantity <= 0) {
      dispatch(actionError("주문 수량을 입력해주세요."));
      return false;
    }

    if (item[field] < quantity) {
      dispatch(
        actionError("주문 수량이 초과되었습니다.\n수량을 확인해주세요.")
      );
      return false;
    }

    onUpdate({
      goodsId: goodsId,
      quantity,
      beforeStatus: field === "stockedWait" ? "STOCKED_WAIT" : "STOCKED",
      afterStatus: field === "stockedWait" ? status : stockedStatus,
      optionId,
      memo,
      purityType: selectedProductName,
    });
  };

  return (
    <Popup
      show={show}
      offset={offset}
      style={{ width: POPUP_WIDTH, zIndex: "auto" }}
      onOpen={() => {
        popupRef.current.focus();
      }}
    >
      <OrderPopupContainer ref={popupRef} tabIndex={0} onBlur={() => {}}>
        <Flex className="title">보유 재고 변경</Flex>
        <Flex className="content">
          <Flex row>
            <span className="header">상품명</span>
            <span>
              {categoryName} &gt; {name}
            </span>
          </Flex>
          <Flex row>
            <span className="header">단위 중량</span>
            <span>{gram}</span>
          </Flex>
          <Flex row>
            <span className="header">수량</span>
            <span>{item[field]}</span>
          </Flex>
          <Flex row>
            {field === "stockedWait" ? (
              targetStatus.map(({ value, label }) => {
                return (
                  <Flex className="radio" row>
                    <RadioButton
                      name="status"
                      label={label}
                      value={value}
                      checked={status === value}
                      onChange={handleChangeStatus}
                    />
                  </Flex>
                );
              })
            ) : (
              <>
                <span className="header">상태</span>
                <DropDownList
                  useAll
                  options={targetStatus}
                  selected={stockedStatus}
                  onChange={handleChangeStatusDrop}
                />
              </>
            )}
          </Flex>
          {item.goldOrSilver === "SILVER" &&
          stockedStatus === "REFINE_DISCARD" ? (
            <Flex row>
              <span className="header">품목</span>
              <DropDownList
                useAll
                options={productNameOptions}
                selected={selectedProductName}
                onChange={handleChangeProductName}
              />
            </Flex>
          ) : null}
          <Flex row>
            <span className="header">주문 수량</span>
            <TextInput
              type="number"
              value={numberWithCommas(quantity)}
              style={{ flex: 1, textAlign: "right" }}
              onChange={(value) => {
                const replaceValue = value.replace(/,/gi, "") || "0";
                setQuantity(+replaceValue);
              }}
            />
          </Flex>
          {stockedStatus === "REFINE_DISCARD" ||
          stockedStatus === "RAWS_DISCARD" ? (
            <Flex row>
              <span className="header">메모</span>
              <TextInput
                type="text"
                value={memo}
                style={{ flex: 1, textAlign: "left" }}
                onChange={(value) => {
                  setMemo(value);
                }}
              />
            </Flex>
          ) : null}
          <Flex row className="button-section">
            <KendoButton label="변경" onClick={handleClickUpdateOrder} />
            <KendoButton label="취소" onClick={onClose} />
          </Flex>
        </Flex>
      </OrderPopupContainer>
    </Popup>
  );
};

const InventoryOrderContainer = styled(Flex)`
  flex: 1;
  padding: 10px;

  .status-section {
    .item {
      flex: 1;
    }
    .data {
      justify-content: center;
    }
  }

  .order-section {
    .order-grid {
      height: 630px;
      width: 100%;

      .click-cell {
        cursor: pointer;
        color: blue;
        text-decoration: underline;
      }
    }
  }
`;

const OrderPopupContainer = styled(Flex)`
  .title {
    background: #f2f4f6;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #e2e5e8;
  }

  .content {
    padding: 10px;
    > div:not(:last-child) {
      margin-bottom: 10px;
    }

    .header {
      width: 80px;
      display: flex;
      align-items: center;
    }

    .radio {
      flex: 1;
      align-items: center;
      label {
        flex: 1;
        font-size: 18px;
      }
    }

    .button-section {
      button {
        flex: 1;
      }
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

export default InventoryOrder;
