import React, { useEffect, useState } from "react";
import { DragAndDrop } from "@progress/kendo-react-common";
import consts from "../../libs/consts";
import * as APIS from "../../libs/apis";
import styled from "styled-components";
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import ImageAddRows from "./ImageAddRows";
import { useDispatch } from "react-redux";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { Button } from "@progress/kendo-react-buttons";
import { CardMedia } from "@material-ui/core";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { openImageView } from "redux/image-viewer/ImageViewerReducer";

export const ReorderContext = React.createContext({
  reorder: () => {},
  dragStart: () => {},
});

const ImageAdd = ({ goodsImage, productId }) => {
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState(goodsImage);

  useEffect(() => {
    setGridData(goodsImage);
  }, [goodsImage]);

  const [activeItem, setActiveItem] = useState(null);

  const reorder = (dataItem, direction) => {
    if (activeItem === dataItem) {
      return;
    }
    let reorderedData = gridData.slice();
    let prevIndex = reorderedData.findIndex((p) => p === activeItem);
    let nextIndex =
      reorderedData.findIndex((p) => p === dataItem) +
      (direction === "before" ? -1 : 0);
    if (prevIndex > nextIndex) {
      nextIndex++;
    }
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem || reorderedData[0]);

    const data = reorderedData?.map((item, i) => {
      return {
        id: item.id,
        sort: i + 1,
      };
    });
    setGridData(reorderedData);
    APIS.patchSortProductImage(productId, data)
      .then(({ data: { data, message, success } }) => {
        if (!success) {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)));
  };

  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  const DragHandleCell = (props) => {
    const {
      ariaColumnIndex,
      dataItem,
      selectionChange,
      columnIndex,
      columnsCount,
      rowType,
      expanded,
      dataIndex,
      isSelected,
      ...rest
    } = props;
    return (
      <td
        {...rest}
        style={{
          touchAction: "none",
        }}>
        <AlignSpan>
          <span
            style={{
              cursor: "move",
            }}
            data-drag-handle="true">
            <span
              className="k-icon k-i-reorder"
              style={{
                pointerEvents: "none",
              }}
            />
          </span>
        </AlignSpan>
      </td>
    );
  };

  const ImageCell = (props) => {
    const { path, dataIndex } = props.dataItem || {};
    return (
      <td style={{ display: "flex", justifyContent: "center" }}>
        <CardMedia
          component="img"
          image={path ? consts.s3BaseUrl + "/" + path : ""}
          style={{
            borderRadius: 10,
            width: 100,
            height: 150,
            maxHeight: 100,
            maxWidth: 100,
            webkitUserDrag: "none",
          }}
        />
      </td>
    );
  };

  //이미지 삭제
  const deleteProductImage = (imageId) => {
    dispatch(loadingStart);
    APIS.deleteProductImage(productId, imageId)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "이미지가 삭제되었습니다.",
              () => {
                history.go(0);
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const DeleteCell = (props) => {
    const { id } = props.dataItem;
    return (
      <td>
        <Button
          themeColor={"primary"}
          onClick={() => {
            deleteProductImage(id);
          }}>
          삭제
        </Button>
      </td>
    );
  };
  const CustomHeaderCell = (props) => {
    return (
      <span style={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={(e) => {
            dispatch(
              openImageView({
                images: gridData
                  ? gridData?.map((item) => {
                      return {
                        src: item.path || "",
                      };
                    })
                  : "",
              })
            );
          }}>
          이미지 미리보기
        </Button>
      </span>
    );
  };
  return (
    <ReorderContext.Provider
      value={{
        reorder: reorder,
        dragStart: dragStart,
      }}>
      <DragAndDrop>
        <KendoGrid
          style={{
            height: "400px",
          }}
          data={gridData}
          dataItemKey={"id"}
          rowRender={(row, rowProps) => (
            <ImageAddRows elementProps={row.props} {...rowProps} />
          )}>
          <Column title="" width="50px" cell={DragHandleCell} />
          <Column
            title="이미지"
            cell={ImageCell}
            width="200px"
            align="center"
          />
          <Column headerCell={CustomHeaderCell} cell={DeleteCell} />
        </KendoGrid>
      </DragAndDrop>
    </ReorderContext.Provider>
  );
};

export default ImageAdd;

const AlignSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
