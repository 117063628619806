//Actions
const CLOSE = "imageViewer/CLOSE";
const OPEN = "imageViewer/OPEN";

const INIT_STATE = {
  open: false,
  images: [],
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case CLOSE:
      return { ...INIT_STATE };

    case OPEN:
      return { ...INIT_STATE, open: true, ...action.payload };

    default:
      return state;
  }
}

//Action 생성자
export const closeImageView = () => {
  return { type: CLOSE };
};

export const openImageView = (payload) => {
  return { type: OPEN, payload };
};
