import moment from "moment-timezone";

export const formatTime = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date).format(format);
  } else {
    return "";
  }
};

export const numFormat = (num) => {
  if (num) {
    return Number(num).toLocaleString();
  } else {
    return 0;
  }
};

export function createQueryString(payload) {
  return Object.entries(payload)
    .map((e) => e.join("="))
    .join("&");
}

export function isType(data) {
  return Object.prototype.toString.call(data).slice(8, -1);
}

export function getNoProcessLabel({ status, approvedAppraise, method }) {
  if (status.length > 0) {
    if (status[status.length - 1].status === "반송대기") {
      return "반송대기";
    }
    if (status[status.length - 1].status === "승인대기") {
      return "승인대기";
    }
    if (
      status.filter((x) => x.status === "감정완료")[0] &&
      !status.filter((x) => x.status === "반송반려")[0] &&
      status.filter((x) => x.status === "반송신청")[0] &&
      !status.filter((x) => x.status === "반송완료")[0]
    ) {
      return "반송대기";
    } else if (
      method !== "택배" &&
      status.filter((x) => x.status === "감정완료")[0] &&
      !approvedAppraise
    ) {
      return "등록대기";
    } else if (method === "택배") {
      if (status[status.length - 1].status === "접수승인") {
        return "감정대기";
      }
      return "접수대기";
    } else if (
      method !== "택배" &&
      status[status.length - 1].status === "접수"
    ) {
      return "감정대기";
    } else {
      return "-";
    }
  }
}

export function formatNumber(x) {
  return Number?.isInteger(x)
    ? numberWithCommas(x)
    : numberWithCommas(parseFloat(x?.toFixed(3)));
}

export function numberWithCommas(x) {
  if (x) {
    let onlyNumber = x.toString().replace(/,/gi, "");
    let parts = onlyNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else if (x === "") {
    return "";
  } else if (x === 0) {
    return "0";
  } else {
    return "0";
  }
}

export function unComma(x) {
  let parts = x.toString().split(".");
  if (typeof x === "string") {
    if (parts[0].includes(",")) {
      parts[0] = parts[0].toString().replace(/[^\d]+/g, "");
    }
  }
  return parts.join(".");
}

export const validation = (type, value) => {
  switch (type) {
    case "tel":
      return /(\d{2}|\d{3})-(\d{3}|\d{4})-\d{4}/.test(value);

    case "businessNumber":
      return /\d{3}-\d{2}-\d{5}/.test(value);

    case "email":
      return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(
        value
      );
    default:
      return !!value;
  }
};

export const parseToBr = (str) => {
  return str.split("").map((x, i) => {
    if (x === "\n") {
      return <br key={i.toString()} />;
    } else {
      return x;
    }
  });
};

export const numFormatFloor = (num) => {
  if (num) {
    return Math.floor(num);
  }
};

export const addMonth = (date) => {
  return moment(date).add(1, "months").format("YYYY-MM-DD");
};

export const subMonth = (date) => {
  return moment(date).subtract(1, "months").format("YYYY-MM-DD");
};
