import { makeStyles } from "@material-ui/core";
import React from "react";
import fonts from "../../libs/fonts";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function PageLabel({
  add,
  children,
  optionComponent,
  blind,
  active,
  fixed,
}) {
  const classes = useStyle();
  return (
    <Flex row className={classes.root}>
      <Text font={fonts.notoSansKRMedium} className={classes.label}>
        {children}
      </Text>
      <Flex row>
        {optionComponent}
        {Boolean(fixed) && (
          <Button
            onClick={fixed.onClick}
            label={fixed.title}
            className={classes.button}
          />
        )}
        {Boolean(active) && (
          <Button
            onClick={active.onClick}
            label={active.title}
            className={classes.button}
          />
        )}
        {Boolean(blind) && (
          <Button
            onClick={blind.onClick}
            label={blind.title}
            className={classes.button}
          />
        )}
        {Boolean(add) && (
          <Button
            onClick={add.onClick}
            label={add.title}
            className={classes.button}
          />
        )}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "26px",
    color: "#131313",
  },
  root: {
    margin: "0px 50px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0px",
    borderBottom: "1px solid black",
    position: "relative",
  },
  button: {
    minWidth: 150,
  },
});

