import { ButtonBase, Dialog, InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import fonts from "../../libs/fonts";

export default function SettingPolicy3Popup({ onClose, isAll }) {
  const classes = useStyle();
  return (
    <Dialog open fullWidth maxWidth="md">
      <Flex className={classes.container}>
        <h3>수수료 테이블</h3>
        <Flex row className={classes.root}>
          <h4>{isAll ? "일괄설정" : "1차 -> 2차"}</h4>
          <Button label={"추가하기"}></Button>
        </Flex>

        <table cellPadding={0} cellSpacing={0} className={classes.table1}>
          <tr>
            <th colSpan={3}>결제금액</th>
            <th colSpan={2}>수수료율</th>
          </tr>
          {[{}, {}, {}, {}].map((x, i) => {
            return (
              <tr key={i.toString()}>
                <th colSpan={3}>
                  <Flex
                    row
                    className="row-center"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <div className={classes.inputWrap}>
                      <InputBase className={classes.input} />원
                    </div>
                    이상
                    <div className={classes.inputWrap}>
                      <InputBase className={classes.input} />원
                    </div>
                    미만
                  </Flex>
                </th>
                <td colSpan={2}>
                  <div className={classes.inputWrap}>
                    <InputBase className={classes.input} />%
                  </div>
                </td>
              </tr>
            );
          })}
        </table>

        <Flex className={classes.buttons} row>
          <ButtonBase onClick={onClose} className={classes.button1}>
            취소
          </ButtonBase>
          <ButtonBase onClick={onClose} className={classes.button2}>
            저장
          </ButtonBase>
        </Flex>
      </Flex>
    </Dialog>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",

    justifyContent: "space-between",
  },
  container: {
    padding: 20,
  },
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },
  labelWrap2: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "start",
    "& button": { width: 100, marginLeft: 20 },
  },

  tdb: {
    backgroundColor: "#f5f5f5",
  },

  input: {
    marginRight: 10,
    paddingRight: 10,
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    width: "80%",
    marginLeft: "10%",
    backgroundColor: "#fff",
    marginRight: 10,
    paddingRight: 10,
  },
  input: {
    marginRight: 10,
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
  buttons: { alignSelf: "center", marginTop: 50 },
  button1: {
    border: "1px solid #ddd",
    height: 40,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 40,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
});
