import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import MainLayout from "../../layouts/main/MainLayout";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import EventList from "./EventList";
import NoticeList from "./NoticeList";
import { getDefaultPageSize } from "../../libs/consts";
import Button from "../../components/button/Button";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/modal/ModalReducer";
import { RadioButton } from "@progress/kendo-react-inputs";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";
import {
  ListBox,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import styled from "styled-components";
import PopupList from "./PopupList";

const List = styled.div`
  width: 100%;
  height: 93%;
  align-items: center;
  font-size: 16px;
  font-family: NotoSansKR-Bold;

  .k-listbox {
    height: 100%;
    width: 100%;
  }

  .k-list-content {
    overflow: auto;
  }
  .k-list-ul {
    width: initial;
  }
  .k-list-item {
    border-bottom: 1px solid lightgrey;
  }
`;

export default function ServiceNotice({}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        ...getDefaultPageSize(),
      });
    }
  }, [state.tab]);

  return (
    <>
      <LabelTabs
        data={[
          { label: "공지사항", value: "1" },
          { label: "이벤트", value: "2" },
          { label: "팝업", value: "3" },
        ]}
        optionComponent={
          <Button
            label="배너 순서 관리"
            onClick={() => {
              dispatch(
                openModal({
                  visiable: true,
                  children: <ManageBannerPopups />,
                  maskClosable: true,
                  style: { padding: "25px 20px 30px 20px" },
                })
              );
            }}
          />
        }
      />

      {state.tab === "1" ? (
        <NoticeList />
      ) : state.tab === "2" ? (
        <EventList />
      ) : (
        <PopupList tab={state.tab} />
      )}
    </>
  );
}

const ManageBannerPopups = () => {
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState("BANNER");
  const [dragState, setDragState] = useState({
    list: [],
    virtualList: [],
    draggedItem: {},
  });

  const handleDragStart = (e) => {
    setDragState({ ...dragState, draggedItem: e.dataItem });
  };

  const handleDrop = (e) => {
    let result = processListBoxDragAndDrop(
      dragState.list,
      dragState.virtualList,
      dragState.draggedItem,
      e.dataItem,
      "id"
    );
    setDragState({
      ...dragState,
      list: result.listBoxOneData?.map((item, index) => {
        return { ...item, sort: index + 1 };
      }),
      virtualList: result.listBoxTwoData.map((item, index) => {
        return { ...item, sort: index + 1 };
      }),
    });
  };

  const fetchBannerList = () => {
    dispatch(loadingStart);
    const params = { type: "BANNER" };
    APIS.getBannerList(objToQueryStr(params))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setDragState({
            list: data.map((item, index) => {
              return {
                ...item,
                subject: item.subject,
                sort: index + 1,
              };
            }),
            draggedItem: {},
          });
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleSaveBannerList = () => {
    const data = [];
    dragState.list.map((item) => {
      data.push({ id: item.id, sort: item.sort });
    });
    dispatch(loadingStart);
    APIS.patchBannerList(data)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "배너 순서가 변경되었습니다.",
              () => {
                fetchBannerList();
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const SELECTED_FIELD = "selected";

  const handleItemClick = (event, data, connectedData) => {
    setDragState({
      ...dragState,
      [data]: dragState[data]?.map((item) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }

        return item;
      }),
      [connectedData]: dragState[connectedData]?.map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  useEffect(() => {
    fetchBannerList();
  }, []);

  return (
    <div
      style={{
        width: "500px",
        height: "300px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "13%",
          alignItems: "center",
          fontSize: 16,
          fontFamily: "NotoSansKR-Bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <RadioButton
            label="배너"
            value="BANNER"
            onChange={(e) => {
              setOrderType(e.target.value);
            }}
            checked={orderType === "BANNER" ? true : false}
          />
          {/* <RadioButton
            label="팝업"
            value="POPUP"
            // onChange={onChangeSort}
            //  checked={orderType === "POPUP" ? true : false}
          /> */}
        </div>
        <Button
          label="저장"
          onClick={handleSaveBannerList}
          style={{ height: 32 }}
        />
      </div>
      <List>
        <ListBox
          data={dragState.list}
          textField="subject"
          onDragStart={handleDragStart}
          onDrop={handleDrop}
          selectedField={SELECTED_FIELD}
          onItemClick={(e) => handleItemClick(e, "list", "virtualList")}
          item={MyCustomItem}
        />
      </List>
    </div>
  );
};

const MyCustomItem = (props) => {
  let { dataItem, selected, ...others } = props;
  return (
    <li
      {...others}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100% ",
      }}
    >
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            margin: "5px",
            borderRadius: "0.3rem",
            textAlign: "center",
            border: "none",
            color: "#fff",
            background: "#ed7632",
            lineHeight: "20px",
            fontWeight: "bold",
          }}
        >
          {props.dataItem.sort}
        </div>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {props.dataItem.subject}
        </span>
      </div>
    </li>
  );
};
