import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import Flex from "../../components/flex/Flex";
import fonts from "../../libs/fonts";
import { FormComponents } from "./components/FormComponents";
import { dateRangeValidator, requiredValidator } from "./components/validator";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "libs/utils";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import * as APIS from "../../libs/apis";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

const period = [
  {
    label: "지정일",
    value: "CUSTOM",
  },
  { label: "매년 반복", value: "EVERY_YEAR" },
];

const isActive = [
  {
    label: "활성",
    value: true,
  },
  { label: "비활성", value: false },
];

export default function SettingHolidayPopup({ onClose, popupType }) {
  const history = useHistory();
  const locationState = history.location.state || {};
  const classes = useStyle();
  const authReducer = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  // EVERY_YEAR or CUSTOM
  const [type, setType] = useState(null);
  const [holidays, setHolidays] = useState("");
  const [holidayData, setHolidayData] = useState(
    popupType === "EDIT"
      ? {
          active: locationState.active,
          date: locationState.date,
          type: locationState.type,
          title: locationState.title,
          password: undefined,
        }
      : {
          active: "",
          date: undefined,
          type: "",
          title: "",
          password: "",
        }
  );

  /**
   * TODO:
   * password 암호화
   * 등록 API
   * 상세 호출 후 form 세팅
   * 수정 API
   */

  const setParameter = (values) => {
    const parsePassword = base64.stringify(sha256(`${values.password}`));
    let today = new Date();
    today = formatTime(today, "YYYY-MM-DDTHH:mm:ss");

    let body = {
      id: null,
      type: "",
      title: "",
      typeKorName: "",
      startDate: "",
      endDate: "",
      startEveryDay: "",
      endEveryDay: "",
      active: true,
      createdAt: "",
      updatedAt: "",
      managerName: "",
      password: "",
    };

    if (popupType === "CREATE") body.createdAt = today;
    else {
      body = { ...body, ...locationState };
      delete body.date;
      body.updatedAt = today;
    }

    body.active = values.active;
    body.type = values.type;
    body.typeKorName = period.find((date) => date.value === values.type)?.label;
    if (values.type === "CUSTOM") {
      body["startDate"] = formatTime(values.date.start, "YYYYMMDD");
      body["endDate"] = formatTime(values.date.end, "YYYYMMDD");
      body["startEveryDay"] = "";
      body["endEveryDay"] = "";
    } else {
      body["startEveryDay"] = formatTime(values.date.start, "MMDD");
      body["endEveryDay"] = formatTime(values.date.end, "MMDD");
      body["startDate"] = "";
      body["endDate"] = "";
    }
    body.title = values.title;
    body.password = parsePassword;
    return body;
  };

  const onSubmit = (values, e) => {
    if (
      !values["type"] ||
      !values["date"]["start"] ||
      !values["date"]["end"] ||
      !values["title"] ||
      !values["password"]
    ) {
      return;
    }
    setParameter(values);
    dispatch(loadingStart);
    let func =
      popupType === "CREATE"
        ? APIS.createSettingHoliday
        : APIS.modifySettingHoliday;
    func(setParameter(values))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              `${popupType === "CREATE" ? "등록" : "수정"}이 완료되었습니다`,
              () => {
                onClose();
              },
              "",
              "",
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    if (popupType === "EDIT" && locationState?.id) {
      setHolidayData({
        date: locationState.date,
        type: locationState.type,
        title: locationState.title,
        password: null,
      });
    }
  }, []);

  return (
    <Dialog
      title={
        <div>
          <span>공휴일 설정</span>
          <br />
          <span style={{ fontSize: "12px" }}>
            *첫 생성 시에는 무조건 활성화가 되며, 수정 시 활성화 여부를 설정
            가능합니다
          </span>
        </div>
      }
      style={{
        zIndex: 98,
      }}
      onClose={onClose}>
      <Flex className={classes.container}>
        <Form
          initialValues={holidayData}
          onSubmit={onSubmit}
          render={(formRenderProps) => (
            <FormElement
              horizontal={true}
              style={{
                marginBottom: 16,
              }}>
              {popupType === "EDIT" && (
                <Field
                  id={"active"}
                  name={"active"}
                  label={"활성 여부"}
                  layout={"horizontal"}
                  formType={"radio"}
                  onChange={(e) => {
                    setHolidays(e.value);
                  }}
                  data={isActive}
                  component={FormComponents}
                  validator={formRenderProps.submitted && requiredValidator}
                />
              )}
              <Field
                id={"type"}
                name={"type"}
                label={"반복주기"}
                layout={"horizontal"}
                formType={"radio"}
                component={FormComponents}
                onChange={(e) => {
                  setType(e.value);
                }}
                data={period}
                validator={formRenderProps.submitted && requiredValidator}
              />

              <Field
                id={"date"}
                name={"date"}
                label={"날짜 선택"}
                formType={"dateRange"}
                onChange={(e) => {
                  setHolidays(e.value);
                }}
                component={FormComponents}
                validator={formRenderProps.submitted && dateRangeValidator}
              />
              <Field
                id={"title"}
                name={"title"}
                label={"제목"}
                formType={"input"}
                component={FormComponents}
                validator={formRenderProps.submitted && requiredValidator}
              />
              <Field
                id={"password"}
                name={"password"}
                type="password"
                label={"비밀번호"}
                formType={"input"}
                component={FormComponents}
                validator={formRenderProps.submitted && requiredValidator}
              />
              <div className="k-form-buttons">
                <Button
                  themeColor={"primary"}
                  type={"submit"}
                  disabled={!formRenderProps.allowSubmit}>
                  등록
                </Button>
                <Button onClick={formRenderProps.onFormReset}>초기화</Button>
              </div>
            </FormElement>
          )}
        />
      </Flex>
    </Dialog>
  );
}

const useStyle = makeStyles({
  container: {},
});
