import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Editor from "../../components/editor/Editor";
import { useHistory } from "react-router";

import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Radio,
  CardMedia,
  Icon,
  InputBase,
  ButtonBase,
  RadioGroup,
} from "@material-ui/core";
import MainLayout from "../../layouts/main/MainLayout";
import { Add } from "@material-ui/icons";
import Flex from "../../components/flex/Flex";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import fonts from "../../libs/fonts";

const useStyles = makeStyles({
  container: {
    padding: "50px 50px 50px 50px",
  },
  grid2: { alignItems: "center", padding: "30px 50px" },
  item: {
    borderBottom: "1px solid #f5f5f5",
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  itemLabel: {
    color: "#555",
  },
  input: {
    border: "1px solid rgba(0,0,0,0.2)",
    minWidth: 200,
    padding: 6,
    marginRight: 10,
  },
  imgWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    cursor: "pointer",
    height: 100,
    border: "1px solid #f5f5f5",
    marginRight: 10,
  },
  imgWrap2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& span": {
      cursor: "pointer",
      marginTop: 10,
    },
  },
  end: {},
});

export default function DirectStatusDetailAdd() {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      tab: "1",
    });
  }, []);

  return (
    <>
      <LabelTabs
        data={[
          {
            label: "상품 상세 내역",
            value: "1",
          },
          {
            label: "접수 상세 내역",
            value: "2",
          },
        ]}
        optionComponent={
          <Flex row className="row-center">
            <ButtonBase
              style={{
                marginLeft: 20,
                backgroundColor: "blue",
                color: "#fff",
                width: 100,
                height: 50,
              }}
            >
              저장
            </ButtonBase>
          </Flex>
        }
      >
        접수 상세 내역
      </LabelTabs>

      <Grid spacing={3} className={classes.grid2} container>
        <Grid item xs={1} style={{ fontFamily: fonts.notoSansKRMedium }}>
          진행상태
        </Grid>
        <Grid item xs={11}>
          <RadioGroup style={{ marginLeft: 20 }} row>
            {[
              "판매대기중",
              "판매중",
              "판매취소",
              "반품 배송중",
              "반품 배송완료",
            ].map((label) => {
              return (
                <FormControlLabel
                  key={label}
                  label={label}
                  control={<Radio color="default" style={{ color: "black" }} />}
                />
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.container}>
        <Grid
          item
          xs={6}
          style={{ fontSize: 18, fontFamily: fonts.notoSansKRBold }}
        >
          상풍 등록 및 수정
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonBase
            style={{
              padding: "10px 30px",
              border: "1px solid rgba(0,0,0,0.23)",
            }}
          >
            삭제
          </ButtonBase>
          <ButtonBase
            style={{
              padding: "10px 30px",
              color: "#fff",
              backgroundColor: "blue",
              marginLeft: 10,
            }}
          >
            저장
          </ButtonBase>
        </Grid>
        <Grid item xs={12}>
          기본정보
        </Grid>
        <Grid
          style={{ borderTop: "1px solid #f5f5f5" }}
          item
          className={classes.item + " " + classes.itemLabel}
          xs={1}
        >
          상품코드
        </Grid>
        <Grid item className={classes.item} xs={11}>
          <InputBase disabled value="NO.DP232323" className={classes.input} />
        </Grid>
        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          상품명
        </Grid>
        <Grid item className={classes.item} xs={11}>
          <InputBase className={classes.input} />
        </Grid>

        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          카테고리
        </Grid>
        <Grid item className={classes.item} xs={11}>
          <select>
            <option label="명품잡화" />
          </select>
          <select style={{ marginLeft: 10 }}>
            <option label="가방" />
          </select>
        </Grid>

        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          구매가격
        </Grid>
        <Grid item className={classes.item} xs={11}>
          <InputBase className={classes.input} />원
        </Grid>

        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          판매희망가격
        </Grid>
        <Grid item className={classes.item} xs={5}>
          <InputBase className={classes.input} />원
        </Grid>

        <Grid
          item
          style={{ color: "red" }}
          className={classes.item + " " + classes.itemLabel}
          xs={1}
        >
          위탁판매 수수료
        </Grid>
        <Grid item className={classes.item} xs={5}>
          <InputBase className={classes.input} />원
        </Grid>

        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          판매자
        </Grid>
        <Grid item className={classes.item} xs={11}>
          홍길동 (abc@naver.com)
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginTop: 50,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          추가정보
          <ButtonBase>
            <Add />
            필드 추가하기
          </ButtonBase>
        </Grid>

        <Grid
          item
          className={classes.item}
          xs={12}
          style={{ borderTop: "1px solid #f5f5f5" }}
        >
          <Grid item xs={2}>
            제조사
          </Grid>
          <Grid item xs={2}>
            한국금거래소
          </Grid>
          <Grid item xs={2} style={{ color: "blue" }}>
            삭제
          </Grid>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <Grid item xs={2}>
            제조사
          </Grid>
          <Grid item xs={2}>
            한국금거래소
          </Grid>
          <Grid item xs={2} style={{ color: "blue" }}>
            삭제
          </Grid>
        </Grid>

        <Grid
          className={classes.item}
          xs={12}
          style={{ marginTop: 50, borderBottom: "1px solid #f5f5f5" }}
        >
          상품이미지
        </Grid>
        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          대표이미지
        </Grid>
        <Grid item className={classes.item} xs={11}>
          <div className={classes.imgWrap}>
            <Add />
          </div>
        </Grid>

        <Grid item className={classes.item + " " + classes.itemLabel} xs={1}>
          상품상세 이미지
        </Grid>
        <Grid item className={classes.item} xs={11}>
          <div className={classes.imgWrap2}>
            <div className={classes.imgWrap}>
              <Add />
            </div>
            <span>삭제</span>
          </div>
          <div className={classes.imgWrap2}>
            <div className={classes.imgWrap}>
              <Add />
            </div>
            <span>삭제</span>
          </div>
        </Grid>

        <Grid
          className={classes.item}
          xs={12}
          style={{ marginTop: 50, borderBottom: "1px solid #f5f5f5" }}
        >
          상품설명
        </Grid>

        <Grid
          className={classes.item}
          xs={12}
          style={{
            flexDirection: "column",
            height: "500px !important",
            minHeight: 500,
          }}
        >
          <Editor />
        </Grid>
      </Grid>
    </>
  );
}
