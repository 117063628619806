export const convertGoodsGroupToGrid = (resGoodsGroup) => {
  return resGoodsGroup.map((goodsGroup) => {
    const innerGoods = translateGoodsDisplayToKor(goodsGroup.goods);
    const _goodsGroup = { ...goodsGroup, goods: innerGoods };
    return _goodsGroup;
  });
};

export const translateGoodsDisplayToKor = (goodsGroupGoods) => {
  const translationToString = (goodsDisplayArray) => {
    return goodsDisplayArray
      .map((type) => {
        switch (type) {
          case "APP":
            return "앱";
          case "MALL":
            return "몰";
          case "K_BANK_MALL":
            return "K-BANK";
          default:
            return "";
        }
      })
      .filter((label) => label !== "")
      .join();
  };

  return goodsGroupGoods.map((good) => ({
    ...good,
    goodsDisplayModeList: translationToString(good.goodsDisplayModeList),
  }));
};

export const getGoodsListOfCurrentViews = (goodsGroups, selectedId) => {
  if (!goodsGroups || goodsGroups.length === 0) {
    return [];
  }
  const currentGroup = goodsGroups.filter(
    (item) => item.goodsGroupId === selectedId
  );
  console.log("currentGroup", currentGroup);
  console.log("goodsGroups", goodsGroups);
  console.log("selectedId", selectedId);
  if (currentGroup.length === 0) {
    return [];
  }
  const goodsList = currentGroup[0].goods;

  return goodsList;
};
