import { makeStyles } from "@material-ui/core";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import Button from "components/button/Button";
import Flex from "components/flex/Flex";
import StyledTable from "components/table/StyledTable";
import SmallTitle from "components/title/SmallTitle";
import { postDeilveryChange } from "libs/apis";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { actionOpen } from "redux/action/ActionActions";
import styled from "styled-components";

const validateFields = (deliveryCorp, regiNo) => {
  if (
    !deliveryCorp ||
    deliveryCorp.length === 0 ||
    (deliveryCorp !== "EPOST" && deliveryCorp !== "VALEX")
  ) {
    return {
      success: false,
      message: "배송업체 값은 필수이며 'VALEX' 또는 'EPOST'만 입력가능합니다.",
    };
  }
  if (!regiNo || regiNo === 0) {
    return { success: false, message: "송장번호의 값은 필수입니다." };
  }
  return { success: true };
};
const EditButtons = ({ isEdit, setIsEdit, onLoad, formRenderProps }) => {
  const locationState = useLocation().state;
  const classes = useButtonStyles();
  const dispatch = useDispatch();
  const handleChangeDeliveryInfo = async (adminPassword) => {
    const validationResult = validateFields(
      formRenderProps.valueGetter("deliveryCorp"),
      formRenderProps.valueGetter("regiNo")
    );
    if (!validationResult.success) {
      return alert(validationResult.message);
    }
    const payload = {
      managerPassword: adminPassword,
      deliveryCorp: formRenderProps.valueGetter("deliveryCorp"), // 배송 업체(EPOST, VALEX)
      deliveryNo: formRenderProps.valueGetter("regiNo"),
    };

    postDeilveryChange(locationState.id, payload).then(({ data }) => {
      const { success, message } = data;
      if (success) {
        dispatch(
          actionOpen(
            "제작 주문 정보가 수정되었습니다.",
            () => {
              onLoad();
              setIsEdit(false);
            },
            null,
            null,
            true
          )
        );
      } else {
        alert(message);
      }
    });
  };

  if (isEdit) {
    return (
      <Flex className={classes.buttons} row>
        <AdminConfirmButton
          popupLabel="배송지 변경"
          popupMessage="변경사항을 저장하시겠습니까?"
          label="저장"
          callback={handleChangeDeliveryInfo}
          rootClassName={classes.confirmRoot}
          btnClassName={classes.confirmButton}
        />
        <Button
          label="취소"
          className={classes.bt1}
          onClick={() => {
            formRenderProps.onFormReset();
            setIsEdit(false);
          }}
        />
      </Flex>
    );
  } else {
    return (
      <Button
        label="수정"
        className={classes.bt1}
        onClick={() => {
          setIsEdit(true);
        }}
      />
    );
  }
};

const useButtonStyles = makeStyles({
  buttons: {
    marginRight: "3%",
    // marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bt1: {
    outline: 0,
    boxSizing: "border-box",
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  confirmRoot: {
    margin: 0,
    marginRight: 10,
    minWidth: 70,
    height: 38,
    width: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});

const linkToDeliveryCorp = (deliveryCorp, regiNo) => {
  if (deliveryCorp === "VALEX" && regiNo) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://vnos.valex.co.kr/pages/progressStatus/nonScheduleStts/popup/jobOrderList2/${regiNo}`}
      >
        {regiNo}
      </a>
    );
  } else if (deliveryCorp === "EPOST" && regiNo) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={` https://m.epost.go.kr/postal/mobile/mobile.trace.RetrieveDomRigiTraceList.comm?sid1=${regiNo}`}
      >
        {regiNo}
      </a>
    );
  } else {
    return "-";
  }
};

const DeliveryDetailTable = (props) => {
  const classes = useStyle();
  const [isEdit, setIsEdit] = useState(false);

  if (Object.keys(props).length === 0) {
    return undefined;
  }
  const { deliveryInfo, onLoad } = props;

  const initialValues = {
    regiNo: deliveryInfo.regiNo,
    deliveryCorp: deliveryInfo.deliveryCorp,
  };

  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps) => {
        return (
          <Flex
            style={{
              margin: "10px 50px 0px 50px",
            }}
          >
            <Flex
              row
              className={classes.header}
              style={{ border: "1px solid #e2e5e8", borderBottom: "none" }}
            >
              <SmallTitle style={{ marginRight: 30 }}>택배 배송정보</SmallTitle>
              <EditButtons
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                onLoad={onLoad}
                formRenderProps={formRenderProps}
              />
            </Flex>
            <StyledTable>
              <Container>
                <Flex row>
                  <Flex className="header">{"받는사람"}</Flex>
                  <Flex className="data">{deliveryInfo.receiver}</Flex>
                  <Flex className="header">{"연락처"}</Flex>
                  <Flex className="data">{deliveryInfo.phoneMobile}</Flex>
                </Flex>
                <Flex row>
                  <Flex className="header">{"받는주소"}</Flex>
                  <Flex className="data">{deliveryInfo.totalAddress}</Flex>
                  <Flex className="header">{"배송요청사항"}</Flex>
                  <Flex className="data">
                    {deliveryInfo.requestMemo || "-"}
                  </Flex>
                </Flex>
                <FormElement>
                  <Flex row>
                    <Flex className="header">{"배송업체"}</Flex>
                    <Flex className="data">
                      {isEdit ? (
                        <Field
                          name="deliveryCorp"
                          placeholder="VALEX 또는 EPOST"
                          component={Input}
                        />
                      ) : (
                        deliveryInfo.deliveryCorp ?? "-"
                      )}
                    </Flex>
                    <Flex className="header">{"송장번호"}</Flex>
                    <Flex className="data">
                      {isEdit ? (
                        <Field name="regiNo" component={Input} />
                      ) : (
                        <span>
                          {deliveryInfo.regiNo
                            ? linkToDeliveryCorp(
                                deliveryInfo.deliveryCorp,
                                deliveryInfo.regiNo
                              )
                            : "-"}
                        </span>
                      )}
                    </Flex>
                  </Flex>
                </FormElement>
              </Container>
            </StyledTable>
          </Flex>
        );
      }}
    />
  );
};

export default DeliveryDetailTable;

const useStyle = makeStyles({
  header: {
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
});

const Container = styled(Flex)`
  .header {
    min-width: 130px;
  }
`;
