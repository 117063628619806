import React, { useEffect, useState } from "react";
import styled from "styled-components";
import images from "../../libs/images";

const ShopName = styled.div`
  margin-top: 1%;
  text-align: center;
  font-family: NotoSansKR-Bold;
  font-size: x-large;
`;
const UserName = styled.div`
  text-align: center;
  font-family: NotoSansKR-Bold;
  font-size: large;
  margin-top: 5px;
`;
const ReceptionDate = styled.div`
  text-align: center;
  font-family: NotoSansKR-Regular;
  font-size: medium;
  margin-top: 2px;
`;
const ReceptionNo = styled.div`
  text-align: center;
  font-family: NotoSansKR-Regular;
  font-size: medium;
`;
const ProductName = styled.div`
  text-align: center;
  font-family: NotoSansKR-Bold;
  font-size: medium;
  margin-top: 5px;
`;
const OptionName = styled.div`
  text-align: center;
  font-family: NotoSansKR-Bold;
  font-size: medium;
`;
const OrderQuantity = styled.div`
  text-align: center;
  font-family: NotoSansKR-Bold;
  font-size: medium;
`;

const PrintLabel = React.forwardRef((props, ref) => {
  const { tableData } = props;

  const division = (number) => {
    const array = [...tableData];
    const length = array.length;
    const count =
      Math.floor(length / number) + (Math.floor(length % number) > 0 ? 1 : 0);
    const result = [];

    for (let i = 0; i < count; i++) {
      result.push(array.splice(0, number));
    }
    return result;
  };
  const newArray = division(12);
  return (
    <div ref={ref}>
      {newArray?.map((array) => {
        return (
          <div
            // 용지설정 letter
            // style={{
            //   padding: "0% 2% 0% 3%",
            //   width: 850,
            //   display: "flex",
            //   flexDirection: "row",
            //   flexWrap: "wrap",
            // }}
            style={{
              padding: "5% 2% 0% 4%",
              width: 850,
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {array?.map((item) => {
              return <LabelItem item={item} />;
            })}
          </div>
        );
      })}
    </div>
  );
});

export default PrintLabel;

const LabelItem = ({ item }) => {
  return (
    <div
      // 용지설정 Letter
      // style={{
      //   width: "32%",
      //   height: 275,
      //   padding: "7px 10px 15px 15px",
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-between",
      // }}
      style={{
        width: "32%",
        height: 277,
        padding: "7px 10px 15px 10px",
        marginBottom: 5,
        marginRight: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <ShopName>{item.shopName}</ShopName>
        <UserName>
          {item.userName}({item.userId || "-"})
        </UserName>
        <ReceptionDate>접수일 : {item.createdAt}</ReceptionDate>
        <ReceptionNo>접수번호 : {item.no}</ReceptionNo>
        <ProductName style={{ backgroundColor: item.labelColor }}>
          {item.goodsName}
        </ProductName>
        {item.optionValue && (
          <OptionName style={{ backgroundColor: item.labelColor }}>
            {item.optionValue}
          </OptionName>
        )}
        <OrderQuantity style={{ backgroundColor: item.labelQtyColor }}>
          {item.gram}g x {item.qty}
        </OrderQuantity>
      </div>
      <div style={{ textAlign: "center" }}>
        <img src={images.logo_new} className="logo" style={{ width: "40%" }} />
      </div>
    </div>
  );
};
