import React from "react";
import PageLabel from "../../components/page-label/PageLabel";
import Flex from "../../components/flex/Flex";
import ServiceAssetDrop from "./ServiceAssetDrop";
import ServiceMarketAsset from "./ServiceMarketAsset";

const ServiceEventDrop = () => {

  return (
    <Flex style={{ padding: "5px 5px 5px 5px" }}>
      {/* <ServiceAssetDrop /> */}
      <ServiceMarketAsset />
    </Flex>
  );
};

export default ServiceEventDrop;
