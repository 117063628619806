import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import KendoButton from "components/button/KendoButton";
import { postCreateAppraisal } from "libs/apis";
import { useEffect, useRef, useState } from "react";

const RADIO_GROUP = [
  { label: "금", value: "GOLD" },
  { label: "은", value: "SILVER" },
];

const ApprisalDisposeButtonSection = ({ refetch }) => {
  const [popupState, setPopupState] = useState({
    show: false,
    offset: { top: 0, right: 0 },
  });
  const popupRef = useRef(null);

  const handlePopupShow = ({ target, item }) => {
    const rect = target.getBoundingClientRect();
    const top = window.scrollY + rect.bottom + 10;
    const left = window.scrollX + rect.left;
    setPopupState((prev) => ({
      show: !prev.show,
      offset: { top, left },
      item,
    }));
  };

  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.innerText !== "자산처분"
    ) {
      setPopupState((prevState) => ({ ...prevState, show: false }));
    }
  };

  const handleSubmit = (formReturnValues) => {
    const { assetType, weightGram, pricePerGram, price } = formReturnValues;

    if (!assetType || !weightGram || !pricePerGram || !price) {
      return;
    }
    const payload = {
      disposeType: null,
      disposeStatus: null,
      appraisalRequest: null,
      weightGram,
      pricePerGram,
      price,
      manager: null,
      assetType,
    };
    postCreateAppraisal(payload).then((res) => {
      if (res.data.success) {
        setPopupState((prev) => ({ ...prev, show: false }));
        refetch();
      }
    });
  };

  const handleEffectPrice = (formRenderProps, coupleField) => (e) => {
    const coupleValue = formRenderProps.valueGetter(coupleField);
    const curretnFieldValue = e.value;
    if (Number(coupleValue)) {
      const sumUp = Number(curretnFieldValue) * Number(coupleValue);
      formRenderProps.onChange("price", {
        value: sumUp,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <KendoButton
        style={{ height: 40, width: 100 }}
        label="자산처분"
        onClick={handlePopupShow}
      />
      <Popup
        show={popupState.show}
        offset={popupState.offset}
        style={{ width: 350, zIndex: "auto" }}
        onOpen={() => {
          popupRef.current.focus();
          console.log("focused");
        }}
      >
        <div
          style={{
            padding: 20,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 25,
          }}
          ref={popupRef}
          tabIndex={0}
          onClick={(e) => e.stopPropagation()} // Prevent click inside popup from closing it
        >
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => {
              console.log(formRenderProps.errors);
              return (
                <FormElement>
                  <table>
                    <tbody>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 100,
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          자산*
                        </div>
                        <Field
                          name="assetType"
                          data={RADIO_GROUP}
                          layout="horizontal"
                          component={RadioGroup}
                        />
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 100,
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          중량*
                        </div>
                        <Field
                          name="weightGram"
                          component={Input}
                          onChange={handleEffectPrice(
                            formRenderProps,
                            "pricePerGram"
                          )}
                        />
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 100,
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          기준시세*
                        </div>
                        <Field
                          name="pricePerGram"
                          component={Input}
                          onChange={handleEffectPrice(
                            formRenderProps,
                            "weightGram"
                          )}
                        />
                      </tr>
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 100,
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          처분금액*
                        </div>
                        <Field name="price" component={Input} />
                      </tr>
                      <tr
                        style={{
                          width: "100%",
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <KendoButton
                          disabled={!formRenderProps.allowSubmit}
                          label="확인"
                          type="submit"
                        />
                      </tr>
                    </tbody>
                  </table>
                </FormElement>
              );
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default ApprisalDisposeButtonSection;
