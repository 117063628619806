import React, { useState, useEffect } from "react";
import {
  Grid,
  getSelectedState,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { numberWithCommas } from "../../libs/utils";
import { formatPhone } from "../../services/utils";
import esMessages from "./es.json";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { getter } from "@progress/kendo-react-common";
import {
  Pager,
  useTableKeyboardNavigation,
} from "@progress/kendo-react-data-tools";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import AdminCofirmBtn from "components/admin-confirm-btn/AdminConfirmBtn";

let gridColumn = {};
let gridOrder = {};
const DATA_ITEM_KEY = "id";

loadMessages(esMessages, "es-KR");
// @ts-check
/**
 * table props
 * @typedef {object}TableProps
 * @property {string} name
 * @property {import("react").ReactChildren} children
 * @property {string} className
 * @property {object} style
 * @property {boolean} dashboard
 * @property {boolean} excelExport
 * @property {boolean} reorderable
 * @property {any[]} data
 * @property {any} cellRender
 * @property {any} rowRender
 * @property {boolean} pager
 * @property {any} onItemChange
 * @property {boolean} pageable
 * @property {number[]} pageSizes
 * @property {number} skip
 * @property {number} take
 * @property {number} total
 * @property {boolean} scrollable
 * @property {any} onPageChange
 * @property {any} onHeaderSelectionChange
 * @property {any} onSelectionChange
 * @property {any} editField
 * @property {any} onScroll
 * @property {any} onRowClick
 */
/**
 *
 * @param {TableProps} props
 * @returns
 */
const GridTable = (props) => {
  const {
    name,
    children,
    className = "",
    style = {},
    dashboard = false,
    excelExport = false,
    reorderable = false,
    data = [],
    cellRender,
    rowRender,
    pager = false,
    onItemChange,
    pageable = false,
    pageSizes = [10, 25, 50, 100],
    skip = 0,
    take = 0,
    total = 0,
    scrollable = false,
    onPageChange = () => {},
    onHeaderSelectionChange = () => {},
    onSelectionChange = () => {},
    editField,
    onScroll,
    onRowClick = () => {},
  } = props;
  const [listData, setListData] = useState([]);
  const [status, setStatus] = useState(true);
  const [selectedState, setSelectedState] = useState({});

  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  useEffect(() => {
    gridColumn = JSON.parse(localStorage.getItem("kumbang-gird-column")) || {};
    gridOrder = JSON.parse(localStorage.getItem("kumbang-grid-order")) || {};
    setListData(data);
  }, [props.data]);

  useEffect(() => {
    if (!status) {
      setStatus(true);
    }
  }, [status]);

  let defaultProps = {
    dashboard,
    excelExport,
    reorderable,
    scrollable: scrollable ? "scrollable" : "none",
  };

  if (rowRender) {
    defaultProps = {
      ...defaultProps,
      rowRender,
    };
  }

  if (cellRender) {
    defaultProps = {
      ...defaultProps,
      cellRender,
    };
  }

  if (pageable) {
    defaultProps = {
      ...defaultProps,
      pageable: {
        pageSizes,
        info: true,
      },
      skip,
      take,
      total,
      //pageSize,
      onPageChange,
    };
  }

  if (onItemChange) {
    defaultProps = {
      ...defaultProps,
      onItemChange,
    };
  }

  if (pager) {
    defaultProps = {
      ...defaultProps,
      pager,
    };
  }

  if (style) {
    defaultProps = {
      ...defaultProps,
      style,
    };
  }

  // const onSelectionChange = (event) => {
  //   const newSelectedState = getSelectedState({
  //     event,
  //     selectedState: selectedState,
  //     dataItemKey: DATA_ITEM_KEY,
  //   });
  //   setSelectedState(newSelectedState);
  // };

  // if (selectable) {
  //   console.log("selected");
  //   defaultProps = {
  //     ...defaultProps,
  //     selectable: {
  //       enabled: true,
  //       cell: true,
  //       mode: "single",
  //     },
  //     onSelectionChange: onSelectionChange,
  //     dataItemKey: DATA_ITEM_KEY,
  //   };
  // }

  const onColumnReorder = (e) => {
    const { target } = e;
    const {
      dragLogic: { columns },
    } = target;

    const parseColums = columns.map((item) => {
      if (item.children.length > 0) {
        return {
          ...item,
          id: `_${item.orderIndex}_column`,
          children: item.children.map((el) => {
            return { ...el, id: `_${el.orderIndex}_column_children` };
          }),
        };
      } else {
        return { ...item, id: `_${item.orderIndex}_column` };
      }
    });

    let data = [];
    let orderChildren = [];

    if (name) {
      if (reorderable) {
        parseColums.forEach((el) => {
          data.push(el);
        });

        parseColums
          .filter((item) => item.parentIndex !== -1)
          .forEach((el) => {
            orderChildren.push(el);
          });

        orderChildren = orderChildren.map((item) => {
          return { ...item, id: `_${item.ariaColumnIndex}_column` };
        });

        data = data.filter((item) => item.parentIndex === -1);

        let arr = [];
        data = data.map((item, index) => {
          if (item.children.length > 0) {
            const filterChildren = orderChildren.filter(
              (el) => el.parentIndex === index
            );
            arr = [...filterChildren];
          }
          return {
            ...item,
            children: arr,
            id: `_${item.ariaColumnIndex}_column`,
          };
        });

        gridOrder[name] = {
          columns: [...data],
        };

        localStorage.setItem("kumbang-grid-order", JSON.stringify(gridOrder));
      }
    }
    setStatus(false);
  };

  const onColumnResizes = (e) => {
    const { columns, index, newWidth, targetColumnId, end } = e;
    let data = [];

    if (end && newWidth > 0) {
      columns.forEach((item) => {
        if (item.children) {
          item.children.forEach((el) => {
            data.push(el);
          });
        } else {
          data.push(data);
        }
      });

      if (name) {
        if (gridColumn?.[name]) {
          if (index !== -1) {
            const parentIndex = columns.findIndex(
              (item) => item.ariaColumnIndex === index
            );
            if (columns[parentIndex]?.children) {
              const childrenIndex = columns[parentIndex]["children"].findIndex(
                (item) => item.id === targetColumnId
              );
              gridColumn[name]["columns"][parentIndex]["children"][
                childrenIndex
              ] = {
                ...gridColumn[name]["columns"][parentIndex]["children"][
                  childrenIndex
                ],
                width: newWidth,
              };
            } else {
              const columnIndex = columns.findIndex(
                (item) => item.id === targetColumnId
              );

              gridColumn[name]["columns"][columnIndex] = {
                ...gridColumn[name]["columns"][columnIndex],
                width: newWidth,
              };
            }
          }

          if (index === -1) {
            const columnIndex = columns.findIndex(
              (item) => item.id === targetColumnId
            );
            if (columns[columnIndex].children) {
              const childrenIndex = columns[columnIndex]["children"].findIndex(
                (item) => item.id === targetColumnId
              );
              gridColumn[name]["columns"][columnIndex]["children"][
                childrenIndex
              ] = {
                ...gridColumn[name]["columns"][columnIndex]["children"][
                  childrenIndex
                ],
                width: newWidth,
              };
            }
          }
          localStorage.setItem(
            "kumbang-gird-column",
            JSON.stringify(gridColumn)
          );
        } else {
          gridColumn[name] = {
            columns: [...columns],
          };

          if (index !== -1) {
            const parentIndex = columns.findIndex(
              (item) => item.ariaColumnIndex === index
            );
            if (columns[parentIndex]?.children) {
              const childrenIndex = columns[parentIndex].findIndex(
                (item) => item.id === targetColumnId
              );
              gridColumn[name]["columns"][parentIndex]["children"][
                childrenIndex
              ] = {
                ...gridColumn[name]["columns"][parentIndex]["children"][
                  childrenIndex
                ],
                width: newWidth,
              };
            } else {
              const columnIndex = columns.findIndex(
                (item) => item.id === targetColumnId
              );

              gridColumn[name]["columns"][columnIndex] = {
                ...gridColumn[name]["columns"][columnIndex],
                width: newWidth,
              };
            }
          }

          if (index === -1) {
            const columnIndex = columns.findIndex(
              (item) => item.id === targetColumnId
            );
            if (columns[columnIndex].children) {
              const childrenIndex = columns[columnIndex].children.findIndex(
                (item) => item.id === targetColumnId
              );
              gridColumn[name]["columns"][columnIndex]["children"][
                childrenIndex
              ] = {
                ...gridColumn[name]["columns"][columnIndex]["children"][
                  childrenIndex
                ],
                width: newWidth,
              };
            }
          }

          if (index && columns[index]?.children === undefined) {
            const columnIndex = columns.findIndex(
              (item) => item.id === targetColumnId
            );

            gridColumn[name]["columns"][columnIndex] = {
              ...gridColumn[name]["columns"][columnIndex],
              width: newWidth,
            };
          }

          localStorage.setItem(
            "kumbang-gird-column",
            JSON.stringify(gridColumn)
          );
        }
      }
    }
  };

  const sortColum = (props) => {
    let children = props;

    if (gridColumn?.[name]) {
      children = children.map((item, index) => {
        return {
          ...item,
          props: {
            ...item.props,
            children: item.props?.children?.map((el, elIndex) => {
              return {
                ...el,
                props: {
                  ...el.props,
                  width:
                    gridColumn[name]["columns"][index]?.["children"]?.[elIndex]
                      ?.width,
                },
              };
            }),
            width: gridColumn[name]["columns"][index]?.["width"],
          },
        };
      });
    }

    if (gridOrder?.[name]) {
      let parseData = [];
      gridOrder[name].columns = gridOrder[name].columns.map((item) => {
        return {
          ...item,
          id: `_${item.orderIndex}_column`,
          declarationIndex: item.orderIndex,
        };
      });

      gridOrder[name].columns.forEach((item) => {
        const key = item.title;
        let findData = children.find((el) => el.props.title === key);
        if (item.children.length > 0) {
          let childrenArr = [];
          item.children.forEach((dl, index) => {
            const childrenKey = dl.title;
            let childrenData = item.children.find(
              (cl) => cl.title === childrenKey
            );

            childrenData = {
              ...childrenData,
              id: `${childrenData.id}_children`,
            };

            childrenArr.push({
              ...childrenData,
              declarationIndex: index,
            });
          });
          findData = {
            ...findData,
            ["props"]: {
              ...findData["props"],
              children: [...childrenArr],
            },
          };
        }
        parseData.push(findData);
      });
      children = parseData;
    }
    // return children.map((column) => {
    //   const { props } = column;
    //   return <Column key={props.field || ""} {...props} />;
    // });
    return children;
  };

  return (
    <LocalizationProvider language="es-KR">
      <IntlProvider locale="en-US">
        {status && (
          <Grid
            className={className}
            data={listData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
            {...defaultProps}
            resizable={true}
            reorderable
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              cell: true,
              mode: "single",
            }}
            pager={(props) => (
              <Pager {...props} info={true} responsive={false} />
            )}
            onSelectionChange={onSelectionChange}
            onColumnReorder={onColumnReorder}
            onColumnResize={onColumnResizes}
            onHeaderSelectionChange={onHeaderSelectionChange}
            onScroll={onScroll}
            editField={editField}
            onRowClick={onRowClick}
          >
            {sortColum(children)}
          </Grid>
        )}
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default GridTable;

export const centerCell = (props) => {
  const { dataItem, field } = props;
  const data = dataItem?.[field] || "-";
  return <td className="td-c center-cell">{data}</td>;
};

export const leftCell = (props) => {
  const { dataItem, field } = props;
  const data = dataItem?.[field] || "-";
  return <td className="td-l">{data}</td>;
};

export const rightCell = (props) => {
  const { dataItem, field } = props;
  const data = dataItem?.[field] || "-";
  return <td className="td-r">{data}</td>;
};

export const numberWithCommasCell = (props) => {
  const { dataItem, field } = props;
  const data = dataItem?.[field] || "0";
  return (
    <td className="td-r" style={{ marginRight: "3px" }}>
      {numberWithCommas(data)}
    </td>
  );
};

export const formatPhoneCell = (props) => {
  const { dataItem, field } = props;
  const data = dataItem?.[field] || "-";
  return (
    <td className="td-c" style={{ marginRight: "3px" }}>
      {formatPhone(data)}
    </td>
  );
};

export const KendoCell = (props) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const data = props.dataItem[props.field];
  return (
    <td
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
      style={props.style}
      className={props.className}
      alt={props.alt}
      title={props.title}
      ref={props.innerRef}
      onClick={props.onClick}
    >
      {props.children || data}
    </td>
  );
};

export const NumberCell = (props) => {
  const data = props.dataItem[props.field];
  return (
    <KendoCell {...props} className={`td-r ${props.className}`}>
      {props.children || `${numberWithCommas(data)}${props.unit || ""}`}
    </KendoCell>
  );
};

export const CenterCell = (props) => {
  const data = props.dataItem[props?.field];
  return (
    <KendoCell {...props} className={`td-c ${props?.className}`}>
      {props.children || `${data}${props?.unit || ""}`}
    </KendoCell>
  );
};

export const NumericCell = (props) => {
  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? 0 : dataItem[field];
  let isEdit = props?.isEdit && dataItem.inEdit;

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };
  return (
    <td className="td-c">
      {isEdit ? (
        <NumericTextBox
          value={dataValue}
          min={0}
          max={100}
          step={0.1}
          onChange={handleChange}
        />
      ) : (
        dataValue.toString()
      )}
    </td>
  );
};

export const CommandCell = (props) => {
  const dataItem = props.dataItem;
  const inEdit = dataItem.inEdit;
  const id = dataItem?.id;
  return (
    <td
      className="td-c"
      style={{
        padding: "8px 12px",
      }}
    >
      {inEdit ? (
        <AdminCofirmBtn
          label={props?.okText || "확인"}
          className={"k-grid-save-command"}
          style={{
            display: "inline-block",
            background: "#fe6e0e",
            border: "none",
            color: "#fff",
            fontWeight: "bold",
          }}
          title={props?.passwordPopupTitle || "비밀번호 입력"}
          // disabled={result && list.length > 0 ? false : true}
          callback={(password) => {
            props?.save(password);
          }}
        />
      ) : (
        <Button
          className={"k-grid-edit-command"}
          themeColor="primary"
          onClick={(_) => {
            props?.enterEdit(dataItem);
          }}
        >
          {" "}
          {props?.updateText || "수정"}
        </Button>
      )}
      {inEdit && (
        <Button
          className={inEdit ? "k-grid-cancel-command" : "k-grid-remove-command"}
          onClick={(_) => {
            props.cancel();
          }}
        >
          {props?.cancelText || "취소"}
        </Button>
      )}
    </td>
  );
};
