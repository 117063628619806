import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { numberWithCommas, unComma } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import AdminCofirmBtn from "../../components/admin-confirm-btn/AdminConfirmBtn";
import { useDispatch } from "react-redux";
import { closeModal } from "../../redux/modal/ModalReducer";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

const INIT = {
  gold: "",
  silver: "",
  krw: "",
  point: "",
  file: "",
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ServiceAssetMint = (props) => {
  const { searchFn } = props;
  const [path, setPath] = useState("");
  const [eventList, setEventList] = useState([]);
  const [selected, setSelected] = useState("");
  const [data, setData] = useState({
    gold: "0",
    silver: "0",
    // krw: "0",
    point: "0",
    file: "",
    fileName: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const { eventList: eventData } = props;
    const parseList = eventData.filter((item) => item.eventId !== " ");
    setEventList(parseList);
    setSelected(parseList?.[0] || {});
  }, [props.eventList]);

  const [numPages, setNumPages] = useState(null);

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    const reg = /[^0-9\s,\s.]/g;
    if (reg.test(value)) {
      return;
    }
    setData({ ...data, [name]: value });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onClickSave = (props) => {
    const formData = new FormData();

    const payload = JSON.stringify({
      managerPassword: props,
      eventId: selected.eventId,
      // krw: typeof data.krw === "string" ? unComma(data.krw) : +data.krw,
      point: typeof data.point === "string" ? unComma(data.point) : +data.point,
      gold: typeof data.gold === "string" ? unComma(data.gold) : +data.gold,
      silver:
        typeof data.silver === "string" ? unComma(data.silver) : +data.silver,
    });

    const blob = new Blob([payload], {
      type: "application/json",
    });

    formData.append("event", blob);
    formData.append("image", data.file);

    APIS.postEventAssetMint(formData).then((res) => {
      dispatch(loadingStart);
      try {
        const { data } = res;
        const { success } = data;
        if (success) {
          dispatch(
            actionOpen("이벤트 자산이 발행되었습니다.", null, "", "", true)
          );
          searchFn(selected, { page: 0, size: 30 });
          dispatch(closeModal());
        }
      } catch (err) {
        console.log(err, "err");
        //dispatch(actionOpen(err, null, "", "", true));
      } finally {
        dispatch(loadingEnd);
      }
    });
  };

  return (
    <div
      id="assetMint"
      style={{
        width: "1050px",
        height: "500px",
        marginRight: "10px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          padding: "3px 3px 3px 3px",
          border: "1px solid #ededed",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "10px",
        }}
      >
        <h4>자산 발행</h4>
        <div style={{ margin: "3px 3px 3px 3px" }}>
          <DropDownList
            style={{ width: "300px", background: "#fff" }}
            data={eventList}
            defaultValue={selected || "이벤트를 선택해주세요."}
            dataItemKey="eventId"
            textField="eventName"
            onChange={(e) => setSelected(e.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 10px 10px 10px",
            }}
          >
            <span>금</span>
            <div>
              <Input
                name="gold"
                style={{ width: "200px" }}
                value={numberWithCommas(data.gold)}
                onChange={onChangeValue}
              />
              &nbsp;g
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 10px 10px 10px",
            }}
          >
            <span>은</span>
            <div>
              <Input
                name="silver"
                style={{ width: "200px" }}
                value={numberWithCommas(data.silver)}
                onChange={onChangeValue}
              />
              &nbsp;g
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 10px 10px 10px",
            }}
          >
            <span style={{ marginRight: "5px" }}>POINT</span>
            <div>
              <Input
                name="point"
                style={{ width: "195px" }}
                value={numberWithCommas(data.point)}
                onChange={onChangeValue}
              />
              &nbsp;P
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            width: "400px",
            height: "30px",
            marginTop: "5px",
            //margin: "3px 3px 3px 3px",
          }}
        >
          <span
            style={{
              width: "50px",
              position: "absolute",
              left: "60px",
              lineHeight: "35px",
            }}
          >
            자료
          </span>
          <div style={{ width: "300px" }}>
            <input
              id="assetDropFile"
              type="file"
              style={{ width: "0px", height: "0px" }}
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files;

                if (e.target.files.length) {
                  const parseFile = e.target.files[0];
                  if (!parseFile.type.includes("pdf")) {
                    dispatch(actionError("등록할 수 없는 파일입니다."));
                    return;
                  }
                  if (+parseFile.size / 1000 > 200) {
                    dispatch(
                      actionOpen(
                        "최대 업로드 가능한 파일의 용량은 200KB입니다.",
                        null,
                        "",
                        "",
                        true
                      )
                    );
                    return;
                  }
                  const reader = new FileReader();
                  reader.readAsDataURL(parseFile);
                  reader.onload = () => {
                    setData({ ...data, file: file[0], fileName: file[0].name });
                    setPath(reader.result);
                  };
                }
              }}
            />
            <input
              id="fileField"
              type="text"
              style={{
                left: "127px",
                position: "absolute",
                width: "200px",
                height: "30px",
                border: "1px solid #ededed",
                borderRadius: "0.2rem",
                background: "#fff",
                cursor: "default",
              }}
              value={data?.fileName || ""}
              onFocus={() => document.getElementById("fileField").blur()}
              onClick={() => document.getElementById("assetDropFile").click()}
            />
            <Button
              style={{
                position: "absolute",
                background: "#fe6e0e",
                right: "50px",
                color: "#fff",
                fontWeight: "bold",
              }}
              onClick={() => document.getElementById("assetDropFile").click()}
            >
              파일찾기
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "400px",
            marginTop: "20px",
          }}
        >
          <Button
            style={{
              background: "#fe6e0e",
              color: "#fff",
              fontWeight: "bold",
              width: "75px",
              height: "40px",
            }}
            onClick={() => {
              setData({ ...INIT });
              setPath("");
            }}
            disabled={
              data.file ||
              data.gold ||
              data.silver ||
              // data.krw ||
              data.point ||
              path
                ? false
                : true
            }
          >
            초기화
          </Button>
          &nbsp;&nbsp;
          <AdminCofirmBtn
            label="저장"
            style={{
              background: "#fe6e0e",
              color: "#fff",
              fontWeight: "bold",
              width: "75px",
              height: "40px",
            }}
            title={"이벤트 자산 발행"}
            disabled={
              selected &&
              data.file &&
              data.gold &&
              data.silver &&
              // data.krw &&
              data.point
                ? false
                : true
            }
            callback={(password) => onClickSave(password)}
          />
          &nbsp;&nbsp;
          <Button
            style={{
              background: "#fe6e0e",
              color: "#fff",
              fontWeight: "bold",
              width: "75px",
              height: "40px",
            }}
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            닫기
          </Button>
        </div>
      </div>
      <div
        style={{
          width: "60%",
          height: "100%",
          display: "flew",
          flexDirection: "row",
          padding: "3px 3px 3px 3px",
          border: "1px solid #ededed",
          position: "relative",
          overflowY: "scroll",
          userSelect: "none",
        }}
      >
        <Document
          file={path}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
          style={{ width: "100%", height: "100%" }}
          noData={
            <span
              style={{
                width: "100%",
                height: "40vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#7d7d7d",
              }}
            >
              PDF파일을 업로드하시면 미리보기 화면이 제공됩니다.
            </span>
          }
          loading={
            <span
              style={{
                width: "100%",
                height: "68vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#7d7d7d",
              }}
            >
              미리보기....
            </span>
          }
        >
          {Array.from({ length: numPages }, (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={true}
              renderTextLayer={true}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default ServiceAssetMint;
