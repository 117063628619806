import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { objToQueryStr, formatTime } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import Filters from "../../components/filters/Filters";
import { getDefaultPageSize } from "../../libs/consts";
import Flex from "../../components/flex/Flex";
import { makeStyles } from "@material-ui/core";
import fonts from "../../libs/fonts";
import { actionError } from "../../redux/action/ActionActions";

export default function ServiceInquiry({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [totalCount, setTotalCount] = useState(0);
  const [qnaList, setQnaList] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [inquiryCount, setInquiryCount] = useState(0);
  const [status, setStatus] = useState(location?.state?.qnaStatus || "ALL");
  const { state = {} } = location;
  // const {} = state;

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  useEffect(() => {
    if (state?.tab === undefined || state?.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        qnaType: "INQUIRED",
        ...getDefaultPageSize(),
      });
    }
  }, [state?.tab]);

  useEffect(() => {
    if (!isNaN(state?.page)) {
      fetchList();
    }
  }, [state?.page, state?.size, status]);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getQnaSummary()
      .then(({ data: { success, message, data } }) => {
        if (success) {
          let inquiredCnt = data?.inquired || 0;
          let penddingCnt = data?.pending || 0;
          setInquiryCount(inquiredCnt);
          setPendingCount(penddingCnt);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  }, []);
  const fetchList = () => {
    const param = { ...state };
    if (param.startDate) {
      param.startDate = `${param.startDate}T00:00:00`;
    }
    if (param.endDate) {
      param.endDate = `${param.endDate}T23:59:59`;
    }

    const paramQuery = objToQueryStr({
      page: state.page,
      size: state.size,
      content: state?.content ? state?.content : "",
      keyword: state?.keyword ? state?.keyword : "",
      startDate: param?.startDate ? param?.startDate : "",
      endDate: param?.endDate ? param?.endDate : "",
      qnAType: state?.qnaType ? state?.qnaType : "",
      status: state?.qnaStatus ? state?.qnaStatus : "",
    });
    dispatch(loadingStart);
    APIS.getQnaList(paramQuery)
      .then(({ data: { success, data, totalElements } }) => {
        if (success) {
          let qnaContent = [...data.content] || [];
          qnaContent = qnaContent.map((item, index) => {
            return {
              ...item,
              status: item.status
                .replace("PENDING", "답변대기")
                .replace("INQUIRED", "처리중")
                .replace("ANSWERED", "답변완료"),
            };
          });
          setQnaList(qnaContent);
          setTotalCount(data.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const onChangeQnaTypeStatus = (key, value) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "" },
    });
    setStatus(value);
  };

  const newList = [
    { label: "답변대기", value: "PENDING" },
    { label: "처리중", value: "INQUIRED" },
    { label: "답변완료", value: "ANSWERED" },
  ];
  return (
    <>
      <PageLabel>고객문의 관리</PageLabel>
      <Filters
        defaultState={{ ...getDefaultPageSize() }}
        onSearch={fetchList}
        type="qnaListSearch"
        data={[
          { label: "내용", type: "input", key: "content" },
          {
            label: "회원정보",
            type: "input",
            key: "keyword",
            placeholder: "이름, 휴대폰",
            autoFocus: true,
          },
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.qnAType}
                  className={classes.selcetBox}
                  onChange={(e) => {
                    handleValueChange("qnaType", e.target.value);
                  }}
                >
                  <option value={"INQUIRED"}>{"문의일자"}</option>
                  <option value={"ANSWERED"}>{"답변일자"}</option>
                </select>
                <input
                  value={state?.startDate || ""}
                  onChange={(e) => {
                    handleValueChange("startDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          // {
          //   data: newList,
          //   type: "menu",
          //   label: "답변처리 상태",
          //   key: "qnaStatus",
          // },
          {
            label: "답변처리 상태",
            type: "checkbox",
            key: "qnaStatus",
            data: newList,
          },
        ]}
      />
      <Flex row className={classes.statusCount}>
        <span
          className={`${
            status === "PENDING"
              ? classes.linkStatusSelected
              : classes.linkStatus
          }`}
        >
          답변대기 :{" "}
          <span onClick={() => onChangeQnaTypeStatus("qnaStatus", "PENDING")}>
            {pendingCount}
          </span>
        </span>
        &nbsp;&nbsp;
        <span
          className={`${
            status === "INQUIRED"
              ? classes.linkStatusSelected
              : classes.linkStatus
          }`}
        >
          처리중 :{" "}
          <span onClick={() => onChangeQnaTypeStatus("qnaStatus", "INQUIRED")}>
            {inquiryCount}
          </span>
        </span>
      </Flex>

      <DataTable
        onClick={(item) => {
          history.push(routes.serviceInquiryDetail, {
            id: item.userId,
            userSocialId: item.userSocialId,
          });
        }}
        data={qnaList}
        totalCount={totalCount}
        columns={[
          {
            label: "문의일자",
            type: "render",
            render: (x) => {
              return x["inquiredAt"]
                ? formatTime(x["inquiredAt"], "YYYY-MM-DD HH:mm")
                : "-";
            },
          },
          {
            label: "답변일자",
            type: "render",
            render: (x) => {
              return x["answeredAt"]
                ? formatTime(x["answeredAt"], "YYYY-MM-DD HH:mm")
                : "-";
            },
          },
          { label: "고객명/이메일", key: "userName" },
          { label: "가입경로", key: "sns" },
          { label: "고객번호", key: "userId" },
          { label: "전화번호", key: "userPhone", type: "phone" },
          { label: "추천매장", key: "recommendShopName" },
          { label: "답변처리 상태", key: "status" },
        ]}
        rowClassName="pointer"
      />
    </>
  );
}

const useStyle = makeStyles({
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "172px",
  },
  selcetBox: {
    minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
  },
  statusCount: {
    marginLeft: "50px",
  },
  linkStatus: {
    "& span": {
      color: "blue",
    },
    cursor: "pointer",
  },
  linkStatusSelected: {
    "& span": {
      color: "brown",
    },
  },
});
