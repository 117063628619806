import {
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
  TablePagination,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import DateRange from "../../components/date-range/DateRange";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function SettlementOutSourcing({}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        page: "1",
      });
    }
  }, [state.tab]);
  if (state.tab === "1") {
    return (
      <>
        <LabelTabs
          data={[
            { label: "정련가공", value: "1" },
            { label: "제작가공", value: "2" },
          ]}
        />
        <Flex>
          <Flex row className={classes.header}>
            <span className={classes.label}>정련내역 등록</span>
            <Button label="저장" />
          </Flex>
          <Flex className={classes.tah1Header}>
            <Grid spacing={2} container className={classes.tah1HeaderGrid}>
              <Grid item xs={1}>
                분류
              </Grid>
              <Grid item xs={2}>
                <select>
                  <option label="전체" />
                  <option label="금" />
                  <option label="은" />
                </select>
              </Grid>
              <Grid item xs={1}>
                중량
              </Grid>
              <Grid item xs={2}>
                <Flex row className={classes.unitInput}>
                  <InputBase />g
                </Flex>
              </Grid>
              <Grid item xs={1}>
                합계금액
              </Grid>
              <Grid item xs={2}>
                <Flex row className={classes.unitInput}>
                  <InputBase />원
                </Flex>
              </Grid>
              <Grid item xs={1}>
                공급가
              </Grid>
              <Grid item xs={2}>
                <Flex row className={classes.unitInput}>
                  <InputBase />원
                </Flex>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={1}>
                부가세
              </Grid>
              <Grid item xs={2}>
                <Flex row className={classes.unitInput}>
                  <InputBase />원
                </Flex>
              </Grid>
            </Grid>
          </Flex>
        </Flex>

        <span
          className={classes.label}
          style={{
            marginTop: 50,
            marginLeft: 50,
            marginBottom: 10,
          }}
        >
          정산내역 조회
        </span>
        <Flex row className={classes.toolbar}>
          <DateRange />
          <Flex row className="row-center">
            분류
            <select style={{ marginLeft: 10 }}>
              <option label="전체" />
              <option label="금" />
              <option label="은" />
            </select>
          </Flex>
        </Flex>

        <Flex row className={classes.stateWrap}>
          <span>상태변경</span>
          <select style={{ marginLeft: 10 }}>
            <option label="완료" />
            <option label="미완료" />
            <option label="취소" />
          </select>
          <Button label="저장" />
        </Flex>
        <Flex className={classes.toolbar2} row>
          <Flex row className={classes.toolbar3}>
            <Text font={fonts.notoSansKRBold}>목록 검색결과 10</Text>
            <Text font={fonts.notoSansKRMedium} style={{ marginLeft: 20 }}>
              합계 : 11,000,000 원
              <Text>(공급가 : 10,000,000 원 / 부가세 : 1,000,000 원)</Text>
            </Text>
          </Flex>

          <Flex row>
            <ButtonBase className={classes.button}>엑셀다운로드</ButtonBase>
          </Flex>
        </Flex>

        <DataTable
          hideLabel
          data={[{}, {}]}
          columns={[
            { label: "날짜" },
            { label: "분류" },
            { label: "중량" },
            { label: "공급가" },
            { label: "부가세" },
            { label: "합계" },
            { label: "정산여부" },
            { label: "처리일시" },
            { label: <Checkbox />, render: (x) => <Checkbox /> },
          ]}
          onClick={(item) => {}}
        />
      </>
    );
  } else {
    return (
      <>
        <LabelTabs
          data={[
            { label: "정련가공", value: "1" },
            { label: "제작가공", value: "2" },
          ]}
        />

        <span
          className={classes.label}
          style={{
            marginTop: 30,
            marginLeft: 50,
            marginBottom: 10,
          }}
        >
          정산내역 조회
        </span>

        <Filters
          data={[
            {
              label: "주문일",
              type: "date-range",
            },
            {
              label: "처리일",
              type: "date-range",
            },
            {
              label: "회원검색",
              type: "input",
              placeholder: "고객명, 연락처, 고객번호",
            },
            {
              label: "분류",
              type: "menu",
              data: [{ label: "전체" }, { label: "금" }, { label: "은" }],
            },
            {
              label: "정산여부",
              type: "menu",
              data: [{ label: "완료" }, { label: "미완료" }, { label: "취소" }],
            },
            {
              label: "제조사",
              type: "menu",
              data: [{ label: "전체" }, { label: "미완료" }, { label: "취소" }],
            },
          ]}
        />

        <RadioGroup
          row
          style={{
            margin: "0px 50px",
            alignSelf: "flex-end",
          }}
        >
          <FormControlLabel
            label="최근 순서로"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
          <FormControlLabel
            label="오래된 순서로"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
        </RadioGroup>

        <Flex row className={classes.stateWrap}>
          <span>상태변경</span>
          <select style={{ marginLeft: 10 }}>
            <option label="완료" />
            <option label="미완료" />
            <option label="취소" />
          </select>
          <Button label="저장" />
        </Flex>
        <Flex className={classes.toolbar2} row>
          <Flex row className={classes.toolbar3}>
            <Text font={fonts.notoSansKRBold}>목록 검색결과 10</Text>
            <Text font={fonts.notoSansKRMedium} style={{ marginLeft: 20 }}>
              합계 : 11,000,000 원
              <Text>(공급가 : 10,000,000 원 / 부가세 : 1,000,000 원)</Text>
            </Text>
          </Flex>

          <Flex row>
            <ButtonBase className={classes.button}>엑셀다운로드</ButtonBase>
          </Flex>
        </Flex>

        <table cellPadding={0} cellSpacing={0} className={classes.table2}>
          <tr>
            <th rowSpan={2}>No</th>
            <th>주문일시</th>
            <th>주문자</th>
            <th rowSpan={2}>분류</th>
            <th rowSpan={2}>카테고리</th>
            <th rowSpan={2}>상품명</th>
            <th rowSpan={2}>수량</th>
            <th rowSpan={2}>제조사</th>
            <th>단위중량</th>
            <th rowSpan={2}>결제중량</th>
            <th rowSpan={2}>추가 결제공임</th>
            <th colSpan={3}>최종 결제공임</th>
            <th rowSpan={2}>정산여부</th>
            <th rowSpan={2}>처리일시</th>
            <th rowSpan={2}>
              <Checkbox />
            </th>
          </tr>

          <tr>
            <th>주문번호</th>
            <th>연락처</th>
            <th>단위수수료</th>
            <th>결제수수료</th>
            <th>수령매장</th>
          </tr>
          {[{}, {}].map((x, i) => {
            return (
              <>
                <tr>
                  <td rowSpan={2}>default</td>
                  <td>default</td>
                  <td>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>default</td>
                  <td>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>default</td>
                  <td colSpan={3}>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>default</td>
                  <td rowSpan={2}>
                    <Checkbox />
                  </td>
                </tr>

                <tr>
                  <td>default</td>
                  <td>default</td>
                  <td>default</td>
                  <td>default</td>
                  <td>default</td>
                </tr>
              </>
            );
          })}
        </table>
        <TablePagination
          page={1}
          count={40}
          onChangePage={() => {}}
          rowsPerPage={10}
          style={{ margin: "0px 50px", borderBottom: "none" }}
        />
      </>
    );
  }
}

const useStyle = makeStyles({
  unitInput: {
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    alignItems: "center",
    padding: "4px 0px",
    paddingRight: 10,
    paddingLeft: 10,
  },
  tah1Header: {
    border: "1px solid #ddd",
    backgroundColor: "#f2f2f2",
    margin: "0px 50px",
    marginTop: 10,
  },
  tah1HeaderGrid: {
    alignItems: "center",
    padding: 20,
    paddingRight: 100,
  },
  label: {
    fontFamily: fonts.notoSansKRBold,
    fontSize: 20,
  },
  label1: {
    margin: "50px",
    color: "black",
    fontSize: 24,
  },
  label2: {
    color: "red",
    marginLeft: 16,
  },
  toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    margin: "0px 50px",
    justifyContent: "space-between",
  },
  toolbar2: {
    alignSelf: "stretch",
    alignItems: "center",
    margin: "0px 50px",
    marginTop: 40,
    justifyContent: "space-between",
  },
  toolbar3: {
    minWidth: "50%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    alignItems: "center",
    height: 40,
    padding: "0px 20px",
    justifyContent: "center",
    color: "#fff",
  },
  header: {
    margin: "0px 50px",
    marginTop: 20,
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stateWrap: {
    alignSelf: "flex-end",
    marginRight: 50,
    marginTop: 50,
    alignItems: "center",
    "& button": {
      marginLeft: 10,
    },
  },
  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    marginTop: 20,
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
});
