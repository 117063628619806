import {
  Button,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionOpen } from "../../redux/action/ActionActions";
import SettingPolicy3Popup from "./SettingPolicy3Popup";

export default function SettingPolicy3({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);

  const handleSave = (bool) => {
    if (bool) {
      setPopup("all");
    } else {
      setPopup(true);
    }
  };

  return (
    <Flex>
      {popup ? (
        <SettingPolicy3Popup
          onClose={() => {
            setPopup(false);
          }}
          isAll={popup === "all"}
        />
      ) : (
        ""
      )}
      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>수수료율 설정</span>
      </Flex>
      <Flex row className={classes.labelWrap2}>
        <span className={classes.label}>일괄설정</span>
        <Button
          variant="outlined"
          title="수정"
          onClick={() => handleSave(true)}
        >
          수정
        </Button>
      </Flex>
      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th>1차 카테고리</th>
          <th>2차 카테고리</th>
          <th>수수료율</th>
        </tr>

        <tr>
          <th rowSpan={3}>골드제품</th>
          <th>골드바</th>
          <td>
            <Button variant="outlined" title="수정" onClick={handleSave}>
              수정
            </Button>
          </td>
        </tr>
        <tr>
          <th>골드바</th>
          <td>
            <Button variant="outlined" title="수정" onClick={handleSave}>
              수정
            </Button>
          </td>
        </tr>
        <tr>
          <th>골드바</th>
          <td>
            <Button variant="outlined" title="수정" onClick={handleSave}>
              수정
            </Button>
          </td>
        </tr>

        <tr>
          <th rowSpan={3}>실버제품</th>
          <th>실버바</th>
          <td>
            <Button variant="outlined" title="수정" onClick={handleSave}>
              수정
            </Button>
          </td>
        </tr>
        <tr>
          <th>실버바</th>
          <td>
            <Button variant="outlined" title="수정" onClick={handleSave}>
              수정
            </Button>
          </td>
        </tr>
        <tr>
          <th>실버바</th>
          <td>
            <Button variant="outlined" title="수정" onClick={handleSave}>
              수정
            </Button>
          </td>
        </tr>
      </table>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },
  labelWrap2: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "start",
    "& button": { width: 100, marginLeft: 20 },
  },

  tdb: {
    backgroundColor: "#f5f5f5",
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    marginRight: "40%",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    width: "80%",
    marginLeft: "10%",

    paddingRight: 10,
  },
  input: {
    marginRight: 10,
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
});
