import React from "react";
import styled from "styled-components";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";

const SmallTitle = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
`;

export default SmallTitle;
