import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
// import Input from "../../components/input/Input";
import {
  Input,
  TextArea,
  RadioButton,
  Checkbox,
} from "@progress/kendo-react-inputs";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import DataTable from "../../components/table/Table";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { objToQueryStr } from "../../services/utils";

const PushNotification = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [target, setTarget] = useState("SELECT");
  const [agreeMarketing, setAgreeMarketing] = useState(true);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const maxLength = 255;

  const handleChangeRadio = (e) => {
    setTarget(e.value);
  };
  const handleChangeCheckBox = () => {
    setAgreeMarketing(!agreeMarketing);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.value);
  };
  const handleChangeMessage = (e) => {
    setMessage(e.value.substr(0, maxLength));
  };

  const handleClickPush = () => {
    const param = { title, message };
    if (!title) {
      dispatch(actionError("푸시 제목을 입력해주세요."));
      return false;
    }

    if (!message) {
      dispatch(actionError("푸시 내용을 입력해주세요."));
      return false;
    }

    if (target === "SELECT") {
      if (selected.length < 1) {
        dispatch(actionError("선택 된 회원이 없습니다."));
        return false;
      }
      param.userIdList = selected.map(({ id }) => id);
      param.adminPushType = "PART";
    } else {
      param.adminPushType = "ALL";
    }
    param.isMarketingAgree = agreeMarketing;
    dispatch(loadingStart);
    APIS.postPush(param)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            actionOpen("푸시가 전송되었습니다.", null, null, null, true)
          );
          setTitle("");
          setMessage("");
          setSelected([]);
          setAgreeMarketing(true);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };
  const handleClickPushCheck = () => {
    if (agreeMarketing) {
      dispatch(
        actionOpen("마케팅 동의자에게만 보냅니다. \n 전송하시겠습니까?", () => {
          handleClickPush();
        })
      );
    } else {
      dispatch(
        actionOpen(
          "마케팅 미동의자에게도 보냅니다. \n 전송하시겠습니까?",
          () => {
            handleClickPush();
          }
        )
      );
    }
  };
  return (
    <PushNotificationContainer row target={target}>
      <Flex className="push-section">
        <Flex row style={{ justifyContent: "flex-end" }}>
          <Button
            fillMode="outline"
            icon="redo"
            style={{ width: 100 }}
            onClick={handleClickPushCheck}
          >
            푸시 전송
          </Button>
        </Flex>

        <table style={{ flex: 1 }}>
          <colgroup>
            <col width="100px" />
          </colgroup>
          <tbody>
            <tr>
              <th>받는 사람</th>
              <td>
                <RadioButton
                  value="ALL"
                  checked={target === "ALL"}
                  label="전체"
                  onChange={handleChangeRadio}
                />
                <RadioButton
                  value="SELECT"
                  checked={target === "SELECT"}
                  label="선택"
                  onChange={handleChangeRadio}
                  style={{ marginLeft: 10 }}
                />
              </td>
            </tr>
            <tr>
              <th>마케팅 동의</th>
              <td>
                <Checkbox
                  checked={agreeMarketing}
                  onClick={handleChangeCheckBox}
                  style={{ marginRight: 5 }}
                />
                마케팅 동의 회원만 수신
              </td>
            </tr>
            <tr>
              <th>푸시 제목</th>
              <td>
                <Input value={title} onChange={handleChangeTitle} />
              </td>
            </tr>
            <tr>
              <th>푸시 내용</th>
              <td style={{ height: "100%" }}>
                <TextArea
                  style={{ height: "calc(100% - 20px)" }}
                  value={message}
                  onChange={handleChangeMessage}
                />
                <Hint direction="end">
                  입력 가능 글자 수 : {message.length} / {maxLength}
                </Hint>
              </td>
            </tr>
          </tbody>
        </table>
      </Flex>
      <SearchSection selected={selected} setSelected={setSelected} />
    </PushNotificationContainer>
  );
};

const SearchSection = ({ selected, setSelected }) => {
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [users, setUsers] = useState([]);
  const [paging, setPaging] = useState({ page: 0, total: 0 });
  const [searchCheckedList, setSearchCheckedList] = useState([]);
  const pagingSize = 30;

  const [selectedCheckedList, setSelectedCheckedList] = useState([]);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const gridSectionRef = useRef(null);
  const selectedSectionRef = useRef(null);

  useEffect(() => {
    fetchSearch({ page: 0 });
  }, []);

  useEffect(() => {
    !selected.length && setSelectedCheckedList([]);
  }, [selected.length]);

  const fetchSearch = ({ page }) => {
    const param = { keyword, size: pagingSize, page, status: "ACTIVE" };

    dispatch(loadingStart);
    APIS.searchAccount(objToQueryStr(param))
      .then(({ data: { success, data } }) => {
        success && setUsers(data?.content || []);
        setSearchCheckedList([]);
        setPaging({ page: data.number, total: data.totalElements });
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleChangePage = ({ page: { skip } }) => {
    fetchSearch({ page: skip / pagingSize });
  };

  const handleClickSearchRow = ({ dataItem: { id } }) => {
    if (searchCheckedList.includes(id)) {
      setSearchCheckedList(searchCheckedList.filter((prevId) => prevId !== id));
    } else {
      setSearchCheckedList([...searchCheckedList, id]);
    }
  };

  const handleClickSelectedRow = ({ dataItem: { id } }) => {
    if (selectedCheckedList.includes(id)) {
      setSelectedCheckedList(
        selectedCheckedList.filter((prevId) => prevId !== id)
      );
    } else {
      setSelectedCheckedList([...selectedCheckedList, id]);
    }
  };

  // 회원 선택
  const handleClickSelect = () => {
    let checkedList = [...searchCheckedList];
    selected.forEach(({ id }) => {
      checkedList = checkedList.filter((checkedId) => id !== checkedId);
    });

    setSelected([
      ...selected,
      ...users.filter(({ id }) => checkedList.includes(id)),
    ]);
    setSearchCheckedList([]);
  };

  // 회원 선택 해제
  const handleClickDeselect = () => {
    setSelected(selected.filter(({ id }) => !selectedCheckedList.includes(id)));
    setSelectedCheckedList([]);
  };

  const handleClickSelectedAllCheck = () => {
    if (selectedCheckedList.length === selected.length) {
      setSelectedCheckedList([]);
    } else {
      setSelectedCheckedList(selected.map(({ id }) => id));
    }
  };

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;

    try {
      const fileContents = await readUploadedFileAsText(file);
      if (!fileContents) return;

      const addList = [];

      const parsingArr = fileContents.split("\n");

      parsingArr.forEach((item, index) => {
        if (item.trim().length === 0) {
          return;
        }
        if (!isNaN(item)) {
          addList.push({ id: +item.trim() });
        }
      });

      const assignList = [...selected, ...addList];

      const originLength = selected.length;

      // 중복 제거
      const uniqueArray = getUniqueObjectArray(assignList, "id");

      setSelected(uniqueArray);
      alert(
        `${parsingArr.length}개의 데이터 중 ${
          uniqueArray.length - originLength
        }개의 데이터가 추가되었습니다.`
      );
    } catch (e) {
      dispatch(actionError("에러가 발생했습니다."));
    }
  };

  const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      if (!inputFile) resolve();

      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };

      temporaryFileReader.readAsText(inputFile);
    });
  };

  const getUniqueObjectArray = (array, key) => {
    return array.filter((item, i) => {
      return (
        array.findIndex((item2, j) => {
          return item[key] === item2[key];
        }) === i
      );
    });
  };

  return (
    <Flex className="search-section" row>
      <Flex style={{ flex: 1, border: "1px solid rgba(0, 0, 0, 0.23)" }}>
        <table>
          <tbody>
            <tr>
              <th>
                <Flex
                  row
                  style={{ justifyContent: "flex-end", position: "relative" }}
                >
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    선택 된 회원 (총 {selected.length}명)
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      fillMode="outline"
                      style={{ width: 80 }}
                      onClick={() => inputFileRef?.current?.click()}
                      onMouseEnter={() => setPopoverVisible(true)}
                      onMouseLeave={() => setPopoverVisible(false)}
                    >
                      파일 첨부
                    </Button>
                    <input
                      style={{
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        position: "absolute",
                        zIndex: -1,
                        opacity: 0,
                      }}
                      type="file"
                      ref={inputFileRef}
                      accept="text/plain"
                      onChange={handleChangeFile}
                    />
                    <Popover
                      show={popoverVisible}
                      anchor={inputFileRef.current}
                      position={"bottom"}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th>File Format</th>
                            <td>.txt</td>
                          </tr>
                          <tr>
                            <th>양식</th>
                            <td>
                              첨부 할 회원목록의 고객번호를
                              <br />
                              줄바꿈하여 입력해주세요.
                            </td>
                          </tr>
                          <tr>
                            <th>예시</th>
                            <td>
                              100
                              <br />
                              101
                              <br />
                              102
                              <br />
                              103
                              <br />
                              104
                              <br />
                              105
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Popover>
                  </div>
                </Flex>
              </th>
            </tr>
          </tbody>
        </table>
        <div ref={selectedSectionRef} style={{ flex: 1 }}>
          <Grid
            style={{ height: selectedSectionRef.current?.clientHeight }}
            scrollable="scrollable"
            data={selected}
            onRowClick={handleClickSelectedRow}
          >
            <GridNoRecords>선택 된 회원이 없습니다.</GridNoRecords>
            <GridColumn
              width={40}
              className="center"
              cell={(e) => {
                return (
                  <td>
                    <Checkbox
                      checked={selectedCheckedList.includes(e.dataItem.id)}
                      onClick={() => handleClickSelectedRow(e)}
                    />
                  </td>
                );
              }}
              headerCell={() => {
                return (
                  <Checkbox
                    checked={
                      selectedCheckedList.length === selected.length &&
                      selected.length
                    }
                    onClick={handleClickSelectedAllCheck}
                  />
                );
              }}
            />
            <GridColumn
              field="id"
              title="고객번호"
              width={80}
              className="center"
            />
            <GridColumn field="name" title="이름" />
            <GridColumn field="phone" title="휴대폰" />
            <GridColumn field="recommendShopName" title="추천매장" />
          </Grid>
        </div>
      </Flex>
      <Flex style={{ justifyContent: "center", padding: 8 }}>
        <Button
          fillMode="outline"
          icon="arrow-chevron-left"
          style={{ width: 80 }}
          onClick={handleClickSelect}
        />
        <Button
          fillMode="outline"
          icon="arrow-chevron-right"
          style={{ width: 80, marginTop: 8 }}
          onClick={handleClickDeselect}
        />
      </Flex>
      <Flex style={{ flex: 1, border: "1px solid rgba(0, 0, 0, 0.23)" }}>
        <table>
          <colgroup>
            <col width="100px" />
          </colgroup>
          <tbody>
            <tr>
              <th>회원 검색</th>
              <td>
                <Flex row>
                  <Input
                    value={keyword}
                    onChange={({ value }) => setKeyword(value)}
                    placeholder="이름, 휴대폰, 고객번호, AD-ID"
                    onKeyPress={(e) => {
                      if (e?.nativeEvent?.key === "Enter") {
                        fetchSearch({ page: 0 });
                      }
                    }}
                  />
                  <Button
                    fillMode="outline"
                    icon="search"
                    style={{ width: 100, marginLeft: 8 }}
                    onClick={() => fetchSearch({ page: 0 })}
                  />
                </Flex>
              </td>
            </tr>
          </tbody>
        </table>
        <div ref={gridSectionRef} style={{ flex: 1 }}>
          <Grid
            style={{ height: gridSectionRef.current?.clientHeight }}
            scrollable="scrollable"
            data={users}
            pageable
            total={paging.total}
            pageSize={pagingSize}
            skip={paging.page * pagingSize}
            onPageChange={handleChangePage}
            onRowClick={handleClickSearchRow}
          >
            <GridNoRecords>검색 결과가 없습니다.</GridNoRecords>
            <GridColumn
              width={40}
              className="center"
              cell={(e) => {
                return (
                  <td>
                    <Checkbox
                      checked={searchCheckedList.includes(e.dataItem.id)}
                      onClick={() => handleClickSearchRow(e)}
                    />
                  </td>
                );
              }}
            />
            <GridColumn
              field="id"
              title="고객번호"
              width={80}
              className="center"
            />
            <GridColumn field="name" title="이름" />
            <GridColumn field="phone" title="휴대폰" />
            <GridColumn field="recommendShopName" title="추천매장" />
          </Grid>
        </div>
      </Flex>
    </Flex>
  );
};

const PushNotificationContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 13px;

  table {
    width: 100%;
  }

  .center {
    text-align: center;
  }

  div.push-section {
    transition: all 1s;
    width: ${({ target }) => (target === "ALL" ? "100%" : "30%")};

    table {
      margin-top: 8px;
      height: 100%;
      width: 100%;
      border-collapse: collapse;
      th {
        border: 1px solid rgba(0, 0, 0, 0.23);
        text-align: center;
        background-color: #f5f5f5;
      }
      td {
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 8px;
      }
    }
  }

  div.search-section {
    transition: all 1s;
    position: absolute;
    width: calc(70% - 8px);
    height: 100%;
    right: ${({ target }) => (target === "ALL" ? "-70%" : 0)};
  }

  textarea {
    resize: none;
  }
`;
export default PushNotification;
