export default {
  white: "#ffffff",
  black: "#000000",
  primary: "#fe6e0e",
  text: "#333333",
  dddddd: "#dddddd",
  gray: "#666666",
  red: "rgb(231,28,28)",
  backgroundGray: "rgb(200,200,200)",
  textBlue: "#2476c2",
  lightGray: "#999999",
};
