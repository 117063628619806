import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../components/flex/Flex";
import fonts from "../libs/fonts";

const withProgressBar = (WrapperComponent) => {
  const Component = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const progressBar = {
      on: () => {
        setIsLoading(true);
      },
      off: () => {
        setIsLoading(false);
      },
    };

    return (
      <ProgressBarContainer>
        <WrapperComponent {...props} progressBar={progressBar} />
        {isLoading && (
          <ProgressBarSection>
            <span className="text">LOADING</span>
            <span className="k-icon k-i-loading" />
          </ProgressBarSection>
        )}
      </ProgressBarContainer>
    );
  };
  return Component;
};

const ProgressBarContainer = styled.div`
  position: relative;
`;

const ProgressBarSection = styled.div`
  z-index: 10;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .text {
    font-family: ${fonts.notoSansKRBold};
    margin-bottom: 5px;
  }
`;

export default withProgressBar;
