import { Dialog } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeImageModal } from "../../redux/image-modal/ImageModalReducer";

export default function ImageModal({}) {
  const dispatch = useDispatch();
  const { open, src } = useSelector((s) => s.imageModal);

  const handleClose = () => {
    dispatch(closeImageModal());
  };
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      {open && (
        <img
          src={src}
          alt="modal-image"
          style={{
            backgroundColor: "#000",
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      )}
    </Dialog>
  );
}
