import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Text from "../text/Text";

export default function Button({
  style,
  label,
  onClick,
  className,
  classNameLabel,
  disabled
}) {
  const classes = useStyle();
  return (
    <>
    {disabled ? (  
      <ButtonBase
      onClick={onClick}
      style={style}
      className={`${classes.button} ${className}`}
      disabled
    >
      <Text
        font={fonts.notoSansKRBold}
        className={classes.label + " " + classNameLabel}
      >
        {label}
      </Text>
    </ButtonBase>
    ) : (  
    <ButtonBase
      onClick={onClick}
      style={style}
      className={`${classes.button} ${className}`}
    >
      <Text
        font={fonts.notoSansKRBold}
        className={classes.label + " " + classNameLabel}
      >
        {label}
      </Text>
    </ButtonBase>
    )}
    </>
  );
}

const useStyle = makeStyles({
  label: {
    color: colors.white,
    fontSize: 15,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    boxSizing: "border-box",
    flexDirection: "row",
    border: "1px solid white",
    borderRadius: "3px",
    backgroundColor: colors.black,
  },
});
