import banner from "../assets/images/banner.png";
import logo from "../assets/images/logo.png";
import logo_new from "../assets/images/logo_new.png";
import plus from "../assets/images/plus.png";

export default {
  banner,
  logo,
  plus,
  logo_new,
};
