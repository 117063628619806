const OPEN = "dialog/OPEN";
const CLOSE = "dialog/CLOSE";

const INIT_STATE = {
  show: false,
  type: "confirm",
  title: "알림",
  message: "",
  action: "",
};

export default function dialogAlert(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case OPEN:
      return { ...INIT_STATE, show: true, ...action.payload };
    case CLOSE:
      return { ...INIT_STATE, show: false };
    default:
      return state;
  }
}

export function openDialog(payload) {
  return { type: OPEN, payload };
}

export function closeDialog() {
  return { type: CLOSE };
}
