import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Flex from "../../../components/flex/Flex";
import DateInput from "../../../components/input/DateInput";
import TextInput from "../../../components/input/TextInput";
import DropDownList from "../../../components/select/DropDownList";
import withProgressBar from "../../../hoc/withProgressBar";
import SearchLayout, { SearchItem } from "../../../layouts/search/SearchLayout";
import * as APIS from "../../../libs/apis";
import GridTable, {
  CenterCell,
  KendoCell,
  NumberCell,
} from "../../../components/gird-table/GridTable";
import { GridColumn } from "@progress/kendo-react-grid";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { actionError, actionOpen } from "../../../redux/action/ActionActions";
import { formatTime, numberWithCommas } from "../../../libs/utils";
import KendoButton from "../../../components/button/KendoButton";
import ButtonTabs from "../../../components/tabs/ButtonTabs";
import StyledTable from "../../../components/table/StyledTable";
import { saveAs } from "file-saver";
import { Popup } from "@progress/kendo-react-popup";
import { RadioButton, RadioGroup } from "@progress/kendo-react-inputs";

const gridName = "InventoryReciving";

const ORDER_STATUS_OPTIONS = [
  { value: "REQUEST", label: "주문요청" },
  { value: "COMPLETE", label: "주문완료" },
];

const INIT_SEARCH_STATE = {
  dateType: "CREATED",
  startDate: "",
  endDate: "",
  inventoryOrderStatus: "REQUEST",
  category: "",
  goldOrSilver: "",
  outsourcing: "",
  goodsName: "",
  orderType: "GOODS",
  sort: "DESC",
};

const ASSET_TYPE = [
  { label: "금", value: "GOLD" },
  { label: "은", value: "SILVER" },
];

const InventoryReceiving = withProgressBar(({ progressBar }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const locationState = history.location.state || {};
  const [categories, setCategories] = useState([]);
  const [searchState, setSearchState] = useState(INIT_SEARCH_STATE);
  const { page, size = 100 } = locationState;
  const [total, setTotal] = useState(0);
  const [stocks, setStocks] = useState([]);
  const [summary, setSummary] = useState({});
  const [popupState, setPopupState] = useState({
    show: false,
    offset: { top: 0, right: 0 },
  });

  useEffect(() => {
    progressBar.on();
    APIS.getGoodsCategoryList()
      .then(({ data: { success, data } }) => {
        success &&
          setCategories(
            data.map(({ id, kind, goldOrSilver }) => ({
              value: id,
              label: kind,
              type: goldOrSilver,
            }))
          );
      })
      .finally(progressBar.off);
  }, []);

  // update state => only update state, 조회 x
  // update location state => update location state + 조회
  useEffect(() => {
    if (locationState.page || locationState.page === 0) {
      getStocks({ ...locationState, size });
    }
  }, [locationState]);

  const {
    dateType,
    startDate,
    endDate,
    inventoryOrderStatus,
    category,
    goldOrSilver,
    outsourcing,
    goodsName,
    orderType,
    sort,
  } = searchState;

  useEffect(() => {
    handleChangeSearchState({ category: "" });
  }, [goldOrSilver]);

  const handleChangeSearchState = (newState) => {
    setSearchState({ ...searchState, ...newState });
  };

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleClickSearch = () => {
    handleChangeLocationState({ ...searchState, page: 0 });
  };

  const getStocks = (param) => {
    setStocks([]);
    setTotal(0);
    setSummary({});
    progressBar.on();
    Promise.all([
      APIS.getInventoryOrderStocks(param).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            setStocks(
              data.content.map((item) => {
                const orderStatus =
                  ORDER_STATUS_OPTIONS.find(
                    ({ value }) => value === item.orderStatus
                  ) || {};

                return {
                  ...item,
                  orderQuantity: item.quantity,
                  quantity: 0,
                  orderStatusKor: orderStatus.label || "",
                };
              })
            );
            setTotal(data.totalElements);
          } else {
            dispatch(actionError(message));
          }
        }
      ),
      APIS.getInventoryOrderSummarize(param).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            setSummary(data);
          } else {
            dispatch(actionError(message));
          }
        }
      ),
    ]).then(progressBar.off);
  };

  const getStocksExcel = (param) => {
    progressBar.on();
    APIS.getInventoryOrderStocksExcel(param)
      .then(({ data }) => {
        const blob = new Blob([data]);
        saveAs(
          blob,
          `상품입고포장_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .finally(progressBar.off);
  };

  const handleClickExcel = () => {
    getStocksExcel({ ...locationState });
  };

  const handleClickReceiving = () => {
    const filtered = stocks.filter(({ quantity }) => quantity);
    if (!filtered.length) {
      dispatch(actionError("수량을 입력해주세요."));
      return;
    }

    const overValueItem = filtered.find(
      ({ orderQuantity, quantity, stockedQty }) =>
        orderQuantity - stockedQty < quantity
    );

    if (overValueItem) {
      dispatch(
        actionError(
          `${overValueItem.goodsName}의 입고수량을 확인해주세요.\n입고가능수량 = 주문수량 - 기입고수량`
        )
      );
      return;
    }

    progressBar.on();
    APIS.postInventoryOrderStocksProcess(
      filtered.map(({ id, quantity, pay, memo, optionId }) => {
        return { id, quantity, unitPrice: pay, memo, optionId };
      })
    ).then(({ data: { success, message } }) => {
      if (success) {
        dispatch(
          actionOpen(
            "입고 처리가 완료되었습니다.",
            () => getStocks({ ...locationState, size }),
            null,
            null,
            true
          )
        );
      } else {
        dispatch(
          actionOpen(
            message,
            () => getStocks({ ...locationState, size }),
            null,
            null,
            true
          )
        );
      }
    });
    progressBar.off();
  };

  const handleChangeTabs = (tab) => {
    if (tab !== orderType) {
      handleChangeSearchState({ orderType: tab });
      handleChangeLocationState({ ...searchState, orderType: tab, page: 0 });
    }
  };

  const handlePopupShow = ({ target, item, field }) => {
    const rect = target.getBoundingClientRect();
    const top = window.pageYOffset + rect.bottom + 10;

    const left = window.pageXOffset + rect.left;

    setPopupState({
      show: true,
      offset: { top, left },
      item,
    });
  };

  const handlePopupClose = () => {
    setPopupState({ ...popupState, show: false });
  };

  const grid = useMemo(() => {
    return (
      <GridTable
        className="grid"
        name={gridName}
        reorderable
        style={{ height: 600 }}
        data={stocks}
        scrollable
        pageable
        skip={page * size}
        take={size}
        total={total}
        onPageChange={({ page: { skip, take: size } }) => {
          const page = skip / size;
          if (locationState.page !== page || locationState.size !== size) {
            handleChangeLocationState({ page, size });
          }
        }}
      >
        <GridColumn title="주문일" field="orderAt" width={160} />
        <GridColumn title="제조사" field="outsourcing" />
        <GridColumn title="상품명" field="goodsName" />
        <GridColumn
          title="옵션"
          field="optionName"
          width={100}
          cell={(props) => {
            const { optionName = "" } = props.dataItem;
            return (
              <KendoCell {...props}>{optionName ? optionName : "-"}</KendoCell>
            );
          }}
        />
        <GridColumn
          title="단위중량"
          field="unitWeightGram"
          cell={NumberCell}
          width={80}
        />
        <GridColumn
          title="주문수량"
          field="orderQuantity"
          cell={NumberCell}
          width={80}
        />
        <GridColumn
          title="총중량"
          field="totalWeightGram"
          cell={NumberCell}
          width={80}
        />
        <GridColumn
          title="공임단가"
          field="pay"
          width={80}
          cell={(props) => {
            const propValue = props.dataItem[props.field];
            const [value, setValue] = useState(propValue || "");

            useEffect(() => {
              setValue(propValue);
            }, [propValue]);

            const { orderStatus } = props.dataItem;
            if (orderStatus === "COMPLETE") {
              return <NumberCell {...props} />;
            }
            return (
              <CenterCell {...props}>
                <TextInput
                  type="number"
                  value={numberWithCommas(value)}
                  style={{ textAlign: "right", width: 70 }}
                  onChange={(value) => {
                    const replaceValue = value.replace(/,/gi, "");
                    const numberValue = replaceValue ? +replaceValue : "";
                    stocks[props.dataIndex][props.field] = numberValue;
                    setValue(numberValue);
                  }}
                  placeholder={0}
                />
              </CenterCell>
            );
          }}
        />
        <GridColumn
          title="총비용"
          field="totalPay"
          cell={NumberCell}
          width={100}
        />
        <GridColumn title="상품">
          <GridColumn
            title="수량"
            field="quantity"
            width={80}
            cell={(props) => {
              const propValue = props.dataItem[props.field];

              const [value, setValue] = useState(propValue || "");

              useEffect(() => {
                setValue(propValue);
              }, [propValue]);

              const { orderStatus } = props.dataItem;
              if (orderStatus === "COMPLETE") {
                return <NumberCell {...props} />;
              }

              return (
                <CenterCell {...props}>
                  <TextInput
                    type="number"
                    value={numberWithCommas(value)}
                    style={{ textAlign: "right", width: 70 }}
                    onChange={(value) => {
                      const replaceValue = value.replace(/,/gi, "");
                      const numberValue = replaceValue ? +replaceValue : "";
                      stocks[props.dataIndex][props.field] = numberValue;
                      setValue(numberValue);
                    }}
                    placeholder={0}
                  />
                </CenterCell>
              );
            }}
          />
          <GridColumn
            title="기입고"
            field="stockedQty"
            cell={NumberCell}
            width={80}
          />
        </GridColumn>
        <GridColumn
          title="상태"
          field="orderStatusKor"
          cell={CenterCell}
          width={100}
        />
        <GridColumn title="입고일" field="stockedAt" width={160} />
        <GridColumn
          title="메모"
          field="memo"
          cell={(props) => {
            const { memo } = props.dataItem;

            const [value, setValue] = useState(memo || "");

            useEffect(() => {
              setValue(memo);
            }, [memo]);

            const { orderStatus } = props.dataItem;
            if (orderStatus === "COMPLETE") {
              return <KendoCell {...props} />;
            }

            return (
              <CenterCell {...props}>
                <TextInput
                  value={value}
                  onChange={(value) => {
                    stocks[props.dataIndex][props.field] = value;
                    setValue(value);
                  }}
                />
              </CenterCell>
            );
          }}
        />
        <GridColumn
          title="입고이력"
          cell={(props) => {
            return (
              <CenterCell {...props}>
                <KendoButton
                  label="부분입고이력"
                  onClick={({ target }) => {
                    handlePopupShow({ target, item: props.dataItem });
                  }}
                />
              </CenterCell>
            );
          }}
          width={120}
        />
      </GridTable>
    );
  }, [stocks, total]);

  return (
    <InventoryReceivingContainer>
      <SearchLayout
        title="제작 입고"
        onSearch={handleClickSearch}
        onReset={() => handleChangeSearchState(INIT_SEARCH_STATE)}
      >
        <Flex row>
          <SearchItem header="조회기간">
            <DropDownList
              options={[
                { label: "주문일", value: "CREATED" },
                { label: "입고일", value: "STOCKED" },
              ]}
              selected={dateType}
              onChange={(dateType) => handleChangeSearchState({ dateType })}
              style={{ width: "auto" }}
            />
            <DateInput
              value={startDate}
              onChange={(startDate) => handleChangeSearchState({ startDate })}
              max={formatTime()}
            />{" "}
            ~{" "}
            <DateInput
              value={endDate}
              onChange={(endDate) => handleChangeSearchState({ endDate })}
              min={startDate}
            />
          </SearchItem>
          <SearchItem header="상태">
            <DropDownList
              useAll
              options={ORDER_STATUS_OPTIONS}
              selected={inventoryOrderStatus}
              onChange={(inventoryOrderStatus) =>
                handleChangeSearchState({ inventoryOrderStatus })
              }
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="제조사">
            <TextInput
              value={outsourcing}
              onChange={(outsourcing) =>
                handleChangeSearchState({ outsourcing })
              }
            />
          </SearchItem>
          <SearchItem header="카테고리">
            <DropDownList
              useAll
              options={ASSET_TYPE}
              selected={goldOrSilver}
              onChange={(goldOrSilver) =>
                handleChangeSearchState({ goldOrSilver })
              }
            />
            <DropDownList
              useAll
              options={categories.filter((item) => item.type === goldOrSilver)}
              selected={category}
              onChange={(category) => handleChangeSearchState({ category })}
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="상품">
            <TextInput
              value={goodsName}
              onChange={(goodsName) => handleChangeSearchState({ goodsName })}
            />
          </SearchItem>
          <SearchItem header="" />
        </Flex>
      </SearchLayout>

      <Flex style={{ position: "relative", marginTop: 10 }}>
        <ButtonTabs
          options={[
            { label: "제작", value: "GOODS" },
            { label: "포장", value: "PACKING" },
          ]}
          selected={orderType}
          onSelect={handleChangeTabs}
        />
        <Flex className="sort-section">
          <RadioGroup
            data={[
              {
                label: "최근 순서로",
                value: "DESC",
              },
              {
                label: "오래된 순서로",
                value: "ASC",
              },
            ]}
            layout="horizontal"
            value={sort}
            onChange={({ value }) => {
              if (value !== sort) {
                handleChangeSearchState({ sort: value });
                handleChangeLocationState({
                  ...searchState,
                  sort: value,
                  page: 0,
                });
              }
            }}
          />
        </Flex>
      </Flex>

      <Flex row className="content-header">
        <Flex>
          {!!summary.totalCnt && (
            <StyledTable>
              <Flex row>
                <Flex className="header">총 주문</Flex>
                <Flex className="data">
                  {numberWithCommas(summary.totalCnt)} 건
                </Flex>
                <Flex className="header">총 중량</Flex>
                <Flex className="data">
                  {numberWithCommas(summary.goodsTotalWeight)} g
                </Flex>
                {orderType !== "PACKING" && (
                  <>
                    <Flex className="header">로스 중량</Flex>
                    <Flex className="data">
                      {numberWithCommas(summary.lossTotalWeight)} g
                    </Flex>
                  </>
                )}
                <Flex className="header">합계비용(VAT 제외)</Flex>
                <Flex className="data">
                  {`${numberWithCommas(summary.goodsTotalPrice)} 원`}
                </Flex>
              </Flex>
            </StyledTable>
          )}
        </Flex>
        <Flex row>
          <KendoButton icon="excel" label="Excel" onClick={handleClickExcel} />
          <KendoButton
            label="입고"
            onClick={handleClickReceiving}
            style={{ marginLeft: 10 }}
          />
        </Flex>
      </Flex>
      {grid}
      <HistoryPopup {...popupState} onClose={handlePopupClose} />
    </InventoryReceivingContainer>
  );
});

const HistoryPopup = ({ show = true, offset, onClose, item = {} }) => {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    if (item.id && show) {
      setHistories([]);
      APIS.getInventoryOrderHistory({
        orderId: item.id,
        orderType: item.orderType,
      }).then(({ data: { success, data } }) => {
        success && setHistories(data);
      });
    }
  }, [item.id, show]);

  return (
    <Popup
      show={show}
      offset={offset}
      style={{ width: 300, zIndex: "auto" }}
      onOpen={() => {
        popupRef.current.focus();
        console.log("focused");
      }}
    >
      <HistoryPopupContainer
        ref={popupRef}
        tabIndex={0}
        onBlur={() => {
          console.log("3123");
          onClose();
        }}
      >
        <Flex className="title">입고이력</Flex>
        <StyledTable>
          <Flex row>
            <Flex className="header">입고일시</Flex>
            <Flex className="header">입고개수</Flex>
          </Flex>
          {histories.map(({ goodsStockedAt, stockedQuantity }, i) => (
            <Flex row key={i.toString()}>
              <Flex className="data">{goodsStockedAt}</Flex>
              <Flex className="data">{numberWithCommas(stockedQuantity)}</Flex>
            </Flex>
          ))}
          {!histories.length && (
            <Flex className="data">입고 이력이 없습니다.</Flex>
          )}
        </StyledTable>
      </HistoryPopupContainer>
    </Popup>
  );
};

const InventoryReceivingContainer = styled(Flex)`
  flex: 1;
  padding: 10px;

  .sort-section {
    position: absolute;
    right: 0;
    height: 100%;
    justify-content: center;
  }

  .content-header {
    margin-top: 10px;
    justify-content: space-between;

    .header {
      width: 135px;
    }
    .data {
      min-width: 140px;
      white-space: nowrap;
      justify-content: center;
    }
  }
  .grid {
    margin-top: 5px;
  }
`;

const HistoryPopupContainer = styled(Flex)`
  .title {
    background: #f2f4f6;
    align-items: center;
    padding: 5px 0;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #e2e5e8;
  }

  .header,
  .data {
    flex: 1;
    justify-content: center;
  }
`;

export default InventoryReceiving;
