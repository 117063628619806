import React from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "components/filters/Filters";
import PageLabel from "components/page-label/PageLabel";
import DataTable from "components/table/Table";
import routes from "libs/routes";

export default function MemberDormant({}) {
  const history = useHistory();
  return (
    <>
      <PageLabel>휴면 회원</PageLabel>
      <Filters
        data={[
          { label: "가입일", type: "date-range", key: "date" },
          {
            label: "검색",
            type: "input",
            placeholder: "이름,닉네임,휴대폰,이메일",
            key: "email",
          },
        ]}
      />
      <DataTable
        data={[{}, {}]}
        columns={[
          { label: "가입일" },
          { label: "이름" },
          { label: "닉네임" },
          { label: "성별" },
          { label: "생년월일" },
          { label: "sns" },
          { label: "ID" },
          { label: "추천매장" },
          { label: "금(GOLD)" },
          { label: "은(SILVER)" },
          { label: "보유원화" },
        ]}
        onExcelDownload={() => {}}
        onClick={(item) => {
          history.push({
            pathname: routes.memberDetail,
            state: {
              userId: 1,
            },
          });
        }}
      />
    </>
  );
}
