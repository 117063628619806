import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Switch,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { objToQueryStr, formatTime, formatPhone } from "../../services/utils";
import Button from "../../components/button/Button";
import DataTable from "../../components/table/Table";
import fonts from "../../libs/fonts";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import Filters from "../../components/filters/Filters";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import {
  actionOpen,
  actionError,
  actionClose,
} from "../../redux/action/ActionActions";
import { getDefaultPageSize, STORAGE_KEY } from "../../libs/consts";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import { TurnedIn } from "@material-ui/icons";

export default function ManagerManagement(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const [managerList, setManagerList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [shopList, setShopList] = useState([]);
  const [authGroupList, setAuthGroupList] = useState([]);
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const handleCreateManager = () => {
    dispatch(
      openModal({
        visiable: true,
        children: <CreateManager />,
      })
    );
  };
  const handleEditManager = (id) => {
    dispatch(
      openModal({
        visiable: true,
        children: <EditManager id={id} fetchAdminList={fetchList} />,
      })
    );
  };

  useEffect(() => {
    //그룹 리스트
    authorityGroupList();

    //매장 리스트
    getShopList();

    //관리자 리스트
    fetchList();
  }, [state.page, state.size]);

  const getShopList = () => {
    dispatch(loadingStart);
    APIS.getShopList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };
  const authorityGroupList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityGroupList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setAuthGroupList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const fetchList = () => {
    const param = {
      keyword: state.keyword,
      groupId: state.groupName,
      shopName: state.shopName,
      page: state.page || 0,
      size: state.size || 10,
    };
    const paramQuery = objToQueryStr(param);
    dispatch(loadingStart);
    APIS.getAdminList(paramQuery)
      .then(({ data: { success, data, totalElements } }) => {
        if (success) {
          const reformData = (data) => {
            let copyData = [...data] || [];
            copyData = copyData.map((item) => {
              return {
                ...item,
                status: item.status.replace("DISABLED", "BLIND"),
              };
            });
            return copyData;
          };
          setManagerList(reformData(data.content));
          setTotalCount(data.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const validationData = () => {
    if (state.selected?.length === 0 || state?.selected === undefined) {
      return "계정을 선택해주세요.";
    }
    return "";
  };
  const handleChangeStatus = (value) => {
    if (state?.selected?.length !== 0) {
      dispatch(loadingStart);

      const copySelected = [...state.selected];
      let data = {
        managerIdList: copySelected,
      };

      APIS.patchAdminChangeStatus(data)
        .then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(
              actionOpen("상태를 바꾸지 못하였습니다", null, null, null, true)
            );
          } else {
            dispatch(
              actionOpen(
                "해당 관리자의 상태를 바꾸었습니다",
                () => {
                  const reformData = (data) => {
                    let copyData = [...data] || [];
                    copyData = copyData.map((item) => {
                      return {
                        ...item,
                        status: item.status.replace("DISABLED", "BLIND"),
                      };
                    });
                    return copyData;
                  };
                  handleValueChange("selected", []);
                  props.history.go(0);
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    } else {
      const errorMessage = validationData();
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }
  };
  return (
    <>
      <PageLabel>관리자관리</PageLabel>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center"></Flex>
          <Flex row>
            {/* <Button
              label="활성/비활성"
              value="ACTIVE"
              className={classes.button}
              onClick={() => handleChangeStatus()}
            /> */}
            <Button
              label="관리자 생성"
              value="CREATE"
              className={classes.button}
              onClick={handleCreateManager}
            />
          </Flex>
        </Flex>
      </Flex>
      <Filters
        defaultState={{
          page: 0,
          size: localStorage.getItem(STORAGE_KEY.tableSize) || 10,
          selected: [],
          groupName: "",
          shopName: "",
        }}
        onSearch={fetchList}
        type="noticeSearch"
        data={[
          {
            data: authGroupList.map((item, index) => {
              return { label: item.groupName, value: item.id };
            }),
            type: "render",
            label: "그룹 검색",
            key: "groupName",
            render: (
              <Flex row>
                <select
                  style={{ minWidth: 50, fontSize: 16 }}
                  value={location.state?.["groupName"]}
                  onChange={(e) => {
                    handleValueChange("groupName", e.target.value);
                  }}>
                  <option value="" key="total">
                    전체
                  </option>
                  {authGroupList?.map((item, index) => {
                    return (
                      <option value={+item.id} key={index}>
                        {item.groupName}
                      </option>
                    );
                  })}
                </select>
              </Flex>
            ),
          },
          {
            label: "관리자 정보",
            wrap: true,
            type: "input",
            key: "keyword",
            placeholder: "이름, 핸드폰, 계정 ID",
            autoFocus: true,
          },
          {
            data: shopList.map((item, index) => {
              return { label: item.shopName, value: item.shopName };
            }),
            type: "render",
            label: "매장 검색",
            key: "shopName",
            render: (
              <Flex row>
                <select
                  style={{ minWidth: 50, fontSize: 16 }}
                  value={location.state?.["shopName"]}
                  onChange={(e) => {
                    handleValueChange("shopName", e.target.value);
                  }}>
                  <option value="" key="total">
                    전체
                  </option>
                  {shopList?.map((item, index) => {
                    return (
                      <option value={item.shopName} key={index}>
                        {item.shopName}
                      </option>
                    );
                  })}
                </select>
              </Flex>
            ),
          },
        ]}
      />
      <DataTable
        data={managerList}
        totalCount={totalCount}
        // useCheckbox
        onChangeCheckbox={(e) => {
          e.stopPropagation();
        }}
        columns={[
          { label: "상태", key: "status" },
          { label: "이름", key: "name" },
          { label: "계정 ID", key: "account" },
          { label: "휴대전화", key: "phone", type: "phone" },
          { label: "매장이름", key: "shopName" },
          { label: "그룹이름", key: "groupName" },
          { label: "생성날짜", key: "createdAt", type: "date" },
          {
            label: "관리",
            render: ({ id }) => {
              return (
                <Button
                  className={classes.btnDetail}
                  label="상세"
                  onClick={() => {
                    handleEditManager(id);
                  }}
                />
              );
            },
          },
        ]}
      />
    </>
  );
}

const CreateManager = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [shopList, setShopList] = useState([]);
  const [authGroupList, setAuthGroupList] = useState([]);
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [adminName, setAdminName] = useState("");
  const [phone, setPhone] = useState("");
  const history = useHistory();
  const location = useLocation();
  const { state = {} } = location;
  const {} = state;
  const [pwdCheckSwitch, setpwdCheckSwitch] = useState(true);
  const [phoneCheckSwitch, setPhoneCheckSwitch] = useState(true);
  const [checkRedundancySwitch, setcheckRedundancySwitch] = useState(true);
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const onChangeValue = ({ target }) => {
    const { name, value } = target;
    if (name === "password") {
      const passwordCheck = value;
      if (password !== passwordCheck) {
        setpwdCheckSwitch(false);
      } else {
        setpwdCheckSwitch(true);
        setPasswordCheck(value);
        handleValueChange("passwordCheck", value);
      }
    } else if (name === "phone") {
      const phoneCheck = value;
      if (!Number(phoneCheck) && Number(phoneCheck) !== 0) {
        setPhoneCheckSwitch(false);
      } else {
        setPhoneCheckSwitch(true);
        setPhone(value);
        handleValueChange("phone", value);
      }
    } else if (name === "account") {
      //중복 오류체크 변화있을 때
      setAccount(value);
      if (value || checkRedundancySwitch === false) {
        setcheckRedundancySwitch(false);
      } else {
        setcheckRedundancySwitch(true);
      }
    }
  };
  const checkRedundancy = () => {
    dispatch(loadingStart);
    if (!account || account === "") {
      dispatch(actionError("계정 ID를 입력해주세요."));
    } else {
      const data = { account: account };
      APIS.postCheckRedundancy(data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(actionError(data));
            setcheckRedundancySwitch(true);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const getShopList = () => {
    dispatch(loadingStart);
    APIS.getShopList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };
  const authorityGroupList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityGroupList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setAuthGroupList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };
  const handleCreateAdmin = (adminPassword) => {
    if (!state.groupId || state.name === "") {
      dispatch(actionError("그룹을 선택해주세요."));
    } else if (!state.shopId || state.name === "") {
      dispatch(actionError("매장을 선택해주세요."));
    } else if (!account || account === "") {
      dispatch(actionError("계정 ID를 입력해주세요."));
    } else if (!state.password || state.password === "") {
      dispatch(actionError("비밀번호를 입력해주세요."));
    } else if (!state.passwordCheck || state.passwordCheck === "") {
      dispatch(actionError("비밀번호 확인란을 입력해주세요."));
    } else if (!state.name || state.name === "") {
      dispatch(actionError("이름을 입력해주세요."));
    } else if (!state.phone || state.phone === "") {
      dispatch(actionError("휴대전화를 입력해주세요."));
    } else if (checkRedundancySwitch === false) {
      dispatch(actionError("계정 ID 중복확인을 해주세요."));
    } else if (pwdCheckSwitch === false) {
      dispatch(actionError("비밀번호가 일치하지 않습니다. 다시 입력해주세요."));
    } else if (phoneCheckSwitch === false) {
      dispatch(
        actionError("전화번호는 숫자만 입력 가능합니다. 다시 입력해주세요.")
      );
    } else {
      dispatch(loadingStart);
      const data = {
        account: account,
        name: state.name,
        password: state.password,
        phone: state.phone,
        groupId: state.groupId,
        shopId: state.shopId,
        managerPassword: adminPassword,
      };
      APIS.postCreateAdmin(data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "관리자계정이 생성되었습니다.",
                () => {
                  history.go(0);
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  useEffect(() => {
    history.replace({ ...location.state, size: 10, page: 0 });
    getShopList();
    authorityGroupList();
  }, []);

  return (
    <div style={{ width: "850px", height: "540px" }}>
      <div className={classes.modal}>관리자 생성</div>
      <div className={classes.content}>
        <header
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "5px",
          }}>
          {isKorda && (
            <AdminConfirmButton
              popupLabel="관리자 생성"
              popupMessage="관리자를 생성하시겠습니까?"
              label="생성"
              callback={handleCreateAdmin}
              style={{
                width: "100px",
                height: "40px",
                color: "white",
                marginRight: "45px",
              }}
            />
          )}
        </header>
        <p style={{ marginLeft: 50 }}>* 는 필수 값 입니다.</p>
        <table cellPadding={0} cellSpacing={0} className={classes.table1}>
          <thead />
          <tbody>
            <tr style={{ alignItems: "center" }}>
              <td style={{ backgroundColor: "#f5f5f5", width: 150 }}>그룹 *</td>
              <td style={{ width: 600 }}>
                <select
                  //   value={state?.groupId}
                  className={classes.modalSelect}
                  onChange={(e) => {
                    handleValueChange("groupId", parseInt(e.target.value));
                  }}>
                  <option value="" key="total">
                    그룹을 선택하세요.
                  </option>
                  {authGroupList?.map((item) => {
                    return <option value={item.id}>{item.groupName}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>매장 *</td>
              <td>
                <select
                  className={classes.modalSelect}
                  onChange={(e) => {
                    handleValueChange("shopId", parseInt(e.target.value));
                  }}>
                  <option value="" key="total">
                    매장을 선택하세요.
                  </option>
                  {shopList?.map((item) => {
                    return <option value={item.shopId}>{item.shopName}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>계정 ID *</td>
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "grid", marginRight: "30px" }}>
                    <input
                      className={
                        checkRedundancySwitch
                          ? classes.modalInput
                          : classes.modalInputWarning
                      }
                      name="account"
                      autoComplete="new-password"
                      onChange={onChangeValue}
                    />
                    {checkRedundancySwitch ? null : (
                      <div className={classes.validationError}>
                        계정 ID 중복확인을 해주세요.
                      </div>
                    )}
                  </div>
                  <Button
                    label="중복확인"
                    className={classes.checkRedundancy}
                    onClick={() => checkRedundancy()}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>비밀번호 *</td>
              <td>
                <input
                  value={password}
                  type="password"
                  className={classes.modalInput}
                  autoComplete="new-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    handleValueChange("password", e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>비밀번호 확인 *</td>
              <td style={{ display: "grid" }}>
                <input
                  type="password"
                  name="password"
                  className={
                    pwdCheckSwitch
                      ? classes.modalInput
                      : classes.modalInputWarning
                  }
                  onChange={onChangeValue}
                />
                {pwdCheckSwitch ? null : (
                  <div className={classes.validationError}>
                    비밀번호가 일치하지 않습니다. 다시 확인해주세요.
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>이름 *</td>
              <td>
                <input
                  value={adminName}
                  className={classes.modalInput}
                  onChange={(e) => {
                    setAdminName(e.target.value);
                    handleValueChange("name", e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>휴대전화 *</td>
              <td style={{ display: "grid" }}>
                <input
                  // value={phone}
                  name="phone"
                  className={
                    phoneCheckSwitch
                      ? classes.modalInput
                      : classes.modalInputWarning
                  }
                  onChange={onChangeValue}
                />
                {phoneCheckSwitch ? null : (
                  <div className={classes.validationError}>
                    전화번호는 숫자만 입력 가능합니다.
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const EditManager = (props) => {
  const { id, fetchAdminList } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [shopList, setShopList] = useState([]);
  const [authGroupList, setAuthGroupList] = useState([]);
  const [manager, setManager] = useState({});
  const history = useHistory();
  const location = useLocation();
  const { state = {} } = location;
  const {} = state;
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const getShopList = () => {
    dispatch(loadingStart);
    APIS.getShopList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };
  const authorityGroupList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityGroupList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setAuthGroupList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };
  const getAdminDetail = () => {
    dispatch(loadingStart);
    APIS.getAdminDetail(id)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setManager({ ...data });
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleModifyPassword = (adminPassword) => {
    dispatch(loadingStart);

    const data = {
      managerId: id,
      password: manager?.password,
      managerPassword: adminPassword,
    };

    APIS.putModifyPassword(data)
      .then(({ data: { message, success } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "비밀번호가 변경되었습니다.",
              () => {
                setManager({ ...manager, password: "" });
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleEditAdmin = (adminPassword) => {
    dispatch(loadingStart);
    const data = {
      id: id,
      // account: manager.account,
      name: manager.name,
      phone: manager.phone,
      groupId: manager.groupId,
      shopId: manager.shopId,
      managerPassword: adminPassword,
      status: manager.status,
    };
    APIS.putEditAdmin(data)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "관리자계정이 수정되었습니다.",
              () => {
                setManager(data);
                history.go(0);
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    handleValueChange("groupId", "");
    handleValueChange("shopId", "");
    getAdminDetail();
    getShopList();
    authorityGroupList();
  }, []);

  const handleClickResetOtp = () => {
    dispatch(loadingStart);
    APIS.deleteManagerOtp(manager.id)
      .then(({ data: { data, success, message } }) => {
        if (success) {
          dispatch(
            actionOpen("OTP가 초기화되었습니다.", getAdminDetail, "", "", true)
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <div style={{ width: "850px", height: "500px" }}>
      <div className={classes.modal}>관리자 상세</div>
      <div className={classes.content}>
        <header
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "5px",
          }}>
          {manager.isOtp && (
            <Button
              style={{ height: 40, marginTop: 20, marginRight: -40 }}
              label="OTP 초기화"
              onClick={() => {
                dispatch(
                  actionOpen(
                    "OTP를 초기화하시겠습니까?",
                    () => {
                      handleClickResetOtp();
                    },
                    "취소",
                    "확인"
                  )
                );
              }}
            />
          )}

          {isKorda && (
            <AdminConfirmButton
              popupLabel="관리자 상세 변경"
              popupMessage="변경하시겠습니까?"
              label="수정"
              callback={handleEditAdmin}
              style={{
                width: "100px",
                height: "40px",
                color: "white",
                marginRight: "45px",
              }}
            />
          )}
        </header>
        <p style={{ marginLeft: 50 }}>* 는 필수 값 입니다.</p>
        <table cellPadding={0} cellSpacing={0} className={classes.table1}>
          <thead />
          <tbody>
            <tr style={{ alignItems: "center" }}>
              <td style={{ backgroundColor: "#f5f5f5", width: 150 }}>그룹 *</td>
              <td style={{ width: 600 }}>
                <select
                  className={classes.modalSelect}
                  value={manager.groupId}
                  onChange={(e) => {
                    setManager({ ...manager, groupId: +e.target.value });
                  }}>
                  <option value="" key="total">
                    그룹을 선택하세요.
                  </option>
                  {authGroupList?.map((item) => {
                    return <option value={+item.id}>{item.groupName}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>매장 *</td>
              <td>
                <select
                  className={classes.modalSelect}
                  value={manager.shopId}
                  onChange={(e) => {
                    setManager({ ...manager, shopId: +e.target.value });
                  }}>
                  <option value="" key="total">
                    매장을 선택하세요.
                  </option>
                  {shopList?.map((item) => {
                    return <option value={item.shopId}>{item.shopName}</option>;
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>계정 ID *</td>
              <td>
                <input
                  className={classes.modalInput}
                  value={manager?.account}
                  disabled={true}
                />
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>이름 *</td>
              <td>
                <input
                  value={manager?.name}
                  className={classes.modalInput}
                  disabled={true}
                />
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>휴대전화 *</td>
              <td>
                <input
                  value={
                    manager?.phone === ""
                      ? manager?.phone
                      : formatPhone(manager?.phone)
                  }
                  className={classes.modalInput}
                  onChange={(e) => {
                    setManager({ ...manager, phone: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>비밀번호 *</td>
              <td style={{ display: "flex" }}>
                <input
                  value={manager?.password}
                  className={classes.modalInput}
                  type="password"
                  onChange={(e) => {
                    setManager({ ...manager, password: e.target.value });
                  }}
                />
                <div style={{ width: 220, height: 34 }}>
                  <AdminConfirmButton
                    popupLabel="관리자 비밀번호 변경"
                    popupMessage="변경하시겠습니까?"
                    className={classes.adminBtn}
                    label="비밀번호 변경"
                    callback={handleModifyPassword}
                    rootClassName={classes.confirmRoot}
                    btnClassName={classes.confirmButton}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f5f5f5" }}>
                계정상태 활성여부 *
              </td>
              <td style={{ display: "flex" }}>
                {/* <Typography style={{ alignSelf: "center" }}>Off</Typography> */}
                <Switch
                  checked={manager.status === "ACTIVE" ? true : false}
                  color="primary"
                  onChange={(e) => {
                    setManager({
                      ...manager,
                      status: e.target.checked === true ? "ACTIVE" : "DISABLED",
                    });
                  }}
                />
                {/* <Typography style={{ alignSelf: "center" }}>On</Typography> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    margin: "10px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  button: {
    border: "5px solid white",
    borderRadius: "7px",
    width: "150px",
  },
  btnDetail: {
    border: "1px solid rgba(0,0,0,0.23)",
    width: "100%",
    height: "30px",
  },
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  modal: {
    textAlign: "center",
    fontWeight: "bold",
    width: "100%",
    height: "50px",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
  },
  content: {
    marginTop: "30px",
    height: "150px",
  },
  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  modalInput: {
    width: 400,
    height: 35,
    float: "left",
    border: "1px solid #dddddd",
  },
  modalInputWarning: {
    width: 400,
    height: 35,
    display: "inline-block",
    color: "#e52528",
    border: "2px solid #e52528",
    transition: "all 0.5s",
  },

  modalSelect: {
    width: 400,
    float: "left",
  },
  checkRedundancy: {
    border: "1px solid white",
    borderRadius: "7px",
    width: "100px",
    height: "36px",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  validationError: {
    color: "#e52528",
    justifySelf: "left",
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    justifyContent: "flex-start",
  },
  confirmButton: {
    minWidth: 70,
    height: 36,
    width: 140,
    marginLeft: 20,
  },
});
