import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import consts, {
  GOODS_DISPLAY_CODE,
  SALE_DISPLAY_CODE,
} from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Radio,
  CardMedia,
  InputBase,
  ButtonBase,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Flex from "components/flex/Flex";
import MakerRows from "./MakerRows";
import { formatTime } from "libs/utils";
import { Switch, Checkbox, ColorPicker } from "@progress/kendo-react-inputs";
// import {
//   GridContextProvider,
//   GridDropZone,
//   GridItem,
//   swap,
// } from "react-grid-dnd";
import { openImageView } from "redux/image-viewer/ImageViewerReducer";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Hint } from "@progress/kendo-react-labels";

import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  Button as KendoButton,
  ButtonGroup,
} from "@progress/kendo-react-buttons"; // MUI랑 중복이라서
import styled from "styled-components";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { getAssociateShops } from "redux/shop/AssociateShopReducer";
import ImageAdd from "./ImageAdd";

export default function PhysicalProductAdd() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  const [distributor, setDistributor] = useState("선택하기");
  const [productName, setProductName] = useState("");
  const [productDescrition, setProductDescrition] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("선택하기");
  const [supplyPrice, setSupplyPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState(""); // 인출수수료
  const [mallFee, setMallFee] = useState(""); // 몰 전용 인출 수수료
  const [salesStatus, setSalesStatus] = useState("on");
  const dispatch = useDispatch();
  const [orderCount, setOrderCount] = useState("");
  const [detail, setDetail] = useState("");
  const [goodsImage, setGoodsImage] = useState([]); // 상품 이미지
  const [categories, setCategories] = useState([]);
  const [ds, setDs] = useState([]);
  const [htmlPath, setHtmlPath] = useState("");
  const [productId, setProductId] = useState();
  const [hotRank, setHotRank] = useState();
  const [makerdata, setmakerdatadata] = useState([]);
  const [goldOrSilver, setgoldOrSilver] = useState("GOLD");
  const [gram, setgram] = useState(0);
  const [lossWeightGram, setLossWeightGram] = useState(0);
  const [options, setoptions] = useState(true);
  const [optionsValue, setoptionsValue] = useState("");
  const [optionsInfos, setOptionsInfos] = useState([]); // 옵션 정보
  const [outsourcing, setoutsourcing] = useState("");
  const [pay, setpay] = useState(0);
  const [laborCost, setLaborCost] = useState(0);
  const [days, setdays] = useState(0);
  const [stock, setstock] = useState(0);
  const [packingOutsourcing, setPackingOutsourcing] = useState(""); //포장외주사
  const [brand, setBrand] = useState(""); // 브랜드명
  const [packingUnitPrice, setPackingUnitPrice] = useState(0); //포장공임
  const [visible, setvisible] = useState("VISIBLE");
  const [memocomment, setmemocomment] = useState("");
  const [productcomment, setproductcomment] = useState("");
  const [imagedel, setimagedel] = useState([]);
  const [recommendShopFee, setRecommendShopFee] = useState(0);
  const [outShopFee, setOutShopFee] = useState(0);
  const [detailImages, setDetailImages] = useState([]); //상품 설명 이미지
  const [deleteDetails, setDeleteDetails] = useState([]);
  const [viewerVisible, setViewerVisible] = useState(false);
  const [isPost, setIsPost] = useState(false); //택배 수령 가능
  const [isShopTaken, setIsShopTaken] = useState(false); // 매장 방문 수령 가능
  const [labelName, setLabelName] = useState("");
  const [labelColor, setLabelColor] = useState("rgba(255, 255, 255, 1)");
  const er = useRef();
  const moveRef = useRef(true);
  const [refineList, setRefineList] = useState([]);
  const [refiningWaste, setRefiningWaste] = useState("");
  const [activeItem, setActiveItem] = useState(null); // 순서 변경 관련 상태
  /**
   * 전용 상품 체크여부
    ALL("전체"),
    APP("금방금방 앱"),
    MALL("금방금방 몰"),
    NOT_SALE("판매 중지. 재고는 관리"),
    DISABLE("비활성. 재고, 판매 모두 안함"))
   */
  const [visibleChecked, setVisibleChecked] = useState([]);
  const [saleVisible, setSaleVisible] = useState([]);
  const [isAppChecked, setIsAppChecked] = useState(true); // 판매처: 앱 체크 여부
  const [isMallChecked, setIsMallChecked] = useState(true); // 판매처: 몰 체크 여부
  // 제휴사 선택 (전체 or associateShopIds)
  const [isAssociateTotal, setIsAssociateTotal] = useState(null);
  const [associateShopIds, setAssociateShopIds] = useState([]);
  // 재고관리 여부
  const [inventoryPlace, setInventoryPlace] = useState("");

  const { associateShops, isLoading } = useSelector((state) => state.shop);

  const editField = "inEdit";
  let now = new Date();
  var today = new Date().toISOString().substring(0, 19).replace(/-/g, "");
  today = today.replace(/:/g, "");

  today = formatTime(new Date(), "YYYYMMDDTHHmmss");

  var productcode = "";
  if (goldOrSilver === "GOLD") {
    productcode = "GP" + today;
  }

  const [productBarcode, setProductBarCode] = useState(productcode);

  useEffect(() => {
    if (goldOrSilver === "GOLD") {
      productcode = "GP" + today;
    } else {
      productcode = "SP" + today;
    }
    setProductBarCode(productcode);
  }, [goldOrSilver]);

  useEffect(() => {
    if (state.action === "detail") {
      if (isLoading) {
        dispatch(loadingStart);
        APIS.getProductDetail(state.id)
          .then((res) => {
            var rows = res.data.data;

            setProductId(rows.id);
            setProductBarCode(rows.code);
            setProductName(rows.name);
            setCategory1(rows.category);
            setgoldOrSilver(rows.goldOrSilver);
            setgram(rows.gram);
            setLossWeightGram(rows.lossWeightGram);
            setSellingPrice(rows.fees);
            setMallFee(rows.mallFee);
            setoptions(rows.options);
            setOptionsInfos(rows.goodsOptionsInfos);
            setoutsourcing(rows.outsourcing);
            setBrand(rows.brand);
            setpay(rows.pay);
            setLaborCost(rows.laborCost);
            setRefiningWaste(rows.putiryType);
            setdays(rows.days);
            setstock(rows.stock);
            setPackingOutsourcing(rows.packingOutsourcing);
            setPackingUnitPrice(rows.packingUnitPrice);
            setIsPost(rows.isPost);
            setIsShopTaken(rows.isShopTaken);
            // goodsDisplayModeList 값에 따라 체크박스 상태 설정
            const initialGoodsDisplayType = rows.goodsDisplayModeList;
            if (
              initialGoodsDisplayType.includes("APP") ||
              initialGoodsDisplayType.includes("MALL") ||
              initialGoodsDisplayType.includes("K_BANK_MALL")
            ) {
              setVisibleChecked(["SALE"]);
              setSaleVisible(initialGoodsDisplayType);
            } else {
              setVisibleChecked(initialGoodsDisplayType);
            }
            setHotRank(rows.hotRank);
            setLabelName(rows.labelName);
            setLabelColor(rows.labelColor);
            for (let i in rows.goodsInfoDtoList) {
              setmakerdatadata((makerdata) => [
                ...makerdata,
                {
                  kind: rows.goodsInfoDtoList[i].text,
                  id: rows.goodsInfoDtoList[i].id,
                  label: rows.goodsInfoDtoList[i].label,
                },
              ]);
              //setCategories({...categories, );
            }

            setmemocomment(rows.memo);
            setGoodsImage(rows.imageIds);
            setoptionsValue(rows.optionValue);

            setproductcomment(rows.comment);

            // 매장수수료 추천,출고
            setRecommendShopFee(rows.recommendShopFee);
            setOutShopFee(rows.outShopFee);

            setDetailImages(rows.detailImages || []);
            // 제휴사 선택
            convertIdsToString(rows.associateShopIds);
          })
          .catch((err) => {
            dispatch(actionError(err));
          })
          .finally(() => {
            dispatch(loadingEnd);
          });
      }
    }
  }, [associateShops]);

  useEffect(() => {
    APIS.getGoodsCategoryList().then((res) => {
      if (res.data.success) {
        setCategories(res.data.data);
      } else {
        dispatch(actionError(res.data.message));
      }
    });

    // 정련 품목 리스트 조회
    APIS.getInventoryItemCategory()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setRefineList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  }, []);

  const handleSetVisibleChecked = (value) => {
    setVisibleChecked([value]);
    if (value !== "SALE") setSaleVisible([]);
  };

  const handleSetSaleVisibleChecked = (value) => {
    if (saleVisible.includes(value)) {
      setSaleVisible(saleVisible.filter((item) => item !== value));
    } else {
      setSaleVisible(saleVisible.concat(value));
    }
  };

  const handleProductBarcode = (e) => {
    if (e.target.value.length > 100) {
      return;
    }
    setProductBarCode(e.target.value);
  };

  const handleDistributor = (e) => {
    setDistributor(e.target.value);
  };

  const handleProductName = (e) => {
    setProductName(e.target.value);
  };

  const handleProductDescription = (e) => {
    setProductDescrition(e.target.value);
  };

  const handleCategory1 = (e) => {
    setCategory1(e.target.value);
  };

  const handleCategory2 = (e) => {
    setCategory2(e.target.value);
  };

  const handleSupplyPrice = (e) => {
    setSupplyPrice(e.target.value);
  };

  const handleSellingPrice = (e) => {
    setSellingPrice(e.target.value);
  };

  // 몰 인출 수수료
  const handleMallFee = (e) => {
    setMallFee(e.target.value);
  };

  const handleAssociateRadioBtn = (value) => {
    setIsAssociateTotal(value);
    if (value === null) {
      setAssociateShopIds(null);
    } else {
      setAssociateShopIds([]);
    }
  };

  const onChangeAssociateShops = (event) => {
    setAssociateShopIds([...event.value]);
  };

  const handleSalesStatus = (e) => {
    setSalesStatus(e.target.value);
  };

  const onClickCancelBtn = () => {
    history.goBack();
  };

  const convertIdsToString = useCallback(
    (ids) => {
      if (ids === null) setAssociateShopIds(null);
      else if (
        typeof ids === "string" &&
        ids !== "" &&
        associateShops.length !== 0
      ) {
        setIsAssociateTotal(true);
        ids = ids.split(",").map((id) => {
          if (
            associateShops.find((item) => item.value === parseInt(id)) !==
            undefined
          ) {
            return associateShops.find((item) => item.value === parseInt(id))
              ?.label;
          }
        });
        setAssociateShopIds(ids);
      }
    },
    [associateShops]
  );

  const convertLabelToNumber = () => {
    // associateShopIds === null -> 제휴사 전체
    if (associateShopIds === null) return null;
    if (
      associateShopIds
        .map(
          (label) => associateShops.find((item) => item.label === label)?.value
        )
        .join(",") !== undefined
    ) {
      return associateShopIds
        .map(
          (label) => associateShops.find((item) => item.label === label)?.value
        )
        .join(",");
    }
  };

  const onClickSaveBtn = async () => {
    var goodsInfoDtoList = [];
    for (var key in makerdata) {
      if (productId > 0) {
        if (
          Object.keys(makerdata[key]).length !== 0 &&
          makerdata[key].label &&
          makerdata[key].kind
        ) {
          goodsInfoDtoList.push({
            label: makerdata[key].label,
            text: makerdata[key].kind,
            id: makerdata[key].id,
          });
        }
      } else {
        if (
          Object.keys(makerdata[key]).length !== 0 &&
          makerdata[key].label &&
          makerdata[key].kind
        ) {
          goodsInfoDtoList.push({
            label: makerdata[key].label,
            text: makerdata[key].kind,
          });
        }
      }
    }

    // 판매처 변환
    let goodsDisplayModeList = visibleChecked.includes("SALE")
      ? saleVisible
      : visibleChecked;

    if (goodsDisplayModeList.length === 0) {
      dispatch(actionError("판매처를 선택해주세요."));
      return;
    }

    if (productName === "") {
      dispatch(actionError("상품명을 입력해주세요."));
      return;
    }

    if (category1 === "") {
      dispatch(actionError("카테고리를 선택해주세요."));
      return;
    }

    if (!isPost && !isShopTaken) {
      dispatch(actionError("수령 가능 여부를 선택해주세요."));
      return;
    }

    if (days === 0) {
      dispatch(actionError("제작기간을 입력해주세요."));
      return;
    }
    if (sellingPrice === "") {
      dispatch(actionError("인출수수료를 입력해주세요."));
      return;
    }

    if (isAssociateTotal !== null && associateShopIds.length === 0) {
      dispatch(actionError("제휴사를 선택해주세요."));
      return;
    }

    const RegisterOrModify = () => {
      const goods = {
        id: productId,
        category: category1,
        comment: productcomment,
        days,
        fees: sellingPrice,
        mallFee,
        goldOrSilver,
        gram,
        lossWeightGram,
        goodsInfoDtoList,
        isPost: isPost,
        isShopTaken,
        goodsDisplayModeList,
        memo: memocomment,
        name: productName,
        options,
        optionValue: optionsValue,
        outsourcing,
        pay,
        laborCost,
        stock,
        recommendShopFee,
        packingOutsourcing,
        packingUnitPrice,
        // type: 1,
        brand,
        deleteInfoIds: [],
        deleteFileIds: imagedel,
        deleteDetails,
        outShopFee,
        hotRank: hotRank,
        labelName,
        labelColor,
        putiryType: refiningWaste,
        associateShopIds: convertLabelToNumber(),
      };

      const jsonString = JSON.stringify(goods);
      const blob = new Blob([jsonString], {
        type: "application/json",
      });

      const formData2 = new FormData();

      // dto data 추가
      formData2.append("goods", blob);

      // 파일은 이런식으로 추가하시면 됩니다.
      for (let i in goodsImage) {
        formData2.append("files", goodsImage[i]);
      }

      for (let i in detailImages) {
        formData2.append("details", detailImages[i]);
      }

      if (productId > 0) {
        dispatch(loadingStart);
        APIS.putModifyProduct(formData2, productId)
          .then(({ data: { data, success, message } }) => {
            if (!success) {
              dispatch(actionOpen(message, null, null, null, true));
            } else {
              dispatch(
                actionOpen(
                  "상품 수정이 완료되었습니다.",
                  () => {
                    history.go(0);
                  },
                  null,
                  null,
                  true
                )
              );
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
          });
      } else {
        dispatch(loadingStart);
        APIS.postCreateProduct(formData2)
          .then(({ data: { data, success, message } }) => {
            if (!success) {
              dispatch(actionOpen(message, null, null, null, true));
            } else {
              dispatch(
                actionOpen(
                  "상품 추가가 완료되었습니다.",
                  () => {
                    history.goBack();
                  },
                  null,
                  null,
                  true
                )
              );
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
          });
      }
    };

    if (mallFee !== null && mallFee !== "" && Number(mallFee) <= 0) {
      dispatch(
        actionOpen(
          "몰 인출수수료가 0보다 작거나 같습니다. 진행하시겠습니까?",
          () => {
            // 진행을 원할 경우의 코드
            RegisterOrModify();
          },
          "취소",
          "진행",
          false
        )
      );
      // 끝나면 무조건 return함. API 2번 실행 방지
      return;
    }

    RegisterOrModify();
  };

  //dnd onChange
  // const onChangeDnd = (sourceId, sourceIndex, targetIndex, targetId) => {
  //   moveRef.current = false;
  //   if (!targetId && sourceId) {
  //     const result = swap(goodsImage, sourceIndex, targetIndex);
  //     const data = result?.map((item, i) => {
  //       return {
  //         id: item.id,
  //         sort: i + 1,
  //       };
  //     });
  //     setGoodsImage(result);
  //     APIS.patchSortProductImage(productId, data)
  //       .then(({ data: { data, message, success } }) => {
  //         if (!success) {
  //           dispatch(actionError(message));
  //         }
  //       })
  //       .catch((error) => dispatch(actionError(error)));
  //   }
  // };

  //이미지 추가
  const handleClickImageAdd = (e) => {
    if (typeof productId === "undefined") {
      e.preventDefault();
      dispatch(
        actionOpen(
          "상품 ID가 없습니다.\n상품을 먼저 등록해주세요.",
          () => {
            history.go(0);
          },
          null,
          null,
          true
        )
      );
      return;
    }
    const { name, files } = e.target;
    const file = files[0];
    const formData = new FormData();
    formData.append("files", file);
    if (!file.type.match(/image-*/)) {
      dispatch(actionError("등록할 수 없는 파일입니다."));
      return;
    } else {
      if (name === "image") {
        APIS.postRegisterProductImage(productId, formData)
          .then(({ data: { data, success, message } }) => {
            if (!success) {
              dispatch(actionOpen(message, null, null, null, true));
            } else {
              dispatch(
                actionOpen(
                  "이미지 추가가 완료되었습니다.",
                  () => {
                    history.go(0);
                  },
                  null,
                  null,
                  true
                )
              );
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
          });
      }
    }
  };

  //이미지 삭제
  const deleteProductImage = (imageId) => {
    dispatch(loadingStart);
    APIS.deleteProductImage(productId, imageId)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "이미지가 삭제되었습니다.",
              () => {
                history.go(0);
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleChangeDetailImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(/image-*/)) {
        dispatch(actionError("등록할 수 없는 파일입니다."));
        return;
      }
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setDetailImages([...detailImages, file]);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const handleOrderCount = (e) => {
    setOrderCount(e.target.value);
  };

  const makerAdd = () => {
    setmakerdatadata((makerdata) => [...makerdata, {}]);
  };
  const makerModify = (v, i) => {
    makerdata[i].kind = v;
  };
  const makerModifylabel = (v, i) => {
    makerdata[i].label = v;
  };

  // 옵션 정보
  const reorder = (dataItem) => {
    if (activeItem === dataItem) {
      return;
    }
    let reorderedData = optionsInfos.slice();
    let prevIndex = reorderedData.findIndex((p) => p === activeItem);
    let nextIndex = reorderedData.findIndex((p) => p === dataItem);
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItem || reorderedData);
    setOptionsInfos(reorderedData);
  };

  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  // 임의의 id 부여
  const generateId = () =>
    optionsInfos.reduce((acc, current) => Math.max(acc, current.id), 0) + 1;

  // 새로운 옵션 정보 추가
  const addNew = () => {
    const newId = generateId();
    const newDataItem = {
      id: newId,
      isNew: true,
      inEdit: true,
      isInventory: false, // 재고여부 default 비활성
      visible: "HIDDEN", // 노출상태 default 비노출
      optionValue: "",
    };
    setOptionsInfos([newDataItem, ...optionsInfos]);
  };

  // 새로운 옵션 삭제
  const remove = (dataItem) => {
    const newData = optionsInfos.slice();
    let index = optionsInfos.findIndex((item) => item.id === dataItem.id);
    newData.splice(index, 1);
    setOptionsInfos(newData);
  };

  // 옵션 정보 Change
  const itemChange = (event) => {
    const newData = optionsInfos.map((item) =>
      item.id === event.dataItem.id
        ? {
            ...item,
            [event.field]: event.value,
          }
        : item
    );
    setOptionsInfos(newData);
  };

  const handleOptionSave = () => {
    // 옵션 순서 부여 및 optionValue, 노출상태, 재고여부만 보냄
    const orderedOptionsInfos = optionsInfos.reduce((acc, option, index) => {
      // 새로 옵션 추가한 정보 있으면 id와 isNew를 제거
      if (option.isNew) {
        const { id, isNew, ...newOption } = option;
        option = newOption;
      }

      // 옵션 이름이 없는 경우 처리
      if (!option.optionValue.trim()) {
        dispatch(actionError("빈 옵션 이름이 있습니다."));
        return acc;
      }

      // 불필요한 속성 제거 후 저장
      const { inEdit, ...filteredOption } = option;

      return [
        ...acc,
        {
          ...filteredOption,
          sortOrder: index + 1,
        },
      ];
    }, []);

    // 빈 옵션이 있으면 저장이 안되도록 하기
    if (orderedOptionsInfos.length < optionsInfos.length) {
      return;
    }

    const data = {
      goodsId: productId,
      goodsOptionsList: orderedOptionsInfos,
    };

    dispatch(loadingStart);
    APIS.postGoodsOption(data)
      .then((res) => {
        if (res.data.success) {
          APIS.getProductDetail(state.id).then((res) => {
            setOptionsInfos(res.data.data.goodsOptionsInfos);
          });
        } else {
          dispatch(actionError(res.data.message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const EditOption = (dataItem, checked, key) => {
    const newData = optionsInfos.map((item) =>
      item.id === dataItem.id
        ? {
            ...item,
            [key]: checked,
          }
        : item
    );
    setOptionsInfos(newData);
  };

  // 노출 여부
  const RadioCell = (props) => (
    <MyRadioCell {...props} EditOption={EditOption} keyName="visible" />
  );

  // 재고여부
  const InventoryCell = (props) => (
    <MyRadioCell {...props} EditOption={EditOption} keyName="isInventory" />
  );

  const CommandCell = (props) => <MyCommandCell {...props} remove={remove} />;

  const GoodsDisplayRadio = useCallback(() => {
    return Object.entries(GOODS_DISPLAY_CODE).map(([key, value], index) => {
      return (
        <>
          <FormControlLabel
            key={`${key}_${index}`}
            control={
              <Radio
                color="default"
                className={classes.radioBtn}
                checked={visibleChecked[0] === key}
                onChange={(e) => handleSetVisibleChecked(e.target.value)}
                value={key || ""}
              />
            }
            label={value}
            labelPlacement="end"
          />
        </>
      );
    });
  }, [visibleChecked]);

  const SaleDisplayBtns = useCallback(() => {
    return Object.entries(SALE_DISPLAY_CODE).map(([key, value], index) => {
      return (
        <>
          <KendoButton
            className={saleVisible.includes(key) ? "k-selected" : ""}
            onClick={(e) => {
              handleSetSaleVisibleChecked(key);
            }}>
            {value}
          </KendoButton>
        </>
      );
    });
  }, [saleVisible]);

  return (
    <>
      <Grid container className={classes.container}>
        <Typography variant="h6" className={classes.bold}>
          {productId ? "상품수정" : "상품등록"}
        </Typography>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={5}
              className={[classes.dfsc, classes.subtitle].join(" ")}>
              <Typography
                style={{ marginBottom: "10px" }}
                variant="subtitle1"
                className={classes.bold}>
                기본정보
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  상품코드
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={productBarcode}
                  style={{ marginLeft: "20px" }}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  상품명
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={productName}
                  onChange={handleProductName}
                  style={{ marginLeft: "20px" }}
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  카테고리
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={category1}
                  onChange={handleCategory1}
                  disabled={productId ? true : false}
                  style={{ marginLeft: "20px" }}>
                  <option selected disabled className={classes.selectForm}>
                    선택하기
                  </option>
                  {categories.map((item, idx) => {
                    return (
                      <option
                        key={idx}
                        value={item.id}
                        className={classes.selectForm}>
                        {item.kind}
                      </option>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  중량
                </Typography>
              </Grid>
              <Grid
                item
                // xs={0}
                className={[classes.dfsc, classes.infoCell].join(" ")}>
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={goldOrSilver === "GOLD"}
                      disabled={productId ? true : false}
                      onChange={(e) => setgoldOrSilver(e.target.value)}
                      value="GOLD"
                    />
                  }
                  label="금"
                  // labelPlacement="gold"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={goldOrSilver === "SILVER"}
                      disabled={productId ? true : false}
                      onChange={(e) => setgoldOrSilver(e.target.value)}
                      value="SILVER"
                    />
                  }
                  label="은"
                  // labelPlacement="silver"
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={gram}
                  disabled={productId ? true : false}
                  onChange={(e) => setgram(e.target.value)}
                  style={{ marginLeft: "20px", width: "120px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  g
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  생산로스
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={lossWeightGram}
                  onChange={(e) => setLossWeightGram(e.target.value)}
                  style={{ marginLeft: "20px", width: "120px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  g
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  선택옵션
                </Typography>
              </Grid>
              <Grid
                item
                // xs={0}
                className={[classes.dfsc, classes.infoCell].join(" ")}>
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={options === true}
                      onChange={(e) => setoptions(true)}
                      value={true}
                    />
                  }
                  label="있음"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={options === false}
                      onChange={(e) => setoptions(false)}
                      value={false}
                    />
                  }
                  label="없음"
                  labelPlacement="end"
                />
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={optionsValue}
                  onChange={(e) => setoptionsValue(e.target.value)}
                  style={{ marginLeft: "20px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  수령 가능 여부
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <ButtonGroup>
                  <KendoButton
                    className={isPost ? "k-selected" : ""}
                    onClick={() => setIsPost(!isPost)}>
                    택배 수령
                  </KendoButton>
                  <KendoButton
                    className={isShopTaken ? "k-selected" : ""}
                    onClick={() => setIsShopTaken(!isShopTaken)}>
                    매장 방문
                  </KendoButton>
                </ButtonGroup>
              </Grid>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  제휴사
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infoCell].join(" ")}>
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={isAssociateTotal === null}
                      onChange={(e) => handleAssociateRadioBtn(null)}
                      value={null}
                    />
                  }
                  label="전체"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={isAssociateTotal !== null}
                      onChange={(e) => handleAssociateRadioBtn(true)}
                      value={true}
                    />
                  }
                  label="제휴사 선택"
                  labelPlacement="end"
                />
                {isAssociateTotal !== null &&
                  (Array.isArray(associateShops) &&
                  associateShops.length !== 0 ? (
                    <form
                      className="k-form"
                      onSubmit={(e) => e.preventDefault()}>
                      <fieldset>
                        <MultiSelect
                          data={associateShops.map((item) => `${item.label}`)}
                          onChange={onChangeAssociateShops}
                          value={associateShopIds}
                          style={{ minWidth: "150px" }}
                        />
                        <Hint>*여러 제휴사 선택 가능합니다.</Hint>
                      </fieldset>
                    </form>
                  ) : (
                    <span>등록된 제휴사 없음</span>
                  ))}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  판매처
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <GoodsDisplayRadio />
                {visibleChecked.includes("SALE") && (
                  <ButtonGroup>
                    <SaleDisplayBtns />
                  </ButtonGroup>
                )}
                {/* <ButtonGroup>
                  <KendoButton
                    className={isAppChecked ? "k-selected" : ""}
                    onClick={() => setIsAppChecked(!isAppChecked)}>
                    앱
                  </KendoButton>
                  <KendoButton
                    className={isMallChecked ? "k-selected" : ""}
                    onClick={() => setIsMallChecked(!isMallChecked)}>
                    몰
                  </KendoButton>
                </ButtonGroup> */}
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={2}
                className={[classes.dfcc, classes.infocell].join(" ")}>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#B7ACAC",
                  }}>
                  인출수수료
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  type="number"
                  size="small"
                  variant="outlined"
                  value={sellingPrice}
                  onChange={handleSellingPrice}
                  style={{ marginLeft: "20px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  원
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                className={[classes.dfcc, classes.infocell].join(" ")}>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#B7ACAC",
                  }}>
                  몰 인출수수료
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  type="number"
                  size="small"
                  variant="outlined"
                  value={mallFee}
                  onChange={handleMallFee}
                  style={{ marginLeft: "20px" }}
                  placeholder="0원이면 0 입력"
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <OptionWrap>
          <OptionGridWrap>
            <OptionHeader>
              <div>
                <Typography variant="subtitle1" className={classes.bold}>
                  옵션 정보
                </Typography>
                <Notice>*상품 등록 후 저장 가능합니다.</Notice>
              </div>
              <KendoButton
                title="옵션 저장"
                size="large"
                disabled={!productId && true}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={handleOptionSave}>
                옵션 저장
              </KendoButton>
            </OptionHeader>
            <MyContext.Provider
              value={{
                reorder: reorder,
                dragStart: dragStart,
              }}>
              <KendoGrid
                data={optionsInfos.map((el, idx) => {
                  el.index = idx + 1;
                  el.inEdit = true;
                  return el;
                })}
                dataItemKey={"id"}
                onItemChange={itemChange}
                editField={editField}
                resizable={true}
                style={{ height: "263px" }}>
                <GridToolbar>
                  <button
                    title="옵션 추가"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    disabled={!productId && true}
                    onClick={addNew}>
                    옵션 추가
                  </button>
                </GridToolbar>
                <Column width="80px" cell={DragCell} />
                <Column
                  width="50px"
                  field="index"
                  title="No."
                  editable={false}
                />
                <Column field="optionValue" title="옵션 이름" />
                <Column field="visible" title="노출상태" cell={RadioCell} />
                {/* <Column
                  field="isInventory"
                  title="재고여부"
                  cell={InventoryCell}
                /> */}
                <Column title="버튼" cell={CommandCell} width="150px" />
              </KendoGrid>
            </MyContext.Provider>
          </OptionGridWrap>
        </OptionWrap>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={5}
              className={[classes.dfsc, classes.subtitle].join(" ")}>
              <Typography
                style={{ marginBottom: "10px" }}
                variant="subtitle1"
                className={classes.bold}>
                관리자 등록정보
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  외주제조사
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  variant="outlined"
                  value={outsourcing}
                  onChange={(e) => setoutsourcing(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  기본공임
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  variant="outlined"
                  value={pay}
                  onChange={(e) => setpay(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  원
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  제작기간
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={days}
                  onChange={(e) => setdays(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  일
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  재고수량
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={stock}
                  onChange={(e) => setstock(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  매장수수료(추천)
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={recommendShopFee}
                  onChange={(e) => setRecommendShopFee(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  매장수수료(출고)
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={outShopFee}
                  onChange={(e) => setOutShopFee(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  포장외주사
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  variant="outlined"
                  value={packingOutsourcing}
                  onChange={(e) => setPackingOutsourcing(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  포장공임
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={packingUnitPrice}
                  onChange={(e) => setPackingUnitPrice(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  판매공임
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  variant="outlined"
                  value={laborCost}
                  onChange={(e) => setLaborCost(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}>
                  원
                </Typography>
              </Grid>
            </Grid>
            {/* 브랜드 추가 */}
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  브랜드명
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  variant="outlined"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>
              {/* <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  폐기정련구분
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ marginLeft: 20 }}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <DropDownList
                  style={{ width: 270 }}
                  options={refineList
                    .filter(
                      (item) =>
                        item.assetType === goldOrSilver &&
                        item.itemType === "REFINE"
                    )
                    .map(({ purityTypeEnum, name }) => ({
                      label: name,
                      value: purityTypeEnum,
                    }))}
                  selected={refiningWaste ? refiningWaste : ""}
                  onChange={(value) => setRefiningWaste(value)}
                  useAll
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid
            item
            xs={12}
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className={[classes.dfsc, classes.subtitle].join(" ")}>
            <Typography variant="subtitle1" className={classes.bold}>
              추가정보
            </Typography>
            <ButtonBase style={{ marginRight: 20 }} onClick={() => makerAdd()}>
              <Add />
              필드 추가하기
            </ButtonBase>
          </Grid>

          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent, classes.ckeditor].join(
              " "
            )}
            style={{ flexDirection: "column" }}>
            {makerdata.map((x, i) => {
              return (
                // <>
                <MakerRows
                  key={Math.random()}
                  rows={x}
                  i={i}
                  makerModify={makerModify}
                  makerModifylabel={makerModifylabel}
                />
                // </>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid item className={[classes.dfsc, classes.subtitle].join(" ")}>
            <Typography variant="subtitle1" className={classes.bold}>
              메모
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent, classes.ckeditor].join(
              " "
            )}
            style={{ margin: 0 }}>
            <InputBase
              multiline
              style={{
                flex: 1,
                border: "1px solid #f5f5f5",
              }}
              inputProps={{
                style: {
                  minHeight: 100,
                  maxHeight: 300,
                  overflow: "auto",
                },
              }}
              value={memocomment}
              onChange={(e) => {
                setmemocomment(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid
            item
            // xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <div>
              <Typography variant="subtitle1" className={classes.bold}>
                상품 이미지
              </Typography>
              <Notice className="mb-5">*상품 등록 후 추가 가능합니다.</Notice>
            </div>
            <div style={{ alignItems: "center" }}>
              <input
                accept="image/*"
                type="file"
                id="add-image-file"
                name="image"
                multiple={false}
                style={{ display: "none" }}
                onChange={handleClickImageAdd}
              />
              <label
                htmlFor="add-image-file"
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginRight: 20,
                  color: "blue",
                }}>
                이미지 추가하기
              </label>
            </div>
          </Grid>
          <Divider className={classes.divider} />
          {/* <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}>
            <GridContextProvider onChange={onChangeDnd}>
              <div className={classes.dropContainer}>
                <Flex row style={{ width: "100%" }}>
                  <GridDropZone
                    className={classes.dropZone}
                    id="imageList"
                    boxesPerRow={8}
                    rowHeight={150}>
                    {goodsImage?.map((item, index) => (
                      <GridItem key={item.id}>
                        <Flex className={classes.imgWrap}>
                          <CardMedia
                            component="img"
                            image={
                              item?.path
                                ? consts.s3BaseUrl + "/" + item?.path
                                : ""
                            }
                            onClick={(e) => {
                              console.log(goodsImage, "goodsImage");
                              if (moveRef.current) {
                                dispatch(
                                  openImageView({
                                    images: goodsImage
                                      ? goodsImage?.map((item) => {
                                          return {
                                            src: item?.path,
                                          };
                                        })
                                      : "",
                                    activeIndex: index,
                                  })
                                );
                              }
                            }}
                            style={{
                              borderRadius: 10,
                              width: 100,
                              height: 150,
                              maxHeight: 100,
                              maxWidth: 100,
                              webkitUserDrag: "none",
                            }}
                          />
                          <Grid>
                            <Button
                              onClick={(e) => {
                                deleteProductImage(item.id);
                              }}
                              size="small">
                              <Typography variant="subtitle2">삭제</Typography>
                            </Button>
                          </Grid>
                        </Flex>
                      </GridItem>
                    ))}
                  </GridDropZone>
                </Flex>
              </div>
            </GridContextProvider>
          </Grid> */}
          <ImageAdd goodsImage={goodsImage} productId={productId} />
        </Grid>
        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid item className={[classes.dfsc, classes.subtitle].join(" ")}>
            <Typography variant="subtitle1" className={classes.bold}>
              상품 설명
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent, classes.ckeditor].join(
              " "
            )}
            style={{ margin: 0 }}>
            {/*
            <Editor
              ref={er}
              htmlPath={htmlPath}
              html={detail}
              onHtmlChange={setDetail}
            />
            */}
            <InputBase
              multiline
              style={{
                flex: 1,
                border: "1px solid #f5f5f5",
              }}
              inputProps={{
                style: {
                  minHeight: 300,
                  maxHeight: 500,
                  overflow: "auto",
                },
              }}
              value={productcomment}
              onChange={(e) => {
                setproductcomment(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid
            item
            className={[classes.dfsc, classes.subtitle].join(" ")}
            style={{
              justifyContent: "space-between",
              margin: 0,
              padding: "10px 20px 10px 20px",
            }}>
            <Typography variant="subtitle1" className={classes.bold}>
              상품 설명 이미지
            </Typography>
            <Flex>
              <Button
                style={{ border: "1px solid", fontWeight: "bold" }}
                onClick={() => setViewerVisible(true)}>
                미리보기
              </Button>
            </Flex>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}>
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={11}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                {detailImages.map((item, idx) => {
                  return (
                    <div
                      className={classes.dfcc}
                      style={{ marginLeft: "10px", flexDirection: "column" }}>
                      <img
                        src={
                          item.path ? consts.s3BaseUrl + item.path : item.uri
                        }
                        style={{ height: 100 }}
                      />
                      <Grid item xs={12} className={classes.dfcs}>
                        <Button
                          onClick={() => {
                            setDetailImages([
                              ...detailImages.filter((x, i) => i !== idx),
                            ]);
                            if (item.id) {
                              setDeleteDetails([...deleteDetails, item.id]);
                            }
                          }}
                          size="small">
                          <Typography variant="subtitle2">삭제</Typography>
                        </Button>
                      </Grid>
                    </div>
                  );
                })}
                <input
                  accept="image/*"
                  type="file"
                  id="add-detail-image-file"
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={handleChangeDetailImage}
                />
                <label htmlFor="add-detail-image-file">
                  <Button
                    className={classes.imageAddBtn}
                    component="span"
                    style={{ marginLeft: 10 }}>
                    ADD
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}>
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={5}
              className={[classes.dfsc, classes.subtitle].join(" ")}>
              <Typography variant="subtitle1" className={classes.bold}>
                상품 인쇄 설정
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  명칭변경
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  variant="outlined"
                  value={labelName}
                  onChange={(e) => setLabelName(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  라벨 색상
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}>
                <ColorPicker
                  value={labelColor}
                  onChange={(e) => {
                    setLabelColor(e.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.dfcc}
          style={{ marginBottom: "30px" }}>
          <Button
            variant="outlined"
            onClick={onClickCancelBtn}
            style={{ backgroundColor: "#fff", color: "#000" }}>
            <Typography variant="subtitle1">취소</Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={onClickSaveBtn}
            style={{
              marginLeft: "10px",
              backgroundColor: "#4664FD",
              color: "#fff",
            }}>
            <Typography variant="subtitle1">저장</Typography>
          </Button>
        </Grid>
      </Grid>
      <DetailImageViewer
        images={detailImages}
        visible={viewerVisible}
        onClose={() => setViewerVisible(false)}
      />
    </>
  );
}

const DetailImageViewer = ({ images, visible = false, onClose }) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    scrollRef.current.scrollIntoView();
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [visible]);
  return (
    <Flex
      style={{
        display: visible ? "flex" : "none",
        background: "rgba(0, 0, 0, 0.6)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2000,
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClose}>
      <div
        style={{
          width: 400,
          maxHeight: "100%",
          overflow: "auto",
        }}>
        <div ref={scrollRef} />
        {images.map((item) => {
          return (
            <img
              src={item.path ? consts.s3BaseUrl + item.path : item.uri}
              style={{ display: "block", width: "100%" }}
            />
          );
        })}
      </div>
    </Flex>
  );
};

const MyContext = createContext({
  reorder: (dataItem) => {},
  dragStart: (dataItem) => {},
});

const DragCell = ({ dataItem }) => {
  const currentContext = useContext(MyContext);
  return (
    <td>
      <AlignSpan
        onDragOver={(e) => {
          currentContext.reorder(dataItem);
          e.preventDefault();
          e.dataTransfer.dropEffect = "copy";
        }}>
        <span
          className="k-icon k-i-reorder"
          draggable={true}
          style={{ cursor: "move" }}
          onDragStart={(e) => {
            currentContext.dragStart(dataItem);
            e.dataTransfer.setData("dragging", "");
          }}
        />
      </AlignSpan>
    </td>
  );
};

const MyCommandCell = ({ dataItem, remove }) => {
  const isNewItem = dataItem.isNew;
  return (
    <td className="k-command-cell">
      {isNewItem && (
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
          onClick={() => remove(dataItem)}>
          삭제
        </button>
      )}
    </td>
  );
};

const MyRadioCell = ({ dataItem, EditOption, keyName }) => {
  const list =
    keyName === "visible"
      ? [
          { label: "노출", value: "VISIBLE" },
          { label: "비노출", value: "HIDDEN" },
        ]
      : [
          { label: "활성", value: true },
          { label: "비활성", value: false },
        ];

  const handleChange = (e) => {
    EditOption(dataItem, e.value, keyName);
  };

  return (
    <td>
      <AlignSpan>
        <RadioGroup
          data={list}
          layout="horizontal"
          value={dataItem[keyName]}
          onChange={handleChange}
        />
      </AlignSpan>
    </td>
  );
};

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
    backgroundColor: "rgb(250,250,250)",
  },
  subtitle: {
    marginTop: "10px",
    marginLeft: "20px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  infoGrid: {
    border: "1px solid #B7ACAC",
    backgroundColor: "#fff",
    marginBottom: "20px",
  },
  infoContent: {
    padding: "10px 20px 10px 20px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  radioBtn: {
    marginLeft: "20px",
  },
  imageAddBtn: {
    width: "100px",
    height: "100px",
    border: "2px dotted #efefef",
    fontWeight: "bold",
  },
  ckeditor: {
    marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px",
    },
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  dropZone: {
    width: "100%",
    height: 160,
    position: "sticky !important",
    overflowX: "hidden",
  },
  dropContainer: {
    touchAction: "none",
    width: "100%",
    display: "flex",
  },
  imgWrap: {
    padding: 5,
    cursor: "pointer",
    width: "100%",
    height: "100%",
    maxWidth: 150,
    alignItems: "center",
  },
});

const AlignSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionWrap = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 20px 20px;
  border: 1px solid #b7acac;
  background-color: white;
  margin-bottom: 20px;
`;

const OptionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const OptionGridWrap = styled.div`
  width: 60%;
`;

const CheckboxWrap = styled.div`
  width: 90px;
  display: flex;
  justify-content: space-between;
`;

const Notice = styled.div`
  font-size: 14px;
  color: #d3322e;

  &.mb-5 {
    margin-bottom: 5px;
  }
`;
