import moment from "moment-timezone";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";

import consts from "libs/consts";
import routes from "libs/routes";
import * as APIS from "libs/apis";
import { actionOpen, actionError } from "redux/action/ActionActions";
import { openImageModal } from "redux/image-modal/ImageModalReducer";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import {
  formatPhone,
  formatTime,
  getPurityLabel,
  numFormat,
} from "services/utils";
import Flex from "components/flex/Flex";
import StatusUpdater from "./StatusUpdater";
import SimpleTable from "components/simple-table/SimpleTable";
import AdminConfirmBtn from "components/admin-confirm-btn/AdminConfirmBtn";
import { CommandCell } from "components/gird-table/GridTable";

export default function AppraisalStatusDetail({}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};
  const classes = useStyle();
  const [data, setData] = useState();
  const [weightRates, setWeightRates] = useState([]);
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  const [returnCancelReason, setReturnCancelReason] = useState("");
  const [appraiseCancelReason, setAppraiseCancelReason] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [expectDeadLine, setExpectDeadLine] = useState(new Date());
  const [deadLineDate, setDeadLineDate] = useState(new Date());
  const [deadLineEdit, setDeadLineEdit] = useState(false);

  const handleDeadLineDate = (e) => {
    setDeadLineDate(e.value);
  };

  const handleSetDeadline = () => {
    dispatch(loadingStart);
    APIS.putAppraisalDeadline(state?.id, {
      deadlineStockDt: formatTime(deadLineDate, "YYYY-MM-DD"),
    })
      .then((res) => {
        console.log(res.data);
        setDeadLineEdit(false);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  useEffect(() => {
    fetchData();
  }, [state?.id]);

  const fetchData = async () => {
    dispatch(loadingStart);

    try {
      const [goldPrice, silverPrice, weightRates, appraisalDetail] =
        await Promise.all([
          fetchMarketPrice("GOLD"),
          fetchMarketPrice("SILVER"),
          fetchAppraisalWeightRates(),
          fetchAppraisalRequestDetail(state.id),
        ]);

      setGoldGramPrice(goldPrice);
      setSilverGramPrice(silverPrice);
      setWeightRates(weightRates);
      setData(appraisalDetail);
      if (appraisalDetail?.deadlineStockDt) {
        setDeadLineDate(new Date(appraisalDetail?.deadlineStockDt));
      }
    } catch (error) {
      dispatch(actionError(error));
    } finally {
      dispatch(loadingEnd);
    }
  };

  const fetchMarketPrice = async (type) => {
    const response = await APIS.getcurrentmarketprice(type);
    return response.data.data;
  };

  const fetchAppraisalWeightRates = async () => {
    const response = await APIS.getAppraisalWeightRates();
    return response.data.data.content;
  };

  const fetchAppraisalRequestDetail = async (id) => {
    const response = await APIS.getAppraisalRequestDetail(id);
    return response.data.data;
  };

  if (!state.id) {
    return (
      <Redirect
        to={{
          pathname: routes.appraisalStatus,
        }}
      />
    );
  }
  if (!data) {
    return null;
  }

  let totalGram =
    data.status === "교환완료"
      ? data.appraisalWeightGram
      : weightRates && weightRates.length
      ? data.appraisalSimples.reduce((a, b) => {
          return (
            b.weightGram *
              (weightRates.filter(
                (y) =>
                  y.assetType === b.appraisalProduct.assetType &&
                  y.purity === b.purity
              )[0]?.weightRatePct /
                100) *
              (b.appraisalProduct.weightRatePct / 100) *
              b.quantity +
            a
          );
        }, 0.0)
      : 0;

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getAppraisalRequestDetail(state.id)
      .then((res) => {
        setData({
          ...res.data.data,
        });
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  // 상태변경 함수
  const handleStatusChange = (status, password) => {
    let updateData = { status, password };

    if (status === "VERIFY_DENY") {
      updateData.appraiseCancelReason = cancelReason;
    } else if (status === "RETURN_COMPLETE") {
      updateData.returnCancelReason = cancelReason;
    }

    dispatch(loadingStart);

    APIS.putAppraisalRequestStatus(data.id, updateData)
      .then((res) => {
        let message;
        if (status === "CANCEL") {
          message = "접수가 취소되었습니다.";
        } else if (status === "VERIFY_DENY") {
          message = "감정이 반려되었습니다.";
        } else if (status === "RETURN_COMPLETE") {
          message = "감정이 반려되었습니다.";
        }

        if (res.data.success) {
          dispatch(actionOpen(message, fetchData, null, null, true));
        } else {
          dispatch(actionError(res.data.message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const renderDetailContainer = (status) => {
    let title, label, callback;

    switch (status) {
      case "접수":
        title = "접수취소";
        label = "접수취소";
        callback = (password) => handleStatusChange("CANCEL", password);
        break;
      case "감정시작":
        title = "감정반려";
        label = "감정반려";
        callback = (password) =>
          handleStatusChange("VERIFY_DENY", password, cancelReason);
        break;
      case "감정완료":
        title = "감정완료 취소";
        label = "감정완료 취소";
        callback = (password) =>
          handleStatusChange("RETURN_COMPLETE", password, cancelReason);
        break;
      default:
        return null;
    }

    return (
      <DetailContainer>
        {["감정시작", "감정완료"].includes(status) && (
          <form>
            <ReasonInput
              type="text"
              placeholder="취소 사유 입력"
              id="cancelReason"
              value={cancelReason}
              autoComplete="off"
              onChange={(e) => setCancelReason(e.target.value)}
            />
          </form>
        )}
        <AdminConfirmBtn
          title={title}
          label={label}
          callback={callback}
          rootClassName={classes.confirmInfoSaveButton}
        />
      </DetailContainer>
    );
  };

  return (
    <>
      <span className={classes.label}>감정평가 상세보기</span>
      <SimpleTable
        fullWidth
        columns={[
          {
            label: "접수번호",
            key: "number",
          },
          {
            label: "요청일시",
            key: "requestDate",
          },
          {
            label: "수량",
            key: "quantity",
          },
          {
            label: "고객",
            key: "user",
          },
          {
            label: "접수 방법",
            key: "method",
          },
          {
            label: "접수 대리점",
            key: "shopName",
          },
          {
            label: "진행상태",
            key: "status",
          },
          {
            label: "예약일",
            key: "visitDate",
          },
          // ["교환완료", "입고배송"].includes(
          //   data.appraisalRequestStatus[data.appraisalRequestStatus.length - 1]
          //     .status
          // ) && ,
          {
            label: "입고 마감일",
            key: "deadlineStockDt",
            render: (x) => {
              return (
                <Flex>
                  {data.deadlineStockDt ? (
                    deadLineEdit ? (
                      <>
                        <DatePicker
                          defaultValue={deadLineDate}
                          onChange={handleDeadLineDate}
                          format={"yyyy-MM-dd"}
                          width={130}
                        />
                        <Flex row center style={{ marginTop: 7 }}>
                          <Button
                            className={"k-grid-edit-command"}
                            themeColor="primary"
                            onClick={(_) => {
                              handleSetDeadline();
                            }}
                            style={{ marginRight: 7 }}
                          >
                            {"확인"}
                          </Button>

                          <Button
                            className={"k-grid-cancel-command"}
                            onClick={(_) => {
                              setDeadLineDate(expectDeadLine);
                              setDeadLineEdit(false);
                            }}
                          >
                            {"취소"}
                          </Button>
                        </Flex>
                      </>
                    ) : (
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underLine",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setDeadLineEdit(true);
                          setExpectDeadLine(deadLineDate);
                        }}
                      >
                        {deadLineDate && formatTime(deadLineDate, "yyyy-MM-DD")}
                      </span>
                    )
                  ) : (
                    <span>-</span>
                  )}
                </Flex>
              );
            },
          },
          {
            label: "최종 처리일시",
            key: "lastDate",
          },
        ].filter((x) => x)}
        data={[
          {
            shopName: data?.shop?.name,
            number: data.number,
            quantity:
              data.method === "현장감정" ? "-" : data.appraisalSimples.length,
            requestDate:
              data.appraisalRequestStatus.length > 0
                ? moment(
                    data.appraisalRequestStatus.find((x) => x.status === "접수")
                      .createdAt
                  ).format("YYYY.MM.DD HH:mm")
                : "",
            user: data.user.name + " (" + formatPhone(data.user.phone) + ")",
            method: data.method,
            status: data.status,
            visitDate:
              data.method === "현장감정"
                ? "-"
                : data.method === "택배"
                ? data.collectDate + " " + data.collectTime
                : data.visitDate,
            lastDate:
              data.appraisalRequestStatus.length > 0
                ? moment(
                    data.appraisalRequestStatus[
                      data.appraisalRequestStatus.length - 1
                    ].updatedAt
                  ).format("YYYY.MM.DD HH:mm")
                : "",
          },
        ]}
      />
      {data.method !== "현장감정" && (
        <>
          <Flex row style={{ justifyContent: "space-between" }}>
            <span className={classes.label}>회원 간편 감정 정보</span>
            <div className={classes.calc}>
              간편 감정 결과 :{" "}
              {data?.appraisalSimples[0]?.appraisalProduct?.assetType}{" "}
              {parseFloat(totalGram).toFixed(2) +
                "g = " +
                numFormat(
                  totalGram *
                    (data?.appraisalSimples[0]?.appraisalProduct?.assetType ===
                    "GOLD"
                      ? goldGramPrice
                      : silverGramPrice)
                ) +
                "원"}
            </div>
          </Flex>
          <SimpleTable
            fullWidth
            columns={[
              {
                label: "제품분류",
                render: (x) =>
                  x.appraisalProduct.assetType + ">" + x.appraisalProduct.name,
              },
              {
                label: "순도",
                render: (x) =>
                  getPurityLabel(x.purity, x.appraisalProduct.assetType),
              },
              { label: "중량", render: (x) => parseFloat(x.weightGram) + "g" },
              { label: "수량", key: "quantity" },
              {
                label: "이미지",
                render: (x) => {
                  let images = [
                    x.topImageUrl,
                    x.frontImageUrl,
                    x.leftSideImageUrl,
                    x.rightSideImageUrl,
                  ].filter((y) => y);
                  return (
                    <div className={classes.images}>
                      {images.map((y, z) => {
                        return (
                          <img
                            onClick={() => {
                              dispatch(
                                openImageModal({
                                  src: consts.s3BaseUrl + "/" + y,
                                })
                              );
                            }}
                            style={{ cursor: "pointer" }}
                            key={z.toString()}
                            alt="gurantter"
                            src={consts.s3BaseUrl + "/" + y}
                            className={classes.image}
                          />
                        );
                      })}
                    </div>
                  );
                },
              },
              {
                label: "보증서",
                render: (x) =>
                  x.guaranteeImageUrl ? (
                    <img
                      alt="gurantter"
                      src={consts.s3BaseUrl + "/" + x.guaranteeImageUrl}
                      className={classes.image}
                      onClick={() => {
                        dispatch(
                          openImageModal({
                            src: consts.s3BaseUrl + "/" + x.guaranteeImageUrl,
                          })
                        );
                      }}
                    />
                  ) : (
                    ""
                  ),
              },
            ]}
            data={data.appraisalSimples}
          />
        </>
      )}

      {renderDetailContainer(data.status)}
      {data.status === "접수" || data.status === "감정시작" ? (
        <></>
      ) : (
        <span className={classes.label}>감정평가</span>
      )}
      <StatusUpdater
        key={JSON.stringify(data)}
        goldGramPrice={goldGramPrice}
        silverGramPrice={silverGramPrice}
        fetchList={fetchList}
        detailData={data}
        {...data}
      />
      {/* {data.method === "현장감정" || data.method === "내방" ? (
      ) : (
        <></>
      )} */}
    </>
  );
}

const useStyle = makeStyles({
  gridLabel: {
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    padding: 14,
  },
  gridValue: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    "& svg": {
      color: "#000",
    },
    "& .MuiFormControlLabel-root": {
      minWidth: 150,
    },
  },
  input: {
    flex: 1,
    marginRight: "50px",
    paddingLeft: "16px",
    marginLeft: "100px",
    border: "1px solid rgb(224,224,224)",
  },
  grid: {
    alignSelf: "stretch",
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 20,
  },
  calc: {
    margin: "30px 50px 0px 50px",
    textAlign: "right",
    // marginBottom: 5,
    // borderBottom: "1px solid rgb(224,224,224)",
  },
  images: { display: "flex", alignItems: "center", width: "100%" },
  image: {
    width: "80px",
    height: "80px",
    marginRight: 16,
    "&:last-child": { marginRight: 0 },
  },
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  multiInput: {
    border: "1px  solid #ddd",
    minWidth: "70%",
    padding: 10,
    marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },
  mainC2Content: {
    alignItems: "center",
    padding: 50,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: 50,
    "& button": {
      minWidth: 150,
    },
    marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    marginLeft: 50,
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: {
    marginRight: 50,
    flex: 3,
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
});

const DetailContainer = styled.div`
  display: flex;
  padding: 10px 50px;
`;

const ReasonInput = styled(Input)`
  width: 250px;
`;
