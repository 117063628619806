import { makeStyles, TablePagination } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function DirectOrder({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  return (
    <>
      <div style={{ marginLeft: 50, marginTop: 30 }}>
        <ButtonRadios
          data={[
            { label: "결제완료", value: "" },
            { label: "배송준비92", value: "서울" },
            { label: "배송중10", value: "경기" },
            { label: "배송완료1", value: "인천" },
            { label: "구매확정32", value: "경상" },
            { label: "취소/환불2", value: "충청" },
          ]}
          field="location"
        />
      </div>

      <Filters
        data={[
          {
            label: "조회기간",
            type: "date-range",
          },
          {
            label: "검색",
            placeholder: "상품명, 주문자",
            type: "input",
          },
        ]}
      />

      <Flex row className={classes.toolbar}>
        <Text>목록 검색결과 10</Text>
        <Button label="엑셀다운로드" />
      </Flex>
      <table cellPadding={0} cellSpacing={0} className={classes.table2}>
        <tr>
          <th rowSpan={3}>No</th>
          <th>주문일</th>
          <th>판매자</th>
          <th>주문자</th>
          <th rowSpan={3}>상품명</th>
          <th rowSpan={3}>물품수령 방법</th>
          <th style={{ color: "red" }}>판매금액</th>
          <th rowSpan={3}>결제 금액</th>
        </tr>

        <tr>
          <th rowSpan={2}>주문번호</th>
          <th>회원ID</th>
          <th>회원ID</th>
          <th style={{ color: "red" }}>수수료</th>
        </tr>

        <tr>
          <th>연락처</th>
          <th>연락처</th>
          <th>주문수량</th>
        </tr>

        {[{}, {}].map((x, i) => {
          return (
            <>
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(routes.directOrderDetail);
                }}
              >
                <td rowSpan={3}>default</td>
                <td>default</td>
                <td>default</td>
                <td>default</td>
                <td rowSpan={3}>default</td>
                <td rowSpan={3}>default</td>
                <td>default</td>
                <td rowSpan={3}>default</td>
              </tr>

              <tr
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(routes.directOrderDetail);
                }}
              >
                <td rowSpan={2}>default</td>
                <td>default</td>
                <td>default</td>
                <td>default</td>
              </tr>

              <tr
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(routes.directOrderDetail);
                }}
              >
                <td>default</td>
                <td>default</td>
                <td>default</td>
              </tr>
            </>
          );
        })}
      </table>
      <TablePagination
        page={1}
        count={40}
        onChangePage={() => {}}
        rowsPerPage={10}
        style={{ margin: "0px 50px", borderBottom: "none" }}
      />
    </>
  );
}
const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },

  toolbar: {
    margin: "100px 50px 30px 50px",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
