import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
} from "@material-ui/core";
import { Add, ExpandMore } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useRef } from "react";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Flex from "../../components/flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import Text from "../../components/text/Text";
import { formatTime } from "../../libs/utils";

export default function ProductAdminMemo(props) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const [adminMemoList, setAdminMemoList] = useState([]);
  const messagesEndRef = useRef(null);
  const [adminMemo, setAdminMemo] = useState("");

  //관리자 비밀번호 저장
  const onSaveAdmin = async (adminPassword) => {
    if (adminMemo === "" || adminMemo === null) {
      dispatch(actionError("관리자 메모를 입력하여 주세요."));
    } else {
      const data = {
        memo: adminMemo,
        managerPassword: adminPassword,
      };
      dispatch(loadingStart);
      APIS.postRegisterAdminMemo(state.itemId, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "메모가 저장되었습니다.",
                () => {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { ...state, itemId: state.itemId },
                  });
                  setAdminMemo("");
                },
                null,
                null,
                true
              )
            );
            setAdminMemoList(data);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView();
  };

  useEffect(scrollToBottom, [adminMemoList]);
  useEffect(() => {
    dispatch(loadingStart);
    const getAdminMemoHistory = APIS.getAdminMemoHistory(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAdminMemoList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    Promise.all([getAdminMemoHistory]).finally(() => dispatch(loadingEnd));
  }, []);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel3a-content"
        id="panel3a-header"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Typography style={{ fontWeight: "bold" }}>관리자 메모</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ justifyContent: "center" }}>
        <Flex style={{ width: "100%" }}>
          <Flex className={classes.scroll}>
            {adminMemoList.length
              ? adminMemoList?.map((memo, index) => {
                  return (
                    <Flex
                      row
                      style={{
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <Text>
                        {memo.content.split("").map((x, i) => {
                          if (x === "\n") {
                            return <br key={i.toString()} />;
                          } else {
                            return x;
                          }
                        })}
                      </Text>
                      <Text style={{ alignSelf: "end" }}>
                        {memo.managerName +
                          " / " +
                          formatTime(memo.createdAt, "YYYY-MM-DD HH:mm:ss")}
                      </Text>
                    </Flex>
                  );
                })
              : ""}
            <div ref={messagesEndRef} />
          </Flex>
          <Flex row>
            <Flex style={{ width: "93%" }}>
              <InputBase
                multiline
                style={{
                  flex: 1,
                  border: "1px solid black",
                  // overflowY: "scroll",
                }}
                inputProps={{
                  style: {
                    maxHeight: "70px",
                  },
                }}
                value={adminMemo}
                onChange={(e) => {
                  setAdminMemo(e.target.value);
                }}
              />
            </Flex>
            <Flex>
              <AdminConfirmButton
                popupLabel="관리자 메모 변경"
                popupMessage="변경사항을 저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
            </Flex>
          </Flex>
        </Flex>
      </AccordionDetails>
    </Accordion>
  );
}
const useStyle = makeStyles({
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  accordion: {
    marginBottom: "20px",
  },
  scroll: {
    maxHeight: 300,
    // width: 700,
    padding: "10px 0px 10px 0px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: 10 },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2f3542",
      borderRadius: 10,
      backgroundClip: "padding-box",
      border: "2px solid transparent",
    },
    // "&::-webkit-scrollbar-track": {
    //   backgroundColor: "grey",
    //   borderRadius: 10,
    //   boxShadow: "inset 0px 0px 5px white",
    // },
  },
});
