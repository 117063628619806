import Flex from "components/flex/Flex";
import StyledTable from "components/table/StyledTable";
import { numberWithCommas } from "libs/utils";

const ApprisalStatus = ({ appraisalStatus, isLoading }) => {
  return (
    <div>
      <div style={{ fontSize: "17px", fontWeight: "bold" }}>손익재고현황</div>
      <Flex row>
        {!appraisalStatus || isLoading ? (
          <div
            style={{ height: "32px", display: "flex", alignItems: "center" }}
          >
            조회 중입니다...
          </div>
        ) : (
          appraisalStatus.map((item, index) => {
            return (
              <Flex key={item.goldOrSilver} row>
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "bold",
                    padding: `5px 10px 5px ${index === 1 ? "5px" : "0"}`,
                  }}
                >
                  {item.goldOrSilver}:
                </div>
                <StyledTable className="summary">
                  <Flex row>
                    <Flex className="header">손익</Flex>
                    <Flex
                      className="data"
                      style={{
                        width: 130,
                        justifyContent: "right",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    >
                      {numberWithCommas(item.income)} g
                    </Flex>
                    <Flex className="header">처분예정</Flex>
                    <Flex
                      className="data"
                      style={{
                        width: 130,
                        justifyContent: "right",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    >
                      {numberWithCommas(item.appraisalFee)} g
                    </Flex>
                    <Flex className="header">전량</Flex>
                    <Flex
                      className="data"
                      style={{
                        width: 130,
                        justifyContent: "right",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    >
                      {numberWithCommas(item.incomeBalance)} g
                    </Flex>
                  </Flex>
                </StyledTable>
              </Flex>
            );
          })
        )}
      </Flex>
    </div>
  );
};

export default ApprisalStatus;
