import { Grid, makeStyles } from "@material-ui/core";
import { GridColumn } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import GridTable from "../../components/gird-table/GridTable";
import SimpleTable from "../../components/simple-table/SimpleTable";
import StyledTable from "../../components/table/StyledTable";
import SmallTitle from "../../components/title/SmallTitle";
import * as APIS from "../../libs/apis";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import { numberWithCommas } from "../../libs/utils";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { formatTime, objToQueryStr } from "../../services/utils";
import PhysicalOrderDetailDialog from "./PhysicalOrderDetailDialog";
import DeliveryDetailTable from "./components/DeliveryDetailTable";
import StatusRadioGroup from "./components/StatusRadioGroup";

const Container = styled(Flex)`
  .header {
    min-width: 130px;
  }
`;

export default function PhysicalOrderDetail({}) {
  const dispatch = useDispatch();
  let clickCheck = false;
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  const classes = useStyle();
  const [dialog, setDialog] = useState(false);
  const [rows, setrows] = useState([]);
  const [product, setproduct] = useState({
    imageIds: [
      {
        path: "",
      },
    ],
  });

  const [status, setStatus] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [statussave, setstatussave] = useState("");
  const [shipmentStatusSave, setShipmentStatusSave] = useState("");
  const [estimateDate, setestimateDate] = useState("");
  const [memo, setmemo] = useState("");
  const [standardPay, setstandardPay] = useState("");
  const [additionalPay, setadditionalPay] = useState(0);
  const [priceSum, setpriceSumData] = useState(0);
  const [transOrderid, settransOrderid] = useState(0);
  const [imgspopOpen, setimgspopOpen] = useState(0);
  const [ordermemo, setordermemo] = useState("");
  // const [historyset, sethistoryset] = useState({
  //   id: 0,
  //   memo: "",
  // });
  const [histories, setHistories] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [additionalDeliveryQty, setAdditionalDeliveryQty] = useState(0); // 추가인출수량
  const { isKorda, shopId } = useSelector((s) => s.auth);

  const onSaveAdmin = async (adminPassword) => {
    if (!clickCheck) {
      clickCheck = true;
      let data = {};
      // if (shipmentStatus === "MAKE" || shipmentStatus === "SHIPMENT") {
      //   paramQuery = objToQueryStr({
      //     estimateDate: estimateDate,
      //     estimateOutSourcingDate: estimateDate,
      //     // historyId: historyset.id,
      //     memo: memo,
      //     standardPay: standardPay,
      //     status: status,
      //     shipmentStatus: shipmentStatus,
      //     additionalPay: additionalPay,
      //     transId: state.id,
      //   });
      //   data = {
      //     estimateDate: estimateDate,
      //     estimateOutSourcingDate: estimateDate,
      //     // historyId: historyset.id,
      //     memo: memo,
      //     standardPay: standardPay,
      //     status: status,
      //     shipmentStatus: shipmentStatus,
      //     additionalPay: additionalPay,
      //     transId: state.id,
      //     managerPassword: adminPassword,
      //   };
      // } else {
      data = {
        estimateDate: estimateDate,
        estimateOutSourcingDate: estimateDate,
        standardPay: standardPay,
        status: status,
        memo: memo,
        shipmentStatus: shipmentStatus,
        additionalPay: additionalPay,
        transId: state.id,
        managerPassword: adminPassword,
      };
      for (let key in data) {
        const value = data[key];
        if ((typeof value === "string" && !value) || value === null) {
          delete data[key];
        }
      }
      // }
      APIS.postProductOrderMod(data).then(({ data }) => {
        if (!data.success) {
          clickCheck = false;
          dispatch(
            actionOpen(
              data.message,
              () => {
                onLoad();
              },
              null,
              null,
              true
            )
          );
          dispatch(loadingEnd);
        } else {
          if (status === "CONFIRM") {
            pendingCheckConfirm(1, state?.id);
          } else {
            dispatch(
              actionOpen(
                "제작 주문 정보가 수정되었습니다.",
                () => {
                  onLoad();
                  dispatch(loadingEnd);
                },
                null,
                null,
                true
              )
            );
            if (status === "CANCEL") {
              if (shipmentStatus !== "CANCEL_RETURN") {
                history.go(-1);
              }
            }
          }
        }
      });
      // dispatch(loadingStart);
      // const data = {
      //   ids: state?.selected || [],
      //   status: selectState,
      //   managerPassword: adminPassword,
      // };
      // APIS.putGoodsWithdrawalStatus(data)
      //   .then(({ data: { data, message, success } }) => {
      //     if (success) {
      //       dispatch(
      //         actionOpen(
      //           "진행상태가 변경되었습니다.",
      //           () => {
      //             // handleValueChange("selected", []);
      //             history.replace({
      //               pathname: location.pathname,
      //               state: null,
      //             });
      //             // fetchList();
      //           },
      //           null,
      //           null,
      //           true
      //         )
      //       );
      //     } else {
      //       dispatch(actionError(message));
      //     }
      //   })
      //   .catch((error) => dispatch(actionError(error)))
      //   .finally(() => dispatch(loadingEnd));
      // }
      return () => {};
    }
  };

  const pendingCheckConfirm = (count, id) => {
    const param = { id: id };
    dispatch(loadingStart);
    setTimeout(() => {
      APIS.getGoodsWithdrawalConfirmCheck(objToQueryStr(param)).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "제작 주문 정보가 수정되었습니다.",
                () => {
                  onLoad();
                  dispatch(loadingEnd);
                },
                null,
                null,
                true
              )
            );
          } else {
            if (count === 10) {
              dispatch(
                actionOpen(
                  "확인 처리 중입니다.",
                  () => {
                    pendingCheckConfirm(count + 1, id);
                    dispatch(loadingEnd);
                  },
                  null,
                  null,
                  true
                )
              );
            } else {
              pendingCheckConfirm(count + 1, id);
            }
          }
        }
      );
    }, 1000);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    APIS.getOrderDetail(state.id).then(({ data }) => {
      setrows(data.data);
      setStatus(data.data.status);
      setShipmentStatus(data.data.shipmentStatus);
      setstatussave(data.data.status);
      setShipmentStatusSave(data.data.shipmentStatus);
      if (data.data.deliveryResponse) {
        data.data.deliveryResponse[
          "totalAddress"
        ] = `(${data.data.deliveryResponse["postCode"]}) ${data.data.deliveryResponse["address"]}  ${data.data.deliveryResponse["addressDetail"]}`;
        data.data.deliveryResponse["phoneMobile"] =
          (data.data.deliveryResponse["phone"]
            ? data.data.deliveryResponse["phone"]
            : "-") +
          "/ " +
          (data.data.deliveryResponse["mobile"]
            ? data.data.deliveryResponse["mobile"]
            : "-");
        data.data.deliveryResponse["requestMemo"] = data.data.deliveryResponse[
          "passwordDoor"
        ]
          ? `${data.data.deliveryResponse["memo"]} (현관 비밀번호 : ${data.data.deliveryResponse["passwordDoor"]})`
          : `${data.data.deliveryResponse["memo"] || "-"}`;
        setDeliveryInfo(data.data.deliveryResponse);
      }
      let reformData = [];
      data.data.goodsTransHistory.forEach((element) => {
        reformData.push({
          ...element,
          status: element.status
            ?.replace("APPROVAL", "결제")
            ?.replace("MAKE", "제작중")
            ?.replace("SHIPMENT", "출고중")
            ?.replace("DELIVERY", "출고완료")
            ?.replace("TAKEOUT", "인출완료")
            ?.replace("CONFIRM", "인출확정")
            ?.replace("CANCEL_RETURN", "취소 회수")
            ?.replace("CANCEL", "결제취소"),
        });
      });
      setHistoryList(reformData);
      APIS.getProductDetail(data.data.goodsId).then(({ data }) => {
        setproduct(data.data);

        // 기본 공임
        setstandardPay(data.data.pay);
      });
      APIS.getProductOrderHistory(data.data.transId).then(({ data }) => {
        setestimateDate(data.data.estimateOutSourcingDate);

        // setstandardPay(data.data.standardPay);

        // 추가 공임
        setadditionalPay(data.data.additionalPay);

        // var priceSumData =
        //   data.data.standardPay * 1 + data.data.additionalPay * 1;

        settransOrderid(data.data.transId);
        var historyData = data.data.histories;
        // for (let i in historyData) {
        //   if (
        //     historyData[i].status === "MAKE" ||
        //     historyData[i].status === "SHIPMENT"
        //   ) {
        //     var historyset = {
        //       id: historyData[i].id,
        //       memo: historyData[i].memo,
        //     };
        //     setmemo(historyData[i].memo);
        //     sethistoryset(historyset);
        //   }
        // }
        setmemo(data.data.memo);
        setHistories(historyData);
      });
    });
  };

  // const setpriceSum = (v) => {
  //   setstandardPay(v);
  //   setpriceSumData(additionalPay * 1 + v * 1);
  // };
  // const setpriceSum2 = (v) => {
  //   setadditionalPay(v);
  //   setpriceSumData(standardPay * 1 + v * 1);
  // };

  // const orderDatilSaver = () => {
  //   var paramQuery = "";
  //   var data = {};
  //   if (status === "MAKE") {
  //     paramQuery = objToQueryStr({
  //       estimateDate: estimateDate,
  //       estimateOutSourcingDate: estimateDate,
  //       historyId: historyset.id,
  //       memo: memo,
  //       standardPay: standardPay,
  //       status: status,
  //       additionalPay: additionalPay,
  //       transId: state.id,
  //     });
  //     data = {
  //       estimateDate: estimateDate,
  //       estimateOutSourcingDate: estimateDate,
  //       historyId: historyset.id,
  //       memo: memo,
  //       standardPay: standardPay,
  //       status: status,
  //       additionalPay: additionalPay,
  //       transId: state.id,
  //     };
  //   } else {
  //     paramQuery = objToQueryStr({
  //       estimateDate: estimateDate,
  //       estimateOutSourcingDate: estimateDate,
  //       standardPay: standardPay,
  //       status: status,
  //       additionalPay: additionalPay,
  //       transId: state.id,
  //     });
  //     data = {
  //       estimateDate: estimateDate,
  //       estimateOutSourcingDate: estimateDate,
  //       standardPay: standardPay,
  //       status: status,
  //       additionalPay: additionalPay,
  //       transId: state.id,
  //     };
  //   }

  //   if (status === "MAKE") {
  //     APIS.postProductOrderModPut(paramQuery, data)
  //       .then(({ data }) => {
  //         if (!data.success) {
  //           dispatch(actionOpen(data.message, null, null, null, true));
  //         } else {
  //           dispatch(
  //             actionOpen(
  //               "제작 주문 정보가 수정되었습니다.",
  //               null,
  //               null,
  //               null,
  //               true
  //             )
  //           );

  //           if (status === "CANCEL") {
  //             history.go(-1);
  //           }

  //           onLoad();
  //         }
  //       })
  //       .finally(() => {
  //         dispatch(loadingEnd);
  //       });
  //   } else {
  //     APIS.postProductOrderMod(paramQuery, data)
  //       .then(({ data }) => {
  //         if (!data.success) {
  //           dispatch(actionOpen(data.message, null, null, null, true));
  //         } else {
  //           dispatch(
  //             actionOpen(
  //               "제작 주문 정보가 수정되었습니다.",
  //               null,
  //               null,
  //               null,
  //               true
  //             )
  //           );

  //           if (status === "CANCEL") {
  //             history.go(-1);
  //           }

  //           onLoad();
  //         }
  //       })
  //       .finally(() => {
  //         dispatch(loadingEnd);
  //       });
  //   }
  // };

  const canModify =
    isKorda &&
    (statussave === "APPROVAL" ||
      statussave === "TAKEOUT" ||
      statussave === "CONFIRM") &&
    (shipmentStatusSave === "NOT_SHIPPED" || shipmentStatusSave === "MAKE");

  const handleClickSaveAddDelivery = (adminPassword, isType) => {
    const data = {
      transId: state.id,
      additionalDeliveryQty: additionalDeliveryQty,
      managerPassword: adminPassword,
      isType,
    };
    dispatch(loadingStart);
    APIS.postAddDelivery(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "추가 인출 수량이 저장 되었습니다.",
              () => {
                onLoad();
                dispatch(loadingEnd);
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <>
      {dialog && (
        <PhysicalOrderDetailDialog
          onClose={() => {
            setDialog(false);
          }}
        />
      )}
      <SmallTitle style={{ margin: "30px 50px 0px 50px" }}>
        주문 상세보기
      </SmallTitle>
      <SimpleTable
        fullWidth
        columns={[
          {
            label: "주문번호",
            key: "no",
          },
          {
            label: "요청일",
            key: "createdAt",
            type: "datetime",
          },
          {
            label: "고객",
            key: "userName",
          },
          {
            label: "고객번호",
            key: "userId",
          },
          {
            label: "핸드폰",
            key: "phone",
            type: "phone",
          },
          {
            label: "방문예약",
            key: "visitDate",
          },
          {
            label: "매장명",
            key: "shopName",
          },
          {
            label: "인출상태",
            key: "status",
            type: "orderstatus",
          },
          {
            label: "인출확정일",
            key: "confirmDate",
            type: "datetime",
          },
          {
            label: "출고상태",
            key: "shipmentStatus",
            type: "orderstatus",
          },
          {
            label: "출고일",
            key: "shipmentDate",
            type: "datetime",
          },
        ]}
        data={[rows]}
      />
      <Flex row style={{ justifyContent: "right" }}>
        <StatusRadioGroup
          status={status}
          shipmentStatus={shipmentStatus}
          setShipmentStatus={setShipmentStatus}
          shipmentStatusSave={shipmentStatusSave}
          statussave={statussave}
          setStatus={setStatus}
          histories={histories}
        />
        <Flex className={classes.buttons} row>
          <Button
            label="취소"
            className={classes.bt1}
            onClick={() => {
              history.replace({
                pathname: location.pathname,
                state: { ...location.state },
              });
              onLoad();
            }}
          />
          <AdminConfirmButton
            popupLabel="상태 변경"
            popupMessage="변경사항을 저장하시겠습니까?"
            label="저장"
            callback={onSaveAdmin}
            validationCheck={
              !isKorda && rows.shopId !== shopId
                ? "상태 변경은 출고매장에서만 변경할 수 있습니다."
                : ""
            }
            rootClassName={classes.confirmRoot}
            btnClassName={classes.confirmButton}
          />
        </Flex>
      </Flex>
      <Flex row className={classes.main}>
        <Flex className={classes.mainC1}>
          <Flex row className={classes.header}>
            <SmallTitle>회원 주문 정보</SmallTitle>
          </Flex>
          <Flex className={classes.mainC1Content}>
            <Grid container spacing={3} className={classes.mainC2ContentGrid}>
              <Grid item xs={3}>
                주문번호
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.no}
              </Grid>
              <Grid item xs={3}>
                제품번호
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.code}
              </Grid>
              <Grid item xs={3}>
                제품분류
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.categoryName}
              </Grid>
              <Grid item xs={3}>
                상품명
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.name}
              </Grid>
              {/* <Grid item xs={3}>
                매장명
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.shopName}
              </Grid> */}
              <Grid item xs={3}>
                이미지
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                <div className={classes.img}>
                  {product.imageIds.length > 0 && (
                    <img
                      src={
                        product.imageIds[0].path
                          ? consts.s3BaseUrl + "/" + product.imageIds[0].path
                          : ""
                      }
                      width="100"
                      alt="상품이미지"
                      style={{ cursor: "pointer" }}
                      onClick={() => setimgspopOpen(1)}
                    />
                  )}
                </div>
                {imgspopOpen === 1 && (
                  <div
                    className={classes.imgspop}
                    onClick={() => setimgspopOpen(0)}
                  >
                    <img
                      src={
                        product.imageIds[0].path
                          ? consts.s3BaseUrl + "/" + product.imageIds[0].path
                          : ""
                      }
                      width="60%"
                      alt="상품이미지"
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={3}>
                순도
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                24k(999)
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                중량
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                {rows.totalGram}g
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                수량
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                {rows.qty}개
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                <BsArrowReturnRight /> 추가인출수량
              </Grid>
              <Grid item xs={3}>
                <Flex row className={classes.numberInputWrap}>
                  <NumericTextBox
                    format="n0"
                    className={classes.numberInput}
                    value={rows?.additionalDeliveryQty || additionalDeliveryQty}
                    onChange={(e) => setAdditionalDeliveryQty(e.value)}
                    type="number"
                    min="0"
                  />
                  <AdminConfirmButton
                    popupLabel="추가인출수량 출고"
                    popupMessage="추가인출수량을 출고하시겠습니까?"
                    label="출고"
                    callback={handleClickSaveAddDelivery}
                    validationCheck={
                      rows?.additionalDeliveryQty === undefined ||
                      rows?.additionalDeliveryQty === null ||
                      rows?.additionalDeliveryQty === 0
                        ? ""
                        : "기존 추가인출수량이 0일 때만 출고할 수 있습니다"
                    }
                    rootClassName={classes.saveRoot}
                    btnClassName={classes.saveBtn}
                    selectItem={"DELIVERY"}
                  />
                  <AdminConfirmButton
                    popupLabel="추가인출수량 입고"
                    popupMessage="추가인출수량을 입고하시겠습니까?"
                    label="입고"
                    callback={handleClickSaveAddDelivery}
                    validationCheck={
                      rows?.additionalDeliveryQty === 0
                        ? "입고 가능한 추가인출수량이 없습니다."
                        : ""
                    }
                    rootClassName={classes.saveRoot}
                    btnClassName={classes.saveBtn}
                    selectItem={"STOCK"}
                  />
                </Flex>
              </Grid>
              <Grid item xs={3}>
                요청사항
              </Grid>
              <Grid item xs={9} style={{ display: "flex" }}>
                {rows.optionValue}
              </Grid>

              <Grid item xs={3} className={classes.stockWrap}>
                재고수량
              </Grid>
              <Grid item xs={9} className={classes.stockWrap}>
                <span>
                  <Link
                    to={{
                      pathname: routes.inventoryOrder,
                      state: { goodsName: rows.name },
                    }}
                  >
                    {numberWithCommas(rows.stock)}
                  </Link>
                </span>
              </Grid>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
      <DeliveryDetailTable deliveryInfo={deliveryInfo} onLoad={onLoad} />
      {/* {Object.keys(deliveryInfo).length !== 0 && (
        <Flex
          style={{
            margin: "10px 50px 0px 50px",
          }}
        >
          <Flex
            row
            className={classes.header}
            style={{ border: "1px solid #e2e5e8", borderBottom: "none" }}
          >
            <SmallTitle style={{ marginRight: 30 }}>택배 배송정보</SmallTitle>
            <KendoButton
              label="배송조회"
              onClick={() => {
                dispatch(
                  openModal({
                    visiable: true,
                    children: <DeliveryHistoryModal id={state.id} />,
                  })
                );
              }}
            />
          </Flex>
          <StyledTable>
            <Container>
              <Flex row>
                <Flex className="header">{"받는사람"}</Flex>
                <Flex className="data">{deliveryInfo.receiver}</Flex>
                <Flex className="header">{"연락처"}</Flex>
                <Flex className="data">{deliveryInfo.phoneMobile}</Flex>
              </Flex>
              <Flex row>
                <Flex className="header">{"받는주소"}</Flex>
                <Flex className="data">{deliveryInfo.totalAddress}</Flex>
                <Flex className="header">{"배송요청사항"}</Flex>
                <Flex className="data">{deliveryInfo.requestMemo || "-"}</Flex>
              </Flex>
              <Flex row>
                <Flex className="header">{"배송업체"}</Flex>
                <Flex className="data">{deliveryInfo.deliveryCorp ?? "-"}</Flex>
                <Flex className="header">{"송장번호"}</Flex>
                <Flex className="data">
                  {deliveryInfo.regiNo ? <a href={``}></a> : "-"}
                </Flex>
              </Flex>
            </Container>
          </StyledTable>
        </Flex>
      )} */}
      <Flex>
        <SmallTitle style={{ margin: "10px 50px 0px 50px" }}>
          실물인출
        </SmallTitle>
        <Flex>
          <SimpleTable
            fullWidth
            columns={[
              {
                label: "일시",
                key: "createdAt",
                type: "datetime",
              },
              {
                label: "상태",
                key: "status",
              },
              {
                label: "관리자",
                key: "managerName",
              },
              {
                label: "사용자",
                key: "userName",
              },
              {
                label: "비고",
                key: "memo",
              },
            ].filter((x) => x)}
            data={historyList}
          />
        </Flex>
      </Flex>
    </>
  );
}

const DeliveryHistoryModal = ({ id }) => {
  const dispatch = useDispatch();
  const [traceList, setTraceList] = useState([]);
  const [deliveryData, setDeliveryData] = useState({});
  useEffect(() => {
    dispatch(loadingStart);
    APIS.getDeliveryHistory(id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          if (data?.mailType && data?.mailKind) {
            data["mailTypeKind"] = data["mailType"] + " / " + data["mailKind"];
          }
          setDeliveryData(data);
          setTraceList(
            data?.traceList?.map((item) => {
              return {
                ...item,
                createdAt: formatTime(item.createdAt, "MM.DD HH:mm"),
              };
            })
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  }, []);
  return (
    <Flex style={{ width: 700 }}>
      <SmallTitle>배송조회</SmallTitle>
      <StyledTable>
        <Container>
          <Flex row>
            <Flex className="header">{"판매자"}</Flex>
            <Flex className="data">{deliveryData?.sender}</Flex>
            <Flex className="header">{"받는사람"}</Flex>
            <Flex className="data">{deliveryData?.receiver}</Flex>
          </Flex>
          <Flex row>
            <Flex className="header">{"송장번호"}</Flex>
            <Flex className="data">{deliveryData?.regiNo}</Flex>
            <Flex className="header">{"배송타입/종류"}</Flex>
            <Flex className="data">{deliveryData?.mailTypeKind}</Flex>
          </Flex>
        </Container>
      </StyledTable>
      <GridTable data={traceList} style={{ height: 350 }} scrollable>
        <GridColumn title="시간" field="createdAt" width={150} />
        <GridColumn title="현재위치" field="currentPosition" />
        <GridColumn title="배송상태" field="status" width={150} />
      </GridTable>
    </Flex>
  );
};

const useStyle = makeStyles({
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  multiInput: {
    border: "1px  solid #f2f2f2",
    minHeight: 200,
    minWidth: "70%",
    marginTop: 20,
  },
  img: {
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },

  main: {
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: "3%",
    // marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    flex: 4,
  },
  mainC2: { flex: 3 },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",

    padding: "5px 10px",
  },
  inputWrap: {
    border: "1px solid #ddd",
    minHeight: 200,
    flex: 1,
    width: "100%",
    alignSelf: "stretch",
    alignItems: "center",
  },
  imgspop: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.7)",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: "1000",
    overflow: "auto",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 46,
    width: 40,
    marginLeft: 0,
  },
  stockWrap: {
    borderTop: "1px solid rgba(0,0,0,0.23)",
    minHeight: 50,
  },
  numberInputWrap: {
    alignItems: "center",
  },
  numberInput: {
    width: 100,
    height: 32,
  },
  saveRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
  },
  saveBtn: {
    minWidth: 70,
    height: 32,
    marginLeft: 8,
  },
});
