import React, { useCallback, useEffect, useState } from "react";
import GridTable, {
  CenterCell,
  CommandCell,
  NumberCell,
  NumericCell,
} from "components/gird-table/GridTable";
import { GridColumn } from "@progress/kendo-react-grid";
import * as APIS from "../../../libs/apis";
import { actionError } from "redux/action/ActionActions";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

const gridName = "settingFee";
const editField = "inEdit";

const TradeFeeTable = ({ assetType, feeData }) => {
  const dispatch = useDispatch();
  const [editID, setEditID] = useState(null);
  const [feeTable, setFeeTable] = useState([]);
  const [copyData, setCopyData] = useState([]);

  useEffect(() => {
    if (feeData) {
      setFeeTable(feeData);
      setCopyData(feeData);
    }
  }, [feeData]);

  const itemChange = useCallback(
    (event) => {
      const inEditID = event.dataItem.id;
      const field = event.field || "";
      const newData = [...feeTable].map((item) => {
        return item.id === inEditID
          ? {
              ...item,
              [field]: event.value,
            }
          : item;
      }, []);
      setFeeTable(newData);
    },
    [feeTable]
  );

  const rowClick = (event) => {
    setEditID(event.dataItem.id);
  };

  const onSave = (password) => {
    dispatch(loadingStart);
    APIS.postTradeFees({
      managerPassword: password,
      buySellPolicyDtoList: feeTable,
    })
      .then((res) => {
        const { success, message } = res.data;
        if (success) {
          setCopyData(feeTable);
          setEditID(null);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };
  return (
    <GridTable
      name={`${gridName}_${assetType}`}
      editField={editField}
      data={
        feeTable.length !== 0
          ? feeTable
              .filter((item) => item?.assetType.includes(assetType))
              .map((item) => {
                return {
                  ...item,
                  inEdit: item.id === editID,
                };
              })
          : []
      }
      style={{
        marginTop: "10px",
      }}
      onItemChange={itemChange}>
      <GridColumn
        title="구분"
        field="tradeType"
        editable={false}
        cell={(props) => {
          return (
            <CenterCell {...props}>
              {props.dataItem.tradeType === "BUY" ? "매수(하한)" : "매도(상한)"}
            </CenterCell>
          );
        }}
      />
      <GridColumn
        className="td-c"
        title="회원"
        field="userFeeRate"
        editor="numeric"
        width={150}
        cell={(props) => {
          let dataItem = props.dataItem;
          return <NumericCell {...props} isEdit={dataItem.inEdit} />;
        }}
      />
      <GridColumn title="대리점">
        <GridColumn
          className="td-c"
          title="추천(%)"
          field="recommendShopFeeRate"
          editor="numeric"
          width={150}
          cell={(props) => {
            let dataItem = props.dataItem;
            return <NumericCell {...props} isEdit={dataItem.inEdit} />;
          }}
        />
        <GridColumn
          className="td-c"
          title="이용(%)"
          field="usingShopFeeRate"
          editor="numeric"
          width={150}
          cell={(props) => {
            let dataItem = props.dataItem;
            return <NumericCell {...props} isEdit={dataItem.inEdit} />;
          }}
        />
        <GridColumn
          className="td-c"
          title="감정(%)"
          field="appraisalShopFeeRate"
          editor="numeric"
          width={150}
          cell={(props) => {
            const { dataItem } = props;
            let isEdit = dataItem?.tradeType !== "BUY";
            let renderString =
              dataItem?.tradeType === "BUY"
                ? "-"
                : dataItem?.appraisalShopFeeRate;
            return (
              <NumericCell
                {...props}
                isEdit={isEdit}
                dataValue={renderString}
              />
            );
          }}
        />
        <GridColumn
          title="합계(%)"
          field="feeSum"
          editable={false}
          cell={(props) => {
            let dataItem = props.dataItem;
            return (
              <CenterCell {...props}>{dataItem.feeSum.toFixed(1)}</CenterCell>
            );
          }}
        />
      </GridColumn>
      <GridColumn
        title="본사(%)"
        editable={false}
        field="headOffice"
        cell={(props) => {
          let dataItem = props.dataItem;
          return (
            <CenterCell {...props}>{dataItem.headOffice.toFixed(1)}</CenterCell>
          );
        }}
      />
      <GridColumn title="상/하한가">
        <GridColumn
          className="td-c"
          title="변동율(%)"
          field="priceLimitRate"
          editor="numeric"
          width={150}
          // cell={(props) => {
          //   return <CenterCell {...props} unit=" %" />;
          // }}
        />
        <GridColumn
          title="현재 적용가(원)"
          field="priceLimit"
          editable={false}
          cell={(props) => {
            return <NumberCell {...props} className="td-c" unit=" 원" />;
          }}
        />
      </GridColumn>
      <GridColumn
        className="td-c"
        title="대기 중량 임계치(g)"
        field="sumRequestLowLimit"
        width={150}
        cell={(props) => {
          return <NumberCell {...props} unit=" g" />;
        }}
      />
      <GridColumn
        cell={(props) => {
          return (
            <CommandCell
              {...props}
              okText="확인"
              cancelText="취소"
              updateText="수정"
              passwordPopupTitle="비밀번호 입력"
              enterEdit={() => {
                rowClick(props);
              }}
              cancel={() => {
                if (copyData.length !== 0) {
                  setFeeTable(copyData);
                }
                setEditID(null);
              }}
              save={(password) => {
                onSave(password);
              }}
            />
          );
        }}
        width="150px"
      />
    </GridTable>
  );
};

export default React.memo(TradeFeeTable);
