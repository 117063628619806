import { makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Input from "../../components/input/Input";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { userSignIn, userUpdate } from "../../redux/user/UserActions";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { signIn } from "../../redux/auth/AuthReducer";
import { useSelector } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import action from "../../redux/action/ActionReducer";
import PageLabel from "../../components/page-label/PageLabel";
import GridRow from "../../components/grid-row/GridRow";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
const MAP_COLUMN_TO_LABEL = {
  group: "그룹",
  shop: "매장",
  account: "계정 ID",
  name: "이름",
  phone: "휴대전화",
  recentPassword: "현재 비밀번호",
  newPassword: "새 비밀번호",
  password: "새 비밀번호 확인",
};

export default function MyInformation(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [recentPassword, setRecentPassword] = useState("");
  const history = useHistory();
  const [manager, setManager] = useState({});
  const [authGroupList, setAuthGroupList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [pwdCheckSwitch, setpwdCheckSwitch] = useState(true);
  const [passwordAfterCheck, setPasswordAfterCheck] = useState(true);
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  const id = localStorage.getItem("id");
  const handleChangeValue = (key, value) => {
    setManager((manager) => ({ ...manager, [key]: value }));
  };

  const onChangeValue = ({ target }) => {
    const { name, value } = target;
    setPassword(value);
    if (name === "password") {
      if (newPassword !== value) {
        setpwdCheckSwitch(false);
      } else {
        setpwdCheckSwitch(true);
        setPassword(value);
      }
    }
  };

  const getColumnData = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_LABEL[key],
      value: manager[key],
      ...option,
    };
  };

  const getInputUI = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_LABEL[key],
      value: manager?.[key] || "",
      type: "input",
      onChange: (e) => handleChangeValue(key, e),
      ...option,
    };
  };

  const getAdminDetail = () => {
    dispatch(loadingStart);

    APIS.getAdminInfo()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setManager(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const authorityGroupList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityGroupList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setAuthGroupList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const getShopList = () => {
    dispatch(loadingStart);
    APIS.getShopList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleEditAdmin = (adminPassword) => {
    dispatch(loadingStart);
    const data = {
      id: id,
      name: manager.name,
      phone: manager.phone,
      groupId: manager.groupId,
      shopId: manager.shopId,
      managerPassword: adminPassword,
      status: "ACTIVE",
    };
    APIS.putEditMyInfo(data)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "내 정보가 수정되었습니다.",
              () => {
                setManager(data);
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };
  const handleRecentPasswordCheck = () => {
    if (!recentPassword) {
      dispatch(actionError("현재 비밀번호를 입력해 주세요."));
    } else {
      dispatch(loadingStart);
      APIS.postRecentPasswordCheck({ password: recentPassword })
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              actionOpen("비밀번호가 확인되었습니다.", null, null, null, true)
            );
            setPasswordAfterCheck(false);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch(() => {
          dispatch(actionError("비밀번호 확인에 실패하셨습니다."));
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleModifyPassword = () => {
    if (!recentPassword) {
      dispatch(actionError("현재 비밀번호를 입력해 주세요."));
    } else if (!password) {
      dispatch(actionError("새 비밀번호를 입력해 주세요."));
    } else if (pwdCheckSwitch === false) {
      dispatch(
        actionError("새 비밀번호와 비밀번호 확인이 \n 일치하지 않습니다.")
      );
    } else {
      dispatch(loadingStart);
      APIS.putModifyMyPassword({
        managerPassword: recentPassword,
        password: password,
      })
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "비밀번호가 변경되었습니다.",
                () => {
                  init();
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .catch(() => {
          dispatch(actionError("비밀번호 변경에 실패하셨습니다."));
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };
  useEffect(() => {
    getAdminDetail();
    getShopList();
    authorityGroupList();
  }, []);

  const init = () => {
    setPassword("");
    setNewPassword("");
    setRecentPassword("");
  };
  return (
    <>
      <PageLabel>내 정보</PageLabel>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center"></Flex>
          <Flex row>
            <AdminConfirmButton
              popupLabel="내 정보 변경"
              popupMessage="변경하시겠습니까?"
              label="저장"
              callback={handleEditAdmin}
              style={{
                width: "100px",
                height: "40px",
                color: "white",
                marginRight: "45px",
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      <GridRow
        // label="기본정보"
        data={[
          {
            label: "그룹",
            type: "render",
            render: (
              <Flex row>
                <select
                  style={{ minWidth: 50, fontSize: 16 }}
                  value={manager.groupId}
                  disabled
                >
                  <option value="" key="total">
                    그룹을 선택하세요.
                  </option>
                  {authGroupList?.map((item, index) => {
                    return (
                      <option key={index} value={+item.id}>
                        {item.groupName}
                      </option>
                    );
                  })}
                </select>
              </Flex>
            ),
            fullWidth: true,
          },
          {
            label: "매장",
            type: "render",
            render: (
              <Flex row>
                <select
                  style={{ minWidth: 50, fontSize: 16 }}
                  value={manager.shopId}
                  disabled
                >
                  <option value="" key="total">
                    매장을 선택하세요.
                  </option>
                  {shopList?.map((item, index) => {
                    return (
                      <option key={index} value={+item.shopId}>
                        {item.shopName}
                      </option>
                    );
                  })}
                </select>
              </Flex>
            ),
            fullWidth: true,
          },
          getColumnData("account", { fullWidth: true }),
          getInputUI("name", { fullWidth: true }),
          getInputUI("phone", { fullWidth: true }),
          {
            label: "현재 비밀번호",
            fullWidth: true,
            type: "render",
            render: (
              <Flex row>
                <input
                  style={{ marginRight: 20, width: 192 }}
                  className={classes.modalInput}
                  type="password"
                  value={recentPassword}
                  autoComplete="new-password"
                  onChange={(e) => {
                    setRecentPassword(e.target.value);
                  }}
                />
              </Flex>
            ),
          },
          {
            label: "새 비밀번호",
            fullWidth: true,
            type: "render",
            render: (
              <input
                style={{ marginRight: 20, width: 192 }}
                value={newPassword}
                className={classes.modalInput}
                type="password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            ),
          },
          {
            label: "새 비밀번호 확인",
            fullWidth: true,
            type: "render",
            render: (
              <div>
                <Flex row>
                  <input
                    style={{ marginRight: 20, width: 192 }}
                    className={
                      pwdCheckSwitch
                        ? classes.modalInput
                        : classes.modalInputWarning
                    }
                    value={password}
                    type="password"
                    name="password"
                    onChange={(e) => {
                      onChangeValue(e);
                    }}
                  />
                  <Button
                    label="비밀번호 변경"
                    style={{ height: "35px" }}
                    // disabled={passwordAfterCheck}
                    onClick={handleModifyPassword}
                  />
                </Flex>
                {pwdCheckSwitch ? null : (
                  <div className={classes.validationError}>
                    비밀번호가 일치하지 않습니다. 다시 확인해주세요.
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
    margin: "10px 50px",
  },
  label: {
    color: colors.black,
    fontSize: "24px",
    marginTop: 10,
    textAlign: "center",
  },
  form: {
    margin: "0px 30px",
    marginTop: "50px",
    alignItems: "center",

    alignSelf: "stretch",
  },
  input: {
    marginTop: "10px",
    border: "none",
    backgroundColor: "#f2f2f2",
  },
  formInput: { flex: 1 },
  button: {
    marginBottom: 50,
    marginTop: "30px",
    alignSelf: "stretch",
    backgroundColor: colors.primary,
    margin: "0px 30px",
  },
  banner: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  main: {
    backgroundColor: "#fff",
    padding: "60px 50px",
    boxSizing: "border-box",
    zIndex: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 600,
  },
  logo: {
    width: 240,
    height: 40,
    objectFit: "contain",
  },
  modalInput: {
    width: 200,
    height: 35,
    float: "left",
    border: "1px solid #dddddd",
  },
  modalInputWarning: {
    width: 400,
    height: 35,
    display: "inline-block",
    color: "#e52528",
    border: "2px solid #e52528",
    transition: "all 0.5s",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  button: {
    border: "5px solid white",
    borderRadius: "7px",
    width: "150px",
  },
  btnDetail: {
    border: "1px solid rgba(0,0,0,0.23)",
    width: "80%",
    height: "30px",
  },
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  //   modal: {
  //     textAlign: "center",
  //     fontWeight: "bold",
  //     width: "100%",
  //     height: "50px",
  //     background: " rgb(180,180,180)",
  //     position: "absolute",
  //     right: "0px",
  //     top: "0px",
  //     borderRadius: "10px 10px 0px 0px",
  //     lineHeight: "50px",
  //   },
  content: {
    marginTop: "30px",
    height: "150px",
  },
  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    marginLeft: "5%",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  modalInput: {
    width: 400,
    height: 35,
    float: "left",
    border: "1px solid #dddddd",
  },
  //   modalInputWarning: {
  //     width: 400,
  //     height: 35,
  //     display: "inline-block",
  //     color: "#e52528",
  //     border: "2px solid #e52528",
  //     transition: "all 0.5s",
  //   },

  modalSelect: {
    width: 400,
    float: "left",
  },
  checkRedundancy: {
    border: "1px solid white",
    borderRadius: "7px",
    width: "100px",
    height: "36px",
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  validationError: {
    color: "#e52528",
    justifySelf: "left",
  },
});
