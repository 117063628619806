import { FormControl, MenuItem, Select as MuiSelect } from "@material-ui/core";
import { FONT_SIZE } from "../../libs/consts";

export default function Select({ options = [], value, onChange, style }) {
  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        style={{ background: "white", minWidth: 100, ...style }}
      >
        <MuiSelect
          value={value}
          onChange={onChange}
          style={{ fontSize: FONT_SIZE.BASIC }}
          displayEmpty
        >
          {options.map(({ value, label }) => (
            <MenuItem value={value} style={{ fontSize: FONT_SIZE.BASIC }}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </>
  );
}
