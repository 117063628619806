import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import SearchLayoutV2 from "layouts/search/SearchLayoutV2";

import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { INVENTORY_APPRISAL_FEE } from "../appraisalFeeConst";

const startDateInputSettings = {
  label: "",
};

const endDateInputSettings = {
  label: "",
};

const InventoryApprisalForm = ({ initalValues, handleSubmit }) => {
  return (
    <Form
      initialValues={initalValues}
      key={JSON.stringify(initalValues)}
      onSubmitClick={handleSubmit}
      render={(formRenderProps) => {
        return (
          <FormElement>
            <SearchLayoutV2.Table>
              <colgroup>
                <col width="10%" />
                <col width="40%" />
                <col width="10%" />
                <col width="40%" />
              </colgroup>
              <tbody>
                <tr>
                  <SearchLayoutV2.TableField
                    label="조회기간"
                    id="dateRange"
                    name="dateRange"
                    format="yyyy-MM-dd"
                    startDateInputSettings={startDateInputSettings}
                    endDateInputSettings={endDateInputSettings}
                    component={DateRangePicker}
                    prefix={
                      <Field
                        id="dateType"
                        name="dateType"
                        data={INVENTORY_APPRISAL_FEE.DROP_DOWN.DATE_TYPE}
                        textField="label"
                        dataItemKey="value"
                        style={{ width: "200px" }}
                        component={DropDownList}
                      />
                    }
                  />
                </tr>
                <tr>
                  <SearchLayoutV2.TableField
                    label="자산유형"
                    name="assetType"
                    data={INVENTORY_APPRISAL_FEE.MUTLISELECT.ASSET_TYPE}
                    textField="label"
                    dataItemKey="value"
                    component={MultiSelect}
                  />
                  <SearchLayoutV2.TableField
                    label="거래구분"
                    name="disposeType"
                    tdColSpan={3}
                    data={INVENTORY_APPRISAL_FEE.MUTLISELECT.DISPOSE_TYPE}
                    textField="label"
                    dataItemKey="value"
                    component={MultiSelect}
                  />
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <SearchLayoutV2.InitBtn
                        type="reset"
                        onClick={formRenderProps.onFormReset}
                      >
                        초기화
                      </SearchLayoutV2.InitBtn>
                      <SearchLayoutV2.SearchBtn type="submit">
                        검색
                      </SearchLayoutV2.SearchBtn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </SearchLayoutV2.Table>
          </FormElement>
        );
      }}
    />
  );
};

export default InventoryApprisalForm;
