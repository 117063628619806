import { Button, makeStyles } from "@material-ui/core";
import React, { useRef } from "react";

const InputFile = ({ label, accept, multiple = false, callback }) => {
  const classes = useStyle();
  const inputRef = useRef(null);

  const handleChangeFile = (e) => {
    if (e.target.files.length) {
      multiple && callback && callback(e.target.files);
      !multiple && callback && callback(e.target.files[0]);
    }
  };

  return (
    <>
      <input
        type="file"
        style={{ width: 0, height: 0 }}
        ref={inputRef}
        onChange={handleChangeFile}
        accept={accept}
        multiple={multiple}
      />
      <Button
        onClick={() => {
          inputRef.current.click();
        }}
        className={classes.btnDel}
      >
        {label}
      </Button>
    </>
  );
};

const useStyle = makeStyles({
  btnDel: {
    border: "1px solid rgba(0,0,0,0.23)",
    width: 150,
  },
});
export default InputFile;
