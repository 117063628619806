import { IconButton, makeStyles } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Flex from "../../components/flex/Flex";
import Drawer from "./Drawer";
//import Topbar from "./Topbar";
import { useHistory } from "react-router";
import routes from "../../libs/routes";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import { getAssociateShops } from "redux/shop/AssociateShopReducer";

const DRAWER_DISABLED = [
  routes.directStatus,
  routes.directStatusDetail,
  routes.tradingAsset,
  routes.appraisalStatus,
  routes.appraisalStatusDetail,
  routes.physicalOrder,
  routes.physicalOrderDetail,
  routes.memberNormal,
  routes.memberDetail,
  routes.memberDetailId,
  routes.shopInfo,
  routes.settlementShopFee,
  routes.myInfo,
];
export default function MainLayout({ children }) {
  const classes = useStyle();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const auth = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  const { isKorda } = auth;
  let drawerDisabled = false;
  DRAWER_DISABLED.forEach((path) => {
    if (history.location.pathname.startsWith(path)) {
      if (
        (isKorda && path === "/direct/status") ||
        (isKorda && path === "/trading/assets") ||
        (isKorda && path === "/appraisal/status") ||
        (isKorda && path === "/appraisal/status/detail") ||
        (isKorda && path === "/physical/order") ||
        (isKorda && path === "/physical/order/detail") ||
        (isKorda && path === "/member/normal") ||
        (isKorda && path === "/member/normal/detail") ||
        (isKorda && path === "/shop/info") ||
        (isKorda && path === "/settlement/shopfee") ||
        (isKorda && path === "/manager/info")
      ) {
        drawerDisabled = false;
      } else {
        drawerDisabled = true;
      }
    }
  });

  useEffect(() => {
    dispatch(getAssociateShops());
  }, []);

  return (
    <Flex className={classes.root}>
      <Header />
      {!drawerDisabled && (
        <ExpandedButton expanded={expanded} setExpanded={setExpanded} />
      )}
      <Flex className={classes.main} row>
        {!drawerDisabled && <Drawer expanded={expanded} />}
        <Flex className={classes.children}>{children}</Flex>
      </Flex>
    </Flex>
  );
}

const ExpandedButton = ({ expanded, setExpanded }) => {
  const classes = useStyle();
  return (
    <IconButton
      className={`${classes.drawerButton} ${
        expanded && classes.drawerButtonExpanded
      }`}
      onClick={() => {
        setExpanded(!expanded);
      }}>
      {expanded ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
};

const useStyle = makeStyles({
  root: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
  },
  main: { flex: 1 },
  children: { flex: 1, overflow: "auto" },
  drawerButton: {
    width: 40,
    height: 40,
    marginLeft: 180,
    marginTop: "100px",
    borderRadius: 15,
    background: "#fff",
    boxShadow: "1px 1px 1px 1px lightGray",
    position: "absolute",
    transition: "all 0.3s;",
    zIndex: 40,
    "&:hover": {
      background: "#efefef",
    },
  },
  drawerButtonExpanded: {
    transform: "rotate(360deg)",
    transition: "all 0.3s;",
    marginLeft: 48,
  },
  icon: {
    width: "100%",
    height: "100%",
    transition: "transform 0.3s ease-in-out",
  },
  expandedIcon: {
    transform: "rotate(90deg)",
  },
});
