import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen } from "../../redux/action/ActionActions";
import Filters from "../../components/filters/Filters";
import { getDefaultPageSize } from "../../libs/consts";
import Flex from "../../components/flex/Flex";
import {
  makeStyles,
  Popover,
  Divider,
  InputBase,
  TableCell,
} from "@material-ui/core";
import fonts from "../../libs/fonts";
import { formatTime } from "../../libs/utils";
import { actionError } from "../../redux/action/ActionActions";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import { ArrowRightAlt, Lock } from "@material-ui/icons";

export default function ServiceDirect({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const [summary, setSummary] = useState([]);
  //   const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [qnaList, setQnaList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [qnaDetail, setQnaDetail] = useState({});
  const [qnaAnswer, setQnaAnswer] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [rowId, setRowId] = useState("");

  const handleClickDetail = (event, qnaId) => {
    const currentTarget = event.currentTarget;
    // 상품 문의 상세
    APIS.getDirectProductInquiryDetail(qnaId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAnchorEl(currentTarget);
          setQnaDetail(data);
          setQnaAnswer(data?.answer);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const fetchList = () => {
    const data = {
      ...state,
      page: state.page,
      size: state.size,
      isAnswered: state?.isAnswered ? state?.isAnswered : "",
      startDate: state.startDate ? state.startDate : "",
      endDate: state.endDate ? state.endDate : "",
      dateType: state.dateType ? state.dateType : "",
    };

    //상품 문의 조회
    dispatch(loadingStart);
    APIS.getDirectProductInquiry(objToQueryStr(data))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setQnaList(data?.content);
          // setQnaList([{ question: "문의 내용" }]);
          setTotalCount(data.totalElements);
          // setTotalCount(1);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickInquiryAnswer = (qnaId) => {
    const data = {
      answer: qnaAnswer,
    };
    //상품 문의 답변
    dispatch(loadingStart);
    APIS.postDirectProductInquiryAnswer(qnaId, data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          fetchList();
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => {
        dispatch(loadingEnd);
        handleClose();
      });
  };

  useEffect(() => {
    // 상품문의 summary 조회
    dispatch(loadingStart);
    APIS.getDirectProductInquirySummary()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));

    //카테고리 목록 조회
    dispatch(loadingStart);
    APIS.getDirectCategoryList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const newData = [{ categoryId: "", name: "전체", isDelete: false }];
          newData.push(...data);
          setCategoryList(newData);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size, status]);

  return (
    <Flex>
      <PageLabel>안심직거래</PageLabel>
      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          //   total: 0,
          // startDate: formatTime(
          //   new Date(
          //     new Date().getFullYear(),
          //     new Date().getMonth(),
          //     new Date().getDate() - 7
          //   ),
          //   "YYYY-MM-DD"
          // ),
          // endDate: formatTime(),
          dateType: "CREATED",
          user: "",
          categoryId: "",
          productName: "",
          unanswered: "",
        }}
        onSearch={fetchList}
        data={[
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}
                >
                  <option value="CREATED">{"요청일"}</option>
                  <option value="UPDATED">{"완료일"}</option>
                </select>
                <input
                  value={state?.startDate || ""}
                  onChange={(e) => {
                    handleValueChange("startDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            label: "문의자",
            placeholder: "고객명, 연락처, 고객번호",
            type: "input",
            renderWidth: "50%",
            key: "user",
            autoFocus: true,
          },
          {
            label: "카테고리",
            type: "render",
            // data: category,
            // key: "categoryId",
            renderWidth: "30%",
            render: (
              <Flex>
                <select
                  value={state?.categoryId}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("categoryId", e.target.value);
                  }}
                >
                  {categoryList?.map((item) => {
                    return <option value={item.categoryId}>{item.name}</option>;
                  })}
                </select>
              </Flex>
            ),
          },
          {
            label: "상품검색",
            placeholder: "상품명",
            type: "input",
            renderWidth: "40%",
            key: "productName",
          },
          {
            label: "처리상태",
            type: "menu",
            data: [
              { label: "미답변", value: "false" },
              { label: "답변완료", value: "true" },
            ],
            key: "isAnswered",
            renderWidth: "30%",
          },
        ]}
      />
      <Flex row className={classes.statusCount}>
        <span
          className={`${
            status === "isAnswered"
              ? classes.linkStatusSelected
              : classes.linkStatus
          }`}
        >
          미답변 :
          <span
            onClick={() => {
              handleValueChange("isAnswered", "false");
              setStatus("isAnswered");
            }}
          >
            {summary?.questionWaitCount}
          </span>
        </span>
        <span
          style={{ marginLeft: 10 }}
          className={`${
            status === "todayInquiry"
              ? classes.linkStatusSelected
              : classes.linkStatus
          }`}
        >
          오늘 문의 :
          <span
            onClick={() => {
              history.replace({
                pathname: location.pathname,
                state: {
                  ...location.state,
                  startDate: formatTime(),
                  endDate: formatTime(),
                },
              });
              setStatus("todayInquiry");
            }}
          >
            {summary?.todayCount}
          </span>
        </span>
      </Flex>
      <DataTable
        data={qnaList}
        totalCount={totalCount}
        clicktype="checkbox"
        noCursor={classes.noCursor}
        columns={[
          {
            label: "문의일시",
            key: "createdAt",
            type: "datetime",
          },
          {
            label: "문의 고객명",
            key: "userName",
            render: (row) => {
              return (
                <a
                  onClick={() => {
                    history.push(`${routes.memberDetail}/${row.userId}`);
                  }}
                  className={classes.underline}
                >
                  {row.userName}
                </a>
              );
            },
          },
          {
            label: "고객번호",
            key: "userId",
          },
          {
            label: "추천매장",
            key: "recommendShop",
          },
          {
            label: "문의내용",
            key: "question",
            render: (row) => {
              return (
                <Flex style={{ cursor: "pointer" }}>
                  <span
                    onClick={(event) => {
                      setQnaAnswer("");
                      setRowId(row?.qnaId);
                      handleClickDetail(event, row?.qnaId);
                    }}
                  >
                    <Flex style={{ textAlignLast: "start" }}>
                      {row?.isSecret === true ? (
                        <Flex row style={{ alignItems: "center" }}>
                          <Flex>
                            <Lock style={{ width: "1.2rem" }} />
                          </Flex>
                          <Text>
                            {row?.question.length > 40
                              ? row.question.substr(0, 40) + "..."
                              : row.question}
                          </Text>
                        </Flex>
                      ) : (
                        row?.question
                      )}
                    </Flex>
                    {row.answer ? (
                      <Flex row>
                        <Flex
                          row
                          style={{
                            marginRight: 5,
                            color: "blue",
                            fontWeight: "bold",
                            minWidth: 61,
                            marginBottom: "auto",
                          }}
                        >
                          <ArrowRightAlt fontColor="blue" />
                          [답변]
                        </Flex>
                        <Flex style={{ maxWidth: 370, textAlignLast: "start" }}>
                          {row?.answer.split("").map((x, i) => {
                            if (x === "\n") {
                              return <br key={i.toString()} />;
                            } else {
                              return x;
                            }
                          }) && row?.answer.length > 25
                            ? row.answer.substr(0, 25) + "..."
                            : row?.answer}
                        </Flex>
                      </Flex>
                    ) : (
                      ""
                    )}
                  </span>
                </Flex>
              );
            },
          },
          {
            label: "카테고리",
            key: "categoryName",
          },
          {
            label: "상품명",
            key: "productName",
            render: (row) => {
              return (
                <a
                  onClick={() => {
                    const itemId = row.itemId;
                    history.push(`${routes.directStatusDetail}/${itemId}`, {
                      itemId,
                    });
                  }}
                  className={classes.underline}
                >
                  {row.productName}
                </a>
              );
            },
          },
          // {
          //   label: "비밀글",
          //   key: "isSecret",
          //   type: "render",
          //   render: (row) => {
          //     return <Flex>{row.isSecret === true ? "YES" : "NO"}</Flex>;
          //   },
          // },
          // {
          //   label: "처리상태",
          //   type: "render",
          //   render: (row) => {
          //     return <Flex>{row.answeredAt ? "답변완료" : "미완료"}</Flex>;
          //   },
          // },
          {
            label: "답변일시",
            key: "answeredAt",
            type: "datetime",
          },
          {
            label: "답변자",
            key: "managerName",
          },
        ]}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Flex className={classes.popopHeader}>
          <Flex row style={{ justifyContent: "space-between" }}>
            <Text font={fonts.notoSansKRBold}>
              {formatTime(qnaDetail?.createdAt)}
            </Text>
            <Flex row>
              <Text>고객명 :</Text>
              <a
                onClick={() => {
                  history.push(`${routes.memberDetail}/${qnaDetail?.userId}`);
                }}
                className={classes.underline}
              >
                <Text font={fonts.notoSansKRBold}>{qnaDetail?.userName}</Text>
              </a>
            </Flex>
            <Flex row>
              <Text>추천매장 :</Text>
              <Text font={fonts.notoSansKRBold}>
                {qnaDetail?.recommendShop}
              </Text>
            </Flex>
          </Flex>
          <Divider className={classes.divider} />
          <Flex className={classes.main}>
            <Flex row>
              <Flex>
                {qnaDetail?.isSecret === true ? (
                  <Flex row style={{ alignItems: "center" }}>
                    <Lock style={{ width: "1.2rem" }} />
                    <Flex>문의내용 :</Flex>
                  </Flex>
                ) : (
                  "문의내용 :"
                )}
              </Flex>
              <Flex
                style={{
                  maxWidth: 300,
                  marginLeft: 5,
                  alignSelf: "center",
                }}
              >
                {qnaDetail?.question}
              </Flex>
            </Flex>
            <Divider className={classes.divider} />
            <InputBase
              value={qnaAnswer}
              multiline
              disabled={qnaDetail?.answer ? true : false}
              style={{
                flex: 1,
                minHeight: 100,
                border: "1px solid black",
                alignItems: "flex-start",
              }}
              onChange={(e) => {
                setQnaAnswer(e.target.value);
              }}
            />
            <Flex row style={{ justifyContent: "center" }}>
              <Button
                className={classes.cancelButton}
                label="닫기"
                onClick={() => {
                  handleClose();
                }}
              />
              {qnaDetail?.answer ? (
                ""
              ) : (
                <Button
                  className={classes.answerButton}
                  label="답변완료"
                  onClick={() => {
                    handleClickInquiryAnswer(rowId);
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Popover>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 10,
    fontFamily: fonts.notoSansKRBold,
    // marginBottom: 10,
  },

  table: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  selectBox: {
    minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "176px",
  },
  popopHeader: {
    position: "relative",
    minWidth: "450px",
    maxWidth: "500px",
    padding: "15px",
    //justifyContent: "center",
    // "& span": {
    //   color: "#fff",
    //   fontSize: 16,
    // },
    // backgroundColor: "rgb(180,180,180)",
  },
  main: {
    backgroundColor: "#fff",
    // border: "1px solid rgba(0,0,0,0.23)",
    // alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    // minHeight: 150,
  },
  cancelButton: {
    padding: 10,
    minWidth: "30%",
  },
  divider: {
    width: "100%",
    margin: "5px 0px 5px 0px",
  },
  answerButton: {
    padding: 10,
    minWidth: "30%",
    color: "black",
    backgroundColor: "grey",
  },
  statusCount: {
    flexDirection: "row",
    marginLeft: "50px",
  },
  linkStatus: {
    "& span": {
      color: "blue",
    },
    cursor: "pointer",
  },
  linkStatusSelected: {
    "& span": {
      color: "brown",
    },
  },
  underline: {
    color: "blue",
    cursor: "pointer",
  },
  noCursor: {
    cursor: "default",
  },
});
