import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as APIS from "libs/apis";
import { numberWithCommas } from "libs/utils";
import { closeModal } from "redux/modal/ModalReducer";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import Flex from "components/flex/Flex";
import SmallTitle from "components/title/SmallTitle";
import KendoButton from "components/button/KendoButton";

const RequestCancelModal = ({
  selectedNumber,
  handleClickSearch,
  infoData,
  userId,
  getStatusSummary,
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");

  //입고 취소 요청
  const handleClickRequestCancel = () => {
    const data = { number: selectedNumber, comment: comment };
    if (comment === "") {
      dispatch(actionError("취소요청 사유를 입력해주시기 바랍니다."));
    } else {
      dispatch(loadingStart);
      APIS.postInventoryRequestCancel(data)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "입고 취소 요청되었습니다.",
                () => {
                  handleClickSearch();
                  getStatusSummary();
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => {
          dispatch(closeModal());
          dispatch(loadingEnd);
        });
    }
  };
  return (
    <Flex>
      <Flex style={{ marginBottom: 10 }}>
        <Flex style={{ justifyContent: "center", marginBottom: 10 }}>
          <SmallTitle>취소요청 하시겠습니까?</SmallTitle>
        </Flex>
        <Flex row>
          <Flex style={{ justifyContent: "center", marginRight: 5 }}>
            회원번호 {userId}
          </Flex>
          <Flex>
            {infoData.map(({ label, sum }) => {
              return (
                <Flex row style={{ border: "1px solid #e2e5e8" }}>
                  <Flex
                    style={{
                      width: 100,
                      textAlign: "center",
                      background: "#e2e5e8",
                    }}
                  >
                    {label}
                  </Flex>
                  <Flex style={{ width: 140, textAlign: "center" }}>
                    {numberWithCommas(sum)}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex row style={{ alignItems: "center", marginTop: 15 }}>
          <SmallTitle>사유</SmallTitle>
          <input
            placeholder={"사유를 입력해주시기 바랍니다."}
            style={{ width: 300, height: 34, borderRadius: 5, marginLeft: 5 }}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            autoFocus
          />
        </Flex>
      </Flex>
      <Flex row style={{ justifyContent: "space-evenly", marginTop: 10 }}>
        <Flex>
          <KendoButton
            onClick={() => {
              handleClickRequestCancel();
            }}
          >
            취소요청
          </KendoButton>
        </Flex>
        <Flex>
          <KendoButton
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            취소
          </KendoButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RequestCancelModal;
