import { getAppraisalFeeList, getAppraisalInventoryStatus } from "libs/apis";
import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import {
  changeAssetType,
  changeDisposeType,
  getPageInfo,
} from "./appraisalFeeConst";
import { formatTime } from "services/utils";
import { actionError } from "redux/action/ActionActions";

export const useApprisalFee = (props) => {
  const [apprisalData, setApprisalData] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props) {
      const {
        dateType,
        page,
        size,
        startDate,
        endDate,
        assetType,
        disposeType,
      } = props;
      const payload = {
        dateType,
        page,
        size,
        startDate,
        endDate,
      };
      const params = new URLSearchParams(payload);
      if (assetType && Array.isArray(assetType)) {
        assetType.forEach((type) => {
          params.append("assetType", type);
        });
      }
      if (disposeType && Array.isArray(disposeType)) {
        disposeType.forEach((type) => {
          params.append("disposeType", type);
        });
      }
      dispatch(loadingStart);
      getAppraisalFeeList(params).then((res) => {
        const { data, success, message } = res.data;
        if (success) {
          const parsedData = data.content.map((item, index) => ({
            ...item,
            assetTypeKor: changeAssetType(item.assetType),
            userNameWithId: `${item.userName} (${item.userId})`,
            managerNameWithId: `${item.managerName} (${item.managerId})`,
            disposeType: changeDisposeType(item.disposeType),
            stockedAt: formatTime(item.stockedAt, "YYYY-MM-DD"),
            disposedAt: formatTime(item.disposedAt, "YYYY-MM-DD"),
            no: data.totalElements - (page * size + index),
          }));
          const pageInfo = getPageInfo(data);
          setApprisalData({
            gridData: parsedData,
            pageInfo,
            original: res.data.data,
          });
        } else {
          dispatch(actionError(message));
        }
        dispatch(loadingEnd);
      });
    }
  }, [props]);

  return [apprisalData];
};

export const useAppraisalInventoryStatus = () => {
  const [appraisalStatus, setAppraisalStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchApprisalStatus = useCallback(async () => {
    setIsLoading(true);
    const { data } = await getAppraisalInventoryStatus();
    const parsedData = data.data.map((datum) => ({
      ...datum,
      goldOrSilver: datum.goldOrSilver === "GOLD" ? "금" : "은",
    }));
    setIsLoading(false);
    setAppraisalStatus(parsedData);
  }, []);

  useEffect(() => {
    fetchApprisalStatus();
  }, [fetchApprisalStatus]);

  return { appraisalStatus, refetch: fetchApprisalStatus, isLoading };
};
