import {
  ButtonBase,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";

export default function DirectOrderDetail({}) {
  const history = useHistory();
  const classes = useStyle();
  const state = history.location.state || {};

  useEffect(() => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      tab: "1",
    });
  }, []);

  return (
    <>
      <LabelTabs
        data={[
          {
            label: "주문 상세 내역",
            value: "1",
          },
          {
            label: "상품 상세 내역",
            value: "2",
          },
          {
            label: "접수 상세 내역",
            value: "3",
          },
        ]}
        optionComponent={
          <Flex row className="row-center">
            <ButtonBase
              style={{
                marginLeft: 20,
                backgroundColor: "blue",
                color: "#fff",
                width: 100,
                height: 50,
              }}
            >
              저장
            </ButtonBase>
          </Flex>
        }
      >
        접수 상세 내역
      </LabelTabs>
      <Grid spacing={3} className={classes.grid2} container>
        <Grid item xs={1}>
          진행상태
        </Grid>
        <Grid item xs={11}>
          <RadioGroup style={{ marginLeft: 20 }} row>
            {[
              "결제완료",
              "배송준비",
              "배송중",
              "배송완료",
              "구매확정",
              "취소/환불",
            ].map((label) => {
              return (
                <FormControlLabel
                  key={label}
                  label={label}
                  control={<Radio color="default" style={{ color: "black" }} />}
                />
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>

      <GridRow
        label="판매자 정보"
        data={[
          { label: "등록 일자", value: "2021-12-31 13:32:00" },
          { label: "판매자 ID", value: "" },
          { label: "판매자", value: "" },
          { label: "휴대폰", value: "" },
        ]}
      />
      <GridRow
        label="주문자 정보"
        data={[
          { label: "주문번호", value: "2021-12-31 13:32:00" },
          { label: "주문일자", value: "" },
          { label: "주문자", value: "" },
          { label: "휴대폰", value: "" },
        ]}
      />

      <Grid spacing={1} className={classes.grid2} container>
        <Grid item xs={1}>
          배송 정보
        </Grid>
        <Grid item xs={11}>
          <RadioGroup style={{ marginLeft: 20 }} row>
            {["직접내방수령", "배송요청"].map((label) => {
              return (
                <FormControlLabel
                  key={label}
                  label={label}
                  control={<Radio color="default" style={{ color: "black" }} />}
                />
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>
      <GridRow
        data={[
          { label: "수령 매장", fullWidth: true, value: "2021-12-31 13:32:00" },
          { label: "예약일시", fullWidth: true, value: "" },
        ]}
      />

      <SimpleTable
        fullWidth
        label="결제정보"
        columns={[
          { label: "상품코드" },
          { label: "상품이미지" },
          { label: "상품명" },
          { label: "판매금액" },
          { label: "수수료" },
          { label: "수량" },
          { label: "결제금액" },
        ]}
        data={[{}]}
      />
    </>
  );
}

const useStyle = makeStyles({
  grid: {
    alignItems: "center",
    marginTop: 30,
    margin: "30px 50px",
    "& div": {
      display: "flex",
    },
  },
  imgWrap: {
    width: 100,
    height: 100,
    marginTop: 20,
    border: "1px solid rgb(220,220,220)",
    marginRight: 10,
  },
  grid2: {
    alignItems: "center",
    margin: "0px 50px",
    marginTop: 50,
    "& div": {
      display: "flex",
    },
  },
});
