import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import GridTable, {
  centerCell,
  leftCell,
  numberWithCommasCell,
} from "../../components/gird-table/GridTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ServiceAssetDrop from "./ServiceAssetDrop";
import ServiceAssetMint from "./ServiceAssetMint";
import { openModal } from "../../redux/modal/ModalReducer";
import * as APIS from "../../libs/apis";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import { createQueryString } from "../../libs/utils";
import { Checkbox } from "@progress/kendo-react-inputs";

const INIT_STATE = {
  skip: 0,
  take: 30,
  totalElements: 0,
};

const assetType = [
  { label: "발행", value: "MINT" },
  { label: "지급", value: "PAYMENT" },
];

const ServiceMarketAsset = () => {
  const [eventList, setEventList] = useState([]);
  const [page, setPage] = useState({ ...INIT_STATE });
  const [eventHistory, setEventHistory] = useState([]);
  const [selected, setSelected] = useState("");
  const [checkAssetType, setCheckAssetType] = useState([]);
  const dispatch = useDispatch();
  const didMount = useRef(false);

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (didMount.current) {
      eventHistoryList(selected, { page: 0, size: page.take });
    } else {
      didMount.current = true;
    }
  }, [checkAssetType]);

  const fetchList = () => {
    APIS.getEventList()
      .then((res) => {
        try {
          const { data } = res;
          const { success, data: eventData } = data;
          const parseEvent = eventData.map((item) => {
            return {
              ...item,
              eventName: `[${item.eventId}] ${item.eventName}`,
            };
          });
          const parseData = [
            { eventId: " ", eventName: "전체" },
            ...parseEvent,
          ];

          if (success) {
            setEventList(parseData);
            setSelected(parseData[0]);
            eventHistoryList(parseData[0]);
          }
        } catch (err) {
          dispatch(actionOpen(err, null, "", "", true));
        }
      })
      .catch((err) => dispatch(actionError(err)));
  };

  const eventHistoryList = (props, data) => {
    const { eventId } = props;
    let queryString = "";
    if (data) {
      const { page, size } = data;
      queryString = {
        eventId: eventId ? eventId : "",
        page: page,
        size: size,
        assetType: checkAssetType || [],
      };
    } else {
      queryString = {
        eventId: eventId ? eventId : "",
        page: page.skip,
        size: page.take,
        assetType: checkAssetType || [],
      };
    }
    for (let key in queryString) {
      const value = queryString[key];
      if (
        value === " " ||
        (typeof value === "string" && !value) ||
        value === null ||
        (Array.isArray(value) && value?.length === 0)
      ) {
        delete queryString[key];
      }
    }
    APIS.getEventAssetHistoryList(createQueryString(queryString)).then(
      (res) => {
        try {
          const { data } = res;
          const { success, data: historyData } = data;
          const { content, totalElements, number, size } = historyData;
          if (success) {
            setEventHistory(content || []);
            setPage({
              ...page,
              totalElements,
              page: number * size,
              size: size,
            });
            setSelected(props);
          }
        } catch (err) {
          console.log(err, "errr");
          //dispatch(actionOpen(err, null, "", "", true));
        }
      }
    );
  };

  const onChangeEventList = (e) => {
    setSelected(e.value);
    eventHistoryList(e.value, { page: 0, size: page.size });
  };

  const handleClickAssetMints = () => {
    dispatch(
      openModal({
        visiable: true,
        children: (
          <ServiceAssetMint eventList={eventList} searchFn={eventHistoryList} />
        ),
      })
    );
  };

  const handleClickAssetPayments = () => {
    dispatch(
      openModal({
        visiable: true,
        children: (
          <ServiceAssetDrop eventList={eventList} searchFn={eventHistoryList} />
        ),
      })
    );
  };

  const onPageChange = (event) => {
    const { page: pageData } = event;
    const { skip, take } = pageData;
    //setPage({ ...page, skip: skip / take, skip: take });
    setPage({ ...page, skip: skip, take: take });
    eventHistoryList(selected, { page: skip / take, size: take });
  };

  return (
    <Flex column>
      <Flex column>
        <Flex
          row
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <span>
            <h4>매장 자산</h4>
          </span>
          <div
            style={{
              width: "885px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "3px 3px 3px 3px",
            }}
          >
            <div style={{ display: "flex" }}>
              {assetType.map((item, index) => {
                const { value, label } = item;
                return (
                  <div style={{ marginRight: 10 }}>
                    <Checkbox
                      key={index.toString()}
                      label={label}
                      checked={checkAssetType.includes(value)}
                      onChange={() => {
                        let arr = [...checkAssetType];
                        if (arr.includes(value)) {
                          arr = arr.filter((item) => item !== value);
                        } else {
                          arr.push(value);
                        }
                        setCheckAssetType(arr);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "710px",
                alignItems: "center",
                margin: "2px 2px 2px 2px",
              }}
            >
              <div>
                <span style={{ lineHeight: "30px" }}>이벤트</span>
                &nbsp;&nbsp;
                <DropDownList
                  style={{ width: "450px", height: "30px", background: "#fff" }}
                  data={eventList}
                  dataItemKey="eventId"
                  textField="eventName"
                  defaultValue={selected}
                  onChange={onChangeEventList}
                />
              </div>
              <Button
                onClick={handleClickAssetMints}
                style={{
                  width: "100px",
                  height: "30px",
                  margin: "2px 2px 2px 2px",
                  background: "#fe6e0e",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                발행
              </Button>
              <Button
                onClick={handleClickAssetPayments}
                style={{
                  width: "100px",
                  height: "30px",
                  margin: "2px 2px 2px 2px",
                  background: "#fe6e0e",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                지급
              </Button>
            </div>
          </div>
        </Flex>
        <Flex style={{ width: "100%", height: "700px" }}>
          <MarketAssetWrap>
            <GridTable
              data={eventHistory.length > 0 ? eventHistory : []}
              style={{ height: "82vh" }}
              pageable={true}
              skip={page.page}
              take={page.size}
              //page={page.page}
              total={page.totalElements}
              scrollable={true}
              onPageChange={onPageChange}
            >
              <Column
                title="일시"
                width={200}
                field="createdAt"
                cell={centerCell}
              />
              <Column
                title="이벤트"
                width={400}
                style={{ lineHeight: "62px" }}
                field="eventName"
                cell={leftCell}
              />
              <Column
                title="회원"
                width={100}
                style={{ lineHeight: "62px" }}
                field="userName"
                cell={(props) => {
                  const { dataItem, field } = props;
                  const data = dataItem?.[field] || "발행";
                  return <td className="td-c">{data}</td>;
                }}
              />
              {/* <Column
                title="자산"
                width={80}
                rowSpan={2}
                style={{ lineHeight: "62px" }}
                field="assetType"
                cell={(props) => {
                  const { dataItem, field } = props;
                  const data =
                    dataItem[field] === "GOLD"
                      ? "금(g)"
                      : dataItem[field] === "SILVER"
                      ? "은(g)"
                      : dataItem[field] === "KRW"
                      ? "KRW(₩)"
                      : dataItem[field] === "POINT"
                      ? "포인트"
                      : "-";
                  return <td style={{ textAlign: "center" }}>{data}</td>;
                }}
              /> */}
              {/* <Column
                title="중량"
                width={150}
                style={{ lineHeight: "62px" }}
                field="tradeAmount"
                cell={numberWithCommasCell}
              /> */}
              <Column title="중량">
                <Column
                  title="금"
                  field="tradeGold"
                  cell={numberWithCommasCell}
                />
                <Column
                  title="은"
                  field="tradeSilver"
                  cell={numberWithCommasCell}
                />
                <Column
                  title="POINT"
                  field="tradePoint"
                  cell={numberWithCommasCell}
                />
                <Column
                  title="KRW"
                  field="tradeKrw"
                  cell={numberWithCommasCell}
                />
              </Column>
              <Column title="잔고">
                <Column
                  title="금"
                  field="afterGold"
                  cell={numberWithCommasCell}
                />
                <Column
                  title="은"
                  field="afterSilver"
                  cell={numberWithCommasCell}
                />
                <Column
                  title="POINT"
                  field="afterPoint"
                  cell={numberWithCommasCell}
                />
                <Column
                  title="KRW"
                  field="afterKrw"
                  cell={numberWithCommasCell}
                />
              </Column>
            </GridTable>
          </MarketAssetWrap>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ServiceMarketAsset;

const MarketAssetWrap = styled.div`
  .k-grid-norecords {
    height: 69vh;
  }

  td {
    border-bottom: 1px solid #ebebeb;
  }

  input {
    width: 250px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .k-cell-inner > .k-link {
    justify-content: center;
  }
`;
