import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import fonts from "../../libs/fonts";
import { TileLayout } from "@progress/kendo-react-layout";
import { STORAGE_KEY } from "../../libs/consts";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { actionError } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { numberWithCommas, numFormatFloor } from "../../libs/utils";
import styled from "styled-components";
import { Tooltip } from "@progress/kendo-react-tooltip";

const Panel = styled.div`
  .k-panelbar > .k-panelbar-header > .k-link {
    cursor: pointer;
  }
`;

const StatusService = () => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [buySellStatus, setBuySellStatus] = useState();
  const [appraisalStatus, setAppraisalStatus] = useState();
  const [goodsStatus, setGoodsStatus] = useState();
  const [safeTradeStatus, setSafeTradeStatus] = useState();
  const [positionData, setPositionData] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEY.serviceStatus))
      ?.serviceStatus || []
  );
  const [buySellExpanded, setBuySellExpanded] = useState([]);
  const [appraisalExpanded, setAppraisalExpanded] = useState([]);
  const [goodsExpanded, setGoodsExpanded] = useState([]);
  const [safeTradeExpanded, setSafeTradeExpanded] = useState([]);
  const buySellCheckApi = useRef(true);
  const appraisalCheckApi = useRef(true);
  const goodsCheckApi = useRef(true);
  const safeTradeCheckApi = useRef(true);
  const [buySellLoading, setBuySellLoading] = useState(true);
  const [appraisalLoading, setAppraisalLoading] = useState(true);
  const [goodsLoading, setGoodsLoading] = useState(true);
  const [safeTradeLoading, setSafeTradeLoading] = useState(true);

  const handleSelect = (event) => {
    if (event.expandedItems) {
      if (event.target.props.id === "buySell") {
        setBuySellExpanded(event.expandedItems);
      } else if (event.target.props.id === "appraisal") {
        setAppraisalExpanded(event.expandedItems);
      } else if (event.target.props.id === "goods") {
        setGoodsExpanded(event.expandedItems);
      } else if (event.target.props.id === "safeTrade") {
        setSafeTradeExpanded(event.expandedItems);
      }
    }
  };

  const digitSetting = (num) => {
    const splitNum = String(num)?.split(".");
    const length = splitNum[1]?.length;
    let result = "";
    if (length >= 4) {
      result = num?.toFixed(4);
    } else {
      result = num?.toFixed(3);
    }
    return result;
  };

  const initData = [
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
  ];

  const accordionContent = [
    {
      item: (
        <Panel>
          <PanelBar
            onSelect={handleSelect}
            expanded={buySellExpanded}
            style={{ cursor: "pointer" }}
          >
            <PanelBarItem title={"매수/매도 현황"} id="buySell">
              {buySellLoading === false ? (
                <Tooltip
                  openDelay={100}
                  position="top"
                  anchorElement="target"
                  tooltipStyle={{ top: "15px" }}
                >
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    className={classes.table}
                  >
                    <tr>
                      <th rowSpan={2} style={{ color: "red" }}>
                        금일
                      </th>
                      <th colSpan={3}>매수</th>
                      <th colSpan={3}>매도</th>
                      <th rowSpan={2}>체결량(g)</th>
                    </tr>
                    <tr>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"금(GOLD) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.goldBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.goldBuyRecommendSignKrw +
                            buySellStatus?.today?.todaySign
                              ?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.goldSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.goldSellRecommendSignKrw +
                            buySellStatus?.today?.todaySign
                              ?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todaySign?.goldBuySignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todaySign?.goldBuySignGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"은(SILVER) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.silverBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.silverBuyRecommendSignKrw +
                            buySellStatus?.today?.todaySign
                              ?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.silverSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign
                            ?.silverSellRecommendSignKrw +
                            buySellStatus?.today?.todaySign
                              ?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todaySign?.silverBuySignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todaySign?.silverBuySignGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"금(GOLD) 미체결(g)"}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.goldBuyRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.goldBuyRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.goldBuyNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.goldBuyNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.goldBuyRecommendUnSignGram +
                              buySellStatus?.today?.todayUnSign
                                ?.goldBuyNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.goldBuyRecommendUnSignGram +
                                buySellStatus?.today?.todayUnSign
                                  ?.goldBuyNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.goldSellRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.goldSellRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.goldSellNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.goldSellNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.goldSellRecommendUnSignGram +
                              buySellStatus?.today?.todayUnSign
                                ?.goldSellNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.goldSellRecommendUnSignGram +
                                buySellStatus?.today?.todayUnSign
                                  ?.goldSellNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"은(SILVER) 미체결(g)"}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.silverBuyRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.silverBuyRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.silverBuyNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.silverBuyNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.silverBuyRecommendUnSignGram +
                              buySellStatus?.today?.todayUnSign
                                ?.silverBuyNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.silverBuyRecommendUnSignGram +
                                buySellStatus?.today?.todayUnSign
                                  ?.silverBuyNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.silverSellRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.silverSellRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.silverSellNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.silverSellNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.today?.todayUnSign
                              ?.silverSellRecommendUnSignGram +
                              buySellStatus?.today?.todayUnSign
                                ?.silverSellNotRecommendUnSignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.today?.todayUnSign
                                ?.silverSellRecommendUnSignGram +
                                buySellStatus?.today?.todayUnSign
                                  ?.silverSellNotRecommendUnSignGram
                            )
                          )
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 200 }}
                      >
                        {"원화 (원)"}
                      </td>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"입금"}
                      </th>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"출금"}
                      </th>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign?.deposit
                        )}
                      </td>
                      <td colSpan={3}>
                        {numberWithCommas(
                          buySellStatus?.today?.todaySign?.withdraw
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <th rowSpan={2} style={{ color: "#ff6e6e" }}>
                        전일
                      </th>
                      <th colSpan={3}>매수</th>
                      <th colSpan={3}>매도</th>
                      <th rowSpan={2}>체결량(g)</th>
                    </tr>
                    <tr>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"금(GOLD) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.goldBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.goldBuyRecommendSignKrw +
                            buySellStatus?.yesterday?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.goldSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.goldSellRecommendSignKrw +
                            buySellStatus?.yesterday
                              ?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.yesterday?.goldBuySignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.yesterday?.goldBuySignGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"은(SILVER) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.silverBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.silverBuyRecommendSignKrw +
                            buySellStatus?.yesterday
                              ?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.silverSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday
                            ?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.yesterday?.silverSellRecommendSignKrw +
                            buySellStatus?.yesterday
                              ?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.yesterday?.silverBuySignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.yesterday?.silverBuySignGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 200 }}
                      >
                        {"원화 (원)"}
                      </td>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"입금"}
                      </th>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"출금"}
                      </th>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {numberWithCommas(buySellStatus?.yesterday?.deposit)}
                      </td>
                      <td colSpan={3}>
                        {numberWithCommas(buySellStatus?.yesterday?.withdraw)}
                      </td>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <th rowSpan={2} style={{ color: "#ff6e6e" }}>
                        전전일
                      </th>
                      <th colSpan={3}>매수</th>
                      <th colSpan={3}>매도</th>
                      <th rowSpan={2}>체결량(g)</th>
                    </tr>
                    <tr>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"금(GOLD) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.goldBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.goldBuyRecommendSignKrw +
                            buySellStatus?.dayBefore?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.goldSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.goldSellRecommendSignKrw +
                            buySellStatus?.dayBefore
                              ?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.dayBefore?.goldBuySignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.dayBefore?.goldBuySignGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"은(SILVER) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.silverBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.silverBuyRecommendSignKrw +
                            buySellStatus?.dayBefore
                              ?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.silverSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore
                            ?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.dayBefore?.silverSellRecommendSignKrw +
                            buySellStatus?.dayBefore
                              ?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            buySellStatus?.dayBefore?.silverBuySignGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              buySellStatus?.dayBefore?.silverBuySignGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 200 }}
                      >
                        {"원화 (원)"}
                      </td>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"입금"}
                      </th>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"출금"}
                      </th>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {numberWithCommas(buySellStatus?.dayBefore?.deposit)}
                      </td>
                      <td colSpan={3}>
                        {numberWithCommas(buySellStatus?.dayBefore?.withdraw)}
                      </td>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr style={{ color: "red" }}>
                      <th rowSpan={2}>누적</th>
                      <th colSpan={3}>매수</th>
                      <th colSpan={3}>매도</th>
                      <th rowSpan={2}>체결량(g)</th>
                    </tr>
                    <tr style={{ color: "#ff6e6e" }}>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                      <th>추천</th>
                      <th>비추천</th>
                      <th>합계</th>
                    </tr>
                    <tr style={{ color: "#ff6e6e" }}>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"금(GOLD) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.goldBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.goldBuyRecommendSignKrw +
                            buySellStatus?.cum?.goldBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.goldSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.goldSellRecommendSignKrw +
                            buySellStatus?.cum?.goldSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(buySellStatus?.cum?.goldBuySignGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(buySellStatus?.cum?.goldBuySignGram)
                          )
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "#ff6e6e" }}>
                      <td style={{ textAlign: "center", width: 200 }}>
                        {"은(SILVER) (원)"}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.silverBuyRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.silverBuyRecommendSignKrw +
                            buySellStatus?.cum?.silverBuyNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.silverSellRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          buySellStatus?.cum?.silverSellRecommendSignKrw +
                            buySellStatus?.cum?.silverSellNotRecommendSignKrw
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(buySellStatus?.cum?.silverBuySignGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(buySellStatus?.cum?.silverBuySignGram)
                          )
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "red" }}>
                      <td
                        rowSpan={2}
                        style={{
                          textAlign: "center",
                          width: 200,
                          color: "#ff6e6e",
                        }}
                      >
                        {"원화 (원)"}
                      </td>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"입금"}
                      </th>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        {"출금"}
                      </th>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                    <tr style={{ color: "#ff6e6e" }}>
                      <td colSpan={3}>
                        {numberWithCommas(buySellStatus?.cum?.deposit)}
                      </td>
                      <td colSpan={3}>
                        {numberWithCommas(buySellStatus?.cum?.withdraw)}
                      </td>
                      <td style={{ textAlign: "center" }}>{"-"}</td>
                    </tr>
                  </table>
                </Tooltip>
              ) : (
                <div style={{ textAlign: "center", paddingBottom: 15 }}>
                  <h4>LOADING</h4>
                  <span className="k-icon k-i-loading" />
                </div>
              )}
            </PanelBarItem>
          </PanelBar>
        </Panel>
      ),
    },
    {
      item: (
        <Panel>
          <PanelBar onSelect={handleSelect} expanded={appraisalExpanded}>
            <PanelBarItem title={"감정평가현황"} id={"appraisal"}>
              {appraisalLoading === false ? (
                <Tooltip
                  openDelay={100}
                  position="top"
                  anchorElement="target"
                  tooltipStyle={{ top: "15px" }}
                >
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    className={classes.table}
                  >
                    <tr>
                      <th rowSpan={2} colSpan={3}></th>
                      <th colSpan={3}>교환완료</th>
                      <th rowSpan={2}>반송완료</th>
                    </tr>
                    <tr>
                      <th>간편감정</th>
                      <th>현장감정</th>
                      <th>합계</th>
                    </tr>
                    <tr>
                      <td
                        rowSpan={4}
                        style={{ textAlign: "center", width: 100 }}
                      >
                        금일
                      </td>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 150 }}
                      >
                        금(GOLD)
                      </td>
                      <td style={{ textAlign: "center", width: 100 }}>건수</td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.gold
                            ?.completeExchangeSimpleCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.gold
                            ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.gold
                            ?.completeExchangeSimpleCount +
                            appraisalStatus?.today?.gold
                              ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.gold?.returnCompleteCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.gold
                              ?.completeExchangeSimpleGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.gold
                                ?.completeExchangeSimpleGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.gold
                              ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.gold
                                ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.gold
                              ?.completeExchangeSimpleGram +
                              appraisalStatus?.today?.gold
                                ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.gold
                                ?.completeExchangeSimpleGram +
                                appraisalStatus?.today?.gold
                                  ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.gold?.returnCompleteGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.gold?.returnCompleteGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        은(SILVER)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.silver
                            ?.completeExchangeSimpleCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.silver
                            ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.silver
                            ?.completeExchangeSimpleCount +
                            appraisalStatus?.today?.silver
                              ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.today?.silver?.returnCompleteCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.silver
                              ?.completeExchangeSimpleGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.silver
                                ?.completeExchangeSimpleGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.silver
                              ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.silver
                                ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.silver
                              ?.completeExchangeSimpleGram +
                              appraisalStatus?.today?.silver
                                ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.silver
                                ?.completeExchangeSimpleGram +
                                appraisalStatus?.today?.silver
                                  ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.today?.silver?.returnCompleteGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.today?.silver?.returnCompleteGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4} style={{ textAlign: "center" }}>
                        전일
                      </td>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        금(GOLD)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.gold
                            ?.completeExchangeSimpleCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.gold
                            ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.gold
                            ?.completeExchangeSimpleCount +
                            appraisalStatus?.yesterday?.gold
                              ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.gold?.returnCompleteCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.gold
                              ?.completeExchangeSimpleGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.gold
                                ?.completeExchangeSimpleGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.gold
                              ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.gold
                                ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.gold
                              ?.completeExchangeSimpleGram +
                              appraisalStatus?.yesterday?.gold
                                ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.gold
                                ?.completeExchangeSimpleGram +
                                appraisalStatus?.yesterday?.gold
                                  ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.gold?.returnCompleteGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.gold
                                ?.returnCompleteGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        은(SILVER)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.silver
                            ?.completeExchangeSimpleCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.silver
                            ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.silver
                            ?.completeExchangeSimpleCount +
                            appraisalStatus?.yesterday?.silver
                              ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.yesterday?.silver
                            ?.returnCompleteCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.silver
                              ?.completeExchangeSimpleGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.silver
                                ?.completeExchangeSimpleGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.silver
                              ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.silver
                                ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.silver
                              ?.completeExchangeSimpleGram +
                              appraisalStatus?.yesterday?.silver
                                ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.silver
                                ?.completeExchangeSimpleGram +
                                appraisalStatus?.yesterday?.silver
                                  ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.yesterday?.silver
                              ?.returnCompleteGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.yesterday?.silver
                                ?.returnCompleteGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={4} style={{ textAlign: "center" }}>
                        전전일
                      </td>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        금(GOLD)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.gold
                            ?.completeExchangeSimpleCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.gold
                            ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.gold
                            ?.completeExchangeSimpleCount +
                            appraisalStatus?.dayBefore?.gold
                              ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.gold?.returnCompleteCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.gold
                              ?.completeExchangeSimpleGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.gold
                                ?.completeExchangeSimpleGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.gold
                              ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.gold
                                ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.gold
                              ?.completeExchangeSimpleGram +
                              appraisalStatus?.dayBefore?.gold
                                ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.gold
                                ?.completeExchangeSimpleGram +
                                appraisalStatus?.dayBefore?.gold
                                  ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.gold?.returnCompleteGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.gold
                                ?.returnCompleteGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        은(SILVER)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.silver
                            ?.completeExchangeSimpleCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.silver
                            ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.silver
                            ?.completeExchangeSimpleCount +
                            appraisalStatus?.dayBefore?.silver
                              ?.completeExchangeVisitCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          appraisalStatus?.dayBefore?.silver
                            ?.returnCompleteCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.silver
                              ?.completeExchangeSimpleGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.silver
                                ?.completeExchangeSimpleGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.silver
                              ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.silver
                                ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.silver
                              ?.completeExchangeSimpleGram +
                              appraisalStatus?.dayBefore?.silver
                                ?.completeExchangeVisitGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.silver
                                ?.completeExchangeSimpleGram +
                                appraisalStatus?.dayBefore?.silver
                                  ?.completeExchangeVisitGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            appraisalStatus?.dayBefore?.silver
                              ?.returnCompleteGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              appraisalStatus?.dayBefore?.silver
                                ?.returnCompleteGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                  </table>
                </Tooltip>
              ) : (
                <div style={{ textAlign: "center", paddingBottom: 15 }}>
                  <h4>LOADING</h4>
                  <span className="k-icon k-i-loading" />
                </div>
              )}
            </PanelBarItem>
          </PanelBar>
        </Panel>
      ),
    },
    {
      item: (
        <Panel>
          <PanelBar onSelect={handleSelect} expanded={goodsExpanded}>
            <PanelBarItem title={"실물인출현황"} id={"goods"}>
              {goodsLoading === false ? (
                <Tooltip
                  openDelay={100}
                  position="top"
                  anchorElement="target"
                  tooltipStyle={{ top: "15px" }}
                >
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    className={classes.table}
                  >
                    <tr>
                      <th colSpan={2}></th>
                      <th></th>
                      <th>결제완료</th>
                      <th>결제취소</th>
                      <th>출고완료</th>
                      <th>인출확정</th>
                    </tr>
                    <tr>
                      <td
                        rowSpan={4}
                        style={{ textAlign: "center", width: 100 }}
                      >
                        금일
                      </td>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 150 }}
                      >
                        금(GOLD)
                      </td>
                      <td style={{ textAlign: "center", width: 100 }}>건수</td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.gold?.approvalCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.gold?.cancelCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.gold?.deliveryCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.gold?.confirmCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.gold?.approvalGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(goodsStatus?.today?.gold?.approvalGram)
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.gold?.cancelGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(goodsStatus?.today?.gold?.cancelGram)
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.gold?.deliveryGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(goodsStatus?.today?.gold?.deliveryGram)
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.gold?.confirmGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(goodsStatus?.today?.gold?.confirmGram)
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        은(SILVER)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.silver?.approvalCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.silver?.cancelCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.silver?.deliveryCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.today?.silver?.confirmCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.silver?.approvalGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.today?.silver?.approvalGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.silver?.cancelGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(goodsStatus?.today?.silver?.cancelGram)
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.silver?.deliveryGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.today?.silver?.deliveryGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.today?.silver?.confirmGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.today?.silver?.confirmGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={4}
                        style={{ textAlign: "center", width: 100 }}
                      >
                        전일
                      </td>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 150 }}
                      >
                        금(GOLD)
                      </td>
                      <td style={{ textAlign: "center", width: 100 }}>건수</td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.gold?.approvalCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.gold?.cancelCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.gold?.deliveryCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.gold?.confirmCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.gold?.approvalGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.gold?.approvalGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.yesterday?.gold?.cancelGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.gold?.cancelGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.gold?.deliveryGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.gold?.deliveryGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.gold?.confirmGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.gold?.confirmGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        은(SILVER)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.silver?.approvalCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.silver?.cancelCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.silver?.deliveryCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.yesterday?.silver?.confirmCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.silver?.approvalGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.silver?.approvalGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.silver?.cancelGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.silver?.cancelGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.silver?.deliveryGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.silver?.deliveryGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.yesterday?.silver?.confirmGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.yesterday?.silver?.confirmGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={4}
                        style={{ textAlign: "center", width: 100 }}
                      >
                        전전일
                      </td>
                      <td
                        rowSpan={2}
                        style={{ textAlign: "center", width: 150 }}
                      >
                        금(GOLD)
                      </td>
                      <td style={{ textAlign: "center", width: 100 }}>건수</td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.gold?.approvalCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.gold?.cancelCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.gold?.deliveryCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.gold?.confirmCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.gold?.approvalGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.gold?.approvalGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(goodsStatus?.dayBefore?.gold?.cancelGram)
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.gold?.cancelGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.gold?.deliveryGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.gold?.deliveryGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.gold?.confirmGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.gold?.confirmGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={2} style={{ textAlign: "center" }}>
                        은(SILVER)
                      </td>
                      <td style={{ textAlign: "center" }}>건수</td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.silver?.approvalCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.silver?.cancelCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.silver?.deliveryCount
                        )}
                      </td>
                      <td>
                        {numberWithCommas(
                          goodsStatus?.dayBefore?.silver?.confirmCount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>중량</td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.silver?.approvalGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.silver?.approvalGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.silver?.cancelGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.silver?.cancelGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.silver?.deliveryGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.silver?.deliveryGram
                            )
                          )
                        )}
                      </td>
                      <td
                        title={numberWithCommas(
                          digitSetting(
                            goodsStatus?.dayBefore?.silver?.confirmGram
                          )
                        )}
                      >
                        {numberWithCommas(
                          numFormatFloor(
                            digitSetting(
                              goodsStatus?.dayBefore?.silver?.confirmGram
                            )
                          )
                        )}
                      </td>
                    </tr>
                  </table>
                </Tooltip>
              ) : (
                <div style={{ textAlign: "center", paddingBottom: 15 }}>
                  <h4>LOADING</h4>
                  <span className="k-icon k-i-loading" />
                </div>
              )}
            </PanelBarItem>
          </PanelBar>
        </Panel>
      ),
    },
    {
      item: (
        <Panel>
          <PanelBar onSelect={handleSelect} expanded={safeTradeExpanded}>
            <PanelBarItem title={"안심직거래현황"} id={"safeTrade"}>
              {safeTradeLoading === false ? (
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  className={classes.table}
                >
                  <tr>
                    <th></th>
                    <th colSpan={4}>판매</th>
                    <th colSpan={3}>구매</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>접수완료</th>
                    <th>판매신청</th>
                    <th>판매중</th>
                    <th>판매취소</th>
                    <th>결제완료</th>
                    <th>구매취소</th>
                    <th>구매완료</th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>금일</td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.today?.inboundCompleteCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.today?.sellConfirmWaitCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(safeTradeStatus?.today?.sellCount)}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.today?.completeCloseCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.today?.paymentDoneCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.today?.paymentCancel1Count
                      )}
                    </td>
                    <td>
                      {numberWithCommas(safeTradeStatus?.today?.completeCount)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>전일</td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.yesterday?.inboundCompleteCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.yesterday?.sellConfirmWaitCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(safeTradeStatus?.yesterday?.sellCount)}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.yesterday?.completeCloseCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.yesterday?.paymentDoneCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.yesterday?.paymentCancel1Count
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.yesterday?.completeCount
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>금주</td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.thisWeek?.inboundCompleteCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.thisWeek?.sellConfirmWaitCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(safeTradeStatus?.thisWeek?.sellCount)}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.thisWeek?.completeCloseCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.thisWeek?.paymentDoneCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.thisWeek?.paymentCancel1Count
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.thisWeek?.completeCount
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>전주</td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.prevWeek?.inboundCompleteCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.prevWeek?.sellConfirmWaitCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(safeTradeStatus?.prevWeek?.sellCount)}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.prevWeek?.completeCloseCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.prevWeek?.paymentDoneCount
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.prevWeek?.paymentCancel1Count
                      )}
                    </td>
                    <td>
                      {numberWithCommas(
                        safeTradeStatus?.prevWeek?.completeCount
                      )}
                    </td>
                  </tr>
                </table>
              ) : (
                <div style={{ textAlign: "center", paddingBottom: 15 }}>
                  <h4>LOADING</h4>
                  <span className="k-icon k-i-loading" />
                </div>
              )}
            </PanelBarItem>
          </PanelBar>
        </Panel>
      ),
    },
  ];
  useEffect(() => {
    if (buySellExpanded.length > 0) {
      if (buySellCheckApi.current) {
        APIS.getBuySellStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setBuySellStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)))
          .finally(() => {
            buySellCheckApi.current = false;
            setTimeout(() => {
              setBuySellLoading(false);
            }, 500);
          });
      }
    }
    if (appraisalExpanded.length > 0) {
      if (appraisalCheckApi.current) {
        APIS.getAppraisalStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setAppraisalStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)))
          .finally(() => {
            appraisalCheckApi.current = false;
            setTimeout(() => {
              setAppraisalLoading(false);
            }, 500);
          });
      }
    }
    if (goodsExpanded.length > 0) {
      if (goodsCheckApi.current) {
        APIS.getGoodsStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setGoodsStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)))
          .finally(() => {
            goodsCheckApi.current = false;
            setTimeout(() => {
              setGoodsLoading(false);
            }, 500);
          });
      }
    }

    if (safeTradeExpanded.length > 0) {
      if (safeTradeCheckApi.current) {
        APIS.getSafeTradeStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setSafeTradeStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)))
          .finally(() => {
            safeTradeCheckApi.current = false;
            setTimeout(() => {
              setSafeTradeLoading(false);
            }, 500);
          });
      }
    }
  }, [buySellExpanded, appraisalExpanded, goodsExpanded, safeTradeExpanded]);

  const handleReposition = (e) => {
    const serviceStatusData =
      JSON.parse(localStorage.getItem(STORAGE_KEY.serviceStatus)) || {};
    serviceStatusData["serviceStatus"] = e.value;
    setPositionData(e.value);
    localStorage.setItem(
      STORAGE_KEY.serviceStatus,
      JSON.stringify(serviceStatusData)
    );
  };

  return (
    <>
      <div className={classes.wrap} style={{ marginBottom: 20 }}>
        <TileLayout
          columns={4}
          positions={
            Object.keys(positionData).length === 0 ? initData : positionData
          } //위치 관련 정보
          items={accordionContent}
          onReposition={handleReposition}
          rowHeight={255}
          gap={{
            rows: 10,
            columns: 10,
          }}
          style={{
            backgroundColor: "white",
            gridAutoRows: "auto",
          }}
        />
      </div>
    </>
  );
};

export default StatusService;
const useStyle = makeStyles({
  wrap: {
    margin: "0px 30px",
    marginTop: 10,
    fontFamily: fonts.notoSansKRBold,
  },
  label: {
    marginTop: 10,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    "& th": {
      textAlign: "center",
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "right",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
});
