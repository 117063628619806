import React, { useEffect } from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { Input, RadioGroup } from "@progress/kendo-react-inputs";

import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { formatTime } from "libs/utils";
import { makeStyles } from "@material-ui/core";

export const FormComponents = ({
  validationMessage,
  touched,
  id,
  label,
  valid,
  disabled,
  hint,
  visited,
  modified,
  formType,
  value,
  ...props
}) => {
  const classes = useStyles();

  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  const commonFormProps = {
    ref: editorRef,
    ariaDescribedBy: `${hintId} ${errorId}`,
    ariaLabelledBy: labelId,
    valid: valid,
    disabled: disabled,
    value: value,
    ...props,
  };

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className={"k-form-label"}
        style={{
          fontWeight: "bold",
          marginBottom: 8,
          width: "25%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}>
        {label}
        {formType === "dateRange" && value?.start && value?.end && (
          <div className={`${classes.subLabel}`}>
            {`(${formatTime(value?.start, "MM-DD")} ~ ${formatTime(
              value?.end,
              "MM-DD"
            )})`}
          </div>
        )}
      </Label>
      <div className={"k-form-field-wrap"}>
        {formType === "radio" ? (
          <RadioGroup {...commonFormProps} defaultValue={value} />
        ) : formType === "dateRange" ? (
          <DateRangePicker
            startDateInputSettings={{
              label: "시작일",
              format: "MM/dd",
              placeholder: "월-일",
            }}
            endDateInputSettings={{
              label: "종료일",
              format: "MM/dd",
              placeholder: "월-일",
            }}
            {...commonFormProps}
          />
        ) : formType === "input" ? (
          <Input {...commonFormProps} />
        ) : null}

        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

const useStyles = makeStyles({
  label: {
    fontWeight: "bold",
    marginBottom: 8,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  subLabel: {
    fontSize: 12,
    fontWeight: "normal",
  },
});
