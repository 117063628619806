import {
  makeStyles,
  Divider,
  IconButton,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { Add, ExpandMore, Close } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import { formatTime } from "../../libs/utils";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { useDispatch, useSelector } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import ProductReceptionInfo from "./ProductReceptionInfo";
import ProductAppraisalInfo from "./ProductAppraisalInfo";
import ProductRegisterInfo from "./ProductRegisterInfo";
import ProductAdminMemo from "./ProductAdminMemo";
import { formatPhone } from "../../services/utils";
import Button from "../../components/button/Button";
import fonts from "../../libs/fonts";
import Text from "../../components/text/Text";
import Input from "../../components/input/Input";
import { openModal } from "../../redux/modal/ModalReducer";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import GridTable, { centerCell } from "../../components/gird-table/GridTable";
import { GridColumn } from "@progress/kendo-react-grid";
import styled from "styled-components";

const GridHeader = styled.div`
  .k-grid-header-wrap {
    font-weight: bold;
    font-family: NotoSansKR-Medium;
  }
  font-family: NotoSansKR-Medium;
`;

export default function DirectStatusDetail({}) {
  const authReducer = useSelector((s) => s.auth);
  const { isKorda } = authReducer;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const state = history.location.state || {};
  const [productHistoryList, setProductHistoryList] = useState([]);
  const [receptionInfo, setReceptionInfo] = useState({});
  const [appraisalInfo, setAppraisalInfo] = useState({});
  const [registerInfo, setRegisterInfo] = useState({});
  const [statusData, setStatusData] = useState([]);
  const [status, setStatus] = useState("");
  const [recentStatus, setRecentStatus] = useState({});
  const [historyChange, setHistoryChange] = useState("false");
  const [cancelReason, setCancelReason] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [offset, setOffset] = useState({});
  const buttonRef = useRef(null);

  // const onChangeCancelReason = (value) => {
  //   setCancelReason(value);
  // };
  const handleClickInfo = () => {
    dispatch(
      openModal({
        visiable: true,
        children: <InfoProductAction />,
        maskClosable: true,
        // closable: false,
      })
    );
  };

  const onSaveAdmin = () => {
    if (status === "") {
      dispatch(actionError("변경할 상태를 선택하여 주세요."));
    } else if (status === statusData[0].status) {
      dispatch(actionError("현재 상태로 변경할 수 없습니다."));
    } else if (
      (status === "INBOUND_CANCEL" || status === "COMPLETE_CLOSE") &&
      cancelReason === ""
    ) {
      dispatch(actionError("취소 사유를 입력하여주세요."));
    } else {
      const data = {
        status: status,
        cancelReason: cancelReason,
        managerPassword: base64.stringify(sha256(`${password}`)),
      };
      dispatch(loadingStart);
      APIS.putChangeStatus(state.itemId, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "상태가 변경되었습니다.",
                () => {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { ...state, itemId: receptionInfo?.itemId },
                  });
                },
                null,
                null,
                true
              )
            );
            setStatus("");
            setPassword("");
            setCancelReason("");
            setHistoryChange("true");
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => {
          dispatch(loadingEnd);
          setShow(false);
        });
    }
  };

  useEffect(() => {
    //상품 이력 목록 조회
    dispatch(loadingStart);
    const getProductHistoryList = APIS.getSafeTradeProductHistoryList(
      state.itemId
    )
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setProductHistoryList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
    Promise.all([getProductHistoryList]).finally(() => {
      setHistoryChange("false");
      dispatch(loadingEnd);
    });
  }, [historyChange]);

  useEffect(() => {
    dispatch(loadingStart);
    //제품 상태 정보 리스트 조회
    const getProductStatusList = APIS.getSafeTradeProductStatusList(
      state.itemId
    )
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const status = {
            status: data.status,
            description: data.description,
          };
          const nextStatus = data.nextStep;
          if (nextStatus === null) {
            setStatusData([]);
            // setStatus(status.status);
            setRecentStatus(status);
          } else {
            setStatusData([{}, ...nextStatus]);
            // setStatus(status.status);
            setRecentStatus(status);
          }
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    //제품 접수 정보 조회
    const getReceptionInfo = APIS.getSafeTradeReceptionInfo(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setReceptionInfo(data);
          // const recentStatus = data.status;
          // const nextStatus = data.nextStatus;
          // if (nextStatus === null) {
          //   setStatusData([recentStatus]);
          //   setStatus(recentStatus);
          // } else {
          //   setStatusData([recentStatus, ...nextStatus]);
          //   setStatus(recentStatus);
          // }
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    //제품 감정 정보 조회
    const getAppraisalInfo = APIS.getSafeTradeAppraisalInfo(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAppraisalInfo(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    //제품 등록 정보 조회
    const getRegisterInfo = APIS.getSafeTradeRegisterInfo(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setRegisterInfo(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    Promise.all([
      getReceptionInfo,
      getAppraisalInfo,
      getRegisterInfo,
      getProductStatusList,
    ]).finally(() => {
      setHistoryChange("false");
      dispatch(loadingEnd);
    });
  }, [historyChange]);

  return (
    <>
      {/* <PageLabel>{receptionInfo?.itemId} 상세보기</PageLabel> */}
      <Flex row>
        <Flex className={classes.leftSide}>
          <Flex row className={classes.information}>
            <Flex className={classes.informationTextBox}>
              <Flex row>
                <Flex className={classes.informationText}>카테고리</Flex>
                <Flex className={classes.informationValue}>
                  {appraisalInfo?.categoryInfo?.categoryName || "\u00A0"}
                </Flex>
              </Flex>
              <Flex row>
                <Flex className={classes.informationText}>상품명</Flex>
                <Flex className={classes.informationValue}>
                  {appraisalInfo?.name || "\u00A0"}
                </Flex>
              </Flex>
              <Flex row>
                <Flex className={classes.informationText}>판매번호</Flex>
                <Flex className={classes.informationValue}>
                  {receptionInfo?.itemId || "\u00A0"}
                </Flex>
              </Flex>
              <Flex row>
                <Flex className={classes.informationText}>출고상태</Flex>
                <Flex className={classes.informationValue}>
                  {receptionInfo?.deliveryType
                    ?.replace("RETURN", "반송")
                    ?.replace("PREVIEW", "프리뷰")
                    ?.replace("BUY", "판매") || "\u00A0"}
                </Flex>
              </Flex>
              <Flex row>
                <Flex className={classes.informationText}>현재상태</Flex>
                <Flex className={classes.informationValue}>
                  {recentStatus?.description || "\u00A0"}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Divider className={classes.divider} />
          <Flex className={classes.progressStatusBox}>진행 상태</Flex>
          <Flex>
            <GridHeader>
              <GridTable
                style={{
                  margin: "6px 0px 0px 0px",
                  maxHeight: 640,
                }}
                data={productHistoryList}
                scrollable
              >
                <GridColumn
                  width={110}
                  field="description"
                  title="상태"
                  cell={centerCell}
                />
                <GridColumn
                  width={120}
                  field="createdAt"
                  title="일시"
                  cell={(props) => {
                    const { dataItem, field } = props;
                    return (
                      <td style={{ textAlign: "center" }}>
                        {dataItem[field]
                          ? formatTime(dataItem[field], "YYYY-MM-DD HH:mm:ss")
                          : "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  width={90}
                  field="managerName"
                  title="관리자"
                  cell={centerCell}
                />
                <GridColumn
                  width={110}
                  field="shopName"
                  title="입출고 매장"
                  cell={centerCell}
                />
              </GridTable>
            </GridHeader>
          </Flex>
        </Flex>
        <Flex className={classes.rightSide}>
          <table cellPadding={0} cellSpacing={0} className={classes.table}>
            <thead>
              <tr>
                <th colSpan={6}>판매자</th>
                <th colSpan={6}>구매자</th>
              </tr>
              <tr>
                <th>접수번호</th>
                <th>고객</th>
                <th>핸드폰</th>
                <th>접수 매장</th>
                <th>매장 방문 예약일</th>
                <th>매장 방문 예약 시간</th>
                <th>구매번호</th>
                <th>고객</th>
                <th>핸드폰</th>
                <th>출고 매장</th>
                <th>매장 방문 예약일</th>
                <th>매장 방문 예약 시간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{receptionInfo?.sellerInfo?.orderId || "-"}</td>
                <td>{receptionInfo?.sellerInfo?.userName || "-"}</td>
                <td>
                  {formatPhone(receptionInfo?.sellerInfo?.phoneNumber) || "-"}
                </td>
                <td>{receptionInfo?.sellerInfo?.shopName || "-"}</td>
                <td>{receptionInfo?.sellerInfo?.inStoreReserve || "-"}</td>
                <td>{receptionInfo?.sellerInfo?.inStoreReserveTime || "-"}</td>
                <td>
                  {receptionInfo?.deliveryType === "RETURN"
                    ? "-"
                    : receptionInfo?.buyerInfo?.orderId || "-"}
                </td>
                <td>
                  {receptionInfo?.deliveryType === "RETURN"
                    ? "-"
                    : receptionInfo?.buyerInfo?.userName || "-"}
                </td>
                <td>
                  {receptionInfo?.deliveryType === "RETURN"
                    ? "-"
                    : formatPhone(receptionInfo?.buyerInfo?.phoneNumber) || "-"}
                </td>
                <td>
                  {receptionInfo?.deliveryType === "RETURN"
                    ? "-"
                    : receptionInfo?.buyerInfo?.shopName || "-"}
                </td>
                <td>
                  {receptionInfo?.deliveryType === "RETURN"
                    ? "-"
                    : receptionInfo?.buyerInfo?.inStoreReserve || "-"}
                </td>
                <td>
                  {receptionInfo?.deliveryType === "RETURN"
                    ? "-"
                    : receptionInfo?.buyerInfo?.inStoreReserveTime || "-"}
                </td>
              </tr>
            </tbody>
          </table>
          <Flex row className={classes.statusChange}>
            상품 처리
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              {statusData.map((item, index) => {
                return (
                  <option
                    key={index}
                    label={item.description}
                    value={item.status}
                  />
                );
              })}
            </select>
            <Button
              ref={buttonRef}
              label="저장"
              onClick={(e) => {
                setShow(!show);
                setOffset({
                  x: e.pageX,
                  y: e.pageY,
                });
              }}
            />
            {show && (
              <div
                onKeyPress={(e) => {
                  if (e?.nativeEvent?.key === "Enter") {
                    onSaveAdmin();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    setShow(false);
                    setPassword("");
                    setCancelReason("");
                  }
                }}
                style={{
                  position: "absolute",
                  top: offset.y + 20,
                  left: offset.x - 10,
                  zIndex: 999,
                  display: "flex",
                }}
              >
                <div style={{ width: "100%", height: "50px" }}>
                  <Flex row className={classes.popopHeader}>
                    <Text font={fonts.notoSansKRBold}>{"상품 상태 변경"}</Text>
                    <IconButton
                      style={{ position: "absolute", right: 20 }}
                      onClick={(e) => {
                        setShow(false);
                        setCancelReason("");
                        setPassword("");
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Flex>

                  <Flex className={classes.main}>
                    {status === "INBOUND_CANCEL" ||
                    status === "COMPLETE_CLOSE" ? (
                      <Flex row>
                        <Flex
                          style={{
                            marginRight: 10,
                            alignSelf: "center",
                          }}
                        >
                          취소 사유
                        </Flex>
                        <input
                          placeholder="취소 사유를 입력하세요."
                          value={cancelReason}
                          onChange={(e) => setCancelReason(e.target.value)}
                          style={{ width: 300, height: 30 }}
                        />
                      </Flex>
                    ) : (
                      <Flex>{"변경사항을 저장하시겠습니까?"}</Flex>
                    )}

                    <Flex row className={classes.mainInput}>
                      <span>관리자 비밀번호</span>
                      <Input
                        autoFocus
                        password
                        onChange={setPassword}
                        value={password}
                        autoComplete="new-password"
                      />
                    </Flex>
                    <Button
                      className={classes.b}
                      label="확인"
                      onClick={() => onSaveAdmin()}
                    />
                  </Flex>
                </div>
              </div>
            )}
            {/* <AdminConfirmButton
              popupLabel="제품상태 변경"
              popupMessage={
                status === "INBOUND_CANCEL" || status === "COMPLETE_CLOSE" ? (
                  <Flex row>
                    <Flex
                      style={{
                        marginRight: 10,
                        alignSelf: "center",
                      }}
                    >
                      취소 사유
                    </Flex>
                    <input
                      value={changeCancelReason}
                      onChange={(e) => {
                        //setCancelReason(e.target.value);
                        onChangeCancelReason(e.target.value);
                      }}
                      placeholder="취소 사유 입력"
                      style={{
                        width: 300,
                        height: 40,
                        border: "1px solid rgb(224,224,224)",
                      }}
                    />
                  </Flex>
                ) : (
                  "변경사항을 저장하시겠습니까?"
                )
              }
              label="저장"
              callback={onSaveAdmin}
              rootClassName={classes.confirmRoot}
              btnClassName={classes.confirmButton}
            />  */}
            <Button
              onClick={() => {
                handleClickInfo();
              }}
              label="설명"
              className={classes.labelIcon}
              // style={{ width: 50 }}
            />
          </Flex>
          <ProductReceptionInfo
            list={receptionInfo}
            recentStatus={recentStatus?.status}
            isKorda={isKorda}
            setList={(value) =>
              setReceptionInfo({ ...receptionInfo, ...value })
            }
          />
          <ProductAppraisalInfo
            list={appraisalInfo}
            recentStatus={recentStatus?.status}
            isKorda={isKorda}
            setList={(value) =>
              setAppraisalInfo({ ...appraisalInfo, ...value })
            }
          />
          <ProductRegisterInfo
            list={registerInfo}
            recentStatus={recentStatus?.status}
            isKorda={isKorda}
            authPrice={appraisalInfo?.authPrice}
            setList={(value) => setRegisterInfo({ ...registerInfo, ...value })}
          />

          <ProductAdminMemo />
          {/* <GridRow
            label="판매자 정보"
            data={[
              { label: "접수 일자", value: "2021-12-31 13:32:00" },
              { label: "판매자 ID", value: "" },
              { label: "판매자", value: "" },
              { label: "휴대폰", value: "" },
            ]}
          />

          <GridRow
            label="접수 정보"
            data={[
              { label: "접수번호", value: "2021-12-31 13:32:00" },
              { label: "접수 매장", value: "" },
              { label: "카테고리", value: "" },
              { label: "제품명", value: "" },
              { label: "브랜드(제조사)", value: "" },
              { label: "제조출시년도", value: "" },
              { label: "구매가격", value: "" },
              { label: "제품상태", value: "" },
              { label: "판매희망가격", value: "" },
              { label: "위탁판매 수수료", value: "" },
            ]}
          />
          <Grid spacing={3} className={classes.grid} container>
            <Grid item xs={1}>
              이미지
            </Grid>
            <Grid item xs={11}>
              <div className={classes.imgWrap} />
              <div className={classes.imgWrap} />
              <div className={classes.imgWrap} />
            </Grid>
            <Grid item xs={1}>
              보증서
            </Grid>
            <Grid item xs={11}>
              <div className={classes.imgWrap} />
              <div className={classes.imgWrap} />
              <div className={classes.imgWrap} />
            </Grid>
          </Grid>

          <SimpleTable
            label="접수 이력"
            columns={[{ label: "변경일자" }, { label: "이력" }]}
            data={[{}, {}, {}, {}, {}]}
          /> */}
        </Flex>
      </Flex>
    </>
  );
}
const InfoProductAction = () => {
  const classes = useStyle();
  const authReducer = useSelector((s) => s.auth);
  const { isKorda } = authReducer;
  return (
    <Flex
      style={{
        width: "850px",
        height: "420px",
        overflowY: "scroll",
        marginTop: 15,
      }}
    >
      <Flex className={classes.modal}>{"상품 처리 설명"}</Flex>
      <Table>
        {/* {isKorda ? ( */}
        <TableBody>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              상품 접수 확인(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품을 확인하고 접수를 진행하여 본사로 송부하기로 한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              상품 접수 반려(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품이 접수를 진행할 수 없는 감정불가 또는 가품 등인 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 인수</TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품을 매장에서 송부하여 본사 관리자가 수령한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 인수 거부</TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품 판매 신청이 접수 완료 된 후 다시 접수를 취소하는
              경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>반송</TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품을 돌려받기, 판매철회 등의 사유로 접수 매장으로
              돌려보내야 하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>감정완료</TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품을 감정하여 감정결과를 입력 및 저장 한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>거래종료</TableCell>
            <TableCell className={classes.cellContent}>
              판매 절차를 강제로 종료하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>반송 상품 출고</TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 반송 상품을 접수 매장으로 발송한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              상품 출고 완료(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              구매자의 구매 상품 또는 판매자의 반송 상품이 본사로부터 도착한
              경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>판매 목록 등록</TableCell>
            <TableCell className={classes.cellContent}>
              판매자의 상품 등록을 위해 상세정보를 입력하고 판매 할 수 있는
              상태로 변경하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 판매 시작</TableCell>
            <TableCell className={classes.cellContent}>
              판매 목록에 등록된 상품을 노출하여 판매될 수 있게 하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 판매 중지</TableCell>
            <TableCell className={classes.cellContent}>
              판매를 위해 노출된 상품을 목록에서 제외하여 판매 대기 상태로
              변경하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 출고</TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 구매한 상품을 수령매장으로 발송한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              상품 출고 완료(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              본사로부터 출고된 상품이 매장에 도착한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 전달(매장)</TableCell>
            <TableCell className={classes.cellContent}>
              상품을 구매자에게 전달한 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              미방문 반송(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 매장 미 방문 등으로 상품을 전달할 수 없게 된 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              구매 취소 확인(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 상품을 확인한 후 구매 취소한 것을 승인하고 본사로
              반송하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              구매 취소 반려(매장)
            </TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 상품을 확인한 후 구매 취소한 것을 반려하여 구매확정
              처리하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 판매 시작</TableCell>
            <TableCell className={classes.cellContent} f>
              상품을 다시 판매중인 상태로 변경하는 경우
            </TableCell>
          </TableRow>
        </TableBody>
        {/* ) : ( */}
        {/* <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                상품 접수 확인(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                판매자의 상품을 확인하고 접수를 진행하여 본사로 송부하기로 한
                경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                상품 접수 반려(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                판매자의 상품이 접수를 진행할 수 없는 감정불가 또는 가품 등인
                경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                상품 출고 완료(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                구매자의 구매 상품 또는 판매자의 반송 상품이 본사로부터 도착한
                경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                상품 출고 완료(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                본사로부터 출고된 상품이 매장에 도착한 경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                상품 전달(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                상품을 구매자에게 전달한 경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                미방문 반송(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                구매자가 매장 미 방문 등으로 상품을 전달할 수 없게 된 경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                구매 취소 확인(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                구매자가 상품을 확인한 후 구매 취소한 것을 승인하고 본사로
                반송하는 경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                구매 취소 반려(매장)
              </TableCell>
              <TableCell className={classes.cellContent}>
                구매자가 상품을 확인한 후 구매 취소한 것을 반려하여 구매확정
                처리하는 경우
              </TableCell>
            </TableRow>
          </TableBody>
        )}*/}
      </Table>
    </Flex>
  );
};
const useStyle = makeStyles({
  table: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "30px 0px 0px 0px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  grid: {
    justifyContent: "center",
    margin: "0px 50px",
    "& div": {
      display: "flex",
    },
  },
  imgWrap: {
    width: 100,
    height: 100,
    border: "1px solid rgb(220,220,220)",
    marginRight: 80,
  },

  leftSide: { margin: "30px 20px 10px 50px", width: "18%" },
  rightSide: { margin: "0px 0px 0px 50px", width: "70%" },
  progressStatusBox: {
    marginTop: 5,
    fontWeight: "bold",
    // padding: 5,
    fontSize: "large",
    // marginBottom: 5,
    // width:280,
    // border: "1px solid black",
    // backgroundColor: "lightgrey",
  },
  information: {
    width: "300px",
    justifyContent: "space-between",
  },
  informationTextBox: {
    marginBottom: "5px",
    fontSize: "large",
  },
  informationText: {
    fontWeight: "bold",
    fontSize: "large",
    minWidth: 63,
    marginRight: 20,
  },
  informationValueBox: {
    marginLeft: "35px",
    fontSize: "large",
  },
  informationValue: {
    fontSize: "large",
    height: "fit-content",
  },
  divider: {
    width: "100%",
    margin: "5px 0px 0px 0px",
  },
  statusChange: {
    fontWeight: "bold",
    alignItems: "center",
    "& select": {
      margin: " 5px 10px 4px 10px",
    },
    margin: "20px 0px",
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },

  container: {
    // display: "flex",
    touchAction: "none",
    width: "1200px",
    margin: "1rem auto",
    height: 350,
  },
  popopHeader: {
    position: "relative",
    minWidth: "400px",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "#fff",
      fontSize: 16,
    },
    backgroundColor: "rgb(180,180,180)",
  },
  main: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,0.23)",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    minHeight: 200,
  },
  mainInput: {
    marginTop: 30,
    alignItems: "center",
    "& span": {
      marginRight: 10,
    },
    "& input": {
      padding: "0px",
    },
  },
  b: { padding: 10, minWidth: "30%", marginTop: 30 },
  labelIcon: {
    margin: "0px 10px",
    fontFamily: fonts.notoSansKRBold,
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
  },
  modal: {
    textAlign: "left",
    padding: "2px 0px 0px 21px",
    width: "100%",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
    fontWeight: "bold",
  },
  cellTitle: {
    fontWeight: "bold",
    width: "20%",
    padding: 10,
  },
  cellContent: {
    width: "80%",
    padding: 10,
  },
});
