import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import Flex from "../flex/Flex";
import {
  ButtonBase,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { formatTime, numFormat } from "../../libs/utils";
import Text from "../text/Text";
import fonts from "../../libs/fonts";
import { formatPhone } from "../../services/utils";

export default function SimpleTable({
  columns = [],
  fullWidth,
  data = [],
  onAdd,
  onClick,
  totalCount,
  label,
  onExcelDownload,
  onPageChanged,
}) {
  const classes = useStyle();
  const { location, push, replace } = useHistory();
  const state = location.state || {};

  return (
    <TableContainer
      className={
        classes.container + " " + (fullWidth && classes.containerFullWidth)
      }
    >
      <Text style={{ marginBottom: 10 }} font={fonts.notoSansKRBold}>
        {label}
      </Text>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.head}>
          <TableRow>
            {columns.map((x, i) => {
              return (
                <TableCell
                  colSpan={x.col || 1}
                  component="th"
                  style={{ minWidth: x.minWidth || 0 }}
                  key={i.toString()}
                  align="center"
                >
                  {x.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              style={{
                cursor: onClick ? "pointer" : "normal",
              }}
              key={i.toString()}
              onClick={() => {
                onClick && onClick(row);
              }}
            >
              {columns.map((column, index) => {
                return (
                  <TableCell
                    key={index.toString()}
                    align="center"
                    component="td"
                    style={{ minWidth: column.minWidth || 0 }}
                    colSpan={column.col || 1}
                  >
                    {column.render
                      ? column.render(row)
                      : (() => {
                          switch (column.type) {
                            case "datetime":
                              return (
                                formatTime(
                                  row[column.key],
                                  "YYYY-MM-DD HH:mm"
                                ) || "-"
                              );
                            case "phone":
                              return formatPhone(row[column.key]);
                            case "number":
                              return numFormat(row[column.key]);
                            case "orderstatus":
                              if (row[column.key] === "APPROVAL") {
                                return "결제";
                              } else if (row[column.key] === "CANCEL") {
                                return "취소";
                              } else if (row[column.key] === "MAKE") {
                                return "제작중";
                              } else if (row[column.key] === "SHIPMENT") {
                                return "출고중";
                              } else if (row[column.key] === "DELIVERY") {
                                return "출고완료";
                              } else if (row[column.key] === "TAKEOUT") {
                                return "인출완료";
                              } else if (row[column.key] === "CONFIRM") {
                                return "인출확정";
                              } else if (row[column.key] === "NOT_SHIPPED") {
                                return "미출고";
                              } else {
                                return "-";
                              }
                            default:
                              return row[column.key] || "-";
                          }
                        })()}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyle = makeStyles({
  header: {
    alignSelf: "stretch",
    marginTop: "30px",
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: "16px",
    padding: "10px 50px",
    backgroundColor: "#000",
  },
  buttonLabel: {
    color: "white",
  },
  add: {
    minWidth: "40%",
    alignSelf: "center",
    marginTop: "50px",
    backgroundColor: "rgba(0,0,0,0)",
    border: "1px solid black",
  },
  addLabel: { color: "black" },
  container: {
    alignSelf: "flex-start",
    borderRadius: "3px",
    width: "50%",
    paddingTop: "10px",
    padding: "30px 50px",
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    "& tr": {
      borderLeft: "1px solid #ddd",
      borderTop: "1px solid #ddd",
      borderRight: "1px solid #ddd",
    },
  },
  containerFullWidth: {
    width: "auto",
    alignSelf: "stretch",
  },
  head: {
    backgroundColor: "#f5f5f5",
  },
});
