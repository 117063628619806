import styled from "styled-components";
import { Popover, FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { signOut } from "../../redux/auth/AuthReducer";
import { HiOutlineMenu, HiShoppingCart } from "react-icons/hi";

import { IoIosCalculator } from "react-icons/io";
import { AiFillGold } from "react-icons/ai";
import { BsPersonLinesFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import {
  FcManager,
  FcBullish,
  FcSettings,
  FcViewDetails,
  FcPortraitMode,
  FcOnlineSupport,
  FcCalculator,
  FcShop,
  FcSurvey,
  FcSearch,
  FcDownload,
  FcReadingEbook,
  FcFilingCabinet,
} from "react-icons/fc";

const KORDA_MENU = [
  {
    path: routes.manager,
    label: "관리자",
    to: routes.managerAuthority,
    icon: <FcManager style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.member,
    label: "회원 관리",
    to: routes.memberNormal,
    icon: <FcPortraitMode style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.transaction,
    label: "통합거래내역",
    to: routes.transactionIntegrated,
    icon: <FcViewDetails style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.trading,
    label: "매수/매도",
    to: routes.tradingAsset,
    icon: <FcBullish style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.appraisal,
    label: "감정평가",
    to: routes.appraisalStatus,
    icon: <FcSurvey style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.physical,
    label: "실물인출",
    to: routes.physicalOrder,
    icon: <AiFillGold style={{ fontSize: "20px", fill: "#ffeb3b" }} />,
  },
  {
    path: routes.direct,
    label: "안심직거래",
    to: routes.directStatus,
    icon: <FcShop style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.inventory,
    label: "재고관리",
    to: routes.inventoryStatus,
    icon: <FcFilingCabinet style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.settlement,
    label: "정산관리",
    to: routes.settlementHistory,
    icon: <FcCalculator style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.service,
    label: "고객센터",
    to: routes.serviceInquiry,
    icon: <FcOnlineSupport style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.setting,
    label: "설정",
    to: routes.settingDirect,
    icon: <FcSettings style={{ fontSize: "20px" }} />,
  },
];

const SHOP_MENU = [
  {
    path: routes.trading,
    label: "매수/매도",
    to: routes.tradingAsset,
    icon: <FiEdit style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.appraisal,
    label: "감정평가",
    to: routes.appraisalStatus,
    icon: <FiEdit style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.physical,
    label: "실물인출",
    to: routes.physicalOrder,
    icon: <AiFillGold style={{ fontSize: "20px" }} />,
  },
  { path: routes.direct, label: "안심직거래", to: routes.directStatus },
  {
    path: routes.member,
    label: "회원 관리",
    to: routes.memberNormal,
    icon: <BsPersonLinesFill style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.shop,
    label: "매장 관리",
    to: routes.shopInfo,
    icon: <HiShoppingCart style={{ fontSize: "20px" }} />,
  },
  {
    path: routes.settlement,
    label: "정산관리",
    to: routes.settlementShopFee,
    icon: <IoIosCalculator style={{ fontSize: "20px" }} />,
  },
];

const KORDA_SIDE_MENU = [
  { path: routes.managerAuthority, label: "권한관리" },
  { path: routes.managerManagement, label: "관리자관리" },
  { path: routes.myInfo, label: "내 정보" },
  { path: routes.memberNormal, label: "일반회원" },
  { path: routes.memberShop, label: "대리점회원" },
  // { path: routes.memberInactive, label: "사용정지회원" },
  // { path: routes.memberDelete, label: "휴면회원" },
  { path: routes.tradingAsset, label: "자산" },
  { path: routes.appraisalStatus, label: "감정현황" },
  { path: routes.appraisalProduct, label: "제품설정" },
  // { path: routes.appraisalProcess, label: "처리현황" },
  { path: routes.appraisalInspection, label: "검품 및 재질 분류" },
  //  { path: routes.appraisalStock, label: "수탁재고관리" },
  { path: routes.physicalOrder, label: "통합주문현황" },
  { path: routes.physicalProduct, label: "제품관리" },
  { path: routes.directStatus, label: "접수/등록 현황" },
  { path: routes.directProduct, label: "상품관리" },
  { path: routes.directOrder, label: "주문관리" },
  { path: routes.settlementHistory, label: "정산현황" },
  { path: routes.settlementVirtualAc, label: "가상계좌관리" },
  { path: routes.settlementOutmoney, label: "출금관리" },
  { path: routes.settlementSalesFee, label: "매출수수료" },
  { path: routes.settlementShopFee, label: "가맹점수수료" },
  { path: routes.serviceNotice, label: "공지사항" },
  // { path: routes.serviceReview, label: "거래후기 관리" },
  { path: routes.serviceFaq, label: "FAQ 관리" },
  { path: routes.serviceFaqCategory, label: "FAQ 분류 관리" },
  // { path: routes.serviceUseGuide, label: "이용가이드 관리" },
  { path: routes.serviceInquiry, label: "고객문의관리" },
  { path: routes.serviceDirect, label: "상품 문의" },
  { path: routes.serviceNotification, label: "알림서비스" },
  // { path: routes.settingOrderPolicy, label: "거래정책" },
  // { path: routes.settingFee, label: "수수료정책" },
  { path: routes.settingPolicy, label: "이용약관" },
  // { path: routes.settingAdmin, label: "관리자 정보" },
  // { path: routes.settingLog, label: "접속기록" },
  // { path: routes.settingBasicInfo, label: "기본정보" },
];

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  const handleClick = (item) => (event) => {
    history.push({ pathname: item.to + "/", state: null });
  };

  const handleClickSearch = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let menu = isKorda ? KORDA_MENU : SHOP_MENU;
  let sideMenu = isKorda ? KORDA_SIDE_MENU : [];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <HeaderWrap>
      <TitleWrap
        onClick={() => {
          history.replace(routes.main);
        }}>
        <img
          src={images.logo_new}
          className="logo"
          style={{ userSelect: "none" }}
        />
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginLeft: "10px",
            userSelect: "none",
          }}>
          관리시스템
        </span>
      </TitleWrap>
      <MenuWrap>
        {menu.map((x, i) => {
          const point = location.pathname.startsWith(x.path);
          return (
            <div
              key={`${x.label}-${i}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                userSelect: "none",
                margin: "3px",
                width: "90px",
                height: "90%",
                borderRadius: "0.3rem",
                background: point ? "#f4f2f2" : "#fff",
                backgroundPosition: "100% 0",
                transition: "all .4s ease-in-out",
              }}
              onClick={handleClick(x)}>
              <span style={{ fontSize: "13px" }}>
                {x?.icon || <HiOutlineMenu />}
              </span>
              <span style={{ fontSize: "13px" }}>{x.label}</span>
            </div>
          );
        })}
      </MenuWrap>
      <InfoWrap>
        <InfoBtn onClick={handleClickSearch}>
          <FcSearch />
          빠른검색
        </InfoBtn>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ width: "400px", height: "150px" }}>
          <FormControl
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100px",
              alignItems: "center",
              marginLeft: "5px",
              marginRight: "5px",
            }}>
            <Autocomplete
              id="combo-box-demo"
              options={sideMenu}
              getOptionLabel={(option) => option.label}
              sx={{ width: 300 }}
              onChange={(_, newValue) => {
                if (newValue) {
                  history.push(newValue.path);
                  setAnchorEl(null);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="이동하실 메뉴를 선택해주세요." />
              )}
            />
          </FormControl>
        </Popover>
        <InfoBtn onClick={() => history.replace(routes.myInfo)}>
          <FcReadingEbook />내 정보
        </InfoBtn>
        <InfoBtn onClick={() => dispatch(signOut())}>
          <FcDownload style={{ transform: "rotate(270deg)" }} />
          로그아웃
        </InfoBtn>
      </InfoWrap>
    </HeaderWrap>
  );
};

export default Header;

const HeaderWrap = styled.div`
  position: relative;
  border-bottom: 1px solid #dddddd;
  height: 60px;
  background: #fff;
  padding: 0px 28px;
  display: flex;
  min-width: 1680px;
  max-width: 1920px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 320px;
  cursor: pointer;
  .logo {
    width: 210px;
    height: 50px;
    object-fit: contain;
  }
`;

const MenuWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 1200px;
  justify-content: flex-start;
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  height: 100%;
`;

const InfoBtn = styled.button`
  width: 100px;
  height: 45px;
  border: none;
  background: #fff;
  border-radius: 0.3rem;
  margin: 5px;
  color: #999;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &:hover {
    color: black;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
