import { userActionType } from "./UserActions";
const initUser = {
  init: false,
};
export default function user(state = initUser, action) {
  switch (action.type) {
    case userActionType.init:
      return { ...initUser };
    case userActionType.logout:
      return { ...initUser };
    case userActionType.update:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}
