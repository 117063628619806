import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { actionError } from "../../redux/action/ActionActions";
import moment from "moment";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { formatTime, numberWithCommas } from "../../libs/utils";
import { Checkbox } from "@progress/kendo-react-inputs";
import cloneDeep from "lodash.clonedeep";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-data-query";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { saveAs } from "file-saver";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const SHOP_FEE_STATUS = [
  { label: "미완료", value: "NEW" },
  { label: "정산완료", value: "DONE" },
  { label: "취소", value: "CANCEL" },
  { label: "정산 대상 아님", value: "NOT_SETTLEMENT" },
];

const EDIT_FIELD = "inEdit";

export const PopoverContext = React.createContext({
  onClick: () => null,
});

export default function SettlementShopFee({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const [shopFees, setShopFees] = useState([]);
  const [gridList, setGridList] = useState(shopFees.length > 0 ? shopFees : []);
  const [modifyStatus, setModifyStatus] = useState("DONE");
  const [summary, setSummary] = useState({});
  const DATA_ITEM_KEY = "no";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedState, setSelectedState] = useState({});
  const [selectList, setSelectList] = useState([]);
  const [sort, setSort] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [discountInfo, setDiscountInfo] = useState({
    modalVisible: false,
    id: 0,
    discountKrw: 0,
  });
  const settleStatusCheckedList = location.state?.settleStatus || [];

  const { associateShops } = useSelector((state) => state.shop);

  useEffect(() => {
    setGridList(shopFees);
  }, [shopFees]);

  const sortChange = (event) => {
    setGridList(getShopName(event.sort));
    setSort(event.sort);
  };

  const enterEdit = (dataItem, field) => {
    if (dataItem?.status === "미완료") {
      setDiscountInfo({
        ...discountInfo,
        id: dataItem.id,
        discountKrw: dataItem.discountKrw,
      });
      setCopyData(gridList);
      const newData = [...gridList].map((item) => ({
        ...item,
        [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
      }));
      setGridList(newData);
    }
  };

  const exitEdit = (e) => {
    const row = copyData.find((item) => item.id === discountInfo.id);

    if (row.discountKrw === discountInfo.discountKrw) {
      discountModalClose();
      setGridList(copyData);
      return;
    }
    setDiscountInfo({
      ...discountInfo,
      modalVisible: true,
    });
  };

  const discountModalClose = () => {
    setDiscountInfo({
      id: 0,
      discountKrw: 0,
      modalVisible: false,
    });
  };

  const onClickDiscountSave = () => {
    const param = {
      id: discountInfo.id,
      discountKrw: discountInfo.discountKrw,
    };

    dispatch(loadingStart);
    APIS.postModifyDiscount(param)
      .then(({ data: { success, data, message } }) => {
        discountModalClose();
        if (success) {
          fetchList();
        } else {
          setGridList(copyData);
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const itemChange = useCallback(
    (event) => {
      const inEditID = event.dataItem.id;
      const field = event.field || "";
      const newData = [...gridList].map((item) => {
        return item.id === inEditID
          ? {
              ...item,
              [field]: event.value,
            }
          : item;
      }, []);
      setDiscountInfo({
        ...discountInfo,
        discountKrw: event.value,
      });
      setGridList(newData);
    },
    [gridList]
  );

  const getShopName = (sort) => {
    return orderBy(shopFees, sort);
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const getDefaultState = () => {
    return {
      fromDate:
        new Date().getDate() <= 15
          ? moment().format("YYYY-MM-01")
          : moment().format("YYYY-MM-16"),
      toDate:
        new Date().getDate() <= 15
          ? moment().format("YYYY-MM-15")
          : moment().endOf("month").format("YYYY-MM-DD"),
      sort: "registrationDate",
      dateType: "CREATED",
      settleStatus: ["DONE", "NEW"],
    };
  };

  const fetchList = () => {
    const { fromDate, toDate } = state;
    if (!fromDate || !toDate) {
      dispatch(actionError("기간을 설정해주세요."));
      return;
    }

    const param = { ...state };

    param["fromDate"] =
      new Date(fromDate).getDate() <= 15
        ? moment(fromDate).format("YYYY-MM-01")
        : moment(fromDate).format("YYYY-MM-16");

    param["toDate"] =
      new Date(toDate).getDate() <= 15
        ? moment(toDate).format("YYYY-MM-15")
        : moment(toDate).endOf("month").format("YYYY-MM-DD");

    // if(param["isFeeAsc"] === "option") {
    //   delete param['isFeeAsc'];
    // }

    dispatch(loadingStart);
    APIS.getShopFeeList(objToQueryStr(param))
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopFees(
            data.map((row) => {
              const no = row.id;
              const settlementAmount = row.settleKrw + row.vat;
              let status = "";
              let updatedAt = row.updatedAt;

              switch (row.status) {
                case "NEW":
                  status = "미완료";
                  updatedAt = "";
                  break;
                case "DONE":
                  status = "정산완료";
                  break;
                case "CANCEL":
                  status = "취소";
                  break;
                case "NOT_SETTLEMENT":
                  status = "정산 대상 아님";
                  break;
              }
              return {
                ...row,
                selected: false,
                no,
                status,
                updatedAt,
                settlementAmount,
              };
            })
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));

    APIS.getShopFeeSummary(objToQueryStr(param))
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const onClickExcelDownload = () => {
    const param = { ...state };
    const { fromDate, toDate } = state;
    if (shopFees.length === 0) {
      dispatch(actionError("리스트를 조회해주세요."));
      return;
    } else {
      param["fromDate"] =
        new Date(fromDate).getDate() <= 15
          ? moment(fromDate).format("YYYY-MM-01")
          : moment(fromDate).format("YYYY-MM-16");

      param["toDate"] =
        new Date(toDate).getDate() <= 15
          ? moment(toDate).format("YYYY-MM-15")
          : moment(toDate).endOf("month").format("YYYY-MM-DD");

      for (let key in param) {
        const value = param[key];

        if (typeof value === "string" && !value) {
          delete param[key];
        }
      }
      dispatch(loadingStart);
      APIS.getShopFeeListExcel(objToQueryStr(param))
        .then((response) => {
          const blob = new Blob([response.data]);
          saveAs(
            blob,
            `대리점수수료_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
          );
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleClickSave = () => {
    if (!selectList.length) {
      dispatch(actionError("목록을 선택해주세요."));
      return;
    }
    const data = {
      settleStatus: modifyStatus,
      settleIdList: selectList,
    };
    dispatch(loadingStart);
    APIS.postModifyShopFee(data)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          fetchList();
          setSelectList([]);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const { fromDate, toDate, sumTotal, sumTradeKrw, sumSupply, sumVat } =
    summary;

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "no" ||
      props.field === "fromDate" ||
      props.field === "toDate" ||
      props.field === "shopName" ||
      props.field === "shopCode" ||
      props.field === "shopBusinessNo" ||
      props.field === "shopOwner" ||
      props.field === "bankName" ||
      props.field === "accountNumber" ||
      props.field === "status"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "tradeKrw" ||
      props.field === "supplyFee" ||
      props.field === "vat" ||
      props.field === "penaltyKrw" ||
      props.field === "settlementAmount" ||
      props.field === "settleKrw" ||
      props.field === "totalFee"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    } else if (props.field === "updatedAt") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {formatTime(props.dataItem[props.field], "YYYY-MM-DD HH:mm")}
        </td>
      );
    } else if (props.field === "discountKrw") {
      const dataItem = props.dataItem;
      const cellField = props.field;
      const inEditField = dataItem[EDIT_FIELD || ""];
      const additionalProps =
        cellField && cellField === inEditField
          ? {
              ref: (td) => {
                const input = td && td.querySelector("input");
                const activeElement = document.activeElement;
                if (
                  !input ||
                  !activeElement ||
                  input === activeElement ||
                  !activeElement.contains(input)
                ) {
                  return;
                }
                if (input.type === "checkbox") {
                  input.focus();
                } else {
                  input.select();
                }
              },
            }
          : {
              onClick: () => {
                enterEdit(dataItem, cellField);
              },
            };
      const clonedProps = {
        ...cell.props,
        ...additionalProps,
      };
      return React.cloneElement(
        cell,
        clonedProps,
        cellField && cellField === inEditField ? (
          cell.props.children
        ) : (
          <div
            style={
              dataItem.status === "미완료"
                ? {
                    textAlign: "right",
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }
                : {
                    textAlign: "right",
                  }
            }>
            {numberWithCommas(dataItem.discountKrw)}
          </div>
        )
      );
    }
    return cell;
  };

  const rowRender = (tr, props) => {
    let preventExit = false;
    const dataItem = props.dataItem;
    const cellField = props.field;

    const trProps = {
      ...tr.props,
      onMouseDown: () => {
        preventExit = true;
      },
      onBlur: (e) => {
        if (!preventExit) {
          exitEdit(e);
        }
        preventExit = false;
      },
      onKeyUp: (e) => {
        if (window.event.keyCode === 13) {
          if (!preventExit) {
            exitEdit(e);
          }
          preventExit = false;
        }
      },
    };
    return <tr {...trProps}>{tr.props.children}</tr>;
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  return (
    <>
      <PageLabel>대리점 수수료</PageLabel>
      <Filters
        defaultState={getDefaultState()}
        data={[
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}>
                  <option value="CREATED">{"거래일"}</option>
                  <option value="UPDATED">{"정산일"}</option>
                </select>
                <input
                  value={state?.fromDate || ""}
                  onChange={(e) => {
                    handleValueChange("fromDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.toDate || ""}
                  onChange={(e) => {
                    handleValueChange("toDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            label: "대리점검색",
            type: "input",
            placeholder: "대리점명, 연락처",
            key: "shopKeyword",
            autoFocus: true,
          },
          {
            label: "정산여부",
            type: "render",
            // key: "settleStatus",
            // data: SHOP_FEE_STATUS,
            render: (
              <Flex row>
                {SHOP_FEE_STATUS.map((checkbox, index) => {
                  const { value: checkValue, label } = checkbox;
                  return (
                    <div
                      key={`${checkValue}_${index}`}
                      style={{ marginRight: 10 }}>
                      <Checkbox
                        label={label}
                        checked={settleStatusCheckedList.includes(checkValue)}
                        onChange={(e) => {
                          if (e.value) {
                            handleValueChange("settleStatus", [
                              ...settleStatusCheckedList,
                              checkValue,
                            ]);
                          } else {
                            handleValueChange(
                              "settleStatus",
                              settleStatusCheckedList.filter(
                                (e) => e !== checkValue
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </Flex>
            ),
          },
          {
            label: "정렬순서",
            type: "menu",
            key: "sort",
            data: [
              { label: "수수료 금액 큰 순서", value: "feeDesc" },
              { label: "수수료 금액 작은 순서", value: "feeAsc" },
              { label: "대리점 등록일", value: "registrationDate" },
            ],
            notEmpty: true,
          },
          {
            label: "제휴사",
            type: "menu",
            key: "shopId",
            data: associateShops,
          },
        ]}
        onSearch={fetchList}
        excel={onClickExcelDownload}
      />

      <Flex row className={classes.tableHeader}>
        <Flex>
          <Flex row className={"row-center"}>
            {fromDate && toDate && (
              <Text font={fonts.notoSansKRBold}>
                조회기간 : {formatTime(fromDate, "YYYY.MM.DD")} ~{" "}
                {formatTime(toDate, "YYYY.MM.DD")}
              </Text>
            )}
          </Flex>
          <Flex row className={"row-center"}>
            <Text font={fonts.notoSansKRBold}>
              목록 검색결과 {numberWithCommas(shopFees.length)}
            </Text>
            <Text font={fonts.notoSansKRBold} className={classes.label}>
              총 거래금액 : {numberWithCommas(sumTradeKrw)} 원
            </Text>
            <Text font={fonts.notoSansKRBold} className={classes.label}>
              총 수수료 : {numberWithCommas(sumTotal)} 원
              <Text>
                {" "}
                (공급가 : {numberWithCommas(sumSupply)} 원 / 부가세 :{" "}
                {numberWithCommas(sumVat)} 원)
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <Flex row className={classes.item}>
            상태변경
            <select
              onChange={(e) => {
                setModifyStatus(e.target.value);
              }}
              value={modifyStatus}>
              {SHOP_FEE_STATUS.filter(({ value }) => value !== "NEW").map(
                ({ label, value }) => {
                  return (
                    <option
                      key={`${label}_${value}`}
                      label={label}
                      value={value}
                    />
                  );
                }
              )}
            </select>
            <Button label="저장" onClick={handleClickSave} />
          </Flex>
        </Flex>
      </Flex>
      {discountInfo.modalVisible && (
        <Dialog
          title={"감면액 수정"}
          width={"10%"}
          onClose={() => {
            discountModalClose();
            setGridList(copyData);
          }}>
          <p>수정하시겠습니까?</p>
          <div className="k-dialog-buttongroup k-actions k-hstack k-justify-content-stretch">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => {
                discountModalClose();
                setGridList(copyData);
              }}>
              취소
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={() => {
                onClickDiscountSave();
              }}>
              수정
            </button>
          </div>
        </Dialog>
      )}
      <Grid
        data={gridList.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        resizable={true}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
        sortable={{
          allowUnsort: true,
          mode: "single",
        }}
        sort={sort}
        onSortChange={sortChange}
        rowRender={rowRender}
        cellRender={cellRender}
        onItemChange={itemChange}
        editField={EDIT_FIELD}
        style={{
          padding: "0px 15px 30px 15px",
          borderColor: "white",
        }}>
        <Column field="no" title="NO" width={50} />
        <Column field="fromDate" title="from" width={100} />
        <Column field="toDate" title="to" width={100} />
        <Column
          field={SELECTED_FIELD}
          headerCell={() => {
            return (
              <div style={{ textAlign: "center" }}>
                <Checkbox
                  checked={
                    selectList.length !== 0 &&
                    selectList.length === gridList.length
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    const { ariaChecked } = e.syntheticEvent.target;
                    if (ariaChecked === "true") {
                      setSelectList([]);
                    } else {
                      setSelectList([...gridList.map((item) => item.no)]);
                    }
                  }}
                />
              </div>
            );
          }}
          cell={(props) => {
            const { dataItem } = props;
            const data = dataItem["no"];
            return (
              <td style={{ textAlign: "center" }}>
                {new Date(dataItem["toDate"]) < new Date() &&
                dataItem["shopType"] === "FRANCHISEE" ? (
                  <Checkbox
                    checked={selectList.includes(data) ? true : false}
                    onChange={() => {
                      let parseList = cloneDeep(selectList);
                      if (selectList.includes(data)) {
                        const result = parseList.filter(
                          (item) => item !== data
                        );
                        setSelectList(result);
                      } else {
                        parseList.push(data);
                        setSelectList(parseList);
                      }
                    }}
                  />
                ) : (
                  <></>
                )}
              </td>
            );
          }}
        />
        <Column field="status" title="정산여부" width={100} />
        <Column field="updatedAt" title="처리일시" width={100} />

        <Column field="shopName" title="정산대리점" width={100} />
        <Column field="totalFee" title="수수료" width={100} />
        <Column field="penaltyKrw" title="지체상금" width={100} />
        <Column
          field="discountKrw"
          title="감면액"
          width={150}
          editor="numeric"
        />

        <Column field="settleKrw" title="정산금" width={100} />
        <Column field="vat" title="부가세" width={100} />
        <Column field="settlementAmount" title="합계" width={100} />
        <Column field="tradeKrw" title="거래금액" width={100} />
        <Column field="shopBusinessNo" title="사업자 번호" width={150} />
        <Column field="shopOwner" title="사업주명" width={100} />
        <Column field="bankName" title="은행명" width={100} />
        <Column field="accountNumber" title="정산계좌" width={150} />

        <Column field="shopCode" title="대리점 코드" width={100} />
      </Grid>
    </>
  );
}
const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    "& select": {
      margin: " 0px 15px",
    },
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: "10px 15px",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    width: "auto",
  },
  selectBox: {
    fontSize: 16,
    marginRight: 10,
    width: 157,
  },
});
