const SET_MENU = "quick/SET_MENU";

const status = JSON.parse(localStorage.getItem("kumbangQuick"));
const INIT_STATE = status
  ? {...status, menu: status.menu.map((item) => {
    return {...item, check: false}
  })}
  : {
      enabled: false,
      menu: [
        { id: 0, label: "", path: "", check: false },
        { id: 1, label: "", path: "", check: false },
        { id: 2, label: "", path: "", check: false },
        { id: 3, label: "", path: "", check: false },
        { id: 4, label: "", path: "", check: false },
      ],
    };

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case SET_MENU:
      return { ...action.payload };
    default:
      return state;
  }
}

export const setQuickMenu = (payload) => {
  return { type: SET_MENU, payload };
};
