import {
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { formatTime } from "../../libs/utils";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import Input from "../input/Input";
import Text from "../text/Text";
import Select from "../select/Select";

export default function Filters({
  data = [],
  onSearch,
  excel,
  customExcel,
  customExcelLabel,
  defaultState,
  dateType = "none",
  customDate,
  searchType = "normal",
  changeStyleValue,
}) {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();

  const initValue = () => {
    history.replace({
      pathname: location.pathname,
      state: {
        ...defaultState,
      },
    });
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const handleSearch = () => {
    if (location.state?.page > 0) {
      history.replace({
        pathname: location.pathname,
        state: { ...location.state, page: 0 },
      });
    } else {
      onSearch && onSearch();
    }
  };

  if (!location.state) {
    return (
      <Redirect
        to={{ pathname: history.location.pathname, state: { ...defaultState } }}
      />
    );
  }
  return (
    <Flex
      className={classes.root}
      onKeyPress={(e) => {
        if (e?.nativeEvent?.key === "Enter") {
          handleSearch();
        }
      }}
    >
      <Grid container>
        {data.map((x, i) => {
          const minWidth = x.wrap
            ? "33%"
            : x.fullWidth
            ? "100%"
            : x.renderWidth
            ? x.renderWidth
            : "50%";
          return (
            <Grid
              style={{
                minWidth,
                width: x.fixed ? minWidth : "auto",
              }}
              key={i.toString()}
              className={classes.item}
              item
            >
              {x.label && (
                <Text font={fonts.notoSansKRMedium} className={classes.label}>
                  {x.label}
                </Text>
              )}
              <Flex
                className={changeStyleValue ? changeStyleValue : classes.value}
              >
                {(() => {
                  const value = location.state
                    ? location.state[x.key] || ""
                    : "";
                  switch (x.type) {
                    case "nested-menu":
                      let [nestedMenuKey1, nestedMenuKey2] = x.key.split(",");
                      let nestedMenuValue1 = location.state[nestedMenuKey1];
                      let nestedMenuValue2 = location.state[nestedMenuKey2];

                      return (
                        <Flex row>
                          <select
                            style={{
                              minWidth: 100,
                              fontSize: 14,
                            }}
                            value={nestedMenuValue1}
                            onChange={(e) => {
                              handleValueChange(
                                nestedMenuKey1,
                                e.target.value,
                                {
                                  [nestedMenuKey2]: "",
                                }
                              );
                            }}
                          >
                            <option value={""}>{x.hint || "전체"}</option>
                            {x.data.map((x, i) => {
                              return (
                                <option key={i.toString()} value={x.value}>
                                  {x.label}
                                </option>
                              );
                            })}
                          </select>

                          <select
                            style={{
                              marginLeft: 20,
                              minWidth: 100,
                              fontSize: 14,
                            }}
                            value={nestedMenuValue2}
                            onChange={(e) => {
                              handleValueChange(nestedMenuKey2, e.target.value);
                            }}
                          >
                            <option value={""}>{x.hint || "전체"}</option>
                            {x.data2.map((x, i) => {
                              return (
                                <option key={i.toString()} value={x.value}>
                                  {x.label}
                                </option>
                              );
                            })}
                          </select>
                        </Flex>
                      );

                    case "menu":
                      let options = x.data;

                      if (!x.notEmpty) {
                        options = [
                          { value: "", label: x.hint || "전체" },
                          ...options,
                        ];
                      }

                      return (
                        <Flex row>
                          <Select
                            value={value}
                            onChange={(e) => {
                              handleValueChange(x.key, e.target.value);
                            }}
                            options={options}
                          />
                        </Flex>
                      );
                    case "input-menu":
                      const [input, menu] = value.split(",");
                      return (
                        <Flex row>
                          <Flex row className={classes.inputMenuWrap}>
                            <InputBase
                              style={{ flex: 1, padding: 6 }}
                              value={input}
                              onChange={(e) => {
                                handleValueChange(
                                  x.key,
                                  e.target.value + "," + menu
                                );
                              }}
                            />
                            <span style={{ margin: "6px 12px" }}>{x.unit}</span>
                          </Flex>
                          <select
                            onChange={(e) => {
                              handleValueChange(
                                x.key,
                                input + "," + e.target.value
                              );
                            }}
                            style={{
                              minWidth: 100,
                              marginLeft: 16,
                              fontSize: 14,
                            }}
                          >
                            <option value={""}>{x.hint}</option>
                            {x.data.map((x, i) => {
                              return (
                                <option key={i.toString()} value={x.value}>
                                  {x.label}
                                </option>
                              );
                            })}
                          </select>
                        </Flex>
                      );
                    case "input-range":
                      const start =
                        value instanceof Array ? value[0] || "" : "";
                      const end = value instanceof Array ? value[1] || "" : "";

                      return (
                        <Flex row>
                          <Flex row className={classes.inputMenuWrap}>
                            <Input
                              validation="number"
                              className={classes.inputRangeWrap}
                              value={start}
                              onChange={(value) => {
                                handleValueChange(x.key, [value, end]);
                              }}
                            />
                            <span style={{ margin: "6px 12px" }}>{x.unit}</span>
                          </Flex>
                          <span
                            style={{
                              alignSelf: "center",
                              margin: "0px 5px",
                              width: 30,
                            }}
                          >
                            이상
                          </span>
                          <Flex row className={classes.inputMenuWrap}>
                            <Input
                              validation="number"
                              className={classes.inputRangeWrap}
                              value={end}
                              onChange={(value) => {
                                handleValueChange(x.key, [start, value]);
                              }}
                            />
                            <span style={{ margin: "6px 12px" }}>{x.unit}</span>
                          </Flex>
                          <span
                            style={{
                              alignSelf: "center",
                              margin: "0px 5px",
                              width: 30,
                            }}
                          >
                            이하
                          </span>
                        </Flex>
                      );
                    case "date-range":
                      let [dateKey1, dateKey2] = x.key.split(",");
                      let dateValue1 = location.state?.[dateKey1];
                      let dateValue2 = location.state?.[dateKey2];
                      return (
                        <Flex row>
                          <input
                            value={dateValue1 || ""}
                            onChange={(e) => {
                              handleValueChange(dateKey1, e.target.value);
                            }}
                            type="date"
                            className={classes.datepicker}
                          />
                          <span
                            style={{ alignSelf: "center", margin: "0px 5px" }}
                          >
                            ~
                          </span>
                          <input
                            value={dateValue2 || ""}
                            onChange={(e) => {
                              handleValueChange(dateKey2, e.target.value);
                            }}
                            type="date"
                            className={classes.datepicker}
                          />
                        </Flex>
                      );
                    case "input":
                      return (
                        <Input
                          value={value}
                          onChange={(v) => {
                            handleValueChange(x.key, v);
                          }}
                          placeholder={x.placeholder}
                          className={classes.input}
                          autoFocus={x.autoFocus}
                        />
                      );
                    case "radio":
                      return (
                        <RadioGroup
                          row
                          value={value}
                          onChange={(e) => {
                            handleValueChange(x.key, e.target.value);
                          }}
                        >
                          {x.data?.map((radio, index) => {
                            return (
                              <FormControlLabel
                                key={index.toString()}
                                value={radio.value}
                                control={
                                  <Radio
                                    color="default"
                                    style={{ color: colors.black }}
                                  />
                                }
                                label={radio.label}
                              />
                            );
                          })}
                        </RadioGroup>
                      );
                    case "checkbox":
                      const checkedList = value || [];

                      return (
                        <FormGroup row>
                          {searchType === "all" && (
                            <FormControlLabel
                              key={Math.random()}
                              style={{ marginLeft: 5 }}
                              control={
                                <Checkbox
                                  checked={
                                    checkedList.length === x?.data?.length
                                  }
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      handleValueChange(x.key, []);
                                    } else {
                                      let type = [];
                                      x?.data?.forEach((data) => {
                                        const { value: parseValue } = data;
                                        type.push(parseValue);
                                      });
                                      handleValueChange(x.key, type);
                                    }
                                  }}
                                />
                              }
                              label={"전체"}
                            />
                          )}
                          {x.data?.map((checkbox, index) => {
                            const { value: checkValue, label } = checkbox;
                            return (
                              <FormControlLabel
                                key={index.toString()}
                                style={{ marginLeft: 5 }}
                                control={
                                  <Checkbox
                                    checked={checkedList.includes(checkValue)}
                                    onChange={(e) => {
                                      if (checkValue === "ALL") {
                                        if (!e.target.checked) {
                                          handleValueChange(x.key, []);
                                        } else {
                                          let type = [];
                                          x?.data?.forEach((data) => {
                                            const { value: parseValue } = data;
                                            type.push(parseValue);
                                          });
                                          handleValueChange(x.key, type);
                                        }
                                      } else {
                                        if (e.target.checked) {
                                          handleValueChange(x.key, [
                                            ...checkedList,
                                            checkValue,
                                          ]);
                                        } else {
                                          handleValueChange(
                                            x.key,
                                            checkedList.filter(
                                              (e) => e !== checkValue
                                            )
                                          );
                                        }
                                      }
                                    }}
                                  />
                                }
                                label={label}
                              />
                            );
                          })}
                        </FormGroup>
                      );
                    case "render":
                      return x.render;
                  }
                })()}
              </Flex>
            </Grid>
          );
        })}
      </Grid>
      <Flex row className={classes.buttons}>
        <Button
          onClick={initValue}
          classNameLabel={classes.buttonLabel}
          className={classes.button}
          label="초기화"
        />
        <Button
          onClick={handleSearch}
          classNameLabel={classes.buttonLabel}
          className={classes.button}
          label="검색"
        />
        {customExcelLabel && (
          <Button
            onClick={() => {
              customExcel();
            }}
            className={classes.customExcelButton}
            label={customExcelLabel}
          />
        )}
        {excel && (
          <Button
            onClick={() => {
              excel();
            }}
            className={classes.excelButton}
            label="Excel"
          />
        )}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "14px",
    width: 130,
  },
  root: {
    margin: "5px 50px 5px 50px",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    // border: "0.5px solid black",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "50%",
    padding: "0px 16px",
  },
  value: { flex: 1, padding: "6px 20px" },
  input: { backgroundColor: colors.white },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "14px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    width: "auto",
  },
  buttons: {
    marginTop: "8px",
    alignItems: "center",
    alignSelf: "center",
  },
  button: {
    width: "200px",
    flex: 3,
    "&:first-child": {
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.23)",
      marginRight: "20px",
      "& span": {
        color: "#000 !important",
      },
    },
    "& span": {
      color: "#fff !important",
    },

    backgroundColor: "black",
  },
  buttonLabel: {
    color: "black",
    fontSize: 14,
  },
  excelButton: {
    flex: 1,
    marginLeft: "15px",
    backgroundColor: "#757575",
    fontSize: 14,
  },
  customExcelButton: {
    flex: 1,
    minWidth: "fit-content",
    marginLeft: "15px",
    backgroundColor: "#444444",
    fontSize: 14,
  },
  inputMenuWrap: {
    flex: 1,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,0.23)",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputRangeWrap: { border: "none" },
});
