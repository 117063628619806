import { Button } from "@progress/kendo-react-buttons";
import React from "react";

const KendoButton = ({
  children,
  style = {},
  label = "",
  fillMode = "outline",
  ...rest
}) => {
  return (
    <Button
      style={{ width: 100, ...style }}
      tabIndex={-1}
      rounded="small"
      fillMode={fillMode}
      {...rest}
    >
      {label}
      {children}
    </Button>
  );
};

export default KendoButton;
