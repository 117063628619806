import {
  ButtonBase,
  // InputBase,
  makeStyles,
  Paper,
  CardMedia,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import BasicLayout from "../../layouts/basic/BasicLayout";
import images from "../../libs/images";
import Button from "../../components/button/Button";
import { actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
import { objToQueryStr } from "../../services/utils";
import moment from "moment";
import { openImageView } from "../../redux/image-viewer/ImageViewerReducer";
import { TextArea } from "@progress/kendo-react-inputs";

export default function ServiceInquiryDetail({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation({});
  const { state = {} } = location;
  const [message, setMessage] = useState("");
  const [qnaImage, setQnaImage] = useState("");
  const [pastConversation, setPastConversation] = useState({});
  const messagesEndRef = useRef(null);

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const validationData = () => {
    if (!state.content || state.content === "") {
      return "내용을 입력하여 주세요";
    }
    return "";
  };

  const reformData = (data) => {
    const itemData = [...data] || [];
    const changePastConversation = itemData.map((item) => {
      return { ...item, day: item?.["createdAt"].split(" ")[0] };
    });
    const dayList = changePastConversation.map((item) => {
      return { day: item?.day };
    });

    const deduplicationDay = dayList.filter((dayName, idx, arr) => {
      return arr.findIndex((item) => item.day === dayName.day) === idx;
    });

    let parseData = {};
    let arrayData = [];

    deduplicationDay.forEach((day) => {
      // day 날짜 필터링
      changePastConversation.forEach((item) => {
        if (item.day === day.day) {
          arrayData.push(item);
          parseData[day.day] = [...arrayData];
        }
      });
      arrayData = [];
    });

    return parseData;
  };

  const handleClickRegister = () => {
    const json = JSON.stringify({
      ...state,
      userSocialId: state.userSocialId,
      userId: state.id,
    });

    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("qna", blob);
    formData.append(
      "image",
      Object.keys(qnaImage).length !== 0 ? qnaImage : ""
    );

    dispatch(loadingStart);
    APIS.postQnaRegister(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "문의 답변을 등록하지 못하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          // dispatch(actionOpen("문의 답변 되었습니다.", null, null, null, true));
          setPastConversation(reformData(data));
          setMessage("");
          setQnaImage("");
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };
  const onInputClick = (event) => {
    event.target.value = "";
  };

  const onChangeImage = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    const _URL = window.URL || window.webkitURL;
    const i = new Image();
    i.src = _URL.createObjectURL(file);

    i.onload = () => {
      if (i.width !== 0 && i.height !== 0) {
        if (file) {
          const f = new FileReader();
          f.onload = () => {
            file.uri = f.result;
            if (name === "qnaImage") {
              setQnaImage(file);
              handleValueChange("image", file);
            }
          };
          f.readAsDataURL(file);
          e.target.value = "";
        }
      }
    };
  };

  useEffect(() => {
    if (!isNaN(state.id)) {
      fetchItem();
    }
  }, [state.id]);

  const fetchItem = () => {
    dispatch(loadingStart);
    const paramQuery = objToQueryStr({
      userSocialId: state.userSocialId,
      userId: state.id,
    });
    APIS.getQnaDetail(paramQuery)
      .then(({ data: { success, data } }) => {
        if (success) {
          setPastConversation(reformData(data));
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView();
  };
  useEffect(scrollToBottom, [pastConversation]);

  const closeQna = () => {
    dispatch(loadingStart);
    const paramQuery = objToQueryStr({
      userId: state.id,
      userSocialId: state.userSocialId,
    });
    APIS.putCloseQna(paramQuery)
      .then(({ data: { success, data } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "해당 문의가 종료되었습니다.",
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  return (
    <>
      <PageLabel>고객문의 상세</PageLabel>
      <BasicLayout center className={classes.root}>
        <Paper className={classes.main}>
          <Flex className={classes.scroll}>
            {Object.keys(pastConversation).length
              ? Object.keys(pastConversation)?.map((day, index) => {
                  if (day) {
                    return (
                      <React.Fragment key={index}>
                        <Text className={classes.day}>{day}</Text>
                        {pastConversation[day]?.map((item) => {
                          const defaultProps = {
                            ...item,
                            userType:
                              item.qnAWriter === "USER" ? "user" : "manager",
                            messageType:
                              item.contentType === "IMAGE" ? "image" : "text",
                          };
                          return (
                            <ChatItem key={Math.random()} {...defaultProps} />
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                })
              : ""}
            <div ref={messagesEndRef} />
          </Flex>
        </Paper>
        <Flex className={classes.form} row>
          <Flex className={classes.formInput}>
            <input
              accept="image/*"
              type="file"
              id="rasied-image-file"
              name="qnaImage"
              style={{ display: "none" }}
              onChange={onChangeImage}
              onClick={onInputClick}
            />
            <label htmlFor="rasied-image-file" className={classes.imageLabel}>
              <img src={images.plus} className={classes.imageButton} />
            </label>
            {qnaImage ? (
              <div
                className={classes.df}
                style={{ flexDirection: "column", paddingTop: 5 }}
              >
                <div className={classes.inputImage}>
                  <CardMedia
                    component="img"
                    image={
                      qnaImage.path
                        ? consts.s3BaseUrl + "/" + qnaImage.path
                        : qnaImage.uri
                    }
                    width="100"
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <Grid className={classes.dfcs}>
                  <Button
                    className={classes.buttonDelete}
                    onClick={() => {
                      setQnaImage("");
                    }}
                    size="small"
                    label="삭제"
                  ></Button>
                </Grid>
              </div>
            ) : (
              // <InputBase
              //   multiline
              //   style={{
              //     flex: 1,
              //     border: "1px solid #f5f5f5",
              //     // overflowY: "scroll",
              //   }}
              //   inputProps={{
              //     style: {
              //       maxHeight: "70px",
              //     },
              //   }}
              //   value={message}
              //   onChange={(e) => {
              //     setMessage(e.target.value);
              //     handleValueChange("content", e.target.value);
              //   }}
              // />
              <TextArea
                rows={4}
                value={message}
                style={{
                  flex: 1,
                  maxWidth: 570,
                }}
                onChange={(e) => {
                  setMessage(e.value);
                  handleValueChange("content", e.value);
                }}
              />
            )}
            <Button
              onClick={() => {
                if (!state.image) {
                  const errorMessage = validationData();
                  if (errorMessage) {
                    dispatch(actionOpen(errorMessage, null, null, null, true));
                    return false;
                  }
                }
                dispatch(
                  actionOpen(
                    "해당 내용으로 답변을 올리시겠습니까?",
                    handleClickRegister,
                    "취소",
                    "등록"
                  )
                );
              }}
              className={
                qnaImage ? classes.buttonRegisterImage : classes.button
              }
              label="등록"
            ></Button>
          </Flex>
        </Flex>
      </BasicLayout>
      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.buttonList}>
          목록
        </ButtonBase>
        <ButtonBase onClick={closeQna} className={classes.button2}>
          문의 종료
        </ButtonBase>
      </Flex>
    </>
  );
}

const ChatItem = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const formatDate = (date) => {
    const time = moment(date).format("HH:mm");
    const getTime = time.substring(0, 2);
    const intTime = parseInt(getTime);
    let str = "";
    let cvHour = 0;
    if (intTime < 12) {
      str = "오전";
    } else {
      str = "오후";
    }
    if (intTime === 12) {
      cvHour = intTime;
    } else {
      cvHour = intTime % 12;
    }
    const res = str + ("0" + cvHour).slice(-2) + time.slice(-3);
    return res;
  };
  return (
    <>
      <Text
        className={
          props.userType === "user" ? classes.userName : classes.managerName
        }
      >
        {props.userType === "user" ? (
          <span
            style={{
              color: props.userId && props.userSocialId ? "blue" : "black",
              cursor: props.userId && props.userSocialId ? "default" : "auto",
              textDecoration:
                props.userId && props.userSocialId ? "underline" : "none",
            }}
            onClick={() => {
              if (props.userId && props.userSocialId) {
                history.push(`/member/normal/detail/${props.userId}`);
              } else {
                return;
              }
            }}
          >
            {props?.userName || "-"}
          </span>
        ) : (
          <span>{props?.managerName || "-"}</span>
        )}
      </Text>
      <Flex
        className={
          props.userType === "user"
            ? classes.userMessage
            : classes.managerMessage
        }
      >
        <div>{formatDate(props.createdAt)}</div>

        {props.messageType === "image" ? (
          <div className={classes.df}>
            <div className={classes.dfcc}>
              <CardMedia
                component="img"
                image={props.content ? consts.s3BaseUrl + props.content : ""}
                width="100"
                onClick={() => {
                  dispatch(
                    openImageView({
                      images: [
                        {
                          src: props.content.replace("/", ""),
                          title: props.content,
                        },
                      ],
                    })
                  );
                }}
                style={{ borderRadius: 10 }}
              />
            </div>
          </div>
        ) : (
          <Text
            className={
              props.userType === "user"
                ? classes.userChatContent
                : classes.managerChatContent
            }
          >
            {props.content.split("").map((x, i) => {
              if (x === "\n") {
                return <br key={i.toString()} />;
              } else {
                return x;
              }
            })}
          </Text>
        )}
      </Flex>
    </>
  );
};

const useStyle = makeStyles({
  buttons: { alignSelf: "center", marginTop: 20 },
  buttonList: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  buttonDelete: {
    height: 26,
    width: 70,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 10,
  },
  buttonRegisterImage: {
    fontFamily: fonts.notoSansKRBold,
    padding: "10px 30px",
    marginLeft: "auto",
  },
  button: {
    fontFamily: fonts.notoSansKRBold,
    padding: "10px 30px",
  },
  scroll: {
    height: 620,
    width: 700,
    padding: "0px 20px 10px 20px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: 10 },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2f3542",
      borderRadius: 10,
      backgroundClip: "padding-box",
      border: "2px solid transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "grey",
      borderRadius: 10,
      boxShadow: "inset 0px 0px 5px white",
    },
  },
  userName: {
    marginTop: 20,
    textAlign: "left",
  },
  userChatContent: {
    backgroundColor: "#dedede",
    fontSize: 16,
    color: "#000",
    justifyContent: "center",
    "word-break": "break-all",
    maxWidth: "67%",
    padding: 10,
    width: "fit-content",
    borderRadius: 20,
  },
  managerName: {
    marginTop: 20,
    textAlign: "right",
  },
  managerChatContent: {
    backgroundColor: "#0078fe",
    fontSize: 16,
    color: "#fff",
    "word-break": "break-all",
    maxWidth: "67%",
    padding: 10,
    width: "fit-content",
    borderRadius: 20,
  },
  main: {
    backgroundColor: "rgb(239,243,247)",
    boxSizing: "border-box",
    zIndex: 10,
    display: "flex !important",
    flexDirection: "column !important",
    alignItems: "center",
    justifyContent: "end",
    width: 700,
    height: 620,
  },
  form: {
    alignSelf: "center",
    width: 700,
    boxSizing: "border-box",
    zIndex: 10,
    boxShadow: "inset 0px 0px 5px white",
  },
  formInput: {
    flex: 1,
    flexDirection: "revert !important",
    border: "1px solid lightgrey",
  },
  root: {
    alignItems: "center",
    padding: 30,
    paddingBottom: 15,
    backgroundColor: "#fff",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
    width: "150px",
  },
  dfcs: {
    alignSelf: "center",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  inputImage: {
    display: "flex",
    alignItems: "center",
    width: 140,
    height: 124,
    alignSelf: "center",
  },
  imageLabel: {
    alignSelf: "center",
    width: 40,
    textAlignLast: "center",
  },
  imageButton: {
    width: 24,
    height: 24,
    border: "2px solid #ada4a4",
    borderRadius: 5,
    padding: 3,
  },
  managerMessage: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  userMessage: {
    display: "flex",
    flexDirection: "row-reverse !important",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  day: {
    fontWeight: "bold",
    alignSelf: "center",
    backgroundColor: "#9eaec1",
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
    textAlign: "center",
    width: "fit-content",
    borderRadius: 20,
  },
});
