import { useHistory, useLocation } from "react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionError } from "redux/action/ActionActions";
import { objToQueryStr, formatTime } from "services/utils";

import routes from "libs/routes";
import * as APIS from "libs/apis";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core";
import { getDefaultPageSize, STATUS_CODE } from "libs/consts";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

import Flex from "components/flex/Flex";
import Input from "components/input/Input";
import Select from "components/select/Select";
import DataTable from "components/table/Table";
import Filters from "components/filters/Filters";
import PageLabel from "components/page-label/PageLabel";

export default function MemberNormal({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state || {};
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  const [shops, setShops] = useState([]);
  const [usingShops, setUsingShops] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    APIS.getRecommendShops().then(({ data: { success, data } }) => {
      if (success) {
        // 추천대리점
        setShops([
          ...data.map(({ shopId, shopName }) => ({
            value: shopId,
            label: shopName,
          })),
          { value: -1, label: "없음" },
        ]);
        // 이용대리점
        setUsingShops([
          ...data.map(({ shopId, shopName }) => ({
            value: shopId,
            label: shopName,
          })),
          { value: -1, label: "없음" },
        ]);
      }
    });
  }, []);
  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    const param = { ...state };
    if (param.startDate) {
      param.startDate = `${param.startDate}T00:00:00`;
    }
    if (param.endDate) {
      param.endDate = `${param.endDate}T23:59:59`;
    }

    // 이상, 이하
    const { amountType, more, less } = param;
    param["amountAssetType"] = amountType;
    if (more) {
      param[`startAssetAmount`] = more;
    }

    if (less) {
      param[`endAssetAmount`] = less;
    }

    delete param.more;
    delete param.less;
    delete param.amountType;

    if (!param.startAssetAmount && !param.endAssetAmount) {
      delete param["amountAssetType"];
    }
    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }

    dispatch(loadingStart);
    APIS.searchAccount(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setUsers(
            data.content.map((item) => {
              const { status, userType } = item;
              return {
                ...item,
                status: STATUS_CODE[status] || status,
                userType: userType
                  ?.replace("PERSON", "개인")
                  ?.replace("BUSINESS", "기업"),
              };
            })
          );
          setTotalCount(data.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const onClickExcelDownload = () => {
    const param = { ...state };
    if (param.startDate) {
      param.startDate = `${param.startDate}T00:00:00`;
    }
    if (param.endDate) {
      param.endDate = `${param.endDate}T23:59:59`;
    }

    // 이상, 이하
    const { amountType, more, less } = param;
    param["amountAssetType"] = amountType;
    if (more) {
      param[`startAssetAmount`] = more;
    }

    if (less) {
      param[`endAssetAmount`] = less;
    }

    delete param.more;
    delete param.less;
    delete param.amountType;
    delete param.page;
    delete param.size;

    if (!param.startAssetAmount && !param.endAssetAmount) {
      delete param["amountAssetType"];
    }
    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.searchMemberExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `일반회원_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
    // axios({
    //   method: "GET",
    //   url: `/api/v1/manager/search/excel${objToQueryStr(param)}`,
    //   responseType: "blob",
    // }).then((response) => {
    //   const blob = new Blob([response.data]);
    //   saveAs(blob, `일반회원_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`);
    // });
  };

  const memoizedUsers = useMemo(() => {
    return users.map((user) => {
      let virtualBankAccount = "",
        bankAccount = "";

      // 가상계좌
      if (user.virtualBankAccountName && user.virtualBankAccountNumber) {
        virtualBankAccount = `${user.virtualBankAccountName} ${user.virtualBankAccountNumber}`;
      }

      // 출금계좌
      if (user.bankAccountName && user.bankAccountNumber) {
        bankAccount = `${user.bankAccountName} ${user.bankAccountNumber}`;
      }
      return {
        ...user,
        virtualBankAccount,
        bankAccount,
      };
    });
  }, [users]);

  const handleClickRow = useCallback((item) => {
    history.push(`${routes.memberDetail}/${item.id}`);
  }, []);

  return (
    <>
      <PageLabel>일반회원</PageLabel>
      <Filters
        defaultState={{ amountType: "GOLD", ...getDefaultPageSize() }}
        onSearch={fetchList}
        excel={onClickExcelDownload}
        data={[
          { label: "가입일", type: "date-range", key: "startDate,endDate" },
          {
            label: "검색",
            type: "input",
            placeholder: "이름, 휴대폰, 고객번호, AD-ID, GA Instance",
            key: "keyword",
            autoFocus: true,
          },

          {
            label: "보유량",
            type: "render",
            render: <AmountFilter />,
          },
          isKorda
            ? {
                label: "이용대리점",
                type: "menu",
                key: "usingShop",
                renderWidth: "10%",
                data: usingShops,
              }
            : {},
          isKorda
            ? {
                label: "추천대리점",
                type: "menu",
                key: "recommendShop",
                renderWidth: "10%",
                data: shops,
              }
            : {},
          {
            label: "상태",
            type: "radio",
            key: "status",
            data: [
              { label: "전체", value: "" },
              { label: "활성", value: "ACTIVE" },
              { label: "사용정지", value: "LOCK" },
              { label: "휴먼", value: "DORMANT" },
              { label: "탈퇴", value: "LEAVE" },
            ],
          },
          {
            label: "구분",
            type: "radio",
            key: "userType",
            data: [
              { label: "전체", value: "" },
              { label: "개인", value: "PERSON" },
              { label: "기업", value: "BUSINESS" },
            ],
          },
          {
            label: "가입경로",
            type: "radio",
            key: "registerPath",
            data: [
              { label: "전체", value: "" },
              { label: "앱", value: "APP" },
              { label: "쇼핑몰", value: "MALL" },
              { label: "K-Bank", value: "K_BANK_MALL" },
            ],
          },
        ]}
      />
      <MemoizedDataTable
        data={memoizedUsers}
        totalCount={totalCount}
        onClick={handleClickRow}
      />
    </>
  );
}

const AmountFilter = () => {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const { amountType, more = "", less = "" } = state;

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  return (
    <Flex row>
      <Select
        options={[
          { value: "GOLD", label: "금" },
          { value: "SILVER", label: "은" },
          { value: "POINT", label: "포인트" },
          { value: "KRW", label: "KRW" },
        ]}
        value={amountType}
        onChange={(e) => {
          handleChangeValue("amountType", e.target.value);
        }}
        style={{ marginRight: 5 }}
      />

      <Input
        white
        validation="number"
        value={more}
        onChange={(value) => {
          handleChangeValue("more", value);
        }}
        className={classes.numberInput}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}
      >
        이상
      </span>
      <Input
        white
        validation="number"
        value={less}
        onChange={(value) => {
          handleChangeValue("less", value);
        }}
        className={classes.numberInput}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}
      >
        이하
      </span>
    </Flex>
  );
};

const MemoizedDataTable = React.memo(({ data, totalCount, onClick }) => {
  return (
    <DataTable
      name="일반회원"
      data={data}
      totalCount={totalCount}
      columns={[
        { label: "가입일", type: "datetime", key: "createdAt" },
        { label: "이름", key: "name", length: 5 },
        { label: "고객번호", key: "id" },
        { label: "휴대폰", key: "phone" },
        {
          label: "이용대리점",
          key: "usingShopName",
        },
        {
          label: "추천대리점",
          key: "recommendShopName",
        },

        { label: "구분", key: "userType" },
        { label: "금(GOLD)", type: "number", key: "gold" },
        { label: "은(SILVER)", type: "number", key: "silver" },
        { label: "포인트(POINT)", type: "number", key: "point" },
        { label: "보유원화(KRW)", type: "number", key: "krw" },
        { label: "계정상태", key: "status" },
        { label: "가입경로", key: "registerPath" },
      ]}
      onClick={onClick}
      contextMenuVisible={false}
      rowClassName="pointer"
    />
  );
});

const useStyle = makeStyles({
  numberInput: {
    width: 140,
  },
});
