import {
  Button,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionOpen } from "../../redux/action/ActionActions";

export default function SettingPolicy4({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch(actionOpen("수정하시겠습니까?", () => {}, "취소", "저장"));
  };
  return (
    <Flex>
      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>출금수수료 일괄 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th></th>
          <th>수수료</th>
        </tr>

        <tr>
          <td className={classes.tdb}>일반회원</td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
        <tr>
          <td className={classes.tdb}>대리점회원</td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
      </table>
      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>최소 출금 단위 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th></th>
          <th>최소 출금 금액</th>
        </tr>

        <tr>
          <td className={classes.tdb}>일반회원</td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
        <tr>
          <td className={classes.tdb}>대리점회원</td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
      </table>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tdb: {
    backgroundColor: "#f5f5f5",
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    width: "80%",
    marginLeft: "10%",
    paddingRight: 10,
  },
  input: {
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
});
