import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function SettlementService({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  return (
    <>
      <PageLabel>외부서비스 사용내역</PageLabel>

      <Filters
        data={[
          {
            label: "조회기간",
            type: "date-range",
          },
          {
            label: "서비스",
            wrap: true,
            data: [{ label: "전체" }, { label: "나이스" }, { label: "배읔" }],
            type: "menu",
          },
        ]}
      />

      <RadioGroup
        row
        style={{
          margin: "0px 50px",
          alignSelf: "flex-end",
        }}
      >
        <FormControlLabel
          label="최근 순서로"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
        <FormControlLabel
          label="오래된 순서로"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
      </RadioGroup>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th>서비스</th>
          <th>NICE</th>
          <th>세틀뱅크(가상계좌 입금)</th>
          <th>다이렉트 샌드</th>
          <th>세틀뱅크(본인계좌 인증)</th>
          <th>알리고</th>
        </tr>

        <tr>
          <td style={{ backgroundColor: "#f5f5f5" }}>호출횟수</td>
          <td>120</td>
          <td>5</td>
          <td>75</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: "#f5f5f5" }}>총발생비용</td>
          <td>120</td>
          <td>5</td>
          <td>75</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: "#f5f5f5" }}>부가가치세</td>
          <td>120</td>
          <td>5</td>
          <td>75</td>
          <td>10</td>
          <td>10</td>
        </tr>
      </table>
      <DataTable
        data={[{}, {}]}
        onExcelDownload={() => {}}
        columns={[
          {
            label: "날짜",
          },
          {
            label: "서비스",
          },
          {
            label: "실행수",
          },
          {
            label: "요금",
          },
          {
            label: "상태",
          },
        ]}
      />
    </>
  );
}

const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },

  table1: {
    marginTop: 30,
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
});
