import React from "react";
import { useHistory } from "react-router";
import Filters from "../../components/filters/Filters";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";

export default function ServiceReview({}) {
  const history = useHistory();
  return (
    <>
      <PageLabel
        add={{
          title: "등록하기",
          onClick: () => {
            history.push(routes.serviceReviewDetail);
          },
        }}
      >
        거래후기 관리
      </PageLabel>

      <Filters
        data={[
          { label: "등록(수정)일", type: "date-range" },
          {
            label: "게재상태",
            type: "checkbox",
            data: [
              {
                label: "노출중",
              },
              { label: "비노출" },
              { label: "메인노출" },
            ],
          },
        ]}
      />
      <DataTable
        onClick={() => {
          history.push(routes.serviceReviewDetail);
        }}
        data={[{}, {}, {}, {}, {}]}
        columns={[
          { label: "이름" },
          { label: "분류" },
          { label: "내용" },
          { label: "평점" },
          { label: "등록일" },
          { label: "게재상태" },
        ]}
      />
    </>
  );
}
