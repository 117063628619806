import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Flex from "components/flex/Flex";
import Button from "components/button/Button";
import DataTable from "components/table/Table";
import Filters from "components/filters/Filters";
import fonts from "libs/fonts";
import routes from "libs/routes";

export default function AppraisalStock({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  return (
    <>
      <span className={classes.label}>현재 재고</span>
      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th></th>
          <th>교환완료</th>
          <th>본사입고</th>
          <th>본사미입고</th>
          <th>
            실물인출
            <br />
            <span style={{ color: "red" }}>(인출확정)</span>
          </th>
          <th>현재보유</th>
        </tr>

        <tr>
          <td>금</td>
          <td>15,000,000g</td>
          <td>13,000,000g</td>
          <td>2,000,000g</td>
          <td>3,000,000g</td>
          <td>10,000,000g</td>
        </tr>
        <tr>
          <td>은</td>
          <td>15,000,000g</td>
          <td>13,000,000g</td>
          <td>2,000,000g</td>
          <td>3,000,000g</td>
          <td>10,000,000g</td>
        </tr>
      </table>
      <span className={classes.label}>입고 현황</span>

      <Filters
        data={[
          {
            label: "조회기간",
            wrap: true,
            type: "date-range",
          },
          {
            label: "회원검색",
            wrap: true,
            placeholder: "고객명, 연락처, 고객번호",
            type: "input",
          },
          {
            label: "매장검색",
            wrap: true,
            placeholder: "매장명, 연락처",
            type: "input",
          },
          {
            label: "제품",
            data: [{ label: "전체" }, { label: "금" }, { label: "은" }],
            type: "menu",
          },
          {
            label: "접수",
            data: [
              { label: "전체" },
              { label: "택배" },
              { label: "방문예약" },
              { label: "현장감정" },
              { label: "딜러감정" },
            ],
            type: "menu",
            wrap: true,
          },
          {
            label: "재고상태",
            data: [{ label: "전체" }, { label: "입고" }, { label: "미입고" }],
            type: "menu",
            wrap: true,
          },
        ]}
      />

      <RadioGroup
        row
        style={{
          margin: "0px 50px",
          alignSelf: "flex-end",
        }}
      >
        <FormControlLabel
          label="최근 순서로"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
        <FormControlLabel
          label="오래된 순서로"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
      </RadioGroup>

      <DataTable
        optionComponent={
          <Flex row className="row-center">
            <span>상태변경</span>
            <select style={{ marginLeft: 10 }}>
              <option label="전체" />
              <option label="입고" />
              <option label="미입고" />
            </select>
            <Button label="저장" style={{ marginLeft: 10 }} />
          </Flex>
        }
        data={[{}, {}]}
        columns={[
          { label: "접수번호" },
          { label: "교환일시" },
          { label: "분류" },
          { label: "감정 정보" },
          { label: "고객" },
          { label: "접수 방법" },
          { label: "진행상태" },
          { label: "비고" },
          { label: "재고상태" },
          { label: "체크박스" },
        ]}
        onExcelDownload={() => {}}
        onClick={() => {
          history.push(
            routes.appraisalStockDetail,

            {
              id: 1,
            }
          );
        }}
      />
    </>
  );
}

const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 30,
    marginBottom: 10,
  },
  table1: {
    width: "80%",
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
});
