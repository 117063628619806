import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import SettingHolidayPopup from "./SettingHolidayPopup";
import * as APIS from "../../libs/apis";
import GridTable, { CenterCell } from "components/gird-table/GridTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { formatTime } from "libs/utils";
import moment from "moment-timezone";

export default function SettingHoliday({}) {
  const history = useHistory();
  const locationState = history.location.state || {};

  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dateList, setDateList] = useState([]);
  const [popup, setPopup] = useState("");

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const formatDate = (dateString) => {
    return new Date(formatTime(dateString));
  };

  const handleClickRow = (dataItem) => {
    let date = new Date();
    let year = date.getFullYear();
    let obj = { ...dataItem };
    delete obj.no;
    delete obj.selected;
    obj.date = {
      start:
        obj.type === "CUSTOM"
          ? formatDate(obj.startDate)
          : formatDate(`${year}${obj.startEveryDay}`),
      end:
        obj.type === "CUSTOM"
          ? formatDate(obj.endDate)
          : formatDate(`${year}${obj.endEveryDay}`),
    };
    setPopup("EDIT");
    handleChangeLocationState({ ...obj });
    // dispatch(actionOpen("수정하시겠s습니까?", () => {}, "취소", "저장"));
  };

  const dateCell = (props, type) => {
    let dataItem = props.dataItem;
    let date = new Date();
    let year = date.getFullYear();
    let property =
      dataItem?.type === "CUSTOM" ? `${type}Date` : `${type}EveryDay`;
    let dateFormat =
      dataItem?.type === "CUSTOM" ? "YYYY-MM-DD" : "매년 MM월 DD일";
    return (
      <CenterCell {...props}>
        {formatTime(
          dataItem?.type === "CUSTOM"
            ? dataItem[property]
            : `${year}${dataItem[property]}`,
          dateFormat
        )}
      </CenterCell>
    );
  };

  const getHoliday = () => {
    APIS.getSettingHoliday().then(
      ({ data: { success, data = [], message } }) => {
        if (success) {
          let date = new Date();
          let year = date.getFullYear();
          setDateList(
            data.map((item, i) => {
              return {
                ...item,
                no: data.length - (data.length - 1 - i),
              };
            })
          );
        } else {
          dispatch(actionError(message));
        }
      }
    );
  };

  useEffect(() => {
    getHoliday();
  }, []);

  return (
    <Flex style={{ padding: "10px" }}>
      {popup !== "" && (
        <SettingHolidayPopup
          popupType={popup}
          onClose={() => {
            setPopup("");
            getHoliday();
          }}
        />
      )}

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>공휴일 설정</span>
        <Flex row className="row-center">
          {/* <Button variant="outlined" title="수정" onClick={handleSave}>
            삭제
          </Button> */}
          <Button
            style={{ backgroundColor: "#000", marginLeft: 10, color: "#fff" }}
            variant="contained"
            title="수정"
            onClick={() => {
              setPopup("CREATE");
            }}>
            등록
          </Button>
        </Flex>
      </Flex>
      <GridTable
        className={classes.gridTable}
        name="holiday_table"
        data={dateList}
        rowRender={(tr, props) => {
          const trProps = {
            style: !props.dataItem.active
              ? { backgroundColor: "rgba(0,0,0,0.4)" }
              : {},
          };
          return React.cloneElement(tr, { ...trProps }, tr.props.children);
        }}>
        <Column className="td-c" title="번호" field="no" />
        <Column
          title="제목"
          field="title"
          cell={(props) => {
            return (
              <CenterCell
                {...props}
                className={classes.dateLabel}
                onClick={() => {
                  handleClickRow(props.dataItem);
                }}>
                {props.dataItem.title}
              </CenterCell>
            );
          }}
        />
        <Column
          className="td-c"
          title="시작"
          cell={(props) => {
            return dateCell(props, "start");
          }}
        />
        <Column
          className="td-c"
          title="종료"
          cell={(props) => {
            return dateCell(props, "end");
          }}
        />
        <Column className="td-c" title="종류" field="typeKorName" />
        <Column
          className="td-c"
          title="활성여부"
          field="active"
          cell={(props) => {
            let dataItem = props.dataItem;
            return (
              <CenterCell {...props}>
                {dataItem?.active ? "활성" : "비활성"}
              </CenterCell>
            );
          }}
        />
        <Column
          className="td-c"
          title="등록일"
          field="createdAt"
          cell={(props) => {
            let dataItem = props.dataItem;
            return (
              <CenterCell {...props}>
                {dataItem?.createdAt
                  ? formatTime(dataItem?.createdAt, "YYYY-MM-DD HH:mm")
                  : "-"}
              </CenterCell>
            );
          }}
        />
        <Column
          className="td-c"
          title="수정일"
          field="updatedAt"
          cell={(props) => {
            let dataItem = props.dataItem;
            return (
              <CenterCell {...props}>
                {dataItem?.updatedAt
                  ? formatTime(dataItem?.updatedAt, "YYYY-MM-DD HH:mm")
                  : "-"}
              </CenterCell>
            );
          }}
        />
        <Column
          className="td-c"
          title="관리자"
          field="managerName"
          cell={(props) => {
            let dataItem = props.dataItem;
            return (
              <CenterCell {...props}>
                {dataItem?.managerName ? dataItem?.managerName : "-"}
              </CenterCell>
            );
          }}
        />
      </GridTable>
      {/* <SimpleTable
        fullWidth
        columns={[
          {
            label: <Checkbox />,
            render: (x) => <Checkbox />,
          },
          {
            label: "제목",
          },
          {
            label: "시작",
          },
          {
            label: "종료",
          },
          {
            label: "종류",
          },
          {
            label: "활성여부",
          },
          {
            label: "등록일",
          },
          {
            label: "수정일",
          },
        ]}
        data={[{}, {}, {}]}
      /> */}
    </Flex>
  );
}

const useStyle = makeStyles({
  gridTable: {
    width: "100%",
    height: "800px",
    overflowY: "scroll",
  },
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  dateLabel: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",
  },
  labelWrap: {
    margin: "0px 20px",
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tdb: {
    backgroundColor: "#f5f5f5",
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    width: "80%",
    marginLeft: "10%",
    backgroundColor: "#fff",
    paddingRight: 10,
  },
  input: {
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
});
