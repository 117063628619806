import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { Button } from "@progress/kendo-react-buttons";

import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { openImageView } from "../../redux/image-viewer/ImageViewerReducer";
import * as APIS from "../../libs/apis";
import consts from "../../libs/consts";

function ProductNotice() {
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const [imageFile, setImageFile] = useState("");

  useEffect(() => {
    dispatch(loadingStart);

    APIS.getMallImage()
      .then((res) => {
        res.data.success && setImageFile(res.data.data);
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  const handleImgButtonClick = (e) => {
    fileInput.current.click();
  };

  const handleImgChange = (e) => {
    const file = e.target.files[0];

    // 이미지 타입 체크
    if (file.type && !file.type.startsWith("image/")) {
      dispatch(actionError("이미지 파일을 올려주세요"));
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      // 파일 사이즈 제한 걸기
      // if (img.width !== 110 || img.height !== 182) {
      //   dispatch(actionError("사이즈를 x*y로 맞춰주세요"));
      //   return;
      // }

      const formData = new FormData();
      formData.append("image", file);

      // 필요한 API 호출
      dispatch(loadingStart);
      APIS.postMallImage(formData)
        .then((res) => {
          res.data.success && setImageFile(res.data.data);
        })
        .finally(() => dispatch(loadingEnd));
    };

    img.onerror = () => {
      dispatch(actionError("유효하지 않은 이미지입니다."));
    };
  };

  const handleDeleteImage = () => {
    // 삭제 요청 처리
    dispatch(loadingStart);
    APIS.deleteMallImage()
      .then((res) => {
        res.data.success;
        setImageFile(res.data.data);
      })
      .finally(() => dispatch(loadingEnd));
  };
  return (
    <Container>
      <Box>
        <ColumnWrapper>
          <h2>쇼핑몰 상품 상세정보</h2>
          {imageFile ? (
            <ImageArea
              src={consts.s3BaseUrl + imageFile?.path}
              onClick={() => {
                dispatch(
                  openImageView({
                    images: [
                      {
                        src: imageFile?.path.slice(1),
                        title: imageFile,
                      },
                    ],
                  })
                );
              }}
            />
          ) : (
            <NullArea>이미지 없음</NullArea>
          )}
        </ColumnWrapper>
        <ColumnWrapper className="buttonGroup">
          <LargeButton
            className="m-10"
            fillMode={"outline"}
            onClick={handleImgButtonClick}
          >
            {imageFile ? "파일 변경" : "파일 추가"}
          </LargeButton>
          <InvisibleInput
            type="file"
            ref={fileInput}
            onChange={handleImgChange}
          />
          <LargeButton
            fillMode={"outline"}
            themeColor={"primary"}
            onClick={handleDeleteImage}
          >
            파일 삭제
          </LargeButton>
        </ColumnWrapper>
      </Box>
    </Container>
  );
}

export default ProductNotice;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  height: 730px;
  padding: 20px 50px;
`;

const Box = styled.div`
  width: 500px;
  height: 765px;
  border: 1px solid lightgray;
`;

const ImageArea = styled.img`
  width: 300px;
  height: 500px;

  object-fit: cover;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const ColumnWrapper = styled(FlexCenter)`
  flex-direction: column;
  margin: 10px;
  padding: 0 10px;
  &.buttonGroup {
    margin-left: 20px;
  }
`;

const LargeButton = styled(Button)`
  width: 150px;
  height: 50px;

  &.m-10 {
    margin: 10px;
  }
`;

const NullArea = styled(FlexCenter)`
  width: 300px;
  height: 500px;

  border: 1px solid lightgray;
`;
