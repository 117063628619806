import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import LabelTabs from "../../components/label-tabs/LabelTabs";

import ProductList from "./ProductList";
import Category from "./Category";
import { getDefaultPageSize } from "../../libs/consts";

import PrintManagement from "./PrintManagement";
import ProductNotice from "./ProductNotice";
import HotProduct from "./HotProducts/HotProduct";

export default function PhysicalProduct({}) {
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        ...getDefaultPageSize(),
      });
    }
  }, [state.tab]);

  return (
    <>
      <LabelTabs
        data={[
          { label: "상품관리", value: "1" },
          { label: "분류관리", value: "2" },
          { label: "상품군관리", value: "3" },
          { label: "인쇄관리", value: "4" },
          { label: "쇼핑몰관리", value: "5" },
        ]}
      />
      {(() => {
        switch (state.tab) {
          case "2":
            return <Category />;

          case "3":
            return <HotProduct />;
          case "4":
            return <PrintManagement />;
          case "5":
            return <ProductNotice />;
          default:
            return <ProductList state />;
        }
      })()}
    </>
  );
}
