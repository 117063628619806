import {
  Button,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionOpen } from "../../redux/action/ActionActions";

export default function SettingPolicy2({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(actionOpen("수정하시겠습니까?", () => {}, "취소", "저장"));
  };

  return (
    <Flex>
      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>순도별 중량 차감률</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th>금(Gold)</th>
          <th>24K(999)</th>
          <th>18K(750)</th>
          <th>14K(585)</th>
        </tr>

        <tr>
          <td>차감률</td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
        </tr>
        <tr>
          <th>은(Silver)</th>
          <th>999이상</th>
          <th>925</th>
          <th>기타</th>
        </tr>

        <tr>
          <td>차감률</td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>택배배송 제한 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th>간편감정 택배 접수</th>
          <th>간편감정 택배 반송</th>
          <th>실물인출 택배 배송</th>
        </tr>

        <tr>
          <td>
            <select>
              <option label="활성" />
              <option label="비활성" />
            </select>
          </td>
          <td>
            <select>
              <option label="활성" />
              <option label="비활성" />
            </select>
          </td>
          <td>
            <select>
              <option label="활성" />
              <option label="비활성" />
            </select>
          </td>
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>매장(대리점) 감정등록 제한 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th colSpan={2}></th>
          <th>설정</th>
          <th>간편감정 등록</th>
          <th>현장감정 등록</th>
          <th>위탁거래 등록</th>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            금(Gold)
          </td>
          <td>
            <select>
              <option label="감정결과 자동 등록" />
              <option label="감정결과 승인 요청" />
              <option label="조건부 승인 요청" />
            </select>
          </td>

          <td>
            <Flex row className={"row-center " + classes.a}>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
              이상 승인 요청
            </Flex>
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
              이상 승인 요청
            </Flex>
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
              이상 승인 요청
            </Flex>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            은(Silver)
          </td>
          <td>
            <select>
              <option label="감정결과 자동 등록" />
              <option label="감정결과 승인 요청" />
              <option label="조건부 승인 요청" />
            </select>
          </td>

          <td>
            <Flex row className={"row-center " + classes.a}>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
              이상 승인 요청
            </Flex>
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
              이상 승인 요청
            </Flex>
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
              이상 승인 요청
            </Flex>
          </td>
        </tr>{" "}
      </table>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tdb: {
    backgroundColor: "#f5f5f5",
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    width: "80%",
    marginLeft: "10%",

    paddingRight: 10,
  },
  input: {
    marginRight: 10,
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
});
