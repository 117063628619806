import { ButtonBase, Dialog } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import { actionClose } from "../../redux/action/ActionActions";

export default function Action({}) {
  const dispatch = useDispatch();
  const { message, open, onClick, cancelTitle, confirmTitle, onlyConfirm } =
    useSelector((s) => s.action);
  if (!open) {
    return null;
  }
  return (
    <Dialog
      onKeyPress={(e) => {
        if (e?.nativeEvent?.key === "Enter") {
          dispatch(actionClose());
          onClick && onClick();
        }
      }}
      open={true}
      fullWidth
      maxWidth="xs"
    >
      <Text
        style={{
          fontSize: 18,
          padding: "50px 50px 50px 50px",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        {message
          ? message.split("").map((x, i) => {
              if (x === "\n") {
                return <br key={i} />;
              } else {
                return x;
              }
            })
          : ""}
      </Text>
      <Flex row>
        {!onlyConfirm && (
          <ButtonBase
            onClick={() => {
              dispatch(actionClose());
            }}
            style={{
              flex: 1,
              padding: "16px",
              backgroundColor: "gray",
              color: "#fff",
              fontFamily: fonts.notoSansKRBold,
            }}
          >
            {cancelTitle || "취소"}
          </ButtonBase>
        )}

        <ButtonBase
          onClick={() => {
            dispatch(actionClose());
            onClick && onClick();
          }}
          style={{
            flex: 1,
            padding: "16px",
            backgroundColor: "black",
            color: "#fff",
            fontFamily: fonts.notoSansKRBold,
          }}
        >
          {confirmTitle || "확인"}
        </ButtonBase>
      </Flex>
    </Dialog>
  );
}
