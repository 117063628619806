import {
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Close, Star } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import Editor from "../../components/editor/Editor";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function ServiceReviewDetail({}) {
  const history = useHistory();

  const classes = useStyle();
  return (
    <>
      <PageLabel
        add={{
          title: "삭제",
          onClick: () => {
            history.goBack();
          },
        }}
      >
        거래후기
      </PageLabel>
      <Flex style={{ marginRight: "10%" }}>
        <GridRow
          data={[
            {
              label: "등록일",
              value: "20203.23.23 13:31:00",
              fullWidth: true,
            },
            {
              label: "게재상태",
              fullWidth: true,
              type: "render",
              render: (
                <RadioGroup row>
                  <FormControlLabel
                    label="노출"
                    control={
                      <Radio style={{ color: "black" }} color="default" />
                    }
                  />
                  <FormControlLabel
                    label="비노출"
                    control={
                      <Radio style={{ color: "black" }} color="default" />
                    }
                  />
                </RadioGroup>
              ),
            },
            {
              label: "메인노출",
              fullWidth: true,
              type: "render",
              render: (
                <FormControlLabel
                  label="메인노출"
                  control={
                    <Checkbox style={{ color: "black" }} color="default" />
                  }
                />
              ),
            },
            {
              label: "등록일자",
              fullWidth: true,
              type: "render",
              render: (
                <input
                  type="date"
                  style={{ padding: "10px 10px", border: "1px solid #ddd" }}
                />
              ),
            },
            {
              label: "분류",
              fullWidth: true,
              type: "render",
              render: (
                <select>
                  <option label="분류" />
                </select>
              ),
            },
            {
              label: "이름",
              type: "input",
              fullWidth: true,
              inputFullWidth: true,
              placeholder: "제목을 입력하세요.",
            },

            {
              label: "이미지",
              fullWidth: true,
              type: "render",
              render: (
                <Flex>
                  <ButtonBase className={classes.button}>업로드</ButtonBase>
                  <Flex row className={classes.images}>
                    <div>
                      <span>
                        <Close />
                      </span>
                    </div>
                    <div>
                      <span>
                        <Close />
                      </span>
                    </div>
                    <div>
                      <span>
                        <Close />
                      </span>
                    </div>
                    <div>
                      <span>
                        <Close />
                      </span>
                    </div>
                  </Flex>
                </Flex>
              ),
            },
            {
              label: "내용",
              fullWidth: true,
              type: "render",
              render: (
                <InputBase rows={10} multiline className={classes.input} />
              ),
            },
            {
              label: "평점",
              fullWidth: true,
              type: "render",
              render: (
                <FormGroup row>
                  <FormControl>
                    <Star style={{ color: colors.primary }} />
                  </FormControl>
                  <FormControl>
                    <Star style={{ color: colors.primary }} />
                  </FormControl>
                  <FormControl>
                    <Star style={{ color: colors.primary }} />
                  </FormControl>
                  <FormControl>
                    <Star style={{ color: colors.primary }} />
                  </FormControl>
                  <FormControl>
                    <Star style={{ color: colors.primary }} />
                  </FormControl>
                </FormGroup>
              ),
            },
          ]}
        />
      </Flex>

      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.button1}>
          목록
        </ButtonBase>
        <ButtonBase onClick={history.goBack} className={classes.button2}>
          저장
        </ButtonBase>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  buttons: { alignSelf: "center", marginTop: 50 },
  button1: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  inputWrap: {
    margin: "50px",
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    height: "500px !important",
    alignSelf: "stretch",
  },
  button: {
    alignSelf: "flex-start",
    border: "1px solid " + colors.primary,
    color: colors.primary,
    fontFamily: fonts.notoSansKRBold,
    padding: "10px 30px",
  },
  images: {
    alignItems: "center",
    marginTop: 10,
    "& div": {
      width: 100,
      border: "1px solid #ddd",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: 100,
      marginRight: 10,
      position: "relative",
      "& span": {
        position: "absolute",
        top: 0,
        right: 0,
        cursor: "pointer",
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": { color: "#fff" },
      },
    },
  },
  input: {
    border: "1px solid #ddd",
    flex: 1,
    marginRight: "30%",
    "& textarea": {
      padding: 10,
      flex: 1,
      width: "100%",
    },
  },
});
