import * as APIS from "libs/apis";

const getStockStatus = async () => {
  const {
    data: { success, data, message },
  } = await APIS.getInventoryPresentByStockType();
  if (success) {
    const updatedStatusList = data.map((item) => {
      const partialSum =
        item.goods +
        item.goodsPacking +
        item.raws +
        item.refineWait +
        item.refining +
        item.making +
        item.nonDelivery;

      const roundedPartialSum = partialSum.toFixed(3);

      return {
        ...item,
        partialSum: roundedPartialSum,
      };
    });

    return updatedStatusList;
  } else {
    return [
      {
        goldOrSilver: "GOLD",
        goods: 0,
        raws: 0,
        refineWait: 0,
        refining: 0,
        making: 0,
        nonDelivery: 0,
        nonAppraisalStocked: 0,
        income: 0,
        partialSum: 0,
      },
      {
        goldOrSilver: "SILVER",
        goods: 0,
        raws: 0,
        refineWait: 0,
        refining: 0,
        making: 0,
        nonDelivery: 0,
        nonAppraisalStocked: 0,
        income: 0,
        partialSum: 0,
      },
    ];
  }
};

export default getStockStatus;
