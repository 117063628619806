import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import PageLabel from "../../components/page-label/PageLabel";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import { formatTime } from "../../libs/utils";
import { PanoramaOutlined } from "@material-ui/icons";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import { actionOpen } from "../../redux/action/ActionActions";
import {
  ButtonBase,
  makeStyles,
  Grid,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";

export default function ServiceFaqDetail({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation({});
  const { state = {} } = location;
  const [faqItemData, setFaqItemData] = useState({});
  const [faqImage, setFaqImage] = useState("");
  useEffect(() => {
    if (state.id) {
      fetchItem();
    }
  }, []);
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const {
    faqCategory = Object.keys(faqItemData).length !== 0
      ? handleValueChange("faqCategory", `${faqItemData?.faqCategory?.id}`)
      : "",
    title = Object.keys(faqItemData).length !== 0
      ? handleValueChange("title", faqItemData?.title)
      : "",
    content = Object.keys(faqItemData).length !== 0
      ? handleValueChange("content", faqItemData?.content)
      : "",
  } = state;
  const [status, setStatus] = useState({
    faqCategory: faqCategory,
    title: title,
    content: content,
  });

  //faq detail 정보
  const fetchItem = () => {
    dispatch(loadingStart);
    APIS.getFaqDetail(state.id)
      .then(({ data: { success, data } }) => {
        if (success) {
          setFaqImage(data.serviceImage);
          setFaqItemData(data);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const checkRatio = (name, targetWidth, targetHeight) => {
    const BaseRatio = "1.8";
    if (name === "faqImage") {
      return (targetWidth / targetHeight).toFixed(1) === BaseRatio;
    }
  };

  const validationData = () => {
    if (!state.faqCategoryList[0]?.id) {
      if (!location.state.faqCategory) {
        return "대분류 카테고리를 선택하여 주세요";
      }
    }
    if (!location.state.title) {
      return "질문 내용을 입력하여 주세요";
    }
    if (!location.state.content) {
      return "답변 내용을 입력하여 주세요";
    }
    return "";
  };

  const handleClickRegister = () => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    const json = JSON.stringify({
      ...state,
      title: location.state.title,
      content: location.state.content,
      faqCategoryId: location.state.faqCategory
        ? location.state.faqCategory
        : state.faqCategoryList[0].id,
    });

    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("faq", blob);
    formData.append(
      "image",
      Object.keys(faqImage).length !== 0 ? faqImage : ""
    );

    dispatch(loadingStart);
    APIS.postFaqRegister(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen("FAQ 등록에 실패하였습니다", null, null, null, true)
          );
        } else {
          dispatch(
            actionOpen(
              "FAQ 추가가 완료되었습니다.",
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handleClickEdit = () => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    const json = JSON.stringify({
      ...state,
      id: state.id,
      title: location.state.title,
      content: location.state.content,
      faqCategoryId: location.state.faqCategory,
    });

    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("faq", blob);
    if (faqImage !== null) {
      formData.append(
        "image",
        Object.keys(faqImage).length !== 0 ? faqImage : ""
      );
    }

    dispatch(loadingStart);
    APIS.postEditFaq(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen("FAQ 수정에 실패하였습니다", null, null, null, true)
          );
        } else {
          dispatch(
            actionOpen(
              "FAQ 수정이 완료되었습니다.",
              () => {
                setFaqItemData(data);
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };
  const onInputClick = (event) => {
    event.target.value = "";
  };

  const onChangeImage = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    const _URL = window.URL || window.webkitURL;
    const i = new Image();
    i.src = _URL.createObjectURL(file);
    i.onload = () => {
      if (i.width !== 0 && i.height !== 0) {
        if (name === "faqImage" && !checkRatio(name, i.width, i.height)) {
          alert("FAQ 이미지는 16 : 9 비율의 이미지만 가능합니다.");
          return false;
        }
        // else if(file.size >= 1024 * 1024 * 1) {
        //   alert("파일 용량이 초과하였습니다.(1MB)");
        //   return false;
        // }
        else {
          if (file) {
            const f = new FileReader();
            f.onload = () => {
              file.uri = f.result;
              if (name === "faqImage") {
                setFaqImage(file);
              }
              setStatus({ ...status, image: file });
            };
            f.readAsDataURL(file);
            e.target.value = "";
          }
        }
      }
    };
  };
  const deleteImage = (id, type) => {
    dispatch(loadingStart);
    APIS.deleteFaqImage(state.id, id)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen("이미지 삭제에 실패하였습니다", null, null, null, true)
          );
        } else {
          dispatch(
            actionOpen(
              "이미지가 삭제되었습니다.",
              () => {
                if (type === "FAQ") {
                  setFaqImage("");
                }
                setFaqItemData(data);
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };
  return (
    <>
      <PageLabel
      // add={{
      //   title: "삭제",
      //   onClick: () => {
      //     history.goBack();
      //   },
      // }}
      >
        FAQ (등록/수정)
      </PageLabel>
      <GridRow
        data={[
          {
            label: "등록일",
            value:
              Object.keys(faqItemData).length !== 0
                ? formatTime(faqItemData.createdAt, "YYYY-MM-DD")
                : formatTime(new Date(), "YYYY-MM-DD"),
            fullWidth: true,
          },
          {
            type: "render",
            label: "대분류",
            fullWidth: true,
            render: (
              <Flex row>
                <select
                  style={{
                    minWidth: 100,
                    fontSize: 16,
                  }}
                  onChange={(e) => {
                    handleValueChange("faqCategory", e.target.value);
                  }}
                >
                  {state.faqCategoryList?.map((item, i) => {
                    if (+state.faqCategory === item.id) {
                      return (
                        <option key={item.id} value={item.id} selected>
                          {item.name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </Flex>
            ),
          },
          {
            label: "질문",
            type: "input",
            key: "title",
            value: faqItemData?.title || location.state.title,
            fullWidth: true,
            inputFullWidth: true,
            onChange: (value) => {
              if (Object.keys(faqItemData).length !== 0) {
                handleValueChange("title", value);
                setFaqItemData((status) => ({
                  ...status,
                  title: value,
                }));
              } else {
                handleValueChange("title", value);
                setStatus((status) => ({
                  ...status,
                  title: value,
                }));
              }
            },
          },
          {
            label: "답변이미지",
            type: "render",
            key: "detailImage",
            fullWidth: true,
            render: (
              <Grid
                item
                xs={12}
                className={[classes.df, classes.infoContent].join(" ")}
                style={{ flexDirection: "column" }}
              >
                <Grid item xs={12} className={classes.df}>
                  <Grid
                    item
                    xs={11}
                    className={[classes.dfsc, classes.infocell].join(" ")}
                  >
                    {faqImage ? (
                      <div
                        className={classes.df}
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className={classes.dfcc}
                          style={{ width: "150px", marginLeft: "16px" }}
                        >
                          <CardMedia
                            component="img"
                            image={
                              faqImage.path
                                ? process.env.REACT_APP_S3_BASE_URL +
                                  faqImage.path
                                : faqImage.uri
                            }
                            width="100"
                          />
                        </div>
                        {/* 삭제  */}
                        <Grid item xs={12} className={classes.dfcs}>
                          <Button
                            onClick={() => {
                              faqImage.id
                                ? deleteImage(faqImage.id, faqImage.imageType)
                                : setFaqImage("");
                            }}
                            size="small"
                          >
                            <Typography variant="subtitle2">삭제</Typography>
                          </Button>
                        </Grid>
                      </div>
                    ) : (
                      <>
                        <input
                          accept="image/*"
                          type="file"
                          id="rasied-thumbnail-file"
                          name="faqImage"
                          style={{ display: "none" }}
                          onChange={onChangeImage}
                          onClick={onInputClick}
                        />
                        <label htmlFor="rasied-thumbnail-file">
                          <Button
                            className={classes.imageAddBtn}
                            component="span"
                          >
                            <PanoramaOutlined />
                            이미지 등록
                          </Button>
                        </label>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            label: "답변내용",
            type: "render",
            key: "content",
            fullWidth: true,
            render: (
              <div className={classes.inputWrap}>
                <textarea
                  name="content"
                  rows="50"
                  value={faqItemData.content}
                  onChange={(e) => {
                    handleValueChange("content", e.target.value);

                    if (Object.keys(faqItemData).length !== 0) {
                      setFaqItemData((status) => ({
                        ...status,
                        content: e.target.value,
                      }));
                    }
                  }}
                />
              </div>
            ),
          },
        ]}
      />

      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.button1}>
          목록
        </ButtonBase>
        {Object.keys(faqItemData).length !== 0 ? (
          <ButtonBase onClick={handleClickEdit} className={classes.button2}>
            수정
          </ButtonBase>
        ) : (
          <ButtonBase onClick={handleClickRegister} className={classes.button2}>
            저장
          </ButtonBase>
        )}
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  buttons: { alignSelf: "center", marginTop: 50 },
  button1: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  inputWrap: {
    display: "flex",
    flex: 1,
    marginBottom: 30,
    flexDirection: "column",
    height: "500px !important",
    alignSelf: "stretch",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imageAddBtn: {
    width: "130px",
    height: "100px",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "5px",
    color: "rgba(0,0,0,0.5)",
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
  },
});
