import styled from "styled-components";
import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { GridColumn } from "@progress/kendo-react-grid";

import * as APIS from "libs/apis";
import { numberWithCommas, formatTime, formatNumber } from "libs/utils";

import GridTable, {
  NumberCell,
  CenterCell,
} from "components/gird-table/GridTable";
import Flex from "components/flex/Flex";
import StyledTable from "components/table/StyledTable";
import { saveAs } from "file-saver";
import { AllToNull } from "./InventoryStatus";
import { useDispatch } from "react-redux";
import { openModal } from "redux/modal/ModalReducer";
import KendoButton from "components/button/KendoButton";

const MaterialComp = ({ detailList, filter }) => {
  const [activeTab, setActiveTab] = useState("원재료");
  const dispatch = useDispatch();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleClickExcel = async () => {
    const paramCopy = { ...filter };
    const data = AllToNull(paramCopy);
    delete data.bizTypes;

    const changeTab = {
      원재료: "materials",
      상품: "goods",
      정련: "refines",
    };

    const param = changeTab[activeTab];

    await APIS.getInventoryPresentDailyDetailExcel(
      `materials/${param}`,
      data
    ).then((response) => {
      const blob = new Blob([response.data]);
      saveAs(
        blob,
        `처리유형별 세부목록 ${activeTab}_${formatTime(
          new Date(),
          "YYYYMMDDHHmmSS"
        )}.xlsx`
      );
    });
  };

  const initializeWeightData = () => ({
    inspectProductWeightSum: 0,
    inspectWeightSum: 0,
  });

  const aggregateWeights = (detailList) => {
    const weightsByType = detailList.reduce(
      (acc, item) => {
        if (item.appraisalItemType !== "정련") {
          return acc;
        }

        const {
          goldOrSilver,
          purityType,
          productName,
          inspectProductWeightGram,
          inspectWeightGram,
        } = item;
        const typeKey = goldOrSilver === "금" ? purityType : productName;

        if (!acc[goldOrSilver][typeKey]) {
          acc[goldOrSilver][typeKey] = initializeWeightData();
        }

        acc[goldOrSilver][typeKey].inspectProductWeightSum +=
          parseFloat(inspectProductWeightGram) || 0;
        acc[goldOrSilver][typeKey].inspectWeightSum +=
          parseFloat(inspectWeightGram) || 0;

        return acc;
      },
      { 금: {}, 은: {} }
    );

    return {
      goldWeights: weightsByType["금"],
      silverWeights: weightsByType["은"],
    };
  };

  const WeightsTable = ({ data, title }) => (
    <>
      <SummaryModalTitle>{title}</SummaryModalTitle>
      <WeightStyledTable>
        <thead>
          <tr>
            <th className="td-c">{title === "금" ? "순도" : "품목"}</th>
            <th className="td-c">실중량</th>
            <th className="td-c">환산중량</th>
            <th className="td-c">평균환산율</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(
            ([key, { inspectProductWeightSum, inspectWeightSum }], index) => {
              const averageWeightRatePct =
                inspectWeightSum / inspectProductWeightSum;
              return (
                <tr key={index}>
                  <td>{key}</td>
                  <td className="td-r">
                    {formatNumber(inspectProductWeightSum)}
                  </td>
                  <td className="td-r">{formatNumber(inspectWeightSum)}</td>
                  <td className="td-r">{formatNumber(averageWeightRatePct)}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </WeightStyledTable>
    </>
  );

  const handleAggregate = () => {
    const { goldWeights, silverWeights } = aggregateWeights(detailList);

    dispatch(
      openModal({
        visible: true,
        children: (
          <SummaryModal>
            <WeightsTable data={goldWeights} title="금" />
            <WeightsTable data={silverWeights} title="은" />
          </SummaryModal>
        ),
      })
    );
  };

  const columns = {
    원재료: [
      {
        title: "입고일시",
        field: "stockedAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "사용자명(id)",
        field: "user",
        cell: CenterCell,
        width: 100,
      },
      { title: "자산", field: "goldOrSilver", cell: CenterCell, width: 50 },
      {
        title: "품목수령일시",
        field: "takenAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "순도",
        field: "purityType",
        cell: CenterCell,
        width: 120,
      },
      { title: "품목", field: "productName", width: 280 },
      {
        title: "기준비율",
        field: "basicPct",
        cell: NumberCell,
        width: 70,
      },
      {
        title: "검품품목총중량",
        field: "inspectWeightGram",
        cell: NumberCell,
        width: 120,
      },
      {
        title: "검품실중량",
        field: "inspectProductWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "관리자메모",
        field: "managerMemo",
        cell: CenterCell,
        width: 200,
      },
      {
        title: "오입력취소요청일",
        field: "canceledAt",
        cell: CenterCell,
        width: 200,
      },
      {
        title: "접수번호",
        field: "number",
        width: 320,
      },
    ],
    상품: [
      {
        title: "입고일시",
        field: "stockedAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "카테고리",
        field: "goodsCategoryName",
        cell: CenterCell,
        width: 100,
      },
      {
        title: "상품",
        field: "goodsName",
        width: 200,
      },
      { title: "옵션명", field: "optionName", cell: CenterCell, width: 120 },
      {
        title: "상품중량",
        field: "goodsGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "재포장",
        field: "repackingQuantity",
        cell: CenterCell,
        width: 100,
      },
      {
        title: "입고수량",
        field: "stockQuantity",
        cell: NumberCell,
        width: 80,
      },
      {
        title: "사용자명(id)",
        field: "user",
        cell: CenterCell,
        width: 100,
      },
      { title: "자산", field: "goldOrSilver", cell: CenterCell, width: 50 },
      {
        title: "품목수령일시",
        field: "takenAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "기준비율",
        field: "basicPct",
        cell: NumberCell,
        width: 70,
      },
      {
        title: "검품품목총중량",
        field: "inspectWeightGram",
        cell: NumberCell,
        width: 120,
      },
      {
        title: "검품실중량",
        field: "inspectProductWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "관리자메모",
        field: "managerMemo",
        cell: CenterCell,
        width: 200,
      },
      {
        title: "오입력취소요청일",
        field: "canceledAt",
        cell: CenterCell,
        width: 200,
      },
      {
        title: "접수번호",
        field: "number",
        width: 320,
      },
    ],
    정련: [
      {
        title: "입고일시",
        field: "stockedAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "감정매장",
        field: "shopName",
        cell: CenterCell,
        width: 120,
      },
      {
        title: "사용자명(id)",
        field: "user",
        cell: CenterCell,
        width: 100,
      },
      {
        title: "접수번호",
        field: "number",
        width: 320,
      },
      {
        title: "검품실중량",
        field: "inspectWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "정련출고상태",
        field: "refineDeliveryStatus",
        cell: CenterCell,
        width: 100,
      },
      {
        title: "정련출고일시",
        field: "refineDeliveryAt",
        cell: CenterCell,
        width: 150,
      },
      { title: "자산", field: "goldOrSilver", cell: CenterCell, width: 50 },
      {
        title: "품목수령일시",
        field: "takenAt",
        cell: CenterCell,
        width: 150,
      },
      {
        title: "순도",
        field: "purityType",
        cell: CenterCell,
        width: 120,
      },
      { title: "품목", field: "productName", width: 280 },
      {
        title: "기준비율",
        field: "basicPct",
        cell: NumberCell,
        width: 70,
      },
      {
        title: "관리자메모",
        field: "managerMemo",
        cell: CenterCell,
        width: 200,
      },
      {
        title: "오입력취소요청일",
        field: "canceledAt",
        cell: CenterCell,
        width: 200,
      },
    ],
  };

  const renderGridColumns = () =>
    columns[activeTab].map((col, idx) => (
      <GridColumn
        key={`grid-column-${idx}`}
        title={col.title}
        field={col.field}
        cell={col.cell}
        width={col.width}
      />
    ));
  return (
    <div>
      <TabSelector activeTab={activeTab} onTabClick={handleTabClick} />
      <SummaryLine>
        <MaterialSummary detailList={detailList} activeTab={activeTab} />
        <Flex row>
          <KendoButton
            icon="excel"
            label="Excel"
            onClick={() => handleClickExcel()}
          />
          {activeTab === "정련" && (
            <SumBtn
              label="순도, 품목별 중량합"
              onClick={() => handleAggregate()}
            />
          )}
        </Flex>
      </SummaryLine>
      <GridTable
        data={detailList?.filter(
          (item) => item.appraisalItemType === activeTab
        )}
        scrollable
        sortable
        style={{ width: "100%", height: "500px" }}
      >
        {renderGridColumns()}
      </GridTable>
    </div>
  );
};

const TabSelector = ({ activeTab, onTabClick }) => (
  <SummaryLine>
    <TabWrap>
      {["원재료", "상품", "정련"].map((tab) => (
        <TabBtn
          key={tab}
          className={activeTab === tab ? "k-selected" : ""}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </TabBtn>
      ))}
    </TabWrap>
  </SummaryLine>
);

// 재질분류 Summary
const MaterialSummary = ({ detailList, activeTab }) => {
  const totalGold = detailList
    ?.filter(
      (item) =>
        item.appraisalItemType === activeTab && item.goldOrSilver === "금"
    )
    ?.reduce((sum, item) => sum + (item.inspectWeightGram || 0), 0);
  const totalSilver = detailList
    ?.filter(
      (item) =>
        item.appraisalItemType === activeTab && item.goldOrSilver === "은"
    )
    ?.reduce((sum, item) => sum + (item.inspectWeightGram || 0), 0);

  return (
    <DetailSummaryTable>
      <Flex row>
        <div className="header">총 주문</div>
        <div className="data">
          {numberWithCommas(
            detailList?.filter((item) => item.appraisalItemType === activeTab)
              ?.length
          )}{" "}
          건
        </div>
        {(activeTab === "원재료" || activeTab === "상품") && (
          <Flex row>
            <div className="header">금 검품품목총중량</div>
            <div className="data">
              {numberWithCommas(formatNumber(totalGold))}
            </div>
            <div className="header">은 검품품목총중량</div>
            <div className="data">
              {numberWithCommas(formatNumber(totalSilver))}
            </div>
          </Flex>
        )}
      </Flex>
    </DetailSummaryTable>
  );
};

const SummaryLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const ToggleBtn = styled(Button)`
  width: 56px;
  height: 24px;
  &.k-button-solid-base.k-selected {
    border-color: #424242;
    background-color: #424242;
  }
`;

const TabWrap = styled.div`
  display: flex;
  margin-right: 8px;
`;

const TabBtn = styled(ToggleBtn)`
  height: 31px;
  border-radius: 0;
`;

const DetailSummaryTable = styled(StyledTable)`
  height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
`;

const SummaryModal = styled.div`
  width: 100%;
`;

const SummaryModalTitle = styled.div`
  font-size: 15px;
`;

const WeightStyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 5px 0 20px;
  font-size: 15px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
  }
  th {
    background-color: #f2f2f2;
    font-weight: 400;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  tr:hover {
    background-color: #f1f1f1;
  }
`;

const SumBtn = styled(KendoButton)`
  min-width: fit-content;
  margin-left: 8px;
`;

const detailConfigs = {
  materials: {
    title: "재질분류",
    innerTabs: true,
    innerComponents: MaterialComp,
  },
  refine: {
    title: "정련입고",
    showSumBtn: true,
    details: [
      {
        title: "코다입고날짜",
        field: "stockedAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "출고날짜",
        field: "deliveredDate",
        cell: CenterCell,
        width: 120,
      },
      { title: "자산", field: "assetType", cell: CenterCell, width: 50 },
      {
        title: "순도",
        field: "purityType",
        cell: CenterCell,
        width: 120,
      },
      {
        title: "실중량",
        field: "inputWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "감정환산중량(고객)",
        field: "inspectWeightGram",
        cell: NumberCell,
        width: 150,
      },
      {
        title: "전해양극중량",
        field: "electrolyticPolarGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "순도(%)",
        field: "inputPurityPct",
        cell: NumberCell,
        width: 80,
      },
      {
        title: "책임중량",
        field: "chargeWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "입고중량",
        field: "stockedWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "최종회수율",
        field: "returnRate",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "최종잉여잔량",
        field: "balanceWeightGram",
        cell: NumberCell,
        width: 100,
      },
    ],
  },
  refineModify: {
    title: "정련입고수정",
    details: [
      {
        title: "정련입고일시",
        field: "stockedAt",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "정련수정일시",
        field: "completeDate",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "감정환산중량(고객)",
        field: "inspectWeightGram",
        cell: NumberCell,
        width: 150,
      },
      {
        title: "입고중량",
        field: "lossInventoryWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "정련입고 실중량",
        field: "inputWeightGram",
        cell: NumberCell,
        width: 120,
      },
      {
        title: "손익변동중량",
        field: "stockedWeightGram",
        width: 100,
        cell: (props) => {
          const { dataItem } = props;
          return (
            <td className="td-r">
              {dataItem?.lossInventoryWeightGram === "-"
                ? `-${numberWithCommas(dataItem?.lossDeliveryWeightGram)}`
                : `+${numberWithCommas(dataItem?.lossInventoryWeightGram)}`}
            </td>
          );
        },
      },
      {
        title: "관리자",
        field: "stockedManagerName",
        cell: CenterCell,
        width: 80,
      },
      {
        title: "정련출고일",
        field: "deliveredDate",
        cell: CenterCell,
        width: 160,
      },
      { title: "자산", field: "goldOrSilver", cell: CenterCell, width: 50 },
      {
        title: "순도",
        field: "purityType",
        cell: CenterCell,
        width: 120,
      },
      {
        title: "기타입고",
        field: "nonRequestType",
        cell: CenterCell,
      },
    ],
  },
  goodsOrder: {
    title: "상품제작주문",
    summaries: [
      {
        header: "금 주문 중량",
        calculate: (detailList) =>
          formatNumber(
            detailList
              ?.filter((item) => item.goldOrSilver === "금")
              ?.reduce(
                (acc, item) =>
                  acc +
                  (Number(item.orderQty) || 0) * (Number(item.unitGram) || 0),
                0
              )
          ),
      },
      {
        header: "은 주문 중량",
        calculate: (detailList) =>
          formatNumber(
            detailList
              ?.filter((item) => item.goldOrSilver === "은")
              ?.reduce(
                (acc, item) =>
                  acc +
                  (Number(item.orderQty) || 0) * (Number(item.unitGram) || 0),
                0
              )
          ),
      },
    ],
    details: [
      { title: "상품명", field: "goodsName", width: 250 },
      { title: "옵션명", field: "optionsName", cell: CenterCell, width: 120 },
      {
        title: "개수",
        field: "orderQty",
        cell: NumberCell,
        width: 80,
      },
      {
        title: "단위중량",
        field: "unitGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "생산단가",
        field: "makeUnitPrice",
        cell: NumberCell,
        width: 120,
      },
      {
        title: "주문일자",
        field: "completeDate",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "출고중량",
        field: "deliveryWeightGram",
        cell: NumberCell,
        width: 100,
      },
      {
        title: "출고중량(손익)",
        field: "lossInventoryWeightGram",
        cell: NumberCell,
        width: 120,
      },
      {
        title: "관리자",
        field: "deliveryManagerName",
        cell: CenterCell,
        width: 80,
      },
      { title: "메모", field: "comment" },
    ],
  },
  goodsDiscard: {
    title: "상품회수",
    summaries: [
      {
        header: "금 중량",
        calculate: (detailList) =>
          formatNumber(
            detailList
              ?.filter((item) => item.goldOrSilver === "금")
              ?.reduce(
                (acc, item) => acc + (Number(item.inventoryWeightGram) || 0),
                0
              )
          ),
      },
      {
        header: "은 중량",
        calculate: (detailList) =>
          formatNumber(
            detailList
              ?.filter((item) => item.goldOrSilver === "은")
              ?.reduce(
                (acc, item) => acc + (Number(item.inventoryWeightGram) || 0),
                0
              )
          ),
      },
    ],
    details: [
      {
        title: "상품회수일시",
        field: "completeDate",
        cell: CenterCell,
        width: 160,
      },
      {
        title: "회수구분(원재료/정련)",
        field: "discardGoodsType",
        cell: CenterCell,
        width: 150,
      },
      {
        title: "입/출고",
        field: "rawHistoryType",
        cell: CenterCell,
        width: 80,
      },
      {
        title: "중량",
        field: "inventoryWeightGram",
        cell: NumberCell,
        width: 80,
      },
      { title: "상품명", field: "goodsName", width: 250 },
      { title: "옵션명", field: "optionsName", cell: CenterCell, width: 120 },
      {
        title: "회수개수",
        field: "discardQty",
        cell: NumberCell,
        width: 80,
      },
      {
        title: "관리자",
        field: "stockedManagerName",
        cell: CenterCell,
        width: 80,
      },
      { title: "메모", field: "memo" },
    ],
  },
  // 1107 배포 이후에 배포될 예정
  // event: {
  //   title: "이벤트",
  //   summaries: [
  //     {
  //       header: "총 입고 중량",
  //       calculate: (detailList) =>
  //         detailList?.reduce(
  //           (acc, item) => acc + (Number(item.inventoryWeightGram) || 0),
  //           0
  //         ),
  //     },
  //     {
  //       header: "총 출고 중량",
  //       calculate: (detailList) =>
  //         detailList?.reduce(
  //           (acc, item) => acc + (Number(item.deliveryWeightGram) || 0),
  //           0
  //         ),
  //     },
  //   ],
  //   details: [
  //     {
  //       title: "처리일자",
  //       field: "completeDate",
  //       cell: CenterCell,
  //       width: 200,
  //     },
  //     {
  //       title: "입/출고",
  //       field: "rawHistoryType",
  //       cell: CenterCell,
  //       width: 150,
  //     },
  //     {
  //       title: "입고중량",
  //       field: "inventoryWeightGram",
  //       cell: NumberCell,
  //       width: 150,
  //     },
  //     {
  //       title: "출고중량",
  //       field: "deliveryWeightGram",
  //       cell: NumberCell,
  //       width: 150,
  //     },
  //     {
  //       title: "입고중량(손익)",
  //       field: "lossInventoryWeightGram",
  //       cell: NumberCell,
  //       width: 150,
  //     },
  //     {
  //       title: "출고중량(손익)",
  //       field: "lossDeliveryWeightGram",
  //       cell: NumberCell,
  //       width: 150,
  //     },
  //     {
  //       title: "입고관리자",
  //       field: "stockedManagerName",
  //       cell: CenterCell,
  //       width: 80,
  //     },
  //     {
  //       title: "출고관리자",
  //       field: "deliveryManagerName",
  //       cell: CenterCell,
  //       width: 80,
  //     },
  //     {
  //       title: "재고순서",
  //       field: "inventoryOrder",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "입고",
  //       field: "appraisalItem",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "정련재고",
  //       field: "inventoryRefineStock",
  //       cell: NumberCell,
  //       width: 120,
  //     },
  //     {
  //       title: "회수재고",
  //       field: "inventoryDiscardGoods",
  //       cell: NumberCell,
  //       width: 120,
  //     },
  //     {
  //       title: "이벤트재고",
  //       field: "inventoryEventItem",
  //       cell: NumberCell,
  //       width: 120,
  //     },
  //     {
  //       title: "comment",
  //       field: "comment",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     { title: "자산", field: "goldOrSilver", cell: CenterCell, width: 50 },
  //     {
  //       title: "쿠폰명",
  //       field: "couponsName",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "공지명",
  //       field: "noticeName",
  //       cell: CenterCell,
  //       width: 300,
  //     },
  //     {
  //       title: "assetType",
  //       field: "assetType",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "쿠폰타입",
  //       field: "couponType",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "status",
  //       field: "status",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "paymentQuantity",
  //       field: "paymentQuantity",
  //       cell: NumberCell,
  //       width: 200,
  //     },
  //     {
  //       title: "point",
  //       field: "point",
  //       cell: NumberCell,
  //       width: 120,
  //     },
  //     {
  //       title: "stockWeightGram",
  //       field: "stockWeightGram",
  //       cell: NumberCell,
  //       width: 120,
  //     },
  //     {
  //       title: "paidDt",
  //       field: "paidDt",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "입고일시",
  //       field: "입고일시",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "관리자",
  //       field: "관리자",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //     {
  //       title: "shop",
  //       field: "shop",
  //       cell: CenterCell,
  //       width: 120,
  //     },
  //   ],
  // },
};

export default detailConfigs;
