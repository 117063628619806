import React from "react";
import styled from "styled-components";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";

const StyledTable = styled(Flex)`
  font-size: 14px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #e2e5e8;

  .header,
  .data {
    flex-direction: row;
    align-items: center;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #e2e5e8;
    font-family: ${fonts.notoSansKRRegular};
    padding: 5px 10px;
  }
  .header {
    background: #f2f4f6;
    justify-content: center;
  }
  .data {
    flex: 1;
  }
`;

export default StyledTable;
