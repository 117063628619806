import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import { RestoreFromTrash, Search } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import * as APIS from "../../libs/apis";
import DaumPostcode from "react-daum-postcode";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import FindAddress from "../../components/find-address/FindAddress";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import InputFile from "../../components/input/InputFile";

const MAP_COLUMN_TO_LABEL = {
  shopIsFranchisee: "가맹점/직영점",
  shopName: "매장명",
  shopCode: "가맹점코드",
  managerAccount: "아이디",
  managerPassword: "비밀번호",
  managerName: "이름",
  managerPhone: "연락처",
  shopBusinessNumber: "사업자등록번호",
  shopPhone: "전화번호",
  shopFax: "팩스번호",
  shopPostCode: "우편번호",
  shopAddress: "주소",
  shopAddressDetail: "상세주소",
  shopDistrict: "찾아오는길",
  shopLatitude: "위도",
  shopLongitude: "경도",
};

export default function ShopInfo({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();

  const [banks, setBanks] = useState([]);
  const [shop, setShop] = useState({});
  const [images, setImages] = useState([]);
  const { shopId } = useSelector((s) => s.auth) || {};

  useEffect(() => {
    onLoad();
    APIS.getBankList().then(({ data: { success, data, message } }) => {
      if (success) {
        setBanks(data);
      } else {
        dispatch(actionError(message));
      }
    });
  }, [shopId]);

  const onLoad = () => {
    if (shopId) {
      dispatch(loadingStart);
      APIS.getShopDetail(shopId)
        .then(({ data: { success, data, message } }) => {
          success && setShop({ ...data, managerPassword: "" });
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const replaceHistory = (state = {}) => {
    history.replace(history.location.pathname, state);
  };

  const handleChangeValue = (key, value) => {
    setShop((shop) => ({ ...shop, [key]: value }));
  };

  const handleClickFindAddress = () => {
    dispatch(
      openModal({
        children: (
          <FindAddress
            callback={({ postCode, address }) => {
              dispatch(closeModal());
              setShop((shop) => ({
                ...shop,
                shopPostCode: postCode,
                shopAddress: address,
                shopAddressDetail: "",
              }));
            }}
          />
        ),
      })
    );
  };

  const handleChangeFile = (file) => {
    setImages([...images, file]);
  };

  const handleClickDelete = () => {
    APIS.deleteShop(shop.id).then(({ data: { success, data, message } }) => {
      success && history.goBack();
    });
  };

  const handleClickSave = (password) => {
    const param = { ...shop };

    const invalidKeys = ["id", "createdAt", "managerAccount", "shopImages"];
    for (let key in param) {
      if (invalidKeys.includes(key) || !param[key]) {
        delete param[key];
      }
    }

    const json = JSON.stringify({ ...shop, password });
    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("shop", blob);

    for (let i in images) {
      formData.append(`image`, images[i]);
    }

    dispatch(loadingStart);

    APIS.saveShop(shop.id, formData)
      .then(({ data: { success, data, message } }) => {
        success &&
          dispatch(
            actionOpen("매장 수정이 완료되었습니다.", onLoad, null, null, true)
          );
        !success && dispatch(actionOpen(message, null, null, null, true));

        // success && history.goBack();
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const getInputUI = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_LABEL[key],
      value: shop[key],
      type: "input",
      onChange: (e) => handleChangeValue(key, e),
      ...option,
    };
  };

  const {
    createdAt = "",
    shopIsFranchisee,
    managerAccount,
    shopBankCode,
    shopBankAccountNumber,
    shopPostCode,
    shopAddress,
    shopAddressDetail,
    shopImages = [],
    shopName,
    shopCode,
  } = shop;

  return (
    <>
      <h1 style={{ marginLeft: 50 }}>{shopName}</h1>
      <AdminConfirmButton
        popupLabel="매장 정보 변경"
        popupMessage="변경하시겠습니까?"
        label="저장"
        callback={handleClickSave}
      />
      <GridRow
        label="기본정보1"
        data={[
          { label: "등록일", value: createdAt },
          {
            label: "가맹점/직영점",
            value: shopIsFranchisee ? "가맹점" : "직영점",
          },
          { label: "매장명", value: shopName },
          { label: "가맹점코드", value: shopCode },
          { label: "아이디", value: managerAccount },
          // getInputUI("managerPassword", { type: "input-password" }),
          getInputUI("managerPhone", { validation: "tel" }),
          getInputUI("managerName"),
          {
            label: "정산계좌",
            value: "",
            type: "render",
            render: (
              <Flex row className={classes.addressContainer}>
                <Flex row>
                  <select
                    style={{
                      minWidth: 100,
                      fontSize: 16,
                    }}
                    value={shopBankCode}
                    onChange={(e) => {
                      setShop((shop) => ({
                        ...shop,
                        shopBankCode: e.target.value,
                      }));
                    }}
                  >
                    {banks.map(({ code, name }, i) => {
                      return (
                        <option key={i.toString()} value={code}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </Flex>
                <InputBase
                  className={classes.addressDetailWrap}
                  value={shopBankAccountNumber}
                  onChange={({ target: { value } }) => {
                    setShop((shop) => ({
                      ...shop,
                      shopBankAccountNumber: value,
                    }));
                  }}
                />
              </Flex>
            ),
          },
          getInputUI("shopBusinessNumber", {
            validation: "businessNumber",
          }),
          { label: "", type: "render", render: <Flex></Flex> },
        ]}
      />

      <GridRow
        label="매장정보"
        data={[
          getInputUI("shopPhone", { validation: "tel" }),
          getInputUI("shopFax", { validation: "tel" }),
          {
            label: "지역",
            value: "",
            type: "render",
            fullWidth: true,
            render: (
              <Flex row className={classes.addressContainer}>
                <Button label="주소찾기" onClick={handleClickFindAddress}>
                  주소찾기
                </Button>

                <span
                  className={classes.addressWrap}
                  onClick={handleClickFindAddress}
                >
                  {shopPostCode && `(${shopPostCode}) `}
                  {shopAddress}
                </span>
                <InputBase
                  className={classes.addressDetailWrap}
                  value={shopAddressDetail}
                  onChange={({ target: { value } }) => {
                    setShop((shop) => ({
                      ...shop,
                      shopAddressDetail: value,
                    }));
                  }}
                />
              </Flex>
            ),
          },
          getInputUI("shopDistrict", {
            fullWidth: true,
            inputFullWidth: true,
          }),
          getInputUI("shopLatitude", { validation: "number" }),
          getInputUI("shopLongitude", { validation: "number" }),
        ]}
      />

      {/* <Flex row className="row-center padding">
        <InputFile
          accept="image/*"
          label="첨부서류"
          callback={handleChangeFile}
        />

        <span style={{ marginLeft: 10 }}>
          ex. 사업자 등록증, 본인신분증 사본, 가맹계약서, 통장사본 등 (첨부파일
          용량 100mb이하)
        </span>
      </Flex> */}

      {/* <Flex
        className="padding"
        style={{ marginTop: 30, alignSelf: "flex-start" }}
      >
        {shopImages.map(({ fileName, path }, i) => {
          let imgTmp = new Image();
          imgTmp.src = path;

          return (
            <ButtonBase
              key={i.toString()}
              style={{ alignSelf: "start" }}
              onClick={() => {
                let imgPopup = window.open(
                  "",
                  fileName,
                  `width=${imgTmp.width}, height=${imgTmp.height}, status=no, menubar=no, toolbar=no, scrollbars=no, resizable=no`
                );

                imgPopup.document.write(`
                            <html>
                              <title>${fileName}</title>
                              <body topmargin=0 leftmargin=0 marginheight=0 marginwidth=0>
                                <a href='javascript:self.close()'>
                                 <img src="${path}" border=0>
                                </a>
                              </body>
                            </html>
                          `);
              }}
            >
              {fileName}
              <Search />
            </ButtonBase>
          );
        })}
        {images.map(({ name }, i) => {
          return (
            <Flex row className="row-center" key={i.toString()}>
              {name}
              <RestoreFromTrash
                className={classes.icon}
                onClick={() => {
                  const copy = [...images];
                  copy.splice(i, 1);
                  setImages(copy);
                }}
              />
            </Flex>
          );
        })}
      </Flex> */}
    </>
  );
}

const useStyle = makeStyles({
  addressWrap: {
    minWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: "0 10px",
    minHeight: 20,
    cursor: "pointer",
  },
  addressDetailWrap: {
    flex: 1,
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: 5,
    minHeight: 20,
  },
  icon: { cursor: "pointer" },
  addressContainer: { flex: 1, alignSelf: "stretch" },
  history1Toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 50px",
    marginTop: 50,
  },
  history1Select: {
    border: "1px solid rgba(0,0,0,0.23)",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  history1buttonRadios: {
    margin: "0px 50px",
    marginTop: 20,
  },
  td: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  td2: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      borderRight: "1px solid rgba(0,0,0,0.23) !important",
      borderBottom: "1px solid rgba(0,0,0,0.23) !important",
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  history2ButtonText: {
    color: "black",
    borderBottom: "1px solid black",
  },
});
