import { InputBase } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import {
  TabStrip,
  TabStripTab,
  TabStripContent,
} from "@progress/kendo-react-layout";
import Flex from "../../components/flex/Flex";
import PushNotification from "./PushNotification";
export default function ServiceNotification({}) {
  const { replace, location } = useHistory();
  const locationState = location.state || {};
  const { tab } = locationState;

  useEffect(() => {
    !tab && replace(location.pathname, { tab: "PUSH" });
  }, [tab]);

  return (
    <ServiceNotificationContainer>
      <ButtonRadios
        data={[
          { label: "푸시알림", value: "PUSH" },
          // { label: "푸시알림 내역", value: "PUSH-HISTORY" },
        ]}
        field="tab"
        className="button-radios"
      />
      <Flex className="content">{tab === "PUSH" && <PushNotification />}</Flex>
    </ServiceNotificationContainer>
  );
}

const ServiceNotificationContainer = styled(Flex)`
  flex: 1;
  padding: 20px;

  .button-radios {
    border-bottom: 1px solid;
    button {
      padding: 5px;
    }
  }

  .content {
    margin-top: 10px;
    flex: 1;
    position: relative;
  }
`;
