import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { getDefaultPageSize, STORAGE_KEY } from "../../libs/consts";
import { openDialog } from "../../redux/dialog-alert/DialogAlertReducer";
import { useDispatch } from "react-redux";
import { FcRefresh } from "react-icons/fc";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import GridTable, {
  centerCell,
  numberWithCommasCell,
} from "../../components/gird-table/GridTable";
import DropDownList from "../../components/select/DropDownList";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { objToQueryStr, formatTime } from "../../services/utils";
import { saveAs } from "file-saver";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const assetCheckBox = [
  { title: "금", key: "GOLD" },
  { title: "은", key: "SILVER" },
];

const tradeCheckBox = [
  { title: "매수", key: "BUY" },
  { title: "매도", key: "SELL" },
];

const INIT = {
  keyword: "",
  shopName: "",
  startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
  sort: "DESC",
  assetType: [],
  tradeType: [],
  dateType: "CREATED",
};
const gridName = "TransactionConsignmentHistory";

const TransactionConsignmentHistory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const history = useHistory();
  const [data, setData] = useState(
    location.state?.data ? location.state?.data : []
  );
  const [expanded, setExpanded] = useState(true);
  const [isCheck, setCheck] = useState(false);
  const [condition, setCondition] = useState(
    location.state?.condition
      ? location.state?.condition
      : {
          keyword: "",
          shopName: "",
          startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
          sort: "DESC",
          dateType: "CREATED",
          assetType: [],
          tradeType: [],
        }
  );
  const [page, setPage] = useState(
    location.state?.page
      ? { ...location.state?.page }
      : {
          ...getDefaultPageSize(),
          totalElements: 0,
        }
  );

  useEffect(() => {
    handleValueChange("condition", condition);
  }, [condition]);

  useEffect(() => {
    handleValueChange("page", page);
  }, [page]);

  useEffect(() => {
    handleValueChange("data", data);
  }, [data]);

  const fetchList = (pageData, sort) => {
    if (
      condition.startDate === "Invalid date" ||
      condition.endDate === "Invalid date"
    ) {
      dispatch(actionError("검색기간의 값을 빠짐없이 입력하여 주세요."));
    } else {
      dispatch(loadingStart);
      if (sort) {
        condition.sort = sort;
      }
      const parsePage = pageData
        ? pageData
        : {
            page: page.page,
            size: page.size,
          };
      const queryString = objToQueryStr({ ...condition, ...parsePage });
      APIS.getConsignmentHistory(queryString)
        .then((res) => {
          const { data } = res;
          const { success, data: historyData } = data;
          const { content, totalElements, number, size } = historyData;
          if (success) {
            const newData = content.map((item, i) => {
              return { ...item, index: totalElements - i - number * size };
            });
            setData(newData || []);
            setPage({
              ...page,
              totalElements,
              page: number * size,
              size: size,
            });
          } else {
            dispatch(loadingEnd);
          }
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const onChangeValue = (e) => {
    const { value, name } = e.target;
    setCondition({
      ...condition,
      [name]: value,
    });
  };

  const onChangeSort = (e) => {
    const { value } = e;
    setCondition({
      ...condition,
      sort: value,
    });
    if (data.length > 0) {
      fetchList({ page: 0, size: page.size }, value);
    }
  };

  const onClickSearch = () => {
    if (!isCheck) {
      setCheck(true);
    }
    fetchList({ page: 0, size: page.size });
  };

  const onClickExcelDownload = () => {
    if (data.length === 0) {
      dispatch(actionError("리스트를 조회해주세요."));
    } else {
      const param = { ...condition };
      for (let key in param) {
        const value = param[key];

        if (typeof value === "string" && !value) {
          delete param[key];
        }
      }
      dispatch(loadingStart);
      APIS.getConsignmentHistoryDownload(objToQueryStr(param))
        .then((response) => {
          const blob = new Blob([response.data]);
          saveAs(
            blob,
            `위탁거래내역_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
          );
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const onPageChange = (event) => {
    const { page: pageData } = event;
    const { skip, take } = pageData;
    localStorage.setItem(STORAGE_KEY.tableSize, take);
    fetchList({ page: skip / take, size: take });
  };

  const onClickRefresh = () => {
    dispatch(
      openDialog({
        action: () => refreshGrid(),
        message: "그리드 설정을 초기화 하시겠습니까?",
      })
    );
  };

  const refreshGrid = () => {
    let setting = JSON.parse(localStorage.getItem("kumbang-gird-column"));
    let order = JSON.parse(localStorage.getItem("kumbang-grid-order"));
    if (setting?.[gridName]) {
      delete setting[gridName];
    }

    if (order?.[gridName]) {
      delete order[gridName];
    }

    localStorage.setItem("kumbang-gird-column", JSON.stringify(setting));
    localStorage.setItem("kumbang-grid-order", JSON.stringify(order));
    window.location.reload();
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };
  const { keyword, startDate, endDate, shopName, assetType, tradeType } =
    condition;

  return (
    <HistoryWrap>
      <TitleWrap onClick={() => setExpanded(!expanded)}>
        <Title>위탁거래내역</Title>
        <div>
          <span style={{ marginRight: "20px" }}>
            {expanded ? <AiOutlineUp /> : <AiOutlineDown />}
          </span>
        </div>
      </TitleWrap>
      <FilterWrap expanded={expanded}>
        <Table>
          <tbody>
            <tr>
              <th id="search-th">회원검색</th>
              <td colSpan={1}>
                <Input
                  name="keyword"
                  type="search"
                  style={{ margin: "10px" }}
                  value={keyword}
                  ref={inputRef}
                  onChange={onChangeValue}
                  placeholder="이름, 전화번호, 생년월일"
                  onFocus={() => {
                    document.getElementById("search-th").style.transition =
                      "all .4s ease-in-out";
                    document.getElementById("search-th").style.color =
                      "#4583ee";
                    document.getElementById("search-th").style.background =
                      "#c9e2ff";
                  }}
                  onBlur={() => {
                    document.getElementById("search-th").style.transition =
                      "all .4s ease-in-out";
                    document.getElementById("search-th").style.background =
                      "#f2f4f6";
                    document.getElementById("search-th").style.color =
                      "#636a75";
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onClickSearch();
                    }
                  }}
                />
              </td>
              <th id="shop-th">매장검색</th>
              <td colSpan={3}>
                <Input
                  name="shopName"
                  type="search"
                  style={{ margin: "10px" }}
                  value={shopName}
                  ref={inputRef}
                  onChange={onChangeValue}
                  placeholder="매장명"
                  onFocus={() => {
                    document.getElementById("shop-th").style.transition =
                      "all .4s ease-in-out";
                    document.getElementById("shop-th").style.color = "#4583ee";
                    document.getElementById("shop-th").style.background =
                      "#c9e2ff";
                  }}
                  onBlur={() => {
                    document.getElementById("shop-th").style.transition =
                      "all .4s ease-in-out";
                    document.getElementById("shop-th").style.background =
                      "#f2f4f6";
                    document.getElementById("shop-th").style.color = "#636a75";
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onClickSearch();
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th id="asset-th">자산유형</th>
              <td colSpan={1}>
                <AssetCheckBoxWrap>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                    }}
                  >
                    <label>
                      <Checkbox
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "3px",
                          borderRadius: "0.5rem",
                          border: "1px solid #bebebe",
                        }}
                        name={"ALL"}
                        checked={assetType.length === 2 ? true : false}
                        onFocus={() => {
                          document.getElementById("asset-th").style.transition =
                            "all .4s ease-in-out";
                          document.getElementById("asset-th").style.color =
                            "#4583ee";
                          document.getElementById("asset-th").style.background =
                            "#c9e2ff";
                        }}
                        onBlur={() => {
                          document.getElementById("asset-th").style.transition =
                            "all .4s ease-in-out";
                          document.getElementById("asset-th").style.background =
                            "#f2f4f6";
                          document.getElementById("asset-th").style.color =
                            "#636a75";
                        }}
                        onChange={({ target }) => {
                          const { name } = target;
                          let arr = [...assetType];
                          if (name === "ALL") {
                            if (arr.length === 2) {
                              arr = [];
                            } else {
                              arr = [
                                ...assetCheckBox.map((item) => {
                                  return item.key;
                                }),
                              ];
                            }
                            setCondition({
                              ...condition,
                              assetType: arr,
                            });
                          }
                        }}
                      />
                      <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                        전체
                      </span>
                    </label>
                  </div>
                  {assetCheckBox.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "3px",
                        }}
                        key={`assetCheckbox_${index}`}
                      >
                        <label>
                          <Checkbox
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "3px",
                              borderRadius: "0.5rem",
                              border: "1px solid #bebebe",
                            }}
                            name={item.key}
                            checked={assetType.includes(item.key)}
                            onFocus={() => {
                              document.getElementById(
                                "asset-th"
                              ).style.transition = "all .4s ease-in-out";
                              document.getElementById("asset-th").style.color =
                                "#4583ee";
                              document.getElementById(
                                "asset-th"
                              ).style.background = "#c9e2ff";
                            }}
                            onBlur={() => {
                              document.getElementById(
                                "asset-th"
                              ).style.transition = "all .4s ease-in-out";
                              document.getElementById(
                                "asset-th"
                              ).style.background = "#f2f4f6";
                              document.getElementById("asset-th").style.color =
                                "#636a75";
                            }}
                            onChange={({ target }) => {
                              const { name } = target;
                              let arr = [...assetType];
                              if (name === "ALL") {
                                if (arr.length === 3) {
                                  arr = [];
                                } else {
                                  arr = [
                                    ...assetCheckBox.map((item) => {
                                      return item.key;
                                    }),
                                  ];
                                }
                                setCondition({
                                  ...condition,
                                  assetType: arr,
                                });
                              } else {
                                if (arr.includes(name)) {
                                  arr = arr.filter((item) => item !== name);
                                } else {
                                  arr.push(name);
                                }
                                setCondition({
                                  ...condition,
                                  assetType: arr,
                                });
                              }
                            }}
                          />
                          <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                            {item.title}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </AssetCheckBoxWrap>
              </td>
              <th id="trade-th">거래형태</th>
              <td colSpan={3}>
                <AssetCheckBoxWrap>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                    }}
                  >
                    <label>
                      <Checkbox
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "3px",
                          borderRadius: "0.5rem",
                          border: "1px solid #bebebe",
                        }}
                        name={"ALL"}
                        checked={tradeType.length === 2 ? true : false}
                        onFocus={() => {
                          document.getElementById("trade-th").style.transition =
                            "all .4s ease-in-out";
                          document.getElementById("trade-th").style.color =
                            "#4583ee";
                          document.getElementById("trade-th").style.background =
                            "#c9e2ff";
                        }}
                        onBlur={() => {
                          document.getElementById("trade-th").style.transition =
                            "all .4s ease-in-out";
                          document.getElementById("trade-th").style.background =
                            "#f2f4f6";
                          document.getElementById("trade-th").style.color =
                            "#636a75";
                        }}
                        onChange={({ target }) => {
                          const { name } = target;
                          let arr = [...tradeType];
                          if (name === "ALL") {
                            if (arr.length === 2) {
                              arr = [];
                            } else {
                              arr = [
                                ...tradeCheckBox.map((item) => {
                                  return item.key;
                                }),
                              ];
                            }
                            setCondition({
                              ...condition,
                              tradeType: arr,
                            });
                          }
                        }}
                      />
                      <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                        전체
                      </span>
                    </label>
                  </div>
                  {tradeCheckBox.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "3px",
                        }}
                        key={`tradeCheckbox_${index}`}
                      >
                        <label>
                          <Checkbox
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "3px",
                              borderRadius: "0.5rem",
                              border: "1px solid #bebebe",
                            }}
                            name={item.key}
                            checked={tradeType.includes(item.key)}
                            onFocus={() => {
                              document.getElementById(
                                "trade-th"
                              ).style.transition = "all .4s ease-in-out";
                              document.getElementById("trade-th").style.color =
                                "#4583ee";
                              document.getElementById(
                                "trade-th"
                              ).style.background = "#c9e2ff";
                            }}
                            onBlur={() => {
                              document.getElementById(
                                "trade-th"
                              ).style.transition = "all .4s ease-in-out";
                              document.getElementById(
                                "trade-th"
                              ).style.background = "#f2f4f6";
                              document.getElementById("trade-th").style.color =
                                "#636a75";
                            }}
                            onChange={({ target }) => {
                              const { name } = target;
                              let arr = [...tradeType];
                              if (name === "ALL") {
                                if (arr.length === 3) {
                                  arr = [];
                                } else {
                                  arr = [
                                    ...tradeCheckBox.map((item) => {
                                      return item.key;
                                    }),
                                  ];
                                }
                                setCondition({
                                  ...condition,
                                  tradeType: arr,
                                });
                              } else {
                                if (arr.includes(name)) {
                                  arr = arr.filter((item) => item !== name);
                                } else {
                                  arr.push(name);
                                }
                                setCondition({
                                  ...condition,
                                  tradeType: arr,
                                });
                              }
                            }}
                          />
                          <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                            {item.title}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </AssetCheckBoxWrap>
              </td>
            </tr>
            <tr>
              <th id="date-from-th">검색기간</th>
              <td colSpan={4}>
                <div
                  style={{
                    widows: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "flex-start",
                  }}
                >
                  <DropDownList
                    style={{ width: 100, marginRight: 10 }}
                    options={[
                      { label: "요청일", value: "CREATED" },
                      { label: "체결일", value: "CONTRACTED" },
                    ]}
                    selected={condition.dateType}
                    onChange={(value) => {
                      setCondition({
                        ...condition,
                        dateType: value,
                      });
                    }}
                    onFocus={() => {
                      document.getElementById("date-from-th").style.transition =
                        "all .4s ease-in-out";
                      document.getElementById("date-from-th").style.color =
                        "#4583ee";
                      document.getElementById("date-from-th").style.background =
                        "#c9e2ff";
                    }}
                    onBlur={() => {
                      document.getElementById("date-from-th").style.transition =
                        "all .4s ease-in-out";
                      document.getElementById("date-from-th").style.background =
                        "#f2f4f6";
                      document.getElementById("date-from-th").style.color =
                        "#636a75";
                    }}
                  />
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      id="search-from-date"
                      placeholder="날짜를 선택해주세요"
                      format={"yyyy-MM-dd"}
                      defaultValue={new Date(moment().subtract(1, "week"))}
                      value={
                        startDate !== "Invalid date" ? new Date(startDate) : ""
                      }
                      max={new Date()}
                      onFocus={() => {
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById("date-from-th").style.color =
                          "#4583ee";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#c9e2ff";
                      }}
                      onBlur={() => {
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                      onChange={(event) => {
                        const { value } = event;
                        setCondition({
                          ...condition,
                          startDate: moment(value).format("YYYY-MM-DD"),
                        });
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                    />
                  </div>
                  &nbsp; &nbsp;
                  <span>-</span>
                  &nbsp; &nbsp;
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      id="search-to-date"
                      placeholder="날짜를 선택해주세요"
                      format={"yyyy-MM-dd"}
                      defaultValue={new Date()}
                      value={
                        endDate !== "Invalid date" ? new Date(endDate) : ""
                      }
                      max={new Date()}
                      min={new Date(startDate)}
                      onChange={(event) => {
                        const { value } = event;
                        setCondition({
                          ...condition,
                          endDate: moment(value).format("YYYY-MM-DD"),
                        });
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                      onFocus={() => {
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById("date-from-th").style.color =
                          "#4583ee";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#c9e2ff";
                      }}
                      onBlur={() => {
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={8}>
                <ButtonWrap>
                  <SearchBtn onClick={onClickSearch}>검색</SearchBtn>
                  <InitBtn
                    onClick={() => {
                      setCondition({ ...INIT });
                    }}
                  >
                    초기화
                  </InitBtn>
                  <RefreshBtn onClick={onClickRefresh}>
                    <FcRefresh style={{ fontSize: "20px" }} />
                  </RefreshBtn>
                  <ExcelBtn onClick={onClickExcelDownload}>Excel</ExcelBtn>
                </ButtonWrap>
              </td>
            </tr>
          </tbody>
        </Table>
      </FilterWrap>
      <div
        style={{
          width: "100%",
          height: "15px",
          display: "flex",
          margin: "5px",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <div>
            <RadioButton
              label="최근 순서로"
              value="DESC"
              onChange={onChangeSort}
              checked={condition.sort === "DESC" ? true : false}
            />
          </div>
          &nbsp; &nbsp;
          <div>
            <RadioButton
              label="오래된 순서로"
              value="ASC"
              onChange={onChangeSort}
              checked={condition.sort === "ASC" ? true : false}
            />
          </div>
        </div>
      </div>
      <ListWrap expanded={expanded}>
        <GridTable
          name={gridName}
          style={{
            height: "100%",
          }}
          selectable={true}
          data={data}
          scrollable={true}
          skip={page.page}
          take={page.size}
          total={page.totalElements}
          pageable
          reorderable
          onPageChange={onPageChange}
        >
          <Column title="No" width={60} field="index" cell={centerCell} />
          <Column title="자산" width={50} field="assetType" cell={centerCell} />
          <Column
            title="거래유형"
            width={70}
            field="tradeType"
            cell={centerCell}
          />
          <Column
            title="전화번호"
            width={150}
            field="phone"
            cell={centerCell}
          />
          <Column
            title="생년월일"
            width={100}
            field="birthday"
            cell={centerCell}
          />
          <Column title="고객명" width={100} field="name" cell={centerCell} />
          <Column
            title="입금자"
            width={100}
            field="accountHolder"
            cell={centerCell}
          />
          <Column
            title="매장명"
            width={100}
            field="shopName"
            cell={centerCell}
          />
          <Column
            title="매장 수수료"
            width={150}
            field="shopFee"
            cell={numberWithCommasCell}
          />
          <Column
            title="요청일"
            width={110}
            field="createdAt"
            cell={centerCell}
          />
          <Column
            title="중량(금 g/ 은 kg)"
            width={140}
            field="tradeGram"
            cell={numberWithCommasCell}
          />
          <Column
            title="주문금액"
            width={150}
            field="tradeKrw"
            cell={numberWithCommasCell}
          />
          <Column
            title="중개수수료"
            width={150}
            field="fee"
            cell={numberWithCommasCell}
          />
          <Column
            title="수수료율"
            width={90}
            field="feeRate"
            cell={numberWithCommasCell}
          />
          <Column
            title="결제총액"
            width={150}
            field="contractedPrice"
            cell={numberWithCommasCell}
          />
          <Column
            title="체결일"
            width={110}
            field="contractedAt"
            cell={centerCell}
          />
        </GridTable>
      </ListWrap>
    </HistoryWrap>
  );
};

export default TransactionConsignmentHistory;

const HistoryWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  user-select: none;
  .k-grid-table {
    overflow-y: scroll;
  }
`;

const Title = styled.div`
  width: 50%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 10px 10px;
`;

const FilterWrap = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.expanded ? "210px" : "0px")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  margin-top: 10px;
`;

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
  th {
    width: 100px;
    background: #f2f4f6;
    text-align: left;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #636a75;
    user-select: none;
  }
  th,
  td {
    border: 1px solid #e2e5e8;
    border-left: none;
    border-right: none;
  }
`;

const ButtonWrap = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: none;
  background: #000;
  color: #fff;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
`;

const InitBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const RefreshBtn = styled.button`
  width: 50px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const ExcelBtn = styled.button`
  width: 100px;
  height: 35px;
  background: #64a758;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  color: #fff;
  font-weight: 500;
  &:hover {
    opacity: 0.7;
  }
`;

const ListWrap = styled.div`
  width: 100%;
  height: ${(props) =>
    props.expanded ? "calc(100% - 315px)" : "calc(100% - 100px)"};
  max-height: ${(props) => (props.expanded ? "59vh" : "82vh")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  background: gray;
  .k-grid-header-wrap {
    th {
      background: #f2f4f6;
      color: #636a75;
      font-weight: bold;
      text-align: center;
    }
  }
  .k-column-resizer {
    background: #f2f4f6;
  }
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const AssetCheckBoxWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
