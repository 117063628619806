import React from "react";
import styled from "styled-components";
import KendoButton from "../button/KendoButton";
import Flex from "../flex/Flex";

const ButtonTabs = ({
  selected = "",
  options = [],
  onSelect = () => {},
  ...rest
}) => {
  return (
    <ButtonTabsContainer {...rest}>
      {options.map(({ label, value }) => {
        return (
          <KendoButton
            label={label}
            onClick={() => onSelect(value)}
            className={value === selected ? "selected" : ""}
            fillMode="flat"
          />
        );
      })}
    </ButtonTabsContainer>
  );
};

const ButtonTabsContainer = styled(Flex)`
  flex-direction: row;
  border-bottom: 1px solid #e2e5e8;

  button {
    &.selected {
      background: black !important;
      color: white;
    }
  }
`;
export default ButtonTabs;
