import styled from "@emotion/styled";

const HistoryWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  user-select: none;
  .k-grid-table {
    overflow-y: scroll;
  }
`;

const Title = styled.div`
  width: 50%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 10px 10px;
`;

const FilterWrap = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.expanded ? "100px" : "0px")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  margin-top: 10px;
`;

const Table = styled.table`
  width: 100%;
  height: 100px;
  /* border-top: 1px solid #444; */
  /* border-bottom: 1px solid #444; */
  border-collapse: collapse;
  th {
    width: 100px;
    background: #f2f4f6;
    text-align: left;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #636a75;
    user-select: none;
  }
  th,
  td {
    border: 1px solid #e2e5e8;
    border-left: none;
    border-right: none;
  }
`;
const DayBtn = styled.button`
  width: 100px;
  height: 35px;
  background: #3e90cf;
  border: none;
  margin: 3px;
  color: #fff;
  border-radius: 0.3rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  &:hover {
    opacity: 0.7;
  }
`;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: none;
  background: #000;
  color: #fff;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
`;

const InitBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const RefreshBtn = styled.button`
  width: 50px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const ListWrap = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.expanded ? "calc(100% - 220px)" : "calc(100%)")};
  max-height: ${(props) => (props.expanded ? "70vh" : "85vh")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  background: gray;
  .k-grid-header-wrap {
    th {
      background: #f2f4f6;
      color: #636a75;
      font-weight: bold;
      text-align: center;
    }
  }
  .k-column-resizer {
    background: #f2f4f6;
  }
  .penaltyHistory .k-grid tr {
    cursor: pointer;
  }
`;

export {
  HistoryWrap,
  TitleWrap,
  Title,
  FilterWrap,
  Table,
  DayBtn,
  ButtonWrap,
  SearchBtn,
  InitBtn,
  RefreshBtn,
  ListWrap,
};
