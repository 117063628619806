import styled from "styled-components";
import React, { useEffect, useMemo, useState, useRef } from "react";

import fonts from "libs/fonts";
import * as APIS from "libs/apis";
import { useDispatch } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

import { objToQueryStr } from "services/utils";
import { formatTime, numberWithCommas } from "libs/utils";
import { closeModal, openModal } from "redux/modal/ModalReducer";
import { getter } from "@progress/kendo-react-common";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import Flex from "components/flex/Flex";
import TextInput from "components/input/TextInput";
import DateInput from "components/input/DateInput";
import SmallTitle from "components/title/SmallTitle";
import KendoButton from "components/button/KendoButton";
import DropDownList from "components/select/DropDownList";
import GridTable, { centerCell } from "components/gird-table/GridTable";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import { makeStyles } from "@material-ui/core";
import RequestCancelModal from "./RequestCancelModal";

const INIT_STATE = {
  startDate: "",
  endDate: "",
  shopName: "",
  userName: "",
  assetType: "",
  dateType: "",
  stockStatus: "",
  number: "",
  page: 0,
  size: 10,
  total: 0,
};

const ITEM_TYPE_OPTIONS = [
  { label: "원재료", value: "RAW_MATERIALS" },
  { label: "정련", value: "REFINE" },
  { label: "상품", value: "GOODS" },
];

const STOCK_STATUS_OPTIONS = [
  { label: "재고 수령", value: "TAKE" },
  { label: "입고 완료", value: "COMPLETE" },
  { label: "입고 취소요청", value: "CANCEL_WAIT" },
];

const DATA_ITEM_KEY = "number";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const AppraisalInspection = () => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [products, setProducts] = useState([]);
  const [goodsCategoryList, setGoodsCategoryList] = useState([]);
  const [searchState, setSearchState] = useState({ ...INIT_STATE });
  const [searchGridList, setSearchGridList] = useState([]); // 그리드 리스트
  const [searchList, setSearchList] = useState([]); //상세보기 리스트
  const [selectedNumber, setSelectedNumber] = useState("");
  const [cancelMessage, setCancelMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [show, setShow] = useState(false);
  const [rawMaterialsList, setRawMaterialsList] = useState([]);
  const [refineList, setRefineList] = useState([]);
  const [goodsList, setGoodsList] = useState([]);
  const [stockCheck, setStockCheck] = useState(""); //상태가 입고완료인지 아닌지의 true/false
  const [clickStockStatus, setClickStockStatus] = useState(""); //재질분류 상태 파악
  const [selectedState, setSelectedState] = useState({});
  const [statusSummary, setStatusSummary] = useState([]);

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    dispatch(loadingStart);
    // 품목 재질, 분류 조회 리스트
    const itemCategory = APIS.getInventoryItemCategory()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setProducts(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
    // 상품 리스트
    const goodsList = APIS.getInventoryGoodsList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setGoodsCategoryList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
    const statusSummary = getStatusSummary();
    Promise.all([itemCategory, goodsList, statusSummary]).finally(() => {
      dispatch(loadingEnd);
    });
  }, []);

  useEffect(() => {
    if (searchState.dateType === "") {
      handleChangeSearchValue({ startDate: "", endDate: "" });
    }
  }, [searchState.dateType]);

  const handleChangeSearchValue = (value = {}) => {
    setSearchState({ ...searchState, ...value });
  };

  const handleClickSearch = () => {
    searchInspectionList(searchState);
  };
  const handleChangePage = ({ page }) => {
    searchInspectionList({
      ...searchState,
      page: page.skip / page.take,
      size: page.take,
    });
  };
  const getStatusSummary = () => {
    APIS.getInventoryStatusSummary()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setStatusSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
  };

  const searchInspectionList = (param, status) => {
    dispatch(loadingStart);
    APIS.getInventoryInspection({ ...param })
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const result = [];
          const searchTotalList = [];
          data.content.forEach((appraisalItem) => {
            const { number, assetType, exchangeGram, stockStatus, stockedAt } =
              appraisalItem;
            const appraisalInfo = {
              ...appraisalItem,
              appraisalId: number,
              statusKor: (
                STOCK_STATUS_OPTIONS.find(
                  ({ value }) => value === stockStatus
                ) || {}
              ).label,
            };

            // item 목록이 없는 경우
            if (!appraisalItem.itemList.length) {
              result.push(appraisalInfo);
              searchTotalList.push(appraisalInfo);
            }

            const inEdit = stockStatus === "TAKE";

            appraisalItem.itemList.forEach((item, i) => {
              const customItem = {
                ...item,
                inEdit,
                stockStatus,
                assetType,
                exchangeGram,
                appraisalId: number,
                stockedAt: stockedAt,
                isDelete: item.status === "CANCEL",
              };

              if (inEdit && !item.inspectProductWeightGram) {
                customItem.inspectProductWeightGram = item.productWeightGram;
              }
              if (
                inEdit &&
                !item.inspectWeightGram &&
                item.itemType !== "GOODS"
              ) {
                customItem.inspectWeightGram = item.appraisalWeightGram;
              }
              if (i === 0) {
                result.push({ ...appraisalInfo, ...customItem });
                searchTotalList.push({ ...appraisalInfo, ...customItem });
              } else {
                result.push(customItem);
              }
            });
          });
          setSearchList(result);
          setSearchGridList(
            searchTotalList.map((item) => {
              return {
                ...item,
                assetType: item.assetType
                  .replace("GOLD", "금")
                  .replace("SILVER", "은"),
                takenAt: formatTime(item.takenAt, "YYYY-MM-DD"),
              };
            })
          );
          setSelectedNumber("");
          if (status) {
            setSearchState({
              ...INIT_STATE,
              page: data.number,
              size: data.size,
              total: data.totalElements,
              stockStatus: status,
            });
          } else {
            handleChangeSearchValue({
              page: data.number,
              size: data.size,
              total: data.totalElements,
            });
          }
        }
      })
      .finally(() => dispatch(loadingEnd));
  };
  //상세 그리드 item change
  const handleItemChange = (e, type) => {
    const { dataItem, field, value } = e;
    const keyInput = e.nativeEvent.data;
    const searchList =
      type === "rawMaterials"
        ? rawMaterialsList
        : type === "goods"
        ? goodsList
        : type === "refine"
        ? refineList
        : [];
    const numberValue = value.replace(/[^0-9.]/g, "");

    // 숫자 또는 점만 입력 가능
    if (isNaN(keyInput) && keyInput !== ".") {
      return false;
    }

    // 소수점 한개만 입력 가능
    if (numberValue.split(".").length > 2) {
      return false;
    }

    const { id, addId } = dataItem;

    const data = searchList.map((item) =>
      (addId ? item.addId === addId : item.id === id)
        ? { ...item, [field]: numberValue }
        : item
    );
    if (type === "rawMaterials") {
      setRawMaterialsList(data);
    } else if (type === "goods") {
      setGoodsList(data);
    } else if (type === "refine") {
      setRefineList(data);
    }
  };

  //품목 dropbox change 할 때
  const handleChangeProduct = (type, addId, productId) => {
    const product = products.find(({ id }) => id === productId);
    let copied = [];
    if (type === "goods") {
      copied = [...goodsList];
    } else if (type === "refine") {
      copied = [...refineList];
    } else if (type === "rawMaterials") {
      copied = [...rawMaterialsList];
    }
    const productData = {
      productId,
      purityType: product.purityType,
      itemType: product.itemType,
    };

    const data = copied.map((item) =>
      item.addId === addId ? { ...item, ...productData } : item
    );

    if (type === "goods") {
      setGoodsList(data);
    } else if (type === "refine") {
      setRefineList(data);
    } else if (type === "rawMaterials") {
      setRawMaterialsList(data);
    }
  };

  //상품 카테고리 dropbox change 할 때
  const handleChangeGoodCategory = (addId, goodsId, dataIndex) => {
    const good = goodsCategoryList.find(
      ({ goodsCategoryId }) => goodsCategoryId === goodsId
    );
    let copied = [...goodsList];
    const goodCategoryData = {
      categoryId: goodsId,
      goodsList: good.goodsList,
      goodCategoryName: good.name,
      goodId: "",
      optionsList: [],
      stockQuantity: "",
      gram: "",
    };
    const data = copied.map((item, index) =>
      index === dataIndex && item.addId === addId
        ? { ...item, ...goodCategoryData }
        : item
    );
    setGoodsList(data);
  };

  //제품명 dropbox change 할 때
  const handleChangeGoodName = (addId, goodId, categoryId, dataIndex) => {
    const good = goodsCategoryList
      .find(({ goodsCategoryId }) => goodsCategoryId === categoryId)
      .goodsList.find(({ goodsId }) => goodsId === goodId);

    let copied = [...goodsList];
    const goodCategoryData = {
      goodId,
      goodName: good.name,
      gram: good.gram,
      optionsList: good.optionsList,
      selectOptionId: "",
      stockQuantity: "",
    };
    const data = copied.map((item, index) =>
      dataIndex === index && item.addId === addId
        ? { ...item, ...goodCategoryData }
        : item
    );
    setGoodsList(data);
  };

  //옵션명 dropbox change 할 때
  const handleChangeGoodOption = (
    addId,
    optionId,
    goodId,
    categoryId,
    dataIndex
  ) => {
    const selectOption = goodsCategoryList
      .find(({ goodsCategoryId }) => goodsCategoryId === categoryId)
      .goodsList.find(({ goodsId }) => goodsId === goodId)
      .optionsList.find(({ id }) => id === optionId);
    let copied = [...goodsList];
    const goodCategoryData = {
      selectOptionId: selectOption.id,
      selectOptionName: selectOption.value,
    };

    const data = copied.map((item, index) =>
      dataIndex === index && item.addId === addId
        ? { ...item, ...goodCategoryData }
        : item
    );
    setGoodsList(data);
  };

  //품목 추가
  const handleClickAddItem = (type) => {
    const selected = searchList.filter(
      ({ appraisalId, isDelete = false }) =>
        appraisalId === selectedNumber && !isDelete
    );
    let copied = [];
    const addId = +new Date();
    if (type === "goods") {
      copied = [...goodsList];
    } else if (type === "refine") {
      copied = [...refineList];
    } else if (type === "rawMaterials") {
      copied = [...rawMaterialsList];
    }
    const firstItem = copied[0];
    let addItem = {};

    if (firstItem) {
      addItem = {
        addId,
        appraisalId: firstItem.appraisalId || firstItem.number,
        inEdit: true,
        stockStatus: "TAKE",
        assetType: firstItem.assetType,
      };
    } else {
      addItem = {
        addId,
        appraisalId: selected[0].appraisalId,
        inEdit: true,
        stockStatus: "TAKE",
        assetType: selected[0].assetType,
      };
    }
    if (type === "goods") {
      setGoodsList(copied.concat(addItem));
    } else if (type === "refine") {
      setRefineList(copied.concat(addItem));
    } else if (type === "rawMaterials") {
      setRawMaterialsList(copied.concat(addItem));
    }
  };

  //품목 삭제
  const handleClickDelete = (dataIndex, isDelete, gridType) => {
    if (gridType === "rawMaterials") {
      const dataItem = rawMaterialsList[dataIndex];
      if (dataItem.addId) {
        setRawMaterialsList(
          rawMaterialsList.filter(({ addId }) => addId !== dataItem.addId)
        );
      } else {
        setRawMaterialsList(
          rawMaterialsList.map((item, i) => {
            return i === dataIndex
              ? { ...item, isDelete: !isDelete, inEdit: isDelete }
              : item;
          })
        );
      }
    } else if (gridType === "refine") {
      const dataItem = refineList[dataIndex];
      if (dataItem.addId) {
        setRefineList(
          refineList.filter(({ addId }) => addId !== dataItem.addId)
        );
      } else {
        setRefineList(
          refineList.map((item, i) => {
            return i === dataIndex
              ? { ...item, isDelete: !isDelete, inEdit: isDelete }
              : item;
          })
        );
      }
    } else if (gridType === "goods") {
      const dataItem = goodsList[dataIndex];
      if (dataItem.addId) {
        setGoodsList(goodsList.filter(({ addId }) => addId !== dataItem.addId));
      } else {
        setGoodsList(
          goodsList.map((item, i) => {
            return i === dataIndex
              ? { ...item, isDelete: !isDelete, inEdit: isDelete }
              : item;
          })
        );
      }
    }
  };
  //감정입고
  const handleClickPost = (appraisalId) => {
    // 감정 총 중량
    const totalAppraisalGram = selectedSummary.find(
      (item) => item.label === "검품 총 중량"
    ).sum;
    // 교환 중량
    const { exchangeGram, id } =
      searchList.filter(
        ({ appraisalId: itemAppraisalId }) => itemAppraisalId === appraisalId
      )[0] || {};

    const itemList = [
      ...rawMaterialsList.map((item) => {
        return {
          ...item,
          productId: item.productId,
          inspectProductWeightGram: (
            item.inspectProductWeightGram + ""
          ).replace(/[^0-9.]/g, ""), //측정 실중량
          inspectWeightGram: (item.inspectWeightGram + "").replace(
            /[^0-9.]/g,
            ""
          ),
          itemType: item.itemType,
          isDeleted: !!item.isDelete,
          stockQuantity: item.stockQuantity, // 수량
          goodsId: item.goodsId, // 상품 아이디
        };
      }),
      ...refineList.map((item) => {
        return {
          ...item,
          productId: item.productId,
          inspectProductWeightGram: (
            item.inspectProductWeightGram + ""
          ).replace(/[^0-9.]/g, ""), //측정 실중량
          inspectWeightGram: (item.inspectWeightGram + "").replace(
            /[^0-9.]/g,
            ""
          ),
          itemType: item.itemType,
          isDeleted: !!item.isDelete,
          stockQuantity: item.stockQuantity, // 수량
          goodsId: item.goodsId, // 상품 아이디
        };
      }),
      ...goodsList.map((item) => {
        const inspectWeightGram = item.gram * Number(item.stockQuantity);
        return {
          ...item,
          productId: item.productId,
          inspectWeightGram: (inspectWeightGram + "").replace(/[^0-9.]/g, ""),
          itemType: item.itemType,
          isDeleted: !!item.isDelete,
          stockQuantity: item.stockQuantity, // 수량
          goodsId: item.goodId, // 상품 아이디
          optionId: item.selectOptionId,
        };
      }),
    ];
    const errorArr = itemList.filter((item) => {
      const {
        isDeleted,
        itemType,
        optionsList,
        selectOptionId,
        goodId,
        goodsList,
        stockQuantity,
        productId,
      } = item;
      return (
        !productId ||
        (itemType === "GOODS" &&
          isDeleted === false &&
          ((optionsList?.length && !selectOptionId) ||
            (goodsList?.length && !goodId) ||
            stockQuantity === "" ||
            !stockQuantity))
      );
    });

    if (errorArr.length !== 0) {
      dispatch(
        actionError(
          "비어있는 입력 값이 있습니다.\n값을 입력하였는지 확인해주세요."
        )
      );
    } else if (
      Math.floor(exchangeGram * 100) !== Math.floor(totalAppraisalGram * 100)
    ) {
      // 소수점 2자리 까지만 비교
      dispatch(
        actionError(
          "교환 중량과 검품 총 중량이 일치하지 않습니다.\n데이터를 확인해주세요."
        )
      );
    } else {
      dispatch(
        actionOpen(
          "입고 처리 하시겠습니까?",
          () => {
            const req = {
              number: appraisalId,
              itemList: itemList.map((item) => {
                return {
                  id: item.id || null,
                  productId: item.productId,
                  inspectProductWeightGram: item.inspectProductWeightGram,
                  inspectWeightGram: item.inspectWeightGram,
                  itemType: item.itemType,
                  isDeleted: !!item.isDeleted,
                  stockQuantity: item.stockQuantity,
                  goodsId: item.goodsId,
                  optionId: item.optionId,
                };
              }),
            };
            dispatch(loadingStart);
            APIS.postInventoryInspection(req)
              .then(({ data: { success, data, message } }) => {
                if (success) {
                  handleClickSearch();
                } else {
                  dispatch(actionError(message));
                }
              })
              .finally(() => dispatch(loadingEnd));
          },
          "",
          "",
          false
        )
      );
    }
  };
  //입고 취소 요청 승인
  const handleClickCancel = (adminPassword, selectState) => {
    const data = { number: selectState };
    data["managerPassword"] = adminPassword;
    dispatch(loadingStart);
    APIS.postInventoryCancel(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "입고 취소 요청 승인되었습니다.",
              () => {
                handleClickSearch();
                getStatusSummary();
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const selectedSummary = useMemo(() => {
    const selected = searchList.filter(
      ({ appraisalId, isDelete = false }) =>
        appraisalId === selectedNumber && !isDelete
    );
    if (!selected.length) return [];
    const sum = {};

    let total = 0;
    let totalExchangeGram = 0;
    selected.forEach(({ itemType, exchangeGram }) => {
      totalExchangeGram = exchangeGram;
    });
    refineList.forEach(({ inspectWeightGram, isDelete }) => {
      inspectWeightGram = !isDelete ? inspectWeightGram : 0;
      const numberValue = (inspectWeightGram + "").replace(/[^0-9.]/g, "");
      sum["REFINE"] = Number(((sum["REFINE"] || 0) + +numberValue).toFixed(4));
    });
    goodsList.forEach(
      ({ inspectWeightGram, gram, stockQuantity, isDelete }) => {
        inspectWeightGram = !isDelete ? inspectWeightGram : 0;
        const numberValue = (inspectWeightGram + "").replace(/[^0-9.]/g, "");
        const calWeightGram = !isDelete ? gram * Number(stockQuantity) : 0;
        const sumValue = (calWeightGram + "").replace(/[^0-9.]/g, "");
        sum["GOODS"] = Number(
          ((sum["GOODS"] || 0) + +numberValue + +sumValue).toFixed(4)
        );
      }
    );
    rawMaterialsList.forEach(({ inspectWeightGram, isDelete }) => {
      inspectWeightGram = !isDelete ? inspectWeightGram : 0;
      const numberValue = (inspectWeightGram + "").replace(/[^0-9.]/g, "");
      sum["RAW_MATERIALS"] = Number(
        ((sum["RAW_MATERIALS"] || 0) + +numberValue).toFixed(4)
      );
    });
    total = Number(
      Number(
        (sum["REFINE"] || 0) + (sum["GOODS"] || 0) + (sum["RAW_MATERIALS"] || 0)
      ).toFixed(4)
    );
    const result = ITEM_TYPE_OPTIONS.map(({ label, value }) => {
      return { label, sum: sum[value] || 0 };
    });
    return [
      { label: "교환 총 중량", sum: totalExchangeGram },
      { label: "검품 총 중량", sum: total },
      ...result,
    ];
  }, [selectedNumber, searchList, rawMaterialsList, refineList, goodsList]);

  useEffect(() => {
    const selected = searchList.filter(
      ({ appraisalId, isDelete = false }) =>
        appraisalId === selectedNumber && !isDelete
    );
    setRawMaterialsList(
      selected.filter((item) => item.itemType === "RAW_MATERIALS")
    );
    setRefineList(selected.filter((item) => item.itemType === "REFINE"));
    setGoodsList(selected.filter((item) => item.itemType === "GOODS"));
  }, [selectedNumber]);

  return (
    <AppraisalInspectionContainer>
      <PanelBar>
        <PanelBarItem expanded={true} title="검품 및 재질 분류">
          <SearchSection
            onKeyPress={(e) => {
              if (e?.nativeEvent?.key === "Enter") {
                handleClickSearch();
              }
            }}>
            <Flex row>
              <Flex className="th">조회기간</Flex>
              <Flex className="td">
                <DropDownList
                  style={{ width: 160 }}
                  options={[
                    { label: "교환완료일", value: "COMPLETED" },
                    { label: "재고수령일", value: "TOOK" },
                    { label: "입고일", value: "STOCKED" },
                  ]}
                  selected={searchState.dateType ? searchState.dateType : ""}
                  onChange={(value) =>
                    handleChangeSearchValue({ dateType: value })
                  }
                  useAll
                />
                <DateInputFilterWrap>
                  <DateInputFilter
                    onChange={(value) =>
                      handleChangeSearchValue({ startDate: value })
                    }
                    value={searchState.startDate ? searchState.startDate : ""}
                    maxDate={new Date()}
                    disabled={searchState.dateType === "" ? true : false}
                  />
                  <span>~</span>
                  <DateInputFilter
                    onChange={(value) =>
                      handleChangeSearchValue({ endDate: value })
                    }
                    value={searchState.endDate ? searchState.endDate : ""}
                    minDate={new Date(searchState.startDate)}
                    disabled={searchState.dateType === "" ? true : false}
                  />
                </DateInputFilterWrap>
              </Flex>
              <Flex className="th">회원검색</Flex>
              <Flex className="td">
                <TextInput
                  value={searchState.userName ? searchState.userName : ""}
                  onChange={(value) =>
                    handleChangeSearchValue({ userName: value })
                  }
                  autoFocus
                />
              </Flex>
              <Flex className="th">접수번호</Flex>
              <Flex className="td">
                <TextInput
                  value={searchState.number ? searchState.number : ""}
                  onChange={(value) =>
                    handleChangeSearchValue({ number: value })
                  }
                  style={{ width: 300, marginRight: 10 }}
                />
                <KendoButton
                  style={{ width: 70 }}
                  onClick={() => {
                    dispatch(
                      openModal({
                        visiable: true,
                        children: (
                          <MultipleSearch
                            searchInspectionList={searchInspectionList}
                            searchState={searchState}
                          />
                        ),
                      })
                    );
                  }}>
                  복수조회
                </KendoButton>
              </Flex>
            </Flex>
            <Flex row>
              <Flex className="th">대리점</Flex>
              <Flex className="td">
                <TextInput
                  value={searchState.shopName ? searchState.shopName : ""}
                  onChange={(value) =>
                    handleChangeSearchValue({ shopName: value })
                  }
                />
              </Flex>
              <Flex className="th">자산</Flex>
              <Flex className="td">
                <DropDownList
                  options={[
                    { label: "금", value: "GOLD" },
                    { label: "은", value: "SILVER" },
                  ]}
                  selected={searchState.assetType ? searchState.assetType : ""}
                  onChange={(value) =>
                    handleChangeSearchValue({ assetType: value })
                  }
                  useAll
                />
              </Flex>
              <Flex className="th">상태</Flex>
              <Flex className="td">
                <DropDownList
                  options={STOCK_STATUS_OPTIONS}
                  selected={
                    searchState.stockStatus ? searchState.stockStatus : ""
                  }
                  onChange={(value) =>
                    handleChangeSearchValue({ stockStatus: value })
                  }
                  useAll
                />
              </Flex>
            </Flex>
            <Flex
              row
              style={{ justifyContent: "center", padding: "10px 0 10px 0" }}>
              <KendoButton
                onClick={() => setSearchState(INIT_STATE)}
                themeColor="primary">
                초기화
              </KendoButton>
              <KendoButton
                onClick={handleClickSearch}
                style={{ marginLeft: 10 }}>
                검색
              </KendoButton>
            </Flex>
          </SearchSection>
        </PanelBarItem>
      </PanelBar>
      <Flex row>
        <SmallTitle>취소요청 :</SmallTitle>
        <button
          onClick={() => {
            const searchState = { ...INIT_STATE, stockStatus: "CANCEL_WAIT" };
            searchInspectionList(searchState, "CANCEL_WAIT");
          }}
          style={{ border: "none", backgroundColor: "unset" }}>
          {`${
            statusSummary.length !== 0
              ? statusSummary?.find(
                  ({ stockStatus }) => stockStatus === "CANCEL_WAIT"
                )?.count
              : 0
          }건`}
        </button>
      </Flex>
      <Flex style={{ flex: 1 }}>
        <Grid
          style={{ height: 640 }}
          scrollable="scrollable"
          data={searchGridList.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          onRowClick={(e) => {
            if (show === false) {
              setShow(true);
            }
            setSelectedNumber(e.dataItem.appraisalId);
            setCancelMessage(e.dataItem.cancelMessage);
            setUserId(e.dataItem.userId);
            setStockCheck(e.dataItem.stockStatus === "COMPLETE");
            setClickStockStatus(e.dataItem.stockStatus);
          }}
          pageable={{ pageSizes: true, pageSizes: [10, 30, 50, 100] }}
          total={searchState.total}
          pageSize={searchState.size}
          skip={searchState.page * searchState.size}
          onSelectionChange={onSelectionChange}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
          }}
          onPageChange={handleChangePage}>
          <GridColumn
            title="접수번호"
            field="number"
            editable={false}
            width={320}
          />
          <GridColumn
            title="교환완료일"
            field="exchangeCompletedDate"
            editable={false}
            className="center"
          />
          <GridColumn
            title="회원번호"
            field="userId"
            editable={false}
            className="center"
          />
          <GridColumn
            title="사용자명"
            field="userName"
            editable={false}
            className="center"
          />
          <GridColumn title="대리점" field="shopName" editable={false} />
          <GridColumn
            title="상태"
            field="statusKor"
            editable={false}
            className="center"
          />
          <GridColumn
            title="자산"
            field="assetType"
            editable={false}
            className="center"
          />
          <GridColumn
            title="재고수령일"
            field="takenAt"
            editable={false}
            className="item center"
            width={200}
          />
          <GridColumn
            title="입고일"
            field="stockedAt"
            editable={false}
            className="item center"
            width={200}
          />
        </Grid>
        {selectedNumber && (
          <div
            style={{
              position: "absolute",
              maxWidth: "-webkit-fill-available",
              zIndex: 2,
              top: show ? "41%" : "95%",
              left: show ? "" : "50%",
              transition: show ? "top 0.2s cubic-bezier(0, 0, 1, 1)" : "",
              borderRadius: show ? "20px 20px 0% 0%" : "20px 20px 0% 0%",
              border: "2px solid rgba(0, 0, 0, 0.08)",
              borderBottom: "none",
              padding: show ? "11px 6px 0px 6px" : "7px 6px 0px",
              height: show ? 556 : "",
              backgroundColor: "white",
            }}>
            <div style={{ textAlign: "center" }}>
              <button
                className={
                  show
                    ? "k-icon k-i-arrow-seek-down"
                    : "k-icon k-i-arrow-seek-up"
                }
                onClick={onClick}
                style={{
                  width: 120,
                  height: 26,
                  backgroundColor: "white",
                  border: "white",
                }}>
                상세보기
              </button>
            </div>
            {show ? (
              <div
                style={{
                  padding: "0px 45px 0px 30px",
                  overflowY: "scroll",
                  height: 480,
                  wekitScollBar: { display: "none" },
                }}>
                <Flex row style={{ justifyContent: "space-between" }}>
                  <Flex style={{ marginLeft: 5 }}>
                    <h3>재질분류 - 입고처리</h3>
                  </Flex>
                  {clickStockStatus === "CANCEL_WAIT" && cancelMessage && (
                    <Flex style={{ justifyContent: "center" }}>
                      <SmallTitle style={{ color: "red" }}>
                        취소 사유 : {cancelMessage}
                      </SmallTitle>
                    </Flex>
                  )}
                </Flex>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: 0,
                    }}>
                    {selectedSummary.map(({ label, sum }) => {
                      return (
                        <Flex row style={{ border: "1px solid #e2e5e8" }}>
                          <Flex
                            style={{
                              width: 100,
                              textAlign: "center",
                              background: "#e2e5e8",
                            }}>
                            {label}
                          </Flex>
                          <Flex style={{ width: 140, textAlign: "center" }}>
                            {numberWithCommas(sum)}
                          </Flex>
                        </Flex>
                      );
                    })}
                  </div>
                  <div>
                    {clickStockStatus === "TAKE" ? (
                      <KendoButton
                        onClick={() => {
                          handleClickPost(selectedNumber);
                        }}>
                        감정입고
                      </KendoButton>
                    ) : clickStockStatus === "COMPLETE" ? (
                      <KendoButton
                        onClick={() => {
                          dispatch(
                            openModal({
                              visiable: true,
                              children: (
                                <RequestCancelModal
                                  selectedNumber={selectedNumber}
                                  handleClickSearch={handleClickSearch}
                                  infoData={selectedSummary.slice(2)}
                                  userId={userId}
                                  getStatusSummary={getStatusSummary}
                                />
                              ),
                            })
                          );
                        }}>
                        취소요청
                      </KendoButton>
                    ) : clickStockStatus === "CANCEL_WAIT" ? (
                      <AdminConfirmButton
                        rootClassName={classes.confirmRoot}
                        btnClassName={classes.confirmButton}
                        popupMessage="승인 처리 하시겠습니까?"
                        label="취소승인"
                        popupLabel="입고 취소 승인"
                        selectItem={selectedNumber}
                        callback={handleClickCancel}
                      />
                    ) : (
                      <></>
                    )}
                    {/* {!stockCheck && (
                      <KendoButton
                        onClick={() => {
                          handleClickPost(selectedNumber);
                        }}
                      >
                        감정입고
                      </KendoButton>
                    )} */}
                  </div>
                </div>
                <div>
                  <table>
                    <tr style={{ marginBottom: 10 }}>
                      <th>
                        <SmallTitle style={{ width: 50 }}>원재료</SmallTitle>
                      </th>
                      <td>
                        <Grid
                          data={rawMaterialsList}
                          style={{ width: 1547 }}
                          scrollable="none"
                          editField="inEdit"
                          onItemChange={(e) => {
                            handleItemChange(e, "rawMaterials");
                          }}
                          rowRender={(trElement, rowProps) => {
                            const trProps = {
                              ...trElement.props,
                              ["data-id"]: rowProps.dataItem.ProductID,
                              className: `${trElement.props.className} ${
                                rowProps.dataItem.appraisalId ===
                                  selectedNumber && rowProps.dataItem.id
                                  ? "k-selected"
                                  : ""
                              } ${
                                rowProps.dataItem.isDelete ? "is-delete" : ""
                              } ${rowProps.dataItem.number ? "top" : ""}`,
                            };
                            return React.cloneElement(
                              trElement,
                              { ...trProps },
                              trElement.props.children
                            );
                          }}>
                          <GridColumn
                            title="자산"
                            className="item"
                            cell={(props) => {
                              const assetType = props.dataItem.assetType;
                              switch (assetType) {
                                case "GOLD":
                                  return <KendoTd {...props}>금</KendoTd>;
                                case "SILVER":
                                  return <KendoTd {...props}>은</KendoTd>;
                                default:
                                  return <KendoTd {...props} />;
                              }
                            }}
                          />
                          <GridColumn
                            title="순도"
                            className="item"
                            cell={(props) => {
                              const { purityType } = props.dataItem;
                              return (
                                <KendoTd {...props} title={purityType}>
                                  {purityType}
                                </KendoTd>
                              );
                            }}
                          />
                          <GridColumn
                            title="품목"
                            field="name"
                            className="item"
                            editable={false}
                            cell={(props) => {
                              const { addId, name, productId, assetType } =
                                props.dataItem;
                              if (addId) {
                                return (
                                  <KendoTd {...props}>
                                    <DropDownList
                                      options={products
                                        .filter(
                                          (item) =>
                                            item.assetType === assetType &&
                                            item.itemType === "RAW_MATERIALS"
                                        )
                                        .map(({ id, name }) => ({
                                          label: name,
                                          value: id,
                                        }))}
                                      selected={productId}
                                      onChange={(value) =>
                                        handleChangeProduct(
                                          "rawMaterials",
                                          addId,
                                          value
                                        )
                                      }
                                      style={{ width: "100%" }}
                                    />
                                  </KendoTd>
                                );
                              } else {
                                return <KendoTd {...props}>{name}</KendoTd>;
                              }
                            }}
                          />
                          <GridColumn title="대리점">
                            <GridColumn
                              title="측정 실중량(g)"
                              field="productWeightGram"
                              editable={false}
                              className="item right"
                              cell={NumberTd}
                            />
                            <GridColumn
                              title="감정 실중량(g)"
                              field="appraisalWeightGram"
                              editable={false}
                              className="item right"
                              cell={NumberTd}
                            />
                          </GridColumn>
                          <GridColumn title="검품">
                            <GridColumn
                              title="측정 실중량(g)"
                              field="inspectProductWeightGram"
                              className="item right"
                            />
                            <GridColumn
                              title="감정 실중량(g)"
                              field="inspectWeightGram"
                              className="item right"
                            />
                          </GridColumn>
                          {!stockCheck && (
                            <GridColumn
                              headerCell={() => {
                                return (
                                  <div style={{ textAlign: "center" }}>
                                    <KendoButton
                                      style={{ width: 70 }}
                                      onClick={() => {
                                        handleClickAddItem("rawMaterials");
                                      }}>
                                      추가
                                    </KendoButton>
                                  </div>
                                );
                              }}
                              cell={(props) => {
                                const {
                                  id,
                                  addId,
                                  stockStatus,
                                  isDelete = false,
                                  appraisalId,
                                } = props.dataItem;
                                if (stockStatus === "TAKE" && (id || addId)) {
                                  return (
                                    <KendoTd
                                      {...props}
                                      style={{
                                        padding: 0,
                                        textAlign: "center",
                                      }}>
                                      <KendoButton
                                        style={{ width: 70 }}
                                        onClick={() => {
                                          setSelectedNumber(appraisalId);
                                          handleClickDelete(
                                            props.dataIndex,
                                            isDelete,
                                            "rawMaterials"
                                          );
                                        }}>
                                        {isDelete ? "삭제 취소" : "삭제"}
                                      </KendoButton>
                                    </KendoTd>
                                  );
                                } else {
                                  return <KendoTd {...props} />;
                                }
                              }}
                            />
                          )}
                        </Grid>
                      </td>
                    </tr>
                    <tr style={{ marginBottom: 10 }}>
                      <th>
                        <SmallTitle>정련</SmallTitle>
                      </th>
                      <td>
                        <Grid
                          data={refineList}
                          scrollable="none"
                          editField="inEdit"
                          onItemChange={(e) => {
                            handleItemChange(e, "refine");
                          }}
                          rowRender={(trElement, rowProps) => {
                            const trProps = {
                              ...trElement.props,
                              ["data-id"]: rowProps.dataItem.ProductID,
                              className: `${trElement.props.className} ${
                                rowProps.dataItem.appraisalId ===
                                  selectedNumber && rowProps.dataItem.id
                                  ? "k-selected"
                                  : ""
                              } ${
                                rowProps.dataItem.isDelete ? "is-delete" : ""
                              } ${rowProps.dataItem.number ? "top" : ""}`,
                            };
                            return React.cloneElement(
                              trElement,
                              { ...trProps },
                              trElement.props.children
                            );
                          }}>
                          <GridColumn
                            title="자산"
                            className="item"
                            cell={(props) => {
                              const assetType = props.dataItem.assetType;
                              switch (assetType) {
                                case "GOLD":
                                  return <KendoTd {...props}>금</KendoTd>;
                                case "SILVER":
                                  return <KendoTd {...props}>은</KendoTd>;
                                default:
                                  return <KendoTd {...props} />;
                              }
                            }}
                          />
                          <GridColumn
                            title="순도"
                            className="item"
                            cell={(props) => {
                              const { purityType } = props.dataItem;
                              return (
                                <KendoTd {...props} title={purityType}>
                                  {purityType}
                                </KendoTd>
                              );
                            }}
                          />
                          <GridColumn
                            title="품목"
                            className="item"
                            editable={false}
                            cell={(props) => {
                              const { addId, name, productId, assetType } =
                                props.dataItem;
                              if (addId) {
                                return (
                                  <KendoTd {...props}>
                                    <DropDownList
                                      options={products
                                        .filter(
                                          (item) =>
                                            item.assetType === assetType &&
                                            item.itemType === "REFINE"
                                        )
                                        .map(({ id, name }) => ({
                                          label: name,
                                          value: id,
                                        }))}
                                      selected={productId}
                                      onChange={(value) =>
                                        handleChangeProduct(
                                          "refine",
                                          addId,
                                          value
                                        )
                                      }
                                      style={{ width: "100%" }}
                                    />
                                  </KendoTd>
                                );
                              } else {
                                return <KendoTd {...props}>{name}</KendoTd>;
                              }
                            }}
                          />
                          <GridColumn title="대리점">
                            <GridColumn
                              title="측정 실중량(g)"
                              field="productWeightGram"
                              editable={false}
                              className="item right"
                              cell={NumberTd}
                            />
                            <GridColumn
                              title="감정 실중량(g)"
                              field="appraisalWeightGram"
                              editable={false}
                              className="item right"
                              cell={NumberTd}
                            />
                          </GridColumn>
                          <GridColumn title="검품">
                            <GridColumn
                              title="측정 실중량(g)"
                              field="inspectProductWeightGram"
                              className="item right"
                            />
                            <GridColumn
                              title="감정 실중량(g)"
                              field="inspectWeightGram"
                              className="item right"
                            />
                          </GridColumn>
                          {!stockCheck && (
                            <GridColumn
                              headerCell={() => {
                                return (
                                  // !stockCheck && (
                                  <div style={{ textAlign: "center" }}>
                                    <KendoButton
                                      style={{ width: 70 }}
                                      onClick={() => {
                                        handleClickAddItem("refine");
                                      }}>
                                      추가
                                    </KendoButton>
                                  </div>
                                  // )
                                );
                              }}
                              cell={(props) => {
                                const {
                                  id,
                                  addId,
                                  stockStatus,
                                  isDelete = false,
                                  appraisalId,
                                } = props.dataItem;
                                if (stockStatus === "TAKE" && (id || addId)) {
                                  return (
                                    <KendoTd
                                      {...props}
                                      style={{
                                        padding: 0,
                                        textAlign: "center",
                                      }}>
                                      <KendoButton
                                        style={{ width: 70 }}
                                        onClick={() => {
                                          setSelectedNumber(appraisalId);
                                          handleClickDelete(
                                            props.dataIndex,
                                            isDelete,
                                            "refine"
                                          );
                                        }}>
                                        {isDelete ? "삭제 취소" : "삭제"}
                                      </KendoButton>
                                    </KendoTd>
                                  );
                                } else {
                                  return <KendoTd {...props} />;
                                }
                              }}
                            />
                          )}
                        </Grid>
                      </td>
                    </tr>
                    <tr style={{ marginBottom: 10 }}>
                      <th>
                        <SmallTitle>상품</SmallTitle>
                      </th>
                      <td>
                        <Grid
                          data={goodsList}
                          scrollable="none"
                          editField="inEdit"
                          onItemChange={(e) => {
                            handleItemChange(e, "goods");
                          }}
                          rowRender={(trElement, rowProps) => {
                            const trProps = {
                              ...trElement.props,
                              ["data-id"]: rowProps.dataItem.ProductID,
                              className: `${trElement.props.className} ${
                                rowProps.dataItem.appraisalId ===
                                  selectedNumber && rowProps.dataItem.id
                                  ? "k-selected"
                                  : ""
                              } ${
                                rowProps.dataItem.isDelete ? "is-delete" : ""
                              } ${rowProps.dataItem.number ? "top" : ""}`,
                            };
                            return React.cloneElement(
                              trElement,
                              { ...trProps },
                              trElement.props.children
                            );
                          }}>
                          <GridColumn
                            title="자산"
                            className="item"
                            cell={(props) => {
                              const assetType = props.dataItem.assetType;
                              switch (assetType) {
                                case "GOLD":
                                  return <KendoTd {...props}>금</KendoTd>;
                                case "SILVER":
                                  return <KendoTd {...props}>은</KendoTd>;
                                default:
                                  return <KendoTd {...props} />;
                              }
                            }}
                          />
                          <GridColumn
                            title="순도"
                            className="item"
                            cell={(props) => {
                              const { purityType } = props.dataItem;
                              return (
                                <KendoTd {...props} title={purityType}>
                                  {purityType}
                                </KendoTd>
                              );
                            }}
                          />
                          <GridColumn
                            title="품목"
                            className="item"
                            editable={false}
                            cell={(props) => {
                              const { addId, name, productId, assetType } =
                                props.dataItem;
                              if (addId) {
                                return (
                                  <KendoTd {...props}>
                                    <DropDownList
                                      options={products
                                        .filter(
                                          (item) =>
                                            item.assetType === assetType &&
                                            item.itemType === "GOODS"
                                        )
                                        .map(({ id, name }) => ({
                                          label: name,
                                          value: id,
                                        }))}
                                      selected={productId}
                                      onChange={(value) =>
                                        handleChangeProduct(
                                          "goods",
                                          addId,
                                          value
                                        )
                                      }
                                      style={{ width: "100%" }}
                                    />
                                  </KendoTd>
                                );
                              } else {
                                return <KendoTd {...props}>{name}</KendoTd>;
                              }
                            }}
                          />
                          <GridColumn title="대리점">
                            <GridColumn
                              title="측정 실중량(g)"
                              field="productWeightGram"
                              editable={false}
                              className="item right"
                              cell={NumberTd}
                            />
                            <GridColumn
                              title="감정 실중량(g)"
                              field="appraisalWeightGram"
                              editable={false}
                              className="item right"
                              cell={NumberTd}
                            />
                          </GridColumn>
                          <GridColumn title="검품">
                            <GridColumn
                              title="카테고리"
                              className="item"
                              editable={false}
                              cell={(props) => {
                                const {
                                  addId,
                                  goodsCategoryName,
                                  categoryId,
                                  assetType,
                                  goodCategoryName,
                                } = props.dataItem;
                                const { dataIndex } = props;
                                if (!goodsCategoryName) {
                                  return (
                                    <KendoTd {...props}>
                                      <DropDownList
                                        options={goodsCategoryList
                                          .filter(
                                            (item) =>
                                              item.assetType === assetType
                                          )
                                          .map(({ goodsCategoryId, name }) => ({
                                            label: name,
                                            value: goodsCategoryId,
                                          }))}
                                        selected={categoryId}
                                        onChange={(value) =>
                                          handleChangeGoodCategory(
                                            addId,
                                            value,
                                            dataIndex
                                          )
                                        }
                                        style={{ width: "100%" }}
                                      />
                                    </KendoTd>
                                  );
                                } else {
                                  return (
                                    <KendoTd {...props}>
                                      {addId
                                        ? goodCategoryName
                                        : goodsCategoryName}
                                    </KendoTd>
                                  );
                                }
                              }}
                            />
                            <GridColumn
                              title="제품명"
                              className="item"
                              editable={false}
                              cell={(props) => {
                                const {
                                  addId,
                                  goodsList,
                                  categoryId,
                                  goodName,
                                  goodId,
                                  goodsName,
                                } = props.dataItem;
                                const { dataIndex } = props;
                                if (!goodsName) {
                                  return (
                                    <KendoTd {...props}>
                                      <DropDownList
                                        options={goodsList?.map(
                                          ({ goodsId, name }) => ({
                                            label: name,
                                            value: goodsId,
                                          })
                                        )}
                                        selected={goodId}
                                        onChange={(value) =>
                                          handleChangeGoodName(
                                            addId,
                                            value,
                                            categoryId,
                                            dataIndex
                                          )
                                        }
                                        style={{ width: "100%" }}
                                      />
                                    </KendoTd>
                                  );
                                } else {
                                  return (
                                    <KendoTd {...props}>
                                      {addId ? goodName : goodsName}
                                    </KendoTd>
                                  );
                                }
                              }}
                            />
                            <GridColumn
                              title="옵션명"
                              className="item"
                              editable={false}
                              cell={(props) => {
                                const {
                                  addId,
                                  categoryId,
                                  goodId,
                                  selectOptionId,
                                  optionsList,
                                  optionName,
                                } = props.dataItem;
                                const { dataIndex } = props;
                                if (
                                  !optionName &&
                                  optionsList &&
                                  optionsList?.length !== 0
                                ) {
                                  return (
                                    <KendoTd {...props}>
                                      <DropDownList
                                        options={optionsList?.map(
                                          ({ id, value }) => ({
                                            label: value,
                                            value: id,
                                          })
                                        )}
                                        selected={selectOptionId}
                                        onChange={(value) =>
                                          handleChangeGoodOption(
                                            addId,
                                            value,
                                            goodId,
                                            categoryId,
                                            dataIndex
                                          )
                                        }
                                        style={{ width: "100%" }}
                                      />
                                    </KendoTd>
                                  );
                                } else {
                                  return (
                                    <KendoTd {...props}>{optionName}</KendoTd>
                                  );
                                }
                              }}
                            />
                            <GridColumn
                              title="중량"
                              className="item"
                              cell={(props) => {
                                const { gram, goodsGram } = props.dataItem;
                                return (
                                  <KendoTd
                                    {...props}
                                    title={gram}
                                    className="item right">
                                    {!goodsGram
                                      ? gram
                                        ? `${gram}g`
                                        : "-"
                                      : goodsGram}
                                  </KendoTd>
                                );
                              }}
                            />
                            <GridColumn
                              title="개수"
                              className="item right"
                              field="stockQuantity"
                              width={60}
                            />
                            <GridColumn
                              title="총중량"
                              className="item"
                              cell={(props) => {
                                const {
                                  gram,
                                  stockQuantity,
                                  inspectWeightGram,
                                } = props.dataItem;
                                const totalGram = gram * stockQuantity;
                                return (
                                  <KendoTd {...props}>
                                    {inspectWeightGram
                                      ? `${inspectWeightGram}g`
                                      : totalGram
                                      ? `${totalGram}g`
                                      : "-"}
                                  </KendoTd>
                                );
                              }}
                            />
                          </GridColumn>
                          {!stockCheck && (
                            <GridColumn
                              headerCell={() => {
                                return (
                                  <div style={{ textAlign: "center" }}>
                                    <KendoButton
                                      style={{ width: 70 }}
                                      onClick={() => {
                                        handleClickAddItem("goods");
                                      }}>
                                      추가
                                    </KendoButton>
                                  </div>
                                );
                              }}
                              cell={(props) => {
                                const {
                                  id,
                                  addId,
                                  stockStatus,
                                  isDelete = false,
                                  appraisalId,
                                } = props.dataItem;
                                if (stockStatus === "TAKE" && (id || addId)) {
                                  return (
                                    <KendoTd
                                      {...props}
                                      style={{
                                        padding: 0,
                                        textAlign: "center",
                                      }}>
                                      <KendoButton
                                        style={{ width: 70 }}
                                        onClick={() => {
                                          setSelectedNumber(appraisalId);
                                          handleClickDelete(
                                            props.dataIndex,
                                            isDelete,
                                            "goods"
                                          );
                                        }}>
                                        {isDelete ? "삭제 취소" : "삭제"}
                                      </KendoButton>
                                    </KendoTd>
                                  );
                                } else {
                                  return <KendoTd {...props} />;
                                }
                              }}
                            />
                          )}
                        </Grid>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Flex>
    </AppraisalInspectionContainer>
  );
};

//복수조회 모달
const MultipleSearch = ({ searchInspectionList, searchState }) => {
  const dispatch = useDispatch();
  const [tempBarCode, setTempBarCode] = useState(""); //인풋에 입력되어지는 barCode
  const [barCode, setBarCode] = useState(""); //데이터 보내지는 barCode
  const [barCodeList, setBarCodeList] = useState([]); //바코드 조회 리스트
  const [idList, setIdList] = useState([]);

  useEffect(() => {
    if (barCode) {
      dispatch(loadingStart);
      APIS.getAppraisalInventoryTake(
        objToQueryStr({
          number: barCode,
        })
      )
        .then(({ data: { data, success, message } }) => {
          if (success) {
            const reformData = [...barCodeList, ...data];
            reformData.reduce(function (acc, current) {
              if (acc.findIndex(({ id }) => id === current.id) === -1) {
                acc.push(current);
              }
              setBarCodeList(
                acc.map((item, i) => {
                  return {
                    ...item,
                    index: i + 1,
                    selected: false,
                    stockStatus: item?.stockStatus
                      .replace("WAIT", "입고대기")
                      .replace("DELIVERY", "입고배송")
                      .replace("TAKE", "재고수령")
                      .replace("COMPLETE", "입고완료"),
                  };
                })
              );
              return acc;
            }, []);
            setTimeout(() => {
              if (data.length === 1) {
                setTempBarCode("");
                setBarCode("");
              }
            }, 500);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  }, [barCode]);

  return (
    <Flex>
      <Flex row style={{ marginBottom: 10 }}>
        <Flex style={{ justifyContent: "center", marginRight: 10 }}>
          <SmallTitle>접수번호 조회</SmallTitle>
        </Flex>
        <input
          style={{ width: 240, height: 34, borderRadius: 5 }}
          value={tempBarCode}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (tempBarCode.length < 4) {
                dispatch(actionError("최소 4자리까지 입력해주세요"));
              } else {
                setBarCode(e.target.value);
              }
            }
          }}
          onChange={(e) => {
            setBarCode("");
            if (!e.nativeEvent.data) {
              setTempBarCode(e.target.value);
            }
          }}
          onKeyDown={(e) => {
            setBarCode("");
            if (e.key.length === 1 && !e.altKey && !e.ctrlKey && !e.metaKey) {
              const eng = "rRseEfaqQtTdwWczxvgkoiOjpuPhynbml";
              const kor =
                "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎㅏㅐㅑㅒㅓㅔㅕㅖㅗㅛㅜㅠㅡㅣ";
              const korIndex = kor.indexOf(e.key);

              setTempBarCode((text) =>
                (text + (eng[korIndex] || e.key)).toLowerCase()
              );
            }
          }}
          autoFocus
        />
      </Flex>
      <div>
        <GridTable
          style={{ height: "350px" }}
          data={barCodeList}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
          }}
          scrollable>
          <Column
            width="40px"
            headerCell={() => {
              return (
                <div>
                  <Checkbox
                    checked={
                      barCodeList.length === idList.length &&
                      barCodeList.length !== 0
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const { ariaChecked } = e.syntheticEvent.target;
                      if (ariaChecked === "true") {
                        setIdList([]);
                      } else {
                        setIdList([...barCodeList.map((item) => item.number)]);
                      }
                    }}
                  />
                </div>
              );
            }}
            cell={(props) => {
              const { dataItem } = props;
              const data = dataItem["number"];
              return (
                <td style={{ textAlign: "center" }}>
                  <Checkbox
                    checked={idList.includes(data) ? true : false}
                    onChange={() => {
                      let parseList = cloneDeep(idList);
                      if (idList.includes(data)) {
                        const result = parseList.filter(
                          (item) => item !== data
                        );
                        setIdList(result);
                      } else {
                        parseList.push(data);
                        setIdList(parseList);
                      }
                    }}
                  />
                </td>
              );
            }}
          />
          <Column
            className="td-c"
            field="number"
            title="접수번호(바코드)"
            width="200px"
          />
          <Column
            className="td-c"
            field="stockStatus"
            width="100px"
            title="재고상태"
          />
        </GridTable>
      </div>
      <div style={{ alignSelf: "center", marginTop: 10 }}>
        <KendoButton
          onClick={() => {
            searchInspectionList({
              number: idList,
              page: searchState.page,
              size: searchState.size,
              total: searchState.total,
            });
          }}>
          검색
        </KendoButton>
      </div>
    </Flex>
  );
};

const KendoTd = (props) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
      style={props.style}
      className={props.className}
      alt={props.alt}
      title={props.title}>
      {props.children}
    </td>
  );
};

const NumberTd = (props) => {
  const number = props.dataItem[props.field];
  return <KendoTd {...props}>{number ? numberWithCommas(number) : ""}</KendoTd>;
};

const AppraisalInspectionContainer = styled(Flex)`
  * {
    z-index: auto;
  }
  flex: 1;
  padding: 10px 10px 0px 10px;

  .center {
    text-align: center;
  }

  .right {
    .k-input {
      text-align: right;
    }
    text-align: right;
  }

  .top td {
    border-color: rgba(0, 0, 0, 0.08);
    border-top-color: black;
    border-top-width: 2px;
  }
  .is-delete td.item::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 2px);
    height: 4px;
    border: 1px solid black;
    border-left: none;
    border-right: none;
  }

  .k-grid td {
    position: relative;
    padding: 3px;
    white-space: normal;
    // display: -webkit-box;
  }
  .k-grid th {
    padding: 4px 0px;
  }
`;

const SearchSection = styled(Flex)`
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  border-top: 1px solid #e2e5e8;

  .th,
  .td {
    flex-direction: row;
    border-bottom: 1px solid #e2e5e8;
    align-items: center;
    padding: 5px;
  }
  .th {
    font-weight: bold;
    background: #f2f4f6;
    width: 150px;
    padding: 10px;
    justify-content: center;
  }
  .td {
    flex: 1;
  }
`;

const DateInputFilter = styled(DateInput)`
  width: 115px !important;
`;

const DateInputFilterWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
export default AppraisalInspection;

const useStyle = makeStyles({
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
  },
  confirmButton: {
    minWidth: 70,
    height: 33,
    width: 100,
    marginLeft: 0,
  },
});
