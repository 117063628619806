import Flex from "components/flex/Flex";
import fonts from "libs/fonts";
import {
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { numberWithCommas } from "libs/utils";

const NotSignedStatus = ({ summaryData }) => {
  const classes = useStyle();

  const headerRows = [
    [
      { label: "구분", rowSpan: 1, width: 50 },
      { label: "총 회원수", rowSpan: 1, width: 100 },
      { label: "본인인증 시도횟수", rowSpan: 1, width: 100 },
      { label: "약관 시도횟수", rowSpan: 1, width: 100 },
      { label: "추천매장 시도횟수", rowSpan: 1, width: 100 },
      { label: "이메일 시도횟수", rowSpan: 1, width: 100 },
      { label: "결제비번 시도횟수", rowSpan: 1, width: 100 },
    ],
  ];

  const columns = [
    { key: "type", label: "구분" },
    { key: "totalCnt", label: "총", type: "number" },
    {
      key: "selfVerificationCnt",
      label: "본인인증",
      type: "number",
    },
    { key: "termsCnt", label: "약관", type: "number" },
    {
      key: "recommendShopCnt",
      label: "추천매장",
      type: "number",
    },
    { key: "emailCnt", label: "이메일", type: "number" },
    { key: "finNumberCnt", label: "결제비번", type: "number" },
  ];

  if (!summaryData) {
    return;
  }

  return (
    <Flex style={{ margin: "0px 50px 20px 50px" }}>
      <Flex>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              {headerRows?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map(
                      ({ label, rowSpan, colSpan, width, render }, index) => {
                        return (
                          <TableCell
                            key={index}
                            colSpan={colSpan}
                            width={width}
                          >
                            {render || label}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map(({ key, align, type }, index) => {
                  const defaultAlign = "center";
                  return (
                    <TableCell key={index} align={align || defaultAlign}>
                      {key === "type"
                        ? "횟수"
                        : type === "number"
                        ? numberWithCommas(summaryData[key])
                        : summaryData[key]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
};

const useStyle = makeStyles({
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
});

export default NotSignedStatus;
