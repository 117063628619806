import { STORAGE_KEY } from "../../libs/consts";
import jwtDecode from "jwt-decode";
import * as APIS from "../../libs/apis";

// Actions
const SIGN_IN = "auth/SIGN_IN";
const SIGN_OUT = "auth/SIGN_OUT";

const INIT_STATE = {
  isSigned: false,
  name: "",
  sns: "",
  socialId: "",
  isKorda: false,
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case SIGN_IN:
      return fnSignIn(action.payload);

    case SIGN_OUT:
      localStorage.removeItem(STORAGE_KEY.accessToken);
      localStorage.removeItem("passwordVerification");
      return { ...INIT_STATE };

    default:
      return state;
  }
}

// Action 생성자
export function signIn(payload) {
  return { type: SIGN_IN, payload };
}

export function signOut() {
  return { type: SIGN_OUT };
}

const fnSignIn = (token) => {
  APIS.setAuthorization(token);

  const tokenData = jwtDecode(token) || {};

  const { roles, sub } = tokenData;
  const ids = sub.split(":");

  return {
    ...INIT_STATE,
    isSigned: true,
    role: roles,
    managerId: +ids[2] || 0,
    shopId: +ids[3] || 0,
    isKorda: roles === "ROLE_MANAGER_KORDA",
  };
};
