import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Editor from "../../components/editor/Editor";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";

export default function SettingTermPolicyDetail({}) {
  const history = useHistory();
  const classes = useStyle();

  const state = history?.location?.state || {};
  const { type } = state;

  useEffect(() => {
    APIS.getTermDetail(type).then(({ data }) => {
      console.log("getTermDetail", data);
    });
  }, []);
  return (
    <>
      <PageLabel>이용약관 (등록/수정)</PageLabel>
      <GridRow
        data={[
          {
            label: "등록일",
            value: "20203.23.23 13:31:00",
            fullWidth: true,
          },
          {
            type: "render",
            label: "시행일",
            fullWidth: true,
            render: (
              <input
                type="date"
                style={{ padding: 8, border: " 1px solid #ddd" }}
              />
            ),
          },
          {
            label: "제목",
            type: "input",
            fullWidth: true,
            inputFullWidth: true,
          },
          {
            type: "render",
            label: "내용",
            fullWidth: true,
            render: (
              <div className={classes.inputWrap}>
                <Editor />
              </div>
            ),
          },
        ]}
      />

      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.button1}>
          목록
        </ButtonBase>
        <ButtonBase onClick={history.goBack} className={classes.button2}>
          저장
        </ButtonBase>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  buttons: { alignSelf: "center", marginTop: 50 },
  button1: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  inputWrap: {
    display: "flex",
    flex: 1,
    marginBottom: 30,
    flexDirection: "column",
    height: "500px !important",
    alignSelf: "stretch",
  },
});
