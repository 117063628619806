export const assets = {
  gold:{
    title: "금(GOLD)",
    key: "gold",
    type: "GOLD",
    korName: "금"
  },
  silver: {
    title: "은(SILVER)",
    key: "silver",
    type: "SILVER",
    korName: "은"
  }
}

export function filterKey(assets){
  const assetsKey = Object.keys(assets);
  return assetsKey;
}