import { useState } from "react";
import styled from "styled-components";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { Field } from "@progress/kendo-react-form";
import {
  IntlProvider,
  LocalizationProvider,
  load,
} from "@progress/kendo-react-intl";
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

const SearchLayoutV2 = ({ children, pageTitle }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <LocalizationProvider language="ko">
      <IntlProvider locale="ko">
        <TitleWrap onClick={() => setExpanded(!expanded)}>
          <Title>{pageTitle}</Title>
          <div>
            <span style={{ marginRight: "20px" }}>
              {expanded ? <AiOutlineUp /> : <AiOutlineDown />}
            </span>
          </div>
        </TitleWrap>
        <FilterWrap expanded={expanded}>{children}</FilterWrap>
      </IntlProvider>
    </LocalizationProvider>
  );
};

const TableField = ({
  label,
  name,
  component,
  tdColSpan = 1,
  validator = null,
  children = null,
  onChange = null,
  prefix = null,
  sufix = null,
  ...others
}) => {
  return (
    <>
      <th>{label}</th>
      <td
        style={{ display: "flex", height: "fit-content" }}
        colSpan={tdColSpan}
      >
        {prefix}
        <Field
          name={name}
          component={component}
          validator={validator}
          onChange={onChange}
          {...others}
        />
        {sufix}
      </td>
    </>
  );
};

const InitBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const SearchBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: none;
  background: #000;
  color: #fff;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  user-select: none;
  .k-grid-table {
    overflow-y: scroll;
  }
`;
const Title = styled.div`
  width: 50%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 10px 10px;
`;

const FilterWrap = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.expanded ? "210px" : "0px")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  margin-top: 10px;
`;

const Table = styled.table`
  width: 100%;
  height: 150px;
  th {
    width: 100px;
    background: #f2f4f6;
    text-align: left;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #636a75;
    user-select: none;
  }
  th,
  td {
    border: 1px solid #e2e5e8;
    border-left: none;
    border-right: none;
  }
`;

SearchLayoutV2.Table = Table;
SearchLayoutV2.TableField = TableField;
SearchLayoutV2.SearchBtn = SearchBtn;
SearchLayoutV2.InitBtn = InitBtn;

export default SearchLayoutV2;
