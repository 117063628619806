import React from "react";
import { DropDownList as KendoDropDownList } from "@progress/kendo-react-dropdowns";
import styled from "styled-components";

const DropDownList = ({
  className = "",
  style = {},
  options = [],
  selected,
  onChange,
  useAll = false,
  onFocus,
  onBlur,
}) => {
  // options = [{label:'금',value:"GOLD"}]

  const handleChangeSelect = (e) => {
    onChange(e.target.value.value, e);
  };

  const optionData = useAll
    ? [{ label: "전체", value: "" }, ...options]
    : options;

  return (
    <DropDownContainer
      className={className}
      style={style}
      data={optionData}
      textField="label"
      dataItemKey="value"
      value={optionData.find(({ value }) => value === selected)}
      onChange={handleChangeSelect}
      onFocus={onFocus}
      onBlur={onBlur}
      rounded="small"
      fillMode="flat"
    />
  );
};

const DropDownContainer = styled(KendoDropDownList)`
  width: 200px;
`;

export default DropDownList;
