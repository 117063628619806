import {
  useEffect,
  useState,
  useRef,
  useMemo,
  createContext,
  useCallback,
  Fragment,
} from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
} from "@progress/kendo-react-grid";

import * as APIS from "../../../libs/apis";
import consts from "../../../libs/consts";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { actionError } from "../../../redux/action/ActionActions";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";
import { openImageView } from "../../../redux/image-viewer/ImageViewerReducer";
import { DragAndDrop, SvgIcon } from "@progress/kendo-react-common";
import { DraggableRow } from "./components/DraggableRows";
import { reorderIcon } from "@progress/kendo-svg-icons";
import {
  convertGoodsGroupToGrid,
  getGoodsListOfCurrentViews,
  translateGoodsDisplayToKor,
} from "./hotProducts.const";

/**  */
const ColumnMenuCheckboxFilter = (data) => (props) => {
  return (
    <div>
      <GridColumnMenuCheckboxFilter {...props} expanded data={data} />
    </div>
  );
};

function HotProduct() {
  const dispatch = useDispatch();
  const [goodsGroups, setGoodsGroups] = useState([]); // 처음 받아온 전체 데이터
  const [selectedId, setSelectedId] = useState(17); // 현재 제품군 아이디
  const [addGoods, setAddGoods] = useState([]); // 추가할 상품
  const [deleteGoods, setDeleteGoods] = useState([]); // 삭제할 상품
  const [serviceImageId, setServiceImageId] = useState(0);
  // 데이터 받아오기
  useEffect(() => {
    dispatch(loadingStart);

    APIS.getGoodsGroup()
      .then((res) => {
        setGoodsGroups(convertGoodsGroupToGrid(res.data.data));
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  }, [selectedId]);

  // 상태 끌어올리기용 함수
  const updateGoodsGroups = (newGoodsGroups) => {
    setGoodsGroups(convertGoodsGroupToGrid(newGoodsGroups));
  };

  const updateServiceImageId = (newServiceImageId) => {
    setServiceImageId(newServiceImageId);
  };

  const syncGoodsGroups = (data) => {
    const translated = translateGoodsDisplayToKor(data);
    // selectedId와 동일한 그룹을 find로 찾은 후 그 Group의 goods 배열의 내용을 교체하면 됨.
    const copyGroup = goodsGroups.slice();
    const currentGroup = copyGroup.map((group) => {
      if (group.goodsGroupId === selectedId) {
        group.goods = translated;
      }
      return group;
    });
    setGoodsGroups(currentGroup);
  };

  const handleAddGoods = () => {
    if (addGoods.length === 0) {
      return dispatch(actionError("추가할 상품을 선택해주세요."));
    } else {
      dispatch(loadingStart);
      APIS.postGoods(selectedId, { goodsIds: addGoods })
        .then((res) => {
          if (res.data.success) {
            setAddGoods([]); // 초기화
            syncGoodsGroups(res.data.data);
          } else {
            dispatch(actionError(res.data.message));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleDeleteGoods = () => {
    if (deleteGoods.length === 0) {
      return dispatch(actionError("삭제할 상품을 선택해주세요."));
    } else {
      dispatch(loadingStart);
      APIS.deleteGoods(selectedId, { goodsIds: deleteGoods })
        .then((res) => {
          setDeleteGoods([]);
          syncGoodsGroups(res.data.data);
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  return (
    <Container>
      <FilterLayout
        goodsGroups={goodsGroups}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        updateGoodsGroups={updateGoodsGroups}
        updateServiceImageId={updateServiceImageId}
      />
      <GoodGroupsLayout
        goodsGroups={goodsGroups}
        selectedId={selectedId}
        updateGoodsGroups={updateGoodsGroups}
        deleteGoods={deleteGoods}
        setDeleteGoods={setDeleteGoods}
        serviceImageId={serviceImageId}
        updateServiceImageId={updateServiceImageId}
      />
      <ButtonWrapper>
        <Button onClick={handleAddGoods}>
          {/* 상품군에 상품 추가 */}
          <FaArrowLeft />
        </Button>
        <Button onClick={handleDeleteGoods}>
          {/* 상품군에 상품 삭제 */}
          <FaArrowRight />
        </Button>
      </ButtonWrapper>
      <GoodsLayout
        selectedId={selectedId}
        addGoods={addGoods}
        setAddGoods={setAddGoods}
      />
    </Container>
  );
}

export default HotProduct;

// 상품군 탭
const FilterLayout = ({
  goodsGroups,
  selectedId,
  setSelectedId,
  updateGoodsGroups,
}) => {
  const dispatch = useDispatch();
  const inputWrapperRef = useRef(null);
  const [show, setShow] = useState(false);
  const [deleteInvisible, setDeleteInvisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const FixGroups = goodsGroups.filter((group) => group.type !== "EDITABLE");
  const EditableGroups = goodsGroups.filter(
    (group) => group.type === "EDITABLE"
  );

  useEffect(() => {
    if (show && inputWrapperRef.current) {
      inputWrapperRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [show]);

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getGoodsGroup()
      .then((res) => {
        updateGoodsGroups(res.data.data);
      })
      .finally(() => dispatch(loadingEnd));
  };

  const AddGoodGroups = (data) => {
    if (data === "") {
      dispatch(actionError("1글자 이상 입력해주세요."));
    } else {
      dispatch(loadingStart);
      APIS.postGoodsGroup({ goodsGroupName: data })
        .then((res) => {
          res.data.success && fetchList();
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const DeleteGoodGroups = (data) => {
    dispatch(loadingStart);

    APIS.deleteGoodsGroup(data)
      .then((res) => {
        res.data.success && fetchList();
        dispatch(actionError("상품군이 삭제되었습니다."));
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    AddGoodGroups(inputValue);
    setInputValue("");
  };

  return (
    <FilterArea>
      <FilterTabArea>
        <NoneEditableArea>
          {FixGroups.map((group, index) => {
            return (
              <StyledButton
                fillMode={
                  group.goodsGroupId === selectedId ? "outline" : "flat"
                }
                onClick={() => setSelectedId(group.goodsGroupId)}
                key={index}
              >
                {group.goodsGroupName}
              </StyledButton>
            );
          })}
        </NoneEditableArea>
        <Line />
        <ScrollableArea>
          {EditableGroups.map((group, index) => {
            return (
              <EditableGroup key={index}>
                {deleteInvisible ? (
                  <StyledButton
                    fillMode="outline"
                    themeColor={"primary"}
                    onClick={() => DeleteGoodGroups(group.goodsGroupId)}
                  >
                    <span class="k-icon k-i-minus-circle"></span>{" "}
                    {group.goodsGroupName}
                  </StyledButton>
                ) : (
                  <StyledButton
                    fillMode={
                      group.goodsGroupId === selectedId ? "outline" : "flat"
                    }
                    onClick={() => setSelectedId(group.goodsGroupId)}
                  >
                    {group.goodsGroupName}
                  </StyledButton>
                )}
              </EditableGroup>
            );
          })}
          {show && (
            <div ref={inputWrapperRef}>
              <StyledForm onSubmit={onSubmit}>
                <StyledInput
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  minLength={1}
                  maxLength={8}
                />
                <div>
                  <MrButton type="submit">저장</MrButton>
                  <Button onClick={() => setShow(!show)}>취소</Button>
                </div>
              </StyledForm>
            </div>
          )}
        </ScrollableArea>
        <Line />
        <ButtonGroup>
          <Button onClick={() => setShow(!show)}>
            {show ? "상품군 추가 취소" : "상품군 추가"}
          </Button>
          <Button onClick={() => setDeleteInvisible(!deleteInvisible)}>
            {deleteInvisible ? "취소" : "상품군 삭제"}
          </Button>
        </ButtonGroup>
      </FilterTabArea>
    </FilterArea>
  );
};

const GoodGroupsLayout = ({
  goodsGroups,
  selectedId,
  updateGoodsGroups,
  deleteGoods,
  setDeleteGoods,
  serviceImageId,
  updateServiceImageId,
}) => {
  const dispatch = useDispatch();
  const prevSelectedId = useRef(selectedId);
  const [activeItem, setActiveItem] = useState(null); // 순서 변경 관련 상태
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [targetData, setTargetData] = useState([]); // 해당하는 상품군 데이터만 추출
  const [dataState, setDataState] = useState({});
  const [reorderedData, setReorderedData] = useState(() => goodsGroups.slice());

  useEffect(() => {
    const validGoodsGroups = Array.isArray(goodsGroups) ? goodsGroups : [];
    const newTargetData = validGoodsGroups.filter(
      (group) => group.goodsGroupId === selectedId
    );

    setTargetData(newTargetData.length > 0 ? newTargetData : []);
    if (newTargetData.length > 0 && newTargetData[0].serviceImage) {
      updateServiceImageId(newTargetData[0].serviceImage.serviceImageId);
    } else {
      updateServiceImageId(null);
    }

    setReorderedData(goodsGroups.slice());
  }, [goodsGroups, selectedId]);

  const handleSort = (reorderedData) => {
    const sortGoods = (reorderedData) => {
      return reorderedData
        .filter((group) => group.goodsGroupId === selectedId)[0]
        .goods.map((item, index) => {
          return {
            goodsId: item.goodsId,
            sort: index + 1,
          };
        });
    };

    let sortedData = sortGoods(reorderedData);

    APIS.putGoodsSort(selectedId, { goodsSortDto: sortedData }).then((res) => {
      res.data.success && fetchList();
    });
  };

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getGoodsGroup()
      .then((res) => {
        updateGoodsGroups(res.data.data);
      })
      .finally(() => dispatch(loadingEnd));
  };

  const EditGoodGroups = (inputValue) => {
    if (inputValue === "") {
      dispatch(actionError("1글자 이상 입력해주세요."));
      setEditable(true);
    } else {
      dispatch(loadingStart);
      APIS.patchGoodsGroup(selectedId, { goodsGroupName: inputValue })
        .then((res) => {
          res.data.success && fetchList();
        })
        .finally(() => {
          setInputValue("");
          setEditable(false);
          dispatch(loadingEnd);
        });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    EditGoodGroups(inputValue);
  };

  const reorder = (dataItem, direction) => {
    if (activeItem === dataItem) {
      return;
    }

    let reorderedDataCopy = [...reorderedData];
    let currentGroup = reorderedDataCopy.find(
      (group) => group.goodsGroupId === selectedId
    );
    let prevIndex = currentGroup.goods.findIndex((p) => p === activeItem);
    let nextIndex =
      currentGroup.goods.findIndex((p) => p === dataItem) +
      (direction === "before" ? -1 : 0);
    if (prevIndex > nextIndex) {
      nextIndex++;
    }

    currentGroup.goods.splice(prevIndex, 1);
    currentGroup.goods.splice(
      nextIndex,
      0,
      activeItem || currentGroup.goods[0]
    );

    setReorderedData(reorderedDataCopy);
  };

  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  const dragEnd = () => {
    console.log("dragEnd", reorderedData);
    handleSort(reorderedData);
  };

  const handleClickSelectedAllCheck = useCallback(() => {
    let data = [];
    let result = targetData[0].goods;

    if (deleteGoods.length === result.length) {
      setDeleteGoods([]);
    } else {
      setDeleteGoods(result.map((item) => item.goodsId));
    }
  }, [goodsGroups, deleteGoods]);

  const handleDataState = (event) => {
    // const updatedState = createDataState()

    const currentGroup = goodsGroups.filter(
      (item) => item.goodsGroupId === selectedId
    );
    const filteredItemsByKeys = process(currentGroup[0].goods, event.dataState);
    setTargetData((prev) => {
      const _prev = { ...prev[0] };
      _prev.goods = filteredItemsByKeys.data;

      return [_prev];
    });
    setDataState(event.dataState);
  };

  const CheckboxCell = ({ dataItem }) => {
    const handleCheckboxChange = (event) => {
      const checked = event.syntheticEvent.target.checked;
      if (checked) {
        setDeleteGoods([...deleteGoods, dataItem.goodsId]);
      } else {
        setDeleteGoods(deleteGoods.filter((item) => item !== dataItem.goodsId));
      }
    };

    const isChecked = deleteGoods.some((item) => item === dataItem.goodsId);

    useEffect(() => {
      if (selectedId !== prevSelectedId.current) {
        setDeleteGoods([]);
        prevSelectedId.current = selectedId;
      }
    }, [selectedId]);

    return (
      <StyledTd>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
      </StyledTd>
    );
  };

  const ImagePreview = () => {
    const fileInput = useRef(null);

    const handleImgButtonClick = (e) => {
      fileInput.current.click();
    };

    const handleImgChange = (e) => {
      const file = e.target.files[0];

      // 이미지 타입 체크

      if (file.type && !file.type.startsWith("image/")) {
        dispatch(actionError("이미지 파일을 올려주세요"));
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        // 파일 사이즈 제한 걸기
        // if (img.width !== 110 || img.height !== 182) {
        //   dispatch(actionError("사이즈를 x*y로 맞춰주세요"));
        //   return;
        // }

        const formData = new FormData();
        formData.append("image", file);

        dispatch(loadingStart);
        APIS.postGoodsImage(selectedId, formData)
          .then((res) => {
            res.data.success && fetchList();
          })
          .finally(() => dispatch(loadingEnd));
      };

      img.onerror = () => {
        dispatch(actionError("유효하지 않은 이미지입니다."));
      };
    };

    const handleDeleteImage = () => {
      // 삭제 요청 처리
      dispatch(loadingStart);
      APIS.deleteGoodsImage(selectedId, serviceImageId)
        .then((res) => {
          res.data.success
            ? fetchList()
            : dispatch(actionError(res.data.message));
        })
        .finally(() => dispatch(loadingEnd));
    };

    return (
      <Fragment>
        <ImageWrapper>
          <Button onClick={handleImgButtonClick}>파일 업로드</Button>
          <InvisibleInput
            type="file"
            ref={fileInput}
            onChange={handleImgChange}
          />
          {serviceImageId === null ? (
            <Frame>
              이미지
              <br />
              없음
            </Frame>
          ) : (
            <RelativeFrame>
              <CloseButton
                className="delete k-icon k-i-close-outline k-i-x-outline"
                onClick={handleDeleteImage}
              />
              <ImageFrame
                src={consts.s3BaseUrl + targetData[0]?.serviceImage?.path}
                onClick={() => {
                  dispatch(
                    openImageView({
                      images: [
                        {
                          src: targetData[0]?.serviceImage?.path.slice(1),
                          title: targetData[0].goodsGroupName,
                        },
                      ],
                    })
                  );
                }}
              />
            </RelativeFrame>
          )}
        </ImageWrapper>
      </Fragment>
    );
  };

  return (
    <GoodGroupsArea>
      <EditHead>
        {editable ? (
          <EditInput
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={targetData[0]?.goodsGroupName}
            minLength={1}
            maxLength={8}
          />
        ) : (
          <h1>{targetData[0]?.goodsGroupName}</h1>
        )}
        <ImageWrapper>
          {editable ? (
            <form onSubmit={onSubmit}>
              <MrButton type="submit">저장</MrButton>
              <MrButton onClick={() => setEditable(!editable)}>취소</MrButton>
            </form>
          ) : (
            targetData[0]?.type === "EDITABLE" && (
              <MrButton onClick={() => setEditable(!editable)}>
                상품군 수정
              </MrButton>
            )
          )}
          <ImagePreview />
        </ImageWrapper>
      </EditHead>
      <ReorderContext.Provider
        value={{
          reorder: reorder,
          dragStart: dragStart,
          dragEnd: dragEnd,
        }}
      >
        <DragAndDrop>
          <Grid
            style={{
              height: "500px",
            }}
            data={targetData[0]?.goods}
            {...dataState}
            dataItemKey={"goodsId"}
            resizable={true}
            onDataStateChange={handleDataState}
            rowRender={(row, rowProps) => (
              <DraggableRow elementProps={row.props} {...rowProps} />
            )}
          >
            <span>{`선택된 상품 전체선택 기능만 추가하면 끝`}</span>
            <Column
              width="50px"
              headerCell={() => {
                return (
                  <StyledTd>
                    <Checkbox
                      checked={
                        targetData[0]?.goods.length !== 0 &&
                        targetData[0]?.goods.length === deleteGoods.length
                      }
                      onClick={handleClickSelectedAllCheck}
                    />
                  </StyledTd>
                );
              }}
              cell={CheckboxCell}
            />
            <Column title="순서" width="50px" cell={DragHandleCell} />
            <Column field="goodsName" title="상품명" width="200px" />

            <Column
              field="goodsDisplayModeList"
              title="전용 상품"
              columnMenu={ColumnMenuCheckboxFilter(
                getGoodsListOfCurrentViews(goodsGroups, selectedId)
              )}
            />
          </Grid>
        </DragAndDrop>
      </ReorderContext.Provider>
    </GoodGroupsArea>
  );
};

const GoodsLayout = ({ selectedId, addGoods, setAddGoods }) => {
  const dispatch = useDispatch();
  const [goods, setGoods] = useState([]);
  const [result, setResult] = useState([]);
  const gridData = useMemo(() => {
    return Object.keys(goods).reduce((acc, categoryName) => {
      goods[categoryName].forEach((item) => {
        acc.push(item);
      });
      return acc;
    }, []);
  }, [goods]);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getGoods()
      .then((res) => {
        const parsedGoods = {};

        const keysOfRes = Object.keys(res.data.data);
        keysOfRes.forEach((key) => {
          const currentItem = res.data.data[key];
          const translatedCurrentItem = translateGoodsDisplayToKor(currentItem);
          parsedGoods[key] = translatedCurrentItem;
        });
        setGoods(parsedGoods);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  }, [selectedId]);

  useEffect(() => {
    setResult(gridData);
  }, [gridData]);

  const createDataState = (dataState) => {
    return {
      result: process(gridData, dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    skip: 0,
  });

  const [dataState, setDataState] = useState(initialState.dataState);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setAddGoods([]);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const handleClickSelectedAllCheck = useCallback(() => {
    let data = [];
    if (Array.isArray(result)) data = result;
    else if (result?.data && Array.isArray(result.data)) data = result?.data;
    else {
    }

    if (addGoods.length === data.length) {
      setAddGoods([]);
    } else {
      setAddGoods(data.map((item) => item.goodsId));
    }
  }, [result, addGoods]);

  const CheckboxCell = ({ dataItem }) => {
    const handleCheckboxChange = (event) => {
      const checked = event.syntheticEvent.target.checked;
      if (checked) {
        setAddGoods([...addGoods, dataItem.goodsId]);
      } else {
        setAddGoods(addGoods.filter((item) => item !== dataItem.goodsId));
      }
    };

    const isChecked = addGoods.some((item) => item === dataItem.goodsId);

    return (
      <StyledTd>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
      </StyledTd>
    );
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuFilter {...props} expanded={true} />
      </div>
    );
  };
  // HEADER Scroll

  console.log(dataState);
  return (
    <GoodsArea>
      <h1>일반 상품</h1>
      <Grid
        style={{
          height: "500px",
        }}
        data={result}
        {...dataState}
        sortable={true}
        resizable={true}
        onDataStateChange={dataStateChange}
      >
        <Column
          width="50px"
          headerCell={() => {
            return (
              <StyledTd>
                <Checkbox
                  checked={
                    Array.isArray(result)
                      ? result.length === addGoods.length
                      : result.data.length === addGoods.length
                  }
                  onClick={handleClickSelectedAllCheck}
                />
              </StyledTd>
            );
          }}
          cell={CheckboxCell}
        />
        <Column
          field="goodsName"
          title="상품명"
          width="200"
          columnMenu={ColumnMenu}
        />
        <Column
          field="categoryName"
          title="카테고리"
          width="100"
          columnMenu={ColumnMenuCheckboxFilter(gridData)}
        />
        <Column field="code" title="상품코드" columnMenu={ColumnMenu} />
        <Column
          field="goodsDisplayModeList"
          title="전용 상품"
          columnMenu={ColumnMenuCheckboxFilter(gridData)}
        />
      </Grid>
    </GoodsArea>
  );
};

export const ReorderContext = createContext({
  reorder: (dataItem) => {},
  dragStart: (dataItem) => {},
  dragEnd: (dataItem) => {},
});

const DragHandleCell = (props) => {
  const {
    ariaColumnIndex,
    dataItem,
    selectionChange,
    columnIndex,
    columnsCount,
    rowType,
    expanded,
    dataIndex,
    isSelected,
    ...rest
  } = props;
  return (
    <td
      {...rest}
      style={{
        touchAction: "none",
      }}
    >
      <AlignSpan
        style={{
          cursor: "move",
        }}
        data-drag-handle="true"
      >
        <SvgIcon
          style={{
            pointerEvents: "none",
          }}
          icon={reorderIcon}
        />
      </AlignSpan>
    </td>
  );
};

// 스타일드 컴포넌트
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  height: 730px;
  padding: 20px 50px;

  display: flex;
  align-items: center;
`;
const FilterArea = styled.div`
  width: 150px;
  height: 100%;
  border: 1px solid lightgray;
  margin-right: 20px;
`;

const FilterTabArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  button {
    margin: 5px 0;
  }
`;

const NoneEditableArea = styled(FlexCenter)`
  flex-direction: column;
`;

const GoodGroupsArea = styled.div`
  width: 660px;
  height: 100%;
  padding: 20px;
  border: 1px solid lightgray;
`;

const GoodsArea = styled.div`
  width: 700px;
  height: 100%;
  border: 1px solid lightgray;
  padding: 20px;
`;

const ButtonWrapper = styled(FlexCenter)`
  flex-direction: column;
  width: 50px;
  height: 100%;

  button {
    margin: 5px 0;
  }
`;

const ButtonGroup = styled(FlexCenter)`
  flex-direction: column;
  margin-top: 10px;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: lightgray;
`;

const ScrollableArea = styled.div`
  overflow-y: auto;
  height: 300px;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 120px;
  height: 30px;

  &:active {
    background-color: black;
  }
`;

const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

const StyledTd = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlignSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditableGroup = styled(FlexCenter)`
  flex-shrink: 0;
`;

const EditHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  button {
    height: 30px;
  }
`;

const EditInput = styled(Input)`
  width: 200px;
  height: 41px;
  margin-bottom: 10px;
`;

const MrButton = styled(Button)`
  margin-right: 10px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageFrame = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 15px;
`;

const RelativeFrame = styled.div`
  position: relative;
  display: inline-block;
`;

const CloseButton = styled.i`
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: white;
  color: red;
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
`;

const Frame = styled(FlexCenter)`
  width: 50px;
  height: 50px;
  margin-left: 15px;
  border: 1px solid lightgray;

  font-size: 12px;
  text-align: center;
`;
