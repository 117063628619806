import { ROLE } from "../../libs/routes";

// Actions
const OPEN = "modalImage/OPEN";
const CLOSE = "modalImage/CLOSE";

const INIT_STATE = {
  open: false,
  src: null,
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case OPEN:
      return { ...INIT_STATE, open: true, ...action.payload };
    case CLOSE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}

// Action 생성자
export function openImageModal(payload) {
  return { type: OPEN, payload };
}

export function closeImageModal(payload) {
  return { type: CLOSE };
}
