import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";

import Flex from "../../components/flex/Flex";
import Button from "../../components/button/Button";

import Input from "../../components/input/Input";

import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";

export default function Category({ rows, i, makerModify, makerModifylabel }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  var modeType = 0;
  if (!rows.kind) {
    modeType = 1;
  }

  const [modCker, setmodCker] = useState(modeType);
  const [makerinput, setmakerinput] = useState(rows.kind);
  const [makerName, setmakerName] = useState(rows.label);
  const modChangeFunc = () => {
    if (
      makerName !== undefined &&
      makerName !== "" &&
      makerinput !== undefined &&
      makerinput !== "" &&
      modCker === 1
    ) {
      setmodCker(0); //저장 버튼표시
    } else {
      setmodCker(1); //수정 버튼표시
    }
  };

  const modmakerName = (v, i) => {
    setmakerinput(v);
    makerModify(v, i);
  };
  const modmakerLabel = (v, i) => {
    setmakerName(v);
    makerModifylabel(v, i);
  };

  return (
    <>
      <Flex row className={classes.row}>
        <span>
          {modCker === 0 ? (
            <span>{makerName}</span>
          ) : (
            <Input
              value={makerName}
              onChange={(v) => {
                modmakerLabel(v, i);
              }}
              placeholder={""}
              className={classes.input}
            />
          )}
        </span>
        <span style={{ color: "red" }}>
          {modCker === 0 ? (
            <span>{makerinput}</span>
          ) : (
            <Input
              value={makerinput}
              onChange={(v) => {
                modmakerName(v, i);
              }}
              placeholder={""}
              className={classes.input}
            />
          )}
        </span>
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => modChangeFunc()}
        >
          {modCker === 0 ? "수정" : "저장"}
        </span>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    borderBottom: "1px solid rgba(0,0,0,0.23)",
    alignSelf: "stretch",
    justifyContent: "space-between",
    marginRight: "50%",
    padding: "10px 0px",
    alignItems: "center",
  },
});
