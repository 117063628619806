import React from "react";
import DaumPostcode from "react-daum-postcode";

const FindAddress = ({ width = 500, height = width * 1.618, callback }) => {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    callback && callback({ postCode: data.zonecode, address: fullAddress });
  };
  return (
    <DaumPostcode
      style={{ width, height }}
      submitMode={false}
      onComplete={handleComplete}
    />
  );
};

export default FindAddress;
