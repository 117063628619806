import { InputBase, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "components/button/Button";
import Flex from "components/flex/Flex";
import GridRow from "components/grid-row/GridRow";
import PageLabel from "components/page-label/PageLabel";
import { actionOpen } from "redux/action/ActionActions";

export default function AppraisalStockDetail({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = history.location.state || {};

  const classes = useStyle();
  return (
    <>
      <PageLabel>재고상세</PageLabel>

      <Flex row className={classes.row}>
        <Button label="취소" className={classes.cancel} />
        <Button
          label="저장"
          onClick={() => {
            dispatch(
              actionOpen("변경사항을 저장하시겠습니까?", null, "취소", "저장")
            );
          }}
        />
      </Flex>

      <GridRow
        data={[
          { label: "접수번호", value: "2021-12-31 13:32:00" },
          {
            label: "재고상태",
            value: "",
            type: "render",
            render: (
              <select>
                <option label="입고" />
                <option label="미입고" />
              </select>
            ),
          },
          { label: "교환일시", value: "" },
          { label: "입고일시", value: "" },
          { label: "주문자", value: "" },
          { label: "휴대폰", value: "" },
          { label: "접수방법", value: "" },
          { label: "매장", value: "" },
          { label: "분류", value: "" },
          { label: "중량", value: "" },
          {
            label: "비고",
            value: "",
            type: "render",
            render: (
              <InputBase
                multiline
                style={{
                  minHeight: 200,
                  border: "1px solid #f5f5f5",
                  flex: 1,
                  marginRight: "30%",
                }}
              />
            ),
            fullWidth: true,
          },
          {
            label: "수수료 정산",
            value: "",
            fullWidth: true,
            type: "render",
            render: (
              <Flex row className="row-center">
                <select>
                  <option label="선택" />
                  <option label="추가 지급" />
                  <option label="수수료 차감" />
                </select>
                <InputBase
                  style={{
                    padding: "5px 0px",
                    border: "1px solid rgba(0,0,0,0.23)",
                    marginLeft: 20,
                    marginRight: 10,
                  }}
                />
                원
                <div
                  style={{
                    margin: "0px 20px",
                    width: 1,
                    alignSelf: "stretch",
                    backgroundColor: "rgb(220,220,220)",
                  }}
                />
                정산이유
                <InputBase
                  style={{
                    padding: "5px 0px",
                    border: "1px solid rgba(0,0,0,0.23)",
                    marginLeft: 20,
                    marginRight: 10,
                  }}
                />
              </Flex>
            ),
          },
        ]}
      />
    </>
  );
}

const useStyle = makeStyles({
  row: {
    alignItems: "center",
    alignSelf: "flex-end",
    "& button": {
      width: "150px",
    },
    marginTop: 50,
    marginRight: 50,
  },
  cancel: {
    marginRight: 10,
    border: "1px solid black",
    backgroundColor: "#fff",
    "& span": {
      color: "#000",
    },
  },
});
