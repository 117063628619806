import * as APIS from "libs/apis";
import { actionError } from "redux/action/ActionActions";

// Actions
const SET = "shop/SET";
const GET = "shop/GET";

const INIT_STATE = {
  associateShops: [],
  isLoading: false,
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET:
      return { ...state, associateShops: action.payload, isLoading: true };

    default:
      return state;
  }
}

// Action 생성자
export const getAssociateShops = () => async (dispatch) => {
  try {
    const shops = await APIS.getAssociateShops();
    if (shops.data?.success) {
      dispatch({
        type: GET,
        payload: shops.data?.data.map((item) => ({
          label: item?.shopName,
          value: item?.shopId,
          feeRate: item?.associateRate,
        })),
      });
    }
  } catch (err) {
    dispatch(actionError(err));
  }
};
export function setAssociateShops(payload) {
  return { type: SET, payload };
}
