import { makeStyles } from "@material-ui/core";
import React from "react";
import Flex from "../../components/flex/Flex";

export default function BasicLayout({ className, children, center }) {
  const classes = useStyle();
  return (
    <Flex
      className={`${classes.root} ${className} ${
        center ? classes.rootCenter : ""
      }`}
    >
      {children}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: "rgb(239,243,247)",
    flex: 1,
  },
  rootCenter: {
    alignItems: "center",
    justifyContent: "center",
  },
});
