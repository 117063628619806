import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import ContextMenuLayout from "../../components/excel-download/ContextMenuLayout";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import Text from "../../components/text/Text";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY, getDefaultPageSize } from "../../libs/consts";
import fonts from "../../libs/fonts";
import { numberWithCommas } from "../../libs/utils";
import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { formatTime, objToQueryStr } from "../../services/utils";

const OUTCOME_STATUS = [
  { label: "미완료", value: "NONE" },
  { label: "현금영수증", value: "CASH" },
  { label: "자진발행", value: "SELF" },
  { label: "세금계산서", value: "TAX_BILL" },
];

export default function SettlementSalesFee({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const { location, replace } = useHistory();
  const state = location.state || {};
  const [totalCount, setTotalCount] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [summary, setSummary] = useState({});

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        beginDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        ...state,
        ...getDefaultPageSize(),
        salesDateType: "CONTRACT_DATE",
      });
    }
  }, []);

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    dispatch(loadingStart);
    const paramQuery = objToQueryStr({
      ...state,
      salesDateType: "CONTRACT_DATE",
      page: state.page,
      size: state.size,
    });
    const getSearch = APIS.getoutcomefeeSearch(paramQuery).then(
      ({ data: { data, message, success } }) => {
        if (success) {
          setDataList(data.content);
          setTotalCount(data.totalElements);
        } else {
          dispatch(actionError(message));
        }
      }
    );

    const getSum = APIS.getoutcomefeeSummary(paramQuery).then(
      ({ data: { success, data, message } }) => {
        if (success) {
          setSummary(data);
        } else {
          dispatch(actionError(message));
        }
      }
    );

    Promise.all([getSearch, getSum]).finally(() => dispatch(loadingEnd));
  };

  const statusChfunc = async (adminPassword) => {
    if (selectedIds.length === 0) {
      dispatch(actionError("선택된 항목이 없습니다."));
      return;
    }
    const data = {
      salesReportType: "CASH",
      userTradeLogIdList: selectedIds,
      managerPassword: adminPassword,
    };
    console.log(data);

    dispatch(loadingStart);
    APIS.patchOutcomeFeeStatus(data)
      .then(({ data: { success, message } }) => {
        if (success) {
          fetchList();
          setSelectedIds([]);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => {
        dispatch(actionError(err));
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onClickExcelDownload = () => {
    const param = { ...state, salesDateType: "CONTRACT_DATE" };
    delete param.page;
    delete param.size;

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.getoutcomefeeSearchExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `매출수수료_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  const onClickCashList = () => {
    const param = { ...state, salesDateType: "CONTRACT_DATE" };
    delete param.page;
    delete param.size;

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.getoutcomefeeSearchCashExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `현금영수증_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const searchResult = useMemo(
    () =>
      console.log(123) ||
      dataList.map((row, rowIndex) => {
        const returnRow = { ...row };
        returnRow.no = row.id;
        //totalCount - parseInt(state.page) * parseInt(state.size) - rowIndex;
        //CASH
        returnRow.requestAt = formatTime(row.requestAt, "YY-MM-DD HH:mm:ss");
        returnRow.completedAt = formatTime(
          row.completedAt,
          "YY-MM-DD HH:mm:ss"
        );
        returnRow.salesReportAt =
          returnRow.salesReportType === "CASH"
            ? formatTime(row.salesReportAt, "YY-MM-DD HH:mm:ss")
            : "-";

        returnRow.recommendShopName = row.recommendShopName || "-";
        returnRow.inOutShopName = row.inOutShopName || "-";

        switch (row.salesReportType) {
          case "OPEN":
            returnRow.salesReportType = "미완료";
            break;
          case "CASH":
            returnRow.salesReportType = "현금영수증";
            break;
          case "SELF":
            returnRow.salesReportType = "자진발행";
            break;
          case "TAX_BILL":
            returnRow.salesReportType = "세금계산서";
            break;
          case "CANCEL":
            returnRow.salesReportType = "취소";
            break;
        }

        switch (row.assetType) {
          case "GOLD":
            returnRow.assetType = "금";
            break;
          case "SILVER":
            returnRow.assetType = "은";
            break;
        }

        // 부가세
        returnRow.surtax = Math.floor(row.userFee / 11);
        // 공급가
        returnRow.supply = row.userFee - returnRow.surtax;

        switch (row.tradeType) {
          case "BUY":
            returnRow.tradeType = "매수";
            break;
          case "SELL":
            returnRow.tradeType = "매도";
            break;
          case "GOODS":
            returnRow.tradeType = "실물인출";
            break;
          case "WITHDRAW":
            returnRow.tradeType = "출금";
            returnRow.pureTradeKrw = "";
            break;
          case "SAFE_TRADE_BUY":
            returnRow.tradeType = "안심 구매";
            break;
          case "SAFE_TRADE_SELL":
            returnRow.tradeType = "안심 판매";
            break;
          case "SAFE_TRADE_BUY_PENALTY":
            returnRow.tradeType = "안심 구매 철회";
            break;
          case "SAFE_TRADE_SELL_PENALTY":
            returnRow.tradeType = "안심 판매 철회";
            break;
          case "SAFE_TRADE_AUTH_PRICE":
            returnRow.tradeType = "안심 감정";
            break;
          case "STORAGE_FEE":
            returnRow.tradeType = "보관료";
            break;
          case "APPRAISAL_FEE":
            returnRow.tradeType = "감정평가";
            break;
        }
        returnRow.isCashReceiptRequest = row.isCashReceiptRequest || null;

        return returnRow;
      }),
    [dataList]
  );

  const { sumTradeKrw, sumTotal, sumSupply, sumVat } = summary;

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  //CONTRACT_DATE 거래일
  return (
    <>
      <PageLabel>매출 수수료 조회</PageLabel>
      <Filters
        onSearch={fetchList}
        defaultState={{
          page: 0,
          size: 10,
          salesDateType: "ADJUST_DATE",
          beginDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        }}
        excel={onClickExcelDownload}
        customExcel={onClickCashList}
        customExcelLabel={"현금영수증 대상"}
        data={[
          { label: "날짜", type: "date-range", key: "beginDate,endDate" },
          {
            label: "회원검색",
            type: "input",
            placeholder: "고객명, 연락처, 고객번호",
            key: "memberName",
            autoFocus: true,
          },
          {
            label: "매장검색",
            type: "input",
            placeholder: "매장명,연락처",
            key: "shopName",
          },
          {
            label: "거래형태",
            type: "checkbox",
            key: "tradeTypeList",
            data: [
              { label: "실물인출", value: "GOODS" },
              { label: "매수", value: "BUY" },
              { label: "매도", value: "SELL" },
              { label: "출금", value: "WITHDRAW" },
              { label: "안심 구매", value: "SAFE_TRADE_BUY" },
              { label: "안심 판매", value: "SAFE_TRADE_SELL" },
              {
                label: "안심 구매 철회",
                value: "SAFE_TRADE_BUY_PENALTY",
              },
              {
                label: "안심 판매 철회",
                value: "SAFE_TRADE_SELL_PENALTY",
              },
              { label: "안심 감정", value: "SAFE_TRADE_AUTH_PRICE" },
              { label: "보관료", value: "STORAGE_FEE" },
              { label: "감정평가", value: "APPRAISAL_FEE" },
            ],
          },
          {
            label: "자산",
            type: "menu",
            key: "goldOrSilver",
            data: [
              { label: "금", value: "GOLD" },
              { label: "은", value: "SILVER" },
            ],
          },
          {
            label: "정산여부",
            type: "menu",
            key: "salesReportType",
            data: OUTCOME_STATUS,
          },
          {
            label: "현금영수증 요청",
            type: "menu",
            key: "isCashReceiptRequest",
            data: [
              { label: "발급요청", value: "true" },
              { label: "요청없음", value: "false" },
            ],
          },
        ]}
      />
      <Flex row className={classes.tableHeader}>
        <Flex
          row
          className={"row-center"}
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Text font={fonts.notoSansKRBold}>
              목록 검색결과 {numberWithCommas(totalCount)}
            </Text>
            <Text font={fonts.notoSansKRBold} className={classes.label}>
              총 거래금액 : {numberWithCommas(sumTradeKrw)} 원
            </Text>
            <Text font={fonts.notoSansKRBold} className={classes.label}>
              총 수수료 : {numberWithCommas(sumTotal)} 원
              <Text>
                (공급가 : {numberWithCommas(sumSupply)} 원 / 부가세 :
                {numberWithCommas(sumVat)} 원)
              </Text>
            </Text>{" "}
          </div>
          <AdminConfirmButton
            popupLabel="현금영수증 발행"
            popupMessage="현금영수증을 발행하시겠습니까?"
            label="발행"
            callback={statusChfunc}
            rootClassName={classes.confirmRoot}
            btnClassName={classes.confirmButton}
          />
        </Flex>
      </Flex>
      <SalesFeeTable
        data={searchResult}
        fetchList={fetchList}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
      {!isNaN(state.page) && (
        <TablePagination
          onChangePage={(e, p) => {
            replace(location.pathname, {
              ...state,
              page: p,
            });
          }}
          className={classes.pagination}
          count={totalCount || 0}
          onChangeRowsPerPage={(e) => {
            replace(location.pathname, {
              ...state,
              page: 0,
              size: e.target.value,
            });
            localStorage.setItem(STORAGE_KEY.tableSize, e.target.value);
          }}
          page={state.page > 0 && totalCount < state.page ? 0 : state.page}
          rowsPerPage={parseInt(state.size)}
          style={{ margin: "0px 50px" }}
        />
      )}
    </>
  );
}
const SalesFeeTable = React.memo(
  ({ data = [], fetchList, selectedIds, setSelectedIds }) => {
    const classes = useStyle();

    // 현금영수증 발행 조건
    const isSelectable = (row) =>
      ["실물인출", "보관료"].includes(row.tradeType) &&
      row.salesReportType === "자진발행";

    const handleSelectAllChange = (event) => {
      if (event.target.checked) {
        // 조건을 만족하는 행만 선택
        const selectableIds = data
          .filter((row) => isSelectable(row))
          .map((row) => row.id);
        setSelectedIds(selectableIds);
      } else {
        setSelectedIds([]);
      }
    };

    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((itemId) => itemId !== id)
          : [...prevSelected, id]
      );
    };

    // 모든 항목이 선택되었는지 확인
    const isAllSelected =
      data.length > 0 &&
      selectedIds.length === data.filter((row) => isSelectable(row)).length;

    const headerRows = [
      [
        { label: "NO", rowSpan: 2, width: 50 },
        { label: "접수번호", rowSpan: 2 },
        { label: "거래일시", rowSpan: 2 },
        { label: "거래형태", rowSpan: 2 },
        { label: "자산", rowSpan: 2 },
        { label: "고객", rowSpan: 2 },
        { label: "고객번호", rowSpan: 2 },
        { label: "추천매장", rowSpan: 2 },
        { label: "입/출고 매장", rowSpan: 2 },
        { label: "거래금액", rowSpan: 2 },
        { label: "수수료", colSpan: 3 },
        { label: "정산여부", rowSpan: 2 },
        { label: "발행일시", rowSpan: 2 },
        { label: "현금영수증 발급요청", rowSpan: 2 },
        { label: "현금영수증", rowSpan: 2 },
      ],
      [{ label: "공급가" }, { label: "부가세" }, { label: "합계" }],
    ];

    const columns = [
      { key: "no", label: "NO", align: "center" },
      { key: "tradeNumber", label: "접수번호", align: "center" },
      { key: "completedAt", label: "거래일시", align: "center" },
      { key: "tradeType", label: "거래형태", align: "center" },
      { key: "assetType", label: "자산", align: "center" },
      { key: "userName", label: "고객", align: "center" },
      { key: "userId", label: "고객번호", align: "center" },
      { key: "recommendShopName", label: "추천매장", align: "center" },
      { key: "inOutShopName", label: "입/출고 매장", align: "center" },
      { key: "pureTradeKrw", label: "거래금액", type: "number" },
      { key: "supply", label: "공급가", type: "number" },
      { key: "surtax", label: "부가세", type: "number" },
      { key: "userFee", label: "합계", type: "number" },
      { key: "salesReportType", label: "정산여부", align: "center" },
      { key: "salesReportAt", label: "발행일시", align: "center" },
    ];

    return (
      <Flex style={{ margin: "0 50px 0 50px" }}>
        <ContextMenuLayout name="매출수수료" headers={columns} data={data}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                {headerRows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map(({ label, rowSpan, colSpan, width, render }) => (
                      <TableCell
                        key={label}
                        rowSpan={rowSpan}
                        colSpan={colSpan}
                        width={width}
                      >
                        {rowIndex === 0 && label === "현금영수증" ? (
                          <Checkbox
                            checked={isAllSelected}
                            onChange={handleSelectAllChange}
                          />
                        ) : (
                          render || label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    {columns.map(({ key, align, type }) => {
                      const defaultAlign = type === "number" ? "right" : "left";
                      return (
                        <TableCell align={align || defaultAlign}>
                          {type === "number"
                            ? numberWithCommas(row[key])
                            : row[key]}
                        </TableCell>
                      );
                    })}
                    <TableCell align="center">
                      {row.isCashReceiptRequest ? "발급요청" : "요청없음"}
                    </TableCell>
                    <TableCell align="center">
                      {isSelectable(row) ? (
                        <Checkbox
                          checked={selectedIds.includes(row.id)}
                          onChange={() => handleCheckboxChange(row.id)}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ContextMenuLayout>
      </Flex>
    );
  }
);

const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    marginRight: 50,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  tableHeader: {
    alignSelf: "stretch",
    marginTop: 50,
    justifyContent: "space-between",
    margin: "10px 50px",
  },
  table3: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
  confirmRoot: {
    margin: 0,
    justifyContent: "center",
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});
