GridRow.js;

import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  NativeSelect,
  Radio,
  RadioGroup,
  Typography,
  Button,
  CardMedia,
  Icon,
} from "@material-ui/core";
import consts from "../../libs/consts";
import React, { useRef, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
// import Button from "../button/Button";
import Flex from "../flex/Flex";
import Input from "../input/Input";
import Text from "../text/Text";
import { PanoramaOutlined, Add } from "@material-ui/icons";
import Editor from "../../components/editor/Editor";
import RenderInput from "../../components/render-input/RenderInput";
import { useDispatch } from "react-redux";
import { actionError } from "../../redux/action/ActionActions";

export default function GridRow({
  label,
  data = [],
  goBack,
  onSave,
  defaultState,
}) {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [imagePopup, setImagePopup] = useState("");
  const [imageBanner, setImageBanner] = useState("");

  const [imagedel, setimagedel] = useState([]);
  const [imagePopupDel, setImagePopupDel] = useState([]);
  const [imageBannerDel, setImageBannerDel] = useState([]);

  // const imageInputRef = React.useRef();
  // const imageBannerInputRef = React.useRef();
  // const imagePopupInputRef = React.useRef();

  // const imageRef = useRef();
  // const imagePopupRef = useRef();
  // const imageBannerRef = useRef();

  // const [imageFile, setImageFile] = useState();
  // const [imagePopupFile, setImagePopupFile] = useState();
  // const [imageBanerFile, setImageBanerFile] = useState();

  const handleChangeValue = ({ target }) => {
    const { name, value } = target;
    setContent(value);
  };
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const onChangeImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(/image-*/)) {
        dispatch(actionError("등록할 수 없는 파일입니다."));
        return;
      }
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setImage(file);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };
  // const initValue = () => {
  //   history.replace({
  //     pathname: location.pathname,
  //     state: {
  //       ...defaultState,
  //     },
  //   });
  // };
  if (!location.state) {
    return (
      <Redirect
        to={{ pathname: history.location.pathname, state: { ...defaultState } }}
      />
    );
  }

  return (
    <Flex className={classes.root}>
      <Text style={{ marginBottom: 10 }} font={fonts.notoSansKRBold}>
        {label}
      </Text>
      <Grid container className={classes.container}>
        {data.map((x, i) => {
          return (
            <Grid
              style={{
                width: x.fullWidth
                  ? "100%"
                  : x.renderWidth
                  ? x.renderWidth
                  : "50%",
              }}
              key={i.toString()}
              className={classes.item}
              item
            >
              <Text font={fonts.notoSansKRMedium} className={classes.label}>
                {x.label}
              </Text>
              <Flex className={classes.value} row>
                {(() => {
                  const value = x.value;
                  switch (x.type) {
                    case "input-password":
                    case "input-number":
                    case "input":
                      return (
                        <Input
                          password={x.type === "input-password"}
                          value={value}
                          onChange={(v) => {
                            if (x.type === "input-number") {
                              if (v && isNaN(v.replace(/./g, ""))) {
                                return;
                              }
                            }
                            if (x.key === "subject") {
                              handleValueChange(x.key, v);
                            }
                            x.onChange && x.onChange(v);
                          }}
                          placeholder={x.placeholder}
                          className={
                            classes.input +
                            " " +
                            (x.inputFullWidth && classes.inputFull)
                          }
                          autoComplete="new-password"
                          validation={x.validation}
                          maxLength={x.maxLength}
                          disabled={x?.disabled === true ? true : false}
                        />
                      );

                    case "image":
                      return (
                        <Grid
                          item
                          xs={12}
                          className={[classes.df, classes.infoContent].join(
                            " "
                          )}
                          style={{ flexDirection: "column" }}
                        >
                          <Grid item xs={12} className={classes.df}>
                            <Grid
                              item
                              xs={11}
                              className={[classes.dfsc, classes.infocell].join(
                                " "
                              )}
                            >
                              {image ? (
                                <div
                                  className={classes.df}
                                  style={{ flexDirection: "column" }}
                                >
                                  <div
                                    className={classes.dfcc}
                                    style={{
                                      width: "150px",
                                      marginLeft: "16px",
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      image={
                                        image.path
                                          ? consts.s3BaseUrl + "/" + image.path
                                          : image.uri
                                      }
                                      width="100"
                                    />
                                  </div>
                                  {/* 삭제  */}
                                  <Grid item xs={12} className={classes.dfcs}>
                                    <Button
                                      onClick={() => {
                                        setImage("");
                                        setimagedel([...imagedel, image.id]);
                                      }}
                                      size="small"
                                    >
                                      <Typography variant="subtitle2">
                                        삭제
                                      </Typography>
                                    </Button>
                                  </Grid>
                                </div>
                              ) : (
                                <>
                                  <input
                                    accept="image/*"
                                    type="file"
                                    id="rasied-button-file"
                                    style={{ display: "none" }}
                                    onChange={onChangeImage}
                                  />
                                  <label htmlFor="rasied-button-file">
                                    <Button
                                      className={classes.imageAddBtn}
                                      component="span"
                                    >
                                      <PanoramaOutlined />
                                      이미지 등록
                                    </Button>
                                  </label>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    // case "image-popup":
                    //   return (
                    //     <Grid item xs={12} className={[classes.df, classes.infoContent].join(" ")}
                    //      style={{ flexDirection: "column" }}>
                    //      <Grid item xs={12} className={classes.df}>
                    //        <Grid item xs={11} className={[classes.dfsc, classes.infocell].join(" ")}>
                    //        {imagePopup? (
                    //               <div
                    //                  className={classes.df}
                    //                  style={{ flexDirection: "column" }}
                    //                >
                    //                  <div
                    //                    className={classes.dfcc}
                    //                    style={{ width: "150px", marginLeft: "16px" }}
                    //                  >
                    //                    <CardMedia
                    //                      component="img"
                    //                      image={
                    //                       imagePopup.path
                    //                          ? consts.s3BaseUrl + "/" + imagePopup.path
                    //                          : imagePopup.uri
                    //                      }
                    //                      width="100"
                    //                    />
                    //                  </div>
                    //                  {/* 삭제  */}
                    //                  <Grid item xs={12} className={classes.dfcs}>
                    //                    <Button
                    //                      onClick={() => {
                    //                        setImagePopup("");
                    //                        setImagePopupDel([...imagePopupDel, imagePopup.id]);
                    //                      }}
                    //                      size="small"
                    //                    >
                    //                      <Typography variant="subtitle2">삭제</Typography>
                    //                    </Button>
                    //                  </Grid>
                    //                </div>
                    //          ) : (
                    //          <>
                    //            <input
                    //              accept="image/*"
                    //              type="file"
                    //              id="rasied-button-file"
                    //              style={{ display: "none" }}
                    //              onChange={onChangeImage}
                    //            />
                    //            <label htmlFor="rasied-button-file">
                    //             <Button className={classes.imageAddBtn} component="span">
                    //               <PanoramaOutlined />
                    //                이미지 등록
                    //             </Button>
                    //            </label>
                    //          </>
                    //           )}
                    //        </Grid>
                    //      </Grid>
                    //    </Grid>
                    //  );
                    // case "image-banner":
                    //   return(
                    //     <Grid item xs={12} className={[classes.df, classes.infoContent].join(" ")}
                    //     style={{ flexDirection: "column" }}>
                    //     <Grid item xs={12} className={classes.df}>
                    //       <Grid item xs={11} className={[classes.dfsc, classes.infocell].join(" ")}>
                    //       {imageBanner? (
                    //              <div
                    //                 className={classes.df}
                    //                 style={{ flexDirection: "column" }}
                    //               >
                    //                 <div
                    //                   className={classes.dfcc}
                    //                   style={{ width: "150px", marginLeft: "16px" }}
                    //                 >
                    //                   <CardMedia
                    //                     component="img"
                    //                     image={
                    //                       imageBanner.path
                    //                         ? consts.s3BaseUrl + "/" + imageBanner.path
                    //                         : imageBanner.uri
                    //                     }
                    //                     width="100"
                    //                   />
                    //                 </div>
                    //                 {/* 삭제  */}
                    //                 <Grid item xs={12} className={classes.dfcs}>
                    //                   <Button
                    //                     onClick={() => {
                    //                       setImageBanner("");
                    //                       setImageBannerDel([...imageBannerDel, imageBanner.id]);
                    //                     }}
                    //                     size="small"
                    //                   >
                    //                     <Typography variant="subtitle2">삭제</Typography>
                    //                   </Button>
                    //                 </Grid>
                    //               </div>
                    //         ) : (
                    //         <>
                    //           <input
                    //             accept="image/*"
                    //             type="file"
                    //             id="rasied-button-file"
                    //             style={{ display: "none" }}
                    //             onChange={onChangeImage}
                    //           />
                    //           <label htmlFor="rasied-button-file">
                    //            <Button className={classes.imageAddBtn} component="span">
                    //              <PanoramaOutlined />
                    //               이미지 등록
                    //            </Button>
                    //           </label>
                    //         </>
                    //          )}
                    //       </Grid>
                    //     </Grid>
                    //   </Grid>
                    //   );
                    case "radio":
                      return (
                        <RadioGroup
                          row
                          value={value}
                          onChange={(e) => {
                            handleValueChange(x.key, e.target.value);
                            x.onChange && x.onChange(e.target.value);
                          }}
                        >
                          {x.data?.map((radio, index) => {
                            return (
                              <FormControlLabel
                                key={index.toString()}
                                value={radio.value}
                                control={
                                  <Radio
                                    color="default"
                                    style={{
                                      color: colors.black,
                                    }}
                                  />
                                }
                                label={radio.label}
                              />
                            );
                          })}
                        </RadioGroup>
                      );

                    case "menu":
                      return (
                        <FormControl>
                          <NativeSelect
                            value={value}
                            onChange={(e) => {
                              x.onChange && x.onChange(e.target.value);
                            }}
                            input={
                              <InputBase className={classes.selectInput} />
                            }
                          >
                            <option aria-label="None" value="" />
                            {x.data.map((menuItem, index) => {
                              return (
                                <option
                                  key={index.toString()}
                                  value={menuItem.value}
                                >
                                  {menuItem.label}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </FormControl>
                      );
                    case "date-range":
                      let [dateKey1, dateKey2] = x.key.split(",");
                      let dateValue1 = location.state[dateKey1];
                      let dateValue2 = location.state[dateKey2];
                      return (
                        <Flex row>
                          <input
                            value={dateValue1 || ""}
                            onChange={(e) => {
                              handleValueChange(dateKey1, e.target.value);
                            }}
                            type="date"
                            className={classes.datepicker}
                          />
                          <span
                            style={{ alignSelf: "center", margin: "0px 5px" }}
                          >
                            ~
                          </span>
                          <input
                            value={dateValue2 || ""}
                            onChange={(e) => {
                              handleValueChange(dateKey2, e.target.value);
                            }}
                            type="date"
                            className={classes.datepicker}
                          />
                        </Flex>
                      );
                    case "render":
                      return x.render;
                    default:
                      return <Text>{value || "-"}</Text>;
                  }
                })()}
                {x.unit ? <span style={{ marginLeft: 10 }}>{x.unit}</span> : ""}
              </Flex>
            </Grid>
          );
        })}
      </Grid>
      <Flex
        row
        className={goBack || onSave ? classes.buttons : classes.basicButtons}
      >
        {goBack && (
          <Button
            onClick={() => {
              history.goBack();
            }}
            classNameLabel={classes.buttonLabel}
            className={classes.button}
            label="목록"
          />
        )}

        {onSave && (
          <Button
            onClick={onSave}
            classNameLabel={classes.buttonLabel2}
            className={classes.button}
            label="저장"
          />
        )}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    width: "200px",

    display: "flex",
    backgroundColor: "#f5f5f5" || "#f5f5f5",
    padding: "12px 0px",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    fontSize: "16px",
  },
  container: {
    borderBottom: "1px solid rgba(0,0,0,0.23)",
  },
  root: {
    margin: "10px 50px 0px 50px",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // minWidth: "50%",
    borderTop: "1px solid rgba(0,0,0,0.23)",
  },
  value: { flex: 1, padding: "12px 20px", alignItems: "center" },
  input: { alignSelf: "flex-start", backgroundColor: colors.white },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 12px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  inputFull: { flex: 1, alignSelf: "stretch" },
  buttons: {
    marginTop: "30px",
    alignItems: "center",
    alignSelf: "center",
    minWidth: "50%",
  },
  basicButtons: {
    marginTop: "10px",
    minWidth: "50%",
    alignItems: "center",
    alignSelf: "center",
  },
  button: {
    flex: 1,
    "&:first-child": { marginRight: "20px" },
    "&:last-child": { backgroundColor: "black" },
    backgroundColor: "transparent",
    border: "1px solid black",
  },
  buttonLabel: {
    color: "black",
  },
  buttonLabel2: {
    color: "white",
  },
  imageWrap: {
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "5px",
    alignSelf: "flex-start",
    minWidth: "50%",
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    color: "rgba(0,0,0,0.5)",
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    minHeight: "150px",
  },
  imageAddBtn: {
    width: "130px",
    height: "100px",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "5px",
    color: "rgba(0,0,0,0.5)",
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  selectInput: {
    borderRadius: "5px",
    padding: "8px 12px",
    border: "1px solid rgba(0,0,0,0.23)",
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
  },
  inputWrap: {
    margin: "50px",
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    height: "500px !important",
    alignSelf: "stretch",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    width: "auto",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});
