import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { actionError } from "redux/action/ActionActions";
import { closeModal, openModal } from "redux/modal/ModalReducer";
import { openDialog } from "redux/dialog-alert/DialogAlertReducer";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

import routes from "libs/routes";
import * as APIS from "libs/apis";
import { saveAs } from "file-saver";
import { getDefaultPageSize, STATUS_CODE } from "libs/consts";
import { objToQueryStr, formatTime } from "services/utils";
import {
  ListBox,
  processListBoxDragAndDrop,
  ListBoxToolbar,
  processListBoxData,
} from "@progress/kendo-react-listbox";
import DataTable from "components/table/Table";
import Filters from "components/filters/Filters";
import PageLabel from "components/page-label/PageLabel";
import { FcRefresh, FcAdvance } from "react-icons/fc";

const associateShopStatus = [
  { label: "전체", value: "" },
  { label: "활성", value: "ACTIVE" },
  { label: "비활성", value: "DISABLED" },
];

export default function MemberShop({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { state = {} } = location;
  const { associateShops } = useSelector((state) => state.shop);

  const [cities, setCities] = useState([]);
  const [shops, setShops] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchList = () => {
    const paramQuery = objToQueryStr({
      page: state.page,
      size: state.size,
      // city: state?.city,
      shopName: state?.shopName,
      associateShopId: state?.associateShopId,
      shopStatus: state?.shopStatus,
    });
    dispatch(loadingStart);
    if (Object.keys(state).length > 0) {
      APIS.getShops(paramQuery)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setShops(
              data.content.map((item) => {
                const { status } = item;

                return {
                  ...item,
                  bank: `${item.bankName} ${item.bankAccountName}`,
                  status: STATUS_CODE[status] || status,
                };
              })
            );
            setTotalCount(data.totalElements);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((err) => {
          dispatch(actionError(err));
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  useEffect(() => {
    fetchList();
  }, [state.page, state.size]);

  const onClickSuggetMarket = () => {
    dispatch(
      openModal({
        visiable: true,
        children: <FranchiaseList />,
      })
    );
  };

  const onClickExcelDownload = () => {
    const param = { ...state };
    delete param.page;
    delete param.size;

    for (let key in param) {
      const value = param[key];
      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.searchShopExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `대리점관리_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <>
      <PageLabel
      // add={{
      //   title: "대리점 추가",
      //   onClick: () => {
      //     history.push(routes.memberShopAdd);
      //   },
      // }}
      >
        대리점 관리
        <div style={{ position: "absolute", right: "10px", bottom: "10px" }}>
          <TopButton onClick={() => history.push(routes.memberShopAdd)}>
            대리점추가
          </TopButton>
          &nbsp;
          <TopButton
            onClick={() => {
              dispatch(
                openModal({
                  visiable: true,
                  children: <FranchiaseList />,
                })
              );
              document.getElementById("main-layout").style.overflow = "hidden";
            }}>
            노출 순서
          </TopButton>
        </div>
      </PageLabel>
      {/* <div style={{ position: "absolute", right: 0, top: 0 }}>
        <button>대리점 추가</button>
        <button onClick={onClickSuggetMarket}>추천 대리점</button>
      </div> */}
      {/* <div style={{ marginLeft: 50, marginTop: 30 }}>
        <ButtonRadios
          data={[{ label: "전체", value: "" }, ...cities]}
          field="city"
          buttonStyle={{ marginTop: 10 }}
        />
      </div> */}
      <Filters
        onSearch={fetchList}
        defaultState={{ ...getDefaultPageSize() }}
        excel={onClickExcelDownload}
        data={[
          {
            label: "제휴사",
            type: "menu",
            data: associateShops,
            key: "associateShopId",
          },
          {
            label: "대리점명",
            type: "input",
            placeholder: "대리점명",
            key: "shopName",
            autoFocus: true,
          },
          {
            label: "상태",
            type: "radio",
            data: associateShopStatus,
            key: "shopStatus",
          },
        ]}
      />
      <DataTable
        data={shops}
        totalCount={totalCount}
        columns={[
          { label: "지역", key: "city" },
          { label: "대리점명", key: "name" },
          { label: "대리점코드", key: "code" },
          { label: "사업주", key: "managerName" },
          { label: "연락처", key: "managerPhone" },
          { label: "사업자등록번호", key: "businessNumber" },
          { label: "정산계좌", key: "bank" },
          { label: "전화번호", key: "phone" },
          { label: "주소", key: "address" },
          // { label: "추천회원수", key: "recommendedCount", type: "number" },
          { label: "상태", key: "status" },
        ]}
        onExcelDownload={() => {}}
        onClick={({ id }) => {
          history.push(routes.memberShopDetail, { id });
        }}
        rowClassName="pointer"
      />
    </>
  );
}
const FranchiaseList = () => {
  const [state, setState] = useState({
    recommendShop: [],
    topShop: [],
    draggedItem: {},
  });

  const [temp, setTemp] = useState([]);
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();

  const SELECTED_FIELD = "selected";
  useEffect(() => {
    fetchList();
    return () =>
      (document.getElementById("main-layout").style.overflow = "scroll");
  }, []);

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data]?.map((item) => {
        if (item.name === event.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }

        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleDragStart = (e) => {
    setState({ ...state, draggedItem: e.dataItem });
  };

  const handleDrop = (e) => {
    let result = processListBoxDragAndDrop(
      state.recommendShop,
      state.topShop,
      state.draggedItem,
      e.dataItem,
      "name"
    );
    setState({
      ...state,
      recommendShop: result.listBoxOneData.map((item, index) => {
        return { ...item, index };
      }),
      topShop: result.listBoxTwoData.map((item, index) => {
        return { ...item, index };
      }),
    });
  };

  const onClickSave = () => {
    dispatch(
      openDialog({
        action: () => onClickSaveOrder(),
        message: "노출 순서를 저장하시겠습니까?",
      })
    );
  };

  const onClickSaveOrder = () => {
    const { topShop } = state;
    const payload = topShop.map((item, index) => {
      return { id: item.id, sort: index + 1 };
    });
    dispatch(loadingStart);
    APIS.patchRecommendShopOrder(payload)
      .then((res) => {
        const {
          data: { success, data: shopData, message },
        } = res;
        const { recommendShopList, topRecommendShopList } = shopData;
        if (success) {
          setTemp(
            (recommendShopList || []).map((item, index) => {
              return {
                ...item,
                name: item.name,
                selected: false,
                index,
                status: "recommendShop",
              };
            })
          );
          setState({
            ...state,
            recommendShop: (recommendShopList || []).map((item, index) => {
              return {
                ...item,
                name: item.name,
                selected: false,
                index,
                status: "recommendShop",
              };
            }),
            topShop: (topRecommendShopList || []).map((item, index) => {
              return {
                ...item,
                name: item.name,
                selected: false,
                index,
                status: "topShop",
              };
            }),
          });
          dispatch(
            openDialog({
              message: "변경되었습니다.",
            })
          );
        } else {
          dispatch(loadingEnd);
          actionError(message);
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getRecommendShopList()
      .then((res) => {
        const { data } = res;
        const { success, data: shopList, message } = data;
        const { recommendShopList = [], topRecommendShopList = [] } = shopList;
        if (success) {
          setTemp(
            (recommendShopList || []).map((item, index) => {
              return {
                ...item,
                name: item.name,
                selected: false,
                index,
                status: "recommendShop",
              };
            })
          );
          setState({
            ...state,
            recommendShop: (recommendShopList || []).map((item, index) => {
              return {
                ...item,
                name: item.name,
                selected: false,
                index,
                status: "recommendShop",
              };
            }),
            topShop: (topRecommendShopList || []).map((item, index) => {
              return {
                ...item,
                name: item.name,
                selected: false,
                index,
                status: "topShop",
              };
            }),
          });
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      state.recommendShop,
      state.topShop,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      recommendShop: result.listBoxOneData.map((item, index) => {
        return { ...item, index };
      }),
      topShop: result.listBoxTwoData.map((item, index) => {
        return { ...item, index };
      }),
    });
  };

  return (
    <div
      style={{
        width: "700px",
        height: "800px",
      }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
        }}>
        <div
          style={{
            width: "49%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
          <div
            style={{
              height: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Input
              type="search"
              placeholder="대리점명을 입력해주세요."
              value={keyword}
              onChange={(e) => {
                const { value } = e.target;
                setKeyword(value);
                if (keyword.length > 0) {
                  let parseData = [...temp];
                  parseData = parseData.filter((item) =>
                    item.name.includes(value)
                  );
                  setState({
                    ...state,
                    recommendShop: parseData,
                  });
                } else {
                  setState({
                    ...state,
                    recommendShop: temp,
                  });
                }
              }}
            />
          </div>
          <div
            style={{
              height: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <span>가까운 거리순</span>
          </div>
          <div
            id="recommendList"
            style={{
              height: "100%",
              width: "100%",
            }}>
            <ListBox
              style={{
                height: "100%",
                width: "100%",
                overflowY: "scroll",
              }}
              textField="name"
              selectedField={SELECTED_FIELD}
              onItemClick={(e) =>
                handleItemClick(e, "recommendShop", "topShop")
              }
              data={state?.recommendShop?.length > 0 ? state.recommendShop : []}
              onDrop={handleDrop}
              onDragStart={handleDragStart}
              toolbar={() => {
                return (
                  <ListBoxToolbar
                    tools={["transferTo", "transferFrom"]}
                    data={
                      state?.recommendShop?.length > 0
                        ? state.recommendShop
                        : []
                    }
                    dataConnected={
                      state?.topShop?.length > 0 ? state.topShop : []
                    }
                    onToolClick={handleToolBarClick}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "70px",
            height: "100%",
          }}>
          <FcAdvance style={{ fontSize: "50px" }} />
        </div>
        <div
          style={{
            width: "49%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}>
            <Button
              style={{ background: "#000", color: "#fff" }}
              onClick={onClickSave}>
              저장
            </Button>
            &nbsp;&nbsp;
            <Button onClick={() => dispatch(closeModal())}>닫기</Button>
          </div>
          <div
            style={{
              height: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}>
            <span>우선 노출</span>
            <div style={{ position: "absolute", right: 5 }}>
              <RefreshButton onClick={fetchList}>
                <FcRefresh />
              </RefreshButton>
            </div>
          </div>
          <div
            id="topRecommendList"
            style={{
              height: "100%",
              width: "100%",
              overflowY: "scroll",
            }}>
            <ListBox
              style={{
                height: "100%",
                width: "100%",
              }}
              textField="name"
              data={state?.topShop?.length > 0 ? state.topShop : []}
              selectedField={SELECTED_FIELD}
              onItemClick={(e) =>
                handleItemClick(e, "topShop", "recommendShop")
              }
              onDragStart={handleDragStart}
              onDrop={handleDrop}
              item={MyCustomItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MyCustomItem = (props) => {
  let { dataItem, selected, ...others } = props;
  return (
    <li
      {...others}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100% ",
      }}>
      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            margin: "5px",
            borderRadius: "0.3rem",
            textAlign: "center",
            border: "none",
            color: "#fff",
            background: "#ed7632",
            lineHeight: "20px",
            fontWeight: "bold",
          }}>
          {props?.dataItem?.index + 1 || "-"}
        </div>
        <span
          style={{
            fontWeight: "bold",
          }}>
          {props.dataItem.name}
        </span>
      </div>
    </li>
  );
};

const Input = styled.input`
  width: 100%;
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const Button = styled.button`
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 0.3rem;
  &:hover {
    opacity: 0.7;
  }
`;

const RefreshButton = styled.button`
  border: 1px solid #7d7d7d;
  width: 40px;
  height: 30px;
  border-radius: 0.3rem;
  background: #fff;
  &:hover {
    opacity: 0.7;
  }
`;

const TopButton = styled.button`
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 0.3rem;
  background: #ed7632;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  &:hover {
    opacity: 0.7;
  }
`;
