import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import * as APIS from "../../../libs/apis";
import useFocusAndBlur from "hook/useFocusBlur";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FcRefresh } from "react-icons/fc";
import {
  HistoryWrap,
  TitleWrap,
  Title,
  FilterWrap,
  Table,
  DayBtn,
  ButtonWrap,
  SearchBtn,
  InitBtn,
  RefreshBtn,
  ListWrap,
} from "./styled";
import { formatTime, objToQueryStr } from "services/utils";
import GridTable, {
  CenterCell,
  NumberCell,
} from "components/gird-table/GridTable";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import Flex from "components/flex/Flex";
import SmallTitle from "components/title/SmallTitle";

const INIT = {
  fromDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  shopId: [],
};

const assetTypes = [
  { label: "금", value: "GOLD" },
  { label: "은", value: "SILVER" },
];

const gridName = "penaltyHistory";
const gridDetailName = "penaltyDetail";

function MemberPenalty() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleFocus, handleBlur } = useFocusAndBlur();

  const [expanded, setExpanded] = useState(true);
  const [shops, setShops] = useState([]);
  const [range, setRange] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [condition, setCondition] = useState(
    location.state?.condition
      ? location.state?.condition
      : {
          fromDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
          shopId: { label: "전체", value: "ALL" },
        }
  );
  const [penaltyDetail, setPenaltyDetail] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);

  const toggleDialog = (id, appraisalIds) => {
    if (appraisalIds.length === 0) {
      setPenaltyDetail([]);
      return;
    }
    setSelectedRow(id);
    let ids = appraisalIds.split(", ").map((item) => parseInt(item));

    dispatch(loadingStart);
    APIS.getAppraisalPenaltyDetail(
      objToQueryStr({
        appraisalIds: appraisalIds,
      })
    )
      .then(({ data: { data, success, message } }) => {
        if (success) {
          setPenaltyDetail(data);
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onClickDay = (e) => {
    const { name } = e.target;
    switch (name) {
      case "day":
        setCondition({
          ...condition,
          fromDate: moment(new Date()).format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "week":
        setCondition({
          ...condition,
          fromDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "1m":
        setCondition({
          ...condition,
          fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "3m":
        setCondition({
          ...condition,
          fromDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "6m":
        setCondition({
          ...condition,
          fromDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
    }
  };

  const fetchList = () => {
    let params = {
      fromDate: condition.fromDate,
      toDate: condition.toDate,
    };
    if (condition.shopId.value !== "ALL") {
      params["shopId"] = condition.shopId.value;
    }
    dispatch(loadingStart);
    APIS.getAppraisalPenalty(objToQueryStr(params))
      .then(({ data: { data, success, message } }) => {
        setDataList(
          data.map((value, i) => {
            return {
              ...value,
              no: data.length - (data.length - 1 - i),
            };
          })
        );
      })
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    fetchList();
    APIS.getRecommendShops().then(({ data: { success, data } }) => {
      if (success) {
        setShops([
          { value: "ALL", label: "전체" },
          ...data.map(({ shopId, shopName }) => ({
            value: shopId,
            label: shopName,
          })),
        ]);
      }
    });
  }, []);

  const { fromDate, toDate, shopId } = condition;

  return (
    <HistoryWrap>
      <TitleWrap onClick={() => setExpanded(!expanded)}>
        <Title>지체상금</Title>
        <div>
          <span style={{ marginRight: "20px" }}>
            {expanded ? <AiOutlineUp /> : <AiOutlineDown />}
          </span>
        </div>
      </TitleWrap>
      <FilterWrap expanded={expanded}>
        <Table>
          <tbody>
            <tr>
              <th id="shop-th">대리점</th>
              <td>
                <div style={{ margin: "5px" }}>
                  <DropDownList
                    value={shopId}
                    data={shops}
                    dataItemKey="value"
                    textField="label"
                    style={{
                      width: "120px",
                      background: "#fff",
                    }}
                    onFocus={() => handleFocus("shop-th")}
                    onBlur={() => handleBlur("shop-th")}
                    onChange={(e) => {
                      const { value } = e;
                      setCondition({
                        ...condition,
                        shopId: value,
                      });
                    }}
                  />
                </div>
              </td>
              <th id="date-from-th">검색기간</th>
              <td colSpan={5}>
                <div
                  style={{
                    widows: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "flex-start",
                  }}>
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      id="search-from-date"
                      placeholder="날짜를 선택해주세요"
                      format={"yyyy-MM-dd"}
                      defaultValue={new Date(moment().subtract(1, "week"))}
                      value={
                        fromDate !== "Invalid date" ? new Date(fromDate) : ""
                      }
                      max={new Date()}
                      onFocus={() => handleFocus("date-from-th")}
                      onBlur={() => handleBlur("date-from-th")}
                      onChange={(event) => {
                        const { value } = event;
                        setCondition({
                          ...condition,
                          fromDate: moment(value).format("YYYY-MM-DD"),
                        });
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                    />
                  </div>
                  &nbsp; &nbsp;
                  <span>-</span>
                  &nbsp; &nbsp;
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      id="search-to-date"
                      placeholder="날짜를 선택해주세요"
                      format={"yyyy-MM-dd"}
                      defaultValue={new Date()}
                      value={toDate !== "Invalid date" ? new Date(toDate) : ""}
                      max={new Date()}
                      min={new Date(fromDate)}
                      onFocus={() => handleFocus("date-from-th")}
                      onBlur={() => handleBlur("date-from-th")}
                      onChange={(event) => {
                        const { value } = event;
                        setCondition({
                          ...condition,
                          toDate: moment(value).format("YYYY-MM-DD"),
                        });
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "400px",
                      height: "100%",
                      marginLeft: "10px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}>
                    <DayBtn name="day" onClick={onClickDay}>
                      당일
                    </DayBtn>
                    <DayBtn name="week" onClick={onClickDay}>
                      1주일
                    </DayBtn>
                    <DayBtn name="1m" onClick={onClickDay}>
                      1개월
                    </DayBtn>
                    <DayBtn name="3m" onClick={onClickDay}>
                      3개월
                    </DayBtn>
                    <DayBtn name="6m" onClick={onClickDay}>
                      6개월
                    </DayBtn>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td
                colSpan={8}
                style={{
                  borderBottom: 0,
                }}>
                <ButtonWrap>
                  <SearchBtn
                    onClick={() => {
                      fetchList();
                    }}>
                    검색
                  </SearchBtn>
                  <InitBtn
                    onClick={() => {
                      setCondition({ ...INIT });
                    }}>
                    초기화
                  </InitBtn>
                  {/* <RefreshBtn
                    onClick={() => {
                      // onClickRefresh()
                    }}>
                    <FcRefresh style={{ fontSize: "20px" }} />
                  </RefreshBtn> */}
                  {/* <ExcelBtn onClick={onClickDownload}>Excel</ExcelBtn> */}
                </ButtonWrap>
              </td>
            </tr>
          </tbody>
        </Table>
      </FilterWrap>
      <ListWrap expanded={expanded}>
        <Flex
          className={gridName}
          style={{
            flex: 0.8,
            backgroundColor: "white",
          }}>
          <SmallTitle style={{ padding: "5px" }}>지체상금 목록</SmallTitle>
          <GridTable
            name={gridName}
            data={dataList}
            scrollable={true}
            style={{
              height: "100%",
            }}
            onRowClick={(e) => {
              toggleDialog(e.dataItem.no, e.dataItem.appraisalIds);
            }}
            rowRender={(tr, props) => {
              const trProps = {
                style:
                  props.dataItem.no === selectedRow
                    ? { backgroundColor: "rgba(0,0,0,0.4)" }
                    : {},
              };
              return React.cloneElement(tr, { ...trProps }, tr.props.children);
            }}>
            <Column title="No" width={50} field="no" cell={CenterCell} />
            <Column
              title="일자"
              // width={150}
              field="completedDate"
              cell={CenterCell}
            />
            <Column
              title="대리점"
              // width={150}
              field="shopName"
              cell={CenterCell}
            />
            <Column
              title="지체상금"
              width={150}
              field="penaltyKrw"
              cell={NumberCell}
            />
            <Column
              title="지체 감정건"
              width={100}
              field="penaltyCount"
              cell={CenterCell}
            />
          </GridTable>
        </Flex>
        <Flex style={{ flex: 1.2, backgroundColor: "white", paddingLeft: 7 }}>
          <SmallTitle style={{ padding: "5px" }}>지체상금 내역</SmallTitle>
          <GridTable
            name={gridDetailName}
            data={penaltyDetail}
            scrollable={true}
            style={{
              height: "100%",
              paddingBottom: 10,
            }}>
            <Column title="접수번호" field="number" cell={CenterCell} />
            <Column
              title="자산"
              field="assetType"
              width={50}
              cell={(props) => {
                const dataItem = props.dataItem;
                return (
                  <CenterCell {...props} className="td-c">
                    {dataItem.assetType
                      ? assetTypes.find(
                          (type) => type.value === dataItem.assetType
                        ).label
                      : "-"}
                  </CenterCell>
                );
              }}
            />
            <Column
              title="중량(g)"
              field="appraisalWeightGram"
              width={100}
              cell={(props) => {
                return <NumberCell {...props} className="td-c" unit=" g" />;
              }}
            />
            <Column
              title="교환일"
              field="completedAt"
              width={150}
              cell={(props) => {
                const dataItem = props.dataItem;
                return (
                  <CenterCell {...props} className="td-c">
                    {formatTime(dataItem.completedAt, "YYYY-MM-DD")}
                  </CenterCell>
                );
              }}
            />
            <Column
              title="입고 상태"
              field="stockStatus"
              width={100}
              cell={CenterCell}
            />
            <Column
              title="입고 마감일"
              field="deadlineStockDt"
              width={150}
              cell={CenterCell}
            />
          </GridTable>
        </Flex>
      </ListWrap>
    </HistoryWrap>
  );
}

export default MemberPenalty;
