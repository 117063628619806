import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};

  // material-ui makeStyles의 className보다 우선순위가 높아
  // makeStyles를 사용하는 Component의 경우 css를 덮어쓰기 때문에 default값을 normal이 아닌 비워둠
  align-items: ${({ center, alignCenter }) =>
    center || alignCenter ? "center" : ""};
  justify-content: ${({ center, justifyCenter }) =>
    center || justifyCenter ? "center" : ""};
`;

export default Flex;
