import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Popup } from "@progress/kendo-react-popup";
import Button from "components/button/Button";
import KendoButton from "components/button/KendoButton";
import GridTable, { KendoCell } from "components/gird-table/GridTable";
import { useEffect, useRef, useState } from "react";
import base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";
import { postAppraisalDisposalConfirm } from "libs/apis";
import { useDispatch } from "react-redux";
import { actionError } from "redux/action/ActionActions";
import { useHistory } from "react-router";
import routes from "libs/routes";

const DisposePopup = ({ gridCellProps, refetch, statusRefetch }) => {
  const { dataItem } = gridCellProps;

  const [popupState, setPopupState] = useState({
    show: false,
    offset: { top: 0, right: 0 },
  });
  const [password, setPassword] = useState("");
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.innerText !== "승인"
    ) {
      setPopupState((prevState) => ({ ...prevState, show: false }));
      setPassword("");
    }
  };
  const handlePopupShow = ({ target, item }) => {
    const rect = target.getBoundingClientRect();
    const top = window.scrollY + rect.bottom + 10;
    const left = window.scrollX + rect.left;
    setPopupState((prev) => ({
      show: !prev.show,
      offset: { top, left },
      item,
    }));
  };

  const handleClick = (type) => () => {
    const parsePassword = base64.stringify(sha256(`${password}`));
    const payload = {
      managerPassword: parsePassword,
      id: dataItem.id,
      inventoryDisposeStatus: type,
    };
    postAppraisalDisposalConfirm(payload).then((res) => {
      const { message, success } = res.data;
      if (success) {
        setPopupState((prevState) => ({ ...prevState, show: false }));
        setPassword("");
        refetch();
        statusRefetch();
      } else {
        dispatch(actionError(message));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setPassword]);
  return (
    <KendoCell {...gridCellProps}>
      <KendoButton
        label="승인"
        onClick={({ target }) => {
          handlePopupShow({ target, item: dataItem });
        }}
      />
      <Popup
        show={popupState.show}
        offset={popupState.offset}
        style={{ width: 300, zIndex: "auto" }}
        onOpen={() => {
          popupRef.current.focus();
          console.log("focused");
        }}
      >
        <div
          style={{
            padding: 20,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 25,
          }}
          ref={popupRef} // onBlur 작동을 위해서 기재
          tabIndex={0}
          onBlur={handleClickOutside}
        >
          <section>
            <div style={{ fontSize: 15, fontWeight: "bold" }}>
              자산: {dataItem.assetTypeKor} ({dataItem.assetType})
            </div>
            <div style={{ fontSize: 15, fontWeight: "bold" }}>
              중량: {dataItem.weightGram}g
            </div>
          </section>
          <section>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label style={{ fontWeight: 600 }} htmlFor="password">
                관리자 비밀번호
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </section>
          <section style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Button
                label="반려"
                style={{ color: "white" }}
                onClick={handleClick("REJECT")}
              />
              <Button label="승인" onClick={handleClick("CONFIRM")} />
            </div>
          </section>
        </div>
      </Popup>
    </KendoCell>
  );
};

const CommandCell = (refetch, statusRefetch) => (props) => {
  const { dataItem, field } = props;
  const renderText = dataItem[field];

  if (renderText === "승인요청") {
    return (
      <DisposePopup
        gridCellProps={props}
        refetch={refetch}
        statusRefetch={statusRefetch}
      />
    );
  }
  // 띄어쓰기를 통해서 화면 공백 노출
  return <KendoCell {...props}> </KendoCell>;
};

const UserManagerCell = (props) => {
  const { dataItem } = props;

  const { userNameWithId, managerNameWithId, disposeType } = dataItem;
  const renderText =
    disposeType === "자산처분" ? managerNameWithId : userNameWithId;
  return <KendoCell {...props}>{renderText}</KendoCell>;
};

const LinkToAppraisalDetailCell = (props) => {
  const { dataItem, field, id, className, ...rest } = props;
  const renderText = dataItem[field];

  const history = useHistory();

  if (!renderText || renderText === "") {
    return (
      <KendoCell {...props} className={`k-table-td ${className}`}></KendoCell>
    );
  }

  return (
    <KendoCell {...props} className={`k-table-td ${className}`}>
      <span
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() =>
          history.push(routes.appraisalStatusDetail, {
            id: dataItem.appraisalRequestId,
          })
        }
      >
        {renderText}
      </span>
    </KendoCell>
  );
};

const InventoryApprisalGrid = ({
  datas,
  refetch,
  hanldePageChange,
  statusRefetch,
}) => {
  return (
    <GridTable
      reorderable
      pageable
      scrollable
      data={datas ? datas.gridData : []}
      {...datas?.pageInfo}
      style={{ height: 500 }}
      onPageChange={hanldePageChange}
    >
      <Column field="no" title="No." className="td-c" />
      <Column field="disposeType" title="구분" className="td-c" />
      <Column field="assetTypeKor" title="자산" className="td-c" />
      <Column field="disposeStatus" title="상태" className="td-c" />
      <Column
        field="appraisalRequestNo"
        title="접수번호"
        className="td-c"
        cell={LinkToAppraisalDetailCell}
      />
      <Column
        field="weightGram"
        title="중량(g)"
        className="td-c"
        format={`{0:##,#.####}`}
      />
      <Column
        field="userNameWithId"
        title="고객 / 관리자"
        className="td-c"
        cell={UserManagerCell}
      />
      <Column field="stockedAt" title="입고일시" className="td-c" />
      <Column field="disposedAt" title="처분일시" className="td-c" />
      <Column
        field="pricePerGram"
        title="처분단가"
        className="td-c"
        format={`{0:##,#.####}`}
      />
      <Column
        field="price"
        title="금액"
        format={`{0:##,#.####}`}
        className="td-c"
      />
      <Column
        field="disposeStatus"
        title=" "
        className="td-c"
        cell={CommandCell(refetch, statusRefetch)}
      />
    </GridTable>
  );
};

export default InventoryApprisalGrid;
