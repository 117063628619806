import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import PageLabel from "../../components/page-label/PageLabel";
import fonts from "../../libs/fonts";
import { useDispatch } from "react-redux";
import { actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import { PanoramaOutlined } from "@material-ui/icons";
import consts from "../../libs/consts";
import moment from "moment";
import styled from "styled-components";
import {
  ButtonBase,
  makeStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
} from "@material-ui/core";
import { Switch } from "@progress/kendo-react-inputs";

const SwitchForm = styled.div`
  text-align: center;
  .k-switch-lg {
    width: 100px;
    font-size: 16px;
  }
  .k-switch-thumb {
    transform: translate(-48%, -50%);
  }
`;

const assetTypes = [
  {
    type: "DEFAULT",
    label: "없음",
  },
  {
    type: "GOLD",
    label: "금",
  },
  {
    type: "SILVER",
    label: "은",
  },
  {
    type: "KRW",
    label: "원화",
  },
  {
    type: "POINT",
    label: "포인트",
  },
];

export default function ServiceNoticeDetail({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation({});
  const { state = {} } = location;
  const [noticeItemData, setNoticeItemData] = useState({});
  const [noticeImage, setNoticeImage] = useState("");
  const [eventThumbnail, setEventThumbnail] = useState("");
  const [banner, setBanner] = useState(""); //배너
  const [bannerStatus, setBannerStatus] = useState("");
  const [mallBanner, setMallBanner] = useState("");
  const [mallBannerStatus, setMallBannerStatus] = useState("");
  const [popup, setPopup] = useState("");
  const [popupStatus, setPopupStatus] = useState("");
  const [editCheck, setEditCheck] = useState(false);

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const {
    category = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("category", noticeItemData?.category)
      : "",
    startDate = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("startDate", noticeItemData?.startDate)
      : "",
    endDate = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("endDate", noticeItemData?.endDate)
      : "",
    subject = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("subject", noticeItemData?.subject)
      : "",
    content = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("content", noticeItemData?.content)
      : "",
    createBy = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("createBy", noticeItemData?.createBy)
      : "",
    assetType = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("assetType", noticeItemData?.assetType)
      : assetTypes[0].type,
    amount = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("amount", noticeItemData?.amount)
      : "",
    expiredDay = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("expiredDay", noticeItemData?.expiredDay)
      : "",
  } = state;

  const [status, setStatus] = useState({
    category: category ? category : "NOTICE",
    noticeType: "ALL",
    popupPeriod: 1,
    status: "ACTIVE",
    startDate: startDate,
    endDate: endDate,
    image: noticeImage,
    subject: subject,
    content: content,
    createBy: createBy,
    assetType: assetType,
    amount: amount,
    expiredDay: expiredDay,
  });
  const isNotice =
    Object.keys(noticeItemData).length === 0
      ? status.category === "NOTICE"
      : noticeItemData.category === "NOTICE";
  const valueCategory =
    Object.keys(noticeItemData).length === 0
      ? status.category
      : noticeItemData.category;

  const valueAssetType =
    Object.keys(noticeItemData).length === 0
      ? status.assetType
      : noticeItemData.assetType === null
      ? "DEFAULT"
      : noticeItemData.assetType;
  const valueAmount =
    Object.keys(noticeItemData).length === 0
      ? status.amount
      : noticeItemData.amount;
  const valueExpireDate =
    Object.keys(noticeItemData).length === 0
      ? status.expiredDay
      : noticeItemData.expiredDay;

  const valueNoticeType =
    Object.keys(noticeItemData).length === 0
      ? status.noticeType
      : noticeItemData.noticeType;

  const valuePopupPeriod =
    Object.keys(noticeItemData).length === 0
      ? status.popupPeriod
      : noticeItemData.popupPeriod;

  const valueSubject =
    Object.keys(noticeItemData).length === 0
      ? status.subject
      : noticeItemData.subject;

  const valueContent =
    Object.keys(noticeItemData).length === 0
      ? status.content
      : noticeItemData.content;
  const valueStartDate =
    Object.keys(noticeItemData).length === 0
      ? status.startDate
        ? moment(status.startDate).format("YYYY-MM-DD")
        : ""
      : noticeItemData.startDate
      ? moment(noticeItemData.startDate).format("YYYY-MM-DD")
      : "";
  const valueEndDate =
    Object.keys(noticeItemData).length === 0
      ? status.endDate
        ? moment(status.endDate).format("YYYY-MM-DD")
        : ""
      : noticeItemData.endDate
      ? moment(noticeItemData.endDate).format("YYYY-MM-DD")
      : "";

  const setState =
    Object.keys(noticeItemData).length === 0 ? setStatus : setNoticeItemData;

  const checkRatio = (name, targetWidth, targetHeight) => {
    const bannerMaxBaseRatio = "3.9";
    const bannerMinBaseRatio = "2.0";
    const thumbnailBaseRatio = "3.2";
    const mallBannerBaseRatio = "3.9";
    const popupBaseRatio = "1.4";
    if (name === "banner") {
      return (
        bannerMinBaseRatio <= (targetWidth / targetHeight).toFixed(1) &&
        (targetWidth / targetHeight).toFixed(1) <= bannerMaxBaseRatio
      );
    } else if (name === "eventthumbnail") {
      return (targetWidth / targetHeight).toFixed(1) === thumbnailBaseRatio;
    } else if (name === "mallBanner") {
      return (targetWidth / targetHeight).toFixed(1) === mallBannerBaseRatio;
    } else if (name === "popup") {
      return (targetWidth / targetHeight).toFixed(1) === popupBaseRatio;
    }
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const onChangeImage = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const _URL = window.URL || window.webkitURL;
    const i = new Image();
    i.src = _URL.createObjectURL(file);
    i.onload = () => {
      if (i.width !== 0 && i.height !== 0) {
        if (file.size >= 1024 * 1024 * 5) {
          alert("파일 용량이 초과하였습니다.(5MB)");
          return false;
        }
        if (name === "banner" && !checkRatio(name, i.width, i.height)) {
          alert("배너 이미지는 39 : 10 비율의 이미지만 가능합니다.");
          return false;
        } else if (
          name === "eventthumbnail" &&
          !checkRatio(name, i.width, i.height)
        ) {
          alert("썸네일 이미지는 16 : 5 비율의 이미지만 가능합니다.");
          return false;
        } else if (
          name === "mallBanner" &&
          !checkRatio(name, i.width, i.height)
        ) {
          alert("Mall 배너 이미지는 328 : 84 비율의 이미지만 가능합니다.");
          return false;
        } else if (name === "popup" && !checkRatio(name, i.width, i.height)) {
          alert("팝업 이미지는 360 : 256 비율의 이미지만 가능합니다.");
          return false;
        } else {
          if (file) {
            const f = new FileReader();
            f.onload = () => {
              file.uri = f.result;
              if (name === "banner") {
                setBanner(file);
              } else if (name === "eventthumbnail") {
                setEventThumbnail(file);
              } else if (name === "noticeImage") {
                setNoticeImage(file);
              } else if (name === "mallBanner") {
                setMallBanner(file);
              } else if (name === "popup") {
                setPopup(file);
              }
              setStatus({ ...status, image: file });
            };
            f.readAsDataURL(file);
            e.target.value = "";
            setEditCheck(false);
          }
        }
      }
    };
  };

  const validationData = () => {
    if (!valueStartDate) {
      return "기간 - 시작 날짜를 선택하여 주세요";
    } else if (!valueSubject) {
      return "제목을 입력하여 주세요";
    } else if (!valueContent) {
      return "내용을 입력하여 주세요";
    } else if ("EVENT" === valueCategory) {
      if (
        !(
          valueContent.startsWith("http://") ||
          valueContent.startsWith("https://")
        )
      ) {
        return "url형식에 맞게 입력하여 주세요";
      }
    }
    return "";
  };
  const handleClickRegister = () => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    let body = {
      subject: valueSubject,
      content: valueContent,
      category: valueCategory,
      startDate: valueStartDate,
      endDate: valueEndDate,
      bannerStatus: bannerStatus === true ? "ACTIVE" : "BLIND",
      mallBannerStatus: mallBannerStatus === true ? "ACTIVE" : "BLIND",
      popupStatus: popupStatus === true ? "ACTIVE" : "BLIND",
      noticeType: valueNoticeType,
      popupPeriod: valuePopupPeriod,
      status: "ACTIVE",
    };

    if (valueCategory === "EVENT") {
      body["assetType"] = valueAssetType === "DEFAULT" ? null : valueAssetType;
      body["amount"] =
        valueAmount === "" ||
        valueAssetType === "DEFAULT" ||
        parseInt(valueAmount) === 0
          ? null
          : valueAmount;
      body["expiredDay"] = valueAssetType !== "POINT" ? null : valueExpireDate;
    }

    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("notice", blob);
    formData.append(
      "detail",
      Object.keys(noticeImage).length !== 0 ? noticeImage : null
    );
    formData.append(
      "thumbnail",
      Object.keys(eventThumbnail).length !== 0 ? eventThumbnail : null
    );

    if (Object.keys(banner).length !== 0) {
      formData.append("banner", banner);
    }

    if (Object.keys(mallBanner).length !== 0) {
      formData.append("mallBanner", mallBanner);
    }

    if (Object.keys(popup).length !== 0) {
      formData.append("popup", popup);
    }

    dispatch(loadingStart);
    APIS.postCreateNotice(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "공지 및 이벤트 등록에 실패하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "공지 및 이벤트 추가가 완료되었습니다.",
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handleClickEdit = () => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    let body = {
      subject: noticeItemData?.subject ? noticeItemData.subject : "",
      content: noticeItemData?.content ? noticeItemData.content : "",
      category: noticeItemData?.category ? noticeItemData.category : "",
      startDate: valueStartDate,
      endDate: valueEndDate,
      bannerStatus: bannerStatus === true ? "ACTIVE" : "BLIND",
      mallBannerStatus: mallBannerStatus === true ? "ACTIVE" : "BLIND",
      popupStatus: popupStatus === true ? "ACTIVE" : "BLIND",
      popupPeriod: valuePopupPeriod,
      noticeType: valueNoticeType,
    };

    if (valueCategory === "EVENT") {
      body["assetType"] = valueAssetType === "DEFAULT" ? null : valueAssetType;
      body["amount"] =
        valueAmount === "" ||
        valueAssetType === "DEFAULT" ||
        parseInt(valueAmount) === 0
          ? null
          : valueAmount;
      body["expiredDay"] = valueAssetType !== "POINT" ? null : valueExpireDate;
    }

    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const formData = new FormData();
    formData.append("notice", blob);
    formData.append("detail", noticeImage ? noticeImage : null);
    formData.append("thumbnail", eventThumbnail ? eventThumbnail : null);
    if (Object.keys(banner).length !== 0) {
      formData.append("banner", banner);
    }
    if (Object.keys(mallBanner).length !== 0) {
      formData.append("mallBanner", mallBanner);
    }
    if (Object.keys(popup).length !== 0) {
      formData.append("popup", popup);
    }

    dispatch(loadingStart);
    APIS.postEditNotice(formData, state.id)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "공지 및 이벤트 수정에 실패하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "공지 및 이벤트 수정이 완료되었습니다.",
              () => {
                setNoticeItemData(data);
                setBannerStatus(data.bannerStatus === "ACTIVE" ? true : false);
                setMallBannerStatus(
                  data.mallBannerStatus === "ACTIVE" ? true : false
                );
                setPopupStatus(data.popupStatus === "ACTIVE" ? true : false);
                setEditCheck(true);
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  useEffect(() => {
    if (!isNaN(state.id)) {
      fetchItem();
    }
  }, [state.id]);

  const fetchItem = () => {
    dispatch(loadingStart);
    APIS.getNoticeDetail(state.id)
      .then(({ data: { success, data } }) => {
        if (success) {
          data.serviceImages.map((item) => {
            if (item.imageType === "THUMBNAIL") {
              setEventThumbnail(item);
            } else if (item.imageType === "BANNER") {
              setBanner(item);
            } else if (item.imageType === "IMAGE") {
              setNoticeImage(item);
            } else if (item.imageType === "MALL_BANNER") {
              setMallBanner(item);
            } else if (item.imageType === "POPUP") {
              setPopup(item);
            }
          });
          setNoticeItemData(data); //아이템 디테일 정보 넣어주는 곳.
          setBannerStatus(data.bannerStatus === "ACTIVE" ? true : false);
          setMallBannerStatus(
            data.mallBannerStatus === "ACTIVE" ? true : false
          );
          setPopupStatus(data.popupStatus === "ACTIVE" ? true : false);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  return (
    <>
      <PageLabel>공지사항 (등록/수정)</PageLabel>
      {/* 4개의 페이지 - 등록 노티스, 수정 노티스, 등록 이벤트, 수정 이벤트 */}
      {isNotice ? (
        <GridRow
          data={[
            {
              label: "구분",
              value: valueCategory,
              fullWidth: true,
              key: "category",
              type: "radio",
              data: [
                { label: "공지사항", value: "NOTICE" },
                { label: "이벤트", value: "EVENT" },
              ],
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  category: value,
                }));
              },
            },
            {
              label: "사용처",
              value: valueNoticeType,
              fullWidth: true,
              key: "noticeType",
              type: "radio",
              data: [
                { label: "전체", value: "ALL" },
                { label: "금방금방 앱", value: "APP" },
                { label: "쇼핑몰", value: "MALL" },
              ],
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  noticeType: value,
                }));
              },
            },
            {
              label: "기간",
              type: "render",
              fullWidth: true,
              render: (
                <Flex row>
                  <input
                    value={valueStartDate}
                    onChange={(e) => {
                      setState((status) => ({
                        ...status,
                        startDate: e.target.value,
                      }));
                    }}
                    type="date"
                    className={classes.datepicker}
                  />
                  <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                    ~
                  </span>
                  <input
                    value={valueEndDate}
                    onChange={(e) => {
                      setState((status) => ({
                        ...status,
                        endDate: e.target.value,
                      }));
                    }}
                    type="date"
                    className={classes.datepicker}
                  />
                </Flex>
              ),
            },
            {
              label: "이미지",
              type: "render",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {noticeImage ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-thumbnail-file"
                              name="noticeImage"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-thumbnail-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  noticeImage.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      noticeImage.path
                                    : noticeImage.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {/* 삭제  */}
                          {!noticeImage.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setNoticeImage("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="noticeImage"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "배너",
              type: "render",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {banner && banner.tradeType !== "EVENT" ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-banner-file"
                              name="banner"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-banner-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  banner.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      banner.path
                                    : banner.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {/* 삭제  */}
                          {!banner.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setBanner("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <SwitchForm>
                            <Switch
                              checked={bannerStatus}
                              onChange={(e) => {
                                setBannerStatus(e.target.value);
                              }}
                              onLabel={"활성"}
                              offLabel={"비활성"}
                              size={"large"}
                              trackRounded={"small"}
                              thumbRounded={"small"}
                            />
                          </SwitchForm>
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="banner"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "Mall 배너",
              key: "mallBanner",
              fullWidth: true,
              type: "render",
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {mallBanner && mallBanner.tradeType !== "EVENT" ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-mall-banner-file"
                              name="mallBanner"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-mall-banner-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  mallBanner.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      mallBanner.path
                                    : mallBanner.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {/* 삭제  */}
                          {!mallBanner.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setMallBanner("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <SwitchForm>
                            <Switch
                              checked={mallBannerStatus}
                              onChange={(e) => {
                                setMallBannerStatus(e.target.value);
                              }}
                              onLabel={"활성"}
                              offLabel={"비활성"}
                              size={"large"}
                              trackRounded={"small"}
                              thumbRounded={"small"}
                            />
                          </SwitchForm>
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="mallBanner"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "팝업",
              key: "popup",
              fullWidth: true,
              type: "render",
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {popup && popup.tradeType !== "EVENT" ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-popup-file"
                              name="popup"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-popup-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  popup.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      popup.path
                                    : popup.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {/* 삭제  */}
                          {!popup.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setPopup("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <SwitchForm>
                            <Switch
                              checked={popupStatus}
                              onChange={(e) => {
                                setPopupStatus(e.target.value);
                              }}
                              onLabel={"활성"}
                              offLabel={"비활성"}
                              size={"large"}
                              trackRounded={"small"}
                              thumbRounded={"small"}
                            />
                          </SwitchForm>
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="popup"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "팝업 미사용 기간",
              value: valuePopupPeriod,
              fullWidth: true,
              key: "popupPeriod",
              type: "radio",
              data: [
                { label: "1일", value: 1 },
                { label: "3일", value: 3 },
                { label: "7일", value: 7 },
                { label: "다시 보지 않기", value: 0 },
              ],
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  popupPeriod: Number(value),
                }));
              },
            },
            {
              label: "제목",
              type: "input",
              key: "subject",
              value: valueSubject,
              fullWidth: true,
              inputFullWidth: true,
              placeholder: "제목을 입력하세요.",
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  subject: value,
                }));
              },
            },
            {
              label: "내용",
              type: "render",
              key: "content",
              fullWidth: true,
              render: (
                <div className={classes.inputWrap}>
                  <textarea
                    name="content"
                    rows="50"
                    value={valueContent}
                    onChange={(e) => {
                      setState((status) => ({
                        ...status,
                        content: e.target.value,
                      }));
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      ) : (
        <GridRow
          data={[
            {
              label: "구분",
              value: valueCategory,
              fullWidth: true,
              key: "category",
              type: "radio",
              data: [
                { label: "공지사항", value: "NOTICE" },
                { label: "이벤트", value: "EVENT" },
              ],
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  category: value,
                }));
              },
            },
            {
              label: "사용처",
              value: valueNoticeType,
              fullWidth: true,
              key: "noticeType",
              type: "radio",
              data: [
                { label: "전체", value: "ALL" },
                { label: "금방금방 앱", value: "APP" },
                { label: "쇼핑몰", value: "MALL" },
              ],
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  noticeType: value,
                }));
              },
            },
            {
              label: "기간",
              type: "render",
              fullWidth: true,
              render: (
                <Flex row>
                  <input
                    value={valueStartDate}
                    onChange={(e) => {
                      setState((status) => ({
                        ...status,
                        startDate: e.target.value,
                      }));
                    }}
                    type="date"
                    className={classes.datepicker}
                  />
                  <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                    ~
                  </span>
                  <input
                    value={valueEndDate}
                    onChange={(e) => {
                      setState((status) => ({
                        ...status,
                        endDate: e.target.value,
                      }));
                    }}
                    type="date"
                    className={classes.datepicker}
                  />
                </Flex>
              ),
            },
            {
              label: "지급액",
              type: "render",
              key: "assetType",
              fullWidth: true,
              render: (
                <Grid item className={classes.df} xs={8}>
                  <Grid
                    item
                    xs={2}
                    className={[classes.dfsc, classes.infocell].join(" ")}
                  >
                    <TextField
                      select
                      fullWidth
                      size="small"
                      variant="outlined"
                      defaultValue={""}
                      value={valueAssetType}
                      onChange={(e) => {
                        let type = e.target.value;
                        setState((status) => ({
                          ...status,
                          assetType: type,
                          amount: type === "DEFAULT" ? "" : status.amount,
                        }));
                      }}
                      style={{ marginRight: "20px" }}
                    >
                      {assetTypes.map((item, idx) => {
                        return (
                          <option
                            key={idx}
                            value={item.type}
                            className={classes.selectForm}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={[classes.dfsc, classes.infocell].join(" ")}
                  >
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      value={valueAmount}
                      disabled={valueAssetType === "DEFAULT"}
                      onChange={(e) => {
                        setState((status) => ({
                          ...status,
                          amount: e.target.value,
                        }));
                      }}
                    />
                  </Grid>
                  {/* <Divider className={classes.divider} /> */}
                  {valueAssetType === "POINT" ? (
                    <Grid item xs={12} className={classes.df}>
                      <Grid
                        item
                        xs={2}
                        className={[classes.dfsc, classes.infocell].join(" ")}
                      >
                        <Typography variant="subtitle2">유효기간</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className={[classes.dfsc, classes.infocell].join(" ")}
                      >
                        <TextField
                          type="number"
                          size="small"
                          variant="outlined"
                          value={valueExpireDate}
                          disabled={valueAssetType === "DEFAULT"}
                          onChange={(e) => {
                            setState((status) => ({
                              ...status,
                              expiredDay: e.target.value,
                            }));
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          style={{ marginLeft: "10px", color: "#000" }}
                        >
                          일
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              ),
            },
            {
              label: "썸네일",
              type: "render",
              key: "noticeImage",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {eventThumbnail ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-thumbnail-file"
                              name="eventthumbnail"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-thumbnail-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  eventThumbnail.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      eventThumbnail.path
                                    : eventThumbnail.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {/* 삭제  */}
                          {!eventThumbnail.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setEventThumbnail("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="eventthumbnail"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "배너",
              type: "render",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {banner && banner.tradeType !== "NOTICE" ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-banner-file"
                              name="banner"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-banner-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  banner.path
                                    ? consts.s3BaseUrl + banner.path
                                    : banner.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {!banner.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setBanner("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <SwitchForm>
                            <Switch
                              checked={bannerStatus}
                              onChange={(e) => {
                                setBannerStatus(e.target.value);
                              }}
                              onLabel={"활성"}
                              offLabel={"비활성"}
                              size={"large"}
                              trackRounded={"small"}
                              thumbRounded={"small"}
                            />
                          </SwitchForm>
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="banner"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "Mall 배너",
              key: "mallBanner",
              fullWidth: true,
              type: "render",
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {mallBanner && mallBanner.tradeType !== "NOTICE" ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-mall-banner-file"
                              name="mallBanner"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-mall-banner-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  mallBanner.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      mallBanner.path
                                    : mallBanner.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {!mallBanner.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setMallBanner("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <SwitchForm>
                            <Switch
                              checked={mallBannerStatus}
                              onChange={(e) => {
                                setMallBannerStatus(e.target.value);
                              }}
                              onLabel={"활성"}
                              offLabel={"비활성"}
                              size={"large"}
                              trackRounded={"small"}
                              thumbRounded={"small"}
                            />
                          </SwitchForm>
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="mallBanner"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "팝업",
              key: "popup",
              fullWidth: true,
              type: "render",
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {popup && popup.tradeType !== "NOTICE" ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px" }}
                          >
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-popup-file"
                              name="popup"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-popup-file">
                              <img
                                style={{
                                  width: "150px",
                                  cursor: "pointer",
                                }}
                                src={
                                  popup.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      popup.path
                                    : popup.uri
                                }
                              ></img>
                            </label>
                          </div>
                          {!popup.tradeType && editCheck !== true && (
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  setPopup("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <SwitchForm>
                            <Switch
                              checked={popupStatus}
                              onChange={(e) => {
                                setPopupStatus(e.target.value);
                              }}
                              onLabel={"활성"}
                              offLabel={"비활성"}
                              size={"large"}
                              trackRounded={"small"}
                              thumbRounded={"small"}
                            />
                          </SwitchForm>
                        </div>
                      ) : (
                        <ImageRegisterForm
                          name="popup"
                          onChangeImage={onChangeImage}
                          onInputClick={onInputClick}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "팝업 미사용 기간",
              value: valuePopupPeriod,
              fullWidth: true,
              key: "popupPeriod",
              type: "radio",
              data: [
                { label: "1일", value: 1 },
                { label: "3일", value: 3 },
                { label: "7일", value: 7 },
                { label: "다시 보지 않기", value: 0 },
              ],
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  popupPeriod: Number(value),
                }));
              },
            },
            {
              label: "제목",
              type: "input",
              key: "subject",
              value: valueSubject,
              fullWidth: true,
              inputFullWidth: true,
              placeholder: "제목을 입력하세요.",
              onChange: (value) => {
                setState((status) => ({
                  ...status,
                  subject: value,
                }));
              },
            },
            {
              label: "내용",
              type: "render",
              key: "content",
              fullWidth: true,
              render: (
                <div className={classes.inputWrap}>
                  <textarea
                    name="content"
                    rows="50"
                    value={valueContent}
                    onChange={(e) => {
                      setState((status) => ({
                        ...status,
                        content: e.target.value,
                      }));
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      )}
      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.button1}>
          목록
        </ButtonBase>

        {Object.keys(noticeItemData).length !== 0 ? (
          <ButtonBase onClick={handleClickEdit} className={classes.button2}>
            수정
          </ButtonBase>
        ) : (
          <ButtonBase onClick={handleClickRegister} className={classes.button2}>
            저장
          </ButtonBase>
        )}
      </Flex>
    </>
  );
}

const ImageRegisterForm = ({ name, onChangeImage, onInputClick }) => {
  const classes = useStyle();
  return (
    <>
      <input
        accept="image/*"
        type="file"
        id={`rasied-${name}-file`}
        name={name}
        style={{ display: "none" }}
        onChange={onChangeImage}
        onClick={onInputClick}
      />
      <label htmlFor={`rasied-${name}-file`}>
        <Button className={classes.imageAddBtn} component="span">
          <PanoramaOutlined />
          이미지 등록
        </Button>
      </label>
    </>
  );
};

const useStyle = makeStyles({
  buttons: { alignSelf: "center" },
  button1: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
    fontSize: 15,
    fontFamily: "NotoSansKR-Bold",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
    fontSize: 15,
    fontFamily: "NotoSansKR-Bold",
  },
  inputWrap: {
    display: "flex",
    flex: 1,
    marginBottom: 10,
    flexDirection: "column",
    height: "250px !important",
    alignSelf: "stretch",
  },
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  divider: {
    width: "100%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imageAddBtn: {
    width: "130px",
    height: "80px",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "5px",
    color: "rgba(0,0,0,0.5)",
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
});
