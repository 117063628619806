import { makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Input from "../../components/input/Input";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
//import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
//import { userSignIn, userUpdate } from "../../redux/user/UserActions";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { signIn } from "../../redux/auth/AuthReducer";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import TextInput from "../../components/input/TextInput";

export default function TwoFactor({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationState = history.location.state || {};
  const [loginInfo, setLoginInfo] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (locationState.account && locationState.password) {
      setLoginInfo({ ...locationState });
      history.replace(location.pathname);
    } else {
      history.goBack();
    }
  }, []);

  const handleClickLogin = () => {
    if (
      verificationCode.length === 6 ||
      process.env.REACT_APP_SERVICE_TYPE === "DEVELOPMENT"
    ) {
      dispatch(loadingStart);
      APIS.postLoginOtp({ ...loginInfo, verificationCode })
        .then(({ data: { success, message, data } }) => {
          if (success) {
            const { authToken, passwordVerification, id } = data || {};
            localStorage.setItem(STORAGE_KEY.accessToken, authToken);
            localStorage.setItem("passwordVerification", passwordVerification);
            localStorage.setItem("id", id);
            localStorage.setItem("userAccount", loginInfo.account);
            dispatch(signIn(authToken));
          } else {
            setVerificationCode("");

            const callback = data?.isOtpFailed ? history.goBack : () => {};
            dispatch(actionOpen(message, callback, "", "", true));
          }
        })
        .finally(() => dispatch(loadingEnd));
    } else {
      dispatch(actionError("OTP Code를 정확히 입력해주세요."));
    }
  };

  return (
    <BasicLayout center className={classes.root}>
      <img src={images.banner} className={classes.banner} />
      <Paper className={classes.main}>
        <img src={images.logo_new} className={classes.logo} />
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          OTP 코드를 입력하세요.
        </Text>

        <Flex className={classes.form} row>
          <Flex className={classes.formInput}>
            <TextInput
              type="number"
              value={verificationCode}
              onChange={setVerificationCode}
              className={classes.input}
              placeholder="Code"
              onEnter={handleClickLogin}
              autoFocus
              maxLength={6}
            />
          </Flex>
        </Flex>

        <Button
          onClick={handleClickLogin}
          className={classes.button}
          label="로그인"
        />
      </Paper>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
  },
  label: {
    color: colors.black,
    fontSize: "24px",
    marginTop: 10,
    textAlign: "center",
  },
  form: {
    margin: "0px 30px",
    marginTop: "50px",
    alignItems: "center",

    alignSelf: "stretch",
  },
  input: {
    marginTop: "10px",
    border: "none",
    backgroundColor: "#f2f2f2",
  },
  formInput: { flex: 1 },
  button: {
    marginBottom: 50,
    marginTop: "30px",
    alignSelf: "stretch",
    backgroundColor: colors.primary,
    margin: "0px 30px",
  },
  banner: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  main: {
    backgroundColor: "#fff",
    padding: "60px 50px",
    boxSizing: "border-box",
    zIndex: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 600,
  },
  logo: {
    width: 240,
    height: 40,
    objectFit: "contain",
  },
});
