import { ButtonBase, makeStyles } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import Editor from "../../components/editor/Editor";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";

export default function ServiceUseGuideDetail({}) {
  const history = useHistory();

  const classes = useStyle();
  return (
    <>
      <PageLabel
        add={{
          title: "삭제",
          onClick: () => {
            history.goBack();
          },
        }}
      >
        이용가이드 (등록/수정)
      </PageLabel>
      <GridRow
        data={[
          {
            label: "등록일",
            value: "20203.23.23 13:31:00",
            fullWidth: true,
          },

          {
            label: "제목",
            type: "input",
            fullWidth: true,
            inputFullWidth: true,
          },
          {
            label: "썸네일",
            fullWidth: true,
            type: "render",
            render: (
              <Flex>
                <Flex row className={classes.images}>
                  <div>
                    <Add />
                    이미지
                  </div>
                </Flex>
              </Flex>
            ),
          },
          {
            type: "render",
            label: "내용",
            fullWidth: true,
            render: (
              <div className={classes.inputWrap}>
                <Editor />
              </div>
            ),
          },
        ]}
      />

      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.button1}>
          목록
        </ButtonBase>
        <ButtonBase onClick={history.goBack} className={classes.button2}>
          저장
        </ButtonBase>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  buttons: { alignSelf: "center", marginTop: 50 },
  button1: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  inputWrap: {
    display: "flex",
    flex: 1,
    marginBottom: 30,
    flexDirection: "column",
    height: "500px !important",
    alignSelf: "stretch",
  },
  images: {
    alignItems: "center",
    "& div": {
      width: 200,
      border: "1px solid #ddd",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: 200,
      marginRight: 10,
      position: "relative",
      "& span": {
        position: "absolute",
        top: 0,
        right: 0,
        cursor: "pointer",
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": { color: "#fff" },
      },
    },
  },
});
