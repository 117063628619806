import { Menu, MenuItem } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { formatTime } from "../../libs/utils";
import Flex from "../flex/Flex";

const ContextMenuLayout = ({ children, name, headers, data = [] }) => {
  const excelRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleClickDownloadExcel = () => {
    handleCloseContextMenu();
    excelRef.current.link.click();
  };

  return (
    <Flex onContextMenu={handleContextMenu}>
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        onContextMenu={handleContextMenu}
      >
        <MenuItem onClick={handleClickDownloadExcel}>Download Excel</MenuItem>
      </Menu>

      <CSVLink
        filename={`${name || "Excel"}_${formatTime(
          new Date(),
          "YYYYMMDDHHmmSS"
        )}`}
        headers={headers}
        data={data.map((dataRow) => {
          const returnRow = { ...dataRow };

          for (let key in returnRow) {
            if (returnRow[key] && !isNaN(returnRow[key])) {
              returnRow[key] = `=""${returnRow[key]}""`;
            }
          }

          return returnRow;
        })}
        ref={excelRef}
        style={{ width: 0, height: 0 }}
      />
    </Flex>
  );
};

export default ContextMenuLayout;
