export const INVENTORY_APPRISAL_FEE = {
  DROP_DOWN: {
    DATE_TYPE: [
      { label: "요청일", value: "CREATED" },
      { label: "확정일", value: "CONFIRM" },
    ],
  },
  MUTLISELECT: {
    ASSET_TYPE: [
      { label: "금", value: "GOLD" },
      { label: "은", value: "SILVER" },
    ],
    DISPOSE_TYPE: [
      { label: "감정평가", value: "APPRAISAL_FEE_RECEIPT" },
      { label: "자산처분", value: "APPRAISAL_FEE_DISPOSE" },
    ],
  },
};
const PAGE_SIZE = [10, 30, 50, 100];

export const changeDisposeType = (disposeType) => {
  if (disposeType === "감정평가수수료수취") return "감정평가";
  if (disposeType === "감정평가수수료처분") return "자산처분";
};

export const changeAssetType = (assetType) => {
  if (assetType === "GOLD") return "금";
  if (assetType === "SILVER") return "은";
  return "";
};

export const getPageInfo = (data) => {
  if (!data) return null;

  const { number, size, totalElements } = data;
  return {
    total: totalElements,
    skip: number * size,
    take: size,
    pageable: {
      pageSizes: [...PAGE_SIZE],
    },
  };
};

export const getFromHistoryState = (locationState) => {
  const { startDate, endDate, dateType, page, size } = locationState;
  const historyInitial = {
    dateRange: {
      start: new Date(startDate),
      end: new Date(endDate),
    },
    dateType: INVENTORY_APPRISAL_FEE.DROP_DOWN.DATE_TYPE.find(
      (item) => item.value === dateType
    ),
    page,
    size,
    refetchFlag: 0,
  };
  if ("assetType" in locationState) {
    historyInitial.assetType = locationState.assetType.map((item) =>
      INVENTORY_APPRISAL_FEE.MUTLISELECT.ASSET_TYPE.find(
        (select) => select.value === item
      )
    );
  }
  if ("disposeType" in locationState) {
    historyInitial.disposeType = locationState.disposeType.map((item) =>
      INVENTORY_APPRISAL_FEE.MUTLISELECT.DISPOSE_TYPE.find(
        (select) => select.value === item
      )
    );
  }

  return { historyInitial, historySearch: locationState };
};
