import { CircularProgress, Dialog, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import colors from "../../libs/colors";

export default function GlobalLoading({}) {
  const { loading } = useSelector((s) => s.loading);
  const classes = useStyle();

  if (!loading) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.wrap}>
        <CircularProgress
          color="inherit"
          style={{ color: colors.primary }}
          size={70}
        ></CircularProgress>
      </Paper>
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    zIndex: 1000,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  wrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: 50,
    borderRadius: 30,
    //backgroundColor: "#fff",
    backgroundColor: "rgba(255,255,255,0.9)",
  },
});
