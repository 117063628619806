import {
  makeStyles,
  Radio,
  RadioGroup,
  Tooltip,
  FormControlLabel,
  Popover,
} from "@material-ui/core";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import * as APIS from "../../libs/apis";
import { objToQueryStr, numFormat } from "../../services/utils";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { getDefaultPageSize } from "../../libs/consts";
import DataTable from "../../components/table/Table";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import routes from "../../libs/routes";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import Input from "../../components/input/Input";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import { STORAGE_KEY } from "../../libs/consts";
import { formatTime, numberWithCommas } from "../../libs/utils";
// import { Popover } from "@progress/kendo-react-tooltip";
import { saveAs } from "file-saver";
import { NumericTextBox } from "@progress/kendo-react-inputs";

export default function SettlementSalesFee({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyle();
  const { location, push, replace } = useHistory();
  const state = location.state || {};
  const [totalCount, setTotalCount] = useState(0);
  const [exchargeList, setexchargeList] = useState([]);
  const [totalPrice, settotalPrice] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [checkYesterDay, setCheckYesterDay] = useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let today = new Date();
  let pastTab = useRef(state.tab);

  // useEffect(() => {
  //   if (state.tab === undefined || state.tab === null) {
  //     history.replace(location.pathname, {
  //       ...state,
  //       tab: "1",
  //       ...getDefaultPageSize(),
  //     });
  //   }
  // }, []);
  // useEffect(() => {
  //   handleChangeValue("status", []);
  //   // if (!isNaN(state.page)) {
  //   //   fetchList();
  //   // }
  // }, [state.tab]);

  useEffect(() => {
    if (checkYesterDay !== "") {
      fetchList();
    }
  }, [checkYesterDay]);

  useEffect(() => {
    if (!isNaN(state.page)) {
      if (pastTab.current !== state.tab) {
        history.replace(location.pathname, {
          ...state,
          amountFrom: "",
          amountTo: "",
          page: 0,
          size: localStorage.getItem(STORAGE_KEY.tableSize) || 10,
        });
        pastTab.current = state.tab;
        if (state.page < 1) {
          fetchList("tabChange");
        }
      } else {
        fetchList();
      }
    }
  }, [state.page, state.orderBy, state.tab]);

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  const fetchList = async (props) => {
    const param = { ...state };
    if (state.tab === "1") {
      param.depositOrWithdraw = state.tab === "1" ? "WITHDRAW" : "DEPOSIT";
    } else {
      // 입금내역
      param.depositOrWithdraw = state.tab === "1" ? "WITHDRAW" : "DEPOSIT";
      param.status = state.statusDeposit;
      param.beginCompleteDate = state.beginDepositDate;
      param.endCompleteDate = state.endDepositDate;
    }
    delete param.replace;
    delete param.tab;
    if (props) {
      delete param.amountFrom;
      delete param.amountTo;
    }
    dispatch(loadingStart);
    const listApi = APIS.getOrderExcharge(objToQueryStr(param))
      .then(({ data: { data } }) => {
        setexchargeList(
          data.content.map((item) => {
            return {
              ...item,
              // account:
              //   item.accountBankName && item.accountNumber
              //     ? `${item.accountBankName} ${item.accountNumber}`
              //     : "-",
            };
          })
        );
        setTotalCount(data.totalElements);
      })
      .catch((err) => dispatch(actionError(err)));

    const totalApi = APIS.getOrderExchargeTotal(objToQueryStr(param))
      .then(({ data: { data, totalElements, success } }) => {
        if (success === true) {
          settotalPrice(data.total);
        } else {
          settotalPrice(0);
        }
      })
      .catch((err) => dispatch(actionError(err)));

    Promise.all([listApi, totalApi]).finally(() => {
      dispatch(loadingEnd);
    });
  };
  // const statusChfunc = () => {
  //   var params = { ids: selectid, status: statusChselect };

  //   APIS.putexChargeStatus(params).then(
  //     ({ data: { data, success, message } }) => {
  //       if (success) {
  //         fetchList();
  //       } else {
  //         dispatch(actionOpen(message, null, null, null, true));
  //       }
  //     }
  //   );
  // };

  //출금계좌번호 조회
  const handleClickDetail = (event, id) => {
    setAccountNumber("");
    const currentTarget = event.currentTarget;
    APIS.getWithdrawalAccountNumber(id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAnchorEl(currentTarget);
          setAccountNumber(data.accountNumber);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickConfirmWithdraw = (props, password, msg) => {
    const { type, data } = props;
    const parsePassword = base64.stringify(sha256(`${password}`));

    if (!password) {
      alert("관리자 패스워드를 입력해주세요.");
      return false;
    }
    let payload = {};
    // 직접이체
    if (type === "direct") {
      payload = {
        id: data.id,
        password: parsePassword,
        status: "COMPLETE",
        type: "WITHDRAW",
      };
    }

    // 승인시 자동이체
    if (type === "approval") {
      payload = {
        id: data.id,
        password: parsePassword,
        status: "COMPLETE",
        type: "FIRM_BANKING",
      };
    }

    // 출금 취소
    if (type === "cancel") {
      payload = {
        id: data.id,
        password: parsePassword,
        status: "CANCEL",
        msg: msg,
      };
    }

    APIS.putexChargeStatus(payload)
      .then(({ data: { success, message } }) => {
        dispatch(closeModal());
        if (!success) {
          dispatch(
            actionOpen(
              message,
              () => {
                fetchList();
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(loadingStart);
          setTimeout(() => {
            dispatch(loadingEnd);
            dispatch(
              actionOpen(
                "처리되었습니다.",
                () => {
                  fetchList();
                },
                null,
                null,
                true
              )
            );
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(closeModal());
        dispatch(actionError(err));
      });
  };

  const AdminConfirmModal = (props) => {
    const { type, data } = props;
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    return (
      <div style={{ width: "400px", height: "200px" }}>
        <div className={classes.modal}>
          출금
          {type === "cancel"
            ? "취소"
            : type === "approval"
            ? "승인"
            : type === "direct"
            ? "직접이체"
            : "-"}
        </div>
        <div className={classes.content}>
          <Flex
            style={{
              textAlign: "center",
              lineHeight: type === "cancel" ? "30px" : "170px",
            }}>
            {type === "cancel" && (
              <span>
                <textarea
                  cols={40}
                  rows={5}
                  maxLength="100"
                  value={msg}
                  placeholder="취소 사유를 입력해주세요"
                  onChange={(e) => setMsg(e.target.value)}
                  style={{ resize: "none" }}
                />
              </span>
            )}
            <span>
              <Text>관리자 비밀번호</Text>&nbsp;
              <Input
                password
                maxLength="30"
                value={password}
                onChange={(e) => setPassword(e)}
              />
            </span>
          </Flex>
        </div>
        <div className={classes.btnWrap}>
          <Button
            label="확인"
            onClick={() => onClickConfirmWithdraw(props, password, msg)}
          />
          &nbsp;&nbsp;
          <Button
            label="취소"
            onClick={() => {
              setPassword("");
              setMsg("");
              dispatch(closeModal());
            }}
          />
        </div>
      </div>
    );
  };

  const onClickApproval = (props) => {
    // props data
    dispatch(closeModal());
    const payload = {
      visiable: true,
      children: <AdminConfirmModal data={props} type="approval" />,
    };
    dispatch(openModal(payload));
  };

  const onClickDirectApproval = (props) => {
    // props data
    dispatch(closeModal());
    const payload = {
      visiable: true,
      children: <AdminConfirmModal data={props} type="direct" />,
    };
    dispatch(openModal(payload));
  };

  const onClickCancelApproval = (props) => {
    const payload = {
      visiable: true,
      children: <AdminConfirmModal data={props} type="cancel" />,
    };
    dispatch(openModal(payload));
  };

  const ConfrimModal = (props) => {
    const {
      accountHolder,
      accountNumber,
      accountBankName,
      phone,
      requestAmount,
    } = props;
    return (
      <div style={{ width: "300px", height: "180px" }}>
        <div className={classes.modal}>승인요청</div>
        <div className={classes.content}>
          {accountHolder}
          <br />
          {phone}
          <br />
          <br />

          <div className={classes.contentText}>
            <span>{accountBankName}</span>
            <span>{accountNumber}</span>
          </div>
          <br />
          <div className={classes.contentText}>
            <span>요청금액</span>
            <span>{numFormat(requestAmount)} 원</span>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <Button label="출금승인" onClick={() => onClickApproval(props)} />
          {/* &nbsp;&nbsp; */}
          {/* <Button
            label="직접이체"
            onClick={() => onClickDirectApproval(props)}
          /> */}
          &nbsp;&nbsp;
          <Button
            label="출금취소"
            onClick={() => onClickCancelApproval(props)}
          />
        </div>
      </div>
    );
  };

  const withdrawConfirm = (props) => {
    const payload = {
      visiable: true,
      children: <ConfrimModal {...props} />,
    };
    dispatch(openModal(payload));
  };

  const onClickExcelDownload = () => {
    const param = { ...state };

    if (state.tab === "1") {
      param.depositOrWithdraw = state.tab === "1" ? "WITHDRAW" : "DEPOSIT";
    } else {
      // 입금내역
      param.depositOrWithdraw = state.tab === "1" ? "WITHDRAW" : "DEPOSIT";
      param.status = state.statusDeposit;
      param.beginCompleteDate = state.beginDepositDate;
      param.endCompleteDate = state.endDepositDate;
    }
    delete param.replace;
    delete param.tab;
    delete param.page;
    delete param.size;

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.getOrderExchargeExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `출금관리_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  // 입금방식 라디오 버튼
  const BankAccountRadioGroup = () => {
    return (
      <RadioGroup
        row
        key={state.depositMethod}
        value={state.depositMethod}
        onChange={(e) => {
          handleChangeValue("depositMethod", e.target.value);
        }}>
        <FormControlLabel
          label="전체"
          value=""
          control={<Radio color="default" style={{ color: "black" }} />}
        />
        <FormControlLabel
          label="가상"
          value="VIRTUAL"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
        <FormControlLabel
          label="헥토 간편"
          value="HECTO_SIMPLE"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
        <FormControlLabel
          label="토스 퀵계좌"
          value="TOSS_QUICK"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
        <FormControlLabel
          label="K-Bank"
          value="K_BANK_MALL"
          control={<Radio color="default" style={{ color: "black" }} />}
        />
      </RadioGroup>
    );
  };

  return (
    <>
      <LabelTabs
        data={[
          { label: "출금관리", value: "1" },
          { label: "입금내역", value: "2" },
        ]}
      />
      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          tab: state.tab === "2" ? "2" : "1",
          orderBy: "DESC",
          beginCompleteDate: formatTime(),
          endCompleteDate: formatTime(),
        }}
        onSearch={fetchList}
        excel={onClickExcelDownload}
        searchType="all"
        data={
          state.tab !== "1"
            ? [
                {
                  label: "입금일",
                  type: "date-range",
                  key: "beginDepositDate,endDepositDate",
                  fullWidth: true,
                },
                {
                  label: "회원검색",
                  type: "input",
                  placeholder: "고객명, 고객번호",
                  key: "search",
                  autoFocus: true,
                },
                {
                  label: "금액",
                  type: "render",
                  render: <AmountFilter />,
                },
                {
                  label: "처리상태",
                  type: "checkbox",
                  key: "statusDeposit",
                  data: [
                    { label: "성공", value: "COMPLETE" },
                    { label: "실패", value: "FAILED" },
                    { label: "취소", value: "CANCEL" },
                  ],
                },
                {
                  label: "입금방식",
                  type: "render",
                  render: <BankAccountRadioGroup />,
                },
              ]
            : [
                {
                  label: "출금요청일",
                  type: "date-range",
                  key: "beginRequestDate,endRequestDate",
                },
                {
                  label: "출금완료일",
                  type: "render",
                  render: (
                    <Flex row>
                      <input
                        value={state.beginCompleteDate || ""}
                        onChange={(e) => {
                          handleChangeValue(
                            "beginCompleteDate",
                            e.target.value
                          );
                        }}
                        type="date"
                        className={classes.datepicker}
                      />
                      <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                        ~
                      </span>
                      <input
                        value={state.endCompleteDate || ""}
                        onChange={(e) => {
                          handleChangeValue("endCompleteDate", e.target.value);
                        }}
                        type="date"
                        className={classes.datepicker}
                      />
                      <Button
                        onClick={() => {
                          const yesterDay = new Date(
                            today.setDate(today.getDate() - 1)
                          );
                          setCheckYesterDay(yesterDay);
                          history.replace({
                            pathname: location.pathname,
                            state: {
                              ...location.state,
                              beginCompleteDate: formatTime(yesterDay),
                              endCompleteDate: formatTime(yesterDay),
                            },
                          });
                        }}
                        label="전일"
                        className={classes.previousDayBtn}
                      />
                    </Flex>
                  ),
                },
                {
                  label: "회원검색",
                  type: "input",
                  placeholder: "고객명, 연락처, 고객번호",
                  key: "search",
                  autoFocus: true,
                },
                {
                  label: "금액",
                  type: "render",
                  render: <AmountFilter />,
                },
                {
                  label: "처리상태",
                  type: "checkbox",
                  key: "status",
                  fullWidth: true,
                  data: [
                    //{ label: "전체", value: "ALL" },
                    { label: "사용자 요청", value: "USER_REQUEST" },
                    { label: "승인 대기", value: "MANAGER_WAIT" },
                    { label: "펌뱅킹 실패", value: "FIRM_BANK_FAILED" },
                    { label: "출금 실패", value: "FAILED" },
                    { label: "출금완료", value: "COMPLETE" },
                    { label: "출금취소", value: "CANCEL" },
                    { label: "처리 중", value: "RESULT_WAIT" },
                  ],
                },
              ]
        }
      />
      <Flex row style={{ alignSelf: "flex-end", marginRight: "34px" }}>
        <RadioGroup
          key={state.orderBy}
          value={state.orderBy}
          onChange={(e) => {
            handleChangeValue("orderBy", e.target.value);
          }}
          row>
          <FormControlLabel
            label="최근 순서로"
            value="DESC"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
          <FormControlLabel
            label="오래된 순서로"
            value="ASC"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
        </RadioGroup>
      </Flex>
      {/* <Flex row className={classes.item}>
        상태변경
        <select
          onChange={(e) => {
            setstatusChselect(e.target.value);
          }}
        >
          <option label="취소" value="CANCEL" />
          <option label="출금완료" value="COMPLETE" />
        </select>
        <Button label="저장" onClick={() => statusChfunc()} />
      </Flex> */}
      <Flex row className={classes.tableHeader}>
        <Flex row className={"row-center"}>
          <Text font={fonts.notoSansKRBold}>목록 검색결과 {totalCount}</Text>
          <Text font={fonts.notoSansKRBold} className={classes.label}>
            합계 : {numFormat(totalPrice)} 원
          </Text>
        </Flex>
      </Flex>
      {state.tab === "1" ? (
        <DataTable
          hideLabel
          data={exchargeList}
          totalCount={totalCount}
          clicktype="checkbox"
          columns={[
            { label: "출금요청일", key: "requestDate", type: "datetime" },
            { label: "출금은행", key: "accountBankName" },
            {
              label: "출금계좌번호",
              key: "accountNumber",
              render: (row) => {
                return (
                  <Flex>
                    <span
                      onClick={(event) => {
                        handleClickDetail(event, row?.id);
                      }}
                      className={classes.underline}>
                      {row.accountNumber}
                    </span>
                  </Flex>
                );
              },
            },
            { label: "출금액", key: "requestAmount", type: "number" },
            {
              label: "예상예금주",
              key: "accountHolder",
              render: (row) => {
                return (
                  <a
                    onClick={() => {
                      history.push(`${routes.memberDetail}/${row.userId}`);
                    }}
                    className={classes.underline}>
                    {row.accountHolder}
                  </a>
                );
              },
            },
            { label: "고객번호", key: "userId" },
            { label: "입금통장표시", key: "depositAccount" },
            { label: "출금통장표시", key: "withdrawAccount" },
            { label: "메모", key: "memo" },
            { label: "CMS코드", key: "cmsCd" },
            { label: "받는분 휴대폰번호", key: "phone" },
            {
              label: "처리상태",
              key: "status",
              type: "exchargestatus",
              render: (row) => {
                const parseStatus =
                  row.status === "COMPLETE"
                    ? "출금완료"
                    : row.status === "WITHDRAWAL_REQUEST"
                    ? "출금 요청"
                    : row.status === "CANCEL"
                    ? "출금취소"
                    : row.status === "FAILED"
                    ? "출금실패"
                    : row.status === "MANAGER_WAIT"
                    ? "승인대기"
                    : row.status === "USER_REQUEST"
                    ? "사용자 요청"
                    : row.status === "FIRM_BANK_FAILED"
                    ? "펌뱅킹 실패"
                    : row.status === "RESULT_WAIT"
                    ? "처리 중"
                    : "-";
                return row.status === "FIRM_BANK_FAILED" ? (
                  <Tooltip
                    title={row.failedReason}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}>
                    <span
                      className={classes.underline}
                      onClick={() => {
                        withdrawConfirm(row);
                      }}>
                      {parseStatus}
                    </span>
                  </Tooltip>
                ) : row.status === "CANCEL" ? (
                  <Tooltip
                    title={row?.failedReason || "-"}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}>
                    <span>{parseStatus}</span>
                  </Tooltip>
                ) : row.status === "MANAGER_WAIT" ? (
                  <span
                    className={classes.underline}
                    onClick={() => {
                      withdrawConfirm(row);
                    }}>
                    {parseStatus}
                  </span>
                ) : (
                  <Text>{parseStatus}</Text>
                );
              },
            },
            { label: "처리일시", key: "completeDate" },
          ]}
        />
      ) : (
        <DataTable
          hideLabel
          data={exchargeList}
          totalCount={totalCount}
          clicktype="checkbox"
          columns={[
            { label: "입금일", key: "requestDate", type: "datetime" },
            // { label: "입금은행", key: "accountBankName" },
            {
              label: "이름",
              key: "userName",
              key: "accountHolder",
              render: (row) => {
                return (
                  <a
                    onClick={() => {
                      history.push(`${routes.memberDetail}/${row.userId}`);
                    }}
                    className={classes.underline}>
                    {row.accountHolder}
                  </a>
                );
              },
            },
            { label: "고객번호", key: "userId" },
            { label: "가상계좌번호", key: "virtualAccountNumber" },
            { label: "입금금액", key: "requestAmount", type: "number" },
            { label: "추천매장", key: "shopName" },
            {
              label: "입금계좌",
              render: (row) => {
                return (
                  <Flex>
                    <span>
                      {row.accountBankName ? row.accountBankName : "-"}
                    </span>
                    <span
                      onClick={(event) => {
                        handleClickDetail(event, row?.id);
                      }}
                      className={classes.underline}>
                      {row.accountNumber}
                    </span>
                  </Flex>
                );
              },
            },
            {
              label: "처리상태",
              key: "status",
              type: "exchargestatus",
              render: (row) => {
                const parseStatus =
                  row.status === "COMPLETE"
                    ? "성공"
                    : row.status === "FAILED"
                    ? "실패"
                    : row.status === "CANCEL"
                    ? "취소"
                    : "-";
                return <Text>{parseStatus}</Text>;
              },
            },
            {
              label: "입금방식",
              key: "depositMethod",
            },
            { label: "처리일시", key: "completeDate", type: "datetime" },
          ]}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top" }}>
        <Flex style={{ padding: 5 }}>{accountNumber}</Flex>
      </Popover>
    </>
  );
}

const AmountFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const { amountFrom = "", amountTo = "" } = state;

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  return (
    <Flex row>
      <NumericTextBox
        value={amountFrom}
        onChange={(e) => {
          handleChangeValue("amountFrom", e.value);
        }}
        width={165}
        spinners={false}
        fillMode={"outline"}
        inputStyle={{ backgroundColor: "white" }}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}>
        이상
      </span>
      <NumericTextBox
        value={amountTo}
        onChange={(e) => {
          handleChangeValue("amountTo", e.value);
        }}
        width={165}
        spinners={false}
        fillMode={"outline"}
        inputStyle={{ backgroundColor: "white" }}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}>
        이하
      </span>
    </Flex>
  );
};

const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    marginRight: 50,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  underline: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  supplementLabel: {
    marginLeft: 10,
    color: "gray",
  },
  tableHeader: {
    alignSelf: "stretch",
    marginTop: 10,
    justifyContent: "space-between",
    margin: "10px 50px",
  },
  table3: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  modal: {
    textAlign: "center",
    width: "100%",
    height: "50px",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
  },
  content: {
    marginTop: "30px",
    height: "150px",
  },
  btnWrap: {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
  },
  contentText: {
    display: "flex",
    justifyContent: "space-between",
  },
  tooltip: {
    fontSize: "18px",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    width: "auto",
  },
  previousDayBtn: {
    fontFamily: fonts.notoSansKRBold,
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 15,
    width: "70px",
  },
});
