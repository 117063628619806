import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import Flex from "components/flex/Flex";
import DataTable from "components/table/Table";
import Filters from "components/filters/Filters";
import PageLabel from "components/page-label/PageLabel";
import ButtonRadios from "components/button-radios/ButtonRadios";
import MainLayout from "layouts/main/MainLayout";
import fonts from "libs/fonts";
import routes from "libs/routes";
import consts from "libs/consts";
import * as APIS from "libs/apis";
import { getNoProcessLabel } from "libs/utils";
import {
  formatPhone,
  numFormat,
  getPurityLabel,
  objToQueryStr,
} from "services/utils";
import moment from "moment-timezone";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";

export default function AppraisalProcess({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const dispatch = useDispatch();

  const [weightRates, setWeightRates] = useState([]);
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  const [visitStats, setVisitStats] = useState({});
  const [logiStats, setLogiStats] = useState({});
  const [siteStats, setSiteStats] = useState({});
  const [summary, setSummary] = useState({});

  useEffect(() => {
    state.page = 0;
    state.size = 10;
    fetchList("mode", "SIMPLE_VISIT", "등록대기");
  }, []);

  useEffect(() => {
    APIS.getcurrentmarketprice("GOLD").then(({ data: { data } }) => {
      setGoldGramPrice(data);
    });

    APIS.getcurrentmarketprice("SILVER").then(({ data: { data } }) => {
      setSilverGramPrice(data);
    });

    APIS.getAppraisalWeightRates()
      .then(({ data: { data } }) => {
        setWeightRates(data.content);
      })
      .catch((err) => dispatch(actionError(err)));

    APIS.getAppraisalStatSummary().then((res) => {
      setSummary(res.data.data);
    });

    APIS.getAppraisalStatStatus(objToQueryStr({ method: "SIMPLE_VISIT" })).then(
      (res) => {
        setVisitStats(res.data.data);
      }
    );

    APIS.getAppraisalStatStatus(
      objToQueryStr({ method: "SIMPLE_DELIVERY" })
    ).then((res) => {
      setLogiStats(res.data.data);
    });

    APIS.getAppraisalStatStatus(objToQueryStr({ method: "DIRECT_VISIT" })).then(
      (res) => {
        setSiteStats(res.data.data);
      }
    );
  }, []);

  const fetchList = (mode, kind) => {
    let queryStr = objToQueryStr({ ...state, kind: kind });

    if (mode === "excel") {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        consts.apiBaseUrl +
          "/api/v1/admin/market/appraisal/Requests/todolist/downloadExcel" +
          objToQueryStr({ ...state, page: undefined, size: undefined })
      );
      element.target = "_blank";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return (element = null);
    }

    dispatch(loadingStart);

    APIS.getAppraisalRequestTodolist(queryStr)
      .then((res) => {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...state,
            total: res.data.data.totalElements,
            list: res.data.data.content,
          },
        });
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <>
      <PageLabel>처리현황</PageLabel>
      <span className={classes.label}>간편감정</span>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <thead>
          <tr>
            <th rowSpan={1}>접수</th>
            <th rowSpan={1}>진행중</th>
            <th colSpan={1}>완료</th>
            <th colSpan={1}>취소</th>
            <th colSpan={1}>미처리</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{summary.reception}</td>
            <td>{summary.ongoing}</td>
            <td>{summary.complete}</td>
            <td>{summary.cancel}</td>
            <td>
              <a
                href="#"
                onClick={() => {
                  state.page = 0;
                  state.size = 10;
                  fetchList("mode", "0");
                }}
              >
                {summary.todo}
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        {/* <thead> */}
        <tr>
          <th rowSpan={3}>매장</th>
          <th rowSpan={2}>접수</th>
          <th colSpan={3}>진행중</th>
          <th colSpan={2}>완료/취소</th>
          <th colSpan={2} className={classes.thHi}>
            미처리
          </th>
        </tr>

        <tr>
          <th>감정중</th>
          <th>감정완료</th>
          <th>등록반려</th>
          <th>교환완료</th>
          <th>취소</th>
          <th>접수대기</th>
          <th>등록대기</th>
        </tr>
        {/* </thead> */}
        {/* <tbody> */}
        <tr>
          <td>{visitStats.visitReservation}</td>
          <td>{visitStats.progressAppraisal}</td>
          <td>{visitStats.progressAppraisalFinish}</td>
          <td>{visitStats.rejectRegistration}</td>
          <td>{visitStats.completeExchange}</td>
          <td>{visitStats.cancel}</td>
          <td>
            <a
              href="#"
              onClick={() => {
                state.page = 0;
                state.size = 10;
                fetchList("mode", "6");
              }}
            >
              {visitStats.waitReceive}
            </a>
          </td>
          <td>
            <a
              href="#"
              onClick={() => {
                state.page = 0;
                state.size = 10;
                fetchList("mode", "1");
              }}
            >
              {visitStats.waitRegistration}
            </a>
          </td>
        </tr>
        {/* </tbody> */}
      </table>

      <table cellPadding={0} cellSpacing={0} className={classes.table2}>
        {/* <thead> */}
        <tr>
          <th rowSpan={3}>택배</th>
          <th colSpan={2}>접수대기</th>
          <th colSpan={2}>진행중</th>
          <th colSpan={2}>완료/취소</th>
          <th colSpan={3} className={classes.thHi}>
            미처리
          </th>
        </tr>

        <tr>
          {/* <th>총접수</th> */}
          <th>접수</th>
          <th>접수반려</th>
          <th>배송중</th>
          {/* <th>배송완료</th> */}
          <th>감정완료</th>
          <th>교환완료</th>
          <th>취소</th>
          <th>접수대기</th>
          <th>감정대기</th>
          <th>반송대기</th>
        </tr>
        {/* </thead> */}
        {/* <tbody> */}
        <tr>
          {/* <td>5</td> */}
          <td>{logiStats.approval}</td>
          <td>{logiStats.rejectReceive}</td>
          <td>{logiStats.shipping}</td>
          {/* <td>5</td> */}
          <td>{logiStats.progressAppraisalFinish}</td>
          <td>{logiStats.completeExchange}</td>
          <td>{logiStats.cancel}</td>
          <td>
            <a
              href="#"
              onClick={() => {
                state.page = 0;
                state.size = 10;
                fetchList("mode", "2");
              }}
            >
              {logiStats.waitReceive}
            </a>
          </td>
          <td>
            <a
              href="#"
              onClick={() => {
                state.page = 0;
                state.size = 10;
                fetchList("mode", "3");
              }}
            >
              {logiStats.waitAppraisal}
            </a>
          </td>
          <td>
            <a
              href="#"
              onClick={() => {
                state.page = 0;
                state.size = 10;
                fetchList("mode", "4");
              }}
            >
              {logiStats.waitReturn}
            </a>
          </td>
        </tr>
        {/* </tbody> */}
      </table>

      <Flex row>
        <Flex style={{ flex: 2 }}>
          <span className={classes.label}>현장감정</span>
          <table cellPadding={0} cellSpacing={0} className={classes.table2}>
            {/* <thead> */}
            <tr>
              <th rowSpan={3}>매장</th>
              <th rowSpan={2}>접수</th>
              <th colSpan={3}>진행중</th>
              <th colSpan={2}>완료/취소</th>
              <th
                className={classes.thHi}
                onClick={() => {
                  state.page = 0;
                  state.size = 10;
                  fetchList("mode", 3);
                }}
              >
                미처리
              </th>
            </tr>

            <tr>
              <th>감정중</th>
              <th>감정완료</th>
              <th>등록반려</th>
              <th>교환완료</th>
              <th>취소</th>
              <th>등록대기</th>
            </tr>
            {/* </thead> */}
            {/* <tbody> */}
            <tr>
              <td>{siteStats.receive}</td>
              <td>{siteStats.progressAppraisal}</td>
              <td>{siteStats.progressAppraisalFinish}</td>
              <td>{siteStats.rejectRegistration}</td>
              <td>{siteStats.completeExchange}</td>
              <td>{siteStats.cancel}</td>
              <td>
                <a
                  href="#"
                  onClick={() => {
                    state.page = 0;
                    state.size = 10;
                    fetchList("mode", "5");
                  }}
                >
                  {siteStats.waitRegistration}
                </a>
              </td>
            </tr>
            {/* </tbody> */}
          </table>
        </Flex>

        <Flex style={{ flex: 1 }}>
          {/*
               <span className={classes.label}>딜러감정</span>
          <table cellPadding={0} cellSpacing={0} className={classes.table2}>
            <tr>
              <th rowSpan={3}>딜러</th>
              <th rowSpan={2}>총접수</th>
              <th colSpan={2}>완료/취소</th>
              <th className={classes.thHi}>미처리</th>
            </tr>

            <tr>
              <th>교환완료</th>
              <th>취소</th>
              <th>등록대기</th>
            </tr>

            <tr>
              <td>5</td>
              <td>5</td>
              <td>5</td>
              <td>5</td>
            </tr>
          </table>
          */}
        </Flex>
      </Flex>

      <DataTable
        name="미처리 현황"
        hideLabel
        labelComponent={<span>미처리 현황</span>}
        data={state.list}
        totalCount={state.total}
        rowClassName="pointer"
        columns={[
          { label: "접수번호", key: "number" },
          {
            label: "요청일",
            render: (x) => {
              if (
                x.appraisalRequestStatus &&
                x.appraisalRequestStatus.filter((x) => x.status === "접수")[0]
              ) {
                return moment(
                  x.appraisalRequestStatus.filter((x) => x.status === "접수")[0]
                    .createdAt
                ).format("YYYY.MM.DD HH:mm");
              } else {
                return "-";
              }
            },
          },
          {
            label: "분류",
            key: "type",
            render: (x) => {
              if (x.type) {
                return x.type;
              } else {
                return "-";
              }
            },
          },
          {
            label: "종류",
            render: (x) => {
              if (!x.appraisalSimples) {
                return "";
              }
              let str = "";
              x.appraisalSimples.forEach((x) => {
                str += str
                  ? str + "\n" + x.appraisalProduct.name
                  : x.appraisalProduct.name;
              });
              return (
                <span>
                  {str
                    ? str.split("").map((x, i) => {
                        if (x === "\n") {
                          return <br key={i.toString()} />;
                        } else {
                          return x;
                        }
                      })
                    : "-"}
                </span>
              );
            },
          },
          {
            label: "감정 정보",
            render: (x) => {
              if (x.status === "교환완료") {
                return (
                  <span>
                    {x.appraisalWeightGram}
                    {" = "}
                    {numFormat(
                      x.appraisalWeightGram *
                        (x.assetType === "GOLD"
                          ? goldGramPrice
                          : silverGramPrice)
                    ) + "원"}
                  </span>
                );
              } else {
                const data = [];
                x.appraisalSimples.forEach((z) => {
                  let a =
                    z.weightGram *
                    (weightRates.filter(
                      (y) =>
                        y.type === z.appraisalProduct.type &&
                        y.purity === z.purity
                    )[0]?.weightRatePct /
                      100) *
                    (z.appraisalProduct.weightRatePct / 100) *
                    z.quantity;
                  data.push({ gram: a, ...z });
                });
                return (
                  <span>
                    {data.length === 0
                      ? "-"
                      : data.map((z, y) => {
                          return (
                            <span key={y.toString()}>
                              {y > 0 && <br />}
                              {y > 0 && <br />}
                              {getPurityLabel(z.purity) +
                                " · " +
                                z.quantity +
                                "개 · 보증서(" +
                                (z.guaranteeImageUrl ? "유" : "무") +
                                ") " +
                                parseFloat(z.gram).toFixed(4) +
                                "g = " +
                                numFormat(
                                  z.gram *
                                    (z.appraisalProduct.type === "GOLD"
                                      ? goldGramPrice
                                      : silverGramPrice)
                                ) +
                                "원"}
                            </span>
                          );
                        })}
                  </span>
                );
              }
            },
          },
          {
            label: "수량",
            render: (x) => {
              if (x.appraisalSimples) {
                return x.appraisalSimples.length || "-";
              } else {
                return "-";
              }
            },
          },
          {
            label: "고객",
            render: (x) => {
              if (x.user) {
                return (
                  <span>
                    {x.user.name}
                    <br />
                    {formatPhone(x.user.phone)}
                  </span>
                );
              } else {
                return "-";
              }
            },
          },
          {
            label: "고객번호",
            render: (x) => {
              if (x.user) {
                return <span>{x.user.id}</span>;
              } else {
                return "-";
              }
            },
          },
          { label: "접수 방법", key: "method" },
          { label: "진행상태", key: "status" },
          {
            label: "예약일시",
            render: (x) => {
              if (x.method === "택배") {
                return (
                  <span>
                    {x.collectDate}
                    <br />
                    {x.collectTime}
                  </span>
                );
              } else if (x.method === "내방") {
                return (
                  <span>
                    {x.visitDate}
                    <br />
                    {x.visitTime}
                  </span>
                );
              } else {
                return "-";
              }
            },
          },
          {
            label: "최종 처리일시",
            render: (x) => {
              if (
                x.appraisalRequestStatus &&
                x.appraisalRequestStatus.filter((y) => y.status === x.status)[0]
              ) {
                return moment(
                  x.appraisalRequestStatus.filter(
                    (y) => y.status === x.status
                  )[0].createdAt
                ).format("YYYY.MM.DD HH:mm");
              } else {
                return "-";
              }
            },
          },
          {
            label: "미처리",
            key: "adminStatus",
            render: (x) => {
              return getNoProcessLabel({
                status: x?.appraisalRequestStatus,
                method: x?.method,
                approvedAppraise: x?.approvedAppraise,
              });
            },
          },

          /*
          {
            label: "재고상태",
            key: "stockStatus",
            render: (x) => {
              if (
                x.appraisalRequestStatus &&
                x.appraisalRequestStatus.filter(
                  (x) => x.status === "교환완료"
                )[0]
              ) {
                return x.stockStatus;
              } else {
                return "-";
              }
            },
          },*/
        ]}
        // onExcelDownload={() => {
        //   fetchList("excel");
        // }}
        onClick={(item) => {
          history.push(routes.appraisalStatusDetail, {
            id: item.id,
          });
        }}
      />

      {/* <DataTable
        hideLabel
        labelComponent={<span>미처리 현황</span>}
        data={unprocessedList.list}
        totalCount={unprocessedList.total}
        columns={[
          { label: "접수번호" },
          { label: "요청일" },
          { label: "분류" },
          { label: "종류" },
          { label: "감정 정보" },
          { label: "수량" },
          { label: "고객" },
          { label: "접수 방법" },
          { label: "진행상태" },
          { label: "예약일시" },
          { label: "요청/처리" },
          { label: "최종 처리일시" },
        ]}
        onExcelDownload={() => {}}
      /> */}
    </>
  );
}

const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 30,
    marginBottom: 10,
  },
  table1: {
    width: "80%",
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
});
