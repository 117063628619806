import { makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function PasswordChange(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [pwdCheckSwitch, setpwdCheckSwitch] = useState(true);
  const history = useHistory();

  const onChangeValue = ({ target }) => {
    const { value } = target;
    setPasswordCheck(value);
    if (password !== value) {
      setpwdCheckSwitch(false);
    } else {
      setpwdCheckSwitch(true);
      setPasswordCheck(value);
    }
  };

  const handleModifyPassword = () => {
    if (!password) {
      dispatch(actionError("새 비밀번호를 입력해 주세요."));
    } else if (!passwordCheck) {
      dispatch(actionError("비밀번호 확인을 입력해 주세요."));
    } else if (pwdCheckSwitch === false) {
      dispatch(
        actionError("새 비밀번호와 비밀번호 확인이 \n 일치하지 않습니다.")
      );
    } else {
      dispatch(loadingStart);
      APIS.putModifyConfirmPassword({
        newPassword: password,
        confirmPassword: passwordCheck,
      })
        .then(({ data: { success, message } }) => {
          if (success) {
            localStorage.removeItem("passwordVerification");
            localStorage.removeItem(STORAGE_KEY.accessToken);
            dispatch(
              actionOpen(
                "비밀번호가 변경되었습니다.",
                () => {
                  history.go(routes.login);
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .catch(() => {
          dispatch(actionError("비밀번호 변경에 실패하셨습니다."));
        })
        .finally(() => dispatch(loadingEnd));
    }
  };
  return (
    <BasicLayout center className={classes.root}>
      <img src={images.banner} className={classes.banner} />
      <Paper className={classes.main}>
        <img src={images.logo} className={classes.logo} />
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          비밀번호 변경
        </Text>
        <Flex className={classes.formNewPassword} row>
          <Flex className={classes.formInput}>
            <input
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className={classes.input}
              placeholder="새 비밀번호"
            />
          </Flex>
        </Flex>
        <Flex className={classes.form} row>
          <Flex className={classes.formInput}>
            <input
              autoComplete="new-password"
              onChange={(e) => {
                onChangeValue(e);
              }}
              type="password"
              className={pwdCheckSwitch ? classes.input : classes.inputWarning}
              placeholder="비밀번호 확인"
              onEnter={handleModifyPassword}
            />
          </Flex>
        </Flex>
        {pwdCheckSwitch ? null : (
          <div className={classes.validationError}>
            비밀번호가 일치하지 않습니다. 다시 확인해주세요.
          </div>
        )}
        <Button
          onClick={handleModifyPassword}
          className={classes.button}
          label="비밀번호 변경하기"
        ></Button>
      </Paper>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
  },
  label: {
    color: colors.black,
    fontSize: "24px",
    marginTop: 10,
    textAlign: "center",
  },
  input: {
    marginTop: "10px",
    backgroundColor: "#f2f2f2",
    height: "40px",
    alignSelf: "stretch",
    borderRadius: "3px",
    border: "1px solid rgba(0,0,0,0.23)",
    color: "black",
    padding: "0px 12px",
  },
  inputWarning: {
    height: "40px",
    marginTop: "10px",
    display: "inline-block",
    color: "#e52528",
    border: "2px solid #e52528",
    backgroundColor: "#f2f2f2",
    transition: "all 0.5s",
    borderRadius: "3px",
    padding: "0px 12px",
  },

  formNewPassword: {
    margin: "0px 30px",
    marginTop: "50px",
    alignItems: "center",
    alignSelf: "stretch",
  },
  form: {
    margin: "0px 30px",
    // marginTop: "50px",
    alignItems: "center",
    alignSelf: "stretch",
  },
  formInput: { flex: 1 },
  button: {
    marginBottom: 50,
    marginTop: "30px",
    alignSelf: "stretch",
    backgroundColor: colors.primary,
    margin: "0px 30px",
  },
  banner: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  main: {
    backgroundColor: "#fff",
    padding: "60px 50px",
    boxSizing: "border-box",
    zIndex: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 600,
  },
  logo: {
    width: 240,
    height: 40,
    objectFit: "contain",
  },
  validationError: {
    color: "#e52528",
    justifySelf: "left",
  },
});
