import { Button, Checkbox, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function SettingTermPolicy({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();

  const [terms, setTerms] = useState([]);

  useEffect(() => {
    dispatch(loadingStart);

    APIS.getTerms()
      .then(({ data: { success, data, message } }) => {
        success &&
          setTerms(
            data.map((term) => ({
              ...term,
              required: term.required ? "필수" : "선택",
              signup: term.signup ? "여" : "부",
            }))
          );
      })
      .finally(() => dispatch(loadingEnd));
  }, []);
  return (
    <>
      <PageLabel
      // add={{
      //   title: "등록하기",
      //   onClick: () => {
      //     history.push(routes.serviceReviewDetail);
      //   },
      // }}
      >
        서비스 이용약관
      </PageLabel>

      {/* <Flex row className={classes.root}>
        <select
          style={{
            alignSelf: "start",
          }}
        >
          <option label="전체" />
          <option label="서비스 이용약관" />
          <option label="개인정보 수집및 동의" />
          <option label="마케팅 활용 동의" />
        </select>
        <Button variant="outlined" style={{ padding: 10, width: 150 }}>
          삭제
        </Button>
      </Flex> */}

      <SimpleTable
        fullWidth
        onClick={({ type }) => {
          history.push(routes.settingPolicyDetail, { type });
        }}
        data={terms}
        columns={[
          { label: <Checkbox />, render: (x) => <Checkbox /> },
          { label: "약관명", key: "title" },
          { label: "시행일" },
          { label: "등록일" },
          { label: "가입조건 여부", key: "signup" },
          { label: "필수여부", key: "required" },
        ]}
      />
    </>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    margin: "20px 50px",
    marginBottom: 0,
    justifyContent: "space-between",
  },
});
