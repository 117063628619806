import React, { useState, useEffect } from "react";
import Flex from "../../components/flex/Flex";
import KendoButton from "../../components/button/KendoButton";
import styled from "styled-components";
import withProgressBar from "../../hoc/withProgressBar";
import SearchLayout, { SearchItem } from "../../layouts/search/SearchLayout";
import DateInput from "../../components/input/DateInput";
import TextInput from "../../components/input/TextInput";
import DropDownList from "../../components/select/DropDownList";
import { GridColumn } from "@progress/kendo-react-grid";
import GridTable, { CenterCell } from "../../components/gird-table/GridTable";
import { RadioGroup, Checkbox, TextArea } from "@progress/kendo-react-inputs";
import cloneDeep from "lodash.clonedeep";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { objToQueryStr } from "../../services/utils";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import StyledTable from "../../components/table/StyledTable";
import { formatTime } from "../../libs/utils";
import SmallTitle from "../../components/title/SmallTitle";

const SELECTED_FIELD = "selected";
const INIT_SEARCH_STATE = {
  subject: "",
  content: "",
  status: "",
  createdBy: "",
};
const PopupList = withProgressBar(({ progressBar, tab }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const { page, size = 100 } = locationState;
  const [checkList, setCheckList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [searchState, setSearchState] = useState(INIT_SEARCH_STATE);
  const [total, setTotal] = useState("");

  useEffect(() => {
    if (tab === "3" || locationState.page || locationState.page === 0) {
      fetchList({ ...locationState, size });
    }
  }, [locationState]);

  const { subject, content, status, createdBy } = searchState;

  const handleChangeSearchState = (newState) => {
    setSearchState({ ...searchState, ...newState });
  };

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleClickSearch = () => {
    handleChangeLocationState({ ...searchState, page: 0 });
  };

  const fetchList = (param) => {
    const paramQuery = { category: "POPUP", ...param };
    delete paramQuery["tab"];
    progressBar.on();
    APIS.getPopupList(objToQueryStr(paramQuery))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setTotal(data.totalElements);
          setPopupList(
            data.content.map((item, index) => {
              return {
                ...item,
                no: data.totalElements - (page * size + index),
                status: item.status
                  .replace("ACTIVE", "활성")
                  .replace("BLIND", "비활성"),
              };
            })
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  };
  return (
    <PopupContainer>
      <ButtonList
        fetchList={fetchList}
        checkList={checkList}
        locationState={locationState}
        size={size}
      />
      <SearchLayout
        title="팝업 조회"
        onSearch={handleClickSearch}
        onReset={() => handleChangeSearchState(INIT_SEARCH_STATE)}
      >
        <Flex row>
          <SearchItem header="제목">
            <TextInput
              value={subject}
              onChange={(subject) => handleChangeSearchState({ subject })}
            />
          </SearchItem>
          <SearchItem header="내용">
            <TextInput
              value={content}
              onChange={(content) => handleChangeSearchState({ content })}
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="작성자">
            <TextInput
              value={createdBy}
              onChange={(createdBy) => handleChangeSearchState({ createdBy })}
            />
          </SearchItem>
          <SearchItem header="상태">
            <DropDownList
              useAll
              options={[
                { label: "활성", value: "ACTIVE" },
                { label: "비활성", value: "BLIND" },
              ]}
              selected={status}
              onChange={(status) => handleChangeSearchState({ status })}
            />
          </SearchItem>
        </Flex>
      </SearchLayout>
      <GridTable
        className="grid"
        style={{ height: 550 }}
        data={popupList}
        skip={page * size}
        take={size}
        total={total}
        selectedField={SELECTED_FIELD}
        scrollable
        name={"PopupList"}
        reorderable
        pageable
        selectable={{
          enabled: true,
        }}
        onPageChange={({ page: { skip, take: size } }) => {
          const page = skip / size;
          if (locationState.page !== page || locationState.size !== size) {
            handleChangeLocationState({ page, size });
          }
        }}
        onRowClick={(e) => {
          dispatch(
            openModal({
              visiable: true,
              children: (
                <PopupModal
                  fetchList={fetchList}
                  id={e.dataItem.id}
                  locationState={locationState}
                  size={size}
                />
              ),
            })
          );
        }}
      >
        <GridColumn
          title=""
          field={SELECTED_FIELD}
          headerCell={() => {
            return (
              <div style={{ textAlign: "center" }}>
                <Checkbox
                  checked={
                    popupList.length === checkList.length &&
                    popupList.length !== 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    const { ariaChecked } = e.syntheticEvent.target;
                    if (ariaChecked === "true") {
                      setCheckList([]);
                    } else {
                      setCheckList([...popupList]);
                    }
                  }}
                />
              </div>
            );
          }}
          cell={(props) => {
            const { dataItem } = props;
            const data = dataItem["id"];
            const idList = checkList.map((item) => item.id);
            return (
              <td style={{ textAlign: "center" }}>
                <Checkbox
                  checked={idList.includes(data) ? true : false}
                  onChange={() => {
                    let parseList = cloneDeep(checkList);
                    if (idList.includes(data)) {
                      const result = parseList.filter((item) => {
                        item.id !== data;
                      });
                      setCheckList(result);
                    } else {
                      parseList.push(dataItem);
                      setCheckList(parseList);
                    }
                  }}
                />
              </td>
            );
          }}
        />
        <GridColumn title="No" field="no" width={50} cell={CenterCell} />
        <GridColumn title="상태" field="status" width={100} cell={CenterCell} />
        <GridColumn title="ID" field="id" width={100} cell={CenterCell} />
        <GridColumn
          title="시작일"
          field="startDate"
          width={100}
          cell={CenterCell}
        />
        <GridColumn
          title="종료일"
          field="endDate"
          width={100}
          cell={CenterCell}
        />
        <GridColumn
          title="팝업기간"
          field="popupPeriod"
          width={100}
          cell={CenterCell}
        />
        <GridColumn title="제목" field="subject" />
        <GridColumn
          title="작성자"
          field="createdBy"
          width={200}
          cell={CenterCell}
        />
      </GridTable>
    </PopupContainer>
  );
});

const ButtonList = ({ fetchList, checkList, locationState, size }) => {
  const dispatch = useDispatch();
  const validationData = () => {
    if (checkList.length === 0) {
      return "목록을 선택해주세요.";
    }
    return "";
  };

  const handleChangeStatus = (value) => {
    if (checkList.length) {
      const data = { ids: checkList.map((item) => item.id), status: value };
      APIS.patchStatusPopup(data).then(
        ({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(
              actionOpen("상태를 바꾸지 못하였습니다", null, null, null, true)
            );
          } else {
            dispatch(
              actionOpen(
                "해당 아이템의 상태를 바꾸었습니다",
                () => {
                  fetchList({ ...locationState, size });
                },
                null,
                null,
                true
              )
            );
          }
        }
      );
    } else {
      const errorMessage = validationData();
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }
  };

  return (
    <Flex row style={{ justifyContent: "flex-end", marginTop: 5 }}>
      <KendoButton
        label="활성화"
        style={{ marginRight: 7 }}
        onClick={() => handleChangeStatus("ACTIVE")}
      />
      <KendoButton
        label="블라인드"
        style={{ marginRight: 7 }}
        onClick={() => handleChangeStatus("BLIND")}
      />
      <KendoButton
        label="등록"
        onClick={() => {
          dispatch(
            openModal({
              visiable: true,
              children: (
                <PopupModal
                  fetchList={fetchList}
                  locationState={locationState}
                  size={size}
                />
              ),
            })
          );
        }}
      />
    </Flex>
  );
};
export default PopupList;

const PopupModal = withProgressBar(
  ({ progressBar, fetchList, id = "", locationState, size }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [stopPeriod, setStopPeriod] = useState("");
    const [status, setStatus] = useState("ACTIVE");

    useEffect(() => {
      if (id) {
        getDetail();
      }
    }, [id]);

    const getDetail = () => {
      progressBar.on();
      APIS.getPopupDetail(id)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            const {
              startDate,
              endDate,
              subject,
              content,
              popupPeriod,
              status,
            } = data;
            setStartDate(startDate);
            setEndDate(endDate);
            setSubject(subject);
            setContent(content);
            setStopPeriod(popupPeriod);
            setStatus(status);
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(progressBar.off);
    };
    const handleClickSave = () => {
      if (
        startDate === "" ||
        endDate === "" ||
        subject === "" ||
        content === "" ||
        stopPeriod === ""
      ) {
        dispatch(actionError("값을 입력하여 주세요"));
      } else {
        const data = {
          category: "POPUP",
          popupPeriod: stopPeriod,
          startDate: startDate,
          endDate: endDate,
          subject: subject,
          content: content,
          status: status,
        };
        progressBar.on();
        {
          id
            ? APIS.patchEditPopup(id, data)
                .then(({ data: { success, message } }) => {
                  if (success) {
                    dispatch(
                      actionOpen(
                        "해당팝업을 수정하셨습니다.",
                        () => {
                          fetchList({ ...locationState, size });
                        },
                        null,
                        null,
                        true
                      )
                    );
                    dispatch(closeModal());
                  } else {
                    dispatch(actionError(message));
                  }
                })
                .finally(progressBar.off)
            : APIS.postRegisterPopup(data)
                .then(({ data: { success, message } }) => {
                  if (success) {
                    dispatch(
                      actionOpen(
                        "저장이 완료되었습니다.",
                        () => {
                          fetchList({ ...locationState, size });
                        },
                        null,
                        null,
                        true
                      )
                    );
                    dispatch(closeModal());
                  } else {
                    dispatch(actionError(message));
                  }
                })
                .finally(progressBar.off);
        }
      }
    };

    return (
      <div>
        <SmallTitle>{id ? "팝업 수정" : "팝업 등록"} </SmallTitle>
        <StyledTable style={{ width: 500 }}>
          <Flex row>
            <Flex className="header" style={{ width: 150 }}>
              사용기간
            </Flex>
            <Flex className="data">
              <DateInput
                onChange={(value) => setStartDate(value)}
                value={startDate}
                max={formatTime()}
              />
              ~
              <DateInput
                onChange={(value) => setEndDate(value)}
                value={endDate}
                min={startDate}
              />
            </Flex>
          </Flex>
          <Flex row>
            <Flex className="header" style={{ width: 150 }}>
              제목
            </Flex>
            <Flex className="data">
              <TextInput
                onChange={(value) => setSubject(value)}
                value={subject}
              />
            </Flex>
          </Flex>
          <Flex row>
            <Flex className="header" style={{ width: 150 }}>
              내용
            </Flex>
            <Flex className="data">
              <TextArea
                value={content}
                onChange={(e) => {
                  setContent(e.value);
                }}
                rows={5}
              />
            </Flex>
          </Flex>
          <Flex row>
            <Flex className="header" style={{ width: 150 }}>
              미사용 기간 설정
            </Flex>
            <Flex className="data">
              <TextInput
                onChange={(value) => setStopPeriod(value)}
                value={stopPeriod}
                style={{ textAlign: "right", width: 80, marginRight: 3 }}
              />
              일
            </Flex>
          </Flex>
          <Flex row>
            <Flex className="header" style={{ width: 150 }}>
              상태
            </Flex>
            <Flex className="data">
              <RadioGroup
                data={[
                  {
                    label: "활성",
                    value: "ACTIVE",
                  },
                  {
                    label: "비활성",
                    value: "BLIND",
                  },
                ]}
                layout="horizontal"
                value={status}
                onChange={({ value }) => {
                  setStatus(value);
                }}
              />
            </Flex>
          </Flex>
        </StyledTable>
        <Flex style={{ alignItems: "flex-end" }}>
          <KendoButton
            label={id ? "수정" : "저장"}
            onClick={() => {
              handleClickSave();
            }}
          />
        </Flex>
      </div>
    );
  }
);

const PopupContainer = styled(Flex)`
  flex: 1;
  padding: 0 50px;
`;
