import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import DataTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import consts, { getDefaultPageSize, STORAGE_KEY } from "../../libs/consts";
import * as APIS from "../../libs/apis";
import { objToQueryStr, numFormat, formatPhone } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import Flex from "../../components/flex/Flex";
import { formatTime } from "../../libs/utils";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import { Info } from "@material-ui/icons";
import { openModal } from "../../redux/modal/ModalReducer";
import Button from "../../components/button/Button";

//랜더링 방지
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  const reMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (reMount.current) {
        func();
      } else {
        reMount.current = true;
      }
    } else {
      didMount.current = true;
    }
  }, deps);
};

// const preventRenderingEffect = (func, deps) => {
//   const didMount = useRef(false);
//   useEffect(() => {
//     if (didMount.current) {
//       func();
//     } else {
//       didMount.current = true;
//     }
//   }, deps);
// };

export default function DirectStatus({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const [summary, setSummary] = useState([]);
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const [progressStatusList, setProgressStatusList] = useState([]);
  const [searchReception, setSearchReception] = useState();

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const handleClickInfo = (type) => {
    dispatch(
      openModal({
        visiable: true,
        children: type === "filtersState" ? <InfoState /> : <InfoSummary />,
        maskClosable: true,
        // closable: false,
      })
    );
  };

  const summaryFetchList = (type) => {
    let statusData = "";

    if (type) {
      statusData = progressStatusList?.find((item) => item.status === type);
    }

    const progressParam = {
      page: state.page,
      size: state.size,
      status: type,
      statusLevel: statusData?.statusLevel,
    };
    delete progressParam["registerList"];
    delete progressParam["releaseList"];
    delete progressParam["saleList"];
    delete progressParam["registerTotal"];
    delete progressParam["releaseTotal"];
    delete progressParam["saleTotal"];

    const progressParamQuery = objToQueryStr(progressParam);
    const registerArr = [];
    const saleArr = [];
    const releaseArr = [];
    if (Object.keys(progressParam).length !== 0) {
      dispatch(loadingStart);
      APIS.getSafeTradeProductList(progressParamQuery)
        .then(
          ({
            data: {
              success,
              message,
              data: { registerPage, releasePage, sellPage, statusLevel },
            },
          }) => {
            if (success) {
              statusLevel === "REGISTER"
                ? history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerPage?.content,
                      saleList: sellPage?.content,
                      releaseList: releasePage.content,
                      registerTotal: registerPage?.totalElements,
                      saleTotal: sellPage?.totalElements,
                      releaseTotal: releasePage.totalElements,
                    },
                  })
                : statusLevel === "SALE"
                ? history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerPage?.content,
                      saleList: sellPage?.content,
                      releaseList: releasePage.content,
                      registerTotal: registerPage?.totalElements,
                      saleTotal: sellPage?.totalElements,
                      releaseTotal: releasePage.totalElements,
                    },
                  })
                : statusLevel === "RELEASE"
                ? history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerPage?.content,
                      saleList: sellPage?.content,
                      releaseList: releasePage.content,
                      registerTotal: registerPage?.totalElements,
                      saleTotal: sellPage?.totalElements,
                      releaseTotal: releasePage.totalElements,
                    },
                  })
                : (registerPage?.content.find((item) => {
                    progressStatusList.forEach((progressStatus) => {
                      if (progressStatus.status === item.status) {
                        if (progressStatus.statusLevel === "REGISTER") {
                          registerArr.push(item);
                        }
                      }
                    });
                  }),
                  sellPage?.content.find((item) => {
                    progressStatusList.forEach((progressStatus) => {
                      if (progressStatus.status === item.status) {
                        if (progressStatus.statusLevel === "SALE") {
                          saleArr.push(item);
                        }
                      }
                    });
                  }),
                  releasePage?.content.find((item) => {
                    progressStatusList.forEach((progressStatus) => {
                      if (progressStatus.status === item.status) {
                        if (progressStatus.statusLevel === "RELEASE") {
                          releaseArr.push(item);
                        }
                      }
                    });
                  }),
                  history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerArr,
                      saleList: saleArr,
                      releaseList: releaseArr,
                      registerTotal: registerArr.length || "0",
                      saleTotal: saleArr.length || "0",
                      releaseTotal: releaseArr.length || "0",
                    },
                  }));
              setSearchReception(type);
            } else {
              dispatch(actionError(message));
            }
          }
        )
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };
  const fetchList = () => {
    let statusData = "";

    if (state.status) {
      statusData = progressStatusList?.find(
        (item) => item.status === state.status
      );
    } else {
      statusData = state.tab;
    }

    const progressParam = {
      ...state,
      page: state.page,
      size: state.size,
      statusLevel: state.status ? statusData?.statusLevel : statusData,
    };

    delete progressParam["registerList"];
    delete progressParam["releaseList"];
    delete progressParam["saleList"];
    delete progressParam["registerTotal"];
    delete progressParam["releaseTotal"];
    delete progressParam["saleTotal"];

    const progressParamQuery = objToQueryStr(progressParam);
    const registerArr = [];
    const saleArr = [];
    const releaseArr = [];
    if (Object.keys(progressParam).length !== 0) {
      dispatch(loadingStart);
      APIS.getSafeTradeProductList(progressParamQuery)
        .then(
          ({
            data: {
              success,
              message,
              data: { registerPage, releasePage, sellPage, statusLevel },
            },
          }) => {
            if (success) {
              statusLevel === "REGISTER"
                ? history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerPage?.content,
                      saleList: sellPage?.content,
                      releaseList: releasePage.content,
                      registerTotal: registerPage?.totalElements,
                      saleTotal: sellPage?.totalElements,
                      releaseTotal: releasePage.totalElements,
                    },
                  })
                : statusLevel === "SALE"
                ? history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerPage?.content,
                      saleList: sellPage?.content,
                      releaseList: releasePage.content,
                      registerTotal: registerPage?.totalElements,
                      saleTotal: sellPage?.totalElements,
                      releaseTotal: releasePage.totalElements,
                    },
                  })
                : statusLevel === "RELEASE"
                ? history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerPage?.content,
                      saleList: sellPage?.content,
                      releaseList: releasePage.content,
                      registerTotal: registerPage?.totalElements,
                      saleTotal: sellPage?.totalElements,
                      releaseTotal: releasePage.totalElements,
                    },
                  })
                : (registerPage?.content.find((item) => {
                    progressStatusList.forEach((progressStatus) => {
                      if (progressStatus.status === item.status) {
                        if (progressStatus.statusLevel === "REGISTER") {
                          registerArr.push(item);
                        }
                      }
                    });
                  }),
                  sellPage?.content.find((item) => {
                    progressStatusList.forEach((progressStatus) => {
                      if (progressStatus.status === item.status) {
                        if (progressStatus.statusLevel === "SALE") {
                          saleArr.push(item);
                        }
                      }
                    });
                  }),
                  releasePage?.content.find((item) => {
                    progressStatusList.forEach((progressStatus) => {
                      if (progressStatus.status === item.status) {
                        if (progressStatus.statusLevel === "RELEASE") {
                          releaseArr.push(item);
                        }
                      }
                    });
                  }),
                  history.replace({
                    pathname: location.pathname,
                    state: {
                      ...location.state,
                      tab: statusLevel,
                      registerList: registerArr,
                      saleList: saleArr,
                      releaseList: releaseArr,
                      registerTotal: registerArr.length || "0",
                      saleTotal: saleArr.length || "0",
                      releaseTotal: releaseArr.length || "0",
                    },
                  }));
              setSearchReception();
            } else {
              dispatch(actionError(message));
            }
          }
        )
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  useEffect(() => {
    // Filters 카테고리 조회
    dispatch(loadingStart);
    APIS.getSafeTradeFitersList()
      .then(
        ({
          data: {
            success,
            message,
            data: { categoryList, statusList },
          },
        }) => {
          if (success) {
            setCategory([
              ...categoryList.map(({ categoryId, name }) => ({
                label: name,
                value: categoryId,
              })),
            ]);
            setProgressStatusList(statusList);
            const searchTypeList = statusList.map((item) => {
              return { statusLevel: item.statusLevel };
            });
            const deduplicationType = searchTypeList.filter(
              (dayName, idx, arr) => {
                return (
                  arr.findIndex(
                    (item) => item.statusLevel === dayName.statusLevel
                  ) === idx
                );
              }
            );
            let parseData = {};
            let arrayData = [];

            deduplicationType.forEach((type) => {
              statusList.forEach((item) => {
                if (item.statusLevel === type.statusLevel) {
                  arrayData.push(item);
                  parseData[type.statusLevel] = [...arrayData];
                }
              });
              arrayData = [];
            });
            setStatus(parseData);
          } else {
            dispatch(actionError(message));
          }
        }
      )
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);

  useEffect(() => {
    // 접수현황 summary 조회
    if (state.page || state.size) {
      dispatch(loadingStart);
      APIS.getSafeTradeSummary()
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setSummary(data);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => dispatch(loadingEnd));
      if (state.status !== "") {
        fetchList();
      }
    }
  }, [state.page, state.size]);

  useDidMountEffect(() => {
    if (
      // registerList?.length > 0 ||
      // saleList?.length > 0 ||
      // releaseList?.length > 0
      state?.registerList ||
      state?.saleList ||
      state?.releaseList
    ) {
      if (!searchReception) {
        fetchList();
      } else {
        summaryFetchList(searchReception);
      }
    }
  }, [state.page, state.size]);

  useDidMountEffect(() => {
    if (state.statusLevel) {
      handleValueChange("status", status[`${state.statusLevel}`]?.[0].status);
    }
  }, [state.statusLevel]);

  return (
    <>
      {isKorda ? (
        <>
          <Flex row>
            <span className={classes.label}>판매신청 접수현황</span>
            <Button
              onClick={() => {
                handleClickInfo();
              }}
              label="설명"
              className={classes.labelIcon}
              style={{ width: "70px" }}
            />
          </Flex>
          <table cellPadding={0} cellSpacing={0} className={classes.table}>
            <thead>
              <tr>
                <th></th>
                <th>접수대기</th>
                <th>접수완료</th>
                <th>감정 대기 중</th>
                <th>감정 완료</th>
                <th>판매 요청 대기</th>
                <th>판매대기</th>
                <th>판매 중</th>
                <th>결제 대기 중</th>
                <th>결제 완료</th>
                <th>출고중</th>
                <th>인도 대기</th>
                <th>반송 중</th>
                <th>구매 취소 본사 반송</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ backgroundColor: "#f5f5f5" }}>건수</td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_WAIT");
                      summaryFetchList("INBOUND_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_COMPLETE");
                      summaryFetchList("INBOUND_COMPLETE");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_COMPLETE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("VERIFY_WAIT");
                      summaryFetchList("VERIFY_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "VERIFY_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("VERIFY_COMPLETE");
                      summaryFetchList("VERIFY_COMPLETE");
                    }}
                  >
                    {summary?.find((item) => item.status === "VERIFY_COMPLETE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL_CONFIRM_WAIT");
                      summaryFetchList("SELL_CONFIRM_WAIT");
                    }}
                  >
                    {summary?.find(
                      (item) => item.status === "SELL_CONFIRM_WAIT"
                    )?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL_WAIT");
                      summaryFetchList("SELL_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "SELL_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL");
                      summaryFetchList("SELL");
                    }}
                  >
                    {summary?.find((item) => item.status === "SELL")?.count ||
                      0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("PAYMENT_WAIT");
                      summaryFetchList("PAYMENT_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "PAYMENT_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("PAYMENT_DONE");
                      summaryFetchList("PAYMENT_DONE");
                    }}
                  >
                    {summary?.find((item) => item.status === "PAYMENT_DONE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("DELIVERY");
                      summaryFetchList("DELIVERY");
                    }}
                  >
                    {summary?.find((item) => item.status === "DELIVERY")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_WAIT");
                      summaryFetchList("TRANSFER_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("RETURN");
                      summaryFetchList("RETURN");
                    }}
                  >
                    {summary?.find((item) => item.status === "RETURN")?.count ||
                      0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_RETURN");
                      summaryFetchList("TRANSFER_RETURN");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_RETURN")
                      ?.count || 0}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <Flex row>
            <span className={classes.label}>판매신청 접수현황</span>
            <Button
              onClick={() => {
                handleClickInfo();
              }}
              label="설명"
              className={classes.labelIcon}
              style={{ width: "70px" }}
            />
          </Flex>
          <table cellPadding={0} cellSpacing={0} className={classes.table}>
            <thead>
              <tr>
                <th></th>
                <th>접수대기</th>
                <th>판매 중</th>
                <th>출고 중</th>
                <th>인도 대기</th>
                <th>구매자 구매 취소</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ backgroundColor: "#f5f5f5" }}>건수</td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_WAIT");
                      summaryFetchList("INBOUND_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL");
                      summaryFetchList("SELL");
                    }}
                  >
                    {summary?.find((item) => item.status === "SELL")?.count ||
                      0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("DELIVERY");
                      summaryFetchList("DELIVERY");
                    }}
                  >
                    {summary?.find((item) => item.status === "DELIVERY")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_WAIT");
                      summaryFetchList("TRANSFER_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_CANCEL");
                      summaryFetchList("TRANSFER_CANCEL");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_CANCEL")
                      ?.count || 0}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <span className={classes.label}>접수 관리</span>

      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          total: 0,
          startDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          dateType: "CREATED",
          tab: "REGISTER",
          numberType: "ORDER_ID",
          userType: "ALL",
          shopType: "ALL",
          numberKeyword: "",
          categoryId: "",
          name: "",
          inventoryLocation: "",
          userKeyword: "",
          shopKeyword: "",
          statusLevel: "",
          status: "",
        }}
        changeStyleValue={classes.changeStyleValue}
        onSearch={fetchList}
        data={[
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}
                >
                  <option value="CREATED">{"요청일"}</option>
                  <option value="UPDATED">{"수정일"}</option>
                </select>
                <input
                  value={state?.startDate || ""}
                  onChange={(e) => {
                    handleValueChange("startDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            label: "번호검색",
            type: "render",
            render: (
              <Flex row style={{ padding: "0px" }}>
                <select
                  value={state?.numberType}
                  className={classes.selectBoxHeader}
                  onChange={(e) => {
                    handleValueChange("numberType", e.target.value);
                  }}
                >
                  <option value="ORDER_ID">{"접수 번호"}</option>
                  <option value="ITEM_ID">{"판매 번호"}</option>
                  <option value="OUTBOUND_ORDER_BOOK_ID">{"구매 번호"}</option>
                </select>
                <input
                  value={state?.numberKeyword}
                  onChange={(e) => {
                    handleValueChange("numberKeyword", e.target.value);
                  }}
                  placeholder={"접수번호, 판매번호, 구매번호"}
                  style={{ width: 350 }}
                />
              </Flex>
            ),
          },
          {
            label: "회원검색",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.userType}
                  className={classes.selectBoxHeader}
                  onChange={(e) => {
                    handleValueChange("userType", e.target.value);
                  }}
                >
                  <option value="ALL">{"전체"}</option>
                  <option value="SELLER">{"판매자"}</option>
                  <option value="BUYER">{"구매자"}</option>
                </select>
                <input
                  value={state?.userKeyword}
                  onChange={(e) => {
                    handleValueChange("userKeyword", e.target.value);
                  }}
                  placeholder={"고객명, 연락처, 고객번호"}
                  style={{ width: 350 }}
                />
              </Flex>
            ),
          },
          {
            label: "카테고리",
            // // type: "menu",
            // // data: category,
            // key: "categoryId",
            type: "render",
            render: (
              <Flex>
                <select
                  value={state?.categoryId}
                  className={classes.selectBoxHeader}
                  onChange={(e) => {
                    handleValueChange("categoryId", e.target.value);
                  }}
                >
                  <option value="">{"전체"}</option>
                  {category?.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}

                  {/* <option value="RECOMMEND_SHOP">{"추천매장"}</option>
                  <option value="SELL_SHOP">{"접수매장"}</option>
                  <option value="BUY_SHOP">{"출고매장"}</option> */}
                </select>
              </Flex>
            ),
          },
          {
            label: "상품검색",
            // placeholder: "상품명",
            // type: "input",
            // key: "name",
            type: "render",
            render: (
              <Flex>
                <input
                  value={state?.name}
                  onChange={(e) => {
                    handleValueChange("name", e.target.value);
                  }}
                  placeholder={"상품명"}
                  style={{ width: 517, height: 45 }}
                  autoFocus
                />
              </Flex>
            ),
          },
          {
            label: "진행상태",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.statusLevel}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("statusLevel", e.target.value);
                  }}
                >
                  <option value="">{"전체"}</option>
                  {Object.keys(status)?.map((type, index) => {
                    return (
                      <option value={type} key={index}>
                        {type === "REGISTER"
                          ? "판매 요청 대기"
                          : type === "SALE"
                          ? "판매 중"
                          : type === "RELEASE"
                          ? "출고 및 판매 반송"
                          : ""}
                      </option>
                    );
                  })}
                </select>
                <select
                  value={state?.status}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("status", e.target.value);
                  }}
                >
                  {status[`${state.statusLevel}`]?.map((item) => {
                    return (
                      item.statusLevel === state.statusLevel && (
                        <option value={item.status}>{item.description}</option>
                      )
                    );
                  })}
                </select>
                <Button
                  onClick={() => {
                    handleClickInfo("filtersState");
                  }}
                  label="설명"
                  className={classes.filtersInfoBtn}
                  style={{ width: "70px" }}
                />
              </Flex>
            ),
          },
          isKorda && {
            label: "매장검색",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.shopType}
                  className={classes.selectBoxHeader}
                  onChange={(e) => {
                    handleValueChange("shopType", e.target.value);
                  }}
                >
                  <option value="ALL">{"전체"}</option>
                  <option value="RECOMMEND_SHOP">{"추천매장"}</option>
                  <option value="SELL_SHOP">{"접수매장"}</option>
                  <option value="BUY_SHOP">{"출고매장"}</option>
                </select>
                <input
                  value={state?.shopKeyword}
                  onChange={(e) => {
                    handleValueChange("shopKeyword", e.target.value);
                  }}
                  placeholder={"매장명"}
                  style={{ width: 350 }}
                />
              </Flex>
            ),
          },
          isKorda && {
            label: "재고위치",
            // placeholder: "재고위치",
            // type: "input",
            // key: "inventoryLocation",
            type: "render",
            render: (
              <Flex>
                <input
                  value={state?.inventoryLocation}
                  onChange={(e) => {
                    handleValueChange("inventoryLocation", e.target.value);
                  }}
                  placeholder={"재고위치"}
                  style={{ width: 517, height: 45 }}
                />
              </Flex>
            ),
          },
        ]}
      />
      <LabelTabs
        data={[
          {
            label: "판매 요청 대기",
            value: "REGISTER",
            totalCount: `${
              state?.saleTotal ||
              state?.saleTotal === 0 ||
              state?.releaseTotal ||
              state?.releaseTotal === 0
                ? state?.registerTotal || 0
                : " - "
            }`,
            // disabled: `${state.status !== "" && state.tab !== "REGISTER"}`,
          },
          {
            label: "판매 중",
            value: "SALE",
            totalCount: `${
              state?.registerTotal ||
              state?.registerTotal === 0 ||
              state?.releaseTotal ||
              state?.releaseTotal === 0
                ? state?.saleTotal || 0
                : " - "
            }`,
            // disabled: `${state.status !== "" && state.tab !== "SALE"}`,
          },
          {
            label: "출고 및 판매 반송",
            value: "RELEASE",
            totalCount: `${
              state?.registerTotal ||
              state?.registerTotal === 0 ||
              state?.saleTotal ||
              state?.saleTotal === 0
                ? state?.releaseTotal || 0
                : " - "
            }`,
            // disabled: `${state.status !== "" && state.tab !== "RELEASE"}`,
          },
        ]}
      />
      <DataTable
        data={
          state.tab === "REGISTER"
            ? state?.registerList
            : state.tab === "SALE"
            ? state?.saleList
            : state.tab === "RELEASE"
            ? state?.releaseList
            : []
        }
        totalCount={
          state.tab === "REGISTER"
            ? state?.registerTotal
            : state.tab === "SALE"
            ? state?.saleTotal
            : state.tab === "RELEASE"
            ? state?.releaseTotal
            : state.total || "0"
        }
        onClick={(id) => {
          const itemId = id.itemId;
          history.push(`${routes.directStatusDetail}/${itemId}`, { itemId });
        }}
        rowClassName="pointer"
        columns={
          state.tab === "REGISTER"
            ? [
                {
                  label: "접수일",
                  key: "createdAt",
                  type: "datetime",
                },
                {
                  label: "접수번호",
                  key: "orderId",
                },
                {
                  label: "추천매장",
                  key: "sellerRecommendShop",
                },
                {
                  label: "고객명",
                  key: "seller",
                },
                {
                  label: "고객번호",
                  key: "sellerUserId",
                },
                {
                  label: "접수매장",
                  key: "sellShop",
                },
                {
                  label: "판매번호",
                  key: "itemId",
                },
                {
                  label: "카테고리",
                  key: "category",
                },
                {
                  label: "상품명",
                  key: "name",
                },
                {
                  label: "재고위치",
                  key: "inventoryLocation",
                },
                {
                  label: "접수 가격",
                  key: "orderPrice",
                  type: "number",
                },
                {
                  label: "감정가격",
                  key: "authPrice",
                  type: "number",
                },
                {
                  label: "진행상태",
                  key: "status",
                },
                {
                  label: "최종처리일시",
                  key: "updatedAt",
                  type: "datetime",
                },
              ]
            : state.tab === "SALE"
            ? [
                {
                  label: "접수일",
                  key: "createdAt",
                  type: "datetime",
                },
                {
                  label: "접수번호",
                  key: "orderId",
                },
                {
                  label: "추천매장",
                  key: "sellerRecommendShop",
                },
                {
                  label: "고객명",
                  key: "seller",
                },
                {
                  label: "고객번호",
                  key: "sellerUserId",
                },
                {
                  label: "접수매장",
                  key: "sellShop",
                },
                {
                  label: "판매번호",
                  key: "itemId",
                },
                {
                  label: "카테고리",
                  key: "category",
                },
                {
                  label: "상품명",
                  key: "name",
                },
                {
                  label: "재고위치",
                  key: "inventoryLocation",
                },
                {
                  label: "판매 가격",
                  key: "currentPrice",
                  type: "number",
                },
                {
                  label: "감정가격",
                  key: "authPrice",
                  type: "number",
                },
                {
                  label: "진행상태",
                  key: "status",
                },
                {
                  label: "최종처리일시",
                  key: "updatedAt",
                  type: "datetime",
                },
              ]
            : [
                {
                  label: "접수일",
                  key: "createdAt",
                  type: "datetime",
                },
                {
                  label: "구매번호",
                  key: "outBoundOrderBookId",
                },
                {
                  label: "추천매장",
                  key: "buyerRecommendShop",
                },
                {
                  label: "고객명",
                  key: "buyer",
                },
                {
                  label: "고객번호",
                  key: "buyerUserId",
                },
                {
                  label: "출고매장",
                  key: "buyShop",
                },
                {
                  label: "판매번호",
                  key: "itemId",
                },
                {
                  label: "카테고리",
                  key: "category",
                },
                {
                  label: "상품명",
                  key: "name",
                },
                {
                  label: "재고위치",
                  key: "inventoryLocation",
                },
                {
                  label: "예약일시",
                  key: "inStoreReserve",
                },
                {
                  label: "판매금액",
                  key: "currentPrice",
                  type: "number",
                },
                {
                  label: "진행상태",
                  key: "status",
                },
                {
                  label: "최종처리일시",
                  key: "updatedAt",
                  type: "datetime",
                },
              ]
        }
      />
    </>
  );
}
const InfoSummary = () => {
  const classes = useStyle();
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  return (
    <Flex
      style={{
        width: "850px",
        height: "320px",
        overflowY: "scroll",
        marginTop: 15,
      }}
    >
      <Flex className={classes.modal}>{"상태 설명"}</Flex>
      <Table>
        {isKorda ? (
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>접수 대기</TableCell>
              <TableCell className={classes.cellContent}>
                판매자가 판매 신청하고 매장 관리자의 상품 접수를 기다리는 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>접수 완료</TableCell>
              <TableCell className={classes.cellContent}>
                판매자가 접수 매장을 방문하고 매장에서 상품 접수를 확인한 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>감정 대기 중</TableCell>
              <TableCell className={classes.cellContent}>
                매장에서 판매자의 상품을 송부하여 본사 관리자가 수령한 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>감정 완료</TableCell>
              <TableCell className={classes.cellContent}>
                본사 관리자가 상품 감정을 마치고 판매자의 판매신청을 대기하는
                상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                판매 요청 대기
              </TableCell>
              <TableCell className={classes.cellContent}>
                판매자가 감정결과를 확인하고 판매신청을 한 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>판매 대기</TableCell>
              <TableCell className={classes.cellContent}>
                본사 관리자가 상품을 등록하고 판매 노출 대기 중인 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>판매 중</TableCell>
              <TableCell className={classes.cellContent}>
                상품목록에서 판매되고 있는 경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>결제 대기 중</TableCell>
              <TableCell className={classes.cellContent}>
                결제가 완료되지 않고 구매 과정에서 이탈하여 결제를 기다리는 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>결제 완료</TableCell>
              <TableCell className={classes.cellContent}>
                구매자가 결제하였을 때 출고되기 전까지의 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>출고 중</TableCell>
              <TableCell className={classes.cellContent}>
                본사 관리자가 상품을 매장으로 발송한 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>인도 대기</TableCell>
              <TableCell className={classes.cellContent}>
                상품이 본사로부터 매장에 도착하여 구매자에게 전달을 위해
                대기중인 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>반송 중</TableCell>
              <TableCell className={classes.cellContent}>
                판매자가 판매 철회 또는 돌려받기를 한 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                구매 취소 본사 반송
              </TableCell>
              <TableCell className={classes.cellContent}>
                구매자가 구매를 취소하여 상품을 본사로 반송하는 경우
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>접수 대기</TableCell>
              <TableCell className={classes.cellContent}>
                판매자가 판매 신청하고 매장 관리자의 상품 접수를 기다리는 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>판매 중</TableCell>
              <TableCell className={classes.cellContent}>
                상품목록에서 판매되고 있는 경우
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>출고 중</TableCell>
              <TableCell className={classes.cellContent}>
                본사 관리자가 상품을 매장으로 발송한 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>인도 대기</TableCell>
              <TableCell className={classes.cellContent}>
                상품이 본사로부터 매장에 도착하여 구매자에게 전달을 위해
                대기중인 상태
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                구매자 구매 취소
              </TableCell>
              <TableCell className={classes.cellContent}>
                구매자가 상품을 확인하고 구매를 취소한 상태
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Flex>
  );
};
const InfoState = () => {
  const classes = useStyle();
  return (
    <Flex
      style={{
        width: "860px",
        height: "450px",
        overflowY: "scroll",
        marginTop: 15,
      }}
    >
      <Flex className={classes.modal}>{"상태 설명"}</Flex>
      <Table>
        <TableBody>
          <TableCell rowSpan={10} className={classes.cellTitle}>
            판매 요청 대기
          </TableCell>
          <TableRow>
            <TableCell className={classes.cellTitle}>접수 대기</TableCell>
            <TableCell className={classes.cellContent}>
              판매자가 판매 신청하고 매장 관리자의 상품 접수를 기다리는 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>접수 완료</TableCell>
            <TableCell className={classes.cellContent}>
              판매자가 접수 매장을 방문하고 매장에서 상품 접수를 확인한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>접수 취소</TableCell>
            <TableCell className={classes.cellContent}>
              판매자가 판매 신청을 취소하거나 접수 매장에서 상품 접수를 거절한
              상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>감정 대기 중</TableCell>
            <TableCell className={classes.cellContent}>
              매장에서 판매자의 상품을 송부하여 본사 관리자가 수령한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>감정 완료</TableCell>
            <TableCell className={classes.cellContent}>
              본사 관리자가 상품 감정을 마치고 판매자의 판매신청을 대기하는 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>판매 요청 대기</TableCell>
            <TableCell className={classes.cellContent}>
              판매자가 감정결과를 확인하고 판매신청을 한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>판매 대기</TableCell>
            <TableCell className={classes.cellContent}>
              본사 관리자가 상품을 등록하고 판매 노출 대기 중인 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              구매 취소 본사 반송
            </TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 구매를 취소하여 상품을 본사로 반송하는 경우
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>거래 종료</TableCell>
            <TableCell className={classes.cellContent}>
              본사 관리자에 의해 판매절차가 강제로 종료된 상태
            </TableCell>
          </TableRow>
          <TableCell rowSpan={2} className={classes.cellTitle}>
            판매 중
          </TableCell>
          <TableRow>
            <TableCell className={classes.cellTitle}>판매 진행 중</TableCell>
            <TableCell className={classes.cellContent}>
              상품목록에서 판매되고 있는 경우
            </TableCell>
          </TableRow>
          <TableCell rowSpan={12} className={classes.cellTitle}>
            출고 및 판매 반송
          </TableCell>
          <TableRow>
            <TableCell className={classes.cellTitle}>반송</TableCell>
            <TableCell className={classes.cellContent}>
              판매자가 판매 철회 또는 돌려받기를 한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>결제 대기 중</TableCell>
            <TableCell className={classes.cellContent}>
              결제가 완료되지 않고 구매 과정에서 이탈하여 결제를 기다리는 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>구매 취소</TableCell>
            <TableCell className={classes.cellContent}>
              결제 대기 상태에서 결제 가능 시간이 경과하여 구매가 취소된 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>결제 완료</TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 결제하였을 때 출고되기 전까지의 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>결제 취소</TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 결제 완료 이후 상품을 인도 받기 전에 결제를 취소한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>출고 중</TableCell>
            <TableCell className={classes.cellContent}>
              본사 관리자가 상품을 매장으로 발송한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>인도 대기</TableCell>
            <TableCell className={classes.cellContent}>
              상품이 본사로부터 매장에 도착하여 구매자에게 전달을 위해 대기중인
              상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>반송 완료</TableCell>
            <TableCell className={classes.cellContent}>
              판매자가 돌려받기 또는 판매철회를 신청하고, 사용자 화면에서 반송
              완료를 누른 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>상품 인도</TableCell>
            <TableCell className={classes.cellContent}>
              매장에서 구매자에게 상품을 전달한 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>구매 완료</TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 상품을 확인하고 구매확정을 눌러 구매절차가 종료된 상태
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              구매자 구매 취소
            </TableCell>
            <TableCell className={classes.cellContent}>
              구매자가 상품을 확인하고 구매를 취소한 상태
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Flex>
  );
};
const useStyle = makeStyles({
  label: {
    margin: "0px 0px 0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  labelIcon: {
    margin: "0px 10px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    height: 29,
  },
  table: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },

  selectBox: {
    // minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
    width: 157,
  },
  selectBoxHeader: {
    background: "white",
    marginRight: 10,
    fontSize: "initial",
    width: 157,
    // padding: "0px !important",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "176px",
  },
  changeStyleValue: {
    padding: "0px 0px 5px 0px",
  },
  modal: {
    textAlign: "left",
    padding: "2px 0px 0px 21px",
    width: "100%",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
    fontWeight: "bold",
  },
  filtersInfoBtn: {
    fontFamily: fonts.notoSansKRBold,
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
  },
  cellTitle: {
    fontWeight: "bold",
    width: "20%",
    padding: 10,
  },
  cellContent: {
    width: "80%",
    padding: 10,
  },
});
