import { Checkbox, makeStyles } from "@material-ui/core";
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import Input from "../../components/input/Input";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { openImageView } from "../../redux/image-viewer/ImageViewerReducer";
import styled from "styled-components";
import { Button } from "@progress/kendo-react-buttons";

export default function Category({
  rows,
  i,
  setcategoryreser,
  categoryselectfunc,
  before,
  after,
  setsubcategoryidFunc,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  var modeType = 0;
  var goldOrSilver = 0;
  if (!rows.kind) {
    modeType = 1;
  }
  if (!rows.goldOrSilver) {
    goldOrSilver = "GOLD";
  } else {
    goldOrSilver = rows.goldOrSilver;
  }
  const [modCker, setmodCker] = useState(modeType);
  const [categoryinput, setcategoryinput] = useState(rows.kind);
  const [categoryselect, setcategoryselect] = useState(goldOrSilver);
  const [labelColor, setLabelColor] = useState(rows.labelColor);
  const [categoryImg, setCategoryImg] = useState(rows.image);
  const [Deletetype, setDeletetype] = useState(
    rows.deleted === true ? false : true
  );

  const fileInput = React.useRef(null);

  const handleImgButtonClick = (e) => {
    fileInput.current.click();
  };

  const checkRatio = (targetWidth, targetHeight) => {
    const categoryBaseRatio = "1.0";
    return (targetWidth / targetHeight).toFixed(1) === categoryBaseRatio;
  };

  const handleImgChange = (e) => {
    const file = e.target.files[0];
    const _URL = window.URL || window.webkitURL;
    const i = new Image();
    i.src = _URL.createObjectURL(file);
    i.onload = () => {
      if (i.width !== 0 && i.height !== 0) {
        if (!checkRatio(i.width, i.height)) {
          dispatch(
            actionOpen(
              "카테고리 이미지는 1 : 1 비율의 이미지만 가능합니다.",
              null,
              null,
              null,
              true
            )
          );
          return false;
        } else {
          if (file) {
            const f = new FileReader();
            f.onload = () => {
              file.uri = f.result;
              setCategoryImg(file);
            };
            f.readAsDataURL(file);
            e.target.value = "";
          }
        }
      }
    };
  };

  const handleDeleteImage = () => {
    setCategoryImg("");
  };

  const modChangeFunc = () => {
    if (modCker === 0) {
      setmodCker(1);
    } else {
      setmodCker(0);
    }
  };

  const modActiveFunc = (id) => {
    if (!categoryinput) {
      dispatch(
        actionOpen("카테고리 분류명을 입력해주세요", null, null, null, true)
      );
      return;
    }
    if (!categoryImg) {
      dispatch(
        actionOpen("카테고리 이미지를 등록해주세요", null, null, null, true)
      );
      return;
    }

    rows.kind = categoryinput;
    var params = {};
    var fn = null;
    let formData = new FormData();

    if (id) {
      params = {
        goldOrSilver: categoryselect,
        kind: categoryinput,
        sort: rows.sort,
        labelColor: labelColor,
        id: id,
        deleted: Deletetype === true ? false : true,
      };
      fn = APIS.postGoodsCategoryModify;
    } else {
      params = {
        goldOrSilver: categoryselect,
        kind: categoryinput,
        labelColor: labelColor,
        sort: i + 1,
        deleted: Deletetype === true ? false : true,
      };
      fn = APIS.postGoodsCategoryCreate;
    }

    const blob = new Blob([JSON.stringify(params)], {
      type: "application/json",
    });
    formData.append("category", blob);
    formData.append("image", categoryImg);
    fn(formData, id)
      .then(() => {
        setcategoryreser(1);
      })
      .catch((err) => dispatch(actionError(err)));
  };
  const updateSort = (type) => {
    var params = {};
    var params2 = {};

    if (type === "up") {
      if (!before) return;
      params = {
        goldOrSilver: before.goldOrSilver,
        kind: before.kind,
        labelColor: before.labelColor,
        sort: rows.sort,
        id: before.id,
      };

      params2 = {
        goldOrSilver: rows.goldOrSilver,
        kind: rows.kind,
        labelColor: rows.labelColor,
        sort: before.sort,
        id: rows.id,
      };
    } else {
      if (!after) return;
      params = {
        goldOrSilver: after.goldOrSilver,
        kind: after.kind,
        labelColor: after.labelColor,
        sort: rows.sort,
        id: after.id,
      };

      params2 = {
        goldOrSilver: rows.goldOrSilver,
        kind: rows.kind,
        labelColor: rows.labelColor,
        sort: after.sort,
        id: rows.id,
      };
    }
    const blob = new Blob([JSON.stringify(params)], {
      type: "application/json",
    });
    let formData1 = new FormData();
    formData1.append("category", blob);

    const blob2 = new Blob([JSON.stringify(params2)], {
      type: "application/json",
    });
    let formData2 = new FormData();
    formData2.append("category", blob2);

    APIS.postGoodsCategoryModify(
      formData1,
      type === "up" ? before.id : after.id
    )
      .then(() => setcategoryreser(1))
      .catch((err) => dispatch(actionError(err)));

    APIS.postGoodsCategoryModify(formData2, rows.id)
      .then(() => setcategoryreser(1))
      .catch((err) => dispatch(actionError(err)));
  };

  return (
    <>
      <tr key={i.toString() + rows.checked}>
        <td>
          {modeType === 0 && (
            <>
              <ArrowDropUpOutlined
                onClick={() => {
                  updateSort("up");
                }}
                className="pointer"
              />
              <ArrowDropDownOutlined
                onClick={() => {
                  updateSort("down");
                }}
                className="pointer"
              />
            </>
          )}
        </td>

        <td>{i + 1}</td>
        <td>
          {rows.goldOrSilver ? (
            rows.goldOrSilver
          ) : (
            <select
              defaultValue={rows.goldOrSilver}
              style={{
                minWidth: 100,
                fontSize: 16,
              }}
              onChange={(e) => {
                setcategoryselect(e.target.value);
              }}
            >
              <>
                <option value={"GOLD"}>금(GOLD)</option>
                <option value={"SILVER"}>은(SILVER)</option>
              </>
            </select>
          )}
        </td>
        <td>
          {modCker === 0 ? (
            <span
            // onClick={() => setsubcategoryidFunc(rows.id)}
            // style={{ cursor: "pointer", color: "blue" }}
            >
              {rows.kind}
            </span>
          ) : (
            <Input
              value={categoryinput}
              onChange={(v) => {
                setcategoryinput(v);
              }}
              placeholder={""}
              className={classes.input}
            />
          )}
        </td>
        <td style={{ display: "flex", justifyContent: "center" }}>
          {modCker === 0 ? (
            rows.image ? (
              <CategoryImage
                src={consts.s3BaseUrl + rows?.image}
                onClick={() => {
                  dispatch(
                    openImageView({
                      images: [
                        {
                          src: rows.image.slice(1),
                          title: rows.kind,
                        },
                      ],
                    })
                  );
                }}
              />
            ) : (
              <Frame>
                이미지
                <br />
                없음
              </Frame>
            )
          ) : categoryImg ? (
            <RelativeFrame>
              <CloseButton
                className="delete k-icon k-i-close-outline k-i-x-outline"
                onClick={handleDeleteImage}
              />
              <InvisibleInput
                accept="image/*"
                type="file"
                id={`rasied-file`}
                ref={fileInput}
                onChange={handleImgChange}
              />
              <label htmlFor={`rasied-file`}>
                <CategoryImage
                  src={
                    rows.image ? consts.s3BaseUrl + rows.image : categoryImg.uri
                  }
                />
              </label>
            </RelativeFrame>
          ) : (
            <FrameRegister>
              <InvisibleInput
                accept="image/*"
                type="file"
                id={`rasied-file`}
                ref={fileInput}
                onChange={handleImgChange}
              />
              <label htmlFor={`rasied-file`}>
                <Button onClick={handleImgButtonClick}>이미지 등록</Button>
              </label>
            </FrameRegister>
          )}
        </td>
        <td>
          {modCker === 0 ? (
            <>
              <ColorPicker value={rows.labelColor} />
            </>
          ) : (
            <>
              <ColorPicker
                value={labelColor}
                onChange={(e) => {
                  setLabelColor(e.value);
                }}
              />
            </>
          )}
        </td>
        <td>
          {modCker === 0 ? (
            <>
              <Checkbox
                checked={rows.deleted === true ? false : true}
                onChange={(e) => setDeletetype(e.target.checked)}
              />
            </>
          ) : (
            <>
              <Checkbox
                checked={Deletetype}
                onChange={(e) => setDeletetype(e.target.checked)}
              />
            </>
          )}
        </td>

        {modCker === 0 ? (
          <>
            <td
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => modChangeFunc()}
            >
              수정
            </td>
          </>
        ) : (
          <>
            <td
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => modActiveFunc(rows.id ? rows.id : "")}
            >
              저장
            </td>
          </>
        )}
        {/*
            <td>
            <Checkbox
                checked={rows.checked}
                onChange={(e) => categoryselectfunc(rows.id, e)}
            />
            </td>
            */}
      </tr>
    </>
  );
}

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Frame = styled(FlexCenter)`
  width: 50px;
  height: 50px;
  border: 1px solid lightgray;
  font-size: 12px;
  text-align: center;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const FrameRegister = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

const RelativeFrame = styled.div`
  margin-top: 7px;
  position: relative;
  display: inline-block;
`;

const CloseButton = styled.i`
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: white;
  color: red;
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
`;

const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
