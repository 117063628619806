import { ButtonBase, Dialog, InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import { actionClose } from "../../redux/action/ActionActions";

export default function PhysicalOrderDetailDialog({ onClose }) {
  const dispatch = useDispatch();

  const classes = useStyle();
  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Flex
        style={{
          fontFamily: fonts.notoSansKRBold,
          backgroundColor: "#f5f5f5",
          padding: 10,
          alignItems: "center",
        }}
      >
        제작 주문 정보
      </Flex>
      <InputBase
        multiline
        className={classes.input}
        placeholder={`반려사유 입력
\n
\n
Ex)
제작 설비 고장으로 인한 과도한 제작기간 지연
제조 담당 직원 코로나 감염에 따른 제조 작업장 폐쇄로 제작기간 과도하게 지연`}
      />

      <Flex row>
        <ButtonBase
          onClick={onClose}
          style={{
            flex: 1,
            padding: "16px",
            backgroundColor: "gray",
            color: "#fff",
            fontFamily: fonts.notoSansKRBold,
          }}
        >
          취소
        </ButtonBase>

        <ButtonBase
          onClick={onClose}
          style={{
            flex: 1,
            padding: "16px",
            backgroundColor: "black",
            color: "#fff",
            fontFamily: fonts.notoSansKRBold,
          }}
        >
          저장
        </ButtonBase>
      </Flex>
    </Dialog>
  );
}

const useStyle = makeStyles({
  input: {
    padding: "10px",
    border: "1px solid #f5f5f5",
    minHeight: 300,
    margin: "20px",
  },
});
