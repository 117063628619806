import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import GridTable, { CenterCell } from "components/gird-table/GridTable";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Grid, GridColumn, GridRow } from "@progress/kendo-react-grid";
import moment from "moment-timezone";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import * as APIS from "../../libs/apis";
import { actionError, actionOpen } from "redux/action/ActionActions";
import Flex from "../../components/flex/Flex";
import { Divider } from "@mui/material";
import SmallTitle from "components/title/SmallTitle";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import TradeFeeTable from "./component/TradeFeeTable";
import AdminCofirmBtn from "components/admin-confirm-btn/AdminConfirmBtn";

const assetRadioData = [
  {
    label: "전체",
    value: "ALL",
  },
  {
    label: "금",
    value: "GOLD",
  },
  {
    label: "은",
    value: "SILVER",
  },
];

export default function SettingDirect({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const dispatch = useDispatch();

  // 제휴사별 수수료(item.feeRate)
  const { associateShops } = useSelector((state) => state.shop);
  const [feeData, setFeeData] = useState([]);
  const [associateFee, setAssociateFee] = useState([]);

  const [dataInitType, setDataInitType] = useState("");
  /**
   * label: "GOLD_BUY",
   * data: {},
   */

  const getFeeData = () => {
    let defaultValue = {
      id: 0,
      manager: null,
      managerName: "MANAGER",
      assetType: "",
      tradeType: "",
      userFeeRate: 0,
      recommendShopFeeRate: 0,
      usingShopFeeRate: 0,
      appraisalShopFeeRate: 0,
      priceLimitRate: 0,
      priceLimit: 0,
      sumRequestLowLimit: 0,
      createdAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
      active: true,
      feeSum: 0,
      headOffice: 0,
    };
    let arr = ["GOLD_BUY", "GOLD_SELL", "SILVER_BUY", "SILVER_SELL"].map(
      (key) => {
        let assetTrade = key.split("_");
        return {
          ...defaultValue,
          assetType: assetTrade[0],
          tradeType: assetTrade[1],
        };
      }
    );
    APIS.getTradeFeeList().then(({ data: { success, data, message } }) => {
      if (success && Array.isArray(data) && data.length !== 0) {
        arr = data.map((obj, index) => {
          let userFeeRate = obj.userFeeRate || 0;
          let recommand = obj.recommendShopFeeRate || 0;
          let using = obj.usingShopFeeRate || 0;
          let appraisal = obj.appraisalShopFeeRate || 0;
          // 대리점 합계
          let feeSum =
            obj.tradeType === "BUY"
              ? recommand + using
              : recommand + using + appraisal;
          return {
            ...obj,
            id: index + 1,
            feeSum: feeSum,
            headOffice: userFeeRate - feeSum, // 본사
          };
          // if (
          //   data.find((item) => key === `${item.assetType}_${item.tradeType}`)
          // ) {
          // } else {
          //   let keys = key.split("_");
          //   return {
          //     ...defaultValue,
          //     assetType: keys[0],
          //     tradeType: keys[1],
          //   };
          // }
        });
      } else {
        dispatch(actionError(message));
      }
      setFeeData(arr);
    });
  };

  useMemo(() => {
    if (Array.isArray(associateShops) && associateShops.length !== 0) {
      let obj = {};
      associateShops.forEach((item, index) => {
        obj = {
          ...obj,
          [index + 1]: item.feeRate,
        };
      });
      setAssociateFee([obj]);
    }
  }, [associateShops]);

  useEffect(() => {
    getFeeData();
  }, []);

  const onClickInit = (props) => {
    if (dataInitType !== "") {
      const payload = {
        managerPassword: props,
        assetType: dataInitType,
      };
      APIS.postResetMatching(payload)
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(actionOpen("초기화 되었습니다.", null, null, null, true));
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((err) => dispatch(actionError(err)));
    } else {
      dispatch(
        actionOpen("초기화할 데이터를 선택해주세요.", null, null, null, true)
      );
    }
  };

  const handleChangeType = (e) => {
    setDataInitType(e.value);
  };

  return (
    <>
      <Root
        data={[{ label: "거래 수수료 정책", value: "1" }]}
        rootStyle={classes.root}
      />
      {/* <AdminConfirmButton
        popupMessage="등록하시겠습니까?"
        label="등록"
        popupLabel="대리점 등록"
        // callback={handleClickRegister}
      /> */}
      <Flex className={classes.tableContainer}>
        <Container>
          <SmallTitle>금</SmallTitle>
          <TradeFeeTable assetType="GOLD" feeData={feeData} />
        </Container>
        <Container>
          <SmallTitle>은</SmallTitle>
          <TradeFeeTable assetType="SILVER" feeData={feeData} />
        </Container>
        <Container>
          <SmallTitle>제휴사 (이용 대리점 수수료 중 비율)</SmallTitle>
          <GridTable
            data={associateFee}
            style={{
              marginTop: "10px",
            }}>
            <GridColumn
              title={"제휴사"}
              width={100}
              cell={(props) => {
                return <td className="td-c">비율(%)</td>;
              }}
            />
            {associateShops.map((item, index) => {
              return (
                <GridColumn
                  key={index}
                  title={item.label}
                  field={`${index + 1}`}
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <CenterCell {...props} unit=" %">
                        {associateFee[0][`${index + 1}`] !== undefined
                          ? `${item.feeRate} %`
                          : "-"}
                      </CenterCell>
                    );
                  }}
                  width={150}
                />
              );
            })}
          </GridTable>
        </Container>
        <Container>
          <SmallTitle>매칭 데이터 초기화</SmallTitle>
          <Flex row style={{ marginTop: 10 }}>
            <Flex style={{ marginRight: 30 }}>
              <RadioGroup
                data={assetRadioData}
                layout={"horizontal"}
                value={dataInitType}
                onChange={handleChangeType}
              />
            </Flex>
            <AdminCofirmBtn
              label={"초기화"}
              className={"k-grid-save-command"}
              callback={(password) => {
                onClickInit(password);
              }}
              style={{
                display: "inline-block",
                background: "#ff6358",
                border: "none",
                color: "#fff",
              }}
            />
          </Flex>
        </Container>
      </Flex>
    </>
  );
}

const Root = styled(LabelTabs)``;
const Container = styled(Flex)`
  flex: 1;
  padding: 15px 0px 0px;
`;

const useStyle = makeStyles({
  root: {
    margin: "0px 20px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    borderBottom: "1px solid black",
  },
  tableContainer: {
    margin: "0px 20px",
  },
  button: {
    width: "150px",
    padding: 8,
    marginRight: 10,
  },
  buttonSel: {
    width: "150px",
    padding: 8,
    color: "#fff !important",
    backgroundColor: "black !important",
  },
});
