import {
  ButtonBase,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router";
import Button from "components/button/Button";
import Filters from "components/filters/Filters";
import Flex from "components/flex/Flex";
import DataTable from "components/table/Table";
import fonts from "libs/fonts";
import routes from "libs/routes";
import * as APIS from "libs/apis";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import { objToQueryStr } from "services/utils";
import { GOODS_DISPLAY_CODE, GOODS_DISPLAY_SEARCH_PARAMS } from "libs/consts";
import { actionError, actionOpen } from "redux/action/ActionActions";

export default function ProductList({ data, optionComponent }) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const [product, setProduct] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const { associateShops } = useSelector((state) => state.shop);

  console.log(`state`, state);
  const displayModeList = state.displayModeList || [
    "APP",
    "MALL",
    "K_BANK_MALL",
  ];

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  useEffect(() => {
    APIS.getGoodsCategoryList()
      .then((res) => {
        if (res.data.success) {
          var cates = res.data.data;
          var categorys = [];
          for (let i in cates) {
            categorys.push({ label: cates[i].kind, value: cates[i].id });
          }
          setCategories(categorys);
        } else {
          dispatch(actionError(res.data.message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
  }, []);

  const fetchList = () => {
    const paramQuery = objToQueryStr({
      ...state,
      page: state.page,
      size: state.size,
      displayModeList: displayModeList,
    });

    dispatch(loadingStart);
    APIS.productList(paramQuery)
      .then(({ data: { success, data, message, totalElements } }) => {
        setProduct(
          data.content.map((product) => {
            return {
              ...product,
              imagePath: product.imageIds?.[0]?.path || "",
              associateShopIds:
                typeof product?.associateShopIds === "string" &&
                product?.associateShopIds !== ""
                  ? product?.associateShopIds
                      .split(",")
                      .map(
                        (id) =>
                          associateShops.find(
                            (item) => item.value === parseInt(id)
                          )?.label
                      )
                      .join(" / ")
                  : "전체",
            };
          })
        );
        setTotalCount(data.totalElements);
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  // GOODS_DISPLAY_CODE 기반의 value, label 생성
  const GOODS_DISPLAY_CODE_LABEL = useMemo(
    () =>
      Object.entries(GOODS_DISPLAY_SEARCH_PARAMS).map(([value, label]) => ({
        value,
        label,
      })),
    []
  );

  const handleValueChange = (checkValue, isChecked) => {
    const updatedList = isChecked
      ? [...displayModeList, checkValue]
      : displayModeList.filter((item) => item !== checkValue);

    history.replace({
      pathname: location.pathname,
      state: { ...state, displayModeList: updatedList },
    });
  };

  const CheckboxComponent = ({ checkbox }) => {
    const { value: checkValue, label } = checkbox;

    return (
      <FormControlLabel
        key={checkValue}
        style={{ marginLeft: 5 }}
        control={
          <Checkbox
            checked={displayModeList.includes(checkValue)}
            onChange={(e) => handleValueChange(checkValue, e.target.checked)}
          />
        }
        label={label}
      />
    );
  };

  return (
    <>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center"></Flex>
          <Flex row>
            <Button
              onClick={() =>
                history.push(routes.physicalProductDetail, { action: "add" })
              }
              label="+ 상품등록"
              className={classes.button}
              classNameLabel={classes.buttonLabel}
            />
          </Flex>
        </Flex>
      </Flex>
      <Filters
        onSearch={fetchList}
        data={[
          {
            label: "조회기간",
            type: "date-range",
            key: "beginDate,endDate",
          },
          {
            label: "검색",
            placeholder: "상품명",
            type: "input",
            key: "search",
            autoFocus: true,
          },
          {
            label: "카테고리",
            data: categories,
            type: "menu",
            key: "category",
          },

          {
            label: "제조사",
            type: "input",
            key: "outsourcing",
          },
          {
            label: "판매처",
            wrap: true,
            render: (
              <Flex row>
                {GOODS_DISPLAY_CODE_LABEL.map((checkbox, index) => (
                  <CheckboxComponent key={index} checkbox={checkbox} />
                ))}
              </Flex>
            ),
            type: "render",
            key: "displayModeList",
          },
        ]}
      />
      <DataTable
        data={product}
        totalCount={totalCount}
        rootStyle={classes.rootStyle}
        onClick={(item) => {
          history.push(routes.physicalProductDetail, {
            id: item.id,
            action: "detail",
          });
        }}
        rowClassName="pointer"
        columns={[
          { label: "상품등록일", key: "createdAt", type: "datetime" },
          { label: "상품코드", key: "code" },
          { label: "이미지", key: "imagePath", type: "s3img" },
          { label: "카테고리", key: "categoryName" },
          { label: "상품명", key: "name" },
          { label: "중량", key: "gram" },
          { label: "앱 수수료", key: "fees", type: "number" },
          { label: "몰 수수료", key: "mallFee", type: "nullableNumber" },
          { label: "대리점 수수료", key: "outShopFee", type: "number" },
          { label: "기본공임", key: "pay", type: "number" },
          { label: "판매공임", key: "laborCost", type: "number" },
          { label: "제조사", key: "outsourcing" },
          { label: "제작기간", key: "days" },
          { label: "재고", key: "stock" },
          { label: "제휴사", key: "associateShopIds" },
        ]}
      />
    </>
  );
}
const useStyle = makeStyles({
  root: {
    margin: "10px 30px",
  },
  rootStyle: {
    width: "calc(100% - 60px)",
    margin: "0 30px",
    overflowX: "scroll",
  },
  header: {
    alignSelf: "stretch",
    // marginTop: "10px",
    marginBottom: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
});
