import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as APIS from "../../libs/apis";
import Flex from "../../components/flex/Flex";
import GridTable, { centerCell } from "../../components/gird-table/GridTable";
import { makeStyles } from "@material-ui/core";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import cloneDeep from "lodash.clonedeep";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { numberWithCommas } from "../../libs/utils";

const SettlementConfiguration = () => {
  const [data, setData] = useState([]);
  const [temp, setTempData] = useState([]);
  const [changed, setChanged] = useState([]);
  const [selected, setSelected] = useState();
  const [item, setItem] = useState({
    dataIndex: "",
    elIndex: "",
  });
  const dispatch = useDispatch();
  const classes = useStyle();
  const currentPosition = useRef("");
  //const inputRef = useRef("");
  //const firstRenderRef = useRef(true);

  useEffect(() => {
    APIS.getConfigurationList().then((res) => {
      try {
        const { data } = res;
        const { data: configuration, success } = data;
        if (success) {
          if (configuration.length > 0) {
            let copyData = cloneDeep(configuration);
            let category = copyData.map((item) => item.configCategory);
            category = new Set(category);
            let obj = {};
            let parseArr = [];

            category.forEach((item) => {
              if (obj[item] === undefined) {
                obj[item] = {
                  data: [],
                };
              }
              copyData.forEach((el) => {
                if (item === el.configCategory) {
                  if (el.canModify) {
                    obj[item] = {
                      ...obj[item],
                      name: el.categoryName,
                      data: [
                        ...obj[item].data,
                        { ...el, commentModify: false, valueModify: false },
                      ],
                    };

                    // obj[item] = {
                    //   ...obj[item],
                    //   data: obj[item].data.sort(function (a, b) {
                    //     if (a.configKey < b.configKey) {
                    //       return -1;
                    //     }

                    //     if (a.configKey > b.configKey) {
                    //       return 1;
                    //     }

                    //     return 0;
                    //   }),
                    // };
                  } else {
                    obj[item] = {
                      ...obj[item],
                      name: el.categoryName,
                      data: [
                        ...obj[item].data,
                        { ...el, commentModify: false },
                      ],
                    };

                    // obj[item] = {
                    //   ...obj[item],
                    //   data: obj[item].data.sort(function (a, b) {
                    //     if (a.configKey < b.configKey) {
                    //       return -1;
                    //     }

                    //     if (a.configKey > b.configKey) {
                    //       return 1;
                    //     }

                    //     return 0;
                    //   }),
                    // };
                  }
                }
              });
              console.log(obj);
              parseArr.push({
                type: item,
                name: obj?.[item]?.name || "-",
                data: obj?.[item]?.data,
              });
            });
            setData(parseArr);
            setTempData(parseArr);
          } else {
            setData([]);
            setTempData([]);
          }
        }
      } catch (err) {
        console.log("err configuration list :::", err);
      }
    });
  }, []);

  useEffect(() => {
    // function setCaretPosition(elemId) {
    //   let elem = document.getElementById(elemId);
    //   const position = currentPosition?.current || 0;

    //   if (elem != null) {
    //     if (elem.createTextRange) {
    //       var range = elem?.createTextRange();
    //       range?.move("character", position);
    //       range?.select();
    //     } else {
    //       if (elem.selectionStart) {
    //         elem?.focus();
    //         elem?.setSelectionRange(position, position);
    //       } else elem?.focus();
    //     }
    //   }
    // }
    // setCaretPosition(selected);
    document.getElementById(selected)?.focus();
  }, [selected, data]);

  const onClickSave = (adminPassword) => {
    let parseData = [];

    changed.forEach((item) => {
      data.forEach((el) => {
        (el?.data || []).forEach((dl) => {
          if (item === dl.id) {
            parseData.push(dl);
          }
        });
      });
    });

    const payload = {
      managerPassword: adminPassword,
      modifyEnvConfigList: [...parseData],
    };

    APIS.postConfiguration(payload).then((res) => {
      const { data: responseData } = res;
      const { success, message } = responseData;

      if (success) {
        setTimeout(() => {
          APIS.getConfigurationList().then((res) => {
            try {
              const { data } = res;
              const { data: configuration, success } = data;
              if (success) {
                if (configuration.length > 0) {
                  let copyData = cloneDeep(configuration);
                  let category = copyData.map((item) => item.configCategory);
                  category = new Set(category);
                  let obj = {};
                  let parseArr = [];

                  category.forEach((item) => {
                    if (obj[item] === undefined) {
                      obj[item] = {
                        data: [],
                      };
                    }
                    copyData.forEach((el) => {
                      if (item === el.configCategory) {
                        if (el.canModify) {
                          obj[item] = {
                            ...obj[item],
                            name: el.categoryName,
                            data: [
                              ...obj[item].data,
                              {
                                ...el,
                                commentModify: false,
                                valueModify: false,
                              },
                            ],
                          };

                          // obj[item] = {
                          //   ...obj[item],
                          //   data: obj[item].data.sort(function (a, b) {
                          //     if (a.configKey < b.configKey) {
                          //       return -1;
                          //     }

                          //     if (a.configKey > b.configKey) {
                          //       return 1;
                          //     }

                          //     return 0;
                          //   }),
                          // };
                        } else {
                          obj[item] = {
                            ...obj[item],
                            name: el.categoryName,
                            data: [
                              ...obj[item].data,
                              { ...el, commentModify: false },
                            ],
                          };

                          // obj[item] = {
                          //   ...obj[item],
                          //   data: obj[item].data.sort(function (a, b) {
                          //     if (a.configKey < b.configKey) {
                          //       return -1;
                          //     }

                          //     if (a.configKey > b.configKey) {
                          //       return 1;
                          //     }

                          //     return 0;
                          //   }),
                          // };
                        }
                      }
                    });
                    parseArr.push({
                      type: item,
                      name: obj?.[item]?.name || "-",
                      data: obj?.[item]?.data,
                    });
                  });
                  setData(parseArr);
                  setTempData(parseArr);
                  setChanged([]);
                  dispatch(
                    actionOpen("변경되었습니다", null, null, null, true)
                  );
                } else {
                  setData([]);
                  setTempData([]);
                  setChanged([]);
                }
              }
            } catch (err) {
              console.log("err configuration list :::", err);
            }
          });
        }, 500);
      } else {
        dispatch(actionError(message));
      }
    });
  };

  const customCell = (props, type = "") => {
    const { dataItem } = props;
    const dataArr = dataItem["data"];
    return (
      <td className="td-l">
        {(dataArr || []).map((el) => {
          return (
            <tr style={{ height: "3vh" }}>
              <td
                style={{
                  cursor: "default",
                  width: "100vw",
                  textAlign:
                    type === "type" || type === "configKey" ? "left" : "center",
                  height: "3vh",
                  background: changed.includes(el.id) ? "#f89a9a" : "none",
                }}
              >
                {el?.[type] || "-"}
              </td>
            </tr>
          );
        })}
      </td>
    );
  };

  // const onChangeValue = (e) => {
  //   const { value, dataset } = e.target;
  //   let el = document.getElementById(selected);
  //   currentPosition.current = el.selectionStart;
  //   const {
  //     dataindex: index,
  //     elindex: elIndex,
  //     eltype: dataType,
  //     typevalue: type,
  //   } = dataset;
  //   let copyData = cloneDeep(data);
  //   let parseValue = "";

  //   if (type === "comment") {
  //     parseValue = value;
  //   } else {
  //     if (dataType === "Decimal") {
  //       //실수 체크
  //       const _pattern2 = /^\d*[.]\d{5}$/;
  //       if (_pattern2.test(value)) {
  //         return false;
  //       } else {
  //         parseValue = value;
  //       }
  //     } else if (dataType === "Number") {
  //       parseValue = value.replace(/[^-.0-9]/g, "");
  //     } else if (dataType === "String" || dataType === "Time") {
  //       parseValue = value;
  //     } else {
  //       parseValue = value;
  //     }
  //   }

  //   copyData[index]["data"][elIndex] = {
  //     ...copyData[index]["data"][elIndex],
  //     [type]: parseValue,
  //   };

  //   setData(copyData);
  // };

  const onKeyupEscComment = (e) => {
    const { dataset } = e.target;
    const { dataindex: dataIndex, elindex: elIndex, elid: elId } = dataset;
    if (e.key === "Escape") {
      let copyChanged = cloneDeep(changed);
      let copyData = cloneDeep(data);
      let copyTemp = cloneDeep(temp);
      if (!copyChanged.includes(+elId)) {
        const check =
          copyData[dataIndex]["data"][elIndex].comment !==
            copyTemp[dataIndex]["data"][elIndex].comment ||
          copyData[dataIndex]["data"][elIndex].value1 !==
            copyTemp[dataIndex]["data"][elIndex].value1
            ? true
            : false;
        if (check) {
          copyChanged.push(copyData[dataIndex]["data"][elIndex].id);
          setChanged(copyChanged);
        }
      } else {
        const check =
          copyData[dataIndex]["data"][elIndex].comment ===
            temp[dataIndex]["data"][elIndex].comment &&
          copyData[dataIndex]["data"][elIndex].value1 ===
            temp[dataIndex]["data"][elIndex].value1
            ? true
            : false;
        if (check) {
          let copyChanged = cloneDeep(changed);
          copyChanged = copyChanged.filter(
            (item) => item !== copyData[dataIndex]["data"][elIndex].id
          );
          setChanged(copyChanged);
        }
      }
      copyData[dataIndex]["data"][elIndex] = {
        ...copyData[dataIndex]["data"][elIndex],
        commentModify: false,
      };
      setData(copyData);
    }
  };

  const onKeyupEscValue = (e) => {
    const { dataset } = e.target;
    const { dataindex: dataIndex, elindex: elIndex, elid: elId } = dataset;
    if (e.key === "Escape") {
      let copyChanged = cloneDeep(changed);
      let copyData = cloneDeep(data);
      let copyTemp = cloneDeep(temp);
      if (!copyChanged.includes(+elId)) {
        const check =
          copyData[dataIndex]["data"][elIndex].comment !==
            copyTemp[dataIndex]["data"][elIndex].comment ||
          copyData[dataIndex]["data"][elIndex].value1 !==
            copyTemp[dataIndex]["data"][elIndex].value1
            ? true
            : false;
        if (check) {
          copyChanged.push(copyData[dataIndex]["data"][elIndex].id);
          setChanged(copyChanged);
        }
      } else {
        const check =
          copyData[dataIndex]["data"][elIndex].comment ===
            temp[dataIndex]["data"][elIndex].comment &&
          copyData[dataIndex]["data"][elIndex].value1 ===
            temp[dataIndex]["data"][elIndex].value1
            ? true
            : false;
        if (check) {
          let copyChanged = cloneDeep(changed);
          copyChanged = copyChanged.filter(
            (item) => item !== copyData[dataIndex]["data"][elIndex].id
          );
          setChanged(copyChanged);
        }
      }
      copyData[dataIndex]["data"][elIndex] = {
        ...copyData[dataIndex]["data"][elIndex],
        valueModify: false,
      };
      setData(copyData);
    }
  };

  const customEditCell = (props, typeValue) => {
    const { dataItem, dataIndex } = props;
    const dataArr = dataItem["data"];
    return (
      <td className="td-c">
        {(dataArr || []).map((el, elIndex) => {
          const [input, setInput] = useState({
            value1: el?.["value1"] || "",
            comment: el?.["comment"] || "",
          });
          return (
            <Tooltip openDelay={100} anchorElement="target" position="top">
              <tr
                style={{ height: "3vh" }}
                onDoubleClick={() => {
                  if (el.canModify) {
                    // 수정이 가능한 상태
                    // 수정할 데이터를 복사
                    let copyData = cloneDeep(data);
                    const modify =
                      typeValue === "value1"
                        ? el.valueModify
                        : el.commentModify;
                    if (modify) {
                      if (typeValue === "value1") {
                        // value1가 true면 false로 변경
                        copyData[dataIndex]["data"][elIndex] = {
                          ...copyData[dataIndex]["data"][elIndex],
                          valueModify: false,
                          value1: input.value1,
                        };
                      } else {
                        // comment가 true면 false로 변경
                        copyData[dataIndex]["data"][elIndex] = {
                          ...copyData[dataIndex]["data"][elIndex],
                          commentModify: false,
                          comment: input.comment,
                        };
                      }

                      if (
                        !changed.includes(
                          +copyData[dataIndex]["data"][elIndex].id
                        )
                      ) {
                        const check =
                          input.comment !==
                            temp[dataIndex]["data"][elIndex].comment ||
                          input.value1 !==
                            temp[dataIndex]["data"][elIndex].value1
                            ? true
                            : false;
                        if (check) {
                          let copyChanged = cloneDeep(changed);
                          copyChanged.push(
                            copyData[dataIndex]["data"][elIndex].id
                          );
                          setChanged(copyChanged);
                        }
                      } else {
                        const check =
                          input.comment ===
                            temp[dataIndex]["data"][elIndex].comment &&
                          input.value1 ===
                            temp[dataIndex]["data"][elIndex].value1
                            ? true
                            : false;
                        if (check) {
                          let copyChanged = cloneDeep(changed);
                          copyChanged = copyChanged.filter(
                            (item) =>
                              item !== copyData[dataIndex]["data"][elIndex].id
                          );
                          setChanged(copyChanged);
                        }
                      }
                    } else {
                      // false일 경우에는 true로 변경
                      if (item.dataIndex !== "" && item.elIndex !== "") {
                        // 만약 item이 변경이 된 경우 이전 item 수정 상태를 false로 변경
                        copyData[item.dataIndex]["data"][item.elIndex] = {
                          ...copyData[item.dataIndex]["data"][item.elIndex],
                          valueModify: false,
                          commentModify: false,
                        };
                      }

                      if (typeValue === "value1") {
                        // value1가 true면 false로 변경
                        copyData[dataIndex]["data"][elIndex] = {
                          ...copyData[dataIndex]["data"][elIndex],
                          valueModify: true,
                        };
                      } else {
                        // comment가 true면 false로 변경
                        copyData[dataIndex]["data"][elIndex] = {
                          ...copyData[dataIndex]["data"][elIndex],
                          commentModify: true,
                        };
                      }

                      if (
                        !changed.includes(
                          +copyData[dataIndex]["data"][elIndex].id
                        )
                      ) {
                        const check =
                          input.comment !==
                            temp[dataIndex]["data"][elIndex].comment ||
                          input.value1 !==
                            temp[dataIndex]["data"][elIndex].value1
                            ? true
                            : false;
                        if (check) {
                          let copyChanged = cloneDeep(changed);
                          copyChanged.push(
                            copyData[dataIndex]["data"][elIndex].id
                          );
                          setChanged(copyChanged);
                        }
                      } else {
                        const check =
                          input.comment ===
                            temp[dataIndex]["data"][elIndex].comment &&
                          input.value1 ===
                            temp[dataIndex]["data"][elIndex].value1
                            ? true
                            : false;
                        if (check) {
                          let copyChanged = cloneDeep(changed);
                          copyChanged = copyChanged.filter(
                            (item) =>
                              item !== copyData[dataIndex]["data"][elIndex].id
                          );
                          setChanged(copyChanged);
                        }
                      }
                      if (typeValue === "value1") {
                        setSelected(
                          `${copyData[dataIndex]["data"][elIndex].id}-value1`
                        );
                      } else {
                        setSelected(
                          `${copyData[dataIndex]["data"][elIndex].id}-comment`
                        );
                      }

                      setItem({
                        dataIndex: dataIndex,
                        elIndex: elIndex,
                      });
                    }
                    setData(copyData);
                  } else {
                    return;
                  }
                }}
              >
                <td
                  style={{
                    cursor: "default",
                    textAlign:
                      typeValue === "comment" &&
                      data[dataIndex]["data"][elIndex].commentModify
                        ? "center"
                        : typeValue === "comment"
                        ? "left"
                        : "center",
                    height: "3vh",
                    minWidth:
                      typeValue === "comment" &&
                      data[dataIndex]["data"][elIndex].commentModify
                        ? "350px"
                        : typeValue === "comment"
                        ? "350px"
                        : "",
                    maxWidth:
                      typeValue === "comment" &&
                      data[dataIndex]["data"][elIndex].commentModify
                        ? "350px"
                        : typeValue === "comment"
                        ? "350px"
                        : "",
                    position: "relative",
                    background: changed.includes(el.id) ? "#f89a9a" : "none",
                  }}
                >
                  <span
                    style={{
                      width: "80px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {typeValue === "comment" ? (
                      el?.commentModify ? (
                        <input
                          id={`${el.id}-comment`}
                          key={`${el.id}-comment`}
                          value={input.comment || ""}
                          //value={el?.[typeValue] || ""}
                          name="comment"
                          style={{
                            textAlign: "right",
                            border: "1px solid #ddd",
                            height: "20px",
                            borderRadius: "0.2rem",
                            width: "200px",
                          }}
                          data-typeValue={typeValue}
                          data-dataIndex={dataIndex}
                          data-elIndex={elIndex}
                          data-elType={el.type}
                          data-elId={el.id}
                          onChange={(e) => {
                            const { value } = e.target;
                            const type = typeValue;
                            const dataType = el.type;
                            let parseValue = "";

                            if (type === "comment") {
                              parseValue = value;
                            } else {
                              if (dataType === "Decimal") {
                                //실수 체크
                                const _pattern2 = /^\d*[.]\d{5}$/;
                                if (_pattern2.test(value)) {
                                  return false;
                                } else {
                                  parseValue = value;
                                }
                              } else if (dataType === "Number") {
                                parseValue = value.replace(/[^-.0-9]/g, "");
                              } else if (
                                dataType === "String" ||
                                dataType === "Time"
                              ) {
                                parseValue = value;
                              } else {
                                parseValue = value;
                              }
                            }
                            setInput({
                              ...input,
                              comment: parseValue,
                            });
                          }}
                          onBlur={() => {
                            let copyData = cloneDeep(data);
                            copyData[dataIndex]["data"][elIndex] = {
                              ...copyData[dataIndex]["data"][elIndex],
                              commentModify: false,
                              comment: input.comment,
                            };

                            if (
                              !changed.includes(
                                +copyData[dataIndex]["data"][elIndex].id
                              )
                            ) {
                              const check =
                                copyData[dataIndex]["data"][elIndex].comment !==
                                  temp[dataIndex]["data"][elIndex].comment ||
                                copyData[dataIndex]["data"][elIndex].value1 !==
                                  temp[dataIndex]["data"][elIndex].value1
                                  ? true
                                  : false;
                              if (check) {
                                let copyChanged = cloneDeep(changed);
                                copyChanged.push(
                                  copyData[dataIndex]["data"][elIndex].id
                                );
                                setChanged(copyChanged);
                              }
                            } else {
                              const check =
                                copyData[dataIndex]["data"][elIndex].comment ===
                                  temp[dataIndex]["data"][elIndex].comment &&
                                copyData[dataIndex]["data"][elIndex].value1 ===
                                  temp[dataIndex]["data"][elIndex].value1
                                  ? true
                                  : false;
                              if (check) {
                                let copyChanged = cloneDeep(changed);
                                copyChanged = copyChanged.filter(
                                  (item) =>
                                    item !==
                                    copyData[dataIndex]["data"][elIndex].id
                                );
                                setChanged(copyChanged);
                              }
                            }

                            setData(copyData);
                          }}
                          onKeyDown={onKeyupEscComment}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              let copyChange = cloneDeep(changed);
                              let copyData = cloneDeep(data);
                              let copyTemp = cloneDeep(temp);

                              if (!copyChange.includes(el.id)) {
                                const check =
                                  copyTemp?.[dataIndex]?.["data"]?.[elIndex]
                                    ?.comment !== input?.comment
                                    ? true
                                    : false;
                                if (check) {
                                  copyChange.push(el.id);
                                  setChanged(copyChange);
                                }
                              } else {
                                const check =
                                  copyTemp?.[dataIndex]?.["data"]?.[elIndex]
                                    ?.comment === input?.comment &&
                                  copyTemp?.[dataIndex]?.["data"]?.[elIndex]
                                    ?.value1 === input?.value1
                                    ? true
                                    : false;
                                if (check) {
                                  let copyChanged = cloneDeep(changed);
                                  let findIndex = copyChanged.indexOf(el.id);
                                  copyChanged = copyChanged.filter(
                                    (_, index) => index !== findIndex
                                  );
                                  setChanged(copyChanged);
                                }
                              }
                              copyData[dataIndex]["data"][elIndex] = {
                                ...copyData[dataIndex]["data"][elIndex],
                                commentModify: false,
                                comment: input.comment,
                              };
                              setData(copyData);
                            }
                          }}
                        />
                      ) : (
                        <span
                          id={`${el.id}-comment`}
                          title={el?.[typeValue] || "-"}
                        >
                          {el.type === "Number" || el.type === "Decimal"
                            ? numberWithCommas(el?.[typeValue] || "-")
                            : el?.[typeValue] || "-"}
                        </span>
                      )
                    ) : typeValue === "value1" ? (
                      el?.valueModify ? (
                        <input
                          id={`${el.id}-value1`}
                          key={`${el.id}-value1`}
                          value={
                            el.type === "Number" || el.type === "Decimal"
                              ? numberWithCommas(input?.value1 || "")
                              : input?.value1 || ""
                          }
                          name="value1"
                          style={{
                            textAlign: "right",
                            border: "1px solid #ddd",
                            height: "20px",
                            borderRadius: "0.2rem",
                            width: "250px",
                          }}
                          data-typeValue={typeValue}
                          data-dataIndex={dataIndex}
                          data-elIndex={elIndex}
                          data-elType={el.type}
                          data-elId={el.id}
                          onKeyDown={onKeyupEscValue}
                          onChange={(e) => {
                            const { value } = e.target;
                            const type = typeValue;
                            const dataType = el.type;
                            let parseValue = "";

                            if (type === "comment") {
                              parseValue = value;
                            } else {
                              if (dataType === "Decimal") {
                                //실수 체크
                                const _pattern2 = /^\d*[.]\d{5}$/;
                                if (_pattern2.test(value)) {
                                  return false;
                                } else {
                                  parseValue = value.replace(/[^-.0-9]/g, "");
                                }
                              } else if (dataType === "Number") {
                                parseValue = value.replace(/[^-.0-9]/g, "");
                              } else if (
                                dataType === "String" ||
                                dataType === "Time"
                              ) {
                                parseValue = value;
                              } else {
                                parseValue = value;
                              }
                            }

                            setInput({
                              ...input,
                              value1: parseValue,
                            });
                          }}
                          onBlur={() => {
                            let copyData = cloneDeep(data);
                            copyData[dataIndex]["data"][elIndex] = {
                              ...copyData[dataIndex]["data"][elIndex],
                              valueModify: false,
                              value1: input.value1,
                            };

                            if (
                              !changed.includes(
                                +copyData[dataIndex]["data"][elIndex].id
                              )
                            ) {
                              const check =
                                copyData[dataIndex]["data"][elIndex].comment !==
                                  temp[dataIndex]["data"][elIndex].comment ||
                                copyData[dataIndex]["data"][elIndex].value1 !==
                                  temp[dataIndex]["data"][elIndex].value1
                                  ? true
                                  : false;
                              if (check) {
                                let copyChanged = cloneDeep(changed);
                                copyChanged.push(
                                  copyData[dataIndex]["data"][elIndex].id
                                );
                                setChanged(copyChanged);
                              }
                            } else {
                              const check =
                                copyData[dataIndex]["data"][elIndex].comment ===
                                  temp[dataIndex]["data"][elIndex].comment &&
                                copyData[dataIndex]["data"][elIndex].value1 ===
                                  temp[dataIndex]["data"][elIndex].value1
                                  ? true
                                  : false;
                              if (check) {
                                let copyChanged = cloneDeep(changed);
                                copyChanged = copyChanged.filter(
                                  (item) =>
                                    item !==
                                    copyData[dataIndex]["data"][elIndex].id
                                );
                                setChanged(copyChanged);
                              }
                            }

                            setData(copyData);
                          }}
                          //onChange={onChangeValue}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              let copyChange = cloneDeep(changed);
                              let copyData = cloneDeep(data);
                              let copyTemp = cloneDeep(temp);

                              if (!copyChange.includes(+el.id)) {
                                const check =
                                  copyTemp?.[dataIndex]?.["data"]?.[elIndex]
                                    ?.value1 !== input?.value1
                                    ? true
                                    : false;
                                if (check) {
                                  copyChange.push(+el.id);
                                  setChanged(copyChange);
                                }
                              } else {
                                const check =
                                  copyTemp?.[dataIndex]?.["data"]?.[elIndex]
                                    ?.comment === input?.comment &&
                                  copyTemp?.[dataIndex]?.["data"]?.[elIndex]
                                    ?.value1 === input?.value1
                                    ? true
                                    : false;
                                if (check) {
                                  let copyChanged = cloneDeep(changed);
                                  let findIndex = copyChanged.indexOf(el.id);
                                  copyChanged = copyChanged.filter(
                                    (_, index) => index !== findIndex
                                  );
                                  setChanged(copyChanged);
                                }
                              }
                              copyData[dataIndex]["data"][elIndex] = {
                                ...copyData[dataIndex]["data"][elIndex],
                                valueModify: false,
                                value1: input.value1,
                              };
                              setData(copyData);
                            }
                          }}
                        />
                      ) : (
                        <span
                          id={`${el.id}-value1`}
                          title={el?.[typeValue] || "-"}
                        >
                          {el.type === "Number" || el.type === "Decimal"
                            ? numberWithCommas(el?.[typeValue] || "-")
                            : el?.[typeValue] || "-"}
                        </span>
                      )
                    ) : (
                      <span>{"-"}</span>
                    )}
                  </span>
                </td>
              </tr>
            </Tooltip>
          );
        })}
      </td>
    );
  };

  return (
    <Flex
      style={{
        padding: "10px 10px 10px 10px",
        width: "100%",
      }}
    >
      <Flex
        column
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: "3px",
          marginTop: "-5px",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            background: "#ddd",
            opacity: 0.5,
            minWidth: 70,
            height: 38,
            width: 40,
            right: 85,
            zIndex: 10,
            display: changed.length > 0 ? "none" : "block",
            position: "absolute",
          }}
        />
        <AdminConfirmButton
          popupLabel="환경설정 변경"
          popupMessage="변경사항을 저장하시겠습니까?"
          label="저장"
          callback={onClickSave}
          disabled={changed.length > 0 ? false : true}
          rootClassName={classes.confirmRoot}
          btnClassName={classes.confirmButton}
        />
        <button
          className={classes.confirmButton}
          disabled={changed.length > 0 ? false : true}
          onClick={() => {
            setData(temp);
            setChanged([]);
          }}
        >
          취소
        </button>
      </Flex>
      <Tooltip openDelay={100} anchorElement="target" position="top">
        <GridTable
          name={"SettingConfiguration"}
          reorderable
          data={data}
          style={{
            height: "80vh",
            width: "100%",
            overflow: "auto",
            overflowX: "scroll",
          }}
          scrollable
          //pager={true}
        >
          <Column title="분류" field="name" cell={centerCell} width={100} />
          <Column title="키" cell={(props) => customCell(props, "configKey")} />
          <Column
            title="자산"
            width={100}
            cell={(props) => {
              const { dataItem } = props;
              const dataArr = dataItem["data"];
              return (
                <td className="td-c">
                  {(dataArr || []).map((el) => {
                    const { assetType } = el;
                    const parseType =
                      assetType === "GOLD"
                        ? "금"
                        : assetType === "SILVER"
                        ? "은"
                        : "-";
                    return (
                      <tr style={{ height: "3vh" }}>
                        <td
                          className="td-c"
                          style={{
                            width: "100vw",
                            height: "3vh",
                            background: changed.includes(el.id)
                              ? "#f89a9a"
                              : "none",
                          }}
                        >
                          {parseType}
                        </td>
                      </tr>
                    );
                  })}
                </td>
              );
            }}
          />
          <Column
            title="값"
            field="value1"
            cell={(props) => customEditCell(props, "value1")}
            width={240}
          />
          <Column
            title="설명"
            field="comment"
            width={350}
            cell={(props) => customEditCell(props, "comment")}
          />
          <Column
            title="타입"
            cell={(props) => customCell(props, "type")}
            width={100}
          />
          <Column
            title="등록일"
            width={250}
            cell={(props) => customCell(props, "createdAt")}
          />
          <Column
            title="관리자"
            width={100}
            cell={(props) => customCell(props, "managerId")}
          />
        </GridTable>
      </Tooltip>
    </Flex>
  );
};

export default SettlementConfiguration;

const useStyle = makeStyles({
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 5,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});
