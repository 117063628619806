import React, { useEffect, useState, useCallback } from "react";
import * as ReactDOM from "react-dom";
import {
  Form,
  FieldArray,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { clone } from "@progress/kendo-react-common";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Input,
  ColorPicker,
  NumericTextBox,
} from "@progress/kendo-react-inputs";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import { actionError, actionOpen } from "../../redux/action/ActionActions";

export const FormGridEditContext = React.createContext({});
const FORM_DATA_INDEX = "formDataIndex";
const DATA_ITEM_KEY = "id";

const PrintManagement = () => {
  const [labelConfigList, setLabelConfigList] = useState([]);
  const [editIndex, setEditIndex] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getGoodsLabelConfig()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setLabelConfigList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };
  const NumberCell = (props) => {
    const { editIndex } = React.useContext(FormGridEditContext);
    const [orderQuantity, setOrderQuantity] = useState(
      props.dataItem["minCount"]
    );
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return isInEdit ? (
      <td style={{ textAlign: "center" }}>
        <NumericTextBox
          spinners={false}
          width={80}
          value={orderQuantity}
          onChange={(e) => {
            setOrderQuantity(e.value);
            labelConfigList[props.dataItem[FORM_DATA_INDEX]]["minCount"] =
              e.value;
          }}
        />
        이상
      </td>
    ) : (
      <td style={{ textAlign: "center" }}>{props.dataItem[props.field]}</td>
    );
  };

  const ColorCell = (props) => {
    const { editIndex } = React.useContext(FormGridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const [labelColor, setLabelColor] = useState(
      props.dataItem["labelColor"] || null
    );
    return isInEdit ? (
      <td style={{ textAlign: "center" }}>
        {
          <ColorPicker
            value={labelColor}
            onChange={(e) => {
              setLabelColor(e.value);
              labelConfigList[props.dataItem[FORM_DATA_INDEX]]["labelColor"] =
                e.value;
            }}
          />
        }
      </td>
    ) : (
      <td style={{ textAlign: "center" }}>
        <ColorPicker value={props.dataItem[props.field]} />
      </td>
    );
  };

  const CommandCell = (props) => {
    const { onRemove, onEdit, onSave, onCancel, editIndex } =
      React.useContext(FormGridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const isNewItem = !props.dataItem[DATA_ITEM_KEY];
    const onRemoveClick = useCallback(
      (e) => {
        e.preventDefault();
        onRemove(props.dataItem.formDataIndex, isNewItem);
      },
      [props.dataItem, onRemove]
    );
    const onEditClick = useCallback(
      (e) => {
        e.preventDefault();
        onEdit(props.dataItem, isNewItem, editIndex);
      },
      [props.dataItem, onEdit, isNewItem, editIndex]
    );
    const onSaveClick = useCallback(
      (e) => {
        e.preventDefault();
        onSave();
      },
      [onSave]
    );
    const onCancelClick = useCallback(
      (e) => {
        e.preventDefault();
        onCancel(props.dataItem);
      },
      [props.dataItem, onCancel]
    );

    //수정 상태일 때
    return isInEdit ? (
      <td className="k-command-cell" style={{ textAlign: "center" }}>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
          onClick={isNewItem ? onSaveClick : onEditClick}
        >
          {isNewItem ? "저장" : "수정"}
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
          onClick={isNewItem ? onRemoveClick : onCancelClick}
        >
          {isNewItem ? "삭제" : "제거"}
        </button>
      </td>
    ) : (
      //수정상태가 아닐때 클릭
      <td className="k-command-cell" style={{ textAlign: "center" }}>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
          onClick={onEditClick}
        >
          수정
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
          onClick={isNewItem ? onRemoveClick : onCancelClick}
        >
          삭제
        </button>
      </td>
    );
  };

  const FormGrid = (fieldArrayRenderProps) => {
    const { validationMessage, visited, name, dataItemKey } =
      fieldArrayRenderProps;

    const editItemCloneRef = React.useRef();
    const dataWithIndexes = labelConfigList.map((item, index) => {
      return {
        ...item,
        [FORM_DATA_INDEX]: index,
      };
    });

    const onAdd = useCallback(
      (e) => {
        e.preventDefault();
        const copy = [...dataWithIndexes];
        copy.unshift({
          id: "",
          minCount: "",
          labelColor: "",
        });
        setLabelConfigList(copy);
        setEditIndex(0);
      },
      [labelConfigList]
    );

    const onRemove = useCallback(
      (dataItem, isNewItem) => {
        const copy = [...dataWithIndexes];
        const newData = copy.filter((item) => item.formDataIndex !== dataItem);
        setLabelConfigList(newData);
        setEditIndex(undefined);
      },
      [labelConfigList]
    );
    //목록의 첫번째 아이템이 수정이 안되었다. 확인 필요
    const onEdit = useCallback(
      (dataItem, isNewItem, editIndex) => {
        setEditIndex(dataItem[FORM_DATA_INDEX]); // editIndex로 화면을 바꾼다.
        if (!isNewItem) {
          //기존 데이터
          if (editIndex || editIndex === 0) {
            editItemCloneRef.current = clone(dataItem);
            if (dataItem[FORM_DATA_INDEX] === editIndex) {
              const data = {
                minCount: labelConfigList[editIndex].minCount,
                labelColor: labelConfigList[editIndex].labelColor,
              };
              dispatch(loadingStart);
              APIS.putGoodsLabelConfig(dataItem.id, data)
                .then(({ data: { success, data, message } }) => {
                  if (success) {
                    dispatch(
                      actionOpen(
                        "해당 설정이 수정되었습니다.",
                        () => {
                          fetchList();
                        },
                        null,
                        null,
                        true
                      )
                    );
                  } else {
                    dispatch(
                      actionOpen(
                        message,
                        () => {
                          fetchList();
                        },
                        null,
                        null,
                        true
                      )
                    );
                  }
                })
                .finally(() => {
                  dispatch(loadingEnd);
                  setEditIndex(null);
                });
            }
          }
        }
      },
      [editIndex]
    );

    const onCancel = useCallback(
      (dataItem) => {
        // if (editItemCloneRef.current) {
        //   fieldArrayRenderProps.onReplace({
        //     index: editItemCloneRef.current[FORM_DATA_INDEX],
        //     value: editItemCloneRef.current,
        //   });
        // }
        // editItemCloneRef.current = undefined;
        dispatch(loadingStart);
        APIS.deleteGoodsLabelConfig(dataItem.id)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "해당 설정이 제거되었습니다.",
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
            } else {
              dispatch(actionError(message));
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            setEditIndex(null);
          });
      },
      [fieldArrayRenderProps]
    );

    const onSave = useCallback(() => {
      if (
        labelConfigList[editIndex].minCount === "" ||
        labelConfigList[editIndex].labelColor === ""
      ) {
        dispatch(
          actionError(
            "설정값이 비어있습니다. 값을 선택하여 저장해주시기 바랍니다."
          )
        );
      } else {
        const data = {
          minCount: labelConfigList[editIndex].minCount,
          labelColor: labelConfigList[editIndex].labelColor,
        };
        dispatch(loadingStart);
        APIS.postGoodsLabelConfig(data)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "해당 설정이 저장되었습니다.",
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
            } else {
              dispatch(
                actionOpen(
                  message,
                  () => {
                    fetchList();
                  },
                  null,
                  null,
                  true
                )
              );
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            setEditIndex(null);
          });
      }
    }, [fieldArrayRenderProps]);

    return (
      <FormGridEditContext.Provider
        value={{
          onCancel,
          onEdit,
          onRemove,
          onSave,
          editIndex,
          parentField: name,
        }}
      >
        {visited && validationMessage && <Error>{validationMessage}</Error>}
        <Grid
          data={dataWithIndexes}
          dataItemKey={DATA_ITEM_KEY}
          scrollable
          style={{ margin: "20px 50px 0px 50px", width: "50%", height: 730 }}
        >
          <GridToolbar>
            <button
              title="Add new"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={onAdd}
            >
              추가하기
            </button>
          </GridToolbar>
          <GridColumn
            field="minCount"
            title="주문수량 별 색상 설정"
            cell={NumberCell}
          />
          <GridColumn field="labelColor" title="색상" cell={ColorCell} />
          <GridColumn cell={CommandCell} width="240px" />
        </Grid>
      </FormGridEditContext.Provider>
    );
  };

  // const handleSubmit = (dataItem) => alert(JSON.stringify(dataItem));
  return (
    <Form
      initialValues={{ products: labelConfigList }}
      // onSubmit={handleSubmit}
      render={() => (
        <FormElement>
          <FieldArray
            name="products"
            dataItemKey={DATA_ITEM_KEY}
            component={FormGrid}
          />
        </FormElement>
      )}
    />
  );
};

export default PrintManagement;
