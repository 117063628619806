import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Flex from "../flex/Flex";
import { useHistory, useLocation } from "react-router";
import { formatTime } from "../../services/utils";
import Button from "../../components/button/Button";

export default function DateRange({
  startDate,
  endDate,
  period = ["today", "week", "month", "month3"],
}) {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const [searchType, setSearchType] = useState(
    period.includes("today") === true ? "today" : period[0]
  );
  const [startDateset, setstartDateset] = useState(
    period.includes("today") === true
      ? formatTime(new Date(), "YYYY-MM-DD")
      : period[0] === "week"
      ? formatTime(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          "YYYY-MM-DD"
        )
      : period[0] === "month"
      ? formatTime(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            new Date().getDate()
          ),
          "YYYY-MM-DD"
        )
      : period[0] === "month3"
      ? formatTime(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3,
            new Date().getDate()
          ),
          "YYYY-MM-DD"
        )
      : ""
  );
  const [endDateset, setendDateset] = useState(
    formatTime(new Date(), "YYYY-MM-DD")
  );

  const startDatemod = (v) => {
    startDate(v);
    setstartDateset(v);
  };
  const endDatemod = (v) => {
    endDate(v);
    setendDateset(v);
  };
  const dateSetfunc = (types) => {
    var today = new Date();
    if (types === "week") {
      startDate(
        formatTime(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
          "YYYY-MM-DD"
        )
      );
      setstartDateset(
        formatTime(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
          "YYYY-MM-DD"
        )
      );
      endDate(formatTime(new Date(), "YYYY-MM-DD"));
      setendDateset(formatTime(new Date(), "YYYY-MM-DD"));
      setSearchType("week");
      handleValueChange("searchType", "week");
    } else if (types === "month") {
      startDate(
        formatTime(
          new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
          "YYYY-MM-DD"
        )
      );
      setstartDateset(
        formatTime(
          new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
          "YYYY-MM-DD"
        )
      );
      endDate(formatTime(new Date(), "YYYY-MM-DD"));
      setendDateset(formatTime(new Date(), "YYYY-MM-DD"));
      setSearchType("month");
      handleValueChange("searchType", "month");
    } else if (types === "month3") {
      startDate(
        formatTime(
          new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
          "YYYY-MM-DD"
        )
      );
      setstartDateset(
        formatTime(
          new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
          "YYYY-MM-DD"
        )
      );
      endDate(formatTime(new Date(), "YYYY-MM-DD"));
      setendDateset(formatTime(new Date(), "YYYY-MM-DD"));
      setSearchType("month3");
      handleValueChange("searchType", "month3");
    } else {
      startDate(formatTime(new Date(), "YYYY-MM-DD"));
      setstartDateset(formatTime(new Date(), "YYYY-MM-DD"));
      endDate(formatTime(new Date(), "YYYY-MM-DD"));
      setendDateset(formatTime(new Date(), "YYYY-MM-DD"));
      setSearchType("today");
      handleValueChange("searchType", "today");
    }
  };
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };
  
  return (
    <Flex row className={classes.root}>
      <input
        type="date"
        value={startDateset}
        className={classes.input}
        onChange={(e) => startDatemod(e.target.value)}
      />
      <div style={{ margin: "0px 10px" }}>~</div>
      <input
        type="date"
        value={endDateset}
        className={classes.input}
        onChange={(e) => endDatemod(e.target.value)}
      />
      {period.map((item, index) => {
        if (item === "today") {
          return (
            <ButtonBase
              key={index}
              style={{ marginLeft: 20 }}
              className={
                searchType === "today"
                  ? classes.selectedButton
                  : classes.dateButton
              }
              onClick={() => dateSetfunc("today")}
            >
              당일
            </ButtonBase>
          );
        } else if (item === "week") {
          return (
            <ButtonBase
              key={index}
              style={{ marginLeft: 10 }}
              className={
                searchType === "week"
                  ? classes.selectedButton
                  : classes.dateButton
              }
              onClick={() => dateSetfunc("week")}
            >
              1주
            </ButtonBase>
          );
        } else if (item === "month") {
          return (
            <ButtonBase
              key={index}
              style={{ marginLeft: 10 }}
              className={
                searchType === "month"
                  ? classes.selectedButton
                  : classes.dateButton
              }
              onClick={() => dateSetfunc("month")}
            >
              1개월
            </ButtonBase>
          );
        } else if (item === "month3") {
          return (
            <ButtonBase
              key={index}
              style={{ marginLeft: 10 }}
              className={
                searchType === "month3"
                  ? classes.selectedButton
                  : classes.dateButton
              }
              onClick={() => dateSetfunc("month3")}
            >
              3개월
            </ButtonBase>
          );
        }
      })}
      {/* {typeNotUse !== "today" && (
        <ButtonBase
          style={{ marginLeft: 20 }}
          className={
            searchType === "today" ? classes.selectedButton : classes.dateButton
          }
          onClick={() => dateSetfunc("today")}
        >
          당일
        </ButtonBase>
      )}
      &nbsp;
      <ButtonBase
        className={
          searchType === "week" ? classes.selectedButton : classes.dateButton
        }
        onClick={() => dateSetfunc("week")}
      >
        1주
      </ButtonBase>
      &nbsp;
      <ButtonBase
        className={
          searchType === "month" ? classes.selectedButton : classes.dateButton
        }
        onClick={() => dateSetfunc("month")}
      >
        1개월
      </ButtonBase>
      &nbsp;
      <ButtonBase
        className={
          searchType === "month3" ? classes.selectedButton : classes.dateButton
        }
        onClick={() => dateSetfunc("month3")}
      >
        3개월
      </ButtonBase> */}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: 6,
    minWidth: 150,
  },
  dateButton: {
    alignSelf: "stretch",
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "6px 20px",
    background: "#f5f5f5",
  },
  selectedButton: {
    alignSelf: "stretch",
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "6px 20px",
    background: "#000",
    color: "#fff",
  },
});
