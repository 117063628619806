import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import moment from "moment-timezone";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";

import {
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import GridTable, {
  centerCell,
  numberWithCommasCell,
} from "components/gird-table/GridTable";
import Flex from "components/flex/Flex";
import Button from "components/button/Button";
import KendoButton from "components/button/KendoButton";
import SimpleTable from "components/simple-table/SimpleTable";
import ButtonRadios from "components/button-radios/ButtonRadios";
import { actionError, actionOpen } from "redux/action/ActionActions";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";

import { formatTime, numFormat, formatPhone } from "services/utils";
import * as APIS from "libs/apis";
import { numberWithCommas } from "libs/utils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { GridColumn as Column } from "@progress/kendo-react-grid";

import ComponentToPrint from "./ComponentToPrint";

const gridName = "AppraisalDetailInfo";

const DialogForm = styled.div`
  max-height: 700px;
`;

export default function StatusUpdater({
  appraisalRequestStatus,
  status,
  id,
  goldProduct,
  silverProduct,
  assetType,
  goldGramPrice,
  silverGramPrice,
  fetchList,
  detailData,
}) {
  const authReducer = useSelector((s) => s.auth);
  let clickCheck = false;
  const { isKorda } = authReducer;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [t3, setT3] = useState(""); // 반송 - 사유
  const [approvalReason, setApprovalReason] = useState(""); //  승인 사유 보여주는 칸
  const [r3, setR3] = useState(""); // 반송 라운드 버튼
  const [approval, setApproval] = useState("1"); //승인 라운드 버튼
  const locationState = location.state || {};
  const { tab } = locationState;
  const [approvalList, setApprovalList] = useState(
    tab === "GOLD"
      ? goldProduct
      : tab === "SILVER"
      ? silverProduct
      : goldProduct
  );

  const [selectedEl, setSelectedEl] = useState("");
  const [show, setShow] = useState(false);
  const printComponentRef = useRef(null);
  const currentPosition = useRef("");
  // const [totalGram, setTotalGram] = useState(0);
  const tableData = {
    receptionStore: detailData?.shop?.name,
    receptionNumber: detailData?.number,
    requestDate: moment(
      detailData?.appraisalRequestStatus?.find((x) => x.status === "접수")
        .createdAt
    ).format("YYYY.MM.DD HH:mm"),
    memberName: detailData?.user?.name,
    memberId: detailData?.user?.id,
    receptionMethod: detailData?.method,
    progressStatus: detailData?.status,
    reservationDate:
      detailData?.method === "현장감정"
        ? "-"
        : detailData?.method === "택배"
        ? detailData?.collectDate + " " + detailData?.collectTime
        : detailData?.visitDate,
    modifiedDate: moment(
      detailData?.appraisalRequestStatus[
        detailData?.appraisalRequestStatus?.length - 1
      ].updatedAt
    ).format("YYYY.MM.DD HH:mm"),
    phone: formatPhone(detailData?.user.phone),
  };
  useEffect(() => {
    !tab &&
      history.replace(location.pathname, {
        ...location.state,
        tab:
          assetType === "GOLD"
            ? "GOLD"
            : assetType === "SILVER"
            ? "SILVER"
            : "GOLD",
      });

    setApprovalList(
      tab === "GOLD"
        ? goldProduct
        : tab === "SILVER"
        ? silverProduct
        : goldProduct
    );
  }, [tab]);

  useEffect(() => {
    function setCaretPosition(elemId) {
      let elem = document.getElementById(elemId);
      const position = currentPosition?.current || 0;

      if (elem != null) {
        if (elem.createTextRange) {
          var range = elem?.createTextRange();
          range?.move("character", position);
          range?.select();
        } else {
          if (elem.selectionStart) {
            elem?.focus();
            elem?.setSelectionRange(position, position);
          } else elem?.focus();
        }
      }
    }
    setCaretPosition(selectedEl);
  }, [selectedEl, approvalList]);

  const avoidDuplicateClicks = (func) => {
    if (!clickCheck) {
      clickCheck = true;
      func();
    }
  };

  const onSave = async () => {
    if (["감정완료", "반송신청", "반송반려", "교환완료"].includes(status)) {
      if (!r3) {
        return dispatch(
          actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (r3 === "2" && !t3) {
        return dispatch(
          actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }
      return APIS.putAppraisalRequestStatus(
        id,
        r3 === "1"
          ? isKorda
            ? {
                status: "RETURN_COMPLETE",
                returnCancelReason: "",
              }
            : status === "교환완료"
            ? {
                status:
                  status === "교환완료" ? "RETURN_WAIT" : "RETURN_COMPLETE",
                returnCancelReason: status === "교환완료" ? t3 : "",
                appraiseGram:
                  status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).appraiseGram
                    : "",
                assetType:
                  status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).assetType
                    : "",
              }
            : { status: "RETURN_COMPLETE", returnCancelReason: "" }
          : {
              status: "RETURN_DENY",
              returnCancelReason: t3,
            },
        isKorda
      )
        .then((res) => {
          if (res.data.success) {
            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r3 === "1"
                  ? status === "교환완료"
                    ? "반송 대기 처리 되었습니다."
                    : "반송 완료 처리 되었습니다."
                  : "반송반려 되었습니다.",
                () => {
                  fetchList();
                },
                "",
                "",
                true
              )
            );
          } else {
            dispatch(actionError(res.data.message));
          }
        })
        .catch((err) => {
          dispatch(actionError(err));
        });
    }
  };

  const onSaveAdmin = async (adminPassword) => {
    if (!clickCheck) {
      clickCheck = true;
      if (["승인대기"].includes(status)) {
        if (!approval) {
          return dispatch(
            actionOpen("승인대기 상태를 선택해 주세요.", null, "", "", true)
          );
        } else if (approval === "1" && !approvalReason) {
          return dispatch(
            actionOpen("승인 사유를 입력해 주세요.", null, "", "", true)
          );
        } else if (approval === "2" && !t3) {
          return dispatch(
            actionOpen("승인 반려 사유를 입력해 주세요.", null, "", "", true)
          );
        }
        const assetType =
          goldProduct.length > 0
            ? "GOLD"
            : silverProduct.length > 0
            ? "SILVER"
            : "";
        const appraiseSum =
          goldProduct.length > 0
            ? goldProduct
                .map((item) => item.appraisalWeightGram)
                .reduce((prev, curr) => prev + curr, 0)
            : silverProduct.length > 0
            ? silverProduct
                .map((item) => item.appraisalWeightGram)
                .reduce((prev, curr) => prev + curr, 0)
            : "";
        return APIS.putAppraisalRequestStatus(
          id,
          approval === "1"
            ? {
                status: "VERIFY_COMPLETE",
                appraiseMemo: approvalReason,
                appraiseGram: appraiseSum,
                assetType: assetType,
                password: adminPassword,
              }
            : {
                status: "CONFIRM_DENY",
                returnCancelReason: t3,
                password: adminPassword,
              },
          isKorda
        )
          .then((res) => {
            if (res.data.success) {
              history.replace({
                pathname: history.location.pathname,
                state: history.location.state,
              });

              return dispatch(
                actionOpen(
                  approval === "1"
                    ? "승인완료 처리 되었습니다."
                    : "승인반려 되었습니다.",
                  () => {
                    fetchList();
                  },
                  "",
                  "",
                  true
                )
              );
            } else {
              dispatch(actionError(res.data.message));
            }
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      } else if (["반송신청"].includes(status)) {
        if (!r3) {
          return dispatch(
            actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
          );
        }
        return APIS.putAppraisalRequestStatus(
          id,
          r3 === "1"
            ? {
                status: "RETURN_COMPLETE",
                returnCancelReason: "",
                password: adminPassword,
              }
            : {
                status: "RETURN_DENY",
                returnCancelReason: t3,
                password: adminPassword,
              },
          isKorda
        )
          .then((res) => {
            if (res.data.success) {
              history.replace({
                pathname: history.location.pathname,
                state: history.location.state,
              });

              return dispatch(
                actionOpen(
                  r3 === "1"
                    ? "반송 완료 처리 되었습니다."
                    : "반송반려 되었습니다.",
                  () => {
                    fetchList();
                  },
                  "",
                  "",
                  true
                )
              );
            } else {
              dispatch(actionError(res.data.message));
            }
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      } else if (["반송대기"].includes(status)) {
        if (!r3) {
          return dispatch(
            actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
          );
        } else if (r3 === "2" && !t3) {
          return dispatch(
            actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
          );
        }
        return APIS.putAppraisalRequestStatus(
          id,
          r3 === "1"
            ? {
                status: "RETURN_COMPLETE",
                returnCancelReason: "",
                password: adminPassword,
              }
            : {
                status: "RETURN_DENY",
                returnCancelReason: t3,
                password: adminPassword,
              },
          isKorda
        )
          .then((res) => {
            if (res.data.success) {
              history.replace({
                pathname: history.location.pathname,
                state: history.location.state,
              });

              return dispatch(
                actionOpen(
                  r3 === "1"
                    ? "반송 완료 처리 되었습니다."
                    : "반송반려 되었습니다.",
                  () => {
                    fetchList();
                  },
                  "",
                  "",
                  true
                )
              );
            } else {
              dispatch(actionError(res.data.message));
            }
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      }
    }
  };

  const handlePrint = () => {
    setShow(!show);
  };
  const printContent = useReactToPrint({
    content: () => printComponentRef.current,
  });
  const onClickPrint = () => {
    printContent();
  };
  const reformData = (data) => {
    const copyData = [...data] || [];
    const parseData = [];
    copyData?.map((item, index) => {
      parseData.push({
        createdAt: formatTime(item.createdAt, "YYYY-MM-DD HH:mm:ss") || "-",
        status: item.status
          ? item.status
          : item.stockStatus
          ? item.stockStatus
              .replace("CANCEL_WAIT", "입고취소 요청")
              .replace("CANCEL", "입고취소")
              .replace("WAIT", "입고대기")
              .replace("DELIVERY", "입고배송")
              .replace("TAKE", "재고수령")
              .replace("COMPLETE", "입고완료")
          : "-",
        admin: item.admin || "-",
        assetType: item.assetType || "-",
        appraiseGram: numberWithCommas(item.appraiseGram) || "-",
        appraiseMemo:
          appraisalRequestStatus[index].status === "감정반려"
            ? item.appraiseCancelReason || "-"
            : appraisalRequestStatus[index].status === "감정완료"
            ? item.appraiseMemo || "-"
            : appraisalRequestStatus[index].status === "반송반려"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "승인대기"
            ? item.appraiseMemo || "-"
            : appraisalRequestStatus[index].status === "승인반려"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "반송대기"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "감정시작"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "반송완료"
            ? item.returnCancelReason || "-"
            : item.appraiseMemo || "-",
        managerName: item.managerName || "-",
      });
    });
    return parseData;
  };

  const parseData = reformData(appraisalRequestStatus);
  return (
    <>
      <Flex row>
        <Grid container className={classes.grid}>
          {appraisalRequestStatus.length > 0 ? (
            status === "접수" || status === "감정시작" ? (
              <></>
            ) : status === "승인대기" ? (
              isKorda ? (
                <>
                  <Grid item className={classes.gridLabel} xs={2}>
                    승인대기
                  </Grid>
                  <Grid item xs={10} className={classes.gridValue}>
                    <RadioGroup
                      value={approval}
                      onChange={(e) => {
                        setApproval(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        disabled={status !== "승인대기"}
                        value={"1"}
                        label="승인완료"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        disabled={status !== "승인대기"}
                        value={"2"}
                        label="승인반려"
                        control={<Radio />}
                      />
                    </RadioGroup>
                    {approval === "1" ? (
                      <>
                        <input
                          value={approvalReason}
                          type="text"
                          autoComplete="off"
                          onChange={(e) => {
                            setApprovalReason(e.target.value);
                          }}
                          // disabled={approval === "2"}
                          placeholder="승인 사유 입력"
                          className={classes.input}
                        />
                        <input
                          style={{
                            position: "absolute",
                            opacity: 0,
                            width: 0,
                            height: 0,
                            zIndex: -10,
                          }}
                          type="text"
                          value=""
                          autoComplete="on"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          value={t3}
                          type="text"
                          autoComplete="off"
                          onChange={(e) => {
                            setT3(e.target.value);
                          }}
                          // disabled={approval === "2"}
                          placeholder="반려 사유 입력"
                          className={classes.input}
                        />
                        <input
                          style={{
                            position: "absolute",
                            opacity: 0,
                            width: 0,
                            height: 0,
                            zIndex: -10,
                          }}
                          type="text"
                          value=""
                          autoComplete="on"
                        />
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <></>
              )
            ) : status === "반송대기" ? (
              isKorda ? (
                <Flex row>
                  <Grid item className={classes.gridLabel} xs={2}>
                    반송(돌려받기)
                  </Grid>
                  <Grid item xs={10} className={classes.gridValue}>
                    <RadioGroup
                      // value={r3}
                      onChange={(e) => {
                        setT3("");
                        setR3(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        disabled={
                          !(
                            (appraisalRequestStatus.filter(
                              (x) => x.status === "반송신청"
                            ).length > 0 ||
                              appraisalRequestStatus.filter(
                                (x) => x.status === "감정완료"
                              ).length > 0) &&
                            appraisalRequestStatus.filter(
                              (x) => x.status === "반송완료"
                            ).length === 0
                          )
                        }
                        value={"1"}
                        label="반송완료"
                        control={<Radio />}
                      />
                      {isKorda ? (
                        <FormControlLabel
                          value={"2"}
                          disabled={
                            !(
                              appraisalRequestStatus.filter(
                                (x) => x.status === "반송신청"
                              ).length === 0 ||
                              appraisalRequestStatus.filter(
                                (x) => x.status === "반송완료"
                              ).length === 0 ||
                              appraisalRequestStatus.filter(
                                (x) => x.status === "감정완료"
                              ).length > 0
                            )
                          }
                          label="반송반려"
                          control={<Radio />}
                        />
                      ) : (
                        <></>
                      )}
                    </RadioGroup>
                    <input
                      className={classes.input}
                      type="text"
                      autoComplete="off"
                      disabled={
                        !(
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          ).length > 0 && r3 === "2"
                        )
                      }
                      // value={t3}
                      onChange={(e) => {
                        setT3(e.target.value);
                      }}
                      placeholder="반송 반려 사유 입력"
                    />
                    <input
                      style={{
                        position: "absolute",
                        opacity: 0,
                        width: 0,
                        height: 0,
                        zIndex: -10,
                      }}
                      type="text"
                      value=""
                      autoComplete="on"
                    />
                  </Grid>
                </Flex>
              ) : (
                //반송대기 상태일 때 본사인지 아닌지
                <></>
              )
            ) : status === "감정완료" ? (
              <></>
            ) : status === "반송신청" ? (
              isKorda ? (
                <Flex row>
                  <Grid item className={classes.gridLabel} xs={2}>
                    반송(돌려받기)
                  </Grid>
                  <Grid item xs={10} className={classes.gridValue}>
                    <RadioGroup
                      // value={r3}
                      onChange={(e) => {
                        setT3("");
                        setR3(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        disabled={
                          !(
                            (appraisalRequestStatus.filter(
                              (x) => x.status === "반송신청"
                            ).length > 0 ||
                              appraisalRequestStatus.filter(
                                (x) => x.status === "감정완료"
                              ).length > 0) &&
                            appraisalRequestStatus.filter(
                              (x) => x.status === "반송완료"
                            ).length === 0
                          )
                        }
                        value={"1"}
                        label="반송완료"
                        control={<Radio />}
                      />
                      {isKorda ? (
                        <FormControlLabel
                          value={"2"}
                          disabled={
                            status === "반송신청" ||
                            status === "반송완료" ||
                            status === "감정완료"
                          }
                          label="반송반려"
                          control={<Radio />}
                        />
                      ) : (
                        <></>
                      )}
                    </RadioGroup>
                    <input
                      className={classes.input}
                      type="text"
                      autoComplete="off"
                      disabled={
                        !(
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          ).length > 0 && r3 === "2"
                        )
                      }
                      // value={t3}
                      onChange={(e) => {
                        setT3(e.target.value);
                      }}
                      placeholder="반송 반려 사유 입력"
                    />
                  </Grid>
                </Flex>
              ) : (
                <Flex row>
                  <Grid item className={classes.gridLabel} xs={2}>
                    반송(돌려받기)
                  </Grid>
                  <Grid item xs={10} className={classes.gridValue}>
                    <RadioGroup
                      // value={r3}
                      onChange={(e) => {
                        setT3("");
                        setR3(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        disabled={
                          !(
                            (appraisalRequestStatus.filter(
                              (x) => x.status === "반송신청"
                            ).length > 0 ||
                              appraisalRequestStatus.filter(
                                (x) => x.status === "감정완료"
                              ).length > 0) &&
                            appraisalRequestStatus.filter(
                              (x) => x.status === "반송완료"
                            ).length === 0
                          )
                        }
                        value={"1"}
                        label="반송완료"
                        control={<Radio />}
                      />
                      {
                        <FormControlLabel
                          value={"2"}
                          disabled={
                            appraisalRequestStatus.filter(
                              (x) => x.status === "감정완료"
                            ).length > 0
                          }
                          label="반송반려"
                          control={<Radio />}
                        />
                      }
                    </RadioGroup>
                    <input
                      className={classes.input}
                      type="text"
                      autoComplete="off"
                      disabled={
                        !(
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          ).length > 0 && r3 === "2"
                        )
                      }
                      // value={t3}
                      onChange={(e) => {
                        setT3(e.target.value);
                      }}
                      placeholder="반송 반려 사유 입력"
                    />
                  </Grid>
                </Flex>
              )
            ) : status === "교환완료" ? (
              isKorda ? (
                <></>
              ) : (
                <Flex row>
                  <Grid item className={classes.gridLabel} xs={2}>
                    반송(돌려받기)
                  </Grid>
                  <Grid item xs={10} className={classes.gridValue}>
                    <RadioGroup
                      // value={r3}
                      onChange={(e) => {
                        setT3("");
                        setR3(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        value={"1"}
                        label="반송요청"
                        control={<Radio />}
                      />
                      {isKorda ? (
                        <FormControlLabel
                          value={"2"}
                          disabled={
                            appraisalRequestStatus.filter(
                              (x) => x.status === "감정완료"
                            ).length > 0
                          }
                          label="반송반려"
                          control={<Radio />}
                        />
                      ) : (
                        <></>
                      )}
                    </RadioGroup>
                    <input
                      className={classes.input}
                      type="text"
                      autoComplete="nope"
                      disabled={
                        !(
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          ).length > 0 && r3 === "1"
                        )
                      }
                      value={t3}
                      onChange={(e) => {
                        setT3(e.target.value);
                      }}
                      placeholder="반송 요청 사유 입력"
                    />
                    <input
                      style={{
                        position: "absolute",
                        opacity: 0,
                        width: 0,
                        height: 0,
                        zIndex: -10,
                      }}
                      type="text"
                      value=""
                      autoComplete="on"
                    />
                  </Grid>
                </Flex>
              )
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </Grid>
        <Flex row style={{ marginTop: 15 }}>
          {isKorda && (status === "승인대기" || status === "반송대기") ? (
            <>
              <AdminConfirmButton
                popupLabel="감정평가"
                popupMessage="저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
              <Button
                onClick={history.goBack}
                label="취소"
                className={classes.bt1}
              />
            </>
          ) : !isKorda &&
            (status === "승인대기" ||
              status === "반송대기" ||
              status === "감정반려" ||
              status === "반송반려" ||
              status === "반송완료" ||
              status === "감정완료") ? (
            <></>
          ) : isKorda &&
            (status === "감정반려" ||
              status === "반송완료" ||
              status === "반송반려" ||
              status === "교환완료" ||
              status === "감정완료" ||
              status === "감정시작" ||
              status === "접수취소" ||
              status === null ||
              status === "접수") ? (
            <></>
          ) : (
            <>
              <Button
                label="저장"
                className={classes.bt2}
                onClick={() => {
                  avoidDuplicateClicks(onSave);
                }}
              />
              <Button
                onClick={history.goBack}
                label="취소"
                className={classes.bt1}
              />
            </>
          )}
        </Flex>
      </Flex>
      {status === "접수" || status === "감정시작" ? (
        <></>
      ) : (
        <Flex>
          <AppraisalDetailContainer>
            <ButtonRadios
              data={[
                { label: "금 (GOLD)", value: "GOLD" },
                { label: "은 (SILVER)", value: "SILVER" },
              ]}
              field="tab"
              className="button-radios"
              subTitle={
                <div style={{ display: "flex" }}>
                  {status === "교환완료" &&
                    detailData.stockStatus === "DELIVERY" && (
                      <KendoButton
                        label="인쇄"
                        onClick={handlePrint}
                        style={{ marginRight: 20 }}
                      />
                    )}
                  {show && (
                    <Dialog
                      style={{ zIndex: 200 }}
                      title={"계산표 인쇄"}
                      onClose={handlePrint}
                    >
                      <DialogForm>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <ComponentToPrint
                            ref={printComponentRef}
                            tableData={tableData}
                            goldProduct={goldProduct}
                            silverProduct={silverProduct}
                            type={assetType}
                            appraisalWeightGram={detailData.appraisalWeightGram}
                          />
                        </p>
                        <Flex row style={{ justifyContent: "space-between" }}>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handlePrint}
                            style={{ width: "49%" }}
                          >
                            취소
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={() => {
                              onClickPrint();
                            }}
                            style={{ width: "49%" }}
                          >
                            확인
                          </button>
                        </Flex>
                      </DialogForm>
                      {/* <DialogActionsBar>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                          onClick={handlePrint}
                        >
                          취소
                        </button>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                          onClick={() => {
                            onClickPrint();
                          }}
                        >
                          확인
                        </button>
                      </DialogActionsBar> */}
                    </Dialog>
                  )}
                  <div
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    환산 중량 총 합계 :
                    {appraisalRequestStatus.find(
                      (item) => item.status === "감정완료"
                    )?.assetType
                      ? appraisalRequestStatus.find(
                          (item) => item.status === "감정완료"
                        )?.assetType
                      : appraisalRequestStatus.find(
                          (item) => item.status === "승인대기"
                        )?.assetType}{" "}
                    {numberWithCommas(
                      parseFloat(
                        appraisalRequestStatus.find(
                          (item) => item.status === "감정완료"
                        )?.appraiseGram
                          ? appraisalRequestStatus.find(
                              (item) => item.status === "감정완료"
                            )?.appraiseGram
                          : appraisalRequestStatus[
                              appraisalRequestStatus?.length - 1
                            ]?.status === "승인대기"
                          ? appraisalRequestStatus[
                              appraisalRequestStatus?.length - 1
                            ]?.appraiseGram
                          : appraisalRequestStatus.find(
                              (item) => item.status === "승인대기"
                            )?.appraiseGram
                      )
                    ) +
                      "g = " +
                      numFormat(
                        appraisalRequestStatus.find(
                          (item) => item.status === "감정완료"
                        )?.appraiseGram
                          ? appraisalRequestStatus.find(
                              (item) => item.status === "감정완료"
                            )?.appraiseGram *
                              (appraisalRequestStatus.find(
                                (item) => item.status === "감정완료"
                              )?.assetType === "GOLD"
                                ? goldGramPrice
                                : silverGramPrice)
                          : appraisalRequestStatus[
                              appraisalRequestStatus?.length - 1
                            ]?.status === "승인대기"
                          ? appraisalRequestStatus[
                              appraisalRequestStatus?.length - 1
                            ]?.appraiseGram *
                            (appraisalRequestStatus[
                              appraisalRequestStatus?.length - 1
                            ]?.assetType === "GOLD"
                              ? goldGramPrice
                              : silverGramPrice)
                          : appraisalRequestStatus.find(
                              (item) => item.status === "승인대기"
                            )?.appraiseGram *
                            (appraisalRequestStatus.find(
                              (item) => item.status === "승인대기"
                            )?.assetType === "GOLD"
                              ? goldGramPrice
                              : silverGramPrice)
                      ) +
                      "원"}
                  </div>
                </div>
              }
            />
          </AppraisalDetailContainer>
          <GridTable
            data={approvalList}
            name={gridName}
            reorderable
            scrollable
            style={{
              maxHeight: "520px",
              margin: "0px 50px",
            }}
          >
            <Column title="구분" field="purityTypeName" cell={centerCell} />
            <Column title="품목" field="name" cell={centerCell} />
            <Column title="기준비율" field="basicPct" cell={centerCell} />
            <Column
              title="측정 실중량(g)"
              field="productWeightGram"
              cell={numberWithCommasCell}
            />
            <Column
              title="환산 중량(g)"
              field="appraisalWeightGram"
              cell={numberWithCommasCell}
            />
            <Column
              title="검품 측정 실중량(g)"
              field="inspectProductWeightGram"
              cell={numberWithCommasCell}
            />
            <Column
              title="검품 환산 중량(g)"
              field="inspectWeightGram"
              cell={numberWithCommasCell}
            />
            <Column
              title="검품 상태"
              field="status"
              cell={(props) => {
                const { dataItem } = props;
                return (
                  <td style={{ textAlign: "center" }}>
                    {dataItem.status
                      ?.replace("WAIT", "대기")
                      ?.replace("CANCEL", "취소")
                      ?.replace("COMPLETE", "완료") || "-"}
                  </td>
                );
              }}
            />
          </GridTable>
        </Flex>
      )}
      <SimpleTable
        fullWidth
        columns={[
          {
            label: "일시",
            key: "createdAt",
          },
          {
            label: "상태",
            key: "status",
          },
          {
            label: "관리자",
            key: "managerName",
          },
          {
            label: "자산",
            key: "assetType",
          },
          {
            label: "중량",
            key: "appraiseGram",
          },
          {
            label: "비고",
            key: "appraiseMemo",
          },
        ].filter((x) => x)}
        data={parseData}
      />
    </>
  );
}

const useStyle = makeStyles({
  gridLabel: {
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    borderTop: "1px solid rgb(224,224,224)",
    padding: 14,
  },
  gridValue: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    "& svg": {
      color: "#000",
    },
    "& .MuiFormControlLabel-root": {
      minWidth: 150,
    },
  },
  input: {
    flex: 1,
    marginRight: "20px",
    paddingLeft: "16px",
    width: "800px",
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },
  input2: {
    paddingLeft: "16px",
    width: 100,
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },
  grid: {
    alignSelf: "stretch",
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 10,
    width: 1200,
  },
  calc: {
    margin: "0px 50px",
    textAlign: "right",
    marginBottom: 100,
    borderBottom: "1px solid rgb(224,224,224)",
  },
  images: { display: "flex", alignItems: "center", width: "100%" },
  image: {
    width: "80px",
    height: "80px",
    marginRight: 16,
    "&:last-child": { marginRight: 0 },
  },
  multiInput: {
    border: "1px  solid #ddd",
    minWidth: "70%",
    padding: 10,
    marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },
  mainC2Content: {
    alignItems: "center",
    padding: 50,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: 50,
    "& button": {
      minWidth: 50,
    },
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    marginLeft: 50,
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: {
    marginRight: 50,
    flex: 3,
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 50,
    minWidth: "70px !important",
    height: 38,
    alignItems: "center",
  },
  bt2: {
    backgroundColor: "black",
    color: "white",
    marginRight: 50,
    minWidth: "70px !important",
    height: 38,
    alignItems: "center",
  },
});
const AppraisalDetailContainer = styled(Flex)`
  flex: 1;
  margin: 10px 50px 0px 50px;

  .button-radios {
    border-bottom: 1px solid;
    button {
      padding: 5px;
    }
  }

  .content {
    margin-top: 10px;
    flex: 1;
    position: relative;
  }
`;
