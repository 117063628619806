import React, { useEffect, useState } from "react";
import fonts from "../../libs/fonts";
import { formatTime } from "../../libs/utils";

const DateInput = ({ value, onChange, ...rest }) => {
  return (
    <input
      type="date"
      onChange={(e) => {
        onChange(formatTime(e.target.value));
      }}
      style={{ fontFamily: fonts.notoSansKRMedium, padding: "0 5px" }}
      value={value}
      {...rest}
    />
    // <DatePicker
    //   wrapperClassName={`${classes.wrapper}`}
    //   className={`${classes.input} ${className}`}
    //   placeholderText={placeholderText}
    //   dateFormat="yyyy-MM-dd"
    //   onChange={(date) => {
    //     onChange(formatTime(date));
    //   }}
    //   value={value}
    //   maxDate={maxDate}
    //   minDate={minDate}
    //   popperContainer={CalendarContainer}
    //   disabled={disabled === true ? true : false}
    // />
  );
};

export default DateInput;
