import { makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Input from "../../components/input/Input";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
//import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
//import { userSignIn, userUpdate } from "../../redux/user/UserActions";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { signIn } from "../../redux/auth/AuthReducer";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import utf8 from "crypto-js/enc-utf8";
import TextInput from "../../components/input/TextInput";

export default function TwoFactorRegister({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationState = history.location.state || {};
  const [loginInfo, setLoginInfo] = useState(null);
  const [otp, setOtp] = useState({ url: "", authKey: "" });
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (locationState.account && locationState.password) {
      setLoginInfo({ ...locationState });
      history.replace(location.pathname);
    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (loginInfo) {
      dispatch(loadingStart);
      APIS.postCreateOTP(loginInfo)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setOtp(data);
          } else {
            dispatch(actionOpen(message, () => history.goBack(), "", "", true));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  }, [loginInfo]);

  const handleClickLogin = () => {
    if (
      verificationCode.length === 6 ||
      process.env.REACT_APP_SERVICE_TYPE === "DEVELOPMENT"
    ) {
      dispatch(loadingStart);
      APIS.postLoginOtp({ ...loginInfo, verificationCode })
        .then(({ data: { success, message, data } }) => {
          if (success) {
            const { authToken, passwordVerification, id } = data || {};
            localStorage.setItem(STORAGE_KEY.accessToken, authToken);
            localStorage.setItem("passwordVerification", passwordVerification);
            localStorage.setItem("id", id);
            localStorage.setItem("userAccount", loginInfo.account);
            dispatch(signIn(authToken));
          } else {
            setVerificationCode("");

            let callBack = () => {};

            if (data.isOtpFailed) {
              callBack = () => {
                history.goBack();
              };
            }

            dispatch(actionOpen(message, callBack, "", "", true));
          }
        })
        .finally(() => dispatch(loadingEnd));
    } else {
      dispatch(actionError("OTP Code를 정확히 입력해주세요."));
    }
  };

  return (
    <BasicLayout center className={classes.root}>
      <img src={images.banner} className={classes.banner} />
      <Paper className={classes.main}>
        <img src={images.logo_new} className={classes.logo} />
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          OTP 등록이 필요합니다.
        </Text>
        {/* <img src={imgUrl} /> */}
        <Flex className={classes.form} row>
          <Flex>
            <img src={otp.url} alt="QrCode" />
            <span style={{ fontWeight: 600, textAlign: "center" }}>
              {otp.authKey}
            </span>
          </Flex>
          <Flex className={classes.description}>
            <span>1. OTP 어플을 다운로드 하세요.(Google OTP, Authy 등)</span>
            <span>2. 신규 OTP 생성에서 좌측 QR 코드를 스캔하세요.</span>
            <span>3. OTP 등록 후 코드를 입력하여 로그인을 완료하세요.</span>
          </Flex>
        </Flex>

        <TextInput
          type="number"
          value={verificationCode}
          onChange={setVerificationCode}
          className={classes.input}
          placeholder="Code"
          onEnter={handleClickLogin}
          autoFocus
          maxLength={6}
        />
        <Button
          onClick={handleClickLogin}
          className={classes.button}
          label="로그인"
        />
      </Paper>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
  },
  label: {
    color: colors.black,
    fontSize: "24px",
    marginTop: 10,
    textAlign: "center",
  },
  form: {
    margin: "20px 0",
    alignItems: "center",
  },
  input: {
    border: "none",
    backgroundColor: "#f2f2f2",
  },
  formInput: { flex: 1 },
  button: {
    backgroundColor: colors.primary,
    width: "100%",
    marginTop: 10,
  },
  banner: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  main: {
    backgroundColor: "#fff",
    padding: "60px 50px",
    boxSizing: "border-box",
    zIndex: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 600,
  },
  logo: {
    width: 240,
    height: 40,
    objectFit: "contain",
  },
  description: {
    flex: 1,
    marginLeft: 10,
    justifyContent: "space-between",
    fontWeight: 400,
    fontSize: 16,
    "&> span": {
      marginTop: 10,
      wordBreak: "keep-all",
    },
  },
  skip: {
    alignSelf: "flex-end",
    marginTop: 10,
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
});
