import { Input } from "@progress/kendo-react-inputs";
import React from "react";

const TextInput = ({
  className = "",
  type = "text",
  style = {},
  value,
  onChange = () => {},
  onClick,
  autoFocus,
  placeholder = "",
  onEnter = () => {},
  maxLength,
}) => {
  const isNumber = type === "number";
  return (
    <Input
      type={isNumber ? "text" : type}
      className={className}
      style={{ ...style }}
      value={value}
      onChange={(e) => {
        const key = e.nativeEvent.data;
        if (isNumber) {
          if (!(key >= 0 && key <= 9)) {
            return false;
          }
        }
        onChange(e.target.value, e);
      }}
      onClick={onClick}
      autoFocus={autoFocus}
      placeholder={placeholder}
      onKeyDown={({ key }) => {
        key === "Enter" && onEnter && onEnter();
      }}
      maxLength={maxLength}
    />
  );
};

export default TextInput;
